// design and keys done 12 march 25
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
  Fragment,
} from "react";
// cleaned wef 24 jul 23
import axios from "axios";
import { NavLink } from "react-router-dom";

// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";

import dateStringer from "../tools/dateStringer";
import serverURL from "../serverURL";

import close from "../img/modals/close.svg";
import greyBin from "../img/general/greyBin.svg";
import code from "../img/general/fileTypes/code.svg";
import doc from "../img/general/fileTypes/doc.svg";
import img from "../img/general/fileTypes/img.svg";
import music from "../img/general/fileTypes/music.svg";
import pdf from "../img/general/fileTypes/pdf.svg";
import ppt from "../img/general/fileTypes/ppt.svg";
import spreadsheet from "../img/general/fileTypes/spreadsheet.svg";
import zip from "../img/general/fileTypes/zip.svg";
import video from "../img/general/fileTypes/video.svg";
import folderIcon from "../img/general/fileTypes/folderIcon.svg";
import folderIconSelf from "../img/general/fileTypes/folderIconSelf.svg";
import folderIconPurple from "../img/general/folderIconPurple.svg";
import signout from "../img/general/signout.svg";
import whiteTriangle from "../img/general/allowArr.svg";

import "../styles/calendar.css";

const MyDocsModal = ({ modal }) => {
  const {
    device,
    updatedReq,
    showMyDocsModal,
    setShowMyDocsModal,
    setIndicate,
    modalOpen,
    setModalOpen,
  } = useContext(DataContext);
  const { checkUserState, currencySymbol } = useContext(UserContext);

  const { mob, mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      device,
      updatedReq,
      checkUserState,
      currencySymbol,
      setShowMyDocsModal,
      modalOpen,
      setModalOpen,
      setIndicate,
    }),
    [
      device, //
      updatedReq, //
      checkUserState, //
      currencySymbol, //
      setShowMyDocsModal,
      setIndicate,
      modalOpen,
      setModalOpen,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [loading, setLoading] = useState(true);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 16) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={greyBin}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={code}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={doc}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={img}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={music}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={pdf}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={ppt}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={spreadsheet}
        alt="Grey Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={zip}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={video}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={folderIcon}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={folderIconSelf}
        alt="Grey Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={folderIconPurple}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={signout}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteTriangle}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [files, setFiles] = useState([]);
  let [isAdmin, setIsAdmin] = useState(false);
  let [openFolderIDs, setOpenFolderIDs] = useState([]);
  let [filesQty, setFilesQty] = useState(0);
  let [showUploadModal, setShowUploadModal] = useState(false);
  let [uploadLoading, setUploadLoading] = useState(false);
  let [exceedSizeLimit, setExceedSizeLimit] = useState(false);
  let [reload, setReload] = useState(false);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-my-files`,
        {
          someData: 1,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setFiles(response.data.filesAndFolders);
          let fileCount = 0;
          response.data.filesAndFolders.forEach((f) => {
            if (f.folderID && f.files && Array.isArray(f.files)) {
              fileCount += f.files.length;
            } else {
              fileCount++;
            }
          });
          setFilesQty(fileCount);
          setIsAdmin(response.data.isAdmin);
          setDataLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [reload]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      memoVals.setShowMyDocsModal((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [isAtTop, setIsAtTop] = useState(true);
  let getFileImg = (type) => {
    if (type.startsWith("image/")) {
      return img;
    } else if (
      type.startsWith("text/") &&
      !type.includes("html") &&
      !type.includes("css") &&
      !type.includes("javascript")
    ) {
      if (type.includes("xls") || type.includes("csv")) {
        return spreadsheet;
      }
      return doc;
    } else if (type.startsWith("audio/")) {
      return music;
    } else if (type.startsWith("video/")) {
      return video;
    } else if (type.startsWith("application/") && type.includes("pdf")) {
      return pdf;
    } else if (
      type.startsWith(
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) ||
      type === "application/vnd.ms-powerpoint" ||
      type === "application/keynote"
    ) {
      return ppt;
    } else if (type.startsWith("application/zip")) {
      return zip;
    } else if (type.includes("xls") || type.includes("csv")) {
      return spreadsheet;
    } else {
      return code;
    }
  };

  let [sureDelete, setSureDelete] = useState({ show: false });
  // master return
  return (
    <div
      className={`desktopDataBox modalReqDataBox reqsDesktopBoxScroll myDocsModalBox noBorder ${
        memoVals.device.ios ? "paddingBottom20" : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={modalPosition}
      ref={modalRef}
    >
      {window.innerWidth <= 550 && (
        <div
          className="modalSwiper modalSwiperMyAllows"
          onTouchStart={handleDown}
          onTouchMove={window.innerWidth <= 550 ? handleMove : null}
          onTouchEnd={window.innerWidth <= 550 ? handleUp : null}
          onMouseDown={window.innerWidth <= 550 ? handleDown : null}
          onMouseMove={window.innerWidth <= 550 ? handleMove : null}
          onMouseUp={window.innerWidth <= 550 ? handleUp : null}
        ></div>
      )}
      <div
        className="desktopDataBoxHeader"
        onTouchStart={handleDown}
        onTouchMove={window.innerWidth <= 550 ? handleMove : null}
        onTouchEnd={window.innerWidth <= 550 ? handleUp : null}
        onMouseDown={window.innerWidth <= 550 ? handleDown : null}
        onMouseMove={window.innerWidth <= 550 ? handleMove : null}
        onMouseUp={window.innerWidth <= 550 ? handleUp : null}
      >
        <img
          src={close}
          alt="Close"
          className="closeReqDataBoxImg x23141"
          onClick={() => {
            memoVals.setShowMyDocsModal((x) => false);
          }}
        />

        <span
          className={`dataBoxHeading colourNavy font18px modalMyReqsDataBoxTitle myDocsTitle`}
        >
          My documents
        </span>
      </div>
      {isAdmin && (
        <p className="myDocsIsAdminTxt">
          You are an admin. Showing all files that belong to you - regardless of
          file/folder visibilty.{" "}
        </p>
      )}
      <div className="myReqSideboxTop">
        <div className="desktopDataRow paddingRight8">
          <p
            className={`desktopDataTitle colourNavy ${
              modal ? "font16px" : ""
            } usedRemainingIndent`}
          >
            Files
          </p>
          {loading ? (
            <p className={`colorWhite`}>-</p>
          ) : (
            <p className={`desktopDataValue boldDataVal font16px colourNavy`}>
              {filesQty}
            </p>
          )}
        </div>
        <div className="desktopDataRow noBorder paddingRight8">
          <p
            className={`desktopDataTitle colourNavy ${
              modal ? "font16px" : ""
            } usedRemainingIndent`}
          >
            Folders
          </p>
          {loading ? (
            <p className={`colorWhite`}>-</p>
          ) : (
            <p className={`desktopDataValue boldDataVal font16px  colourNavy`}>
              {files &&
                files.filter((x) => {
                  return x.folderName;
                }).length}
            </p>
          )}
          <p
            className={`${
              memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
            }`}
          >
            -
          </p>
        </div>
        <div className="myDocsBox">
          {files
            .sort((a, b) => {
              // Check if folderID is "self_"
              if (a.folderID === "self_") return -1; // "self_" comes first
              if (b.folderID === "self_") return 1; // "self_" comes first

              // Check for teamFolder === true
              if (a.teamFolder && !b.teamFolder) return -1; // teamFolder comes second
              if (!a.teamFolder && b.teamFolder) return 1; // teamFolder comes second

              // If both are teamFolder or both are not
              if (a.teamFolder && b.teamFolder) {
                const folderNameA = a.folderName || "";
                const folderNameB = b.folderName || "";
                return folderNameA.localeCompare(folderNameB); // Sort alphabetically by folderName
              }

              // Handle folderID existence
              if (!a.folderID && !b.folderID) {
                const fileNameA = a.fileName || "";
                const fileNameB = b.fileName || "";
                return fileNameA.localeCompare(fileNameB); // Sort by fileName if no folderID
              } else if (!a.folderID) {
                return 1; // a (no folderID) goes to the bottom
              } else if (!b.folderID) {
                return -1; // b (no folderID) goes to the bottom
              }

              // If folderName doesn't exist or is equal, consider them equal
              const folderNameA = a.folderName || "";
              const folderNameB = b.folderName || "";
              return folderNameA.localeCompare(folderNameB); // Sort by folderName
            })
            .map((f, i) => {
              if (f.folderID) {
                return (
                  <Fragment key={i}>
                    <div
                      className={`myDocsFolderRow ${
                        openFolderIDs.includes(f.folderID)
                          ? "myDocsFolderRowActive"
                          : ""
                      } ${f.folderID === "self_" ? "myUplFolder" : ""}`}
                      onClick={() => {
                        if (openFolderIDs.includes(f.folderID)) {
                          setOpenFolderIDs(
                            openFolderIDs.filter((x) => {
                              return x !== f.folderID;
                            })
                          );
                        } else {
                          setOpenFolderIDs([...openFolderIDs, f.folderID]);
                        }
                      }}
                    >
                      <div className="myDocsFolderRowLeft">
                        <img
                          src={
                            f.teamFolder
                              ? folderIconPurple
                              : f.folderID === "self_"
                              ? folderIconSelf
                              : folderIcon
                          }
                          alt="Folder"
                          className="myDocsFolderImg"
                        />
                        {f.folderName}
                      </div>
                      <div className="myDocsFolderRowRight">
                        <p className="myDocsFolderQty">
                          {f.files.length > 0 ? f.files.length : "-"}{" "}
                          {f.files && f.files.length > 0 ? "file" : ""}
                          {(f.files && f.files.length === 1) ||
                          f.files.length === 0
                            ? ""
                            : "s"}
                        </p>
                        <img
                          src={whiteTriangle}
                          alt="Open folder"
                          className={`myDocsWhiteTriImg ${
                            openFolderIDs.includes(f.folderID)
                              ? "myDocsWhiteTriImgActive"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                    {openFolderIDs.includes(f.folderID) && (
                      <div className="myDocsFolderOpen">
                        {!f.files || f.files.length === 0 ? (
                          <p className="noFilesInSub">
                            No files in this folder
                          </p>
                        ) : (
                          f.files.map((f, i) => {
                            return (
                              <div
                                key={i}
                                className={`myDocsFolderRow myDocsFolderRowIndent`}
                                onClick={() => {
                                  axios({
                                    method: "get",
                                    url: `${serverURL}/download-file/${f.fileName}`,
                                    responseType: "blob", // Set the responseType to 'blob' to handle binary data
                                  })
                                    .then((response) => {
                                      // Create a URL for the blob data
                                      const blobUrl =
                                        window.URL.createObjectURL(
                                          response.data
                                        );

                                      // Create a temporary link element
                                      const link = document.createElement("a");
                                      link.href = blobUrl;

                                      // Set the download attribute to specify the file name
                                      link.download = f.fileName;
                                      // "your-file-name.jpg"; // Replace 'your-file-name.jpg' with the desired file name

                                      // Append the link to the body
                                      document.body.appendChild(link);

                                      // Trigger the download by programmatically clicking on the link
                                      link.click();

                                      // Clean up: remove the temporary link
                                      document.body.removeChild(link);
                                      memoVals.setIndicate((x) => {
                                        return {
                                          show: true,
                                          message: `${dateStringer.shorten(
                                            f.fileName,
                                            22
                                          )} downloading`,
                                          duration: 4000,
                                        };
                                      });
                                    })
                                    .catch((error) => {
                                      // Handle errors
                                      console.error(
                                        "Error downloading file:",
                                        error
                                      );
                                    });
                                }}
                              >
                                <div className="myDocsFolderRowLeft">
                                  <img
                                    src={getFileImg(f.type)}
                                    alt="Folder"
                                    className="myDocsFolderImg"
                                  />
                                  <div className="myDocsTxtLeft">
                                    <p className="myDocsFileName">
                                      {dateStringer.removeTsFromFileName(
                                        f.fileName
                                      )}
                                      {dateStringer.getFileExtension(
                                        f.fileName
                                      )}
                                    </p>
                                    <p className="myFileDs">
                                      {dateStringer.printedDateWithTimeFromDs(
                                        f.ds,
                                        true,
                                        false,
                                        true
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={`myDocsFolderRowRight ${
                                    f.folderID === "self_"
                                      ? "myDocsFolderRowRightSelf"
                                      : ""
                                  }`}
                                >
                                  <p
                                    className={`myDocsFolderQty ${
                                      f.folderID === "self_"
                                        ? "myDocsFolderQtySelf"
                                        : ""
                                    }`}
                                  >
                                    {dateStringer.bytes(f.bytes)}
                                  </p>
                                  {f.folderID === "self_" ? (
                                    <img
                                      src={greyBin}
                                      alt="delete file"
                                      className="deleteFileMyIcon"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setSureDelete({
                                          show: true,
                                          fileID: f.fileID,
                                          fileName: f.fileName,
                                        });
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {/* <img
                                src={whiteTriangle}
                                alt="Open folder"
                                className={`myDocsWhiteTriImg ${
                                  openFolderIDs.includes(f.folderID)
                                    ? "myDocsWhiteTriImgActive"
                                    : ""
                                }`}
                              /> */}
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    )}
                  </Fragment>
                );
              }

              if (f.fileName) {
                return (
                  <div
                    className={`myDocsFolderRow ${
                      i === files.findIndex((x) => x.fileName)
                        ? "firstMyDocsFile"
                        : ""
                    }`}
                    onClick={() => {
                      axios({
                        method: "get",
                        url: `${serverURL}/download-file/${f.fileName}`,
                        responseType: "blob", // Set the responseType to 'blob' to handle binary data
                      })
                        .then((response) => {
                          // Create a URL for the blob data
                          const blobUrl = window.URL.createObjectURL(
                            response.data
                          );

                          // Create a temporary link element
                          const link = document.createElement("a");
                          link.href = blobUrl;

                          // Set the download attribute to specify the file name
                          link.download = f.fileName;
                          // "your-file-name.jpg"; // Replace 'your-file-name.jpg' with the desired file name

                          // Append the link to the body
                          document.body.appendChild(link);

                          // Trigger the download by programmatically clicking on the link
                          link.click();

                          // Clean up: remove the temporary link
                          document.body.removeChild(link);
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: `${dateStringer.shorten(
                                f.fileName,
                                22
                              )} downloading`,
                              duration: 4000,
                            };
                          });
                        })
                        .catch((error) => {
                          // Handle errors
                          console.error("Error downloading file:", error);
                        });
                    }}
                  >
                    <div className="myDocsFolderRowLeft">
                      <img
                        src={getFileImg(f.type)}
                        alt="Folder"
                        className="myDocsFolderImg"
                      />
                      <div className="myDocsTxtLeft">
                        <p className="myDocsFileName">
                          {dateStringer.removeTsFromFileName(f.fileName)}
                          {dateStringer.getFileExtension(f.fileName)}
                        </p>
                        <p className="myFileDs">
                          {dateStringer.printedDateWithTimeFromDs(
                            f.ds,
                            true,
                            false,
                            true
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="myDocsFolderRowRight">
                      <div className="docFileRight">
                        <p className="myDocsFolderQty">
                          {dateStringer.bytes(f.bytes)}
                        </p>
                        <p className="docFileType">
                          {dateStringer.getExt(f.fileName).replace(".", "")}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
      {/* - - - - */}
      <div className="emptyDesktopDataRow"></div>

      <div className="myDocsFooter">
        <div className="flexStart">
          <p
            className={`uploadDocDashBtn `}
            onClick={() => {
              setShowUploadModal(true);
              if (uploadLoading) {
                setUploadLoading(false);
              }
            }}
          >
            Upload file
          </p>{" "}
          <NavLink
            to="staff-dashboard/documents"
            className={`uploadDocDashBtn lilacColours x58778433`}
            onClick={() => {
              memoVals.setShowMyDocsModal((x) => false);
            }}
          >
            Manage docs
            <img src={signout} alt="Docs" className="empDocsImgInline" />
          </NavLink>{" "}
        </div>
        <p
          className={`closeReqInfo`}
          onClick={() => {
            memoVals.setShowMyDocsModal((x) => false);
          }}
        >
          Close
        </p>
      </div>
      {showUploadModal ? (
        <div
          className="editProPicUnderlay"
          onClick={() => {
            setShowUploadModal(false);
            if (exceedSizeLimit) {
              setExceedSizeLimit(false);
            }
          }}
        >
          <div
            className={`editProPicModalBox ${
              memoVals.device.ios ? "paddingBottom20" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="editProPicModalHeader x2989248423">
              <img
                src={close}
                alt="Close edit profile photo modal"
                className="closeEditProPicModalIcon"
                onClick={() => {
                  setShowUploadModal(false);
                  if (exceedSizeLimit) {
                    setExceedSizeLimit(false);
                  }
                }}
              />
              Upload a file
            </div>
            <div className="editProPicModalContentBox">
              {uploadLoading ? (
                <p className="waitForProPic">Uploading...</p>
              ) : (
                <input
                  type="file"
                  title=" "
                  id="avatar"
                  name="avatar"
                  className="proPicChangeBtnMyAcc"
                  // accept="image/png, image/jpeg"
                  onChange={async (e) => {
                    let file = e.target.files[0];

                    if (file && file.size > 50000000) {
                      setExceedSizeLimit(true);
                    } else {
                      setExceedSizeLimit(false);
                      setUploadLoading(true);

                      try {
                        const formData = new FormData();
                        formData.append("file", file);
                        formData.append("name", file.name);
                        formData.append("type", file.type);

                        formData.append("userID", "self");
                        formData.append(
                          "nowDs",
                          dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          )
                        );

                        const response = await axios.post(
                          `${serverURL}/upload-dash-file`,
                          formData,
                          {
                            withCredentials: true,
                            credentials: "include",
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        );

                        if (response.data.message.includes("exceed")) {
                          setExceedSizeLimit(true);
                        }
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          // setFiles([...files, response.data.newFile]);
                          // put the file into the self_ folderID folder
                          let newArr = [];
                          files.map((folder) => {
                            if (folder.folderID === "self_") {
                              newArr.push({
                                folderID: "self_",
                                folderName: "My uploads",
                                files: [...folder.files, response.data.newFile],
                              });
                            } else {
                              newArr.push(folder);
                            }
                          });
                          setFilesQty(filesQty + 1);
                          setFiles(newArr);
                          setShowUploadModal(false);
                          // Handle success
                          console.log("File uploaded successfully");
                        }
                        if (response.data.message === "2GB limit exceeded") {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "2GB employee limit exceeded",
                              colour: "red",
                              duration: 4000,
                            };
                          });
                        }
                      } catch (error) {
                        // Handle error
                        console.error("Error uploading file:", error);
                      } finally {
                        setUploadLoading(false);
                      }
                    }
                  }}
                />
              )}

              {!uploadLoading ? (
                <p className="uploadDisclaim">
                  By uploading files to FlowRota, you acknowledge that files are
                  stored in a public cloud and are accessible via a link. Whilst
                  FlowRota does not share links to anyone other than the
                  specific FlowRota user, please note that these files are not
                  encrypted and may be accessible to anyone with the link
                  without authentication. FlowRota uses Google Cloud for its
                  storage and assumes no responsibility for the availability or
                  security of these files once uploaded. We recommend exercising
                  caution when sharing sensitive information through FlowRota's
                  file uploads, and refrain from uploading highly confidential
                  information.{" "}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="editProPicModalFooter">
              <p className={`max25mb ${!exceedSizeLimit ? "invis" : ""}`}>
                Max 50MB file size limit
              </p>

              <p
                className="closeProPicModalBtn"
                onClick={() => {
                  setShowUploadModal(false);
                  if (exceedSizeLimit) {
                    setExceedSizeLimit(false);
                  }
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {sureDelete.show ? (
        <div
          className="clockOnModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setSureDelete({ show: false });
          }}
        >
          <div className={`formCreatedModal `}>
            <p className="overlapsRenTxt">
              Are you sure you want to delete this file?
              <br /> <br />
              You cannot undo this action.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setSureDelete({ show: false });
                  axios
                    .post(
                      `${serverURL}/delete-dash-file`,
                      {
                        fileName: sureDelete.fileName,
                        folderID: false,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setReload(!reload);
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            colour: "green",
                            message: `${dateStringer.shorten(
                              sureDelete.fileName,
                              22
                            )} deleted`,
                            duration: 4000,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDelete({ show: false });
                }}
              >
                No
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default MyDocsModal;
