import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useRef,
  Fragment,
} from "react";

// this is a subcomponent of the Dashboard -- the dashboard file is already big enough

import axios from "axios";
import { UserContext } from "../../contexts/UserContext";
import { StyleContext } from "../../contexts/StyleContext";
import { DataContext } from "../../contexts/DataContext";
import { CalendarContext } from "../../contexts/CalendarContext";

import websiteURL from "../../websiteURL";

import { NavLink } from "react-router-dom";
import imageCompression from "browser-image-compression";

import serverURL from "../../serverURL";

import Navbar from "../Navbar";
import CheckUser from "../../tools/CheckUser";
import AddShiftModal2 from "../account/admin/subcomponents/AddShiftModal2";

import "../../styles/settings.css";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";
import allowArr from "../../img/general/allowArr.svg";
import downArr516b8b from "../../img/general/downArr516b8b.svg";
import whiteAddThin from "../../img/general/whiteAddThin.svg";

import dashSwap from "../../img/general/dashSwap.svg";
import dashToil from "../../img/general/dashToil.svg";
import dashChange from "../../img/general/dashChange.svg";

import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";
import manGreen from "../../img/general/manGreen.svg";
import convert from "../../img/general/convert.svg";
import openShiftBlock from "../../img/general/openShiftBlock.svg";
import bin1 from "../../img/general/bin1.svg";
import threeGreyDots from "../../img/general/threeGreyDots.svg";
import doubleTick from "../../img/general/doubleTick.svg";
import lightBlueQuestionMark from "../../img/general/lightBlueQuestionMark.svg";

import whiteTriangle from "../../img/general/whiteTriangle.svg";
import up from "../../img/general/up.svg";
import plane2 from "../../img/general/plane2.svg";
import redWarning from "../../img/general/redWarning.svg";
import clockLightBlue from "../../img/general/clockLightBlue.svg";
import clockGreen from "../../img/general/clockGreen.svg";
import clockRed from "../../img/general/clockRed.svg";
import clockGrey from "../../img/general/clockGrey.svg";
import planeGreen from "../../img/general/planeGreen.svg";

import cross516b8b from "../../img/general/cross516b8b.svg";
import paperPlane516b8b from "../../img/general/paperPlane516b8b.svg";
import file from "../../img/general/fileTypes/file.svg";
import img from "../../img/general/fileTypes/img.svg";
import music from "../../img/general/fileTypes/music.svg";
import pdf from "../../img/general/fileTypes/pdf.svg";
import ppt from "../../img/general/fileTypes/ppt.svg";
import spreadsheet from "../../img/general/fileTypes/spreadsheet.svg";
import zip from "../../img/general/fileTypes/zip.svg";
import video from "../../img/general/fileTypes/video.svg";
import folder from "../../img/general/fileTypes/folder.svg";
import folderIcon from "../../img/general/fileTypes/folderIcon.svg";
import folderHidden from "../../img/general/fileTypes/folderHidden.svg";
import privateIcon from "../../img/general/fileTypes/private.svg";

import close from "../../img/modals/close.svg";
import dateStringer from "../../tools/dateStringer";
import appURL from "../../appURL";

const DashboardDash = ({}) => {
  const { checkUserState, currencySymbol } = useContext(UserContext);
  const { updatedItem, setShowOpenShiftModal } = useContext(CalendarContext);

  const { mob, full, fullButNarrow, mobModal, setAccNav } =
    useContext(StyleContext);
  const {
    setShowEditUser,
    setShowEditShiftModal,
    updateSingleUserAllowances,
    setClockOnID,
    setUpdateSingleUserAllowances,
    setShowConvertToilModal,
    setIndicate,
    device,
    setShowActiveClocks,
    setShowReqMgrModal,
    setShowAddLeaveOnTeam,
    changedClockCard,
    setShowTeamSwapsModal,
    updateOpensModal,
    reloadTeamClockOns,
  } = useContext(DataContext);

  let [page, setPage] = useState("today"); // today leave amendments or spend
  let [mediumPage, setMediumPage] = useState(1); // 1 or 2
  let [medium, setMedium] = useState(false);

  let [showAddShift, setShowAddShift] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 1155 || mob) {
      setMedium(false);
    } else {
      if (window.innerWidth > 820 && window.innerWidth <= 1155) {
        setMedium(true);
      } else {
        setMedium(false);
      }
    }
    // if (!mob) {
    //   if (window.innerWidth < 1140 && !mob) {
    //     if (!medium) {
    //       setMedium(true);
    //     }
    //   } else {
    //     if (medium) {
    //       if (!mob) {
    //         setMedium(false);
    //       }
    //     }
    //   }
    // }
  }, [window.innerWidth, mob, full]);
  let [loading, setLoading] = useState(true);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let formatSurname = (string, surname) => {
    if ((string && string.length > 18 && surname) || 1 + 1 === 2) {
      return surname[0];
    } else {
      return surname;
    }
  };

  let today00 = new Date();
  today00.setHours(0, 0, 0, 0);
  let tomorrow00 = new Date(today00.getTime());
  tomorrow00.setDate(tomorrow00.getDate() + 1);
  tomorrow00.setHours(0, 0, 0, 0);
  let yesterday00 = new Date(today00.getTime());
  yesterday00.setDate(yesterday00.getDate() - 1);
  yesterday00.setHours(0, 0, 0, 0);

  let [shiftsDs, setShiftsDs] = useState(
    dateStringer.createStringFromTimestamp(today00.getTime())
  );
  let [leaveDs, setLeaveDs] = useState(
    dateStringer.createStringFromTimestamp(today00.getTime())
  );

  let [todayShifts, setTodayShifts] = useState([]);
  let [todayShiftsLoading, setTodayShiftsLoading] = useState(true);
  let [onLeaveLoading, setOnLeaveLoading] = useState(true);
  let [onLeave, setOnLeave] = useState([]);

  let [swaps, setSwaps] = useState(0);
  let [tilReqs, setTilReqs] = useState(0);
  let [amendReqs, setAmendReqs] = useState(0);
  let [opens, setOpens] = useState([]);
  let [openTeams, setOpenTeams] = useState([]);
  let [opensLoading, setOpensLoading] = useState(true);
  let [toggleAddOpen, setToggleAddOpen] = useState(false);
  let [openDateInput, setOpenDateInput] = useState(
    dateStringer.createDateInputFromTimestamp(new Date().getTime())
  );
  let [openTeamID, setOpenTeamID] = useState("");
  let [maxDs, setMaxDs] = useState("");

  let [wageDs, setWageDs] = useState(
    dateStringer.createStringFromTimestamp(new Date().getTime())
  );
  let [wageTeams, setWageTeams] = useState([]);
  let [wageLoading, setWageLoading] = useState(true);
  const cancelSource1 = axios.CancelToken.source();
  const cancelSource2 = axios.CancelToken.source();
  const cancelSource3 = axios.CancelToken.source();
  const cancelSource4 = axios.CancelToken.source();

  useEffect(() => {
    if ((!mob && !medium) || page === "today" || mediumPage === 1) {
      axios
        .post(
          `${serverURL}/get-dash-shifts`,
          {
            ds: shiftsDs,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setTodayShifts(response.data.shifts || []);
            setTodayShiftsLoading(false);
          }
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [
    page,
    shiftsDs,
    updatedItem,
    medium,
    mob,
    mediumPage,
    changedClockCard,
    reloadTeamClockOns,
  ]);
  useEffect(() => {
    if ((!mob && !medium) || page === "leave" || mediumPage === 1) {
      axios
        .post(
          `${serverURL}/get-dash-leave`,
          {
            ds: leaveDs,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setOnLeave(response.data.onLeave || []);
            setOnLeaveLoading(false);
            console.log("setting on leave loading to false");
          }
        });
    }
    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
      cancelSource3.cancel("Component unmounted");
      cancelSource4.cancel("Component unmounted");
    };
  }, [page, medium, leaveDs, mediumPage]);
  useEffect(() => {
    if ((!mob && !medium) || page === "amendments" || mediumPage === 2) {
      axios
        .post(
          `${serverURL}/get-dash-outstanding`,
          {
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setSwaps(response.data.teamSwapsRequiringAction);
            setTilReqs(response.data.ttr);
            setAmendReqs(response.data.changes);
            setMaxDs(response.data.maxDs);
            setOpens(response.data.opens);
            setOpensLoading(false);
            setOpenTeams(response.data.teamsArr);
            if (
              response.data.teamsArr &&
              Array.isArray(response.data.teamsArr)
            ) {
              setOpenTeamID(response.data.teamsArr[0].teamID);
            }
          }
        });
    }
    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
      cancelSource3.cancel("Component unmounted");
      cancelSource4.cancel("Component unmounted");
    };
  }, [page, medium, leaveDs, mediumPage, updateOpensModal]);
  useEffect(() => {
    if ((!mob && !medium) || page === "spend" || mediumPage === 2) {
      axios
        .post(
          `${serverURL}/get-dash-spends`,
          {
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            ds: wageDs,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setWageTeams(response.data.arr);
            setWageLoading(false);
          }
        });
    }
    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
      cancelSource3.cancel("Component unmounted");
      cancelSource4.cancel("Component unmounted");
    };
  }, [page, medium, wageDs, mediumPage, updatedItem]);
  let offset = (startDs, endDs) => {
    // endDs is selected day
    if (
      startDs &&
      typeof startDs === "string" &&
      endDs &&
      typeof endDs === "string"
    ) {
      let startObj = new Date(dateStringer.createTimestampFromString(startDs));
      startObj.setHours(0, 0, 0, 0);

      let endObj = new Date(dateStringer.createTimestampFromString(endDs));
      endObj.setHours(0, 0, 0, 0);

      // Calculate the difference in days
      let daysDiff = Math.round(
        (startObj.getTime() - endObj.getTime()) / 86400000
      );

      if (daysDiff === 0) {
        return ""; // Same day
      } else if (daysDiff < 0) {
        return `+${Math.abs(daysDiff)} day${Math.abs(daysDiff) > 1 ? "s" : ""}`; // startObj is after todayObj by at least 1 day
      } else {
        return `-${Math.abs(daysDiff)} day${Math.abs(daysDiff) > 1 ? "s" : ""}`; // startObj is before todayObj by at least 1 day
      }
    }
  };
  // useEffect(() => {
  //   const addShiftDateInput = document.getElementById("addShiftDateInput");

  //   // Define the event handler with error handling
  //   const handleFocus = function (evt) {
  //     // try {
  //     //   if (this && typeof this.showPicker === "function") {
  //     //     this.showPicker();
  //     //   } else {
  //     //     console.warn("showPicker is not available on this element");
  //     //   }
  //     // } catch (error) {
  //     //   console.error("Error in handleFocus event handler:", error);
  //     // }
  //   };

  //   // Add the event listener
  //   if (addShiftDateInput) {
  //     addShiftDateInput.addEventListener("focus", handleFocus);
  //   }

  //   if (toggleAddOpen) {
  //     if (addShiftDateInput && !mob) {
  //       // Attach the event listener
  //       addShiftDateInput.addEventListener("focus", handleFocus);
  //     }
  //   }

  //   return () => {
  //     // Clean up: remove the event listener if it exists
  //     if (addShiftDateInput && !mob) {
  //       addShiftDateInput.removeEventListener("focus", handleFocus);
  //     }
  //   };
  // }, [toggleAddOpen, mob]); // Add `mob` to the dependency array

  let openAddContent = toggleAddOpen ? (
    <div
      className={`dashOpensInputDiv ${
        !medium ? "dashOpensInputDivNonMed" : ""
      }`}
    >
      <p className="dashOpensInputTitle">Team</p>

      <select
        className={`dashOpenTeamsDropdown ${
          !medium ? "dashOpenTeamsDropdownNonMed" : ""
        } ${!medium && !mob ? "dashOpenTeamsDropdownFull" : ""}`}
        value={openTeamID}
        onChange={(e) => {
          setOpenTeamID(e.target.value);
        }}
      >
        {openTeams.map((team) => {
          return (
            <option key={team.teamID} value={team.teamID}>
              {team.teamName}
            </option>
          );
        })}
      </select>
      <p className="dashOpensInputTitle">Date</p>
      <input
        id="addShiftDateInput"
        min={dateStringer.createDateInputFromTimestamp(new Date().getTime())}
        max={dateStringer.createDateInputFromDateString(maxDs)}
        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
        type="date"
        className={`dashOpenInputDateInput ${
          !medium ? "dashOpenInputDateInputNonMed" : ""
        } ${!medium && !mob ? "dashOpenInputDateInputFull" : ""}`}
        value={openDateInput}
        onChange={(e) => {
          setOpenDateInput(e.target.value);
        }}
      ></input>

      {openDateInput && openDateInput.length === 10 && openTeamID && (
        <p
          className="addOpenLaunchBtn blueColours"
          onClick={() => {
            axios
              .post(
                `${serverURL}/schedule-new-shift`,
                {
                  teamID: openTeamID,
                  ds: dateStringer.createStringFromDateInput(openDateInput),
                  userID: "",
                  type: "open",
                  nowDs: dateStringer.createStringFromTimestamp(
                    new Date().getTime()
                  ),
                },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message.includes("logout")) {
                  window.location.href = "/signin";
                }
                if (response.data.message === "success") {
                  setToggleAddOpen(false);
                  setShowOpenShiftModal({
                    ds: dateStringer.createStringFromDateInput(openDateInput),
                    new: true,
                    openID: response.data.shiftID,
                  });
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          Create open shift
        </p>
      )}
    </div>
  ) : (
    <div
      className={`addStaffLeaveBtn lilacColours addStaffLeaveBtnDash addOpenDashBtn ${
        window.innerWidth < 975 && medium ? "addOpenDashBtnSmaller" : ""
      } ${!medium ? "addOpenDashBtnNonMed" : ""} ${opensLoading ? "none" : ""}`}
      onClick={() => {
        setToggleAddOpen(true);
      }}
    >
      Add open shift{" "}
      <img
        src={whiteAddThin}
        alt="Add form"
        className={`whiteAddFormImg ${
          window.innerWidth < 975 && medium ? "x2904393" : ""
        }`}
      />
    </div>
  );

  // master return
  return (
    <div className={`dashAllowancesContainer dashAllowancesContainerDash`}>
      {(mob || medium) && (
        <div
          className={`dashAllowsTopBar  ${
            mediumPage === 1 ? "x232982111" : "x232982111"
          }`}
        >
          {" "}
          {medium ? (
            <div className={`dashAllowTilSelector`}>
              <div
                className={`dashAllowTilBtn ${
                  mediumPage === 1 ? "dashAllowTilBtnSel" : ""
                }`}
                onClick={() => {
                  setMediumPage(1);
                }}
              >
                <p>Shifts and leave</p>
                <div
                  className={`dashAllowTilBtnBar ${
                    mediumPage !== 1 ? "invis" : ""
                  }`}
                ></div>
              </div>
              <div className="subHeadSplit2"></div>
              <div
                className={`dashAllowTilBtn ${
                  mediumPage === 2 ? "dashAllowTilBtnSel" : ""
                }`}
                onClick={() => {
                  setMediumPage(2);
                }}
              >
                {" "}
                <p>Outstanding and wages</p>
                <div
                  className={`dashAllowTilBtnBar ${
                    mediumPage !== 2 ? "invis" : ""
                  }`}
                ></div>
              </div>{" "}
            </div>
          ) : (
            <div className="dashAllowTilSelector">
              <div
                className={`dashAllowTilBtn ${
                  page === "today" ? "dashAllowTilBtnSel" : ""
                }`}
                onClick={() => {
                  setPage("today");
                }}
              >
                <p>Shifts</p>
                <div
                  className={`dashAllowTilBtnBar ${
                    page !== "today" ? "invis" : ""
                  }`}
                ></div>
              </div>
              <div className="subHeadSplit2"></div>
              <div
                className={`dashAllowTilBtn ${
                  page === "leave" ? "dashAllowTilBtnSel" : ""
                }`}
                onClick={() => {
                  setPage("leave");
                }}
              >
                {" "}
                <p>On leave</p>
                <div
                  className={`dashAllowTilBtnBar ${
                    page !== "leave" ? "invis" : ""
                  }`}
                ></div>
              </div>{" "}
              <div className="subHeadSplit2"></div>
              <div
                className={`dashAllowTilBtn ${
                  page === "amendments" ? "dashAllowTilBtnSel" : ""
                }`}
                onClick={() => {
                  setPage("amendments");
                }}
              >
                {" "}
                <p>Outstanding</p>
                <div
                  className={`dashAllowTilBtnBar ${
                    page !== "amendments" ? "invis" : ""
                  }`}
                ></div>
              </div>{" "}
              <div className="subHeadSplit2"></div>
              <div
                className={`dashAllowTilBtn ${
                  page === "spend" ? "dashAllowTilBtnSel" : ""
                }`}
                onClick={() => {
                  setPage("spend");
                }}
              >
                {" "}
                <p>Wages</p>
                <div
                  className={`dashAllowTilBtnBar ${
                    page !== "spend" ? "invis" : ""
                  }`}
                ></div>
              </div>
            </div>
          )}
          <div className="dashAllowTopRight"></div>
        </div>
      )}

      <div className="dashWindowsContainer">
        {((mob && page === "today") ||
          (medium && mediumPage === 1) ||
          (!medium && !mob)) && (
          <div
            className={`dashWindowColumn30 ${
              mob && !medium ? "dashWindowColumn30Mob" : ""
            } ${medium ? "dashWindowColumn30Medium" : ""}`}
          >
            <div
              className={`dashWindowTitleParent x238929898111 ${
                mob ? "dashWindowTitleParentMob" : ""
              }`}
            >
              {" "}
              <p
                className={`dashWindowTitle x238298211 ${
                  mob ? "dashWindowTitleMob" : ""
                }`}
              >
                {shiftsDs ===
                dateStringer.createStringFromTimestamp(tomorrow00.getTime())
                  ? `Tomorrow's shifts`
                  : shiftsDs ===
                    dateStringer.createStringFromTimestamp(
                      yesterday00.getTime()
                    )
                  ? `Yesterday's shifts`
                  : shiftsDs ===
                    dateStringer.createStringFromTimestamp(today00.getTime())
                  ? "Today's shifts"
                  : `${dateStringer.printedDateFromDs(shiftsDs)}`}
              </p>
              <div
                className={`dashWindowArrs ${mob ? "dashWindowArrsMob" : ""}`}
              >
                <img
                  src={allowArr}
                  alt="Yesterday"
                  className={`dashWindowArrLeft ${
                    mob ? "dashWindowArrLeftMob" : ""
                  } ${todayShiftsLoading ? "disableDashArr" : ""}`}
                  onClick={() => {
                    if (!todayShiftsLoading) {
                      setTodayShiftsLoading(true);
                    }
                    let dateObj = new Date(
                      dateStringer.createTimestampFromString(shiftsDs)
                    );
                    dateObj.setDate(dateObj.getDate() - 1);
                    setShiftsDs(
                      dateStringer.createStringFromTimestamp(dateObj.getTime())
                    );
                  }}
                />
                <img
                  onClick={() => {
                    if (!todayShiftsLoading) {
                      setTodayShiftsLoading(true);
                    }
                    let dateObj = new Date(
                      dateStringer.createTimestampFromString(shiftsDs)
                    );
                    dateObj.setDate(dateObj.getDate() + 1);
                    setShiftsDs(
                      dateStringer.createStringFromTimestamp(dateObj.getTime())
                    );
                  }}
                  src={allowArr}
                  alt="Yesterday"
                  className={`dashWindowArrLeft dashWindowArrRight ${
                    todayShiftsLoading ? "disableDashArr" : ""
                  } ${mob ? "dashWindowArrLeftMob" : ""}`}
                />
              </div>
            </div>
            <div
              className={`dashWindowScrollBox ${
                mob && !medium ? "dashWindowScrollBoxMob" : ""
              } ${medium ? "dashWindowScrollBoxMedium" : ""} ${
                mob || medium ? "minW100" : "minWidth260"
              } ${device.ios ? "dashWindowScrollBoxMobIos" : ""}`}
            >
              {todayShiftsLoading ? (
                <img
                  src={horizontalBalls}
                  alt="Loading"
                  className="todayshIFTSloadingImg"
                />
              ) : todayShifts.length === 0 ? (
                <p className="nilTodayShifts">No activity</p>
              ) : (
                todayShifts.map((item, i) => {
                  let noBord = false;
                  let finFilt = todayShifts.filter((x) => {
                    return x.type === "finishedTitle";
                  });

                  let lengthOfArr = todayShifts.length;
                  if (finFilt[0]) {
                    lengthOfArr--;
                  }
                  if (i === lengthOfArr - 1) {
                    noBord = true;
                  }
                  if (item.type === "finishedTitle") {
                    return (
                      <div className="finishedDashTitle">
                        Finished{" "}
                        <img
                          src={downArr516b8b}
                          alt="Finished"
                          className="finDashArr"
                        />
                      </div>
                    );
                  }
                  if (
                    item.type === "shift" ||
                    item.type === "til" ||
                    item.type === "overtime"
                  ) {
                    return (
                      <div
                        key={i}
                        className={`dashTodayFrag ${noBord ? "noBorder" : ""}`}
                      >
                        <p className="dashTodaySchedShTxt">
                          Scheduled{" "}
                          {item.type === "til" ? "shift in lieu" : item.type}
                        </p>
                        <div
                          className={`dashTodayShiftItem ${
                            item.selfLoggedAbsence ? "x328932982981" : ""
                          } ${medium ? "X18198991" : mob ? "x2393298292" : ""}`}
                          onClick={() => {
                            setShowEditShiftModal({
                              itemID: item.shiftID || item.tilID,
                              itemType: item.type,
                              itemFName: item.fName,
                              itemLName: item.lName,
                              itemIsNew: false,
                              userID: item.userID,
                            });
                          }}
                        >
                          <div className="dashTodayShiftLeft">
                            <img
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowEditUser(item.userID);
                              }}
                              src={item.profPicUrl || profilePicturePlaceholder}
                              alt={item.fName}
                              className="dashTodayShiftPicImg"
                            />
                          </div>
                          <div className="dashTodayShiftRight">
                            <p
                              className={`dashTodayTimeStrip ${
                                mob || medium ? "dashTodayTimeStripMob" : ""
                              }  ${
                                item.scheduledNow &&
                                (!item.acceptRequired ||
                                  (item.acceptRequired && item.accepted))
                                  ? "dashTodayTimeStripGreen"
                                  : ""
                              } ${item.past ? "colorNavy" : ""} ${
                                item.absent || item.selfLoggedAbsence
                                  ? "dashTodayTimeStripAbsent"
                                  : ""
                              }`}
                            >
                              <img
                                src={
                                  item.absent || item.selfLoggedAbsence
                                    ? clockRed
                                    : item.scheduledNow &&
                                      (!item.acceptRequired ||
                                        (item.acceptRequired && item.accepted))
                                    ? clockGreen
                                    : item.past
                                    ? clockGrey
                                    : clockLightBlue
                                }
                                alt="Times"
                                className="dashClockImg"
                              />
                              {dateStringer.dsToTimeStrip(item.startDs, true)} -{" "}
                              {dateStringer.dsToTimeStrip(item.endDs, true)}{" "}
                              {item.acceptRequired &&
                              item.accepted &&
                              !item.absent ? (
                                <img
                                  src={doubleTick}
                                  alt="Accepted"
                                  className="dashAcceptedIcon"
                                />
                              ) : (
                                ""
                              )}
                              {item.acceptRequired &&
                              !item.accepted &&
                              !item.absent ? (
                                <img
                                  src={lightBlueQuestionMark}
                                  alt="Accepted"
                                  className="dashNotAcceptedIcon"
                                />
                              ) : (
                                ""
                              )}
                            </p>
                            <p className="dashTodayTeamName">
                              {dateStringer.shorten(item.teamName, 23)}
                            </p>
                          </div>
                          <div
                            className={`dashShiftTongue ${
                              item.selfLoggedAbsence ? "dashShiftTongueAbs" : ""
                            }`}
                          >
                            {item.type === "til"
                              ? "Lieu shift"
                              : item.type === "overtime"
                              ? "Overtime"
                              : "Shift"}
                          </div>
                        </div>
                        <div className="dashTodayUnder">
                          <p
                            className={`dashTodayName ${
                              item.selfLoggedAbsence
                                ? "ba0000 borderBa0000"
                                : ""
                            } ${mob || medium ? "dashTodayNameMob" : ""}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowEditUser(item.userID);
                            }}
                          >
                            {item.fName}{" "}
                            {formatSurname(
                              `${item.fName} ${item.lName}`,
                              item.lName
                            )}
                          </p>{" "}
                          {!item.clockedEndDs &&
                            item.startTs >= today00.getTime() && (
                              <p
                                className={`dashTodayType ${
                                  !item.clockedStart ? "bbcada" : ""
                                } ${
                                  medium
                                    ? "dashTodayTypeMedium"
                                    : mob
                                    ? "dashTodayTypeMob"
                                    : ""
                                } ${
                                  item.past &&
                                  item.clockOnRequired &&
                                  !item.clockedStart
                                    ? "colourRed"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (item.clockOnID) {
                                    if (item.clockedEndDs) {
                                      setClockOnID({
                                        clockOnID: item.clockOnID,
                                        isMgr: true,
                                      });
                                    } else {
                                      setShowActiveClocks(true);
                                    }
                                  } else {
                                    setShowEditShiftModal({
                                      itemID: item.shiftID || item.tilID,
                                      itemType: item.type,
                                      itemFName: item.fName,
                                      itemLName: item.lName,
                                      itemIsNew: false,
                                      userID: item.userID,
                                    });
                                  }
                                }}
                              >
                                {item.absent && !item.partialAbsent ? (
                                  "Absent"
                                ) : item.acceptRequired && !item.accepted ? (
                                  "Not yet accepted"
                                ) : item.clockedEndDs ? (
                                  ""
                                ) : item.clockOnID && item.clockedStart ? (
                                  <>
                                    Clocked {item.clockedEndDs ? "off" : "on"}:{" "}
                                    <span
                                      className={
                                        item.clockedLate && !item.clockedEndDs
                                          ? "ba0000 x2389389244"
                                          : "x2389389244"
                                      }
                                    >
                                      {dateStringer.dsToTimeStrip(
                                        item.clockedEndDs
                                          ? item.clockedEndDs
                                          : item.clockedStart,
                                        true
                                      )}
                                    </span>
                                  </>
                                ) : item.startTs < today00.getTime() ? (
                                  "-"
                                ) : item.startDs.substr(0, 11) ===
                                  dateStringer
                                    .createStringFromTimestamp(today00)
                                    .substr(0, 11) ? (
                                  "Awaiting clock on"
                                ) : item.clockOnRequired &&
                                  !item.clockedStart ? (
                                  item.past ? (
                                    "Did not clock on"
                                  ) : (
                                    "Awaiting clock on"
                                  )
                                ) : (
                                  "Scheduled"
                                )}
                              </p>
                            )}
                        </div>
                        {item.selfLoggedAbsence && (
                          <div className="dashTodayUnderClockLine redSelfLogAbsLine"></div>
                        )}
                        {item.selfLoggedAbsence && (
                          <div
                            onClick={() => {
                              setShowEditShiftModal({
                                itemID: item.shiftID || item.tilID,
                                itemType: item.type,
                                itemFName: item.fName,
                                itemLName: item.lName,
                                itemIsNew: false,
                                userID: item.userID,
                              });
                            }}
                            className={`dashTodayUnderClock redBorder`}
                          >
                            <div className="selfLogAbsUpperDash">
                              <img
                                src={redWarning}
                                alt="Self logged absence"
                                className="selfLoggedAbswarnImg"
                              />
                              <p className="dashSelflogg">
                                {item.fName} has marked themselves as absent
                              </p>
                            </div>
                            {item.absReason && (
                              <div className="selfLoggAbsReasonDash">
                                {item.absReason}
                              </div>
                            )}
                            <p className="selfLogAbsDashCta">
                              Requires manager action
                            </p>
                          </div>
                        )}
                        {item.clockOnID && (
                          <div className="dashTodayUnderClockLine"></div>
                        )}
                        {item.clockOnID && (
                          <div
                            onClick={() => {
                              if (item.clockedEndDs) {
                                setClockOnID({
                                  clockOnID: item.clockOnID,
                                  isMgr: true,
                                });
                              } else {
                                setShowActiveClocks(true);
                              }
                            }}
                            className={`dashTodayUnderClock ${
                              item.clockedEndDs
                                ? "dashTodayUnderClockEnded"
                                : "dashTodayUnderClockOpen"
                            }`}
                          >
                            <div className="dashCCleft">
                              <p className="dashCCtitle">Clocked on </p>
                              <p className="dashCCvalue">
                                {dateStringer.dsToTimeStrip(
                                  item.clockedStart,
                                  true
                                )}
                              </p>
                            </div>
                            <div className="dashCCcenterline"></div>
                            <div className="dashCCRight">
                              <p className="dashCCtitle">Clocked off </p>
                              <p className="dashCCvalue">
                                {!item.clockedEndDs
                                  ? "-"
                                  : dateStringer.dsToTimeStrip(
                                      item.clockedEndDs,
                                      true
                                    )}
                              </p>
                              <p className="dashCCoffSetDay">
                                {offset(item.clockedStart, item.clockedEndDs)}
                              </p>
                            </div>
                          </div>
                        )}
                        {(item.b1secs > 0 ||
                          item.b2secs > 0 ||
                          item.b3secs > 0) && (
                          <div className="dashTodayUnderClockLine"></div>
                        )}
                        {(item.b1secs > 0 ||
                          item.b2secs > 0 ||
                          item.b3secs > 0) && (
                          <div className="dashTodayCCbreakRow">
                            {item.b1secs > 0 && (
                              <div
                                className="dashTodayCCbreakRowItem"
                                onClick={() => {
                                  if (item.clockedEndDs) {
                                    setClockOnID({
                                      clockOnID: item.clockOnID,
                                      isMgr: true,
                                    });
                                  } else {
                                    setShowActiveClocks(true);
                                  }
                                }}
                              >
                                <p className="dashTodayCCbrkTitle">Break 1</p>
                                <p className="dashTodayCCbrkValue">
                                  {dateStringer.formatMinsDurationToHours(
                                    item.b1secs / 60
                                  )}
                                </p>
                              </div>
                            )}
                            {item.b2secs > 0 && (
                              <div className="dashTodayBrkDiv"></div>
                            )}

                            {item.b2secs > 0 && (
                              <div
                                className="dashTodayCCbreakRowItem"
                                onClick={() => {
                                  if (item.clockedEndDs) {
                                    setClockOnID({
                                      clockOnID: item.clockOnID,
                                      isMgr: true,
                                    });
                                  } else {
                                    setShowActiveClocks(true);
                                  }
                                }}
                              >
                                <p className="dashTodayCCbrkTitle">Break 2</p>
                                <p className="dashTodayCCbrkValue">
                                  {dateStringer.formatMinsDurationToHours(
                                    item.b2secs / 60
                                  )}
                                </p>
                              </div>
                            )}
                            {item.b3secs > 0 && (
                              <div className="dashTodayBrkDiv"></div>
                            )}
                            {item.b3secs > 0 && (
                              <div
                                className="dashTodayCCbreakRowItem"
                                onClick={() => {
                                  if (item.clockedEndDs) {
                                    setClockOnID({
                                      clockOnID: item.clockOnID,
                                      isMgr: true,
                                    });
                                  } else {
                                    setShowActiveClocks(true);
                                  }
                                }}
                              >
                                <p className="dashTodayCCbrkTitle">Break 3</p>
                                <p className="dashTodayCCbrkValue">
                                  {dateStringer.formatMinsDurationToHours(
                                    item.b3secs / 60
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  }
                  if (item.type === "clockCard") {
                    return (
                      <div
                        key={i}
                        className={`dashTodayFrag ${noBord ? "noBorder" : ""}`}
                      >
                        <p className="dashTodaySchedShTxt">
                          Unscheduled clock on
                        </p>
                        <div
                          className="dashTodalClockCardUser"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <img
                            src={item.profPicUrl || profilePicturePlaceholder}
                            alt={item.fName}
                            className="dashTodayShiftPicImg"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowEditUser(item.userID);
                            }}
                          />{" "}
                          <div
                            className={`dashTodayCCname ${
                              mob || medium ? "dashTodayCCnameMob" : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowEditUser(item.userID);
                            }}
                          >
                            {item.fName} {item.lName}
                            <br />
                            <span className="dashTodayCCteamname">
                              {item.teamName}
                            </span>
                          </div>
                        </div>
                        <div className="dashTodayUnderClockLine"></div>
                        <div
                          onClick={() => {
                            if (item.endDs) {
                              setClockOnID({
                                clockOnID: item.clockOnID,
                                isMgr: true,
                              });
                            } else {
                              setShowActiveClocks(true);
                            }
                          }}
                          className={`dashTodayUnderClock ${
                            item.endDs
                              ? "dashTodayUnderClockEnded"
                              : "dashTodayUnderClockOpen"
                          }`}
                        >
                          <div className="dashCCleft">
                            <p className="dashCCtitle">Clocked on </p>
                            <p className="dashCCvalue">
                              {dateStringer.dsToTimeStrip(item.startDs, true)}
                            </p>
                          </div>
                          <div className="dashCCcenterline"></div>
                          <div className="dashCCRight">
                            <p className="dashCCtitle">Clocked off </p>
                            <p className="dashCCvalue">
                              {!item.endDs
                                ? "-"
                                : dateStringer.dsToTimeStrip(item.endDs, true)}
                            </p>
                            <p className="dashCCoffSetDay">
                              {offset(item.startDs, item.endDs)}
                            </p>
                          </div>
                        </div>
                        {(item.b1secs > 0 ||
                          item.b2secs > 0 ||
                          item.b3secs > 0) && (
                          <div className="dashTodayUnderClockLine"></div>
                        )}
                        {(item.b1secs > 0 ||
                          item.b2secs > 0 ||
                          item.b3secs > 0) && (
                          <div className="dashTodayCCbreakRow">
                            {item.b1secs > 0 && (
                              <div
                                className="dashTodayCCbreakRowItem"
                                onClick={() => {
                                  if (item.endDs) {
                                    setClockOnID({
                                      clockOnID: item.clockOnID,
                                      isMgr: true,
                                    });
                                  } else {
                                    setShowActiveClocks(true);
                                  }
                                }}
                              >
                                <p className="dashTodayCCbrkTitle">Break 1</p>
                                <p className="dashTodayCCbrkValue">
                                  {dateStringer.formatMinsDurationToHours(
                                    item.b1secs / 60
                                  )}
                                </p>
                              </div>
                            )}
                            {item.b2secs > 0 && (
                              <div className="dashTodayBrkDiv"></div>
                            )}

                            {item.b2secs > 0 && (
                              <div
                                className="dashTodayCCbreakRowItem"
                                onClick={() => {
                                  if (item.endDs) {
                                    setClockOnID({
                                      clockOnID: item.clockOnID,
                                      isMgr: true,
                                    });
                                  } else {
                                    setShowActiveClocks(true);
                                  }
                                }}
                              >
                                <p className="dashTodayCCbrkTitle">Break 2</p>
                                <p className="dashTodayCCbrkValue">
                                  {dateStringer.formatMinsDurationToHours(
                                    item.b2secs / 60
                                  )}
                                </p>
                              </div>
                            )}
                            {item.b3secs > 0 && (
                              <div className="dashTodayBrkDiv"></div>
                            )}
                            {item.b3secs > 0 && (
                              <div
                                className="dashTodayCCbreakRowItem"
                                onClick={() => {
                                  if (item.endDs) {
                                    setClockOnID({
                                      clockOnID: item.clockOnID,
                                      isMgr: true,
                                    });
                                  } else {
                                    setShowActiveClocks(true);
                                  }
                                }}
                              >
                                <p className="dashTodayCCbrkTitle">Break 3</p>
                                <p className="dashTodayCCbrkValue">
                                  {dateStringer.formatMinsDurationToHours(
                                    item.b3secs / 60
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  }
                })
              )}
              {!todayShiftsLoading && (
                <div
                  className={`addStaffLeaveBtn lilacColours addStaffLeaveBtnDash ${
                    todayShifts.length === 0 ? "noDashLeaveAddLeaveBtn" : ""
                  }`}
                  onClick={() => {
                    setShowAddShift(true);
                  }}
                >
                  Add shift{" "}
                  <img
                    src={whiteAddThin}
                    alt="Add form"
                    className="whiteAddFormImg"
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {((mob && page === "leave") ||
          (medium && mediumPage === 1) ||
          (!medium && !mob)) && (
          <div
            className={`dashWindowColumn30 ${
              mob && !medium ? "dashWindowColumn30Mob" : ""
            } ${medium ? "dashWindowColumn30Medium" : ""}`}
          >
            <div
              className={`dashWindowTitleParent x238929898111 ${
                mob ? "dashWindowTitleParentMob" : ""
              }`}
            >
              {" "}
              <p
                className={`dashWindowTitle x238298211 ${
                  mob ? "dashWindowTitleMob" : ""
                }`}
              >
                {leaveDs ===
                dateStringer.createStringFromTimestamp(tomorrow00.getTime())
                  ? `Tomorrow's leave`
                  : leaveDs ===
                    dateStringer.createStringFromTimestamp(
                      yesterday00.getTime()
                    )
                  ? `Yesterday's leave`
                  : leaveDs ===
                    dateStringer.createStringFromTimestamp(today00.getTime())
                  ? "On leave today"
                  : `${dateStringer.printedDateFromDs(leaveDs)}`}
              </p>
              <div
                className={`dashWindowArrs ${mob ? "dashWindowArrsMob" : ""}`}
              >
                <img
                  src={allowArr}
                  alt="Yesterday"
                  className={`dashWindowArrLeft ${
                    mob ? "dashWindowArrLeftMob" : ""
                  } ${onLeaveLoading ? "disableDashArr" : ""}`}
                  onClick={() => {
                    if (!onLeaveLoading) {
                      setOnLeaveLoading(true);
                    }
                    let dateObj = new Date(
                      dateStringer.createTimestampFromString(leaveDs)
                    );
                    dateObj.setDate(dateObj.getDate() - 1);
                    setLeaveDs(
                      dateStringer.createStringFromTimestamp(dateObj.getTime())
                    );
                  }}
                />
                <img
                  onClick={() => {
                    if (!onLeaveLoading) {
                      setOnLeaveLoading(true);
                    }
                    let dateObj = new Date(
                      dateStringer.createTimestampFromString(leaveDs)
                    );
                    dateObj.setDate(dateObj.getDate() + 1);
                    setLeaveDs(
                      dateStringer.createStringFromTimestamp(dateObj.getTime())
                    );
                  }}
                  src={allowArr}
                  alt="Yesterday"
                  className={`dashWindowArrLeft dashWindowArrRight ${
                    onLeaveLoading ? "disableDashArr" : ""
                  } ${mob ? "dashWindowArrLeftMob" : ""}`}
                />
              </div>
            </div>
            <div
              className={`dashWindowScrollBox ${
                mob && !medium ? "dashWindowScrollBoxMob" : ""
              } ${medium ? "dashWindowScrollBoxMedium" : ""} ${
                mob || medium ? "minW100" : "minWidth260"
              } ${device.ios ? "dashWindowScrollBoxMobIos" : ""}`}
            >
              {onLeaveLoading ? (
                <img
                  src={horizontalBalls}
                  alt="Loading"
                  className="todayshIFTSloadingImg"
                />
              ) : onLeave.length === 0 ? (
                <p className="nilTodayShifts">No scheduled leave</p>
              ) : (
                onLeave.map((item, i) => {
                  return (
                    <div
                      className={`dashTodayFrag ${
                        i === onLeave.length - 1 ? "noBorder" : ""
                      }`}
                      key={i}
                    >
                      <div
                        className={`dashTodayShiftItem  ${
                          medium ? "X18198991" : mob ? "x2393298292" : ""
                        }`}
                        onClick={() => {
                          setShowReqMgrModal(item.reqID);
                        }}
                      >
                        <div className="dashTodayShiftLeft">
                          <img
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowEditUser(item.userID);
                            }}
                            src={item.profPicUrl || profilePicturePlaceholder}
                            alt={item.fName}
                            className="dashTodayShiftPicImg"
                          />
                        </div>
                        <div className="dashTodayShiftRight">
                          <p
                            className={`dashTodayTimeStrip ${
                              mob || medium ? "dashTodayTimeStripMob" : ""
                            } ${
                              item.annLeave
                                ? "dashTodayTimeStripGreen"
                                : "colorNavy"
                            }`}
                          >
                            <img
                              src={
                                item.annLeave
                                  ? planeGreen
                                  : item.isAbsence
                                  ? cross516b8b
                                  : paperPlane516b8b
                              }
                              alt="Times"
                              className={`dashClockImg ${
                                item.annLeave
                                  ? "dashClockImgAnnLeave"
                                  : item.isAbsence
                                  ? "dashClockImgAbs"
                                  : "dashClockImgCustom"
                              }`}
                            />
                            {item.leaveTypeName}
                          </p>
                          <p className="dashTodayTeamName dashTodayTeamNameLeave dashDayStatus">
                            {/* {dateStringer.shorten(item.jt, 43)} Day 4 /
                            8 */}
                            {item.inactiveDay
                              ? "Non-working day"
                              : `Day ${item.day}`}
                          </p>
                        </div>
                        <div
                          className={`dashShiftTongue ${
                            item.isAbsence
                              ? "dashLeaveTongueAbs"
                              : "dashLeaveTongue"
                          }`}
                        >
                          {item.isAbsence ? "Absence" : "Leave"}
                        </div>
                      </div>

                      <div className="dashTodayUnder">
                        <p
                          className={`dashTodayName ${
                            mob || medium ? "dashTodayNameMob" : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowEditUser(item.userID);
                          }}
                        >
                          {item.fName}{" "}
                          {formatSurname(
                            `${item.fName} ${item.lName}`,
                            item.lName
                          )}
                        </p>{" "}
                        <p
                          className={`dashTodayType  ${
                            medium
                              ? "dashTodayTypeMedium"
                              : mob
                              ? "dashTodayTypeMob"
                              : ""
                          } ${item.inactiveDay ? "invis" : ""}`}
                          onClick={() => {
                            setShowReqMgrModal(item.reqID);
                          }}
                        >
                          {dateStringer.formatMinsDurationToHours(item.durMins)}
                        </p>
                      </div>
                      {item.firstHalfOff ||
                        (item.lastHalfOff && (
                          <div className="dashTodayUnder dashTodayUnderLeave">
                            <div className="dashTodayUnderLeaveLeft">
                              {" "}
                              {/* <div className="dashTodayUnderLeaveLeftBar"></div>{" "} */}
                              {/* <p
                                className={`dashTodayType  ${
                                  medium
                                    ? "dashTodayTypeMedium"
                                    : mob
                                    ? "dashTodayTypeMob"
                                    : ""
                                }`}
                                onClick={() => {
                                  setShowReqMgrModal(item.reqID);
                                }}
                              >
                                Day {item.day}
                              </p> */}
                              <p></p>
                            </div>
                            <div className="dashTodayUnderLeaveRight">
                              {" "}
                              <div className="dashTodayUnderLeaveRightBar"></div>{" "}
                              <p
                                className={`dashTodayType  ${
                                  medium
                                    ? "dashTodayTypeMedium"
                                    : mob
                                    ? "dashTodayTypeMob"
                                    : ""
                                } colour0af`}
                                onClick={() => {
                                  setShowReqMgrModal(item.reqID);
                                }}
                              >
                                {item.firstHalfOff
                                  ? "Last half off"
                                  : item.lastHalfOff
                                  ? "First half off"
                                  : ""}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  );
                })
              )}
              {!onLeaveLoading && (
                <div
                  className={`addStaffLeaveBtn lilacColours addStaffLeaveBtnDash ${
                    onLeave.length === 0 ? "noDashLeaveAddLeaveBtn" : ""
                  }`}
                  onClick={() => {
                    setShowAddLeaveOnTeam(leaveDs);
                  }}
                >
                  Add leave{" "}
                  <img
                    src={whiteAddThin}
                    alt="Add form"
                    className="whiteAddFormImg"
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {((mob && page === "amendments") ||
          (medium && mediumPage === 2) ||
          (!medium && full)) && (
          <div
            className={`dashWindowColumn20  ${
              medium ? "dashWindowColumn30Medium" : ""
            } ${!medium && !mob ? "dashWindowColumn20FullOutstanding" : ""} ${
              mob && !medium ? "dashWindowColumn30Mob x99101111" : ""
            } x282999 ${!mob ? "reverseOutstanding" : ""}`}
          >
            <div className={`dashOutUpper" ${mob ? "dashOutUpperMob" : ""}`}>
              {" "}
              {/* <p className="dashWindowTitle">Outstanding</p>{" "} */}
              <div
                className={`dashOutRow ${mob ? "dashOutRowMob" : ""}`}
                onClick={() => {
                  setShowTeamSwapsModal(true);
                }}
              >
                <p
                  className={`dashOutRowTitle ${
                    mob ? "dashOutRowTitleMob" : ""
                  }`}
                >
                  <img
                    src={dashSwap}
                    alt="Swap"
                    className="dashSwapImgInline"
                  />
                  Shift swaps
                </p>
                <p className="dashOutRowValue">{swaps}</p>
              </div>
              <NavLink
                className={`dashOutRow ${mob ? "dashOutRowMob" : ""}`}
                to="/team-hours?requests=true"
              >
                <p
                  className={`dashOutRowTitle ${
                    mob ? "dashOutRowTitleMob" : ""
                  }`}
                >
                  <img
                    src={dashToil}
                    alt="Swap"
                    className="dashSwapImgInlineToil"
                  />
                  TOIL requests
                </p>
                <p className="dashOutRowValue">{tilReqs}</p>
              </NavLink>
              <NavLink
                className={`dashOutRow ${mob ? "dashOutRowMob" : ""}`}
                to="/team-hours?requests=true"
              >
                <p
                  className={`dashOutRowTitle ${
                    mob ? "dashOutRowTitleMob" : ""
                  }`}
                >
                  <img
                    src={dashChange}
                    alt="Swap"
                    className="dashSwapImgInline"
                  />
                  Shift change requests
                </p>
                <p className="dashOutRowValue">{amendReqs}</p>
              </NavLink>
            </div>
            <div
              className={`dashOutLower ${
                mob && !medium ? "dashOutLowerMob" : ""
              } ${!mob && medium ? "dashOutLowerMedium" : ""} ${
                !mob ? "dashOutLowerMediumNonMob" : ""
              }`}
            >
              <p
                className={`dashOpensTitle ${
                  mob && !medium ? "dashOpensTitleMob" : ""
                }`}
              >
                Open shifts
              </p>
              <div
                className={
                  medium
                    ? "dashOpensParentMedium"
                    : mob
                    ? `dashOpensParentMobFull ${
                        device.ios ? "dashOpensParentMobFullIos" : ""
                      }`
                    : ""
                }
              >
                <div
                  className={`dashOpenShiftsScrollBox ${
                    mob
                      ? "dashOpenShiftsScrollBoxMob"
                      : medium
                      ? "dashOpenShiftsScrollBoxMedium"
                      : "dashOpenShiftsScrollBoxDesktop"
                  } `}
                >
                  {opensLoading ? (
                    <img
                      src={horizontalBalls}
                      alt="Loading"
                      className="todayshIFTSloadingImg"
                    />
                  ) : (
                    opens.map((open, i) => {
                      return (
                        <div
                          className={`dashOpenFrag ${
                            i === opens.length - 1 ? "noBorder" : ""
                          } ${mob ? "dashOpenFragMob" : ""}`}
                          onClick={() => {
                            setShowOpenShiftModal({
                              ds: open.startDs,
                              new: false,
                              openID: open.openID,
                              teamID: open.teamID,
                            });
                          }}
                        >
                          <div
                            className={`dashOpenItem ${
                              mob || medium ? "dashOpenItemMob" : ""
                            }`}
                            key={open.openID}
                          >
                            <div
                              className={`dashOpenLeft ${
                                mob ? "dashOpenLeftMob" : ""
                              }`}
                            >
                              <div className="dashOpenBids">
                                <img
                                  src={manGreen}
                                  alt="Bids"
                                  className={
                                    open.bids
                                      ? "manGreendashOpen"
                                      : "manGreendashOpen greyscale"
                                  }
                                />
                                {open.bids}
                              </div>

                              <p className="dashOpenIcon">OPEN</p>
                              <p className="dashOpenIcon dashOpenIconType">
                                {open.type === "til"
                                  ? "lieu"
                                  : open.type === "overtime"
                                  ? "over"
                                  : "shift"}
                              </p>
                            </div>

                            <div
                              className={`dashOpenRight ${
                                mob ? "dashOpenRightMob" : ""
                              }`}
                            >
                              <p
                                className={`dashOpenDs ${
                                  mob ? "dashOpenDsMob" : ""
                                }`}
                              >
                                {open.startDs.substr(0, 11) ===
                                dateStringer
                                  .createStringFromTimestamp(
                                    tomorrow00.getTime()
                                  )
                                  .substr(0, 11)
                                  ? "Tomorrow"
                                  : open.startDs.substr(0, 11) ===
                                    dateStringer
                                      .createStringFromTimestamp(
                                        today00.getTime()
                                      )
                                      .substr(0, 11)
                                  ? "Today"
                                  : open.startDs.substr(0, 11) ===
                                    dateStringer
                                      .createStringFromTimestamp(
                                        yesterday00.getTime()
                                      )
                                      .substr(0, 11)
                                  ? "Yesterday"
                                  : dateStringer.printedDateFromDs(
                                      open.startDs
                                    )}
                                <img
                                  src={openShiftBlock}
                                  alt="Open shift"
                                  className="dashOpenShBlockIcon"
                                />
                              </p>
                              <div
                                className={`dashOpenRightTopRow ${
                                  mob || medium ? "dashOpenRightTopRowMob" : ""
                                }`}
                              >
                                {dateStringer.dsToTimeStrip(open.startDs, true)}{" "}
                                - {dateStringer.dsToTimeStrip(open.endDs, true)}{" "}
                              </div>
                              <div className="dashOpenRightBottomRow">
                                {open.teamName}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`dashOpenUnder ${
                              mob || medium ? "dashOpenUnderMob" : ""
                            }`}
                          >
                            <p
                              className={`dashOpenRate ${
                                open.type === "til" ? "invis" : ""
                              }`}
                            >
                              {open.rate}x
                            </p>
                            {open.tags && open.tags[0] ? (
                              <div className="dashOpenTagsArr">
                                {open.tags.length === 1 ? (
                                  <div className="dashTagInlineOpen">
                                    {dateStringer.shorten(open.tags[0], 19)}
                                  </div>
                                ) : (
                                  <div className="multiDashOpen">
                                    <div className="dashTagInlineOpen">
                                      +{open.tags.length - 1}
                                    </div>{" "}
                                    <div className="dashTagInlineOpen">
                                      {dateStringer.shorten(open.tags[0], 14)}
                                    </div>{" "}
                                  </div>
                                )}

                                {/* {open.tags.map((x, ii) => {
                              return (
                                <div key={ii} className="dashTagInlineOpen">
                                  {dateStringer.shorten(x, 14)}
                                </div>
                              );
                            })} */}
                              </div>
                            ) : (
                              <p></p>
                            )}
                          </div>
                        </div>
                      );
                    })
                  )}
                  {!medium && openAddContent}
                </div>
                {medium && (
                  <div className="dashOpensAddOpen">{openAddContent}</div>
                )}
              </div>
            </div>
          </div>
        )}
        {((mob && page === "spend") ||
          (medium && mediumPage === 2) ||
          (!medium && full)) && (
          <div
            className={`dashWindowColumn20  ${
              medium ? "dashWindowColumn30Medium" : ""
            } ${!medium && !mob ? "dashWindowColumn20Full" : ""} ${
              mob && !medium ? "dashWindowColumn30Mob" : ""
            }`}
          >
            {" "}
            {!mob && <p className="dashWindowTitle">Wages</p>}
            <div
              className={`dashWindowScrollBox ${
                mob && !medium ? "dashWindowScrollBoxMob2 " : ""
              } ${medium ? "dashWindowScrollBoxMedium" : ""} ${
                mob || medium ? "minW100" : "minWidth260"
              } ${!mob && !medium ? "desktopSpendCol" : ""} ${
                device.ios ? "dashWindowScrollBoxMob2Ios" : ""
              }  noScroll`}
            >
              <div
                className={`dashWageSelectorDiv ${
                  medium || mob ? "dashWageSelectorDivMediumMob" : ""
                } ${mob ? "dashWageSelectorDivMob" : ""}`}
              >
                <p
                  className={`dashWageDs ${
                    mob || medium ? "dashWageDsMediumMob" : ""
                  } ${mob ? "dashWageDsMob" : ""}`}
                >
                  {dateStringer.printedDateFromDs(wageDs)}
                  {/* {dateStringer.getThreeDayLettersFromDs(wageDs, true)} */}
                </p>
                <div
                  className={`dashWageSelBox ${mob ? "dashWageSelBoxMob" : ""}`}
                >
                  {" "}
                  <img
                    src={allowArr}
                    alt="Yesterday"
                    className={`dashWindowArrLeft ${
                      mob ? "dashWindowArrLeftMob" : ""
                    } ${wageLoading ? "disableDashArr" : ""}`}
                    onClick={() => {
                      if (!wageLoading) {
                        setWageLoading(true);
                      }
                      let dateObj = new Date(
                        dateStringer.createTimestampFromString(wageDs)
                      );
                      dateObj.setDate(dateObj.getDate() - 1);
                      setWageDs(
                        dateStringer.createStringFromTimestamp(
                          dateObj.getTime()
                        )
                      );
                    }}
                  />
                  {/* <p className="dashWageDayNum">
                    {parseInt(wageDs.substr(9, 2))}
                  </p> */}
                  <img
                    onClick={() => {
                      if (!wageLoading) {
                        setWageLoading(true);
                      }
                      let dateObj = new Date(
                        dateStringer.createTimestampFromString(wageDs)
                      );
                      dateObj.setDate(dateObj.getDate() + 1);
                      setWageDs(
                        dateStringer.createStringFromTimestamp(
                          dateObj.getTime()
                        )
                      );
                    }}
                    src={allowArr}
                    alt="Yesterday"
                    className={`dashWindowArrLeft dashWindowArrRight ${
                      wageLoading ? "disableDashArr" : ""
                    } ${mob ? "dashWindowArrLeftMob" : ""} ${
                      !mob && !medium ? "dashWindowArrRightFull" : ""
                    } wageRight`}
                  />
                </div>
                {/* <p className="dashWageDs">
                  {dateStringer.getMonthThreeLettersFromDs(wageDs, true)}
                </p> */}
              </div>
              <div
                className={`dashWageScrollBox ${
                  mob || medium ? "dashWageScrollBoxMediumMob" : ""
                } ${device.ios ? "dashWageScrollBoxMediumMobIos" : ""} ${
                  mob ? "dashWageScrollBoxMob" : ""
                }`}
              >
                {wageLoading ? (
                  <img
                    src={horizontalBalls}
                    alt="Loading"
                    className="wageLoadingImg"
                  />
                ) : (
                  wageTeams.map((team, i) => {
                    return (
                      <NavLink
                        to={`/reports?teamID=${team.teamID}&ds=${wageDs}`}
                        // `}
                        // to={`/team-rota?load=true&teamID=${
                        //   team.teamID
                        // }&monweek=${dateStringer.getMonWeekOfDs(wageDs)}`}
                        key={team.teamID}
                        className={`wageTeamItem ${
                          i === wageTeams.length - 1 ? "noBorder" : ""
                        } ${mob || medium ? "wageTeamItemMediumMob" : ""}`}
                        onClick={() => {
                          // daisy
                          setAccNav("reports");
                        }}
                      >
                        <p
                          className={`wageTeamItemName ${
                            mob || medium ? "wageTeamItemNameMediumMob" : ""
                          } ${mob ? "x2383282387474" : ""}`}
                        >
                          {team.teamName}
                        </p>
                        <p
                          className={`wageTeamItemCost ${
                            mob || medium ? "wageTeamItemCostMediumMob" : ""
                          } ${mob ? "wageTeamItemCostMob" : ""}`}
                        >
                          {currencySymbol}
                          {team.cost}
                        </p>
                      </NavLink>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {showAddShift && (
        <AddShiftModal2
          teamID="allStaff"
          type="shift"
          ds={shiftsDs}
          setShowAddShift={setShowAddShift}
        />
      )}
    </div>
  );
};

export default DashboardDash;
