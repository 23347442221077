import React, { useState, useContext, useEffect, useMemo } from "react";
// cleaned wef 24 july 23
// params cleared 10 sep 23
// images preloaded 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../contexts/CalendarContext";
import { UserContext } from "../../contexts/UserContext";
import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";

import { NavLink } from "react-router-dom";

import dateStringer from "../../tools/dateStringer";
import CheckUser from "../../tools/CheckUser";

import subNavbarTriangle from "../../img/general/subNavbarTriangle.svg";

import "../../styles/calendar.css";

// if checkUserState.permissions are above 3, then the subbar can toggle to the manager pages:
// user pages         mgr pages
// - - - - -          - - - - - - -
// calendar           team-calendar
// schedule           team-schedule
// absence            team-absence
// shifts             team-shifts

const CalendarSubBar = () => {
  const { calPage, setCalPage, setShiftsPage, setUserShiftsPage } =
    useContext(CalendarContext);
  const { checkUserState } = useContext(UserContext);

  const { changeNavTo, setShowAddTilModal, setAbsencePage, device, countData } =
    useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      calPage, //
      setCalPage, //
      setShiftsPage, //
      setUserShiftsPage, //
      checkUserState, //
      changeNavTo, //
      setShowAddTilModal, //
      setAbsencePage, //
      device,
    }),
    [
      calPage, //
      setCalPage, //
      setShiftsPage, //
      setUserShiftsPage, //
      checkUserState, //
      changeNavTo, //
      setShowAddTilModal, //
      setAbsencePage, //
      device,
    ]
  );

  // CheckUser();

  useEffect(() => {
    // clean not needed
    memoVals.changeNavTo((x) => 2.1);
  }, []);

  let [mgrMode, setMgrMode] = useState(false);
  useEffect(() => {
    if (
      window.location.href.includes("team") ||
      window.location.href.includes("staff")
    ) {
      setMgrMode(true);
    } else {
      setMgrMode(false);
    }
  }, [window.location.href]);

  return (
    <div
      className={`${
        window.location.href.includes("team") ||
        window.location.href.includes("staff")
          ? `purpleBgCalSubbar ${
              memoVals.device.ios ? "purpleBgCalSubbarIos" : ""
            }`
          : `calendarSubBar ${memoVals.device.ios ? "calendarSubBarIos" : ""}`
      }`}
    >
      <NavLink
        to={`${
          (memoVals.checkUserState.permissions < 3 &&
            mgrMode &&
            memoVals.calPage !== "staff-calendar") ||
          (memoVals.checkUserState.permissions < 3 &&
            memoVals.calPage === "calendar")
            ? "/staff-calendar"
            : "/calendar"
        }`}
        onClick={() => {
          if (
            (memoVals.checkUserState.permissions < 3 &&
              mgrMode &&
              memoVals.calPage !== "staff-calendar") ||
            (memoVals.checkUserState.permissions < 3 &&
              memoVals.calPage === "calendar")
          ) {
            memoVals.setCalPage((x) => "staff-calendar");
          } else {
            memoVals.setCalPage((x) => "calendar");
          }
        }}
      >
        <div
          className={`${
            memoVals.calPage === "staff-calendar" ||
            memoVals.calPage === "calendar"
              ? `calSubBtn calSubBtnSel ${
                  memoVals.calPage === "staff-calendar"
                    ? "pinkSelectedCalBtn"
                    : ""
                }`
              : `${
                  window.location.href.includes("team") ||
                  window.location.href.includes("staff")
                    ? "calSubBtn"
                    : "calSubBtnBlue"
                }`
          }`}
        >
          <p className="calSubBarItemTxt">Calendar</p>
          <img
            className={`${
              memoVals.calPage == "calendar" ||
              memoVals.calPage === "staff-calendar"
                ? "calSubBarTriangle"
                : "calSubBarTriangle invis"
            }`}
            src={subNavbarTriangle}
            alt="selected"
          />
        </div>
      </NavLink>
      <NavLink
        to={`${
          (memoVals.checkUserState.permissions < 3 &&
            mgrMode &&
            memoVals.calPage !== "team-rota") ||
          (memoVals.checkUserState.permissions < 3 &&
            memoVals.calPage === "schedule")
            ? "/team-rota"
            : "/rota"
        }`}
        onClick={() => {
          if (
            (memoVals.checkUserState.permissions < 3 &&
              mgrMode &&
              memoVals.calPage !== "team-rota") ||
            (memoVals.checkUserState.permissions < 3 &&
              memoVals.calPage === "schedule")
          ) {
            memoVals.setCalPage((x) => "team-rota");
          } else {
            memoVals.setCalPage((x) => "schedule");
          }
        }}
      >
        <div
          className={`${
            memoVals.calPage === "team-rota" || memoVals.calPage === "schedule"
              ? `calSubBtn calSubBtnSel ${
                  memoVals.calPage === "team-schedule"
                    ? "pinkSelectedCalBtn"
                    : ""
                }`
              : `${
                  window.location.href.includes("team") ||
                  window.location.href.includes("staff")
                    ? "calSubBtn"
                    : "calSubBtnBlue"
                }`
          }`}
        >
          <div className="calSubBarItemTxt">
            Rota{" "}
            {window.location.href.includes("team") ||
            window.location.href.includes("staff")
              ? ""
              : countData.inboundSwaps +
                  countData.outboundSwaps +
                  countData.openShifts +
                  countData.outstandingChanges >
                  0 && (
                  <p className="subBarQtyNum">
                    {countData.inboundSwaps +
                      countData.outboundSwaps +
                      countData.openShifts +
                      countData.outstandingChanges >
                    9
                      ? "9+"
                      : countData.inboundSwaps +
                        countData.outboundSwaps +
                        countData.openShifts +
                        countData.outstandingChanges}
                  </p>
                )}
          </div>
          <img
            className={`${
              memoVals.calPage == "schedule" || memoVals.calPage === "team-rota"
                ? "calSubBarTriangle"
                : "calSubBarTriangle invis"
            }`}
            src={subNavbarTriangle}
            alt="selected"
          />
        </div>
      </NavLink>
      <NavLink
        to={`${
          (memoVals.checkUserState.permissions < 3 &&
            mgrMode &&
            memoVals.calPage !== "team-absence") ||
          (memoVals.checkUserState.permissions < 3 &&
            memoVals.calPage === "absence")
            ? "/staff-absence"
            : "/absence"
        }`}
        onClick={() => {
          if (
            (memoVals.checkUserState.permissions < 3 &&
              mgrMode &&
              memoVals.calPage !== "team-absence") ||
            (memoVals.checkUserState.permissions < 3 &&
              memoVals.calPage === "absence")
          ) {
            memoVals.setCalPage((x) => "team-absence");
            memoVals.setAbsencePage((x) => "shifts");
          } else {
            memoVals.setCalPage((x) => "absence");
            memoVals.setAbsencePage((x) => "shifts");
          }
        }}
      >
        <div
          className={`${
            memoVals.calPage === "team-absence" ||
            memoVals.calPage === "absence"
              ? `calSubBtn calSubBtnSel ${
                  memoVals.calPage === "team-absence"
                    ? "pinkSelectedCalBtn"
                    : ""
                }`
              : `${
                  window.location.href.includes("team") ||
                  window.location.href.includes("staff")
                    ? "calSubBtn"
                    : "calSubBtnBlue"
                }`
          }`}
        >
          <div className="calSubBarItemTxt">
            Absence{" "}
            {countData.selfLoggedAbsence +
              countData.forms +
              countData.ongoingAbs <=
            0 ? (
              ""
            ) : window.location.href.includes("team") ||
              window.location.href.includes("staff") ? (
              <p className="subBarQtyNum subBarQtyNumMgr">
                {countData.selfLoggedAbsence +
                  countData.forms +
                  countData.ongoingAbs >
                9
                  ? "9+"
                  : countData.selfLoggedAbsence +
                    countData.forms +
                    countData.ongoingAbs}
              </p>
            ) : countData.userForms > 0 ? (
              <p className="subBarQtyNum">
                {countData.userForms > 9 ? "9+" : countData.userForms}
              </p>
            ) : (
              ""
            )}
          </div>
          <img
            className={`${
              memoVals.calPage == "absence" ||
              memoVals.calPage === "team-absence"
                ? "calSubBarTriangle"
                : "calSubBarTriangle invis"
            }`}
            src={subNavbarTriangle}
            alt="selected"
          />
        </div>
      </NavLink>
      <NavLink
        to={`${
          (memoVals.checkUserState.permissions < 3 &&
            mgrMode &&
            memoVals.calPage !== "team-shifts") ||
          (memoVals.checkUserState.permissions < 3 &&
            memoVals.calPage === "shifts")
            ? "/team-hours"
            : "/hours"
        }`}
        onClick={() => {
          memoVals.setShowAddTilModal((x) => false);
          if (
            (memoVals.checkUserState.permissions < 3 &&
              mgrMode &&
              memoVals.calPage !== "team-shifts") ||
            (memoVals.checkUserState.permissions < 3 &&
              memoVals.calPage === "shifts")
          ) {
            memoVals.setCalPage((x) => "team-shifts");
            memoVals.setShiftsPage((x) => "team-shifts");
            memoVals.setUserShiftsPage((x) => "team-shifts");
          } else {
            memoVals.setCalPage((x) => "shifts");
            memoVals.setShiftsPage((x) => "shifts");
            memoVals.setUserShiftsPage((x) => "shifts");
          }
        }}
      >
        <div
          className={`${
            memoVals.calPage === "team-shifts" || memoVals.calPage === "shifts"
              ? `calSubBtn calSubBtnSel ${
                  memoVals.calPage === "team-shifts" ? "pinkSelectedCalBtn" : ""
                }`
              : `${
                  window.location.href.includes("team") ||
                  window.location.href.includes("staff")
                    ? "calSubBtn"
                    : "calSubBtnBlue"
                }`
          }`}
        >
          <div className="calSubBarItemTxt">
            Shifts{" "}
            {countData.ttrs +
              countData.shiftEdits +
              countData.drops +
              countData.loggedShifts +
              countData.clocks +
              countData.timesheets <=
            0 ? (
              ""
            ) : window.location.href.includes("team") ||
              window.location.href.includes("staff") ? (
              <p className="subBarQtyNum subBarQtyNumMgr">
                {countData.ttrs +
                  countData.shiftEdits +
                  countData.drops +
                  countData.loggedShifts +
                  countData.clocks +
                  countData.timesheets >
                9
                  ? "9+"
                  : countData.ttrs +
                    countData.shiftEdits +
                    countData.drops +
                    countData.loggedShifts +
                    countData.clocks +
                    countData.timesheets}
              </p>
            ) : (
              countData.shiftAccepts +
                countData.outstandingChanges +
                countData.userTimesheets >
                0 && (
                <p className="subBarQtyNum">
                  {countData.shiftAccepts +
                    countData.outstandingChanges +
                    countData.userTimesheets >
                  9
                    ? "9+"
                    : countData.shiftAccepts +
                      countData.outstandingChanges +
                      countData.userTimesheets}
                </p>
              )
            )}
          </div>
          <img
            className={`${
              memoVals.calPage == "shifts" || memoVals.calPage === "team-shifts"
                ? "calSubBarTriangle"
                : "calSubBarTriangle invis"
            }`}
            src={subNavbarTriangle}
            alt="selected"
          />
        </div>
      </NavLink>
    </div>
  );
};

export default CalendarSubBar;
