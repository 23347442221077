// design and keys fonr 28 feb 25
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import { StyleContext } from "../../../contexts/StyleContext";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { DataContext } from "../../../contexts/DataContext";

import axios from "axios";
import serverURL from "../../../serverURL";

import "../../../styles/navbar.css";

import navyClose from "../../../img/general/navycross.svg";

import dateStringer from "../../../tools/dateStringer";

const EditMyShiftModal = ({
  unitType,
  unitID,
  unitTeamID,
  unitUserID,
  unitDs,
  unitStart,
  unitEnd,
  unitBreak,
  setUpdateChangeRequests,
}) => {
  const { mob, mobModal } = useContext(StyleContext);
  const { setShowEditMyShiftModal, setUpdateChanges, updateChanges } =
    useContext(CalendarContext);
  const {
    setRemoveMyChangeEditID,
    updateShifts,
    setUpdateShifts,
    setIndicate,
    amendReqSent,
    setAmendReqSent,
    modalOpen,
    setModalOpen,
    device,
    countData,
    setCountData,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setShowEditMyShiftModal,
      setUpdateChanges,
      updateChanges,
      setRemoveMyChangeEditID,
      updateShifts,
      setUpdateShifts,
      setIndicate,
      amendReqSent,
      setAmendReqSent,
      mob,
      mobModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setShowEditMyShiftModal, //
      setUpdateChanges, //
      updateChanges, //
      setRemoveMyChangeEditID, //
      updateShifts, //
      setUpdateShifts, //
      setIndicate, //
      amendReqSent, //
      setAmendReqSent, //
      mob, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [itemType, setItemType] = useState("");
  let [itemID, setItemID] = useState("");
  let [itemUserID, setItemUserID] = useState("");

  let [selectedDate, setSelectedDate] = useState("");
  let [selectedStart, setSelectedStart] = useState("00:00");
  let [selectedEnd, setSelectedEnd] = useState("00:00");
  let [selectedBreak, setSelectedBreak] = useState(0);
  let [selectedType, setSelectedType] = useState("shift");
  let [note, setNote] = useState("");
  let [loading, setLoading] = useState(false);

  let [invalidModal, setInvalidModal] = useState(false);
  let [changeMade, setChangeMade] = useState(false);

  useEffect(() => {
    // clean not needed
    setItemType(unitType);
    setItemID(unitID);
    setItemUserID(unitUserID);

    setSelectedDate(dateStringer.createDateInputFromDateString(unitDs));

    setSelectedStart(unitStart);
    setSelectedEnd(unitEnd);

    setSelectedBreak(unitBreak);

    setSelectedType(unitType);
  }, []);

  let [durMins, setDurMins] = useState(0);

  useEffect(() => {
    if (
      selectedStart &&
      selectedStart.length === 5 &&
      selectedStart.includes(":") &&
      selectedEnd &&
      selectedEnd.length === 5 &&
      selectedEnd.includes(":")
    ) {
      let calc = dateStringer.calcMinsBetweenTwoTimes(
        selectedStart,
        selectedEnd
      );

      if (typeof parseInt(selectedBreak) === "number" && selectedBreak > 0) {
        calc -= parseInt(selectedBreak);
      }

      setDurMins(calc);
    } else {
      setDurMins(0);
    }
  }, [selectedStart, selectedEnd, selectedBreak]);
  let closeModal = () => {
    memoVals.setShowEditMyShiftModal((x) => false);

    setSelectedType("shift");
    setNote("");
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [users, setUsers] = useState([]);
  let [selectedUser, setSelectedUser] = useState("");
  let [sureDrop, setSureDrop] = useState(false);

  // master return

  return (
    <div
      className={`clockOnModalUnderlay`}
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div
        className={`dayCommentsModal ${
          memoVals.mobModal
            ? `mobModalShoulder ${memoVals.device.ios ? "paddingBottom35" : ""}`
            : "maxViewZoneModalWidth"
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {memoVals.mobModal ? (
          <div
            className="modalSwiper modalSwiperViewLeaveType"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          ></div>
        ) : (
          ""
        )}
        <div
          className="mySwapsHeader mySwapsHeaderLT"
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={memoVals.mobModal ? navyClose : navyClose}
            alt="Close"
            className="closeMySwapsModalImg"
            onClick={() => {
              closeModal();
            }}
          />
          <p>
            Amend&nbsp;
            {itemType === "shift" ? "shift" : `overtime`} request
          </p>

          {/* <p></p> */}
        </div>
        <div className="dayCommentsModalBody editShiftMaxHeight">
          {/* = = = = = = = ==  */}

          <div className="gpsZoneRow x4389589456986565_">
            {/* <p className="lonLatTitles addShShellTitle createNewShellTitle yourReqAmendTitle">
              Your requested amendments
            </p> */}
            <div className="modalExplainer x39493895855">
              <div className="modalExplainLeftBlob x3499858555"></div>
              <p className="modalExplainRightTxt">
                Request an amendment to the times of your{" "}
                {itemType === "shift" ? "shift" : `overtime`}{" "}
              </p>
            </div>

            <p className="lonLatTitles addShShellTitle editShTitle">Times</p>

            <div className="addShShellTimeDiv">
              <input
                className={`shiftTimeInput`}
                type="time"
                id="toilStart"
                value={selectedStart}
                placeholder={selectedStart}
                onChange={(e) => {
                  setChangeMade(true);

                  setSelectedStart(e.target.value);
                }}
                onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
              />{" "}
              until
              <input
                className={`shiftTimeInput `}
                type="time"
                id="toilStart"
                value={selectedEnd}
                placeholder={selectedEnd}
                onChange={(e) => {
                  setChangeMade(true);

                  setSelectedEnd(e.target.value);
                }}
                onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
              />
            </div>
            <div className="addShShellTimeDiv addShShellTimeDivBrk">
              <input
                className={`shiftTimeInput x298489458954`}
                type="number"
                id="toilStart"
                value={selectedBreak}
                placeholder={selectedBreak}
                onChange={(e) => {
                  setChangeMade(true);

                  setSelectedBreak(e.target.value);
                }}
              />{" "}
              <p className="addShShellUnpBrkTxt">mins unpaid break</p>
            </div>
            <p
              className={`addShShellDur ${
                durMins <= 0 ? "opac05" : ""
              } editShDurMins`}
            >
              <span className="colour00aaff">
                {dateStringer.formatMinsDurationToHours(durMins)}
              </span>{" "}
              payable duration
            </p>
          </div>
          <div className="gpsZoneRow x24356786533">
            <p className="lonLatTitles addShShellTitle editShTitle">
              Add a note
            </p>
            <textarea
              className="EditMyShiftNoteTextBox"
              // type=''
              id="addShiftDateInput"
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="dayCommentsModalFooter">
          <div className="viewMapsAndSaveDiv">
            <p
              className={`saveGpsZoneBtn x34895895677 ${
                selectedStart &&
                selectedStart.length === 5 &&
                selectedEnd &&
                selectedEnd.length === 5
                  ? ""
                  : "disabled defaultCursor"
              }  ${
                selectedStart === unitStart &&
                selectedEnd === unitEnd &&
                selectedBreak == unitBreak
                  ? "disabled defaultCursor none"
                  : ""
              }`}
              onClick={() => {
                if (selectedStart.length !== 5 || selectedEnd.length !== 5) {
                  setInvalidModal(true);
                } else {
                  if (
                    selectedStart === unitStart &&
                    selectedEnd === unitEnd &&
                    selectedBreak == unitBreak
                  ) {
                    return;
                  }
                  setLoading(true);

                  axios
                    .post(
                      `${serverURL}/request-change-to-my-shift`,
                      {
                        userID: itemUserID,
                        shiftID: itemID,
                        type: itemType,
                        newDate:
                          dateStringer.createStringFromDateInput(selectedDate),
                        newStart: selectedStart,
                        newEnd: selectedEnd,
                        newBrk: selectedBreak,
                        note: note,
                        newType: selectedType,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        if (!response.data.existed) {
                          setCountData({
                            outstandingChanges:
                              countData.outstandingChanges + 1,
                          });
                        }
                        // setTimeout(() => {
                        memoVals.setAmendReqSent((x) => !amendReqSent);
                        setLoading(false);
                        memoVals.setUpdateChanges(
                          (x) => !memoVals.updateChanges
                        );
                        closeModal();
                        memoVals.setRemoveMyChangeEditID(Math.random());
                        memoVals.setUpdateShifts(!memoVals.updateShifts);
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Change request made",
                            colour: "green",
                            duration: 4000,
                          };
                        });
                        if (setUpdateChangeRequests) {
                          response.data.changesArr.sort();

                          setUpdateChangeRequests(response.data.changesArr);
                        }
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Submit
            </p>

            <p
              className={`saveGpsZoneBtn x34895895677 greyColoursDelete deleteFromShell`}
              onClick={() => {
                if (!users[0]) {
                  axios
                    .post(
                      `${serverURL}/get-drop-users`,
                      {
                        teamID: unitTeamID,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setUsers(response.data.users);
                      }
                    });
                }
                setSureDrop(true);
              }}
            >
              Drop
            </p>
          </div>

          <p
            className="closeReqInfo"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </p>
        </div>
      </div>

      {invalidModal ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">Please enter a start and end time.</p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setInvalidModal(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {sureDrop ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setSureDrop(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              Do you want to put a colleague forward to cover this{" "}
              {itemType === "shift"
                ? "shift"
                : `${itemType === "til" ? "overtime" : "overtime"}`}{" "}
              instead of you?
              <br />
              {/* <br />{" "} */}
              <select
                className="shiftLeaveDropdown colleagueCoverDropdown"
                value={selectedUser}
                onChange={(e) => {
                  setSelectedUser(e.target.value);
                }}
              >
                <option value="">No colleague</option>
                {users.map((x) => {
                  return (
                    <option value={x.userID} key={x.userID}>
                      {x.fName} {x.lName}
                    </option>
                  );
                })}
              </select>{" "}
              {/* <br /> */}
              <br />
              FlowRota will notify you if your drop request is approved by your
              manager.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className={`areYouSureModalYesBtn ${loading ? "disabled" : ""}`}
                onClick={() => {
                  setLoading(true);

                  axios
                    .post(
                      `${serverURL}/request-change-to-my-shift`,
                      {
                        userID: itemUserID,
                        shiftID: itemID,
                        type: itemType,
                        newDate:
                          dateStringer.createStringFromDateInput(selectedDate),
                        newStart: selectedStart,
                        newEnd: selectedEnd,
                        newBrk: selectedBreak,
                        note: note,
                        newType: selectedType,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                        drop: true,
                        coverUserID: selectedUser,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        if (!response.data.existed) {
                          setCountData({
                            outstandingChanges:
                              countData.outstandingChanges + 1,
                          });
                        }
                        // setTimeout(() => {
                        memoVals.setAmendReqSent((x) => !amendReqSent);
                        setLoading(false);
                        memoVals.setUpdateChanges(
                          (x) => !memoVals.updateChanges
                        );
                        closeModal();
                        memoVals.setRemoveMyChangeEditID(Math.random());
                        memoVals.setUpdateShifts(!memoVals.updateShifts);
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Drop request made",
                            colour: "green",
                            duration: 4000,
                          };
                        });
                        if (setUpdateChangeRequests) {
                          response.data.changesArr.sort();
                          // .sort((a, b) => {
                          //   return (
                          //     dateStringer.createTimestampFromString(a.dsMade) -
                          //     dateStringer.createStringFromTimestamp(b.dsMade)
                          //   );
                          // });
                          setUpdateChangeRequests(response.data.changesArr);
                        }
                        // }, 900);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Submit{" "}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  if (selectedUser) {
                    setSelectedUser("");
                  }
                  setSureDrop(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default EditMyShiftModal;
