// design and keys done 4 march 25

import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  memo,
} from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 8 sep 24
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";

import axios from "axios";
import serverURL from "../serverURL";
import SwapModal from "./SwapModal";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";
import inReturnForArrow from "../img/general/inReturnForArrow.svg";
import navyClose from "../img/general/navycross.svg";
import inboundSwap from "../img/general/inboundSwap.svg";
import outboundSwap from "../img/general/outboundSwap.svg";
import publishTick1 from "../img/general/checkboxInstall1.svg";
import publishTick2 from "../img/general/checkboxInstall4.svg";
import greenTick2 from "../img/general/greenTick2.svg";
import personBbcada from "../img/general/personBbcada.svg";
import person00aaff from "../img/general/person00aaff.svg";

import rotaClock from "../img/general/rotaClock.svg";
import rotaDur from "../img/general/rotaDur.svg";
import rotaDate from "../img/general/rotaDate.svg";

const MySwapsModal = ({ openedFromUserRota, showHistoryProp }) => {
  const { mobModal } = useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);
  const {
    setShowSwapsModal,
    allowSwaps,
    setAllowSwaps,
    setIndicate,
    showMyShiftModal,
    setShowMyShiftModal,
    inboundSwapsQty,
    setInboundSwapsQty,
    outboundSwapsQty,
    setOutboundSwapsQty,
    setDynamicSwap,
    modalOpen,
    setModalOpen,
    device,
    setCountData,
    countData,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setShowSwapsModal,
      allowSwaps,
      setAllowSwaps,
      setIndicate,
      showMyShiftModal,
      setShowMyShiftModal,
      inboundSwapsQty,
      setInboundSwapsQty,
      outboundSwapsQty,
      setOutboundSwapsQty,
      setDynamicSwap,
      checkUserState,
      mobModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setShowSwapsModal, //
      allowSwaps, //
      setAllowSwaps, //
      setIndicate, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      inboundSwapsQty, //
      setInboundSwapsQty, //
      outboundSwapsQty, //
      setOutboundSwapsQty, //
      setDynamicSwap, //
      checkUserState, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [showNoLongerAllowedModal, setShowNoLongerAllowedModal] = useState(false);
  let [showHistory, setShowHistory] = useState(false);
  let [sureCancel, setSureCancel] = useState({ show: false });
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 9) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={publishTick1}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaDate}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDur}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaClock}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={publishTick2}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={inReturnForArrow}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyClose}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={inboundSwap}
        alt="Inbound Swap"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={outboundSwap}
        alt="Outbound Swap"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [loadingSwap, setLoadingSwap] = useState("");
  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowSwapsModal((x) => false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let closeModal = () => {
    memoVals.setShowSwapsModal((x) => false);
  };

  let [swaps, setSwaps] = useState([]);
  let [loaded, setLoaded] = useState(false);

  let [historyArr, setHistoryArr] = useState([]);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (showHistory) {
      axios
        .post(
          `${serverURL}/get-swap-history`,
          {
            user: true,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setHistoryArr(response.data.output);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [showHistory]);

  let generateSwaps = swaps.map((swap, i) => {
    return (
      <div className="swapUnitMaster" key={i}>
        {swap.inboundOutbound === "inbound" && !swap.usrApproved ? (
          <div className="swapBlueBlob"></div>
        ) : (
          ""
        )}
        <div className="swapUnitParent">
          <div
            key={i}
            className={`swapUnit ${
              swap.inboundOutbound === "inbound" && !swap.usrApproved
                ? "inboundSwapUnit"
                : ""
            }`}
          >
            <div className="swapUnitMain">
              <div className="swapUnitLeft">
                <div className="swapUnitLeftUpperMinimum">
                  <img
                    src={
                      swap.inboundOutbound === "inbound"
                        ? inboundSwap
                        : outboundSwap
                    }
                    alt="Inbound swap request"
                    className="inbOutbSwapReqImg"
                  />
                  {swap.inboundOutbound === "inbound" ? (
                    <p className="swapUnitLeftTxt swapUnitLeftTxtMy">
                      {swap.fName} {swap.lName[0]} wants your{" "}
                      {swap.wantType === "til"
                        ? "overtime (time in lieu)"
                        : swap.wantType}
                      :
                    </p>
                  ) : (
                    <p className="swapUnitLeftTxt swapUnitLeftTxtMy">
                      You want {dateStringer.possession(swap.fName)}{" "}
                      {swap.wantType === "til"
                        ? "overtime (time in lieu)"
                        : swap.wantType}
                      :
                    </p>
                  )}
                </div>
                <div
                  className={`swapUnitWantItemDiv ${
                    swap.inboundOutbound === "inbound" ? "inboundWantItem" : ""
                  }`}
                  onClick={() => {
                    memoVals.setShowMyShiftModal((x) => {
                      return {
                        unitType: swap.wantType,
                        unitID: swap.wantTypeID,
                        otherUser:
                          swap.inboundOutbound === "inbound" ? false : true,
                      };
                    });
                  }}
                >
                  <p
                    className={`swapUnitWantDs ${
                      memoVals.mobModal ? "font12" : ""
                    }`}
                  >
                    <img
                      src={rotaDate}
                      alt="swap rota date"
                      className="rotaDateMySwapsImg"
                    />
                    {memoVals.mobModal
                      ? `${dateStringer.printedDateFromDs(
                          swap.wantStartDs
                        )} '${swap.wantStartDs.substr(3, 2)}`
                      : `${dateStringer.printedDateWithYrFromDs(
                          swap.wantStartDs,
                          false,
                          true
                        )} '${swap.wantStartDs.substr(3, 2)}`}
                  </p>
                  <p
                    className={`swapUnitWantDs ${
                      memoVals.mobModal ? "font12" : ""
                    }`}
                  >
                    <img
                      src={rotaClock}
                      alt="swap rota date"
                      className="rotaDateMySwapsImg"
                    />
                    {dateStringer.dsToTimeStrip(swap.wantStartDs)} -{" "}
                    {dateStringer.dsToTimeStrip(swap.wantEndDs)}
                  </p>
                  <p
                    className={`swapUnitWantDsSub noBorder ${
                      memoVals.mobModal ? "font12" : ""
                    }`}
                  >
                    <img
                      src={rotaDur}
                      alt="swap rota date"
                      className="rotaDateMySwapsImg rotaDateMySwapsImgDur"
                    />
                    {dateStringer.formatMinsDurationToHours(swap.wantDurMins)}
                  </p>
                </div>
              </div>
              <div
                className={`mySwapsSwapUnitMiddle ${
                  memoVals.mobModal && "hidden"
                }`}
              >
                <img
                  src={inReturnForArrow}
                  alt="In return for"
                  className="inReturnForArrowImg"
                />
                {/* <p
                className={`inReturnForTxt ${
                  memoVals.mobModal ? "font12px" : ""
                }`}
              >
                in return for
              </p> */}
                <img
                  src={inReturnForArrow}
                  alt="In return for"
                  className="inReturnForArrowImg flipH"
                />
              </div>

              <div className="swapUnitRight">
                <div className="swapUnitRightUpperMinimum">
                  {swap.offerNothing || swap.deleted ? (
                    <p
                      className={`offerSwapNothingTxt ${
                        swap.offerNothing
                          ? `x139832 ${memoVals.mobModal ? "x139832Mob" : ""}`
                          : ""
                      }`}
                    >
                      {swap.deleted ? (
                        `for nothing. A${
                          swap.typeOffered === "shift" ? "" : "n"
                        } ${
                          swap.typeOffered === "til"
                            ? "overtime"
                            : swap.typeOffered
                        } was offered, but it has since been changed by a manager`
                      ) : (
                        <>
                          No shift
                          <br />
                          in return
                        </>
                      )}
                    </p>
                  ) : swap.inboundOutbound === "outbound" ? (
                    <p className="swapUnitRightTxt swapUnitRightTxtMy">
                      in return for your{" "}
                      {swap.typeOffered === "til"
                        ? "overtime (time in lieu)"
                        : swap.typeOffered}
                      :
                    </p>
                  ) : (
                    <p className="swapUnitRightTxt swapUnitRightTxtMy swap500">
                      in return for their{" "}
                      {swap.typeOffered === "til"
                        ? "overtime (time in lieu)"
                        : swap.typeOffered}
                      :
                    </p>
                  )}
                </div>
                {swap.offerNothing || swap.deleted ? (
                  <div className="offerNothingMinHeight"></div>
                ) : (
                  <div
                    className={`swapUnitWantItemDiv xx2222 ${
                      memoVals.mobModal ? "xx2222Small" : ""
                    } ${
                      swap.inboundOutbound === "outbound"
                        ? "inboundWantItem"
                        : ""
                    }`}
                    onClick={() => {
                      memoVals.setShowMyShiftModal((x) => {
                        return {
                          unitType: swap.typeOffered,
                          unitID: swap.offeredTypeID,
                          otherUser:
                            swap.inboundOutbound === "outbound" ? false : true,
                        };
                      });
                    }}
                  >
                    <p
                      className={`swapUnitWantDs txtAlignRight ${
                        memoVals.mobModal ? "font12" : ""
                      }`}
                    >
                      <img
                        src={rotaDate}
                        alt="swap rota date"
                        className="rotaDateMySwapsImg"
                      />{" "}
                      {memoVals.mobModal
                        ? `${dateStringer.printedDateFromDs(
                            swap.offerStartDs
                          )} '${swap.offerStartDs.substr(3, 2)}`
                        : `${dateStringer.printedDateWithYrFromDs(
                            swap.offerStartDs,
                            false,
                            true
                          )} '${swap.offerStartDs.substr(3, 2)}`}
                    </p>
                    <p
                      className={`swapUnitWantDs txtAlignRight ${
                        memoVals.mobModal ? "font12" : ""
                      }`}
                    >
                      <img
                        src={rotaClock}
                        alt="swap rota date"
                        className="rotaDateMySwapsImg"
                      />{" "}
                      {dateStringer.dsToTimeStrip(swap.offerStartDs)} -{" "}
                      {dateStringer.dsToTimeStrip(swap.offerEndDs)}
                    </p>
                    <p
                      className={`swapUnitWantDsSub txtAlignRight noBorder ${
                        memoVals.mobModal ? "font12" : ""
                      }`}
                    >
                      <img
                        src={rotaDur}
                        alt="swap rota date"
                        className="rotaDateMySwapsImg rotaDateMySwapsImgDur"
                      />{" "}
                      {dateStringer.formatMinsDurationToHours(
                        swap.offerDurMins
                      )}
                    </p>
                  </div>
                )}
                <div
                  className={`swapStatusDivider hidden ${
                    swap.offerNothing || swap.deleted
                      ? "swapStatusDividerOffernothing"
                      : ""
                  }`}
                ></div>
                {/* {swap.offerTags && swap.offerTags[0] ? (
              <div className="wantShiftTags">
                <p className="wantShiftTagsTitle txtAlignRight">Tags:</p>
                <div className="wantShiftTagsList">{generateOfferTags}</div>
              </div>
            ) : (
              ""
            )} */}
              </div>
            </div>
          </div>
          <div
            className={`swapUnitCtaBar ${
              swap.inboundOutbound === "inbound" ? "inboundSwapCta" : ""
            }`}
          >
            <p
              className={`swapDsMade
             ${swap.inboundOutbound === "inbound" ? "colourW hite" : ""} 
            `}
            >
              Requested {dateStringer.dsToTimeStrip(swap.dsMade)}
              <br />
              {dateStringer.printedDateFromDs(swap.dsMade)}
            </p>
            {swap.inboundOutbound === "inbound" ? (
              <div className="swapCtaBtns swapCtaBtnsMy">
                <p
                  className={`swapDecline inboundDeclineSwap ${
                    swap.usrApproved ? "declineBtnRight" : ""
                  }`}
                  onClick={() => {
                    let original = swaps;
                    let newArr = [];

                    swaps.forEach((s) => {
                      if (s.id !== swap.id) {
                        newArr.push(s);
                      }
                    });

                    setSwaps(newArr);
                    axios
                      .post(
                        `${serverURL}/remove-swap-request`,
                        {
                          wanterUserID: swap.wanterUserID,
                          shiftID: swap.wantTypeID,
                          type: swap.wantType,
                          cancellingOwnSwap: false,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          let newInboundQty = memoVals.inboundSwapsQty - 1;
                          memoVals.setInboundSwapsQty((x) => newInboundQty);
                          setCountData({
                            inboundSwaps: countData.inboundSwaps - 1,
                          });
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: `Declined swap with ${swap.fName}`,
                              colour: "red",
                              duration: 4000,
                              ts: new Date().getTime(),
                            };
                          });
                        } else {
                          setSwaps(original);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  {swap.usrApproved ? "Cancel" : "Decline"}
                </p>
                {!swap.usrApproved ? (
                  <p
                    className={`swapAccept ${
                      loadingSwap === i ? "disabled noPointer" : ""
                    }`}
                    onClick={() => {
                      if (swap.allowed) {
                        setLoadingSwap(i);
                        axios
                          .post(
                            `${serverURL}/accept-swap`,
                            {
                              wantUserID: swap.wanterUserID,
                              shiftID: swap.wantTypeID,
                              wantType: swap.wantType,
                              returnShiftData: window.location.href.includes(
                                "rota"
                              )
                                ? true
                                : false,
                            },
                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              if (response.data.swapInitiated) {
                                let newArr = [];

                                swaps.forEach((s) => {
                                  if (s.id !== swap.id) {
                                    newArr.push(s);
                                  }
                                });
                                memoVals.setInboundSwapsQty(
                                  (x) => memoVals.inboundSwapsQty - 1
                                );

                                setSwaps(newArr);
                                memoVals.setIndicate((x) => {
                                  return {
                                    show: true,
                                    message: `Accepted swap with ${swap.fName}`,
                                    colour: "green",
                                    duration: 4000,
                                    ts: new Date().getTime(),
                                  };
                                });
                                if (window.location.href.includes("rota")) {
                                  memoVals.setDynamicSwap((x) => {
                                    return {
                                      getRidOfTypeIDs:
                                        response.data.getRidOfTypeIDs,
                                      newItem1: response.data.newItem1,
                                      newItem2: response.data.newItem2,
                                      teamID: response.data.teamID,
                                    };
                                  });
                                }
                              }

                              if (response.data.usrApproved) {
                                let newArr = [];

                                swaps.forEach((s) => {
                                  let newObj = s;
                                  if (s.id === swap.id) {
                                    newObj["usrApproved"] = true;
                                  }

                                  newArr.push(s);
                                });

                                let inbound = [];
                                let outbound = [];

                                newArr.forEach((item) => {
                                  if (item.inboundOutbound === "inbound") {
                                    inbound.push(item);
                                  } else {
                                    outbound.push(item);
                                  }
                                });

                                setSwaps([...inbound, ...outbound]);

                                setCountData({
                                  inboundSwaps: countData.inboundSwaps - 1,
                                });

                                memoVals.setIndicate((x) => {
                                  return {
                                    show: true,
                                    message: `Accepted swap with ${swap.fName} - awaiting manager approval`,
                                    colour: "blue",
                                    duration: 4000,
                                    ts: new Date().getTime(),
                                  };
                                });
                              }
                              setLoadingSwap("");
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      } else {
                        setShowNoLongerAllowedModal(true);
                      }
                    }}
                  >
                    Accept
                  </p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <p
                className="swapDecline"
                onClick={() => {
                  setSureCancel({
                    show: true,
                    shiftID: swap.wantTypeID,
                    type: swap.wantType,
                    fName: swap.fName,
                    id: swap.id,
                  });
                }}
              >
                Cancel swap
              </p>
            )}
          </div>{" "}
          <div
            className={`swapUnitLower ${
              memoVals.mobModal &&
              !swap.usrApproved &&
              swap.inboundOutbound === "inbound"
                ? "swapUnitLowerDecidingMob"
                : ""
            } ${memoVals.mobModal ? "" : "swapUnitLowerDesktop"}`}
          >
            {" "}
            {swap.mgrApproved && !swap.autoApprove ? (
              <p className="mgrApprovedSwapInfo">
                <img src={greenTick2} alt="Approved" className="appByMgrTick" />
                Manager approved:
                <br />
                {swap.mgrApproved}
              </p>
            ) : (
              ""
            )}
            {!swap.mgrApproved && !swap.autoApprove ? (
              <p className="mgrApprovedSwapInfo outboundAwaitingTxtColour swapBorderRight_">
                <img
                  src={personBbcada}
                  alt="Awaiting approval"
                  className="appByMgrTick"
                />
                Awaiting manager approval
                {/* <br />
                by a manager */}
              </p>
            ) : (
              ""
            )}
            {/* {!swap.usrApproved &&
              !swap.mgrApproved &&
              swap.inboundOutbound === "inbound" ? (
                <p className="awaitingNameDecicionSwap">
                  {" "}
                  <img
                    src={personBbcada}
                    alt="Awaiting approval"
                    className="appByMgrTick"
                  />
                  Awaiting your approval
                </p>
              ) : (
                ""
              )} */}
            {!swap.usrApproved &&
            !swap.mgrApproved &&
            swap.inboundOutbound === "outbound" ? (
              <p className="awaitingNameDecicionSwap outboundAwaitingTxtColour swapBorderRight_">
                <img
                  src={personBbcada}
                  alt="Awaiting approval"
                  className="appByMgrTick"
                />
                Awaiting {dateStringer.possession(swap.fName)} approval
              </p>
            ) : (
              ""
            )}
            {swap.usrApproved ? (
              swap.inboundOutbound === "inbound" ? (
                <p className="mgrApprovedSwapInfo">Approved by you</p>
              ) : (
                <p className="mgrApprovedSwapInfo">Approved by {swap.fName}</p>
              )
            ) : (
              ""
            )}
            {swap.mgrApproved ? (
              <p
                className={`awaitingNameDecicionSwap ${
                  swap.inboundOutbound === "outbound"
                    ? "outboundAwaitingTxtColour"
                    : ""
                }`}
              >
                <img
                  src={
                    swap.inboundOutbound === "outbound"
                      ? personBbcada
                      : person00aaff
                  }
                  alt="Awaiting approval"
                  className="appByMgrTick"
                />
                Awaiting{" "}
                {`${
                  swap.inboundOutbound === "outbound"
                    ? `${dateStringer.possession(swap.fName)} approval`
                    : "your approval"
                }`}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>{" "}
      </div>
    );
  });

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-my-swaps`,
        {
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (showHistoryProp) {
          setShowHistory(true);
        }
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          memoVals.setAllowSwaps((x) => response.data.allowSwaps);
          response.data.combinedArr.sort(
            (a, b) =>
              dateStringer.createTimestampFromString(a.dsMade) -
              dateStringer.createTimestampFromString(b.dsMade)
          );

          let inbound = [];
          let outbound = [];

          response.data.combinedArr.forEach((item) => {
            if (item.inboundOutbound === "inbound") {
              inbound.push(item);
            } else {
              outbound.push(item);
            }
          });

          let unreadInbound = [];
          let readInbound = [];

          inbound.forEach((item) => {
            if (!item.usrApproved) {
              unreadInbound.push(item);
            } else {
              readInbound.push(item);
            }
          });

          setSwaps([...unreadInbound, ...readInbound, ...outbound]);
          setDataLoaded(true);
          setLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className={`clockOnModalUnderlay ${
        memoVals.showMyShiftModal.unitID ? "none" : ""
      }`}
      onClick={() => {
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
          {imgPreload}
        </div>
      ) : (
        <div
          className={`mySwapsModal ${
            memoVals.mobModal ? "seeUserModalMob mobModalShoulder" : ""
          } ${memoVals.device.ios ? "paddingBottom35" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperMySwaps"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className={`mySwapsHeader x2389982328238 ${
              memoVals.mobModal ? "x438954895454" : ""
            }`}
            onClick={() => {}}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={memoVals.mobModal ? navyClose : navyClose}
              alt="Close"
              className="closeMySwapsModalImg"
              onClick={() => {
                closeModal();
              }}
            />
            <p>My shift swaps</p>
            {/* <p></p> */}
          </div>

          <div className="mySwapsContainer">
            {!loaded ? (
              <img
                src={horizontalBalls}
                alt="Loading"
                className="loadingSwaps"
              />
            ) : (
              ""
            )}
            {showHistory ? (
              <p className="showHistTxt">
                Showing swap history from the last 120 days
              </p>
            ) : (
              ""
            )}
            {showHistory
              ? historyArr.map((hist, i) => {
                  return (
                    <div
                      className={`histSwap ${
                        i === historyArr.length - 1 ? "noBorder" : ""
                      }`}
                    >
                      <p className="histSwapTxt" key={i}>
                        <span className="makeBold">
                          {hist.buyerMe ? "You" : hist.buyerUserFName}{" "}
                          {hist.buyerMe ? "" : hist.buyerUserLName}
                        </span>{" "}
                        wanted{" "}
                        <span className="makeBold">
                          {hist.sellerMe
                            ? "your"
                            : dateStringer.possession(
                                `${hist.sellerFName} ${hist.sellerLName}`
                              )}{" "}
                          {hist.sellerType === "til"
                            ? "lieu shift"
                            : hist.sellerType}
                        </span>{" "}
                        scheduled for{" "}
                        <span className="makeBold">
                          {!hist.sellerStartDs
                            ? "unknown"
                            : dateStringer.printedDateFromDs(
                                hist.sellerStartDs,
                                true
                              )}
                        </span>{" "}
                        starting{" "}
                        <span className="makeBold">
                          {!hist.sellerStartDs
                            ? "unknown"
                            : dateStringer.dsToTimeStrip(hist.sellerStartDs)}
                        </span>{" "}
                        and ending{" "}
                        <span className="makeBold">
                          {!hist.sellerEndDs
                            ? "unknown"
                            : dateStringer.dsToTimeStrip(hist.sellerEndDs)}
                        </span>{" "}
                        in return for{" "}
                        {hist.offerNothing ? (
                          <>
                            <span className="makeBold">nothing</span>
                          </>
                        ) : (
                          <>
                            <span className="makeBold">
                              {hist.buyerMe
                                ? "your"
                                : dateStringer.possession(
                                    hist.buyerUserFName
                                  )}{" "}
                              {hist.buyerType === "til"
                                ? "lieu shift"
                                : hist.buyerType}
                            </span>{" "}
                            scheduled for{" "}
                            <span className="makeBold">
                              {!hist.buyerStartDs
                                ? "unknown"
                                : dateStringer.printedDateFromDs(
                                    hist.buyerStartDs,
                                    true
                                  )}
                            </span>{" "}
                            starting{" "}
                            <span className="makeBold">
                              {!hist.buyerStartDs
                                ? "unknown"
                                : dateStringer.dsToTimeStrip(hist.buyerStartDs)}
                            </span>{" "}
                            and ending{" "}
                            <span className="makeBold">
                              {!hist.buyerEndDs
                                ? "unknown"
                                : dateStringer.dsToTimeStrip(hist.buyerEndDs)}
                            </span>
                          </>
                        )}
                        .
                      </p>
                      <p className="histTeamName">{hist.teamName}</p>
                      {hist.approved ? (
                        <p className="histSub">
                          This swap was{" "}
                          <span className="swapGreen">approved</span> and
                          initiated
                        </p>
                      ) : (
                        ""
                      )}
                      {hist.declined ? (
                        <p className="histSub">
                          This swap was{" "}
                          <span className="swapRed">declined</span> by{" "}
                          {hist.actionedFName} {hist.actionedLName}
                        </p>
                      ) : (
                        ""
                      )}
                      {hist.cancelled ? (
                        <p className="histSub">
                          This swap was{" "}
                          <span className="swapRed">cancelled</span> by{" "}
                          {hist.cancelMe ? "you" : hist.actionedFName}{" "}
                          {hist.cancelMe ? "" : hist.actionedLName}
                        </p>
                      ) : (
                        ""
                      )}
                      {hist.denied ? (
                        <p className="histSub">
                          This swap was <span className="swapRed">denied</span>{" "}
                          by {hist.actionedFName} {hist.actionedLName}
                        </p>
                      ) : (
                        ""
                      )}

                      <p className="swapHistoryDs">
                        {dateStringer.printedDateWithTimeFromDs(hist.dsMade)}
                      </p>
                    </div>
                  );
                })
              : ""}
            {loaded && swaps.length === 0 && !showHistory ? (
              <p className="noSwapsTxt">No swap requests</p>
            ) : showHistory ? (
              ""
            ) : (
              generateSwaps
            )}
          </div>

          <div className="mySwapsModalFooter">
            <div className="mySwapsFooterLeft">
              <p
                className="pubShiftBtn histSwapBtn"
                onClick={() => {
                  setShowHistory(!showHistory);
                }}
              >
                Historical{" "}
                <img
                  src={showHistory ? publishTick2 : publishTick1}
                  className="histCheck"
                  alt="Publish"
                />
              </p>
              <div
                className={`myRotaAllowSwapsBtn ${
                  memoVals.allowSwaps ? "allowSwapsMyRotaBtnActive" : ""
                } allowSwapsBtnTxtModal`}
                onClick={() => {
                  // setState({ allowSwaps: !state.allowSwaps });
                  axios
                    .post(
                      `${serverURL}/toggle-my-enable-swaps`,
                      { data: !memoVals.allowSwaps },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: response.data.data
                              ? "Swap requests allowed"
                              : "Swap requests not allowed",
                            colour: "blue",
                            duration: 4000,
                            ts: new Date().getTime(),
                          };
                        });
                        memoVals.setAllowSwaps((x) => response.data.data);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                {" "}
                Allow colleagues to swap with me
                {/* KNOB BEGIN */}
                <div className="editDayFxSchedKnob">
                  <div className="knobContainer allowSwapsKnobMyRota">
                    <div
                      className={`switchKnob ${
                        memoVals.allowSwaps
                          ? "knobOn allowSwapsKnobOn"
                          : "knobOff"
                      }`}
                    ></div>
                    <div className="knobBg allowSwapsKnobBg"></div>
                  </div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
            <p
              className="closeReqInfo"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
          {imgPreload}
          {sureCancel.show ? (
            <div
              className="areYouSureModalUnderlay"
              onClick={() => {
                setSureCancel({ show: false });
              }}
            >
              <div
                className="formCreatedModal"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <p className="overlapsRenTxt">
                  Are you sure you want to to cancel this swap request with{" "}
                  {sureCancel.fName}?
                </p>
                <div className="areYouModalBtnsDiv">
                  <p
                    className="areYouSureModalYesBtn"
                    onClick={() => {
                      setSureCancel({ show: false });
                      let original = swaps;
                      let newArr = [];

                      swaps.forEach((s) => {
                        if (s.id !== sureCancel.id) {
                          newArr.push(s);
                        }
                      });

                      setSwaps(newArr);
                      axios
                        .post(
                          `${serverURL}/remove-swap-request`,
                          {
                            wanterUserID: memoVals.checkUserState.userID,
                            shiftID: sureCancel.shiftID,
                            type: sureCancel.type,
                            cancellingOwnSwap: true,
                            nowDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                          },
                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setCountData({
                              outboundSwaps: countData.outboundSwaps - 1,
                            });
                            let newOutboundQty = memoVals.outboundSwapsQty - 1;
                            memoVals.setOutboundSwapsQty((x) => newOutboundQty);
                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: `Cancelled swap with ${sureCancel.fName}`,
                                colour: "green",
                                duration: 4000,
                                ts: new Date().getTime(),
                              };
                            });
                          } else {
                            setSwaps(original);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    Yes
                  </p>
                  <p
                    className="areYouModalNoBtn"
                    onClick={() => {
                      setSureCancel({ show: false });
                    }}
                  >
                    No
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {showNoLongerAllowedModal ? (
            <div
              className="shiftAlreadyExistsUnderlay"
              onClick={() => {
                setShowNoLongerAllowedModal(false);
              }}
            >
              <div className="swapNoLongerAllowedModalBody">
                <p className="swapNoLongerTxt">
                  Sorry, but since this request was made, a manager has disabled
                  shift swaps.
                </p>
                <p
                  className="closeSwapsNoLongerBtn"
                  onClick={() => {
                    setShowNoLongerAllowedModal(false);
                  }}
                >
                  Understood
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default MySwapsModal;
