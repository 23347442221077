import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
  Fragment,
} from "react";
// cleaned wef 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";

import dateStringer from "../../../tools/dateStringer";
import serverURL from "../../../serverURL";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import ShiftsHeader from "./ShiftsHeader";

import "../../../styles/calendar.css";

// import AddShiftModal from "../../account/admin/subcomponents/AddShiftModal";
import AddShiftModal2 from "../../account/admin/subcomponents/AddShiftModal2";

// import AddTilModal from "../../account/admin/subcomponents/AddTilModal";
import ManagerShiftModal from "./ManagerShiftModal";

import TeamHoursDataBox from "./TeamHoursDataBox";
import profilePicturePlaceholder from "../../../img/general/profilePicturePlaceholder.svg";
import dashSwap from "../../../img/general/dashSwap.svg";

import checkboxInstall1 from "../../../img/general/checkboxInstall1.svg";
import checkboxInstall2 from "../../../img/general/checkboxInstall2purp.svg";
import shiftChangeArrow from "../../../img/general/shiftChangeArrow.svg";
import shiftChangeArrowGreen from "../../../img/general/shiftChangeArrowGreen.svg";
import shiftChangeArrowRed from "../../../img/general/shiftChangeArrowRed.svg";
import barChart from "../../../img/general/barChart.svg";

import time from "../../../img/general/time.svg";
import unitTick from "../../../img/general/unitTick.svg";
import unitCross from "../../../img/general/unitCross.svg";
import navyFilter from "../../../img/general/navyFilter.svg";
import rotaClock from "../../../img/general/rotaClock.svg";
import rotaEdit from "../../../img/general/rotaEdit.svg";
import navyAdd from "../../../img/general/navyAdd.svg";
import popup from "../../../img/general/popup.svg";
import threeWhiteDots from "../../../img/general/threeWhiteDots.svg";

import whitecross from "../../../img/general/whitecross.svg";
import exclaimWhite from "../../../img/general/exclaimWhite.svg";
import rightArr from "../../../img/general/rightArr.svg";
import doubleTick from "../../../img/general/doubleTick.svg";
import doubleTickRed from "../../../img/general/doubleTickRed.svg";

import lightBlueQuestionMark from "../../../img/general/lightBlueQuestionMark.svg";
import teamIcon from "../../../img/general/rotaTeam.svg";
// import zzz from "../../../img/general/zzz.svg";
import rotaDur from "../../../img/general/rotaDur.svg";
import shiftBlockNavy from "../../../img/general/shiftBlock.svg";
import shiftBlockMger from "../../../img/general/shiftBlockMger.svg";
import whiteTick from "../../../img/general/whiteTick.svg";
import navyClose from "../../../img/general/navyClose.svg";
import rotaDate from "../../../img/general/rotaDate.svg";
import rotaCoffee from "../../../img/general/rotaCoffee.svg";
import rotaTeam from "../../../img/general/rotaTeam.svg";
import desktopBurger from "../../../img/general/desktopBurger.svg";
import rotaPerson from "../../../img/general/rotaPerson.svg";
import rotaTag from "../../../img/general/rotaTag.svg";
import tick from "../../../img/general/checkboxInstall4.svg";
import pin from "../../../img/general/pin.svg";
import navyTick from "../../../img/general/navyTick.svg";

const TeamShifts = () => {
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 36) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      {/* Previously added images */}
      {/* ... */}
      {/* Additional images */}
      <img
        src={desktopBurger}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyTick}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={threeWhiteDots}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={pin}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={popup}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={tick}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={doubleTickRed}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyAdd}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaEdit}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaPerson}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={lightBlueQuestionMark}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaTag}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={whiteTick}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyClose}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftBlockMger}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftBlockNavy}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaDur}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rightArr}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaDate}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={barChart}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaTeam}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaCoffee}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={dashSwap}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkboxInstall1}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkboxInstall2}
        alt="Checkbox Install 2 Purple"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrow}
        alt="Shift Change Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrowGreen}
        alt="Shift Change Arrow Green"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrowRed}
        alt="Shift Change Arrow Red"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={time}
        alt="Time"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitTick}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitCross}
        alt="Unit Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyFilter}
        alt="Filter White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClock}
        alt="Rota Clock"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  const { currencySymbol, checkUserState } = useContext(UserContext);
  const { mob, full, fullButNarrow, iPad } = useContext(StyleContext);
  const { calPage, updatedItem, setCalPage } = useContext(CalendarContext);
  const {
    device,
    selectedShiftTeam,
    setSelectedShiftTeam,
    setAddShiftPage,
    setAddShiftOrigin,
    setAddTilUserID,
    setAddTilSelf,
    setAddTilTeam,
    showAddTilModal,
    setShowAddTilModal,
    setAddTilPage,
    setAddTilUserKnown,
    updateShiftsOnceItemAdded,

    setShowEditShiftModal,
    updateShifts,
    setUpdateShifts,
    updateShiftReqs,
    update2,
    setOpenManagerShiftModal,
    setPage,
    loadUser,
    setLoadUser,
    selectedName,
    setSelectedName,
    selectedDay,
    setSelectedDay,
    availableTeamsForShifts,
    setIndicate,
    showAddLeaveOnTeam,
    setShowAddLeaveOnTeam,
    setShowTeamSwapsModal,
    removeLoggedShiftID,
    setRemoveLoggedShiftID,
    setShowEditUser,

    countData,
    setCountData,
    showMobMenu,
    showNotifications,
    showAddAvailModal,
    showTimesheet,
    showClockOnModal,
    showAddOptions,
    showOpenShiftsMgrModal,
    showUnclocked,
    showScheduledTodayModal,
    showActiveClocks,
    showMgrAcceptanceModal,
    showTeamSwapsModal,
    showSwapsModal,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      checkUserState,
      currencySymbol, //
      mob, //
      full, //
      fullButNarrow, //
      iPad, //
      calPage, //
      updatedItem, //
      setCalPage, //
      device, //
      selectedShiftTeam, //
      setSelectedShiftTeam, //
      setAddShiftPage, //
      setAddShiftOrigin, //
      setAddTilUserID, //
      setAddTilSelf, //
      setAddTilTeam, //
      showAddTilModal, //
      setShowAddTilModal, //
      setAddTilPage, //
      setAddTilUserKnown, //
      updateShiftsOnceItemAdded, //
      setShowEditShiftModal, //
      updateShifts, //
      setUpdateShifts, //
      updateShiftReqs, //
      update2, //
      setOpenManagerShiftModal, //
      setPage, //
      loadUser, //
      setLoadUser, //
      selectedName, //
      setSelectedName, //
      selectedDay, //
      setSelectedDay, //
      availableTeamsForShifts, //
      setIndicate, //
      showAddLeaveOnTeam, //
      setShowAddLeaveOnTeam, //
      setShowTeamSwapsModal, //
      removeLoggedShiftID,
      setRemoveLoggedShiftID,
      setShowEditUser,
      showMobMenu,
      showNotifications,
      showAddAvailModal,
      showTimesheet,
      showClockOnModal,
      showAddOptions,
      showOpenShiftsMgrModal,
      showUnclocked,
      showScheduledTodayModal,
      showActiveClocks,
      showMgrAcceptanceModal,
      showTeamSwapsModal,
      showSwapsModal,
    }),
    [
      checkUserState,
      currencySymbol, //
      mob, //
      full, //
      fullButNarrow, //
      iPad, //
      calPage, //
      updatedItem, //
      setCalPage, //
      device, //
      selectedShiftTeam, //
      setSelectedShiftTeam, //
      setAddShiftPage, //
      setAddShiftOrigin, //
      setAddTilUserID, //
      setAddTilSelf, //
      setAddTilTeam, //
      showAddTilModal, //
      setShowAddTilModal, //
      setAddTilPage, //
      setAddTilUserKnown, //
      updateShiftsOnceItemAdded, //
      setShowEditShiftModal, //
      updateShifts, //
      setUpdateShifts, //
      updateShiftReqs, //
      update2, //
      setOpenManagerShiftModal, //
      setPage, //
      loadUser, //
      setLoadUser, //
      selectedName, //
      setSelectedName, //
      selectedDay, //
      setSelectedDay, //
      availableTeamsForShifts, //
      setIndicate, //
      showAddLeaveOnTeam, //
      setShowAddLeaveOnTeam, //
      setShowTeamSwapsModal, //
      removeLoggedShiftID,
      setRemoveLoggedShiftID,
      setShowEditUser,
      showMobMenu,
      showNotifications,
      showAddAvailModal,
      showTimesheet,
      showClockOnModal,
      showAddOptions,
      showOpenShiftsMgrModal,
      showUnclocked,
      showScheduledTodayModal,
      showActiveClocks,
      showMgrAcceptanceModal,
      showTeamSwapsModal,
      showSwapsModal,
    ]
  );

  // let [loggedCount, setLoggedCount] = useState(0);
  // let [loggedCounted, setLoggedCounted] = useState(false);
  let [totDur, setTotDur] = useState(0);

  let [showTilOrShiftPopUp, setShowTilOrShiftPopUp] = useState(false);
  let [sureDeclineChange, setSureDeclineChange] = useState({ show: false });
  let [tilOrOvertime, setTilOrOvertime] = useState("overtime");
  let [scrollPosTop, setScrollPosTop] = useState(true);
  let [showAddShift, setShowAddShift] = useState(false);
  let [reqMy, setReqMy] = useState(
    `${
      new Date().getMonth() < 10 ? "0" : ""
    }${new Date().getMonth()}${JSON.stringify(new Date().getFullYear()).substr(
      2,
      2
    )}`
  );
  useEffect(() => {
    let today = new Date();
    let monthNum = today.getMonth();
    if (monthNum < 10) {
      monthNum = `0${monthNum}`;
    } else {
    }
    let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

    setReqMy(`${monthNum}${yearNum}`);

    memoVals.setCalPage((x) => "team-shifts");
  }, []);

  let [recordLateItemIDs, setRecordLateItemIDs] = useState([]);
  let [recordLateUnpaidItemIDs, setRecordLateUnpaidItemIDs] = useState([]);

  let [allowSelfHoursApprove, setAllowSelfHoursApprove] = useState([]);
  let [showFilter, setShowFilter] = useState(false);
  let [showShifts, setShowShifts] = useState(true);
  let [showTils, setShowTils] = useState(true);
  let [showOvertimes, setShowOvertimes] = useState(true);
  let [availableMonths, setAvailableMonths] = useState([]);
  let [allShiftsAndTils, setAllShiftsAndTils] = useState([]);
  let [my, setMy] = useState(
    `${
      new Date().getMonth() < 10 ? "0" : ""
    }${new Date().getMonth()}${JSON.stringify(new Date().getFullYear()).substr(
      2,
      2
    )}`
  );
  let [showOutstanding, setShowOutstanding] = useState(false);
  let [names, setNames] = useState([]);

  let [days, setDays] = useState([]);

  let [showRequests, setShowRequests] = useState(false);
  let [showLogged, setShowLogged] = useState(false);
  let [loggedLoading, setLoggedLoading] = useState(false);
  let [reqQty, setReqQty] = useState(0);
  let [shiftReqsLoaded, setShiftReqsLoaded] = useState(false);

  let [shiftReqArr, setShiftReqArr] = useState([]);
  let [shiftReqsLoading, setShiftReqsLoading] = useState(true);

  let [changesArr, setChangesArr] = useState([]);

  useEffect(() => {
    // clean not needed

    let arr = shiftReqArr.sort((a, b) => {
      return (
        dateStringer.createTimestampFromString(a.oldDate) -
        dateStringer.createTimestampFromString(b.oldDate)
      );
    });

    let pending = [];
    let nonPending = [];
    arr.forEach((x) => {
      if (!x.approved && !x.declined) {
        pending.push(x);
      } else {
        nonPending.push(x);
      }
    });

    setChangesArr([...pending, ...nonPending]);
  }, [shiftReqArr]);

  let [namesOfShiftReqs, setNamesOfShiftReqs] = useState([]);

  let [highlightedEditID, setHighlightedEditID] = useState("");

  useEffect(() => {
    if (highlightedEditID) {
      setHighlightedEditID("");
    }
  }, [changesArr, showRequests]);

  useEffect(() => {
    // clean not needed

    document.title = "Team hours - FlowRota";

    memoVals.setPage((x) => "team-shifts");
  }, []);

  useEffect(() => {
    // clean not needed

    memoVals.setSelectedShiftTeam((x) => "allStaff");
  }, []);
  useEffect(() => {
    if (memoVals.removeLoggedShiftID) {
      setAllShiftsAndTils((prevShifts) =>
        prevShifts.map((x) => {
          if (!x.dsTitle && x.typeID === memoVals.removeLoggedShiftID) {
            return {
              ...x,
              data: {
                ...x.data,
                logged: "", // Set the logged property to an empty string
              },
            };
          }
          return x; // No change for other objects
        })
      );

      memoVals.setRemoveLoggedShiftID(""); // Reset after updating
    }
  }, [memoVals.removeLoggedShiftID]);

  useEffect(() => {
    // clean not needed

    let newArr = [];
    if (memoVals.updatedItem.data) {
      let original = allShiftsAndTils.filter((x) => {
        return x.typeID === memoVals.updatedItem.key;
      });

      let outstandingChanges = 0;
      if (original[0]) {
        outstandingChanges = original[0].outstandingChanges;
      }

      let formattedItem = {
        picUrl: original[0] ? original[0].picUrl : "",
        tags: memoVals.updatedItem.tags,
        fName: memoVals.updatedItem.fName,
        lName: memoVals.updatedItem.lName,
        key: memoVals.updatedItem.key,
        startTs: memoVals.updatedItem.startTs,
        tilApproved: memoVals.updatedItem.tilApproved,
        tilStart: memoVals.updatedItem.tilStart,
        tilEnd: memoVals.updatedItem.tilEnd,
        type: memoVals.updatedItem.type,
        typeID: memoVals.updatedItem.key,
        cost: memoVals.updatedItem.cost,
        userID: memoVals.updatedItem.userID,
        startDs: memoVals.updatedItem.data.start,
        endDs: memoVals.updatedItem.data.end,

        teamName:
          memoVals.updatedItem.teamName || memoVals.updatedItem.data.teamName,
        outstandingChanges: outstandingChanges || 0,
        data: {
          logged: memoVals.updatedItem.data.logged,
          durMins: memoVals.updatedItem.data.durMins,
          selfLoggedAbsence: memoVals.updatedItem.selfLoggedAbsence,

          absent: memoVals.updatedItem.data.absent,
          ghost: memoVals.updatedItem.data.ghost,
          start:
            memoVals.updatedItem.type === "shift"
              ? memoVals.updatedItem.data.start
              : `${memoVals.updatedItem.data.start.substr(
                  12,
                  2
                )}:${memoVals.updatedItem.data.start.substr(15, 2)}`,
          end:
            memoVals.updatedItem.type === "shift"
              ? memoVals.updatedItem.data.end
              : `${memoVals.updatedItem.data.end.substr(
                  12,
                  2
                )}:${memoVals.updatedItem.data.end.substr(15, 2)}`,
          partialAbsent: memoVals.updatedItem.data.partialAbsent,
          partialAbsentStart: memoVals.updatedItem.data.partialAbsentStart,
          partialAbsentEnd: memoVals.updatedItem.data.partialAbsentEnd,

          unpaidAbsent: memoVals.updatedItem.data.unpaidAbsent,
          requireAccept: original[0] && original[0].data.requireAccept,
          accepted: memoVals.updatedItem.accepted
            ? memoVals.updatedItem.accepted
            : original[0] && original[0].data.accepted,
        },
      };

      if (!memoVals.updatedItem.deleted) {
        newArr.push(formattedItem);
      }

      allShiftsAndTils.forEach((item) => {
        if (item.key !== memoVals.updatedItem.key && !item.dsTitle) {
          newArr.push(item);
        }
      });

      setAllShiftsAndTils(newArr);
    }
  }, [memoVals.updatedItem]);

  useEffect(() => {
    // clean not needed

    setShiftReqsLoading(true);

    let tempMy = "0000";
    if (my === "0000") {
      let today = new Date();
      let monthNum = today.getMonth();
      if (monthNum < 10) {
        monthNum = `0${monthNum}`;
      } else {
      }
      let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

      tempMy = `${monthNum}${yearNum}`;
    }
  }, [reqMy, memoVals.updateShiftReqs, memoVals.selectedShiftTeam]);

  let [showSelfLoggedAbsenceOnly, setShowSelfLoggedAbsenceOnly] =
    useState(false);

  useEffect(() => {
    // clean not needed

    setShowOutstanding(false);
    setShowRequests(false);
  }, [memoVals.selectedShiftTeam]);

  let [selectedNameReq, setSelectedNameReq] = useState("allStaff");

  let generateNamesForShiftReqs = useMemo(() => {
    return namesOfShiftReqs.map((name) => {
      return (
        <option key={Math.random()} value={name.userID}>
          {name.fName}{" "}
          {selectedNameReq !== "allStaff" && selectedNameReq === name.userID
            ? name.lName[0]
            : name.lName}
        </option>
      );
    });
  }, [namesOfShiftReqs, selectedNameReq]);

  if (my === "0000") {
    let today = new Date();
    let monthNum = today.getMonth();
    if (monthNum < 10) {
      monthNum = `0${monthNum}`;
    } else {
    }
    let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

    setMy(`${monthNum}${yearNum}`);
    setReqMy(`${monthNum}${yearNum}`);
  }

  let [showCannotManageOwnShift, setShowCannotManageOwnShift] = useState({
    show: false,
  });

  const cancelSource1 = axios.CancelToken.source();
  // const cancelSource2 = axios.CancelToken.source();

  let [usersToCover, setUsersToCover] = useState([]);

  useEffect(() => {
    // set my to today's date
    let today = new Date();
    let monthNum = today.getMonth();
    if (monthNum < 10) {
      monthNum = `0${monthNum}`;
    } else {
    }
    let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

    setMy(`${monthNum}${yearNum}`);

    if (!availableMonths[0]) {
      axios
        .post(
          `${serverURL}/get-month-year-dropdown-options`,
          { teamID: "allStaff" },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            // setAvailableMonths(response.data.arr);

            if (!response.data.arr.includes(my)) {
              setAvailableMonths([my, ...response.data.arr]);
            } else {
              setAvailableMonths(response.data.arr);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    setLoading(true);

    return () => {
      cancelSource1.cancel("Component unmounted");
      // cancelSource2.cancel("Component unmounted");
    };
  }, [memoVals.calPage, memoVals.updateShiftReqs, memoVals.selectedShiftTeam]);

  const [currentDate, setCurrentDate] = useState(""); // State for the current top date

  const cancelSource3 = axios.CancelToken.source();
  const cancelSource4 = axios.CancelToken.source();
  const cancelSource5 = axios.CancelToken.source();
  const cancelSource6 = axios.CancelToken.source();
  // const cancelSource7 = axios.CancelToken.source();

  useEffect(() => {
    if (!showRequests) {
      let m = my.substr(0, 2);
      let y = my.substr(2, 2);

      if (m === "00" && y === "00") {
        let today = new Date();
        m = today.getMonth();
        y = today.getFullYear();
      }
      axios
        .post(
          `${serverURL}/get-shifts-and-till-all-staff`,
          {
            month: parseInt(m),
            year: parseInt(y),
            teamID: memoVals.selectedShiftTeam || "allStaff",
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            logged: showLogged,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource3.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setAllowSelfHoursApprove(response.data.allowSelfHoursApprove);
            // setReverse(response.data.reverse);

            setLoggedLoading(false);

            function insertDayTitles(array) {
              const result = [];
              let previousDay = null;

              array.forEach((item, index) => {
                // Extract the day from the startTs
                const currentDay = new Date(item.startTs)
                  .toISOString()
                  .split("T")[0]; // YYYY-MM-DD

                // Check if the day is different from the previous one
                if (currentDay !== previousDay) {
                  // Insert a new dsTitle object before items of a new day
                  let alreadyExists = result.filter((xx) => {
                    return (
                      xx.dsTitle ===
                      dateStringer.printedDateWithYrFromDs(item.startDs)
                    );
                  });
                  if (!alreadyExists[0]) {
                    result.push({
                      dsTitle: dateStringer.printedDateWithYrFromDs(
                        item.startDs
                      ),
                      startTs: item.startTs,
                      fName: "--",
                      data: {},
                    });

                    previousDay = currentDay;
                  }
                }

                // Add the current item to the result
                result.push(item);
              });

              return result;
            }

            const array = response.data.data;

            const newArray = insertDayTitles(array);

            setAllShiftsAndTils(response.data.data);

            // setCurrentDate(
            //   Array.isArray(response.data.data)
            //     ? response.data.data.sort((a, b) => {
            //         return a.startTs - b.startTs;
            //       })[0].startDs
            //     : ""
            // );
            // response.data.data.forEach()
            if (response.data.permissions === 3) {
              window.location.href = "/shifts";
            }
            setTotDur(response.data.totalDurationMins);
            let arr = [];
            let uids = [];
            let dayNums = [];
            response.data.names.forEach((unit) => {
              if (!uids.includes(unit.userID)) {
                uids.push(unit.userID);
                arr.push({
                  fName: unit.fName,
                  lName: unit.lName,
                  userID: unit.userID,
                });
              }

              // let fullName = `${unit.fName} ${unit.lName[0]}`;
              // if (!arr.includes(fullName)) {
              //   arr.push(fullName);
              // }
              let ds = dateStringer.createStringFromTimestamp(unit.startTs);
              if (!dayNums.includes(parseInt(`${ds.substr(9, 2)}`))) {
                dayNums.push(parseInt(`${ds.substr(9, 2)}`));
              }
            });

            arr.sort((a, b) => a.fName.localeCompare(b.fName));
            dayNums.sort(function (a, b) {
              return a - b;
            });
            setNames(arr);
            setDays(dayNums);

            if (dataLoaded) {
              setDataLoaded(false);
            }
            setDataLoaded(true);
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            if (params && params.requests) {
              setShowRequests(true);
              setHighlightedEditID(dateStringer.cleanParam(params.editID));
              // clear the params
              window.history.pushState(
                {},
                document.title,
                window.location.pathname
              );
            }

            if (params && params.userID) {
              setShowFilter(true);
              setSelectedName(params.userID);
              window.history.pushState(
                {},
                document.title,
                window.location.pathname
              );
            }

            if (params && params.logged) {
              setShowSelfLoggedAbsenceOnly(true);
              setShowLogged(true);
              window.history.pushState(
                {},
                document.title,
                window.location.pathname
              );
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (showRequests) {
      setShiftReqsLoading(true);
      // jamie
      axios
        .post(
          `${serverURL}/get-shift-edits-for-mgr`,
          {
            teamID: memoVals.selectedShiftTeam,
            countOnly: true,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource4.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            if (response.data.qty > 0) {
              setShowOutstanding(true);
              setSelectedChangeID("");
              setSelectedNameReq("allStaff");
              setShiftReqsLoading(true);

              axios
                .post(
                  `${serverURL}/get-shift-edits-for-mgr`,
                  {
                    teamID: memoVals.selectedShiftTeam,
                    my: "undecided",

                    nowDs: dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    ),
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message.includes("logout")) {
                    window.location.href = "/signin";
                  }
                  if (response.data.message === "success") {
                    let names = [];
                    let userIDs = [];
                    response.data.arr.forEach((ch) => {
                      if (!userIDs.includes(ch.userID)) {
                        userIDs.push(ch.userID);
                        names.push({
                          userID: ch.userID,
                          fName: `${ch.fName}`,
                          lName: `${ch.lName}`,
                        });
                      }
                    });
                    setNamesOfShiftReqs(names);

                    setShiftReqArr(response.data.arr);
                    setShiftReqsLoading(false);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            } else {
              axios
                .post(
                  `${serverURL}/get-shift-edits-for-mgr`,
                  {
                    teamID: memoVals.selectedShiftTeam,
                    my: reqMy === "0000" ? tempMy : reqMy,
                    nowDs: dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    ),
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                    cancelToken: cancelSource6.token,
                  }
                )
                .then((response) => {
                  if (response.data.message.includes("logout")) {
                    window.location.href = "/signin";
                  }
                  if (response.data.message === "success") {
                    let names = [];
                    let userIDs = [];
                    response.data.arr.forEach((ch) => {
                      if (!userIDs.includes(ch.userID)) {
                        userIDs.push(ch.userID);
                        names.push({
                          userID: ch.userID,
                          fName: `${ch.fName}`,
                          lName: `${ch.lName}`,
                        });
                      }
                    });
                    setNamesOfShiftReqs(names);

                    setShiftReqArr(response.data.arr);
                    setShiftReqsLoading(false);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }
            // }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource3.cancel("Component unmounted");
      cancelSource4.cancel("Component unmounted");
      cancelSource5.cancel("Component unmounted");
      cancelSource6.cancel("Component unmounted");
      // cancelSource7.cancel("Component unmounted");
    };
  }, [
    memoVals.updateShifts,
    memoVals.updateShiftsOnceItemAdded,
    memoVals.update2,
    memoVals.selectedShiftTeam,
    showRequests,
    showLogged,
  ]);

  useEffect(() => {
    // clean not neede
    if (memoVals.loadUser && memoVals.loadUser.userID) {
      if (showLogged) {
        setShowLogged(false);
      }
      if (showRequests) {
        setShowRequests(false);
      }
      setMy(memoVals.loadUser.my);
      memoVals.setSelectedName((x) => memoVals.loadUser.userID);
      setShowFilter(true);
      // coils
      memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
    } else {
      memoVals.setSelectedName((x) => "allNames");

      // setShowFilter(false);
    }
  }, [memoVals.loadUser]);

  let ddmmToMon21 = (month) => {
    let formatMonth = "Jan";
    let formatYear = month.substr(2, 2);
    if (month.substr(0, 2) === "00") {
      formatMonth = "Jan";
    }
    if (month.substr(0, 2) === "01") {
      formatMonth = "Feb";
    }
    if (month.substr(0, 2) === "02") {
      formatMonth = "Mar";
    }
    if (month.substr(0, 2) === "03") {
      formatMonth = "Apr";
    }
    if (month.substr(0, 2) === "04") {
      formatMonth = "May";
    }
    if (month.substr(0, 2) === "05") {
      formatMonth = "Jun";
    }
    if (month.substr(0, 2) === "06") {
      formatMonth = "Jul";
    }
    if (month.substr(0, 2) === "07") {
      formatMonth = "Aug";
    }
    if (month.substr(0, 2) === "08") {
      formatMonth = "Sep";
    }
    if (month.substr(0, 2) === "09") {
      formatMonth = "Oct";
    }
    if (month.substr(0, 2) === "10") {
      formatMonth = "Nov";
    }
    if (month.substr(0, 2) === "11") {
      formatMonth = "Dec";
    }

    return `${formatMonth} ${formatYear}`;
  };

  let getTeamName = (teamID, teams) => {
    if (teamID) {
      if (teamID === "allStaff") {
        return "all staff you manage";
      } else {
        let teamFilt = teams.filter((item) => {
          return item.teamID === memoVals.selectedShiftTeam;
        });

        if (teamFilt[0]) {
          return teamFilt[0].teamName;
        }
      }
    }
  };

  let generateTeamsSelectBar = useMemo(() => {
    return memoVals.availableTeamsForShifts
      ? memoVals.availableTeamsForShifts.map((team) => {
          return (
            <option key={team.teamID} value={team.teamID}>
              {dateStringer.shorten(
                team.teamName,
                selectedShiftTeam === "allStaff" ? 100 : 19
              )}
            </option>
          );
        })
      : [];
  }, [memoVals.availableTeamsForShifts, selectedShiftTeam]);

  let generateMonthOptions = (isChanges, shorten) => {
    return useMemo(() => {
      return availableMonths.map((month) => {
        let formatMonth = memoVals.mob && !isChanges ? "January" : "Jan"; // default value
        let formatYear = month.substr(2, 2); // Extract last 2 characters for year

        switch (month.substr(0, 2)) {
          case "00":
            formatMonth =
              memoVals.mob && !isChanges && !shorten ? "January" : "Jan";
            break;
          case "01":
            formatMonth =
              memoVals.mob && !isChanges && !shorten ? "February" : "Feb";
            break;
          case "02":
            formatMonth =
              memoVals.mob && !isChanges && !shorten ? "March" : "Mar";
            break;
          case "03":
            formatMonth =
              memoVals.mob && !isChanges && !shorten ? "April" : "Apr";
            break;
          case "04":
            formatMonth =
              memoVals.mob && !isChanges && !shorten ? "May" : "May";
            break;
          case "05":
            formatMonth =
              memoVals.mob && !isChanges && !shorten ? "June" : "Jun";
            break;
          case "06":
            formatMonth =
              memoVals.mob && !isChanges && !shorten ? "July" : "Jul";
            break;
          case "07":
            formatMonth =
              memoVals.mob && !isChanges && !shorten ? "August" : "Aug";
            break;
          case "08":
            formatMonth =
              memoVals.mob && !isChanges && !shorten ? "September" : "Sep";
            break;
          case "09":
            formatMonth =
              memoVals.mob && !isChanges && !shorten ? "October" : "Oct";
            break;
          case "10":
            formatMonth =
              memoVals.mob && !isChanges && !shorten ? "November" : "Nov";
            break;
          case "11":
            formatMonth =
              memoVals.mob && !isChanges && !shorten ? "December" : "Dec";
            break;
          default:
            formatMonth = "Invalid";
        }

        return (
          <option key={month} value={month}>
            {formatMonth} {formatYear}
          </option>
        );
      });
    }, [availableMonths, memoVals.mob, isChanges, window.innerWidth]); // Dependencies
  };

  // allShiftsAndTils.sort(function (a, b) {
  //   return b.startTs - a.startTs;
  // });

  function customSort(a, b) {
    // Handle the case where a.dsTitle does not exist
    if (!a.dsTitle) {
      if (a.startTs < b.startTs + 1) {
        return -1;
      } else if (a.startTs > b.startTs + 1) {
        return 1;
      }
    } else {
      // If a.dsTitle exists, compare with b.startTs + 1
      if (a.startTs < b.startTs + 1) {
        return -1;
      } else if (a.startTs > b.startTs + 1) {
        return 1;
      }
    }
    // If startTs (adjusted) is equal, compare by fName alphabetically
    return a.fName.localeCompare(b.fName);
  }

  allShiftsAndTils.sort(customSort);
  // allShiftsAndTils.sort(function (a, b) {
  //   //   if (!reverse) {
  //   // return b.startTs - a.startTs;
  //   //   } else {
  //   return a.startTs - b.startTs;
  //   //   }
  // });

  // ------------------------------------------------------------------------
  let [itemType, setItemType] = useState("");
  let [itemID, setItemID] = useState("");

  let [hoveredTick, setHoveredTick] = useState("");

  // ------------------------------------------------------------------------

  useEffect(() => {
    // clean not needed

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && params.swaps) {
      memoVals.setShowTeamSwapsModal((x) => {
        return { show: true, history: params.history ? true : false };
      });
      window.history.pushState({}, document.title, window.location.pathname);
    }
    if (params && params.shiftID && params.type && params.mgr) {
      if (dateStringer.cleanParam(params.type) === "unknown") {
        axios
          .post(
            `${serverURL}/check-shift-til`,
            {
              shiftID: dateStringer.cleanParam(params.shiftID),
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message.includes("logout")) {
              window.location.href = "/signin";
            }
            if (response.data.message === "success") {
              memoVals.setShowEditShiftModal((x) => {
                return {
                  itemID: dateStringer.cleanParam(params.shiftID),
                  itemType: response.data.type,
                  itemFName: dateStringer.cleanParam(params.fName),
                  itemLName: dateStringer.cleanParam(params.lName),
                  itemIsNew: false,
                  userID: dateStringer.cleanParam(params.userID),
                };
              });
            }
          });
      } else {
        memoVals.setShowEditShiftModal((x) => {
          return {
            itemID: dateStringer.cleanParam(params.shiftID),
            itemType: dateStringer.cleanParam(params.type),
            itemFName: dateStringer.cleanParam(params.fName),
            itemLName: dateStringer.cleanParam(params.lNam),
            itemIsNew: false,
            userID: dateStringer.cleanParam(params.userID),
          };
        });
      }
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  // ------------

  let formatTag = (tag) => {
    if (tag && tag.length > 21) {
      return `${tag.substr(0, 18)}...`;
    } else return tag;
  };

  // ------------------------------------------------------------------------

  let generateNameOptions = useMemo(() => {
    return names.map((name) => {
      return (
        <option key={Math.random()} value={name.userID}>
          {name.fName} {name.lName[0]}
        </option>
      );
    });
  }, [names]);

  shiftReqArr.sort(function (a, b) {
    return (
      dateStringer.createTimestampFromString(b.dateMade) -
      dateStringer.createTimestampFromString(a.dateMade)
    );
  });

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  let [selectedChangeID, setSelectedChangeID] = useState("");

  let generateShiftRequests = useMemo(() => {
    return changesArr
      .sort(function (a, b) {
        // Custom sorting logic
        if (a.editID === highlightedEditID) {
          return -1; // 'a' comes first
        } else if (b.editID === highlightedEditID) {
          return 1; // 'b' comes first
        } else {
          return 0; // no change in order
        }
      })
      .map((change) => {
        if (change.split && !showOutstanding) {
          return <div className="pendingTsSplitter"></div>;
        } else {
          if (!change.split) {
            if (!change.takeTilRequest) {
              return (
                <div
                  className={`shiftChangeRowParent ${
                    change.drop && !change.coverFName && !change.declined
                      ? "droppedNoFwdHeightParent"
                      : ""
                  }`}
                >
                  {!change.approved && !change.declined && (
                    <div
                      className={`shiftChangeBlueBar ${
                        change.drop
                          ? `shiftChangeBlueBarDrop ${
                              !change.coverFName && !change.declined
                                ? "shiftChangeBlueBarDropNoCover"
                                : ""
                            }`
                          : ""
                      }`}
                    ></div>
                  )}

                  <div
                    className={`${
                      selectedChangeID === change.editID
                        ? "shiftChangeRow selectedShiftChangeRow"
                        : "shiftChangeRow selectedShiftChangeRow"
                    } ${
                      highlightedEditID === change.editID
                        ? "highlighedChangeID"
                        : ""
                    } ${
                      selectedNameReq === change.userID ||
                      selectedNameReq === "allStaff"
                        ? ""
                        : "none"
                    }  ${
                      !change.approved && !change.declined
                        ? "undeciedBorderLeftChange"
                        : ""
                    } ${
                      change.drop && !change.coverFName && !change.declined
                        ? "droppedNoFwdHeight"
                        : ""
                    }
            
            ${
              !change.approved && change.declined
                ? "declinedBorderLeftChange"
                : ""
            }

            ${
              change.approved && !change.declined
                ? "approvedBorderLeftChange"
                : ""
            } ${memoVals.mob ? "borderRadius10" : ""}`}
                    key={change.editID}
                    onClick={() => {
                      if (change.editID === selectedChangeID) {
                        setSelectedChangeID("");
                      } else {
                        setSelectedChangeID(change.editID);
                      }
                      // setShiftID("");
                      // setIsTilTake(false);
                    }}
                  >
                    <div className="shiftChangeTitleAndNote">
                      {change.drop ? (
                        <p className="shiftChangeTitleTilOrChange">
                          <img
                            src={change.profPicUrl || profilePicturePlaceholder}
                            alt={change.fName}
                            className="shChProPic"
                          />
                          Drop request
                        </p>
                      ) : (
                        <p className="shiftChangeTitleTilOrChange">
                          <img
                            src={change.profPicUrl || profilePicturePlaceholder}
                            alt={change.fName}
                            className="shChProPic"
                          />
                          {change.newType === "shift"
                            ? "Shift"
                            : `${
                                change.newType === "overtime"
                                  ? "Overtime"
                                  : "Overtime (time in lieu)"
                              }`}{" "}
                          change request
                        </p>
                      )}
                      <p></p>
                    </div>

                    <div className="shiftChangeTitleRow">
                      <p className="shiftChangeType">
                        {change.you ? (
                          <span className="shiftTypeSpan">You</span>
                        ) : (
                          <span className="shiftTypeSpan">{`${change.fName} ${change.lName[0]}`}</span>
                        )}{" "}
                        {change.drop
                          ? `requested to drop ${
                              change.you ? "your" : "their"
                            } `
                          : `requested a change to ${
                              change.you ? "your" : "their"
                            } `}
                        <span className="shiftTypeSpan">
                          {change.newType === "shift"
                            ? "shift"
                            : `${
                                change.newType === "overtime"
                                  ? "overtime"
                                  : "overtime (time in lieu)"
                              }`}
                        </span>{" "}
                      </p>

                      {/* {change.drop && change.coverFName && !change.declined ? (
                      <div className="dropReqSuggOtherUser">
                        <p>
                          {change.you ? "You have" : `${change.fName} has`} put{" "}
                          {change.coverFName} {change.coverLName[0]} forward to
                          cover it
                        </p>
                        <img
                          src={
                            change.coverProfPicUrl || profilePicturePlaceholder
                          }
                          alt="Swap"
                          className="shChSwapImg"
                        />
                      </div>
                    ) : (
                      ""
                    )} */}
                    </div>
                    <div className="changeTitleRowSubBar">
                      <p className="shiftChangeMadeDate">
                        <span className="reqOnSpan">
                          {`${
                            !change.approved && !change.declined
                              ? "Requested"
                              : `${
                                  change.approved && !change.declined
                                    ? "Approved"
                                    : "Declined"
                                }`
                          }`}{" "}
                          on
                        </span>{" "}
                        {dateStringer.printedDateFromDs(change.dateMade)}
                        <span className="reqOnSpan"> at</span>{" "}
                        {dateStringer.tsToHHMM(
                          dateStringer.createTimestampFromString(
                            change.dateMade
                          )
                        )}
                      </p>
                      <p
                        className={`${
                          change.requested
                            ? `${
                                change.approved && !change.declined
                                  ? "changeStatusApproved"
                                  : `${
                                      change.declined
                                        ? "changeStatusDeclined"
                                        : "changeStatus"
                                    }`
                              } `
                            : "none"
                        }`}
                      >
                        {`${
                          change.approved && !change.declined
                            ? "Approved"
                            : `${change.declined ? "Declined" : "Pending"}`
                        }`}
                      </p>
                    </div>
                    <div
                      className={`changeRowMain ${
                        change.requested && change.declined
                          ? "declinedChange"
                          : ""
                      }`}
                    >
                      <div
                        className="changeRowLeft"
                        onClick={() => {
                          memoVals.setShowEditShiftModal((x) => {
                            return {
                              itemID: change.shiftID,
                              itemType: change.newType || change.type,
                              itemFName: change.fName,
                              itemLName: change.lName,
                              itemIsNew: false,
                              userID: change.userID,
                            };
                          });
                        }}
                      >
                        <p className="oldShiftDate textAlignLeft">
                          <img
                            src={rotaDate}
                            alt="Shift change date"
                            className="shShIcon shShIcon1"
                          />{" "}
                          {dateStringer.printedDateWithYrFromDs(change.oldDate)}
                        </p>
                        <p className="oldShiftTimes textAlignLeft">
                          <img
                            src={rotaClock}
                            alt="Shift change date"
                            className="shShIcon"
                          />{" "}
                          {change.oldStart} - {change.oldEnd}
                        </p>
                        <p className="oldShiftBrk textAlignLeft">
                          <img
                            src={rotaCoffee}
                            alt="Shift change date"
                            className="shShIcon"
                          />{" "}
                          {dateStringer.formatMinsDurationToHours(
                            change.oldBrk
                          )}
                        </p>
                        <p className="oldShiftBrk textAlignLeft bbcada">
                          <img
                            src={rotaTeam}
                            alt="Shift change date"
                            className="shShIcon"
                          />{" "}
                          {change.teamName || "Unknown team"}
                        </p>
                      </div>

                      <div className="changeRowMiddle">
                        <img
                          src={shiftChangeArrow}
                          alt="arrow"
                          className={`changeShiftArrowImg ${
                            change.drop ? "invis" : ""
                          }`}
                        />
                      </div>
                      {change.drop && change.coverUserID ? (
                        !change.declined ? (
                          <div className="dropReqSuggOtherUser">
                            <p>
                              {change.you ? "You have" : `${change.fName} has`}{" "}
                              put {change.coverFName} {change.coverLName[0]}{" "}
                              forward to cover it
                            </p>
                            <img
                              src={
                                change.coverProfPicUrl ||
                                profilePicturePlaceholder
                              }
                              alt="Swap"
                              className="shChSwapImg"
                              onClick={() => {
                                memoVals.setShowEditUser(
                                  (x) => change.coverUserID
                                );
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div
                          className={`changeRowRight ${
                            change.drop ? "invis" : ""
                          }`}
                          onClick={() => {
                            memoVals.setShowEditShiftModal((x) => {
                              return {
                                itemID: change.shiftID,
                                itemType: change.newType || change.type,
                                itemFName: change.fName,
                                itemLName: change.lName,
                                itemIsNew: false,
                                userID: change.userID,
                              };
                            });
                          }}
                        >
                          <p
                            className={`oldShiftDate newShiftDate ${
                              change.oldDate !== change.newDate
                                ? "colour0077ff"
                                : ""
                            }`}
                          >
                            {dateStringer.printedDateWithYrFromDs(
                              change.newDate
                            )}

                            <img
                              src={rotaDate}
                              alt="TOIL to use"
                              className="shChImgRight"
                            />
                          </p>
                          <p className={`oldShiftDate newShiftDate x239499211`}>
                            <span
                              className={
                                change.newStart !== change.oldStart
                                  ? "colour0077ff"
                                  : ""
                              }
                            >
                              {change.newStart}
                            </span>{" "}
                            <span className="newShTimesSpaceSpan2"></span>-{" "}
                            <span className="newShTimesSpaceSpan2"></span>
                            <span
                              className={
                                change.newEnd !== change.oldEnd
                                  ? "colour0077ff"
                                  : ""
                              }
                            >
                              {change.newEnd}
                            </span>
                            <img
                              src={rotaClock}
                              alt="TOIL to use"
                              className="shChImgRight"
                            />
                          </p>
                          <div
                            className={`tilTimesStrip tilTimesStripNewSh x2202055 _ ${
                              change.newBrk !== change.oldBrk
                                ? "colour0077ff"
                                : ""
                            }`}
                          >
                            {/* <p className="tilStripTitle">TOIL: </p> */}
                            {dateStringer.formatMinsDurationToHours(
                              change.newBrk
                            )}
                            <img
                              src={rotaCoffee}
                              alt="TOIL to use"
                              className="rotaClockNewShiMG x200205552"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="decisionRequestShiftDiv">
                      {change.note ? (
                        <p className="changeNoteDiv">
                          <span className="changeNoteTitleSpan">
                            {change.fName}:{" "}
                          </span>
                          {change.note}
                        </p>
                      ) : (
                        ""
                      )}
                      <div
                        className={`${
                          selectedChangeID === change.editID
                            ? "deleteChangeUnit"
                            : "deleteChangeUnit"
                        }`}
                      >
                        <div className="deleteChangeUnitLeft">
                          <img
                            src={rotaDur}
                            alt="Old new duration"
                            className="chShRotaDur"
                          />{" "}
                          {dateStringer.formatMinsDurationToHours(
                            change.oldDuration,
                            false,
                            true
                          )}{" "}
                          <img
                            src={rightArr}
                            alt="Duration"
                            className="oldDurRightArrImg"
                          />{" "}
                          <span
                            className={`${
                              change.drop ||
                              change.newDuration !== change.oldDuration
                                ? "colour0077ff"
                                : ""
                            }`}
                          >
                            {dateStringer.formatMinsDurationToHours(
                              change.drop ? 0 : change.newDuration,
                              false,
                              true
                            )}
                          </span>
                          {/* <p className="oldChangeUnitDur">
                          <span className="oldDurationTitleSpan">
                            Old duration:&nbsp;
                          </span>
                          {dateStringer.formatMinsDurationToHours(
                            change.oldDuration,
                            false,
                            true
                          )}
                        </p>
                        <p className="oldChangeUnitDur">
                          <span className="oldDurationTitleSpan">
                            New duration:
                          </span>{" "}
                          {dateStringer.formatMinsDurationToHours(
                            change.newDuration,
                            false,
                            true
                          )}
                        </p> */}
                        </div>
                        {change.approved || change.declined ? (
                          <p
                            className={`${
                              change.approved || change.declined
                                ? "shiftEditDecidedBy"
                                : "none"
                            }`}
                          >
                            <span className="decidedBySpan">{`${
                              change.approved ? "Approved by " : "Declined by "
                            }`}</span>
                            <br />
                            {change.decidedBy || "unknown"}
                          </p>
                        ) : change.blockApprove ? (
                          <p
                            className="approveSwapBlocked"
                            onClick={() => {
                              setShowCannotManageOwnShift({
                                show: true,
                                teamName: change.teamName,
                              });
                            }}
                          >
                            You cannot approve your own swaps as a manager
                          </p>
                        ) : (
                          <div className="approveOrDeclineChangeReqDiv">
                            <div className="approveDecShDropParent">
                              <p
                                className="approveShChangeReqBtn"
                                onClick={() => {
                                  // setApproveLoading(true);
                                  let today00 = new Date();
                                  today00.setHours(0, 0, 0, 0);
                                  setShiftReqsLoading(true);
                                  let newArr = [];
                                  shiftReqArr.forEach((obj) => {
                                    if (
                                      obj.editID !== change.editID ||
                                      change.takeTilRequest
                                    ) {
                                      newArr.push(obj);
                                    }
                                  });

                                  // let qty =
                                  //   countData.shiftEdits +
                                  //   countData.ttrs +
                                  //   countData.drops;
                                  if (
                                    dateStringer.createTimestampFromString(
                                      change.oldDate
                                    ) >= today00.getTime()
                                  ) {
                                    if (change.drop) {
                                      setCountData({
                                        drops: countData.drops - 1,
                                      });
                                    } else {
                                      setCountData({
                                        shiftEdits: countData.shiftEdits - 1,
                                      });
                                    }

                                    // qty--;

                                    // setReqQty(qty);
                                  }

                                  setShiftReqArr(newArr);
                                  axios
                                    .post(
                                      `${serverURL}/approve-or-decline-shift-edit`,
                                      {
                                        editID: change.editID,
                                        userID: change.userID,
                                        decision: "approve",
                                        nowDs:
                                          dateStringer.createStringFromTimestamp(
                                            new Date().getTime()
                                          ),
                                        coverUserID:
                                          change.drop && change.coverUserID
                                            ? true
                                            : "",
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (
                                        response.data.message.includes("logout")
                                      ) {
                                        window.location.href = "/signin";
                                      }
                                      if (response.data.message === "success") {
                                        // setApproveLoading(false);

                                        let newArr = [];
                                        shiftReqArr.forEach((obj) => {
                                          if (
                                            obj.editID !== change.editID ||
                                            change.takeTilRequest
                                          ) {
                                            newArr.push(obj);
                                          }
                                        });

                                        // let qty =
                                        //   countData.shiftEdits +
                                        //   countData.ttrs +
                                        //   countData.drops;
                                        if (
                                          dateStringer.createTimestampFromString(
                                            change.oldDate
                                          ) >= today00.getTime()
                                        ) {
                                          if (change.drop) {
                                            setCountData({
                                              drops: countData.drops - 1,
                                            });
                                          } else {
                                            setCountData({
                                              shiftEdits:
                                                countData.shiftEdits - 1,
                                            });
                                          }
                                          // qty--;

                                          // setReqQty(qty);
                                        }
                                        setShiftReqArr(newArr);
                                        setTimeout(() => {
                                          setShiftReqsLoading(false);
                                        }, 100);

                                        // setUpdateShiftReqs(!memoVals.updateShiftReqs);
                                      }

                                      if (
                                        response.data.message.includes(
                                          "no longer exist"
                                        )
                                      ) {
                                        // setApproveLoading(false);

                                        let newArr = [];
                                        shiftReqArr.forEach((obj) => {
                                          if (
                                            obj.editID !== change.editID ||
                                            change.takeTilRequest
                                          ) {
                                            newArr.push(obj);
                                          }
                                        });

                                        // let qty =
                                        //   countData.shiftEdits +
                                        //   countData.ttrs +
                                        //   countData.drops;
                                        if (
                                          dateStringer.createTimestampFromString(
                                            change.oldDate
                                          ) >= today00.getTime()
                                        ) {
                                          if (change.drop) {
                                            setCountData({
                                              drops: countData.drops - 1,
                                            });
                                          } else {
                                            setCountData({
                                              shiftEdits:
                                                countData.shiftEdits - 1,
                                            });
                                          }
                                          // qty--;

                                          // setReqQty(qty);
                                        }
                                        setShiftReqArr(newArr);
                                        setShiftReqsLoading(false);
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                }}
                              >
                                Approve{" "}
                                {change.drop && change.coverUserID
                                  ? "cover"
                                  : ""}
                                <img
                                  src={whiteTick}
                                  alt="Approve"
                                  className="shAppWhiteTickImg"
                                />
                              </p>

                              {change.drop && change.coverUserID ? (
                                <p
                                  className="approveShChangeReqBtn dropOnlyApp"
                                  onClick={() => {
                                    // setApproveLoading(true);
                                    setShiftReqsLoading(true);
                                    let newArr = [];
                                    shiftReqArr.forEach((obj) => {
                                      if (
                                        obj.editID !== change.editID ||
                                        change.takeTilRequest
                                      ) {
                                        newArr.push(obj);
                                      }
                                    });
                                    // let qty =
                                    //   countData.shiftEdits +
                                    //   countData.ttrs +
                                    //   countData.drops;
                                    let today00 = new Date();
                                    today00.setHours(0, 0, 0, 0);
                                    if (
                                      dateStringer.createTimestampFromString(
                                        change.oldDate
                                      ) >= today00.getTime()
                                    ) {
                                      if (change.drop) {
                                        setCountData({
                                          drops: countData.drops - 1,
                                        });
                                      } else {
                                        setCountData({
                                          shiftEdits: countData.shiftEdits - 1,
                                        });
                                      }
                                      // qty--;

                                      // setReqQty(qty);
                                    }
                                    setShiftReqArr(newArr);
                                    axios
                                      .post(
                                        `${serverURL}/approve-or-decline-shift-edit`,
                                        {
                                          editID: change.editID,
                                          userID: change.userID,
                                          decision: "approve",
                                          nowDs:
                                            dateStringer.createStringFromTimestamp(
                                              new Date().getTime()
                                            ),
                                          coverUserID: false,
                                        },
                                        {
                                          withCredentials: true,
                                          credentials: "include",
                                        }
                                      )
                                      .then((response) => {
                                        if (
                                          response.data.message.includes(
                                            "logout"
                                          )
                                        ) {
                                          window.location.href = "/signin";
                                        }
                                        if (
                                          response.data.message === "success"
                                        ) {
                                          // setApproveLoading(false);
                                          let newArr = [];
                                          shiftReqArr.forEach((obj) => {
                                            if (
                                              obj.editID !== change.editID ||
                                              change.takeTilRequest
                                            ) {
                                              newArr.push(obj);
                                            }
                                          });
                                          // let qty =
                                          //   countData.shiftEdits +
                                          //   countData.ttrs +
                                          //   countData.drops;

                                          if (
                                            dateStringer.createTimestampFromString(
                                              change.oldDate
                                            ) >= today00.getTime()
                                          ) {
                                            if (change.drop) {
                                              setCountData({
                                                drops: countData.drops - 1,
                                              });
                                            } else {
                                              setCountData({
                                                shiftEdits:
                                                  countData.shiftEdits - 1,
                                              });
                                            }
                                            // qty--;

                                            // setReqQty(qty);
                                          }
                                          setShiftReqArr(newArr);
                                          setTimeout(() => {
                                            setShiftReqsLoading(false);
                                          }, 100);
                                          // setUpdateShiftReqs(!memoVals.updateShiftReqs);
                                        }
                                        if (
                                          response.data.message.includes(
                                            "no longer exist"
                                          )
                                        ) {
                                          // setApproveLoading(false);
                                          let newArr = [];
                                          shiftReqArr.forEach((obj) => {
                                            if (
                                              obj.editID !== change.editID ||
                                              change.takeTilRequest
                                            ) {
                                              newArr.push(obj);
                                            }
                                          });
                                          // let qty =
                                          //   countData.shiftEdits +
                                          //   countData.ttrs +
                                          //   countData.drops;

                                          if (
                                            dateStringer.createTimestampFromString(
                                              change.oldDate
                                            ) >= today00.getTime()
                                          ) {
                                            if (change.drop) {
                                              setCountData({
                                                drops: countData.drops - 1,
                                              });
                                            } else {
                                              setCountData({
                                                shiftEdits:
                                                  countData.shiftEdits - 1,
                                              });
                                            }
                                            // qty--;

                                            // setReqQty(qty);
                                          }
                                          setShiftReqArr(newArr);
                                          setShiftReqsLoading(false);
                                        }
                                      })
                                      .catch((err) => {
                                        console.error(err);
                                      });
                                  }}
                                >
                                  Drop only
                                  <img
                                    src={whiteTick}
                                    alt="Approve"
                                    className="shAppWhiteTickImg"
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            <p
                              className="declineShChangeReqBtn"
                              onClick={() => {
                                // setApproveLoading(true);

                                setSureDeclineChange({
                                  show: true,
                                  change: true,
                                  toil: false,
                                  editID: change.editID,
                                  userID: change.userID,
                                  ds: change.oldDate || change.oldDs,
                                });
                              }}
                            >
                              Decline
                            </p>
                          </div>
                        )}
                      </div>

                      {/* end of sub bar */}
                    </div>
                    {/* end of decision sub bar */}

                    {/* <p>{change.oldDate}</p> */}
                  </div>
                </div>
              );
            }

            if (change.takeTilRequest) {
              return (
                <div className="shiftChangeRowParent">
                  {!change.approved && !change.declined && (
                    <div className="shiftChangeBlueBar shiftChangeBlueBarToil"></div>
                  )}
                  <div
                    className={`${
                      selectedChangeID === change.takeTilID
                        ? "shiftChangeRow selectedShiftChangeRow"
                        : "shiftChangeRow"
                    } ${
                      selectedNameReq === change.userID ||
                      selectedNameReq === "allStaff"
                        ? ""
                        : "none"
                    } ${
                      !change.approved && !change.declined
                        ? "undeciedBorderLeftChange"
                        : ""
                    }
            
            ${
              !change.approved && change.declined
                ? "declinedBorderLeftChange"
                : ""
            }

            ${
              change.approved && !change.declined
                ? "approvedBorderLeftChange"
                : ""
            } ${memoVals.mob ? "borderRadius10" : ""}
            
            `}
                    key={change.takeTilID}
                    onClick={() => {
                      if (change.takeTilID === selectedChangeID) {
                        setSelectedChangeID("");
                      } else {
                        setSelectedChangeID(change.takeTilID);
                      }

                      // setIsTilTake(true);
                      // setShiftID(change.shiftID);
                    }}
                  >
                    <div className="shiftChangeTitleAndNote">
                      <p className="shiftChangeTitleTilOrChange">
                        <img
                          src={change.profPicUrl || profilePicturePlaceholder}
                          alt={change.fName}
                          className="shChProPic"
                        />
                        Request to use TOIL
                      </p>
                      <p></p>
                    </div>{" "}
                    <div className="shiftChangeTitleRow">
                      <p className="shiftChangeType">
                        {change.you ? (
                          <span className="shiftTypeSpan">You</span>
                        ) : (
                          <span className="shiftTypeSpan">{`${change.fName} ${change.lName[0]}`}</span>
                        )}{" "}
                        asked to shortern {change.you ? "your" : "their"}{" "}
                        <span className="shiftTypeSpan">
                          {change.type === "shift" ? "shift" : "overtime"}
                        </span>{" "}
                        with {change.you ? "your" : "their"} TOIL allowance
                      </p>
                    </div>{" "}
                    <div className="changeTitleRowSubBar">
                      <p className="shiftChangeMadeDate">
                        <span className="reqOnSpan">
                          {`${
                            !change.approved && !change.declined
                              ? "Requested"
                              : `${
                                  change.approved && !change.declined
                                    ? "Approved"
                                    : "Declined"
                                }`
                          }`}{" "}
                          on
                        </span>{" "}
                        {dateStringer.printedDateFromDs(change.dateMade)}
                        <span className="reqOnSpan"> at</span>{" "}
                        {dateStringer.tsToHHMM(
                          dateStringer.createTimestampFromString(
                            change.dateMade
                          )
                        )}
                      </p>
                      <p
                        className={`${
                          change.requested
                            ? `${
                                change.approved && !change.declined
                                  ? "changeStatusApproved"
                                  : `${
                                      change.declined
                                        ? "changeStatusDeclined"
                                        : "changeStatus"
                                    }`
                              } `
                            : "none"
                        }`}
                      >
                        {`${
                          change.approved && !change.declined
                            ? "Approved"
                            : `${change.declined ? "Declined" : "Pending"}`
                        }`}
                      </p>
                    </div>
                    {!change.approved && !change.declined ? (
                      <div className="changeTitleRowSubBar toilAllowRow">
                        <div className="shiftChangeMadeDate">
                          {change.tilMins <= change.availableTil ? (
                            <span className="sufficientTil">
                              Sufficient allowance{" "}
                              <img
                                src={unitTick}
                                alt="Sufficient"
                                className="sufficientTilTick"
                              />
                            </span>
                          ) : (
                            <span className="insufficientTil">
                              Insufficient allowance{" "}
                              <img
                                src={unitCross}
                                alt="Sufficient"
                                className="insufficientTilTick"
                              />
                            </span>
                          )}{" "}
                          <p
                            className={`x2831981s1981 ${
                              change.availableTil <= 0 ? "ba0000" : ""
                            }`}
                          >
                            {dateStringer.formatMinsDurationToHours(
                              change.availableTil,
                              false,
                              true
                            )}{" "}
                            remaining
                          </p>
                        </div>
                        {/* <p
                className={`${
                  change.requested
                    ? `${
                        change.approved && !change.declined
                          ? "changeStatusApproved"
                          : `${
                              change.declined
                                ? "changeStatusDeclined"
                                : "changeStatus"
                            }`
                      } `
                    : "none"
                }`}
              >
                {`${
                  change.approved && !change.declined
                    ? "Approved"
                    : `${change.declined ? "Declined" : "Pending"}`
                }`}
              </p> */}
                      </div>
                    ) : (
                      ""
                    )}
                    {/*  */}
                    <div
                      className={`changeRowMain ${
                        change.requested && change.declined
                          ? "declinedChange"
                          : ""
                      }`}
                    >
                      <div
                        className="changeRowLeft"
                        onClick={() => {
                          memoVals.setShowEditShiftModal((x) => {
                            return {
                              itemID: change.shiftID,
                              itemType: change.newType || change.type,
                              itemFName: change.fName,
                              itemLName: change.lName,
                              itemIsNew: false,
                              userID: change.userID,
                            };
                          });
                        }}
                      >
                        <p className="oldShiftDate textAlignLeft">
                          <img
                            src={rotaDate}
                            alt="Shift change date"
                            className="shShIcon shShIcon1"
                          />{" "}
                          {dateStringer.printedDateWithYrFromDs(change.oldDs)}
                        </p>
                        <p className="oldShiftTimes textAlignLeft">
                          <img
                            src={rotaClock}
                            alt="Shift change date"
                            className="shShIcon"
                          />{" "}
                          {change.oldStart} - {change.oldEnd}
                        </p>
                        <p className="oldShiftBrk textAlignLeft">
                          <img
                            src={rotaCoffee}
                            alt="Shift change date"
                            className="shShIcon"
                          />{" "}
                          {change.oldBrk}m break
                        </p>
                        <p className="oldShiftBrk textAlignLeft bbcada">
                          <img
                            src={rotaTeam}
                            alt="Shift change date"
                            className="shShIcon"
                          />{" "}
                          {change.teamName || "Unknown team"}
                        </p>
                      </div>
                      <div className="changeRowMiddle">
                        <img
                          src={`${
                            change.requested
                              ? `${
                                  !change.declined && change.approved
                                    ? shiftChangeArrowGreen
                                    : `${
                                        change.declined
                                          ? shiftChangeArrowRed
                                          : shiftChangeArrow
                                      }`
                                }`
                              : shiftChangeArrow
                          }`}
                          alt="arrow"
                          className="changeShiftArrowImg"
                        />
                      </div>
                      <div
                        className="changeRowRight changeRowRightTilOnly"
                        onClick={() => {
                          memoVals.setShowEditShiftModal((x) => {
                            return {
                              itemID: change.shiftID,
                              itemType: change.newType || change.type,
                              itemFName: change.fName,
                              itemLName: change.lName,
                              itemIsNew: false,
                              userID: change.userID,
                            };
                          });
                        }}
                      >
                        <p
                          className={`oldShiftDate newShiftDate ${
                            change.tilMins > change.availableTil
                              ? "colorRed"
                              : ""
                          }`}
                        >
                          <span className="useTilTxt">TOIL to use:</span>{" "}
                          <span className="spanNoBreakLine cx020948">
                            {dateStringer.formatMinsDurationToHours(
                              change.tilMins,
                              true,
                              true
                            )}
                          </span>
                          <img
                            src={barChart}
                            alt="TOIL to use"
                            className="shChImgRight"
                          />
                        </p>
                        <div
                          className={`tilTimesStrip tilTimesStripNewSh ${
                            dateStringer
                              .generateTilShiftString(
                                change.start,
                                change.end,
                                change.oldStart,
                                change.oldEnd,
                                false,
                                false,
                                change.type,
                                true
                              )
                              .includes("Whole")
                              ? "wholeTilLift"
                              : ""
                          }`}
                        >
                          {/* <p className="tilStripTitle">TOIL: </p> */}
                          {change.start === change.oldStart &&
                          change.end === change.oldEnd ? (
                            <p className="oldShiftTimes highlighedTilTimes noBorder">
                              Whole shift
                            </p>
                          ) : (
                            <p className="oldShiftTimes highlighedTilTimes">
                              <span className="">{change.start}</span> -{" "}
                              <span className="">{change.end}</span>
                            </p>
                          )}
                          <img
                            src={rotaClock}
                            alt="TOIL to use"
                            className="rotaClockNewShiMG"
                          />
                        </div>
                        <div
                          className={`tilTimesStrip tilTimesStripNewSh bbcada x2202055 x2202055B ${
                            dateStringer
                              .generateTilShiftString(
                                change.start,
                                change.end,
                                change.oldStart,
                                change.oldEnd,
                                false,
                                false,
                                change.type,
                                true
                              )
                              .includes("Whole")
                              ? "none"
                              : ""
                          } ${
                            dateStringer.generateTilShiftString(
                              change.start,
                              change.end,
                              change.oldStart,
                              change.oldEnd,
                              false,
                              false,
                              change.type,
                              true
                            ).length > 25 &&
                            dateStringer
                              .generateTilShiftString(
                                change.start,
                                change.end,
                                change.oldStart,
                                change.oldEnd,
                                false,
                                false,
                                change.type,
                                true
                              )
                              .includes(":")
                              ? "x2829848484848999"
                              : ""
                          }`}
                        >
                          {/* <p className="tilStripTitle">TOIL: </p> */}
                          {dateStringer.generateTilShiftString(
                            change.start,
                            change.end,
                            change.oldStart,
                            change.oldEnd,
                            false,
                            false,
                            change.type,
                            true
                          )}
                          <img
                            src={shiftBlockNavy}
                            alt="TOIL to use"
                            className="rotaClockNewShiMG x200205552"
                          />
                        </div>
                        {/* <p
                        className={`tilNewShiftTime ${
                          change.start !== change.oldStart &&
                          change.end !== change.oldEnd
                            ? "smallerTilTwoShifts"
                            : ""
                        } ${
                          dateStringer
                            .generateTilShiftString(
                              change.start,
                              change.end,
                              change.oldStart,
                              change.oldEnd,
                              false,
                              false,
                              change.type,
                              true
                            )
                            .includes("Whole")
                            ? "none"
                            : ""
                        }`}
                      >
                        {dateStringer.generateTilShiftString(
                          change.start,
                          change.end,
                          change.oldStart,
                          change.oldEnd,
                          false,
                          false,
                          change.type,
                          true
                        )}
                      </p> */}
                      </div>
                    </div>
                    {change.note ? (
                      <p className="changeNoteDiv">
                        <span className="changeNoteTitleSpan">
                          {change.fName}:{" "}
                        </span>
                        {change.note}
                      </p>
                    ) : (
                      ""
                    )}
                    <div
                      className={`${
                        selectedChangeID === change.takeTilID
                          ? "deleteChangeUnit"
                          : "deleteChangeUnit"
                      }`}
                    >
                      <div className="deleteChangeUnitLeft">
                        <img
                          src={rotaDur}
                          alt="Old new duration"
                          className="chShRotaDur"
                        />{" "}
                        {dateStringer.formatMinsDurationToHours(
                          change.oldDur,
                          false,
                          true
                        )}{" "}
                        <img
                          src={rightArr}
                          alt="Duration"
                          className="oldDurRightArrImg"
                        />{" "}
                        {dateStringer.formatMinsDurationToHours(
                          change.oldDur - change.tilMins,
                          false,
                          true
                        )}
                      </div>

                      {change.approved || change.declined ? (
                        <p
                          className={`${
                            change.approved || change.declined
                              ? "shiftEditDecidedBy"
                              : "none"
                          }`}
                        >
                          <span className="decidedBySpan">{`${
                            change.approved ? "Approved by " : "Declined by "
                          }`}</span>
                          <br />
                          {change.decidedBy || "unknown"}
                        </p>
                      ) : change.blockApprove ? (
                        <p
                          className="approveSwapBlocked"
                          onClick={() => {
                            setShowCannotManageOwnShift({
                              show: true,
                              teamName: change.teamName,
                            });
                          }}
                        >
                          You cannot approve your own swaps as a manager
                        </p>
                      ) : (
                        <div className="approveOrDeclineChangeReqDiv">
                          <p
                            className="approveShChangeReqBtn"
                            onClick={() => {
                              // setApproveLoading(true);
                              setShiftReqsLoading(true);

                              let newArr = [];
                              shiftReqArr.forEach((obj) => {
                                if (
                                  !obj.takeTilRequest ||
                                  obj.takeTilID !== change.takeTilID
                                ) {
                                  newArr.push(obj);
                                }
                              });
                              // let qty =
                              //   countData.shiftEdits +
                              //   countData.ttrs +
                              //   countData.drops;
                              let today00 = new Date();
                              today00.setHours(0, 0, 0, 0);
                              if (
                                dateStringer.createTimestampFromString(
                                  change.oldDs
                                ) >= today00.getTime()
                              ) {
                                // if (change.drop) {
                                //   setCountData({
                                //     drops:
                                //       countData.drops - 1 < 0
                                //         ? 0
                                //         : countData.drops - 1,
                                //   });
                                // } else {
                                setCountData({
                                  ttrs: countData.ttrs - 1,
                                });
                                // }
                                // qty--;

                                // setReqQty(qty);
                              }
                              setShiftReqArr(newArr);
                              axios
                                .post(
                                  `${serverURL}/approve-or-decline-take-til-request`,
                                  {
                                    takeTilID: change.takeTilID,
                                    userID: change.userID,
                                    decision: "approve",
                                    nowDs:
                                      dateStringer.createStringFromTimestamp(
                                        new Date().getTime()
                                      ),
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (
                                    response.data.message.includes("logout")
                                  ) {
                                    window.location.href = "/signin";
                                  }
                                  if (
                                    response.data.message === "success" ||
                                    response.data.message.includes("no longer")
                                  ) {
                                    // setApproveLoading(false);
                                    if (
                                      response.data.message.includes(
                                        "no longer"
                                      )
                                    ) {
                                      memoVals.setIndicate((x) => {
                                        return {
                                          show: true,
                                          message: "Request no longer exists",
                                          colour: "red",
                                          duration: 4000,
                                        };
                                      });
                                    }

                                    setShiftReqArr(newArr);
                                    setTimeout(() => {
                                      setShiftReqsLoading(false);
                                    }, 100);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }}
                          >
                            Approve{" "}
                            <img
                              src={whiteTick}
                              alt="Approve"
                              className="shAppWhiteTickImg"
                            />
                          </p>
                          <p
                            className="declineShChangeReqBtn"
                            onClick={() => {
                              setSureDeclineChange({
                                show: true,
                                change: false,
                                toil: true,
                                takeTilID: change.takeTilID,
                                userID: change.userID,
                                ds: change.oldDs || change.oldDate,
                              });
                            }}
                          >
                            Decline
                          </p>
                        </div>
                      )}
                    </div>
                    {/*  */}
                    {/* end of decision sub bar */}
                  </div>
                </div>
              );
            }
          }
        }
      });
  }, [changesArr, selectedChangeID]);

  let pendingsExist = () => {
    let pend = 0;

    shiftReqArr.forEach((x) => {
      if (!x.approved && !x.declined) {
        pend++;
      }
    });
    return pend;
  };
  let hoursScrollBox = useRef();

  useEffect(() => {
    if (!hoursScrollBox.current) return;

    // Initialize IntersectionObserver
    const observer = new IntersectionObserver(
      (entries) => {
        // Filter visible entries
        const visibleEntries = entries.filter((entry) => entry.isIntersecting);

        // Find the first visible item
        if (visibleEntries.length > 0) {
          const firstVisibleItem = visibleEntries.sort(
            (a, b) => a.boundingClientRect.top - b.boundingClientRect.top
          )[0];
          setCurrentDate(firstVisibleItem.target.getAttribute("data-start-ds")); // Update the date
        }
      },
      {
        root: hoursScrollBox.current, // Scrollable container
        rootMargin: "0px",
        threshold: 0.1, // Adjust as needed to detect partial visibility
      }
    );

    // Observe each item
    const items = hoursScrollBox.current.querySelectorAll(".reqUnitParent");
    items.forEach((item) => observer.observe(item));

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, [allShiftsAndTils, hoursScrollBox, loading]);

  //
  //  - - - - - - - - -

  useEffect(() => {
    if (!loading && hoursScrollBox.current) {
      hoursScrollBox.current.scrollTop = 0;
    }
  }, [loading, my, showRequests, showLogged]);

  let [arrWithDsTitles, setArrWithDsTitles] = useState([]);

  let generateTeamShiftsAndTils = useMemo(() => {
    return arrWithDsTitles.map((unit, i) => {
      if (
        !showSelfLoggedAbsenceOnly ||
        (showSelfLoggedAbsenceOnly && unit.data.selfLoggedAbsence)
      ) {
        if (!loading) {
          if (unit.dsTitle) {
            return (
              <p
                className={`teamShDsTitle ${
                  memoVals.mob ? "teamShDsTitleMob" : ""
                } ${i === 0 ? "marginTop0" : ""} ${
                  memoVals.selectedName && memoVals.selectedName !== "allNames"
                    ? "none"
                    : ""
                }`}
              >
                {unit.dsTitle}
              </p>
            );
          }
          if (
            (showShifts && unit.type === "shift") ||
            (showTils && unit.type === "til") ||
            (showOvertimes && unit.type === "overtime")
          ) {
            if (
              memoVals.selectedName === "allNames" ||
              memoVals.selectedName === unit.userID
            ) {
              let printShiftDurationSubTxt = () => {
                // house
                if (unit.data.durMins === 0 || !unit.data.durMins) {
                  return "0 mins";
                }
                if (
                  // unit.type === "shift" &&
                  unit.data.absent &&
                  !unit.data.partialAbsent &&
                  unit.data.unpaidAbsent
                ) {
                  return "0 hours";
                } else {
                  return unit.data.durMins < 1 && unit.data.durMins > 0
                    ? "1 minute"
                    : `${dateStringer.formatMinsDurationToHours(
                        unit.data.durMins || 0,
                        false,
                        true
                      )}`;
                }
              };

              let rows = 4;
              if (unit.data.absent) {
                rows++;
              }
              if (
                unit.tilRequested ||
                unit.outstandingChanges ||
                unit.data.selfLoggedAbsence
                // ||
                // unit.data.logged
              ) {
                rows++;
              }

              if (unit.tags && Array.isArray(unit.tags) && unit.tags[0]) {
                rows++;
              }

              // rows: 3, 4, 5, 6
              return (
                <div
                  className="reqUnitParent"
                  key={i}
                  data-start-ds={unit.startDs} // Custom attribute for startDs
                >
                  <div
                    onClick={() => {
                      memoVals.setOpenManagerShiftModal((x) => {
                        return {
                          shiftID: unit.type === "shift" ? unit.typeID : "",
                          tilID: unit.type !== "shift" ? unit.typeID : "",
                          fName: unit.fName,
                          lName: unit.lName,
                          openedFromShiftsPage: true,
                          type: unit.type,
                        };
                      });
                    }}
                    className={`mgrReqItem pend ${
                      memoVals.mob ? "borderRadius10x" : ""
                    } leaveReqItem ${
                      rows === 3
                        ? "shiftItemRows3"
                        : rows === 4
                        ? "shiftItemRows4"
                        : rows === 5
                        ? "shiftItemRows5"
                        : rows === 6
                        ? "shiftItemRows6"
                        : ""
                    }`}
                  >
                    {" "}
                    <div
                      className={`unsubmittedTsBlob annLeaveBlob ${
                        unit.tilRequested ||
                        unit.outstandingChanges ||
                        unit.data.selfLoggedAbsence ||
                        unit.data.logged
                          ? //  ||
                            // unit.data.logged
                            ""
                          : "none"
                      } ${
                        rows === 3
                          ? `annLeaveBlobRows3 ${
                              unit.data.logged ? "annLeaveBlobRows3Logged" : ""
                            }`
                          : rows === 4
                          ? "annLeaveBlobRows4"
                          : rows === 5
                          ? "annLeaveBlobRows5"
                          : rows === 6
                          ? "annLeaveBlobRows6"
                          : ""
                      }`}
                    ></div>
                    <div className="reqPlaneDiv opac06">
                      <img
                        src={shiftBlockNavy}
                        alt="Requested leave"
                        className={`reqPlaneIcon shIconImgTeamSh ${
                          memoVals.mob ? "reqPlaneIconMob" : ""
                        }`}
                      />
                    </div>
                    <div className="reqColBarDiv divPend"></div>
                    <div
                      className={`mgrReqItemCol2 ${
                        memoVals.mob ? "mgrReqItemCol2Mob" : ""
                      }`}
                    >
                      <div className="mgrReqTopRow">
                        <div className="mgrReqName mgrReqName2">
                          {/* ------ */}
                          <div
                            className={`mgrReqDateStrip pendDateDiv x29898988444 ${
                              rows === 3
                                ? "shContentLeftRows3"
                                : rows === 4
                                ? "shContentLeftRows4"
                                : rows === 5
                                ? "shContentLeftRows5"
                                : rows === 6
                                ? "shContentLeftRows6"
                                : ""
                            }`}
                          >
                            <div
                              className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons displayFlexShiftType`}
                            >
                              <img
                                src={desktopBurger}
                                alt="Request duration"
                                className="rotaSunReqs leaveNameBurger"
                              />

                              <p className="shTypeNameStrip">
                                {unit.data.ghost ? (
                                  "Paid day off"
                                ) : unit.type === "shift" ? (
                                  "Shift"
                                ) : unit.type === "overtime" ? (
                                  "Paid overtime"
                                ) : unit.type === "til" ? (
                                  <>
                                    Overtime{" "}
                                    <span className="shTypeTilSmallSpan">
                                      (in lieu)
                                    </span>
                                  </>
                                ) : (
                                  "Other---"
                                )}
                              </p>
                              {unit.data.requireAccept && !unit.data.ghost ? (
                                !unit.data.accepted ? (
                                  <img
                                    src={lightBlueQuestionMark}
                                    alt="Not yet accepted"
                                    className="shItemAccQImg shItemAccQImgQ"
                                  />
                                ) : (
                                  <img
                                    src={
                                      unit.data.absent ||
                                      unit.data.selfLoggedAbsence
                                        ? doubleTickRed
                                        : doubleTick
                                    }
                                    alt="Accepted"
                                    className="shItemAccQImg"
                                  />
                                )
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan marginTop6`}
                            >
                              <img
                                src={rotaDur}
                                alt="Request duration"
                                className="rotaSunReqs"
                              />
                              {printShiftDurationSubTxt()}
                            </div>

                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan marginTop6`}
                            >
                              <img
                                src={rotaTeam}
                                alt="Request duration"
                                className="rotaSunReqs"
                              />
                              {dateStringer.shorten(
                                unit.teamName,
                                window.innerWidth < 1034 ? 20 : 24
                              )}
                            </div>

                            {unit.data.absent ? (
                              <div
                                className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur ba0000 makeBold marginTop6`}
                              >
                                <img
                                  src={rotaPerson}
                                  alt="Request duration"
                                  className="rotaSunReqs marginBottom0"
                                />

                                <p className="teamShiftStringTxt fontWeight400">
                                  {unit.data.absent
                                    ? unit.data.partialAbsent
                                      ? `Absent: ${unit.data.partialAbsentStart} - ${unit.data.partialAbsentEnd}`
                                      : "Absent"
                                    : ""}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            {unit.tags &&
                            Array.isArray(unit.tags) &&
                            unit.tags[0] ? (
                              <div
                                className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur flexStart marginTop6`}
                              >
                                <img
                                  src={rotaTag}
                                  alt="Request duration"
                                  className="rotaSunReqs"
                                />
                                <div className="myHoursTagsInline x28987654345654">
                                  <div
                                    className={`shTagInline myRotaTag x23784575 fontSize14 lineHeight15 ${
                                      unit.tags.length === 1 ? "noBorder" : ""
                                    }`}
                                  >
                                    {dateStringer.shorten(
                                      unit.tags[0],
                                      memoVals.mob ? 18 : 25
                                    )}
                                  </div>
                                  {unit.tags.length > 1 && (
                                    <div
                                      className={`shTagInline myRotaTag x23784575 fontSize14 lineHeight15 noBorder`}
                                    >
                                      +{unit.tags.length - 1}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {unit.data.selfLoggedAbsence ||
                            unit.outstandingChanges ||
                            unit.tilRequested ? (
                              // ||
                              // unit.data.logged
                              <div
                                className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur  marginTop6 ${
                                  unit.data.selfLoggedAbsence
                                    ? "ba0000 makeBold"
                                    : unit.outstandingChanges ||
                                      unit.tilRequested
                                    ? "colour00aaff makeBold"
                                    : ""
                                }`}
                              >
                                <img
                                  src={rotaPerson}
                                  alt="Request duration"
                                  className="rotaSunReqs"
                                />
                                <p className="teamShiftStringTxt">
                                  {unit.data.selfLoggedAbsence
                                    ? "Logged absence"
                                    : unit.outstandingChanges
                                    ? "Change requested"
                                    : unit.tilRequested
                                    ? "TOIL requested"
                                    : ""}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                        </div>
                        <div className="teamShRightBlock">
                          <div className="absTimelineVal absTimelineValReqs marginRight0">
                            <div
                              className={`absCalDiv ${
                                showLogged ||
                                (memoVals.selectedName &&
                                  memoVals.selectedName !== "allNames")
                                  ? ""
                                  : "invis"
                              }`}
                            >
                              <div className="absCalDivUpper">
                                <div className="absDivCalNip"></div>
                                <div className="absDivCalNip"></div>
                              </div>
                              <div
                                className={`absCalDivLower absCalDivLowerAllTime`}
                              >
                                {/* <p className="calDivUpperThreeLetterDay">{dateStringer.getThreeDayLettersFromDs(unit.startDs)}</p> */}
                                <p className="absCalDivLowerMonth absCalDivLowerMonthReqs">
                                  {dateStringer.getThreeDayLettersFromDs(
                                    unit.startDs
                                  )}
                                </p>{" "}
                                <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                                  {parseInt(unit.startDs.substr(9, 2))}
                                </p>
                                <p className="absCalDivLowerYear">
                                  {dateStringer.getMonthThreeLettersFromDs(
                                    unit.startDs
                                  )}
                                  {/* {unit.startDs.substr(1, 4)} */}
                                </p>
                              </div>
                            </div>{" "}
                            <div
                              className={`shItemTimes ${
                                unit.data.ghost ? "invis" : ""
                              }`}
                            >
                              <p className="shItemTimeRow">
                                {unit.data.start.length > 10
                                  ? dateStringer.dsToTimeStrip(
                                      unit.data.start,
                                      true
                                    )
                                  : unit.data.start}
                              </p>
                              <div className="shItemTimeRowSplitter"></div>
                              <p className="shItemTimeRow">
                                {unit.data.end.length > 10
                                  ? dateStringer.dsToTimeStrip(
                                      unit.data.end,
                                      true
                                    )
                                  : unit.data.end}
                              </p>
                            </div>
                          </div>
                          {/* <p
                          className={`teamClockBreakDurStrip bbcada marginTop20`}
                        >
                          {dateStringer.shorten(unit.teamName, 20)}{" "}
                          <img
                            src={teamIcon}
                            alt="Team"
                            className="teamIconHours opac05"
                          />
                        </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`mgrReqNameTab pendClockName ${
                      rows === 3
                        ? "shNameTabRows3"
                        : rows === 4
                        ? "shNameTabRows4"
                        : rows === 5
                        ? "shNameTabRows5"
                        : rows === 6
                        ? "shNameTabRows6"
                        : ""
                    }`}
                    onClick={() => {
                      memoVals.setShowEditUser((x) => unit.userID);
                    }}
                  >
                    <img
                      src={unit.picUrl || profilePicturePlaceholder}
                      alt={unit.fName}
                      className="itemNameTabImg"
                    />{" "}
                    {unit.fName} {unit.lName}
                    {unit.userID === memoVals.checkUserState.userID ? (
                      <span className="youSpan">(You)</span>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                  {/* sub bar begins */}
                  {(unit.data.logged || unit.data.selfLoggedAbsence) && (
                    <div
                      className={`mgrClockSubBar ${
                        memoVals.mob ? "mgrClockSubBarMob" : ""
                      } ${
                        unit.data.loggedExcess ||
                        unit.data.loggedLocationName ||
                        (unit.data.loggedTags && unit.data.loggedTags[0])
                          ? "minHeight94"
                          : ""
                      } minHeightAuto
                  pendClockSub teamShiftsSubAppBar ${
                    rows === 5 ? "teamShiftsSubAppBarRows5" : ""
                  }`}
                    >
                      <div
                        className={`clocksSchedSubLeft ${
                          memoVals.mob ? "font12px" : ""
                        } `}
                      >
                        {/* <div className={`clockSchedTitle`}>Title here</div> */}
                        {unit.data.selfLoggedAbsence ? (
                          <p className="clockSchedValue fontSize14 lineHeight15">
                            <span className="subBarLoggedTitle subBarLoggedTitle2">
                              Requires action
                            </span>{" "}
                          </p>
                        ) : (
                          <div className="teamShLoggedRow">
                            {" "}
                            <p className="clockSchedValue fontSize14 lineHeight15">
                              <span
                                className={`subBarLoggedTitle subBarLoggedTitle2 ${
                                  unit.data.loggedExcess ? "x39248928484" : ""
                                }`}
                              >
                                Logged:{" "}
                                <span className="colour00aaff">
                                  {unit.data.logged}
                                </span>
                              </span>{" "}
                            </p>
                            {unit.data.loggedLateDurMins &&
                            unit.data.loggedLateDurMins > 0 ? (
                              <div
                                className="loggedShLateDurRow"
                                onClick={() => {
                                  if (
                                    !recordLateItemIDs.includes(unit.typeID)
                                  ) {
                                    setRecordLateItemIDs([
                                      ...recordLateItemIDs,
                                      unit.typeID,
                                    ]);
                                  } else {
                                    setRecordLateItemIDs(
                                      recordLateItemIDs.filter(
                                        (x) => x !== unit.typeID
                                      )
                                    );
                                  }
                                }}
                              >
                                Record{" "}
                                <span className="colourBa0000 x4389985555322">
                                  {dateStringer.formatMinsDurationToHours(
                                    unit.data.loggedLateDurMins,
                                    true
                                  )}
                                </span>{" "}
                                late start{" "}
                                <div className="recordLogShLateBox">
                                  <img
                                    src={navyTick}
                                    className={`recordLogShLateBoxImg ${
                                      !recordLateItemIDs.includes(unit.typeID)
                                        ? "invis"
                                        : ""
                                    }`}
                                    alt="Record late"
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {recordLateItemIDs.includes(unit.typeID) ? (
                              <div
                                className="loggedShLateDurRow unpaidLateLoggedRow"
                                onClick={() => {
                                  if (
                                    !recordLateUnpaidItemIDs.includes(
                                      unit.typeID
                                    )
                                  ) {
                                    setRecordLateUnpaidItemIDs([
                                      ...recordLateUnpaidItemIDs,
                                      unit.typeID,
                                    ]);
                                  } else {
                                    setRecordLateUnpaidItemIDs(
                                      recordLateUnpaidItemIDs.filter(
                                        (x) => x !== unit.typeID
                                      )
                                    );
                                  }
                                }}
                              >
                                Unpaid late{" "}
                                <div className="recordLogShLateBox">
                                  <img
                                    src={navyTick}
                                    className={`recordLogShLateBoxImg ${
                                      !recordLateUnpaidItemIDs.includes(
                                        unit.typeID
                                      )
                                        ? "invis"
                                        : ""
                                    }`}
                                    alt="Record late"
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {unit.data.loggedLocationName ? (
                              <div className="clockSchedValue fontSize14 lineHeight15 loggedLocNameRow">
                                <p
                                  className={`subBarLoggedTitle subBarLoggedTitle2 teamShLocTagRow ${
                                    unit.data.loggedExcess ? "x39248928484" : ""
                                  } `}
                                >
                                  <img
                                    src={pin}
                                    className="loggedLocPin"
                                    alt="Logged location"
                                  />{" "}
                                  <span className="colour00aaff">
                                    {dateStringer.shorten(
                                      unit.data.loggedLocationName,
                                      26
                                    )}
                                  </span>
                                </p>{" "}
                              </div>
                            ) : (
                              ""
                            )}{" "}
                            {unit.data.loggedTags &&
                            unit.data.loggedTags.length > 0 ? (
                              <div className="clockSchedValue fontSize14 lineHeight15 loggedLocNameRow">
                                <div
                                  className={`subBarLoggedTitle subBarLoggedTitle2 teamShLocTagRow teamShLoggedTagsRow ${
                                    unit.data.loggedExcess ? "x39248928484" : ""
                                  } `}
                                >
                                  <img
                                    src={rotaTag}
                                    className="loggedTagImg"
                                    alt="Logged location"
                                  />{" "}
                                  <div className="loggedTagsTeamShArr">
                                    {unit.data.loggedTags.map((tag) => {
                                      return (
                                        <p
                                          className="loggedTagsTeamShTag"
                                          key={tag}
                                        >
                                          {dateStringer.shorten(tag, 14)}
                                        </p>
                                      );
                                    })}
                                  </div>
                                </div>{" "}
                              </div>
                            ) : (
                              ""
                            )}
                            {unit.data.loggedExcess ? (
                              <div className="teamShLogSelectRow">
                                <div
                                  className={`tsDiffDiv tsDiffDivTeamShifts`}
                                >
                                  <p className="teamShShortOf">
                                    <span className="colour00aaff">
                                      {dateStringer.formatMinsDurationToHours(
                                        unit.data.loggedExcess
                                      )}
                                    </span>{" "}
                                    {unit.data.loggedExcess < 0
                                      ? "short of"
                                      : "over"}{" "}
                                    scheduled duration
                                  </p>
                                  <div
                                    className={`tsDiffDivSelBox`}
                                    onClick={() => {
                                      //
                                      axios
                                        .post(
                                          `${serverURL}/amend-logged-excess-toil-in-timesheet`,
                                          {
                                            timesheetID: "none",

                                            shiftID: unit.typeID,
                                            data: !unit.data.loggedExcessToil,
                                          },

                                          {
                                            withCredentials: true,
                                            credentials: "include",
                                          }
                                        )
                                        .then((response) => {
                                          if (
                                            response.data.message.includes(
                                              "logout"
                                            )
                                          ) {
                                            window.location.href = "/signin";
                                          }
                                          if (
                                            response.data.message === "success"
                                          ) {
                                            memoVals.setUpdateShifts(
                                              (x) => !memoVals.updateShifts
                                            );
                                          }
                                        });
                                    }}
                                  >
                                    <div className="tsDiffTickBox">
                                      <img
                                        src={tick}
                                        alt="TOIL"
                                        className={`tsDiffTickBoxImg ${
                                          unit.data.loggedExcessToil
                                            ? ""
                                            : "invis"
                                        }`}
                                      />
                                    </div>

                                    {unit.data.loggedExcess > 0
                                      ? `Add to TOIL balance`
                                      : unit.data.loggedExcess < 0
                                      ? "Deduct from TOIL balance"
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </div>
                      <div
                        className={`clocksSchedSubRight ${
                          memoVals.mob ? "flexEnd" : ""
                        }`}
                      >
                        {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                        {unit.data.selfLoggedAbsence ? (
                          <p
                            className={`approveClock ${
                              unit.userID === memoVals.checkUserState.userID &&
                              !allowSelfHoursApprove.includes(unit.teamID) &&
                              memoVals.checkUserState.permissions !== 1
                                ? "invis"
                                : ""
                            } reqActBtn_ lilacColours`}
                            onClick={() => {
                              memoVals.setOpenManagerShiftModal((x) => {
                                return {
                                  shiftID:
                                    unit.type === "shift" ? unit.typeID : "",
                                  tilID:
                                    unit.type !== "shift" ? unit.typeID : "",
                                  fName: unit.fName,
                                  lName: unit.lName,
                                  openedFromShiftsPage: true,
                                  type: unit.type,
                                };
                              });
                            }}
                          >
                            Action{" "}
                            <img
                              src={threeWhiteDots}
                              alt="Approve clock card"
                              className="approveCCtickimg reqActpopup x23894984334"
                            />
                          </p>
                        ) : (
                          <p
                            className={`approveClock ${
                              unit.userID === memoVals.checkUserState.userID &&
                              !allowSelfHoursApprove.includes(unit.teamID) &&
                              memoVals.checkUserState.permissions !== 1
                                ? "invis"
                                : ""
                            }`}
                            onClick={() => {
                              axios
                                .post(
                                  `${serverURL}/approve-logged-times`,
                                  {
                                    shiftID: unit.typeID,
                                    type: unit.type,

                                    nowDs:
                                      dateStringer.createStringFromTimestamp(
                                        new Date().getTime()
                                      ),
                                    recordLate: recordLateItemIDs.includes(
                                      unit.typeID
                                    )
                                      ? unit.data.loggedLateDurMins
                                      : false,
                                    recordLateUnpaid:
                                      recordLateUnpaidItemIDs.includes(
                                        unit.typeID
                                      )
                                        ? true
                                        : false,
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (
                                    response.data.message.includes("logout")
                                  ) {
                                    window.location.href = "/signin";
                                  }
                                  if (response.data.message === "success") {
                                    // setRemoveLoggedShiftID(state.editShiftShiftID);
                                    // setReload(!reload);
                                    setCountData({
                                      loggedShifts: countData.loggedShifts - 1,
                                    });
                                    //
                                    memoVals.setRemoveLoggedShiftID(
                                      (x) => unit.typeID
                                    );
                                    memoVals.setIndicate((x) => {
                                      return {
                                        show: true,
                                        message: `Logged times approved`,
                                        colour: "green",
                                        duration: 4000,
                                      };
                                    });
                                  }
                                });
                            }}
                          >
                            {/* Approve{" "} */}
                            <img
                              src={navyTick}
                              alt="Approve clock card"
                              className="approveCCtickimg x48958553443"
                            />
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          }
        }
      }
    });
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  }, [
    loading,
    showShifts,
    showTils,
    showOvertimes,
    showRequests,
    memoVals.selectedName,
    allShiftsAndTils,
    window.innerWidth,
    recordLateItemIDs,
    recordLateUnpaidItemIDs,
    arrWithDsTitles,
  ]);
  useEffect(() => {
    function insertDayTitles(array) {
      const result = [];
      let previousDay = null;

      array.forEach((item, index) => {
        // Extract the day from the startTs
        const currentDay = new Date(item.startTs).toISOString().split("T")[0]; // YYYY-MM-DD

        // Check if the day is different from the previous one
        if (currentDay !== previousDay) {
          // Insert a new dsTitle object before items of a new day
          let alreadyExists = result.filter((xx) => {
            return (
              xx.dsTitle === dateStringer.printedDateWithYrFromDs(item.startDs)
            );
          });
          if (!alreadyExists[0]) {
            result.push({
              dsTitle: dateStringer.printedDateWithYrFromDs(item.startDs),
              startTs: item.startTs,
              fName: "--",
              data: {},
            });

            previousDay = currentDay;
          }
        }

        // Add the current item to the result
        result.push(item);
      });

      return result;
    }

    const array = allShiftsAndTils.filter((x) => {
      return !x.dsTitle;
    });

    const newArray = insertDayTitles(array);

    setArrWithDsTitles(newArray);
  }, [allShiftsAndTils]);

  // master
  return (
    <div
      className={`userShiftsPage  ${
        memoVals.mob ? "" : "pageLeft240pxDesktop"
      }`}
    >
      {" "}
      <ShiftsHeader isTeam={true} my={my} setMy={setMy} />
      <div className="adminCurverCropper bgColUpcoming absenceShoulder hardPurpleBg"></div>
      <div className={`desktopMaster ${memoVals.mob ? "desktopPurpBg" : ""}`}>
        <div
          className={`desktopMasterMain  ${
            memoVals.mob ? "mob100Witdth mob100WitdthPlus2" : ""
          } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""} ${
            memoVals.mob ? "mgrHoursShoulderBar" : ""
          }`}
        >
          <div
            className={`${
              memoVals.mob ? "teamShiftsPageFilterBar" : "shiftsPageFilterBar"
            } ${
              memoVals.mob
                ? `userHoursShoulderBar ${
                    showFilter ? "minHeight106px" : "minHeight56px"
                  }`
                : ""
            } ${!memoVals.mob && "borderLeftGrey"} noBorderBottom ${
              !memoVals.full ? "noBorderRight" : ""
            }`}
          >
            {!memoVals.mob ? (
              <div className="shiftsPageFilterBarLeft ">
                <select
                  className={`shiftsTilMonthDropdownSmall ${
                    showRequests || showLogged
                      ? "makeFilterInvisAndInactive"
                      : ""
                    // makeFilterInvisAndInactive
                  } shiftsTilMonthDropdown ${
                    dateStringer.getMyFromTs(new Date().getTime()) === my
                      ? "colour00aaff"
                      : ""
                  }`}
                  value={my}
                  onChange={(e) => {
                    setLoading(true);
                    memoVals.setSelectedShiftTeam((x) => "allStaff");
                    memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
                    setMy(e.target.value);
                    setSelectedChangeID("");
                  }}
                >
                  {generateMonthOptions(false)}
                </select>
              </div>
            ) : (
              ""
            )}
            {!memoVals.mob ? (
              <div className="shiftsPageFilterBarRight marginTop0">
                <div
                  className={`${
                    shiftReqsLoaded
                      ? "requestsBtnContainer"
                      : "requestsBtnContainer"
                  }`}
                >
                  {" "}
                  <div
                    className={`${
                      showFilter ||
                      !showOvertimes ||
                      !showShifts ||
                      !showTils ||
                      loading
                        ? "none"
                        : `${
                            showLogged
                              ? `shiftAmendmentRequestsBtnActive ${
                                  memoVals.fullButNarrow ? "fontSize12" : ""
                                }`
                              : `${
                                  countData.loggedShifts +
                                    countData.selfLoggedAbsence <=
                                  0
                                    ? "none"
                                    : "shiftAmendmentRequestsBtn"
                                }`
                          }
                    `
                    }
           ${showRequests ? "invis" : ""}`}
                    onClick={() => {
                      // if (!showLogged) {
                      setLoggedLoading(true);
                      // }
                      if (showSelfLoggedAbsenceOnly) {
                        setShowSelfLoggedAbsenceOnly(false);
                      }
                      setShowLogged(!showLogged);
                      if (showRequests) {
                        setShowRequests(false);
                      }
                    }}
                  >
                    Logged&nbsp;
                    <p
                      className={`${showLogged ? "none" : "amendmentsNumber"}`}
                    >
                      {countData.loggedShifts + countData.selfLoggedAbsence > 9
                        ? "9+"
                        : countData.loggedShifts + countData.selfLoggedAbsence}
                    </p>
                    <img
                      src={navyClose}
                      className={`${
                        showLogged ? "closeShiftBtnCrossImg" : "none"
                      }`}
                      alt="Close"
                    />
                  </div>
                </div>
                <div
                  className={`${
                    shiftReqsLoaded
                      ? "requestsBtnContainer"
                      : "requestsBtnContainer"
                  }`}
                >
                  {" "}
                  <div
                    className={`${
                      showFilter ||
                      memoVals.selectedDay !== "allDays" ||
                      memoVals.selectedName !== "allNames" ||
                      !showOvertimes ||
                      !showShifts ||
                      !showTils ||
                      loading
                        ? "none"
                        : `${
                            showRequests
                              ? `shiftAmendmentRequestsBtnActive shiftAmendmentRequestsBtnActiveReqs ${
                                  memoVals.fullButNarrow ? "fontSize12" : ""
                                }`
                              : `shiftAmendmentRequestsBtn`
                          }
                    `
                    }
                    ${showLogged ? "invis" : ""}`}
                    onClick={() => {
                      // setUpdateShiftReqs(!memoVals.updateShiftReqs);

                      setSelectedChangeID("");
                      setShowRequests(!showRequests);
                      // setShowOutstanding(false);

                      if (!showRequests) {
                        setShiftReqsLoading(true);
                      }
                    }}
                  >
                    Requests&nbsp;
                    <p
                      className={`${
                        showRequests ||
                        countData.ttrs +
                          countData.shiftEdits +
                          countData.drops <=
                          0
                          ? "none"
                          : "amendmentsNumber"
                      }`}
                    >
                      {countData.ttrs + countData.shiftEdits + countData.drops <
                      0
                        ? 0
                        : countData.ttrs +
                            countData.shiftEdits +
                            countData.drops >
                          9
                        ? "9+"
                        : countData.ttrs +
                          countData.shiftEdits +
                          countData.drops}
                    </p>
                    <img
                      src={navyClose}
                      className={`${
                        showRequests ? "closeShiftBtnCrossImg" : "none"
                      }`}
                      alt="Close"
                    />
                  </div>
                </div>
                {memoVals.full ? (
                  <p
                    className={`addShiftShoulderBtn ${
                      loading || showLogged || showRequests || showFilter
                        ? "invis"
                        : ""
                    }`}
                    onClick={() => {
                      memoVals.setAddShiftPage((x) => 0);
                      memoVals.setAddShiftOrigin((x) => "onTeam");
                      setShowTilOrShiftPopUp(false);

                      setShowAddShift(true);
                    }}
                  >
                    {window.innerWidth < 1030 && memoVals.full
                      ? ""
                      : "Add shift"}
                    <img
                      src={navyAdd}
                      alt="Add shift"
                      className={`addShiftShoulderBtnAddImg ${
                        window.innerWidth < 1030 && memoVals.full
                          ? "x3948983x822"
                          : ""
                      }`}
                    />
                  </p>
                ) : (
                  ""
                )}

                <div
                  className={`${
                    showFilter
                      ? "shiftTilFilterBtnActive"
                      : `${
                          memoVals.selectedDay !== "allDays" ||
                          memoVals.selectedName !== "allNames" ||
                          !showOvertimes ||
                          !showShifts ||
                          !showTils
                            ? "shiftTilFilterBtnEngaged"
                            : "shiftTilFilterBtn"
                        }`
                  } ${
                    showRequests || showLogged
                      ? "makeFilterInvisAndInactive"
                      : ""
                  }`}
                  onClick={() => {
                    if (showFilter) {
                      memoVals.setSelectedDay((x) => "allDays");
                      memoVals.setSelectedName((x) => "allNames");
                      setShowOvertimes(true);
                      setShowShifts(true);
                      setShowTils(true);
                    }
                    setShowFilter(!showFilter);
                  }}
                >
                  <img
                    src={navyFilter}
                    alt="Close filter"
                    className={`filtImg ${!showFilter ? "x19831" : ""}`}
                  />{" "}
                  <img
                    className={`${
                      showFilter ? "closeFilterImgTilShifts" : "none"
                    }`}
                    alt="close"
                    src={navyClose}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {memoVals.mob ? (
              <div
                className={`teamShiftsPageFilterBarUpper -- ${
                  memoVals.mob ? "upperMobTeamHours_" : ""
                }`}
              >
                {" "}
                <div className="mobTeamShiftsUpperLeft">
                  <select
                    className={`${
                      showRequests ? "makeFilterInvisAndInactive" : ""
                      // makeFilterInvisAndInactive
                    } shiftsTilMonthDropdown shiftsTilMonthDropdownSmall ${
                      showLogged || showRequests ? "invis" : ""
                    } ${
                      dateStringer.getMyFromTs(new Date().getTime()) === my
                        ? "colour00aaff"
                        : ""
                    }`}
                    value={my}
                    onChange={(e) => {
                      setLoading(true);
                      memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
                      setMy(e.target.value);
                      setSelectedChangeID("");
                    }}
                  >
                    {generateMonthOptions(false, true)}
                  </select>
                </div>
                <div className="mobTeamShTopRight">
                  <div className="mobShiftsLowerLeft">
                    <div
                      className={`${
                        memoVals.selectedDay !== "allDays" ||
                        memoVals.selectedName !== "allNames" ||
                        !showOvertimes ||
                        !showShifts ||
                        !showTils ||
                        loading
                          ? "none"
                          : `${
                              showRequests
                                ? `shiftAmendmentRequestsBtnActive shiftAmendmentRequestsBtnActiveReqs ${
                                    memoVals.fullButNarrow ? "fontSize12" : ""
                                  }`
                                : `shiftAmendmentRequestsBtn`
                            }
             marginTop0 ${showLogged || showFilter ? "invis" : ""}`
                      }
    `}
                      onClick={() => {
                        // setUpdateShiftReqs(!memoVals.updateShiftReqs);

                        setSelectedChangeID("");
                        setShowRequests(!showRequests);
                        setShowOutstanding(false);
                      }}
                    >
                      Requests&nbsp;
                      <p
                        className={`${
                          showRequests ||
                          countData.ttrs +
                            countData.shiftEdits +
                            countData.drops <=
                            0
                            ? "none"
                            : "amendmentsNumber"
                        }`}
                      >
                        {countData.ttrs +
                          countData.shiftEdits +
                          countData.drops <
                        0
                          ? 0
                          : countData.ttrs +
                              countData.shiftEdits +
                              countData.drops >
                            9
                          ? "9+"
                          : countData.ttrs +
                            countData.shiftEdits +
                            countData.drops}
                      </p>
                      <img
                        src={navyClose}
                        className={`${
                          showRequests ? "closeShiftBtnCrossImg" : "none"
                        }`}
                        alt="Close"
                      />
                    </div>{" "}
                    <div
                      className={`${
                        showFilter ||
                        memoVals.selectedDay !== "allDays" ||
                        memoVals.selectedName !== "allNames" ||
                        !showOvertimes ||
                        !showShifts ||
                        !showTils ||
                        loading ||
                        countData.loggedShifts + countData.selfLoggedAbsence <=
                          0
                          ? "none"
                          : `${
                              showLogged
                                ? `shiftAmendmentRequestsBtnActive ${
                                    memoVals.fullButNarrow ? "fontSize12" : ""
                                  }`
                                : `${
                                    countData.loggedShifts +
                                      countData.selfLoggedAbsence <=
                                    0
                                      ? "none"
                                      : "shiftAmendmentRequestsBtn"
                                  }`
                            }
             marginTop0`
                      }
                  ${showRequests || showFilter ? "invis" : ""} `}
                      onClick={() => {
                        setLoggedLoading(true);
                        // }
                        setShowLogged(!showLogged);
                        if (showSelfLoggedAbsenceOnly) {
                          setShowSelfLoggedAbsenceOnly(false);
                        }
                        if (showRequests) {
                          setShowRequests(false);
                        }
                      }}
                    >
                      Logged&nbsp;
                      <p
                        className={`${
                          showLogged ? "none" : "amendmentsNumber"
                        }`}
                      >
                        {countData.loggedShifts + countData.selfLoggedAbsence >
                        9
                          ? "9+"
                          : countData.loggedShifts +
                            countData.selfLoggedAbsence}
                      </p>
                      <img
                        src={navyClose}
                        className={`${
                          showLogged ? "closeShiftBtnCrossImg" : "none"
                        }`}
                        alt="Close"
                      />
                    </div>
                  </div>
                  <div
                    className={`${
                      showFilter
                        ? "shiftTilFilterBtnActive"
                        : `${
                            memoVals.selectedDay !== "allDays" ||
                            memoVals.selectedName !== "allNames" ||
                            !showOvertimes ||
                            !showShifts ||
                            !showTils
                              ? "shiftTilFilterBtnEngaged"
                              : "shiftTilFilterBtn marginRight0"
                          }`
                    } ${
                      showRequests || showLogged
                        ? "makeFilterInvisAndInactive"
                        : ""
                    } ${memoVals.mob ? "marginTop0" : ""} ${
                      loading ? "none" : ""
                    }`}
                    onClick={() => {
                      if (showFilter) {
                        memoVals.setSelectedDay((x) => "allDays");
                        memoVals.setSelectedName((x) => "allNames");
                        setShowOvertimes(true);
                        setShowShifts(true);
                        setShowTils(true);
                      }
                      setShowFilter(!showFilter);
                    }}
                  >
                    <img
                      src={navyFilter}
                      alt="Close filter"
                      className={`filtImg ${!showFilter ? "x19831" : ""}`}
                    />{" "}
                    <img
                      className={`${
                        showFilter ? "closeFilterImgTilShifts" : "none"
                      }`}
                      alt="close"
                      src={navyClose}
                    />
                  </div>
                </div>
                {/* <select
                  className={`shiftsHeaderTeamDropdown ${
                    memoVals.mob ? "shiftsHeaderTeamDropdownMob" : ""
                  } ${
                    memoVals.selectedShiftTeam !== "allStaff"
                      ? `teamSelectedDrop ${
                          memoVals.mob ? "mobteamSelectedDrop" : ""
                        }`
                      : ""
                  }`}
                  value={memoVals.selectedShiftTeam}
                  onChange={(e) => {
                    let val = e.target.value;
                    memoVals.setLoadUser((x) => {
                      return {};
                    });

                    memoVals.setSelectedShiftTeam((x) => val);
                  }}
                >
                  <option value="allStaff">-- All of your teams</option>
                  {generateTeamsSelectBar}
                </select> */}
              </div>
            ) : (
              ""
            )}

            {memoVals.mob ? (
              <div
                className={`teamShiftsPageFilterBarLower ${
                  memoVals.mob ? "upperMobTeamHours" : ""
                }`}
                // onClick={() => {
                //   setCountData({
                //     ttrs: countData.ttrs - 1,
                //   });
                // }}
              >
                {/* {" "} */}
                {/* <p></p> */}
                {/* PLACE HERE */}
                {showFilter ? (
                  <div
                    className={`mobShiftsFilterBarActive ${
                      !memoVals.full ? "noBorder" : ""
                    }`}
                  >
                    <select
                      className={`${
                        memoVals.selectedName === "allNames"
                          ? "shiftNamesDropdown unselectedShiftOrTil_"
                          : "shiftNamesDropdown"
                      } teamShStaffSel`}
                      value={memoVals.selectedName}
                      onChange={(e) => {
                        let val = e.target.value;

                        memoVals.setSelectedName((x) => val);
                        setSelectedChangeID("");
                      }}
                    >
                      <option key={Math.random()} value="allNames">
                        -- All of your staff
                      </option>
                      {generateNameOptions}
                    </select>
                  </div>
                ) : (
                  ""
                )}
                {showFilter && (
                  <select
                    className={`shiftsHeaderTeamDropdown ${
                      memoVals.mob ? "shiftsHeaderTeamDropdownMob" : ""
                    } ${
                      memoVals.selectedShiftTeam !== "allStaff"
                        ? `teamSelectedDrop ${
                            memoVals.mob ? "mobteamSelectedDrop" : ""
                          }`
                        : ""
                    }`}
                    value={memoVals.selectedShiftTeam}
                    onChange={(e) => {
                      let val = e.target.value;
                      memoVals.setLoadUser((x) => {
                        return {};
                      });

                      memoVals.setSelectedShiftTeam((x) => val);
                    }}
                  >
                    <option value="allStaff">-- All of your teams</option>
                    {generateTeamsSelectBar}
                  </select>
                )}
                {/* <div
                  className={`${
                    showFilter
                      ? "shiftTilFilterBtnActive"
                      : `${
                          memoVals.selectedDay !== "allDays" ||
                          memoVals.selectedName !== "allNames" ||
                          !showOvertimes ||
                          !showShifts ||
                          !showTils
                            ? "shiftTilFilterBtnEngaged"
                            : "shiftTilFilterBtn marginRight0"
                        }`
                  } ${
                    showRequests || showLogged
                      ? "makeFilterInvisAndInactive"
                      : ""
                  } ${memoVals.mob ? "marginTop0" : ""} ${
                    loading ? "none" : ""
                  }`}
                  onClick={() => {
                    if (showFilter) {
                      memoVals.setSelectedDay((x) => "allDays");
                      memoVals.setSelectedName((x) => "allNames");
                      setShowOvertimes(true);
                      setShowShifts(true);
                      setShowTils(true);
                    }
                    setShowFilter(!showFilter);
                  }}
                >
                  <img
                    src={navyFilter}
                    alt="Close filter"
                    className={`filtImg ${!showFilter ? "x19831" : ""}`}
                  />{" "}
                  <img
                    className={`${
                      showFilter ? "closeFilterImgTilShifts" : "none"
                    }`}
                    alt="close"
                    src={navyClose}
                  />
                </div> */}
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className={`${showFilter ? "filterNameShiftsDropdown" : "none"}`}
          >
            {memoVals.mob ? (
              ""
            ) : (
              <div
                className={`nameAndDateDropdownsContainer paddingLeft5pc ${
                  !memoVals.full ? "noBorder" : ""
                }`}
              >
                <select
                  className={`shiftsHeaderTeamDropdown x10924014 ${
                    memoVals.mob ? "shiftsHeaderTeamDropdownMob" : ""
                  } ${
                    memoVals.selectedShiftTeam !== "allStaff"
                      ? `teamSelectedDrop ${
                          memoVals.mob ? "mobteamSelectedDrop" : ""
                        }`
                      : ""
                  }`}
                  value={memoVals.selectedShiftTeam}
                  onChange={(e) => {
                    let val = e.target.value;
                    memoVals.setSelectedShiftTeam((x) => val);
                  }}
                >
                  <option value="allStaff">-- All of your teams</option>
                  {generateTeamsSelectBar}
                </select>
                <select
                  className={`${
                    memoVals.selectedName === "allNames"
                      ? "shiftNamesDropdown unselectedShiftOrTil_"
                      : "shiftNamesDropdown"
                  } ${
                    memoVals.fullButNarrow
                      ? "fullButNarrowShiftNamesDropdown"
                      : ""
                  }`}
                  value={memoVals.selectedName}
                  onChange={(e) => {
                    let val = e.target.value;
                    // setLoading(true);
                    // memoVals.setUpdateShifts(x =>!memoVals.updateShifts);
                    memoVals.setSelectedName((x) => val);
                    setSelectedChangeID("");
                  }}
                >
                  <option key={Math.random()} value="allNames">
                    -- All staff
                  </option>
                  {generateNameOptions}
                </select>
                {/* <select
                  className={`${
                    memoVals.selectedDay === "allDays"
                      ? "shiftDayDropdown unselectedShiftOrTil"
                      : "shiftDayDropdown"
                  }`}
                  value={memoVals.selectedDay}
                  onChange={(e) => {
                    // setLoading(true);
                    // memoVals.setUpdateShifts(x =>!memoVals.updateShifts);
                    memoVals.setSelectedDay(x =>e.target.value);
                    setSelectedChangeID("");
                  }}
                >
                  <option value={"allDays"} key={Math.random()}>
                    Date
                  </option>
                  {generateDayOptions}
                </select> */}
              </div>
            )}
          </div>
          <div
            className={`shiftTilFilterHolder2 ${
              memoVals.mob && showFilter
                ? "shiftTilFilterHolder2MobFiltActive"
                : ""
            }`}
          >
            <div
              className={`${
                showFilter
                  ? `shiftTilFilterBtnsContainer ${
                      !memoVals.full ? "noBorder" : ""
                    }`
                  : "none"
              }`}
            >
              <div
                className={`shiftsOrTilFilterTeam ${
                  showShifts ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowShifts(!showShifts);
                }}
              >
                Shifts{" "}
                <img
                  src={showShifts ? checkboxInstall2 : checkboxInstall1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
              <div
                className={`shiftsOrTilFilterTeam ${
                  showOvertimes ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowOvertimes(!showOvertimes);
                }}
              >
                Overtime{" "}
                <img
                  src={showOvertimes ? checkboxInstall2 : checkboxInstall1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
              <div
                className={`shiftsOrTilFilterTeam ${
                  showTils ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowTils(!showTils);
                }}
              >
                Shifts in lieu{" "}
                <img
                  src={showTils ? checkboxInstall2 : checkboxInstall1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
            </div>
          </div>
          <p
            className={`teamShiftsDs ${
              dateStringer.printedDateFromDs(currentDate).includes("1970") ||
              scrollPosTop ||
              showRequests ||
              (memoVals.selectedName && memoVals.selectedName !== "allNames") ||
              showLogged
                ? "invis"
                : ""
            }`}
          >
            {dateStringer.printedDateFromDs(currentDate)}
          </p>
          <div
            className={`filterSplitterContainer ${
              !memoVals.full ? "noBorder" : ""
            }`}
          >
            <div
              className={`${showRequests ? "none" : "filterSplitter"}`}
            ></div>
          </div>
          <div className={`${showRequests ? "shiftReqsPage" : "none"}`}>
            <div
              className={`shiftRequestsKeyContainer ${
                memoVals.mob || (!memoVals.mob && !memoVals.full)
                  ? "noBorderRight"
                  : ""
              }`}
            >
              <div
                className={
                  // 'outstandingShiftReqContainer'
                  `outstandingShiftReqContainer ${
                    countData.ttrs + countData.shiftEdits + countData.drops < 0
                      ? 0
                      : countData.ttrs +
                          countData.shiftEdits +
                          countData.drops ===
                        0
                      ? "invis"
                      : ""
                  }`
                }
              >
                <div
                  onClick={() => {
                    setShowOutstanding(!showOutstanding);
                    setSelectedChangeID("");
                    setSelectedNameReq("allStaff");
                    setShiftReqsLoading(true);

                    axios
                      .post(
                        `${serverURL}/get-shift-edits-for-mgr`,
                        {
                          teamID: memoVals.selectedShiftTeam,
                          my: !showOutstanding
                            ? "undecided"
                            : reqMy === "0000"
                            ? tempMy
                            : reqMy,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          let names = [];
                          let userIDs = [];
                          response.data.arr.forEach((ch) => {
                            if (!userIDs.includes(ch.userID)) {
                              userIDs.push(ch.userID);
                              names.push({
                                userID: ch.userID,
                                fName: `${ch.fName}`,
                                lName: `${ch.lName}`,
                              });
                            }
                          });
                          setNamesOfShiftReqs(names);

                          setShiftReqArr(response.data.arr);
                          setShiftReqsLoading(false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                  className={`${
                    showOutstanding
                      ? `outstandingShiftReqsBtnActive ${
                          memoVals.fullButNarrow ? "fontSize12" : ""
                        }`
                      : "outstandingShiftReqsBtn"
                  } ${
                    countData.ttrs + countData.shiftEdits + countData.drops < 0
                      ? 0
                      : countData.ttrs +
                          countData.shiftEdits +
                          countData.drops <=
                        0
                      ? "none"
                      : ""
                  }`}
                >
                  {showOutstanding
                    ? countData.ttrs + countData.shiftEdits + countData.drops <
                      0
                      ? 0
                      : countData.ttrs +
                          countData.shiftEdits +
                          countData.drops >
                        9
                      ? "9+"
                      : countData.ttrs +
                          countData.shiftEdits +
                          countData.drops <
                        0
                      ? 0
                      : countData.ttrs + countData.shiftEdits + countData.drops
                    : ""}
                  {showOutstanding &&
                  countData.ttrs + countData.shiftEdits + countData.drops < 0
                    ? 0
                    : countData.ttrs + countData.shiftEdits + countData.drops >
                        0 && showOutstanding
                    ? " n"
                    : "N"}
                  ew {/* {showOutstanding ? "change requests" : ""} */}
                  <img
                    src={whitecross}
                    alt="close"
                    className={`${
                      showOutstanding
                        ? "whiteCrossCloseOutstandingShiftRequests"
                        : "none"
                    }`}
                  />
                  <p
                    className={`${
                      countData.ttrs + countData.shiftEdits + countData.drops <
                      0
                        ? 0
                        : countData.ttrs +
                            countData.shiftEdits +
                            countData.drops && !showOutstanding
                        ? "amendmentsNumber amendmentsNumberInOutstanding"
                        : "none"
                    }`}
                  >
                    {countData.ttrs + countData.shiftEdits + countData.drops < 0
                      ? 0
                      : countData.ttrs +
                          countData.shiftEdits +
                          countData.drops >
                        9
                      ? "9+"
                      : countData.ttrs +
                          countData.shiftEdits +
                          countData.drops <
                        0
                      ? 0
                      : countData.ttrs + countData.shiftEdits + countData.drops}
                  </p>
                </div>
                {/* {countData.ttrs} {countData.shiftEdits} {countData.drops} */}
              </div>
              <div
                className={`shiftReqMonthSelectContainer ${
                  showOutstanding ? "x1821rr" : ""
                }`}
              >
                <select
                  className={`shiftReqNamesDropdown fontSize12_`}
                  value={selectedNameReq}
                  onChange={(e) => {
                    setSelectedNameReq(e.target.value);
                    setShowOutstanding(false);
                    setSelectedChangeID("");
                  }}
                >
                  <option value="allStaff">-- All staff</option>
                  {generateNamesForShiftReqs}{" "}
                </select>

                <select
                  className={`shiftsTilMonthDropdown shiftsTilMonthDropdownSmall x398294822 ${
                    !memoVals.mob ? "x3474782832" : ""
                  } ${
                    dateStringer.getMyFromTs(new Date().getTime()) === reqMy
                      ? "colour00aaff"
                      : ""
                  } fontSize12_`}
                  value={reqMy}
                  onChange={(e) => {
                    setReqMy(e.target.value);
                    setShowOutstanding(false);
                    setSelectedChangeID("");
                    setShiftReqsLoading(true);

                    axios
                      .post(
                        `${serverURL}/get-shift-edits-for-mgr`,
                        {
                          teamID: memoVals.selectedShiftTeam,
                          my: e.target.value,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          let names = [];
                          let userIDs = [];
                          response.data.arr.forEach((ch) => {
                            if (!userIDs.includes(ch.userID)) {
                              userIDs.push(ch.userID);
                              names.push({
                                userID: ch.userID,
                                fName: `${ch.fName}`,
                                lName: `${ch.lName}`,
                              });
                            }
                          });
                          setNamesOfShiftReqs(names);

                          setShiftReqArr(response.data.arr);
                          setShiftReqsLoading(false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  {generateMonthOptions(true)}
                </select>
              </div>
            </div>
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            <div
              className={`shiftRequestsContainerSplitterLineWrapper ${
                memoVals.mob || (!memoVals.full && !memoVals.mob)
                  ? "noBorderRight"
                  : ""
              }`}
            >
              <div className="shiftRequestsContainerSplitterLine"></div>
            </div>

            <div
              className={`shiftReqsContainer ${
                memoVals.mob
                  ? `shiftReqsContainerMob ${
                      memoVals.device.ios ? "shiftReqsContainerMobIos" : ""
                    }`
                  : ""
              }`}
            >
              <img
                src={horizontalBalls}
                className={`${
                  shiftReqsLoading ? "loadingHoriztonalImg" : "none"
                }`}
                alt="Loading"
              />
              <div
                className={`${
                  shiftReqsLoading
                    ? "none"
                    : `shiftChangesParent ${
                        memoVals.mob ? "shiftChangesParentMob" : ""
                      }`
                } ${!memoVals.full ? "noBorderRight" : ""}`}
              >
                {!shiftReqsLoading && shiftReqArr.length <= 0 ? (
                  <div
                    className={`noShiftsTxtContainer noUpcomingReqs ${
                      memoVals.mob ? "x1984971" : ""
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>

                    <p className={`noShiftsTxt nilTxtTeamNoChanges`}>
                      No change requests for any shifts in {ddmmToMon21(reqMy)}
                    </p>

                    <div className="noShiftsSideBar"></div>
                  </div>
                ) : (
                  ""
                )}

                {/* <div
                  className={`${
                    !shiftReqsLoading && shiftReqArr.length === 0
                      ? "noShiftsTxtContainer"
                      : "none"
                  }`}
                >
                  <div className="noShiftsSideBar"></div>
                  <p className="noShiftsTxt">
                    No change requests for
                    <br />
                    any shifts in {ddmmToMon21(reqMy)}
                  </p>
                  <div className="noShiftsSideBar"></div>
                </div> */}
                {/* {!loading && pendingsExist() && !showOutstanding ? (
                  <p className="clocksAwaitingTitles notYetSubmitTitleArr x9813114">
                    Outstanding - {pendingsExist()}
                  </p>
                ) : (
                  ""
                )} */}
                {generateShiftRequests}
              </div>
            </div>
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
          </div>
          <div
            onScroll={(e) => {
              if (e.target.scrollTop === 0) {
                setScrollPosTop(true);
              } else {
                setScrollPosTop(false);
              }
            }}
            ref={hoursScrollBox}
            className={`${
              showRequests
                ? "none"
                : `teamShiftsPage ${
                    !memoVals.mob && !memoVals.full
                      ? `${
                          showFilter
                            ? "shiftsContainerNonFullNonMobWithFilt"
                            : "shiftsContainerNonFullNonMobWithoutFilt"
                        }`
                      : ""
                  } ${!memoVals.full ? "noBorderRight" : ""}`
            } ${
              memoVals.mob
                ? `shiftsContainerMobOnly ${
                    showFilter ? "shiftsContainerMobOnlyFilterActive" : ""
                  } ${memoVals.device.ios ? "shiftsContainerMobOnlyIos" : ""} ${
                    showFilter
                      ? `showFilterMobOnlyShiftsContainer ${
                          memoVals.device.ios
                            ? "showFilterMobOnlyShiftsContainerIos"
                            : ""
                        }`
                      : ""
                  }`
                : showFilter
                ? "nonMobShiftsContainerWithFilt"
                : "nonMobShiftsContainerWithoutFilt"
            } ${memoVals.device.vhIssue ? "vhIssue" : ""} ${
              !memoVals.mob ? "b221" : ""
            }`}
          >
            {/* Teammms, selected team: {memoVals.selectedShiftTeam} */}
            <img
              src={horizontalBalls}
              className={`${
                loading || loggedLoading ? "loadingHoriztonalImg" : "none"
              }`}
              alt="Loading"
            />

            {!loading &&
            allShiftsAndTils.length <= 0 &&
            !showLogged &&
            !showRequests ? (
              <div
                className={`noShiftsTxtContainer noUpcomingReqs ${
                  memoVals.mob ? "x1984971" : ""
                }`}
              >
                <div className="noShiftsSideBar"></div>

                <p className={`noShiftsTxt nilTxtTeam`}>
                  No hours in{" "}
                  {dateStringer.printMonthAndYearFromMy(my, true, true)} for
                  staff{" "}
                  {memoVals.selectedShiftTeam === "allStaff"
                    ? "you manage"
                    : "in"}{" "}
                  {memoVals.selectedShiftTeam === "allStaff"
                    ? ""
                    : getTeamName(
                        memoVals.selectedShiftTeam,
                        memoVals.availableTeamsForShifts
                      )}
                </p>

                <div className="noShiftsSideBar"></div>
              </div>
            ) : (
              ""
            )}

            {!loggedLoading && generateTeamShiftsAndTils}

            <div
              className={`${
                totDur > 0 && !loading ? "teamShiftsDataBubble" : "none"
              }`}
            >
              <p className="totalDurMinsTxt">
                {dateStringer.formatMinsDurationToHours(totDur, false, true)}
              </p>
            </div>
          </div>{" "}
        </div>
        {memoVals.full ? (
          <div className="desktopMasterSecondary">
            <TeamHoursDataBox
              setLoadUser={setLoadUser}
              setShowRequests={setShowRequests}
              // setShowTeamHoursStatsModal={setShowTeamHoursStatsModal}
            />
          </div>
        ) : (
          ""
        )}

        {!showRequests &&
        !memoVals.showNotifications &&
        !memoVals.showReqMgrModal &&
        !memoVals.showAddOptions &&
        !memoVals.showMobMenu &&
        !memoVals.showAddAvailModal &&
        !memoVals.showTimesheet &&
        !memoVals.showClockOnModal &&
        !memoVals.showOpenShiftsMgrModal &&
        !memoVals.showUnclocked &&
        !memoVals.showScheduledTodayModal.show &&
        !memoVals.showActiveClocks &&
        !memoVals.showMgrAcceptanceModal &&
        !memoVals.showTeamSwapsModal &&
        !memoVals.showSwapsModal ? (
          <p
            className={`addShiftBtnOnTeamShifts ${
              memoVals.full ? "none" : ""
            } ${loading ? "disableAddBtn" : ""}`}
            onClick={() => {
              if (!loading) {
                setShowTilOrShiftPopUp(true);
              }
            }}
          >
            +
          </p>
        ) : (
          ""
        )}
      </div>
      {/* {ren   derMgrShiftModal()} */}
      {/* {renderEditShiftModal()} */}
      {showAddShift ? (
        <AddShiftModal2
          loadedFromMgrShiftsPage={true}
          loadedTeamName={""}
          loadedFromTeamSched={false}
          teamID={memoVals.selectedShiftTeam}
          teamIDpreSelected={true}
          type="shift"
          setShowAddShift={setShowAddShift}
        />
      ) : (
        ""
      )}
      {memoVals.showAddTilModal ? (
        <AddShiftModal2
          loadedFromMgrShiftsPage={true}
          loadedTeamName={""}
          loadedFromTeamSched={false}
          teamID={memoVals.selectedShiftTeam}
          teamIDpreSelected={true}
          type={tilOrOvertime}
          setShowAddTilModal={setShowAddTilModal}
        />
      ) : (
        // <AddTilModal
        //   loadedFromMgrShiftsPage={true}
        //   loadedTeamName={""}
        //   loadedFromTeamSched={false}
        // />
        ""
      )}
      <div
        className={`${
          showTilOrShiftPopUp ? "addTilOrShiftOptionsUnderlay" : "none"
        }`}
        onClick={() => {
          setShowTilOrShiftPopUp(false);
        }}
      >
        {" "}
        <div
          className="addShiftBtnOnTeamShiftsOnOverlay"
          onClick={() => {
            setShowTilOrShiftPopUp(false);
          }}
        >
          <p className="diagPlus">+</p>
        </div>
        <div className="addTilShiftBtnsContainer">
          <p
            className="addTilBtn extendPurpleLeftBorder"
            onClick={() => {
              memoVals.setShowAddLeaveOnTeam((x) => true);
            }}
          >
            Leave / Absence
          </p>
          <p
            className="addTilBtn extendGreyLeftBorder"
            onClick={() => {
              memoVals.setAddTilSelf((x) => false);
              memoVals.setAddTilTeam((x) => memoVals.selectedShiftTeam);
              memoVals.setAddTilUserKnown((x) => false);
              memoVals.setAddTilPage((x) => 0);
              memoVals.setAddTilUserID((x) => "");
              if (showTilOrShiftPopUp) {
                memoVals.setShowAddTilModal((x) => true);
              }
              setTilOrOvertime("til");
            }}
          >
            Overtime (time in lieu)
          </p>
          <p
            className="addTilBtn extendGreyLeftBorder"
            onClick={() => {
              memoVals.setAddTilSelf((x) => false);
              memoVals.setAddTilTeam((x) => memoVals.selectedShiftTeam);
              memoVals.setAddTilUserKnown((x) => false);
              memoVals.setAddTilPage((x) => 0);
              memoVals.setAddTilUserID((x) => "");
              if (showTilOrShiftPopUp) {
                memoVals.setShowAddTilModal((x) => true);
              }
              setTilOrOvertime("overtime");
            }}
          >
            Overtime
          </p>
          <p
            className="addTilBtn extendBlueLeftBorder"
            onClick={() => {
              memoVals.setAddShiftPage((x) => 0);
              memoVals.setAddShiftOrigin((x) => "onTeam");
              setShowTilOrShiftPopUp(false);

              setShowAddShift(true);
            }}
          >
            Shift
          </p>
        </div>
      </div>
      {memoVals.showAddLeaveOnTeam ? (
        <AddShiftModal2
          teamID={memoVals.selectedShiftTeam}
          type="leave"
          setShowAddLeaveOnTeam={setShowAddLeaveOnTeam}
        />
      ) : (
        ""
      )}
      {showCannotManageOwnShift.show ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setShowCannotManageOwnShift({ show: false });
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <div className="overlapsRenTxt">
              You cannot approve your own shift change requests as a manager of
              your own team.
              <br />
              <br />
              Only another manager from {showCannotManageOwnShift.teamName} can
              approve your shift change requests unless a FlowRota administrator
              grants you the permissions to self-manage them.
              <br />
              <br />
              For permissions, please speak to a FlowRota administrator.
              <br />
              <br />
              Share this{" "}
              <a
                className="makeBold cursorPointer x8499282"
                href="https://flowrota.com/support/manager-self-permissions"
                target="_blank"
              >
                guidance link
              </a>{" "}
              with them.
            </div>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setShowCannotManageOwnShift({ show: false });
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {sureDeclineChange.show ? (
        <div
          className="areYouSureModalUnderlay"
          onClick={() => {
            setSureDeclineChange({ show: false });
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {sureDeclineChange.change ? (
              <p className="overlapsRenTxt">
                Are you sure you want to decline this{" "}
                {sureDeclineChange.drop ? "drop" : "change"} request?
              </p>
            ) : (
              <p className="overlapsRenTxt">
                Are you sure you want to decline this TOIL request?
              </p>
            )}

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  let today00 = new Date();
                  today00.setHours(0, 0, 0, 0);
                  if (sureDeclineChange.change) {
                    setShiftReqsLoading(true);

                    let newArr = [];
                    shiftReqArr.forEach((obj) => {
                      if (obj.editID !== sureDeclineChange.editID) {
                        newArr.push(obj);
                      }
                    });

                    // let qty =
                    //   countData.shiftEdits + countData.ttrs + countData.drops;

                    // if (
                    //   dateStringer.createTimestampFromString(
                    //     sureDeclineChange.ds
                    //   ) >= today00.getTime()
                    // ) {

                    // qty--;

                    // setReqQty(qty);
                    // }
                    setShiftReqArr(newArr);
                    setSureDeclineChange({ show: false });

                    axios
                      .post(
                        `${serverURL}/approve-or-decline-shift-edit`,
                        {
                          editID: sureDeclineChange.editID,
                          userID: sureDeclineChange.userID,
                          decision: "decline",
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          // setApproveLoading(false);

                          let newArr = [];
                          shiftReqArr.forEach((obj) => {
                            if (
                              obj.takeTilRequest ||
                              obj.editID !== sureDeclineChange.editID
                            ) {
                              newArr.push(obj);
                            }
                          });
                          // let qty =
                          //   countData.shiftEdits +
                          //   countData.ttrs +
                          //   countData.drops;
                          if (
                            dateStringer.createTimestampFromString(
                              sureDeclineChange.ds
                            ) >= today00.getTime()
                          ) {
                            if (sureDeclineChange.drop) {
                              setCountData({
                                drops: countData.drops - 1,
                              });
                            } else {
                              setCountData({
                                shiftEdits: countData.shiftEdits - 1,
                              });
                            }
                            // qty--;

                            // setReqQty(qty);
                          }
                          setShiftReqArr(newArr);
                          setTimeout(() => {
                            setShiftReqsLoading(false);
                          }, 100);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }

                  if (sureDeclineChange.toil) {
                    // axios here we go
                    setShiftReqsLoading(true);

                    let newArr = [];
                    shiftReqArr.forEach((obj) => {
                      if (
                        !obj.takeTilRequest ||
                        obj.takeTilID !== sureDeclineChange.takeTilID
                      ) {
                        newArr.push(obj);
                      }
                    });
                    // let qty =
                    //   countData.shiftEdits + countData.ttrs + countData.drops;
                    if (
                      dateStringer.createTimestampFromString(
                        sureDeclineChange.ds
                      ) >= today00.getTime()
                    ) {
                      setCountData({
                        ttrs: countData.ttrs - 1,
                      });
                      // if (sureDeclineChange.drop) {
                      // setCountData({
                      //   ttrs: countData.ttrs - 1 < 0 ? 0 : countData.ttrs - 1,
                      // });
                      // } else {
                      //   setCountData({
                      //     shiftEdits:
                      //       countData.shiftEdits - 1 < 0
                      //         ? 0
                      //         : countData.shiftEdits - 1,
                      //   });
                      // }
                      // qty--;
                      // setReqQty(qty);
                    }
                    setSureDeclineChange({ show: false });

                    axios
                      .post(
                        `${serverURL}/approve-or-decline-take-til-request`,
                        {
                          takeTilID: sureDeclineChange.takeTilID,
                          userID: sureDeclineChange.userID,
                          decision: "decline",
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (
                          response.data.message === "success" ||
                          response.data.message.includes("no longer")
                        ) {
                          // setApproveLoading(false);
                          if (response.data.message.includes("no longer")) {
                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: "Request no longer exists",
                                colour: "red",
                                duration: 4000,
                              };
                            });
                          }

                          setShiftReqArr(newArr);
                          setTimeout(() => {
                            setShiftReqsLoading(false);
                          }, 100);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeclineChange({ show: false });
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default TeamShifts;
