// design and keys done 20/3/25
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";

import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";
// ios done 8 sep 24
import axios from "axios";
import serverURL from "../serverURL";
import appURL from "../appURL";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import navyClose from "../img/general/navycross.svg";

import popup2 from "../img/general/popup2.svg";
import unclockCal from "../img/general/unclockCal.svg";
import hammer from "../img/general/hammer.svg";
import teamIcon from "../img/general/teamIcon2.svg";
import openShiftBlock from "../img/general/openShiftBlockUser.svg";
import desktopBurger from "../img/general/desktopBurger.svg";

const UserOpenShiftsModal = () => {
  const { mobModal } = useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);

  const {
    setIndicate,
    setShowOpenShiftsModal,
    updateOpensModal,
    setShowUserOpenShiftModal,
    modalOpen,
    setModalOpen,
    device,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setIndicate,
      setShowOpenShiftsModal,
      checkUserState,
      mobModal,
      setShowUserOpenShiftModal,
      updateOpensModal,
      modalOpen,
      device,
      setModalOpen,
    }),
    [
      setIndicate, //
      setShowOpenShiftsModal,
      checkUserState, //
      mobModal, //
      setShowUserOpenShiftModal,
      updateOpensModal,
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 7) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={unclockCal}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={desktopBurger}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={openShiftBlock}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyClose}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup2}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={hammer}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon}
        alt="Grey Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let closeModal = () => {
    memoVals.setShowOpenShiftsModal((x) => false);
  };

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      closeModal(false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let [loaded, setLoaded] = useState(false);

  let [opens, setOpens] = useState([]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-open-shifts-user`,
        {
          someData: 1,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setDataLoaded(true);
          setLoaded(true);
          setOpens(response.data.opens || []);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.updateOpensModal]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  // master return
  return (
    <div
      className={`clockOnModalUnderlay `}
      onClick={() => {
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`mySwapsModal ${
            memoVals.mobModal ? "mobModalShoulder" : "x209dw022"
          } ${memoVals.device.ios ? "paddingBottom35" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperTeamSwaps"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="mySwapsHeader"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={memoVals.mobModal ? navyClose : navyClose}
              alt="Close"
              className="closeMySwapsModalImg"
              onClick={() => {
                closeModal();
              }}
            />
            <p>Available open shifts</p>

            {/* <p></p> */}
          </div>
          <div
            className={`mySwapsContainer ${
              memoVals.mobModal ? "mgrAcceptanceContainer" : ""
            }`}
          >
            {!loaded ? (
              <img
                src={horizontalBalls}
                alt="Loading"
                className="loadingSwaps"
              />
            ) : (
              ""
            )}
            {!loaded ? (
              ""
            ) : opens.length === 0 ? (
              <p className="noSwapsTxt">
                Open shifts across teams you manage will be displayed here
              </p>
            ) : (
              opens.map((unit, i) => {
                return (
                  <div
                    className="mgrOpenShUnit"
                    key={i}
                    onClick={() => {
                      memoVals.setShowUserOpenShiftModal((x) => unit.openID);
                    }}
                  >
                    <div className="mgrOpenShLeftContainer">
                      <div className="openShUserLeft">
                        {" "}
                        <img
                          src={openShiftBlock}
                          alt="Open shift"
                          className="openShBlockIcon"
                        />
                        {unit.meBid ? (
                          <div className="userShiftMebidBlob"></div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="mgrOpenShUnitMid">
                        <div className="mgrOpenShUnitMidRow x32444367">
                          <img
                            src={desktopBurger}
                            alt="Open shift type"
                            className="openShRowIcon x45675645656"
                          />{" "}
                          Open {unit.type === "til" ? "overtime" : unit.type}
                        </div>
                        <div className="mgrOpenShUnitMidRow">
                          <img
                            src={unclockCal}
                            alt="Open shift type"
                            className="openShRowIcon"
                          />{" "}
                          {dateStringer.printedDateWithYrFromDs(unit.ds)}
                        </div>
                        <div className="mgrOpenShUnitMidRow">
                          <img
                            src={teamIcon}
                            alt="Open shift type"
                            className="openShRowIcon"
                          />{" "}
                          {unit.teamName || "Unknown team"}
                        </div>{" "}
                        <div
                          className={`mgrOpenShUnitMidRow ${
                            unit.bids ? "x24895895445" : ""
                          }`}
                        >
                          <img
                            src={hammer}
                            alt="Open shift type"
                            className="openShRowIcon"
                          />{" "}
                          {unit.bids === 0 ? "No" : unit.bids} bid
                          {unit.bids === 1 ? "" : "s"}{" "}
                          {unit.meBid ? (
                            <span className="mebidSpan">incl. you</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mgrOpenShUnitRight">
                      <p className="shItemTimeRow">
                        {dateStringer.dsToTimeStrip(unit.startDs, true)}
                      </p>

                      <p className="shItemTimeRow">
                        {dateStringer.dsToTimeStrip(unit.endDs, true)}
                      </p>
                    </div>
                  </div>
                );
              })
            )}
          </div>

          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}
          {/* = = = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  = = =  */}

          <div className="mySwapsModalFooter">
            <p></p>

            <p
              className="closeReqInfo"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}

      {imgPreload}
    </div>
  );
};

export default UserOpenShiftsModal;
