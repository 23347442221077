import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  Fragment,
} from "react";
import { NavLink } from "react-router-dom";
// ios done 7 aug 24
// cleaned wed 24 jul 23
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";

import axios from "axios";
import serverURL from "../serverURL";
import close from "../img/modals/close.svg";
import allowArr from "../img/general/allowArr.svg";
import greenTick from "../img/general/greenTick.svg";
import popup from "../img/general/popup.svg";
import whiteGo from "../img/general/whiteGo.svg";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";
import { DataContext } from "../contexts/DataContext";
import appURL from "../appURL";

const ChecklistModal = ({}) => {
  const { mobModal } = useContext(StyleContext);
  const { setCheckUserState, checkUserState } = useContext(UserContext);
  const {
    setShowChecklist,
    modalOpen,
    setModalOpen,
    setShowEditUser,
    showEditUser,
    showAddTeamModal,
    setShowAddTeamModal,
    device,
    setIndicate,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      mobModal,
      setCheckUserState,
      checkUserState,
      setShowChecklist,
      modalOpen,
      setModalOpen,
      setShowEditUser,
      showEditUser,
      showAddTeamModal,
      setShowAddTeamModal,
      device,
      setIndicate,
    }),
    [
      mobModal,
      setCheckUserState,
      checkUserState,
      setShowChecklist,
      modalOpen,
      setModalOpen,
      setShowEditUser,
      showEditUser,
      showAddTeamModal,
      setShowAddTeamModal,
      device,
      setIndicate,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let closeModal = () => {
    memoVals.setShowChecklist((x) => false);
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
      closeModal();
    });
  }, []);

  const cancelSource1 = axios.CancelToken.source();
  let [clientCreated, setClientCreated] = useState(0);
  let [done, setDone] = useState([]);
  let [companyName, setCompanyName] = useState("");
  let [jt, setJt] = useState("");
  let [d, setD] = useState(1);
  let [m, setM] = useState(1);
  let [originalYearStart, setOriginalYearStart] = useState("D01M01");
  let [originalJt, setOriginalJt] = useState("");
  let [startDateInput, setStartDateInput] = useState("");
  let [originalStartDateInput, setOriginalStartDateInput] = useState("");
  let [minTs, setMinTs] = useState(0);
  let [periodStartDs, setPeriodStartDs] = useState(0);
  let [beforeToday, setBeforeToday] = useState(false);

  let [collapse3, setCollapse3] = useState(false);
  let [preHpw, setPreHpw] = useState(0);
  let [preMpw, setPreMpw] = useState(0);
  let [preDaysPerWeek, setPreDaysPerWeek] = useState(5);
  let [prePaidHrs, setPrePaidHrs] = useState(0);
  let [preAnnLeaveHours, setPreAnnLeaveHours] = useState(0);
  let [preAnnLeaveMins, setPreAnnLeaveMins] = useState(0);
  let [prePaidAbsHours, setPrePaidAbsHours] = useState(0);
  let [prePaidAbsMins, setPrePaidAbsMins] = useState(0);
  let [preUnpaidAbsHours, setPreUnpaidAbsHours] = useState(0);
  let [preUnpaidAbsMins, setPreUnpaidAbsMins] = useState(0);

  let [showSave3a, setShowSave3a] = useState(false);
  let [showSave3b, setShowSave3b] = useState(false);
  let [showSave3c, setShowSave3c] = useState(false);
  let [showSave3d, setShowSave3d] = useState(false);
  let [showSave3e, setShowSave3e] = useState(false);
  let [showSave3f, setShowSave3f] = useState(false);
  let [save3edited, setSave3edited] = useState(false);
  let [row3done, setRow3done] = useState(false);
  let [historyEndDs, setHistoryEndDs] = useState("");
  let [allDone, setAllDone] = useState(false);

  useEffect(() => {
    memoVals.setCheckUserState({
      checklistDone: done,
    });

    if (!beforeToday) {
      if (
        done.includes("1") &&
        done.includes("2") &&
        done.includes("3") &&
        done.includes("4") &&
        done.includes("5") &&
        done.includes("6") &&
        done.includes("7")
      ) {
        if (!allDone) {
          setAllDone(true);
        }
      } else {
        if (allDone) {
          setAllDone(false);
        }
      }
    }

    if (beforeToday) {
      if (
        done.includes("1") &&
        done.includes("2") &&
        done.includes("3") &&
        done.includes("3.1") &&
        done.includes("3.2") &&
        done.includes("3.3") &&
        done.includes("3.4") &&
        done.includes("3.5") &&
        done.includes("3.6") &&
        done.includes("4") &&
        done.includes("5") &&
        done.includes("6") &&
        done.includes("7")
      ) {
        if (!allDone) {
          setAllDone(true);
        }
      } else {
        if (allDone) {
          setAllDone(false);
        }
      }
    }
  }, [done, beforeToday]);
  useEffect(() => {
    if (beforeToday && collapse3) {
      setCollapse3(false);
    }
  }, [beforeToday]);
  useEffect(() => {
    // let dun = true;
    // if (beforeToday) {
    //   if (
    //     showSave3a ||
    //     showSave3b ||
    //     showSave3c ||
    //     showSave3d ||
    //     showSave3e ||
    //     showSave3f
    //   ) {
    //     dun = false;
    //   }

    //   if (
    //     !done.includes("3.1") ||
    //     !done.includes("3.2") ||
    //     !done.includes("3.3") ||
    //     !done.includes("3.4") ||
    //     !done.includes("3.5") ||
    //     !done.includes("3.6")
    //   ) {
    //     dun = false;
    //   }
    // } else {
    //   if (!done.includes("3")) {
    //     dun = false;
    //   }
    // }
    if (
      beforeToday &&
      done.includes("3.1") &&
      done.includes("3.2") &&
      done.includes("3.3") &&
      done.includes("3.4") &&
      done.includes("3.5") &&
      done.includes("3.6")
    ) {
      setRow3done(true);
    }
    // console.log({ dun });
    // setRow3done(dun);

    // if (dun && row3done) {
    //   setRow3done(true);
    // }

    // if (!dun && !row3done) {
    //   setRow3done(false);
    // }
  }, [
    showSave3a,
    showSave3b,
    showSave3c,
    showSave3d,
    showSave3e,
    showSave3f,
    done,
    beforeToday,
  ]);
  console.log({ beforeToday });
  console.log({ done });
  console.log({ row3done });
  useEffect(() => {
    if (startDateInput.length === 10 && minTs > 0 && clientCreated) {
      if (collapse3) {
        setCollapse3(false);
      }
      let today = new Date(clientCreated);
      today.setHours(0, 0, 0, 0);
      let startTs = dateStringer.createTimestampFromString(
        dateStringer.createStringFromDateInput(startDateInput)
      );
      // if (startTs < today.getTime()) {
      //   setBeforeToday(true);
      // } else {
      //   setBeforeToday(false);
      // }
      if (startTs > minTs) {
        setPeriodStartDs(dateStringer.createStringFromTimestamp(startTs));
      } else {
        setPeriodStartDs(dateStringer.createStringFromTimestamp(minTs));
      }
    }
  }, [startDateInput, minTs, clientCreated]);
  let [hide3, setHide3] = useState(false);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={greenTick}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={popup}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteGo}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={allowArr}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 4) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [dataLoaded, imagesLoaded]);

  useEffect(() => {
    if (!memoVals.showEditUser && !memoVals.showAddTeamModal) {
      axios
        .post(
          `${serverURL}/open-checklist`,
          {
            someData: 1,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setDataLoaded(true);
            setBeforeToday(response.data.userStartedBeforeClient);
            if (!response.data.userStartedBeforeClient) {
              setRow3done(true);
            }
            // action data here
            setDone(response.data.done || []);
            setHistoryEndDs(response.data.historyEndDs);
            setClientCreated(response.data.created);
            setCompanyName(response.data.companyName);

            setJt(response.data.jt || "");
            setOriginalJt(response.data.jt || "");
            setMinTs(response.data.minTs);
            setStartDateInput(
              dateStringer.createDateInputFromDateString(
                response.data.startDate
              )
            );
            // if (response.data.startDate) {
            //   setHide3(true);
            // }
            setOriginalStartDateInput(
              dateStringer.createDateInputFromDateString(
                response.data.startDate
              )
            );
            if (
              response.data.yearStart &&
              response.data.yearStart.length === 6
            ) {
              setOriginalYearStart(response.data.yearStart);
              let ddd = parseInt(response.data.yearStart.substr(1, 2));
              let mmm = parseInt(response.data.yearStart.substr(4, 2));
              setD(parseInt(response.data.yearStart.substr(1, 2)));
              setM(parseInt(response.data.yearStart.substr(4, 2)));
            }

            if (typeof response.data.preHpw === "number") {
              setPreHpw(response.data.preHpw);
            }

            if (typeof response.data.preMpw === "number") {
              setPreMpw(response.data.preMpw);
            }

            if (typeof response.data.preDaysPerWeek) {
              setPreDaysPerWeek(response.data.preDaysPerWeek);
            }
            if (typeof response.data.prePaidHrs) {
              setPrePaidHrs(response.data.prePaidHrs);
            }

            let doneArr = response.data.done;
            let today = new Date(response.data.created);
            today.setHours(0, 0, 0, 0);
            if (response.data.minTs < today.getTime()) {
              // if 3 subs are all done, collapse 3
              if (
                doneArr.includes("3.1") &&
                doneArr.includes("3.2") &&
                doneArr.includes("3.3") &&
                doneArr.includes("3.4") &&
                doneArr.includes("3.5") &&
                doneArr.includes("3.6")
              ) {
                console.log("Collapge 3");
                setCollapse3(true);
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [memoVals.showEditUser, memoVals.showAddTeamModal]);

  let generateDateOptions = (month) => {
    let arr = [];
    let daysQty = 31;
    if (month === 2) {
      daysQty = 28;
    }
    if (month === 4 || month === 6 || month === 9) {
      daysQty = 30;
    }
    for (var i = 0; i < daysQty; i++) {
      arr.push(i + 1);
    }

    return arr.map((x) => {
      return (
        <option value={x} key={x}>
          {x}
        </option>
      );
    });
  };

  let actionChecklist = (action, avoidUpdatingData) => {
    // 3.1 / 3a   hours per week contracted
    // 3.2 / 3b   days per week contracted
    // 3.3 / 3c   total hours (excl. today) paid for (inc leave, paid absence, paid work)
    // 3.4 / 3d annual leave hours taken tot in pre-period
    // 3.5 / 3e  paid absence hours
    // 3.6 / 3f  unpaid absence hours
    if (action) {
      let data;
      if (action === 1) {
        let prepend0 = (val, isMonth) => {
          if (val) {
            let num = isMonth ? val - 1 : val;

            if (num < 10) {
              return `0${num}`;
            } else {
              return num;
            }
          }
        };
        data = `D${prepend0(d)}M${prepend0(m)}`;
      }
      if (action === 2) {
        data = jt;
      }

      if (action === 3) {
        data = dateStringer.createStringFromDateInput(startDateInput);
      }

      if (action === 3.1) {
        data = {
          hpw: parseInt(preHpw < 0 ? 0 : preHpw),
          mpw: parseInt(preMpw < 0 ? 0 : preMpw),
          startDs: dateStringer.createStringFromTimestamp(minTs),
        };
      }

      if (action === 3.2) {
        data = {
          daysPerWeek: parseInt(
            preDaysPerWeek < 0 ? 0 : preDaysPerWeek > 7 ? 7 : preDaysPerWeek
          ),
          startDs: dateStringer.createStringFromTimestamp(minTs),
        };
      }
      if (action === 3.3) {
        data = {
          prePaidHrs: parseInt(prePaidHrs < 0 ? 0 : prePaidHrs),
          startDs: dateStringer.createStringFromTimestamp(minTs),
        };
      }

      if (action === 3.4) {
        data = {
          preAnnLeaveHours: parseInt(
            preAnnLeaveHours < 0 ? 0 : preAnnLeaveHours
          ),
          preAnnLeaveMins: parseInt(preAnnLeaveMins < 0 ? 0 : preAnnLeaveMins),
          startDs: dateStringer.createStringFromTimestamp(minTs),
        };
      }

      if (action === 3.5) {
        data = {
          prePaidAbsHours: parseInt(prePaidAbsHours < 0 ? 0 : prePaidAbsHours),
          prePaidAbsMins: parseInt(prePaidAbsMins < 0 ? 0 : prePaidAbsMins),
          startDs: dateStringer.createStringFromTimestamp(minTs),
        };
      }

      if (action === 3.6) {
        data = {
          preUnpaidAbsHours: parseInt(
            preUnpaidAbsHours < 0 ? 0 : preUnpaidAbsHours
          ),
          preUnpaidAbsMins: parseInt(
            preUnpaidAbsMins < 0 ? 0 : preUnpaidAbsMins
          ),
          startDs: dateStringer.createStringFromTimestamp(minTs),
        };
      }

      if (action === 4) {
        data = done.includes("4") ? false : true;
      }

      if (action === 5) {
        data = done.includes("5") ? false : true;
      }

      if (action === 6) {
        data = done.includes("6") ? false : true;
      }

      if (action === 7) {
        data = done.includes("7") ? false : true;
      }

      if (action === 8) {
        data = done.includes("8") ? false : true;
      }

      if (action === 9) {
        // complete the checklist (no data needed)
      }

      axios
        .post(
          `${serverURL}/checklist-action`,
          {
            action,
            data,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            historyEndDs: historyEndDs,
            avoidUpdatingData,
            beforeToday,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            // action data here

            memoVals.setCheckUserState({
              checklistDone:
                action === 9
                  ? [
                      "1",
                      "2",
                      "3",
                      "3.1",
                      "3.2",
                      "3.3",
                      "3.4",
                      "3.5",
                      "3.6",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                    ]
                  : response.data.done,
            });

            if (
              action === 1 &&
              response.data.yearStart &&
              response.data.yearStart.length === 6
            ) {
              setOriginalYearStart(response.data.yearStart);
              setD(parseInt(response.data.yearStart.substr(1, 2)));
              setM(parseInt(response.data.yearStart.substr(4, 2)));
              setMinTs(response.data.minTs);
              setDone([...done, "1"]);
            }

            if (action === 2) {
              setOriginalJt(response.data.jt);
              setJt(response.data.jt);
              setDone([...done, "2"]);
            }

            if (action === 3.1) {
              setDone([...done, "3.1"]);
              setShowSave3a(false);
              setPreHpw(response.data.h);
              setPreMpw(response.data.m);
            }
            if (action === 3.2) {
              setDone([...done, "3.2"]);
              setShowSave3b(false);
              setPreDaysPerWeek(response.data.daysPerWeek);
            }
            if (action === 3.3) {
              setDone([...done, "3.3"]);
              setPrePaidHrs(response.data.prePaidHrs);
              setShowSave3c(false);
            }
            if (action === 3.4) {
              setDone([...done, "3.4"]);
              setShowSave3d(false);
            }
            if (action === 3.5) {
              setDone([...done, "3.5"]);
              setShowSave3e(false);
            }
            if (action === 3.6) {
              setDone([...done, "3.6"]);
              setShowSave3f(false);
            }

            if (
              action === 4 ||
              action === 5 ||
              action === 6 ||
              action === 7 ||
              action === 8
            ) {
              setDone(response.data.done);
            }

            if (action === 9) {
              closeModal();
            }
          }
          if (response.data.message === "data invalid for action 2") {
            memoVals.setIndicate((x) => {
              return {
                show: true,
                message: `Job title cannot be empty`,
                colour: "red",
                duration: 4000,
              };
            });
          }

          if (action === 3) {
            setBeforeToday(response.data.userStartedBeforeClient);
            if (response.data.userStartedBeforeClient) {
              if (
                beforeToday &&
                done.includes("3.1") &&
                done.includes("3.2") &&
                done.includes("3.3") &&
                done.includes("3.4") &&
                done.includes("3.5") &&
                done.includes("3.6")
              ) {
                setRow3done(true);
              }
            }
            if (!response.data.userStartedBeforeClient) {
              setRow3done(true);
            }
          }

          // if (action === 3 && !beforeToday) {
          //   setRow3done(true);
          // }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const inputRef = useRef(null);

  // const handleButtonClick = () => {
  //   try {
  //     if (inputRef.current) {
  //       if (typeof inputRef.current.showPicker === "function") {
  //         inputRef.current.showPicker();
  //       } else {
  //         console.warn("showPicker is not a function on inputRef.current");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error in handleButtonClick:", error);
  //   }
  // };

  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className="clockOnModalUnderlay underlayInstall"
      onClick={() => {
        closeModal();
      }}
    >
      <div
        className={`userCheckListModal ${
          memoVals.mob ? `userCheckListModalMob` : ""
        } ${
          memoVals.mobModal
            ? `mobModalShoulder slideCheckUp ${
                memoVals.device.ios ? "paddingBottom20" : ""
              }`
            : "zoomIn"
        } rightAuto paddingBottom0 clientCheckListModal`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={modalRef}
        style={memoVals.mobModal ? modalPosition : null}
      >
        {memoVals.mobModal && (
          <div className="modalSwiper modalSwiperBulkLimits"></div>
        )}
        <div
          className={`userCheckListHeader ${
            memoVals.mobModal ? "userCheckListHeaderMobModal" : ""
          }`}
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={close}
            alt="Close checklist"
            className="closeCheckListImg"
            onClick={() => {
              closeModal();
            }}
          />
          <p className="userCheckListTitle">
            Set up checklist -{" "}
            {Array.isArray(done)
              ? done.filter((x) => {
                  return !x.includes(".");
                }).length
              : 0}{" "}
            / 8
          </p>
        </div>
        <p className={`checkInfoTxt ${loading ? "invis" : ""}`}>
          Complete the tasks below to finish setting {companyName} up with
          FlowRota
        </p>

        {!loading ? (
          <div className={`clientCheckDiv`}>
            {/* new row */}
            <div
              className={`checkListItem ${
                memoVals.mobModal ? "checkListItemMob" : ""
              }  checkListItemClient ${
                memoVals.mobModal ? "checkListItemClientMob" : ""
              } ${done.includes("1") ? "opac07" : ""}`}
            >
              <p className="checkListItemNum checkListItemNumClient">1</p>
              <div className="checkListTxtAndShowMeClient">
                <p
                  className={`checkListItemTitle checkListTitleClient ${
                    done.includes("1") ? "strikeThrough" : ""
                  }`}
                >
                  What is the first day of the business year for {companyName}?
                </p>
                <div className="checkListInputRow">
                  <select
                    value={JSON.stringify(d)}
                    className="checklistYearStartInputDate"
                    onChange={(e) => {
                      if (done.includes("1")) {
                        setDone(
                          done.filter((x) => {
                            return x !== "1";
                          })
                        );
                      }
                      setD(parseInt(e.target.value));
                    }}
                  >
                    {generateDateOptions(m)}
                  </select>{" "}
                  <select
                    value={JSON.stringify(m)}
                    className="checklistYearStartInputDate checklistYearStartInputDateMonth"
                    onChange={(e) => {
                      if (d !== 1) {
                        setD(1);
                      }
                      setM(parseInt(e.target.value));
                      if (done.includes("1")) {
                        setDone(
                          done.filter((x) => {
                            return x !== "1";
                          })
                        );
                      }
                    }}
                  >
                    <option value="1">Jan</option>
                    <option value="2">Feb</option>
                    <option value="3">Mar</option>
                    <option value="4">Apr</option>
                    <option value="5">May</option>
                    <option value="6">Jun</option>
                    <option value="7">Jul</option>
                    <option value="8">Aug</option>
                    <option value="9">Sep</option>
                    <option value="10">Oct</option>
                    <option value="11">Nov</option>
                    <option value="12">Dec</option>
                  </select>
                  {!done.includes("1") && (
                    <p
                      className="checklistSaveBtn"
                      onClick={() => {
                        actionChecklist(1);
                      }}
                    >
                      Save
                    </p>
                  )}
                </div>
              </div>
              <div
                className={`checkListDoItemClient `}
                onClick={() => {
                  if (done.includes("1")) {
                    setDone(
                      done.filter((x) => {
                        return x !== "1";
                      })
                    );
                  } else {
                    setDone([...done, "1"]);
                    setD(parseInt(originalYearStart.substr(1, 2)));
                    setM(parseInt(originalYearStart.substr(4, 2)));
                    actionChecklist(1, true);
                  }
                }}
              >
                <img
                  src={greenTick}
                  alt="Completed"
                  className={`completedCheckListTickImg ${
                    done.includes("1") ? "cursorPointer" : "invis"
                  }`}
                />
              </div>
            </div>

            {/* end of new row */}
            {/* new row */}
            <div
              className={`checkListItem ${
                memoVals.mobModal ? "checkListItemMob" : ""
              }  checkListItemClient ${
                memoVals.mobModal ? "checkListItemClientMob" : ""
              } ${done.includes("2") ? "opac07" : ""}`}
            >
              <p className="checkListItemNum checkListItemNumClient">2</p>
              <div className="checkListTxtAndShowMeClient">
                <p
                  className={`checkListItemTitle checkListTitleClient ${
                    done.includes("2") ? "strikeThrough" : ""
                  }`}
                >
                  What is your job title?
                </p>
                <div className="checkListInputRow">
                  <input
                    type="text"
                    className="checkListTxtInput"
                    value={jt}
                    placeholder="Job title"
                    onClick={() => {
                      if (done.includes("2")) {
                        setDone(
                          done.filter((x) => {
                            return x !== "2";
                          })
                        );
                      }
                    }}
                    onChange={(e) => {
                      setJt(e.target.value);
                    }}
                  ></input>
                  {!done.includes("2") && (
                    <p
                      className="checklistSaveBtn"
                      onClick={() => {
                        actionChecklist(2);
                      }}
                    >
                      Save
                    </p>
                  )}
                </div>
              </div>
              <div
                className={`checkListDoItemClient `}
                onClick={() => {
                  if (done.includes("2")) {
                    setDone(
                      done.filter((x) => {
                        return x !== "2";
                      })
                    );
                  } else {
                    setDone([...done, "2"]);
                    setJt(originalJt);
                    actionChecklist(2, true);
                  }
                }}
              >
                <img
                  src={greenTick}
                  alt="Completed"
                  className={`completedCheckListTickImg ${
                    done.includes("2") ? "cursorPointer" : "invis"
                  }`}
                />
              </div>
            </div>

            {/* end of new row */}
            {/* new row */}
            <div
              className={`checkListItem ${
                memoVals.mobModal ? "checkListItemMob" : ""
              }  checkListItemClient ${
                memoVals.mobModal ? "checkListItemClientMob" : ""
              } ${row3done ? "opac07" : ""}`}
            >
              <p className="checkListItemNum checkListItemNumClient">3</p>
              <div className="checkListTxtAndShowMeClient">
                <p
                  className={`checkListItemTitle checkListTitleClient ${
                    row3done ? "strikeThrough" : ""
                  }`}
                >
                  When did you join {companyName}?
                </p>
                <div className="checkListInputRow">
                  <input
                    ref={inputRef}
                    type="date"
                    className="checklistDateInput"
                    onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                    value={startDateInput}
                    // placeholder=""
                    max={dateStringer.createDateInputFromDateString(
                      dateStringer.createStringFromTimestamp(
                        new Date().getTime()
                      )
                    )}
                    onClick={() => {
                      handleButtonClick();
                      if (row3done) {
                        setRow3done(false);
                      }
                      if (!save3edited) {
                        setSave3edited(true);
                      }
                      // if (done.includes("3")) {
                      //   setDone(
                      //     done.filter((x) => {
                      //       return x !== "3";
                      //     })
                      //   );
                      // }
                    }}
                    onChange={(e) => {
                      setStartDateInput(e.target.value);
                      if (hide3) {
                        setHide3(false);
                      }
                    }}
                  ></input>
                  {!done.includes("3") || save3edited ? (
                    <p
                      className={`checklistSaveBtn ${
                        startDateInput.length === 10 ? "" : "disabled"
                      } ${hide3 ? "none" : ""}`}
                      onClick={() => {
                        actionChecklist(3);
                        if (!hide3) {
                          setHide3(true);
                        }
                      }}
                    >
                      Save
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                {beforeToday && !collapse3 ? (
                  <div className="beforeTodayCheckInputs">
                    {/* 3.1 */}
                    <div
                      className={`checklistSubRow noBorder ${
                        done.includes("3.1") && !showSave3a ? "opac07_" : ""
                      }`}
                    >
                      <div className="checklistSubRowLeft">
                        <div className="checklist3subTitle">
                          <p className="check3subNum">3a</p>
                          <p
                            className={`sub3atitleSpan ${
                              done.includes("3.1") && !showSave3a
                                ? "strikeThrough"
                                : ""
                            }`}
                          >
                            How many hours per week were you contracted to work
                            between{" "}
                            <span className="lightBlue">
                              {dateStringer.printedDateWithYrFromDs(
                                periodStartDs
                              )}
                            </span>{" "}
                            and{" "}
                            <span className="lightBlue">
                              {dateStringer.printedDateWithYrFromDs(
                                historyEndDs
                              )}
                            </span>
                            ?
                          </p>
                        </div>
                        <p className="check3Ainfo">
                          Provide an average if this varied
                        </p>
                        <div className="hrMinPerWeekDiv">
                          <input
                            type="number"
                            className="hrMinInputter"
                            value={preHpw}
                            onChange={(e) => {
                              // if (parseInt(e.target.value) >= 0) {
                              if (!showSave3a) {
                                setShowSave3a(true);
                              }
                              setPreHpw(e.target.value);
                              // } else {
                              //   setPreHpw(0);
                              // }
                            }}
                          ></input>
                          <p className="preHpwValTxt">h</p>
                          <input
                            type="number"
                            className="hrMinInputter"
                            value={preMpw}
                            onChange={(e) => {
                              // if (parseInt(e.target.value) >= 0) {
                              if (!showSave3a) {
                                setShowSave3a(true);
                              }
                              setPreMpw(e.target.value);
                              // } else {
                              //   setPreMpw(0);
                              // }
                            }}
                          ></input>
                          <p className="preHpwValTxt">m</p>
                          {showSave3a && (
                            <p
                              className={`checklistSaveBtn ${
                                isNaN(parseInt(preHpw)) ||
                                isNaN(parseInt(preMpw))
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() => {
                                actionChecklist(3.1);
                              }}
                            >
                              Save
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={`checkListSubTickBox ${
                          parseInt(preHpw) === 0 &&
                          parseInt(preMpw) === 0 &&
                          !done.includes("3.1")
                            ? "cursorPointer"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            parseInt(preHpw) === 0 &&
                            parseInt(preMpw) === 0 &&
                            !done.includes("3.1")
                          ) {
                            // set preHpw and preMpw to 0
                            actionChecklist(3.1);
                          }
                        }}
                      >
                        <img
                          src={greenTick}
                          alt="Checklist done"
                          className={`checklistSubGreenTickImg ${
                            done.includes("3.1")
                              ? ""
                              : parseInt(preHpw) === 0 &&
                                parseInt(preMpw) === 0 &&
                                !done.includes("3.1")
                              ? "showTickOnHover"
                              : "invis"
                          }`}
                        />
                      </div>
                    </div>
                    {/* 3.2 */}
                    <div
                      className={`checklistSubRow ${
                        done.includes("3.2") && !showSave3b ? "opac07_" : ""
                      }`}
                    >
                      <div className="checklistSubRowLeft">
                        <div className="checklist3subTitle">
                          <p className="check3subNum">3b</p>
                          <p
                            className={`sub3atitleSpan ${
                              done.includes("3.2") && !showSave3b
                                ? "strikeThrough"
                                : ""
                            }`}
                          >
                            How many days per week were you contracted to work
                            between{" "}
                            <span className="lightBlue">
                              {dateStringer.printedDateWithYrFromDs(
                                periodStartDs
                              )}
                            </span>{" "}
                            and{" "}
                            <span className="lightBlue">
                              {dateStringer.printedDateWithYrFromDs(
                                historyEndDs
                              )}
                            </span>
                            ?
                          </p>
                        </div>
                        <p className="check3Ainfo">
                          Provide an average if this varied
                        </p>
                        <div className="hrMinPerWeekDiv">
                          <input
                            type="number"
                            className="hrMinInputter"
                            value={preDaysPerWeek}
                            onChange={(e) => {
                              // if (parseInt(e.target.value) >= 0) {
                              // if (parseInt(e.target.value) > 7) {
                              //   if (!showSave3b) {
                              //     setShowSave3b(true);
                              //   }
                              //   setPreDaysPerWeek(7);
                              // } else {
                              if (!showSave3b) {
                                setShowSave3b(true);
                              }
                              setPreDaysPerWeek(e.target.value);
                              // }
                              // } else {
                              //   setPreDaysPerWeek(0);
                              // }
                            }}
                          ></input>
                          <p className="preHpwValTxt">days per week</p>

                          {showSave3b && (
                            <p
                              className={`checklistSaveBtn ${
                                isNaN(parseInt(preDaysPerWeek))
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() => {
                                actionChecklist(3.2);
                              }}
                            >
                              Save
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={`checkListSubTickBox ${
                          parseInt(preDaysPerWeek) === 5 &&
                          !done.includes("3.2")
                            ? "cursorPointer"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            parseInt(preDaysPerWeek) === 5 &&
                            !done.includes("3.2")
                          ) {
                            // set preDaysPerWeek to 5
                            actionChecklist(3.2);
                          }
                        }}
                      >
                        <img
                          src={greenTick}
                          alt="Checklist done"
                          className={`checklistSubGreenTickImg ${
                            done.includes("3.2")
                              ? ""
                              : parseInt(preDaysPerWeek) === 5 &&
                                !done.includes("3.2")
                              ? "showTickOnHover"
                              : "invis"
                          }`}
                        />
                      </div>
                    </div>
                    {/* 3.3 */}
                    <div
                      className={`checklistSubRow ${
                        done.includes("3.3") && !showSave3c ? "opac07_" : ""
                      }`}
                    >
                      <div className="checklistSubRowLeft">
                        <div className="checklist3subTitle">
                          <p className="check3subNum">3c</p>
                          <p
                            className={`sub3atitleSpan ${
                              done.includes("3.3") && !showSave3c
                                ? "strikeThrough"
                                : ""
                            }`}
                          >
                            How many total hours were you paid for between{" "}
                            <span className="lightBlue">
                              {dateStringer.printedDateWithYrFromDs(
                                periodStartDs
                              )}
                            </span>{" "}
                            and{" "}
                            <span className="lightBlue">
                              {dateStringer.printedDateWithYrFromDs(
                                historyEndDs
                              )}
                            </span>
                            ?
                          </p>
                        </div>
                        <p className="check3Ainfo">
                          Include shifts, overtime, leave and absence (paid)
                        </p>
                        <div className="hrMinPerWeekDiv">
                          <input
                            type="number"
                            className="hrMinInputter"
                            value={prePaidHrs}
                            onChange={(e) => {
                              // if (parseInt(e.target.value) >= 0) {
                              if (!showSave3c) {
                                setShowSave3c(true);
                              }
                              setPrePaidHrs(e.target.value);
                              // } else {
                              //   setPrePaidHrs(0);
                              // }
                            }}
                          ></input>
                          <p className="preHpwValTxt">hours</p>

                          {showSave3c && (
                            <p
                              className={`checklistSaveBtn ${
                                isNaN(parseInt(prePaidHrs)) ? "disabled" : ""
                              }`}
                              onClick={() => {
                                actionChecklist(3.3);
                              }}
                            >
                              Save
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={`checkListSubTickBox ${
                          parseInt(prePaidHrs) === 0 && !done.includes("3.3")
                            ? "cursorPointer"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            parseInt(prePaidHrs) === 0 &&
                            !done.includes("3.3")
                          ) {
                            // set prePaidHrs to 0
                            actionChecklist(3.3);
                          }
                        }}
                      >
                        <img
                          src={greenTick}
                          alt="Checklist done"
                          className={`checklistSubGreenTickImg ${
                            done.includes("3.3")
                              ? ""
                              : parseInt(prePaidHrs) === 0 &&
                                !done.includes("3.3")
                              ? "showTickOnHover"
                              : "invis"
                          }`}
                        />
                      </div>
                    </div>
                    {/* 3.4 */}
                    <div
                      className={`checklistSubRow ${
                        done.includes("3.4") && !showSave3d ? "opac07_" : ""
                      }`}
                    >
                      <div className="checklistSubRowLeft">
                        <div className="checklist3subTitle">
                          <p className="check3subNum">3d</p>
                          <p
                            className={`sub3atitleSpan ${
                              done.includes("3.4") && !showSave3d
                                ? "strikeThrough"
                                : ""
                            }`}
                          >
                            How much annual leave have you taken between{" "}
                            <span className="lightBlue">
                              {dateStringer.printedDateWithYrFromDs(
                                periodStartDs
                              )}
                            </span>{" "}
                            and{" "}
                            <span className="lightBlue">
                              {dateStringer.printedDateWithYrFromDs(
                                historyEndDs
                              )}
                            </span>
                            ?
                          </p>
                        </div>

                        <div className="hrMinPerWeekDiv">
                          <input
                            type="number"
                            className="hrMinInputter"
                            value={preAnnLeaveHours}
                            onChange={(e) => {
                              // if (parseInt(e.target.value) >= 0) {
                              if (!showSave3d) {
                                setShowSave3d(true);
                              }
                              setPreAnnLeaveHours(e.target.value);
                              // } else {
                              //   setPreAnnLeaveHours(0);
                              // }
                            }}
                          ></input>
                          <p className="preHpwValTxt">h</p>
                          <input
                            type="number"
                            className="hrMinInputter"
                            value={preAnnLeaveMins}
                            onChange={(e) => {
                              // if (parseInt(e.target.value) >= 0) {
                              if (!showSave3d) {
                                setShowSave3d(true);
                              }
                              setPreAnnLeaveMins(e.target.value);
                              // } else {
                              //   setPreAnnLeaveMins(0);
                              // }
                            }}
                          ></input>
                          <p className="preHpwValTxt">m</p>
                          {showSave3d && (
                            <p
                              className={`checklistSaveBtn ${
                                isNaN(parseInt(preAnnLeaveHours)) ||
                                isNaN(parseInt(preAnnLeaveMins))
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() => {
                                actionChecklist(3.4);
                              }}
                            >
                              Save
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={`checkListSubTickBox ${
                          parseInt(preAnnLeaveHours) === 0 &&
                          parseInt(preAnnLeaveMins) === 0 &&
                          !done.includes("3.4")
                            ? "cursorPointer"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            parseInt(preAnnLeaveHours) === 0 &&
                            parseInt(preAnnLeaveMins) === 0 &&
                            !done.includes("3.4")
                          ) {
                            // set preAnnLeaveHours and preAnnLeaveMins to 0
                            actionChecklist(3.4);
                          }
                        }}
                      >
                        <img
                          src={greenTick}
                          alt="Checklist done"
                          className={`checklistSubGreenTickImg ${
                            done.includes("3.4")
                              ? ""
                              : parseInt(preAnnLeaveHours) === 0 &&
                                parseInt(preAnnLeaveMins) === 0 &&
                                !done.includes("3.4")
                              ? "showTickOnHover"
                              : "invis"
                          }`}
                        />
                      </div>
                    </div>
                    {/* 3.5 */}
                    <div
                      className={`checklistSubRow ${
                        done.includes("3.5") && !showSave3e ? "opac07_" : ""
                      }`}
                    >
                      <div className="checklistSubRowLeft">
                        <div className="checklist3subTitle">
                          <p className="check3subNum">3e</p>
                          <p
                            className={`sub3atitleSpan ${
                              done.includes("3.5") && !showSave3e
                                ? "strikeThrough"
                                : ""
                            }`}
                          >
                            How much paid absence did you receive between{" "}
                            <span className="lightBlue">
                              {dateStringer.printedDateWithYrFromDs(
                                periodStartDs
                              )}
                            </span>{" "}
                            and{" "}
                            <span className="lightBlue">
                              {dateStringer.printedDateWithYrFromDs(
                                historyEndDs
                              )}
                            </span>
                            ?
                          </p>
                        </div>

                        <div className="hrMinPerWeekDiv">
                          <input
                            type="number"
                            className="hrMinInputter"
                            value={prePaidAbsHours}
                            onChange={(e) => {
                              // if (parseInt(e.target.value) >= 0) {
                              if (!showSave3e) {
                                setShowSave3e(true);
                              }
                              setPrePaidAbsHours(e.target.value);
                              // } else {
                              //   setPrePaidAbsHours(0);
                              // }
                            }}
                          ></input>
                          <p className="preHpwValTxt">h</p>
                          <input
                            type="number"
                            className="hrMinInputter"
                            value={prePaidAbsMins}
                            onChange={(e) => {
                              // if (parseInt(e.target.value) >= 0) {
                              if (!showSave3e) {
                                setShowSave3e(true);
                              }
                              setPrePaidAbsMins(e.target.value);
                              // } else {
                              //   setPrePaidAbsMins(0);
                              // }
                            }}
                          ></input>
                          <p className="preHpwValTxt">m</p>
                          {showSave3e && (
                            <p
                              className={`checklistSaveBtn ${
                                isNaN(parseInt(prePaidAbsHours)) ||
                                isNaN(parseInt(prePaidAbsMins))
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() => {
                                actionChecklist(3.5);
                              }}
                            >
                              Save
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={`checkListSubTickBox ${
                          parseInt(prePaidAbsHours) === 0 &&
                          parseInt(prePaidAbsMins) === 0 &&
                          !done.includes("3.5")
                            ? "cursorPointer"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            parseInt(prePaidAbsHours) === 0 &&
                            parseInt(prePaidAbsMins) === 0 &&
                            !done.includes("3.5")
                          ) {
                            // set prePaidAbsHours and prePaidAbsMins to 0
                            actionChecklist(3.5);
                          }
                        }}
                      >
                        <img
                          src={greenTick}
                          alt="Checklist done"
                          className={`checklistSubGreenTickImg ${
                            done.includes("3.5")
                              ? ""
                              : parseInt(prePaidAbsHours) === 0 &&
                                parseInt(prePaidAbsMins) === 0 &&
                                !done.includes("3.5")
                              ? "showTickOnHover"
                              : "invis"
                          }`}
                        />
                      </div>
                    </div>

                    {/* 3.6 */}
                    <div
                      className={`checklistSubRow ${
                        done.includes("3.6") && !showSave3f ? "opac07_" : ""
                      }`}
                    >
                      <div className="checklistSubRowLeft">
                        <div className="checklist3subTitle">
                          <p className="check3subNum">3f</p>
                          <p
                            className={`sub3atitleSpan ${
                              done.includes("3.6") && !showSave3f
                                ? "strikeThrough"
                                : ""
                            }`}
                          >
                            How much unpaid absence leave did you take between{" "}
                            <span className="lightBlue">
                              {dateStringer.printedDateWithYrFromDs(
                                periodStartDs
                              )}
                            </span>{" "}
                            and{" "}
                            <span className="lightBlue">
                              {dateStringer.printedDateWithYrFromDs(
                                historyEndDs
                              )}
                            </span>
                            ?
                          </p>
                        </div>

                        <div className="hrMinPerWeekDiv">
                          <input
                            type="number"
                            className="hrMinInputter"
                            value={preUnpaidAbsHours}
                            onChange={(e) => {
                              // if (parseInt(e.target.value) >= 0) {
                              if (!showSave3f) {
                                setShowSave3f(true);
                              }
                              setPreUnpaidAbsHours(e.target.value);
                              // } else {
                              //   setPreUnpaidAbsHours(0);
                              // }
                            }}
                          ></input>
                          <p className="preHpwValTxt">h</p>
                          <input
                            type="number"
                            className="hrMinInputter"
                            value={preUnpaidAbsMins}
                            onChange={(e) => {
                              // if (parseInt(e.target.value) >= 0) {
                              if (!showSave3f) {
                                setShowSave3f(true);
                              }
                              setPreUnpaidAbsMins(e.target.value);
                              // } else {
                              //   setPreUnpaidAbsMins(0);
                              // }
                            }}
                          ></input>
                          <p className="preHpwValTxt">m</p>
                          {showSave3f && (
                            <p
                              className={`checklistSaveBtn ${
                                isNaN(parseInt(preUnpaidAbsHours)) ||
                                isNaN(parseInt(preUnpaidAbsMins))
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() => {
                                actionChecklist(3.6);
                              }}
                            >
                              Save
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={`checkListSubTickBox ${
                          parseInt(preUnpaidAbsHours) === 0 &&
                          parseInt(preUnpaidAbsMins) === 0 &&
                          !done.includes("3.6")
                            ? "cursorPointer"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            parseInt(preUnpaidAbsHours) === 0 &&
                            parseInt(preUnpaidAbsMins) === 0 &&
                            !done.includes("3.6")
                          ) {
                            // set preUnpaidAbsHours and preUnpaidAbsMins to 0
                            actionChecklist(3.6);
                          }
                        }}
                      >
                        <img
                          src={greenTick}
                          alt="Checklist done"
                          className={`checklistSubGreenTickImg ${
                            done.includes("3.6")
                              ? ""
                              : parseInt(preUnpaidAbsHours) === 0 &&
                                parseInt(preUnpaidAbsMins) === 0 &&
                                !done.includes("3.6")
                              ? "showTickOnHover"
                              : "invis"
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="check3RightCol">
                <div
                  className={`checkListDoItemClient defaultCursor`}
                  onClick={() => {
                    if (row3done) {
                      setRow3done(false);
                    } else {
                      // setStartDateInput(originalStartDateInput);
                    }
                  }}
                >
                  <img
                    src={greenTick}
                    alt="Completed"
                    className={`completedCheckListTickImg ${
                      row3done ? "cursorPointer" : "invis"
                    }`}
                  />
                </div>{" "}
                {beforeToday ? (
                  <img
                    src={allowArr}
                    onClick={() => {
                      setCollapse3(!collapse3);
                    }}
                    alt="Collapse check list 3 items"
                    className={`check3arrImg ${!collapse3 ? "uncollap3" : ""}`}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* end of new row */}
            {/* new row */}
            <div
              className={`checkListItem ${
                memoVals.mobModal ? "checkListItemMob" : ""
              }  checkListItemClient ${
                memoVals.mobModal ? "checkListItemClientMob" : ""
              } ${done.includes("4") ? "opac07" : ""} checkPadTop`}
            >
              <p className="checkListItemNum checkListItemNumClient">4</p>
              <div className="checkListTxtAndShowMeClient">
                <p
                  className={`checkListItemTitle checkListTitleClient ${
                    done.includes("4") ? "strikeThrough" : ""
                  }`}
                >
                  Set annual leave allowance
                </p>
                <div className="checkListInputRow">
                  <div
                    // to="/admin/leave-settings"
                    className={`checkShowMeBtn checkShowMeBlue ${
                      done.includes("4") ? "amendNowDoneBtn" : ""
                    }`}
                    onClick={() => {
                      window.location.href = `${appURL}/admin/leave-settings?check=true`;
                      // memoVals.setShowEditUser((x) => {
                      //   return {
                      //     userID: checkUserState.userID,
                      //     checkPay: true,
                      //   };
                      // });
                    }}
                  >
                    Amend now
                    <img
                      src={whiteGo}
                      alt="Show me"
                      className="showMeCheckPopUp showMeCheckPopUpGo"
                    />
                  </div>
                </div>
              </div>
              <div
                className={`checkListDoItemClient `}
                onClick={() => {
                  actionChecklist(4);
                }}
              >
                <img
                  src={greenTick}
                  alt="Completed"
                  className={`completedCheckListTickImg ${
                    done.includes("4") ? "cursorPointer" : "invis"
                  }`}
                />
              </div>
            </div>

            {/* end of new row */}
            {/* new row */}
            <div
              className={`checkListItem ${
                memoVals.mobModal ? "checkListItemMob" : ""
              }  checkListItemClient ${
                memoVals.mobModal ? "checkListItemClientMob" : ""
              } ${done.includes("5") ? "opac07" : ""} checkPadTop`}
            >
              <p className="checkListItemNum checkListItemNumClient">5</p>
              <div className="checkListTxtAndShowMeClient">
                <p
                  className={`checkListItemTitle checkListTitleClient ${
                    done.includes("5") ? "strikeThrough" : ""
                  }`}
                >
                  Add your pay details
                </p>
                <div className="checkListInputRow">
                  <p
                    className={`checkShowMeBtn ${
                      done.includes("5") ? "amendNowDoneBtn" : ""
                    }`}
                    onClick={() => {
                      memoVals.setShowEditUser((x) => {
                        return {
                          userID: checkUserState.userID,
                          checkPay: true,
                        };
                      });
                    }}
                  >
                    Amend now
                    <img
                      src={popup}
                      alt="Show me"
                      className="showMeCheckPopUp"
                    />
                  </p>
                </div>
              </div>
              <div
                className={`checkListDoItemClient `}
                onClick={() => {
                  actionChecklist(5);
                }}
              >
                <img
                  src={greenTick}
                  alt="Completed"
                  className={`completedCheckListTickImg ${
                    done.includes("5") ? "cursorPointer" : "invis"
                  }`}
                />
              </div>
            </div>

            {/* end of new row */}
            {/* new row */}
            <div
              className={`checkListItem ${
                memoVals.mobModal ? "checkListItemMob" : ""
              }  checkListItemClient ${
                memoVals.mobModal ? "checkListItemClientMob" : ""
              } ${done.includes("6") ? "opac07" : ""} checkPadTop`}
            >
              <p className="checkListItemNum checkListItemNumClient">6</p>
              <div className="checkListTxtAndShowMeClient">
                <p
                  className={`checkListItemTitle checkListTitleClient ${
                    done.includes("6") ? "strikeThrough" : ""
                  }`}
                >
                  Add your contracted hours
                </p>
                <div className="checkListInputRow">
                  <p
                    className={`checkShowMeBtn ${
                      done.includes("6") ? "amendNowDoneBtn" : ""
                    }`}
                    onClick={() => {
                      memoVals.setShowEditUser((x) => {
                        return {
                          userID: checkUserState.userID,
                          checkHours: true,
                        };
                      });
                    }}
                  >
                    Amend now
                    <img
                      src={popup}
                      alt="Show me"
                      className="showMeCheckPopUp"
                    />
                  </p>
                </div>
              </div>
              <div
                className={`checkListDoItemClient `}
                onClick={() => {
                  actionChecklist(6);
                }}
              >
                <img
                  src={greenTick}
                  alt="Completed"
                  className={`completedCheckListTickImg ${
                    done.includes("6") ? "cursorPointer" : "invis"
                  }`}
                />
              </div>
            </div>

            {/* end of new row */}
            {/* new row */}
            <div
              className={`checkListItem ${
                memoVals.mobModal ? "checkListItemMob" : ""
              }  checkListItemClient ${
                memoVals.mobModal ? "checkListItemClientMob" : ""
              } ${done.includes("7") ? "opac07" : ""} checkPadTop`}
            >
              <p className="checkListItemNum checkListItemNumClient">7</p>
              <div className="checkListTxtAndShowMeClient">
                <p
                  className={`checkListItemTitle checkListTitleClient ${
                    done.includes("7") ? "strikeThrough" : ""
                  }`}
                >
                  Set {dateStringer.possession(companyName)} working-week days
                </p>
                <div className="checkListInputRow">
                  <p
                    // to="/admin/closed-days?highlight=true"
                    className={`checkShowMeBtn ${
                      done.includes("7") ? "amendNowDoneBtn" : ""
                    }`}
                    onClick={() => {
                      window.location.href = `${appURL}/admin/day-rules?highlight=true`;
                    }}
                  >
                    Amend now
                    <img
                      src={whiteGo}
                      alt="Show me"
                      className="showMeCheckPopUp showMeCheckPopUpGo"
                    />{" "}
                  </p>
                </div>
              </div>
              <div
                className={`checkListDoItemClient `}
                onClick={() => {
                  actionChecklist(7);
                }}
              >
                <img
                  src={greenTick}
                  alt="Completed"
                  className={`completedCheckListTickImg ${
                    done.includes("7") ? "cursorPointer" : "invis"
                  }`}
                />
              </div>
            </div>

            {/* end of new row */}
            {/* new row */}
            <div
              className={`checkListItem ${
                memoVals.mobModal ? "checkListItemMob" : ""
              }  checkListItemClient ${
                memoVals.mobModal ? "checkListItemClientMob" : ""
              } ${
                done.includes("8") || checkUserState.checklistDone.includes("8")
                  ? "opac07"
                  : ""
              } checkPadTop`}
            >
              <p className="checkListItemNum checkListItemNumClient">8</p>
              <div className="checkListTxtAndShowMeClient">
                <p
                  className={`checkListItemTitle checkListTitleClient ${
                    done.includes("8") ||
                    checkUserState.checklistDone.includes("8")
                      ? "strikeThrough"
                      : ""
                  }`}
                >
                  Add your teams
                </p>
                <div className="checkListInputRow">
                  <p
                    // to="/admin/closed-days?highlight=true"
                    className={`checkShowMeBtn ${
                      done.includes("8") ? "amendNowDoneBtn" : ""
                    }`}
                    onClick={() => {
                      // window.location.href = `${appURL}/admin/teams`;
                      memoVals.setShowAddTeamModal((x) => true);
                    }}
                  >
                    Add a team now
                    <img
                      src={whiteGo}
                      alt="Show me"
                      className="showMeCheckPopUp showMeCheckPopUpGo"
                    />{" "}
                  </p>
                </div>
              </div>
              <div
                className={`checkListDoItemClient `}
                onClick={() => {
                  actionChecklist(8);
                }}
              >
                <img
                  src={greenTick}
                  alt="Completed"
                  className={`completedCheckListTickImg ${
                    done.includes("8") ||
                    checkUserState.checklistDone.includes("8")
                      ? "cursorPointer"
                      : "invis"
                  }`}
                />
              </div>
            </div>

            {/* end of new row */}
          </div>
        ) : (
          <div
            className={`clientCheckDiv ${
              !memoVals.mobModal ? "clientCheckDivDesktop" : ""
            }`}
          >
            <img
              src={horizontalBalls}
              alt="Loading"
              className={`checklistLoadImg ${
                memoVals.mobModal ? "checklistLoadImgMobModdal" : ""
              }`}
            />
          </div>
        )}
        <div className="closedDaysTimelineFooter">
          <p></p>
          <p
            className="closeReqInfo x18938311123255"
            onClick={() => {
              if (loading) {
                closeModal();
              } else {
                if (
                  !beforeToday &&
                  done.filter((x) => {
                    return !x.includes(".");
                  }).length >= 8
                ) {
                  // complete
                  actionChecklist(9);
                } else if (!loading && done.length >= 14 && beforeToday) {
                  //complete
                  actionChecklist(9);
                } else {
                  closeModal();
                }
              }
            }}
          >
            Close
            {(!loading &&
              !beforeToday &&
              done.filter((x) => {
                return !x.includes(".");
              }).length >= 8) ||
            (!loading && done.length >= 14 && beforeToday)
              ? " & complete"
              : ""}
          </p>
        </div>
        {/* <div className="areYouModalBtnsDiv">
          <p
            className="closeSwapsNoLongerBtn completeCheckBtnAll"
            onClick={() => {
              setShowCheckList(false);
              setSuppressCheckList(true);
              updateCheckList("all");

              if (showMeAllowances) {
                setShowMeAllowances(false);
              }

              if (showMeHours) {
                setShowMeHours(false);
              }
              if (showMePay) {
                setShowMePay(false);
              }
            }}
          >
            Complete all{" "}
            <img
              src={whiteTick}
              alt="Complete all"
              className="completeAllTick"
            />
          </p>
        </div> */}
      </div>
      {imgPreload}
    </div>
  );
};

export default ChecklistModal;
