import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import serverURL from "../../../../serverURL";

import { DataContext } from "../../../../contexts/DataContext";
import { StyleContext } from "../../../../contexts/StyleContext";
import { UserContext } from "../../../../contexts/UserContext";

import horizontalBalls from "../../../../img/loaders/horizontalBalls.svg";

import allowDropNavy2 from "../../../../img/general/allowDropNavy2.svg";

import exclaimGrey from "../../../../img/general/exclaimGrey.svg";
import blueLoader from "../../../../img/general/blueLoader.gif";
import whiteGoBack from "../../../../img/general/arrow.svg";
import resetWarning from "../../../../img/general/resetWarning.svg";
import downRightArrow from "../../../../img/general/downRightArrow.svg";
import popup from "../../../../img/general/popupNavy.svg";
import customPlus from "../../../../img/general/customPlus.svg";
import customMinus from "../../../../img/general/customMinus.svg";
import checkboxInstall4 from "../../../../img/general/checkboxInstall4.svg";
import update from "../../../../img/general/update.svg";
import convArr from "../../../../img/general/navySwap.svg";
import convert from "../../../../img/general/convert.svg";
import editUserEdit1 from "../../../../img/general/editUserEdit1.svg";

// import editInCircle from "../../../../img/general/editInCircle.svg";

import dateStringer from "../../../../tools/dateStringer";

const UserAllowances = ({
  userID,
  fName,
  setAllowsUpdatedLocal,
  editUserPage,
  setShowMpwTimeline,
  setAllowancesChanged,
  showMeAllowances,
  setCheckListDone,
  checkListDone,

  editUserState,
}) => {
  const {
    setShowUpdatedReqsModal,
    setIndicate,
    setShowConvertToilModal,
    showConvertToilModal,
    updateSingleUserAllowances,
    setUpdateSingleUserAllowances,
    device,
  } = useContext(DataContext);
  const { mobModal } = useContext(StyleContext);
  const { currencySymbol, checkUserState } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      setIndicate,
      setShowUpdatedReqsModal,
      mobModal,
      currencySymbol,
      checkUserState,
      setShowConvertToilModal,
      showConvertToilModal,
      updateSingleUserAllowances,
      setUpdateSingleUserAllowances,
      device,
    }),
    [
      setIndicate,
      setShowUpdatedReqsModal,
      mobModal,
      currencySymbol,
      checkUserState,
      setShowConvertToilModal,
      showConvertToilModal,
      updateSingleUserAllowances,
      setUpdateSingleUserAllowances,
      device,
    ]
  );

  let [showIsSelfNotAdminModal, setShowIsSelfNotAdminModal] = useState(false);
  let [allowanceTypes, setAllowanceTypes] = useState([]);
  let [availableYears, setAvailableYears] = useState([]);
  let [selectedYear, setSelectedYear] = useState();
  let [annLeaveOrCustom, setAnnLeaveOrCustom] = useState("annLeave");
  let [updateData, setUpdateData] = useState(false);
  let [contractedHoursOnly, setContractedHoursOnly] = useState(false);
  let [tilEnabled, setTilEnabled] = useState(false);
  let [sureDeleteConversion, setSureDeleteConversion] = useState({
    show: false,
  });
  let [historyMins, setHistoryMins] = useState(0);
  let [editHistory, setEditHistory] = useState(false);
  let [histVal, setHistVal] = useState(0);
  let [historyPeriodStartDs, setHistoryPeriodStartDs] = useState("");
  let [historyPeriodEndDs, setHistoryPeriodEndDs] = useState("");

  let [toilConversionArr, setToilConversionArr] = useState([]);
  let [toilToAnnLeave, setToilToAnnLeave] = useState(0);
  let [annLeaveToToil, setAnnLeaveToToil] = useState(0);
  let [showConversions, setShowConversions] = useState(false);
  let [toilBalance, setToilBalance] = useState(0);
  // let [selectedMins, setSelectedMins] = useState(60);
  let [selectedDays, setSelectedDays] = useState(0);
  let [dailyMins, setDailyMins] = useState(1);
  let [mode, setMode] = useState(0);
  let [annLeavePercentage, setAnnLeavePercentage] = useState(0);

  let [flashReset, setFlashReset] = useState(false);
  let [companyName, setCompanyName] = useState("the organisation");
  let [userCreatedThisYear, setUserCreatedThisYear] = useState(false);
  let [created, setCreated] = useState(0);
  let [annLeaveAdditions, setAnnLeaveAdditions] = useState(0);
  let [historyMpwEnd, setHistoryMpwEnd] = useState("");
  let [historyMpw, setHistoryMpw] = useState(0);
  let [reqMins, setReqMins] = useState(0);
  let [shiftMins, setShiftMins] = useState(0);
  let [overtimMins, setOvertimeMins] = useState(0);

  let [showMeta, setShowMeta] = useState(false);
  let [showAdjustments, setShowAdjustments] = useState(false);
  let [addedMins, setAddedMins] = useState(0);
  let [addedMinsOnTop, setAddedMinsOnTop] = useState(0);
  let [editAddition, setEditAddition] = useState(false);

  let [remainingMins, setRemainingMins] = useState(0);
  let [usedMins, setUsedMins] = useState(0);
  let [totalMins, setTotalMins] = useState(0);

  let [joinDate, setJoinDate] = useState("");
  let [joinPercentIntoYear, setJoinPercentIntoYear] = useState(0);

  let [allowLoading, setAllowLoading] = useState(true);
  let [accrualDataLoading, setAccrualDataLoading] = useState(true);

  let [selectedCustomLeaveType, setSelectedCustomLeaveType] = useState("");

  let [currContractedOrAvg, setCurrContractedOrAvg] = useState("");

  let [currMpw, setCurrMpw] = useState(0);
  let [avgMpw, setAvgMpw] = useState(0);

  let [customDataArr, setCustomDataArr] = useState([]);
  let [customData, setCustomData] = useState([]);

  let [customMinsOrDays, setCustomMinsOrDays] = useState(0);
  let [customAdditional, setCustomAdditional] = useState(0);
  let [customExcessAdditional, setCustomExcessAdditional] = useState(0);

  let [loadingCustomLeaveTypeID, setLoadingCustomLeaveTypeID] = useState("");

  let [historyTotalMins, setHistoryTotalMins] = useState(0);

  let [customOverride, setCustomOverride] = useState(0);
  let [currYear, setCurrYear] = useState(0);

  let [userEnableTil, setUserEnableTil] = useState(false);
  let [clientEnableTil, setClientEnableTil] = useState(false);

  let [excessMinsExtendsAllowance, setExcessMinsExtendsAllowance] = useState(0);
  let [
    totalAllowMinsBeforeExcessApplied,
    setTotalAllowMinsBeforeExcessApplied,
  ] = useState(0);

  // let [customUnlimited = useState(false)
  let [scrollTop, setScrollTop] = useState(false);
  let allowsBox = useRef();
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    const scrollUp = () => {
      if (allowsBox.current) {
        if (showConversions) {
          setShowConversions(false);
        }
        if (showAdjustments) {
          setShowAdjustments(false);
        }
        const currentScrollTop = allowsBox.current.scrollTop;

        if (currentScrollTop > 0) {
          const step = currentScrollTop / 2.5; // You can adjust the division factor for smoother/faster scroll
          allowsBox.current.scrollTop -= step;

          // Call itself recursively until scrollTop is 0
          window.requestAnimationFrame(scrollUp);
        }
      }
    };

    if (!loading && !showMeAllowances) {
      scrollUp();
    }
  }, [allowsBox, loading, scrollTop]);

  useEffect(() => {
    if (customData) {
      setCustomMinsOrDays(customData.totAllow);
      setCustomAdditional(customData.additional);
      setCustomOverride(customData.totAllow);
    }
  }, [selectedCustomLeaveType, customData]);

  let [payExcessOnReqsUser, setPayExcessOnReqsUser] = useState(false);
  let [payExcessOnReqsClient, setPayExcessOnReqsClient] = useState(false);
  let [contractedMpw, setContractedMpw] = useState(0);
  let [minsPerWeekIncludingExcess, setMinsPerWeekIncludingExcess] = useState(0);
  let [refPeriod, setRefPeriod] = useState(0);
  let [showUpdateReqsBtn, setShowUpdateReqsBtn] = useState(false);

  let showMeAllowancesRef = useRef();
  useEffect(() => {
    if (allowsBox.current) {
      setTimeout(() => {
        if (
          showMeAllowances &&
          showMeAllowancesRef &&
          showMeAllowancesRef.current
        ) {
          // Scroll the container to the target element
          showMeAllowancesRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 750);
    }
  }, [showMeAllowances]);

  useEffect(() => {
    setLoading(true);
    if (editUserPage === "Allowances") {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-allowance-types-on-user`,
          { userID: userID },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setAllowanceTypes(response.data.data);
            setCompanyName(response.data.companyName);
            setPayExcessOnReqsUser(response.data.excessMinsPaidInRequestsUser);
            setMinsPerWeekIncludingExcess(
              response.data.minsPerWeekIncludingExcess || 0
            );
            setContractedMpw(response.data.contractedMpw);
            setPayExcessOnReqsClient(
              response.data.excessMinsPaidInRequestsClient
            );
            setRefPeriod(response.data.refPeriod);

            if (response.data.data[1]) {
              setSelectedCustomLeaveType(response.data.data[1].leaveTypeID);
            } else {
              setSelectedCustomLeaveType("annLeave");
            }

            setUserEnableTil(response.data.userEnableTil);
            setClientEnableTil(response.data.clientEnableTil);
            axios
              .post(
                `${serverURL}/get-available-years`,
                { userID: userID },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((resp) => {
                if (resp.data.message === "success") {
                  setAvailableYears(resp.data.data);
                  setSelectedYear(resp.data.currentYear);
                  setUpdateData(!updateData);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [editUserPage]);

  let [pHolQty, setPHolQty] = useState(0);

  let [leaveChartData, setLeaveChartData] = useState({
    taken: 0,
    booked: 0,
    remaining: 0,
    takenVal: 0,
    bookedVal: 0,
    remainingVal: 0,
    daysOrHours: "days",
  });

  let [avgMpd, setAvgMpd] = useState(0);

  useEffect(() => {
    if (editUserPage === "Allowances" && selectedYear) {
      setAllowLoading(true);
      setAccrualDataLoading(true);
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-ann-leave-allowance-toggle-info-on-user`,
          { userID: userID, year: parseInt(selectedYear) },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            if (response.data.data) {
              setContractedHoursOnly(true);
            } else {
              setContractedHoursOnly(false);
            }
            setCurrYear(response.data.currYear);

            setDailyMins(Math.floor(response.data.dailyMins));

            setSelectedDays(response.data.selectedDays);
            setMode(response.data.mode);
            setAnnLeavePercentage(response.data.annLeavePercentage);

            setCurrMpw(response.data.currMpw);
            setAvgMpw(response.data.avgMpw);

            if (response.data.userCreatedThisYear) {
              setUserCreatedThisYear(true);
            } else {
              setUserCreatedThisYear(false);
            }
            setPHolQty(response.data.pHolQty || 0);
            setCreated(response.data.created);
            setAvgMpd(response.data.avgMpd);
            setHistoryMpwEnd(response.data.historyMpwEnd);
            setHistoryMpw(response.data.historyMpw);
            setReqMins(response.data.reqsMins);
            setShiftMins(response.data.shiftMins);
            setOvertimeMins(response.data.overtimeMins);
            setAddedMins(response.data.additions);
            setCurrContractedOrAvg(response.data.baseContractedOnAvg);
            setHistoryTotalMins(response.data.historyTotalMins);
            if (response.data.additions !== 0) {
              setShowAdjustments(true);
            } else {
              setShowAdjustments(false);
            }
            setJoinDate(response.data.joinDate);
            setJoinPercentIntoYear(response.data.joinPercentIntoYear);
            setAccrualDataLoading(false);

            setFade(false);
            setLoading(false);
            axios
              .post(
                `${serverURL}/calc-allowance`,
                {
                  userID: userID,
                  nowDs: dateStringer.nowDs(),
                  year: parseInt(selectedYear),
                  leaveTypeID:
                    annLeaveOrCustom === "annLeave"
                      ? "annLeave"
                      : selectedCustomLeaveType,
                  getAdditionData: parseInt(selectedYear) < parseInt(currYear),
                  getChartData: annLeaveOrCustom === "annLeave" ? true : false,
                },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (annLeaveOrCustom === "annLeave") {
                  setScrollTop(!scrollTop);
                }
                if (response.data.message.includes("logout")) {
                  window.location.href = "/signin";
                }
                if (response.data.message === "success") {
                  setHistoryMins(response.data.hasHistory);
                  setHistVal(response.data.hasHistory);

                  setHistoryPeriodStartDs(response.data.minDs);
                  setHistoryPeriodEndDs(response.data.joinedDs);
                  setLeaveChartData(response.data.leaveChartData);
                  setDailyMins(response.data.userDailyMins);
                  setTilEnabled(response.data.tilEnabled);
                  setToilConversionArr(response.data.conversions);
                  setToilToAnnLeave(response.data.toilToAnnLeave);
                  setAnnLeaveToToil(response.data.annLeaveToToil);
                  setTotalMins(response.data.totalAllowMins);
                  setUsedMins(response.data.usedMins);
                  setRemainingMins(response.data.remaining);
                  setToilBalance(response.data.toilBalance);
                  setCustomDataArr(
                    response.data.customData.filter((x) => {
                      return !x.removed;
                    })
                  );
                  setAnnLeaveAdditions(response.data.annLeaveAdditions);

                  setExcessMinsExtendsAllowance(
                    response.data.excessMinsExtendAllowance
                  );
                  setTotalAllowMinsBeforeExcessApplied(
                    response.data.totalAllowMinsBeforeExcessApplied
                  );
                  setAllowLoading(false);
                  if (loadingCustomLeaveTypeID) {
                    setLoadingCustomLeaveTypeID("");
                  }
                  // setTimeout(() => {
                  setFade(false);
                  setLoading(false);
                  // }, 500);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [editUserPage, updateData]);

  useEffect(() => {
    if (memoVals.updateSingleUserAllowances) {
      console.log("UPDATE CALC ALLOW");
      setUpdateData(!updateData);
      memoVals.setUpdateSingleUserAllowances("");
    }
  }, [memoVals.updateSingleUserAllowances]);

  let [recalc, setRecalc] = useState(0);
  useEffect(() => {
    if (recalc) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/calc-allowance`,
          {
            userID: userID,
            year: parseInt(selectedYear),
            leaveTypeID:
              annLeaveOrCustom === "annLeave"
                ? "annLeave"
                : selectedCustomLeaveType,
            nowDs: dateStringer.nowDs(),
            getAdditionData: parseInt(selectedYear) < parseInt(currYear),
            getChartData: annLeaveOrCustom === "annLeave" ? true : false,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            // percy
            setTilEnabled(response.data.tilEnabled);
            setHistoryMins(response.data.hasHistory);
            setHistVal(response.data.hasHistory);

            if (annLeaveOrCustom === "annLeave") {
              setLeaveChartData(response.data.leaveChartData);

              setTotalMins(response.data.totalAllowMins);
              setUsedMins(response.data.usedMins);
              setRemainingMins(response.data.remaining);
              setToilBalance(response.data.toilBalance);
              setDailyMins(response.data.userDailyMins);

              setCustomDataArr(
                response.data.customData.filter((x) => {
                  return !x.removed;
                })
              );
              setExcessMinsExtendsAllowance(
                response.data.excessMinsExtendAllowance
              );
              setTotalAllowMinsBeforeExcessApplied(
                response.data.totalAllowMinsBeforeExcessApplied
              );
              setAnnLeaveAdditions(response.data.annLeaveAdditions);

              setAllowLoading(false);
              // setTimeout(() => {
              setFade(false);
              setLoading(false);
            } else {
              // is custom
              if (response.data.customData && response.data.customData[0]) {
                setCustomDataArr(
                  response.data.customData.filter((x) => {
                    return !x.removed;
                  })
                );
                let custFilt = response.data.customData.filter((item) => {
                  return item.leaveTypeID === selectedCustomLeaveType;
                });

                setAllowLoading(false);
                setFade(false);
                setLoading(false);
              } else {
                setAllowLoading(false);
                setFade(false);
                setLoading(false);
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [recalc]);

  // setTimeout(() => {
  //   if (allowLoading) {
  //     setRecalc(recalc + 1);
  //   }
  // }, 5000);

  useEffect(() => {
    setShowResetInstruction(false);
  }, []);

  let [showOverride, setShowOverride] = useState(false);
  let [fade, setFade] = useState(false);

  let generateAvailableYears = availableYears.map((yr) => {
    return (
      <option key={yr} value={yr}>
        {yr}
      </option>
    );
  });

  let vals = [
    0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5,
    10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17,
    17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5,
    25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 29.5, 30, 30.5, 31, 31.5, 32,
    32.5, 33, 33.5, 34, 34.5, 35, 35.5, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5,
    40, 40.5, 41, 41.5, 42, 42.5, 43, 43.5, 44, 44.5, 45, 45.5, 46, 46.5, 47,
    47.5, 48, 48.5, 49, 49.5, 50, 50.5, 51, 51.5, 52, 52.5, 53, 53.5, 54, 54.5,
    55, 55.5, 56, 56.5, 57, 57.5, 58, 58.5, 59, 59.5, 60, 60.5, 61, 61.5, 62,
    62.5, 63, 63.5, 64, 64.5, 65, 65.5, 66, 66.5, 67, 67.5, 68, 68.5, 69, 69.5,
    70,
  ];
  let generateManualAllowances = vals.map((num) => {
    if (Math.round(num * (dailyMins / 60) * 10) / 10 === 0) {
      return (
        <option value={num} key={num}>
          {num} {num === 1 || num === 0.5 ? "day" : "days"}
        </option>
      );
    } else {
      return (
        <option value={num} key={num}>
          {num} {num === 1 || num === 0.5 ? "day" : "days"} /{" "}
          {Math.round(num * (dailyMins / 60) * 10) / 10} hours
        </option>
      );
    }
  });

  let calcMpwPeriod = (historyMpwStart, historyMpwEnd, historyMpw) => {
    let endDateObj = new Date(historyMpwEnd);
    let startDateObj = new Date(historyMpwStart);

    let totMs = endDateObj.getTime() - startDateObj.getTime();
    let totDays = totMs / (1000 * 60 * 60 * 24);
    let totWeeks = totDays / 7;

    console.log(historyMpwStart, historyMpwEnd, historyMpw, totDays, totWeeks);

    return historyMpw * totWeeks;
  };

  let getSnapOffset = (totMins, dailyMins) => {
    let days = totMins / dailyMins;
    let surplus = totMins % dailyMins;
    let downBy = surplus;
    let upBy = dailyMins - surplus;

    let obj = {
      downBy,
      upBy,
      downDay: Math.floor(days),
      upDay: Math.ceil(days),
    };

    return obj;
  };

  let [showResetInstuction, setShowResetInstruction] = useState(false);

  let generateCustoms = allowanceTypes.map((type) => {
    if (type.leaveTypeID !== "annLeave") {
      return (
        <div
          className={`customLeaveTypeBarUnit ${
            selectedCustomLeaveType === type.leaveTypeID
              ? "selectedCustomLT"
              : ""
          }`}
          key={type.leaveTypeID}
          onClick={() => {
            setSelectedCustomLeaveType(type.leaveTypeID);
            let newCustomData = customDataArr.filter((item) => {
              return item.leaveTypeID === type.leaveTypeID;
            });

            if (newCustomData[0]) {
              setCustomData(newCustomData[0]);
            }
          }}
        >
          {type.name}
        </div>
      );
    }
  });

  // useEffect(() => {
  //   if (recalc) {
  //     setRecalc(recalc + 1);
  //   }
  // }, [annLeaveOrCustom, selectedYear]);

  return (
    <div className="userAllowancesContainer">
      <div className="allowancesTopDiv"></div>
      <div className="allowancesSelectContainer">
        <select
          className={`availableAllowancesYearsDropdown ${
            allowLoading ? "disable" : ""
          }`}
          value={selectedYear}
          onChange={(e) => {
            setSelectedYear(e.target.value);
            setScrollTop(!scrollTop);
            setFade(true);
            setRecalc(recalc + 1);
            setAllowLoading(true);
            // setUpdateData(!updateData);
          }}
        >
          {generateAvailableYears}
        </select>

        <div className="annLeaveOrCustomSelector">
          <div className="annLeaveOrCustomBox">
            <p
              onClick={() => {
                setAnnLeaveOrCustom("annLeave");
                setAddedMinsOnTop(0);
                setScrollTop(!scrollTop);
                setRecalc(recalc + 1);

                // setUpdateAll(!updateAll);
                // setRecalc(recalc + 0);
                // console.log({ calcData });
              }}
              className={`annLeaveOrCustomTxt ${
                annLeaveOrCustom === "annLeave"
                  ? "annLeaveOrCustomSelected"
                  : "unselAnnLeaveOrCustom"
              }`}
            >
              Annual Leave
            </p>
            <div
              className={`annLeaveOrCustomUnderLineAnnLeave ${
                annLeaveOrCustom === "custom" ? "invis" : ""
              }`}
            ></div>
          </div>
          <div className="annLeaveOrCustomBox">
            <p
              onClick={() => {
                setAnnLeaveOrCustom("custom");

                setTotalMins(0);
                setUsedMins(0);
                setRemainingMins(0);
                setExcessMinsExtendsAllowance(false);
                setTotalAllowMinsBeforeExcessApplied(0);
                setScrollTop(!scrollTop);
                setRecalc(recalc + 1);
              }}
              className={`annLeaveOrCustomTxt ${
                annLeaveOrCustom === "custom"
                  ? "annLeaveOrCustomSelected"
                  : "unselAnnLeaveOrCustom"
              }`}
            >
              Other leave
            </p>
            <div
              className={`annLeaveOrCustomUnderLineAnnLeave ${
                annLeaveOrCustom === "annLeave" ? "invis" : ""
              }`}
            ></div>
          </div>
        </div>
      </div>
      {/* <p>{userID || "no user ID provided"}</p> */}
      <img
        src={horizontalBalls}
        className={`${loading ? "loadingBallsUserAllow" : "none"}`}
        alt="Loading"
      />
      <div
        className={`${
          loading
            ? "none"
            : `userAnnLeaveSettingsContainer ${
                memoVals.device.ios ? "userAnnLeaveSettingsContainerIos" : ""
              } ${fade ? "fadeUserAllowsOnYearChange" : ""}`
        }`}
        ref={allowsBox}
      >
        {annLeaveOrCustom === "annLeave" ? (
          <div>
            <div className="annLeaveAllowanceRemainingContainer">
              <div className="allowanceInfoIfInitialYearContainer">
                <div className="myAccLeaveChartRow">
                  <div
                    className={`myAccLeaveChartTaken myAccLeaveChartTakenMgr ${
                      leaveChartData.taken === 0
                        ? "char0"
                        : leaveChartData.taken === 10
                        ? "char10"
                        : leaveChartData.taken === 20
                        ? "char20"
                        : leaveChartData.taken === 30
                        ? "char30"
                        : leaveChartData.taken === 40
                        ? "char40"
                        : leaveChartData.taken === 50
                        ? "char50"
                        : leaveChartData.taken === 60
                        ? "char60"
                        : leaveChartData.taken === 70
                        ? "char70"
                        : leaveChartData.taken === 80
                        ? "char80"
                        : leaveChartData.taken === 90
                        ? "char90"
                        : "char100"
                    }`}
                  ></div>
                  <div
                    className={`myAccLeaveChartBooked myAccLeaveChartBookedMgr ${
                      leaveChartData.booked === 0 && leaveChartData.taken === 0
                        ? "myAccLeaveChartBookedMinus5Left"
                        : ""
                    }  ${
                      leaveChartData.booked === 0
                        ? "char0"
                        : leaveChartData.booked === 10
                        ? "char10"
                        : leaveChartData.booked === 20
                        ? "char20"
                        : leaveChartData.booked === 30
                        ? "char30"
                        : leaveChartData.booked === 40
                        ? "char40"
                        : leaveChartData.booked === 50
                        ? "char50"
                        : leaveChartData.booked === 60
                        ? "char60"
                        : leaveChartData.booked === 70
                        ? "char70"
                        : leaveChartData.booked === 80
                        ? "char80"
                        : leaveChartData.booked === 90
                        ? "char90"
                        : "char100"
                    }
`}
                  ></div>

                  <div
                    className={`myAccLeaveChartRemaining myAccLeaveChartRemainingMgr ${
                      leaveChartData.remaining === 0
                        ? "char0"
                        : leaveChartData.remaining === 10
                        ? "char10"
                        : leaveChartData.remaining === 20
                        ? "char20"
                        : leaveChartData.remaining === 30
                        ? "char30"
                        : leaveChartData.remaining === 40
                        ? "char40"
                        : leaveChartData.remaining === 50
                        ? "char50"
                        : leaveChartData.remaining === 60
                        ? "char60"
                        : leaveChartData.remaining === 70
                        ? "char70"
                        : leaveChartData.remaining === 80
                        ? "char80"
                        : leaveChartData.remaining === 90
                        ? "char90"
                        : "char100"
                    }
`}
                  ></div>
                </div>
                <div className="myAccLeaveChartKey userAllowChartBottom">
                  <div className="myAccLeaveChartKeyItem keyItem1">
                    <div className="myAccLeaveChartKeyBlob myAccLeaveChartTakenMgr"></div>
                    <div className="myAccLCKeyTxtDiv">
                      <p className="myAccLCkeyVal">
                        {leaveChartData.daysOrHours === "hours"
                          ? dateStringer.formatMinsDurationToShortHours(
                              leaveChartData.takenVal || 0
                            )
                          : leaveChartData.takenVal || 0}{" "}
                        {leaveChartData.daysOrHours === "days" ? "day" : ""}
                        {leaveChartData.takenVal === 1
                          ? ""
                          : leaveChartData.daysOrHours === "days"
                          ? "s"
                          : ""}
                      </p>
                      <p className="myAccLCkeyValTxt">taken</p>
                    </div>
                  </div>{" "}
                  {parseInt(selectedYear) >= parseInt(currYear) && (
                    <div className="myAccLeaveChartKeyItem keyItem2">
                      <div className="myAccLeaveChartKeyBlob myAccLeaveChartKeyBlobBooked myAccLeaveChartBookedMgr"></div>
                      <div className="myAccLCKeyTxtDiv">
                        <p className="myAccLCkeyVal">
                          {leaveChartData.daysOrHours === "hours"
                            ? dateStringer.formatMinsDurationToShortHours(
                                leaveChartData.bookedVal || 0
                              )
                            : leaveChartData.bookedVal || 0}{" "}
                          {leaveChartData.daysOrHours === "days" ? "day" : ""}
                          {leaveChartData.bookedVal === 1
                            ? ""
                            : leaveChartData.daysOrHours === "days"
                            ? "s"
                            : ""}
                        </p>
                        <p className="myAccLCkeyValTxt">booked</p>
                      </div>
                    </div>
                  )}
                  <div className="myAccLeaveChartKeyItem keyItem3">
                    <div className="myAccLeaveChartKeyBlob myAccLeaveChartKeyBlobRemaining myAccLeaveChartRemainingMgr"></div>
                    <div className="myAccLCKeyTxtDiv">
                      <p className="myAccLCkeyVal">
                        {leaveChartData.daysOrHours === "hours"
                          ? dateStringer.formatMinsDurationToShortHours(
                              leaveChartData.remainingVal || 0
                            )
                          : leaveChartData.remainingVal || 0}{" "}
                        {leaveChartData.daysOrHours === "days" ? "day" : ""}
                        {leaveChartData.remainingVal === 1
                          ? ""
                          : leaveChartData.daysOrHours === "days"
                          ? "s"
                          : ""}
                      </p>
                      <p className="myAccLCkeyValTxt">remaining</p>
                    </div>
                  </div>
                </div>
                <div className="allowanceRow noBorder">
                  <p className="allowanceInfoKey">
                    {/* hello */}
                    Remaining
                  </p>
                  <img
                    src={blueLoader}
                    className={`${
                      allowLoading ? "allowanceLoadingImg" : "none"
                    }`}
                    alt="loading"
                  />
                  <p
                    className={`${
                      allowLoading ? "none" : "allowanceInfoValue"
                    } ${
                      dateStringer.formatMinsDurationToHours(
                        remainingMins || 0,
                        false,
                        true
                      ) ===
                      dateStringer.convertMinsToDays(
                        remainingMins || 0,
                        dailyMins
                      )
                        ? "flexEnd"
                        : ""
                    } ${remainingMins > 0 ? "remainingColourGreen" : ""}`}
                  >
                    {dateStringer.formatMinsDurationToHours(
                      remainingMins || 0,
                      false,
                      true
                    )}{" "}
                    {dateStringer.formatMinsDurationToHours(
                      remainingMins || 0,
                      false,
                      true
                    ) !==
                      dateStringer.convertMinsToDays(
                        remainingMins || 0,
                        dailyMins
                      ) && remainingMins >= 0 ? (
                      <span
                        className={`daysConvertSpan ${
                          remainingMins > 0 ? "remainingColourGreen" : ""
                        }`}
                      >
                        {dateStringer.convertMinsToDays(
                          remainingMins || 0,
                          dailyMins
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                <div className="allowanceInfoRowDivider"></div>
                <div
                  className="allowanceRow"
                  onClick={() => {
                    console.log({ usedMins, dailyMins });
                  }}
                >
                  <p className="allowanceInfoKey">
                    {/* hello */}
                    Used
                  </p>
                  <img
                    src={blueLoader}
                    className={`${
                      allowLoading ? "allowanceLoadingImg" : "none"
                    }`}
                    alt="loading"
                  />
                  <div
                    className={`${
                      allowLoading ? "none" : "allowanceInfoValue"
                    } ${
                      dateStringer.formatMinsDurationToHours(usedMins || 0) ===
                      dateStringer.convertMinsToDays(usedMins || 0, dailyMins)
                        ? "flexEnd"
                        : ""
                    } ${usedMins > 0 ? "colour143051" : ""}`}
                  >
                    {dateStringer.formatMinsDurationToHours(usedMins || 0)}{" "}
                    {dateStringer.formatMinsDurationToHours(usedMins || 0) !==
                    dateStringer.convertMinsToDays(usedMins || 0, dailyMins) ? (
                      <span className="daysConvertSpan">
                        {dateStringer.convertMinsToDays(
                          usedMins || 0,
                          dailyMins
                        )}
                      </span>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                </div>
                {excessMinsExtendsAllowance > 0 ? (
                  <div className="allowanceInfoRowDivider extraThickDivider"></div>
                ) : (
                  ""
                )}
                <div
                  className={`${
                    excessMinsExtendsAllowance > 0 ? "allowanceRow" : "none"
                  }`}
                >
                  <p className="allowanceInfoKey">
                    {/* hello */}
                    Accrued from schedule
                  </p>
                  <img
                    src={blueLoader}
                    className={`${
                      allowLoading ? "allowanceLoadingImg" : "none"
                    }`}
                    alt="loading"
                  />
                  <p
                    className={`${
                      allowLoading ? "none" : "allowanceInfoValue"
                    }`}
                  >
                    <span className="daysConvertSpan">
                      {dateStringer.formatMinsDurationToHours(
                        totalAllowMinsBeforeExcessApplied
                      )}
                    </span>
                  </p>
                </div>
                <div
                  className={`${
                    excessMinsExtendsAllowance > 0
                      ? "allowanceInfoRowDivider"
                      : "none"
                  }`}
                ></div>
                <div
                  className={`${
                    excessMinsExtendsAllowance > 0 ? "allowanceRow" : "none"
                  }`}
                >
                  <p className="allowanceInfoKey">
                    {/* hello */}
                    Accrued from leave
                  </p>
                  <img
                    src={blueLoader}
                    className={`${
                      allowLoading ? "allowanceLoadingImg" : "none"
                    }`}
                    alt="loading"
                  />
                  <p
                    className={`${
                      allowLoading ? "none" : "allowanceInfoValue"
                    }`}
                  >
                    <span className="daysConvertSpan">
                      {dateStringer.formatMinsDurationToHours(
                        excessMinsExtendsAllowance
                      )}
                    </span>
                  </p>
                </div>
                <div
                  className={`${
                    excessMinsExtendsAllowance > 0
                      ? "allowanceInfoRowDivider"
                      : "none"
                  }`}
                ></div>
                <div
                  className="allowanceRow highlightedAllowanceRow"
                  onClick={() => {
                    console.log(
                      "added mins is: ",
                      addedMins,
                      "total mins is: ",
                      totalMins
                    );
                  }}
                >
                  <p className="allowanceInfoKey">
                    {/* hello */}
                    Total
                  </p>
                  <img
                    src={blueLoader}
                    className={`${
                      allowLoading ? "allowanceLoadingImg" : "none"
                    }`}
                    alt="loading"
                  />
                  <div
                    className={`${
                      allowLoading ? "none" : "allowanceInfoValue"
                    } ${
                      dateStringer.formatMinsDurationToHours(totalMins || 0) ===
                      dateStringer.convertMinsToDays(totalMins || 0, dailyMins)
                        ? "flexEnd daisyButler"
                        : ""
                    }`}
                  >
                    {" "}
                    {totalMins < 0 ? "-" : ""}
                    {dateStringer.formatMinsDurationToHours(totalMins || 0)}
                    {dateStringer.formatMinsDurationToHours(totalMins || 0) !==
                    dateStringer.convertMinsToDays(
                      totalMins || 0,
                      dailyMins
                    ) ? (
                      <span
                        className={`daysConvertSpan totalAllowSpanHighlighted
                
                    `}
                      >
                        {dateStringer.convertMinsToDays(
                          totalMins || 0,
                          dailyMins
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className={`historyAdj ${allowLoading ? "disable" : ""}`}>
                  {parseInt(selectedYear) < parseInt(currYear) && (
                    <div
                      className="historyPlusMinus1 minusHistAnnLeave"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          axios
                            .post(
                              `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                              {
                                userID: userID,
                                leaveTypeID: "annLeave",
                                year: parseInt(selectedYear),
                                mins: -60,
                                settingAsZero: false,
                                historic: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                if (!checkListDone.includes("allowances")) {
                                  setCheckListDone([
                                    ...checkListDone,
                                    "allowances",
                                  ]);
                                }
                                let newArr = [];
                                setAllowancesChanged(true);
                                setUpdateData(!updateData);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      -1hr
                    </div>
                  )}
                  {parseInt(selectedYear) < parseInt(currYear) && (
                    <div
                      className="historyPlusMinus1 minusHistAnnLeave"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          axios
                            .post(
                              `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                              {
                                userID: userID,
                                leaveTypeID: "annLeave",
                                year: parseInt(selectedYear),
                                mins: -1,
                                settingAsZero: false,
                                historic: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                if (!checkListDone.includes("allowances")) {
                                  setCheckListDone([
                                    ...checkListDone,
                                    "allowances",
                                  ]);
                                }
                                let newArr = [];
                                setAllowancesChanged(true);
                                setUpdateData(!updateData);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      -1m
                    </div>
                  )}
                  {parseInt(selectedYear) < parseInt(currYear) && (
                    <div
                      className="historyPlusMinus1"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          axios
                            .post(
                              `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                              {
                                userID: userID,
                                leaveTypeID: "annLeave",
                                year: parseInt(selectedYear),
                                mins: 1,
                                settingAsZero: false,
                                historic: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                if (!checkListDone.includes("allowances")) {
                                  setCheckListDone([
                                    ...checkListDone,
                                    "allowances",
                                  ]);
                                }
                                let newArr = [];
                                setAllowancesChanged(true);
                                setUpdateData(!updateData);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      +1m
                    </div>
                  )}
                  {parseInt(selectedYear) < parseInt(currYear) && (
                    <div
                      className="historyPlusMinus1"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          axios
                            .post(
                              `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                              {
                                userID: userID,
                                leaveTypeID: "annLeave",
                                year: parseInt(selectedYear),
                                mins: 60,
                                settingAsZero: false,
                                historic: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                if (!checkListDone.includes("allowances")) {
                                  setCheckListDone([
                                    ...checkListDone,
                                    "allowances",
                                  ]);
                                }
                                let newArr = [];
                                setAllowancesChanged(true);
                                setUpdateData(!updateData);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      +1hr
                    </div>
                  )}
                </div>
                {!isNaN(annLeaveAdditions) && annLeaveAdditions !== 0 ? (
                  <div className="allowsPholsRow annLeaveAdditionsRowHist">
                    {annLeaveAdditions < 0 ? "-" : "+"}
                    {dateStringer.formatMinsDurationToHours(
                      annLeaveAdditions
                    )}{" "}
                    adjustment
                  </div>
                ) : (
                  ""
                )}
                {pHolQty > 0 ? (
                  <div className="allowsPholsRow">
                    + <span className="pHolQtyAllows">{pHolQty}</span> paid{" "}
                    {memoVals.currencySymbol === "£" ? "bank" : "public"}{" "}
                    holidays
                  </div>
                ) : (
                  ""
                )}
                {addedMins !== 0 && 2 + 2 === 5 ? (
                  <div className="adjAllowRow">
                    <div
                      className="adjAllowInfo"
                      onClick={() => {
                        console.log({
                          dailyMins,
                          customAdditional,
                          addedMins,
                          addedMinsOnTop,
                        });
                      }}
                    >
                      Manually adjusted:
                      <br />
                      <span className="manAdjSpan">
                        {addedMins > 0 ? "+" : "-"}
                        {dateStringer.formatMinsDurationToHours(
                          Math.abs(addedMins)
                        )}
                      </span>
                    </div>

                    <div
                      className="adjAllowInfo adjAllowInfo2"
                      onClick={() => {
                        console.log({
                          dailyMins,
                          customAdditional,
                          addedMins,
                          addedMinsOnTop,
                        });
                      }}
                    >
                      Before adjustment: <br />
                      <span className="manAdjSpan">
                        {dateStringer.convertMinsToDays(
                          totalMins - addedMins,
                          dailyMins
                        )}
                      </span>
                      {/* {dateStringer.formatMinsDurationToHours(
                    Math.abs(totalMins + addedMins)
                  )} */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div
                  className={`${
                    mode === 1 &&
                    getSnapOffset(totalMins, dailyMins).upDay > 0 &&
                    getSnapOffset(totalMins, dailyMins).downDay > 0 &&
                    !allowLoading &&
                    getSnapOffset(totalMins, dailyMins).upDay !==
                      getSnapOffset(totalMins, dailyMins).downDay
                      ? `snapOptionsContainer ${
                          showResetInstuction ? "opac4SnapBtns" : ""
                        }`
                      : "none"
                  } ${
                    checkUserState.permissions !== 1 && editUserState.isSelf
                      ? "greyscale"
                      : ""
                  }`}
                >
                  <p>&nbsp;</p>
                  <div className="snapBtnsParent">
                    <div
                      className="snapDownBtn"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          if (addedMins !== 0) {
                            setShowResetInstruction(true);
                            setShowAdjustments(true);

                            setFlashReset(true);
                            setTimeout(() => {
                              setFlashReset(false);
                              setTimeout(() => {
                                setFlashReset(true);
                                setTimeout(() => {
                                  setFlashReset(false);
                                  setTimeout(() => {
                                    setFlashReset(true);
                                    setTimeout(() => {
                                      setFlashReset(false);
                                      setTimeout(() => {
                                        setFlashReset(true);
                                        setTimeout(() => {
                                          setFlashReset(false);
                                          setTimeout(() => {
                                            setFlashReset(true);
                                            setTimeout(() => {
                                              setFlashReset(false);
                                              setTimeout(() => {
                                                setFlashReset(true);
                                                setTimeout(() => {
                                                  setFlashReset(false);
                                                }, 250);
                                              }, 250);
                                            }, 250);
                                          }, 250);
                                        }, 250);
                                      }, 250);
                                    }, 250);
                                  }, 250);
                                }, 250);
                              }, 250);
                            }, 250);
                          } else {
                            setShowResetInstruction(false);
                            setAllowLoading(true);
                            let downMinsTotal =
                              getSnapOffset(totalMins, dailyMins).downDay *
                              dailyMins;
                            let downDiff = totalMins - downMinsTotal;
                            if (showResetInstuction) {
                              setShowResetInstruction(false);
                            }
                            axios
                              .post(
                                `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                                {
                                  userID: userID,
                                  year: parseInt(selectedYear),
                                  mins: 0 - downDiff,
                                  leaveTypeID:
                                    annLeaveOrCustom === "annLeave"
                                      ? "annLeave"
                                      : selectedCustomLeaveType,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message.includes("logout")) {
                                  window.location.href = "/signin";
                                }
                                if (response.data.message === "success") {
                                  if (!checkListDone.includes("allowances")) {
                                    setCheckListDone([
                                      ...checkListDone,
                                      "allowances",
                                    ]);
                                  }
                                  setScrollTop(!scrollTop);
                                  setAllowancesChanged(true);
                                  setAddedMins(response.data.data);

                                  setAddedMinsOnTop(0);
                                  setAllowsUpdatedLocal(true);
                                  setTimeout(() => {
                                    setRecalc(recalc + 1);
                                  }, 50);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }
                      }}
                    >
                      <img src={whiteGoBack} alt="up" className="snapDownImg" />{" "}
                      <p className="snapToTxt">
                        Round down
                        <br />
                        to {getSnapOffset(totalMins, dailyMins).downDay} days
                      </p>
                    </div>
                    <div
                      className="snapUpBtn"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          if (addedMins !== 0) {
                            setShowResetInstruction(true);
                            setShowAdjustments(true);
                            setFlashReset(true);
                            setTimeout(() => {
                              setFlashReset(false);
                              setTimeout(() => {
                                setFlashReset(true);
                                setTimeout(() => {
                                  setFlashReset(false);
                                  setTimeout(() => {
                                    setFlashReset(true);
                                    setTimeout(() => {
                                      setFlashReset(false);
                                    }, 250);
                                  }, 250);
                                }, 250);
                              }, 250);
                            }, 250);
                          } else {
                            setShowResetInstruction(false);

                            setAllowLoading(true);

                            let upMinsTotal =
                              getSnapOffset(totalMins, dailyMins).upDay *
                              dailyMins;
                            let upDiff = upMinsTotal - totalMins;

                            console.log(
                              "total mins is: ",
                              totalMins,
                              "upDiff will be: ",
                              upDiff
                            );
                            if (showResetInstuction) {
                              setShowResetInstruction(false);
                            }
                            axios
                              .post(
                                `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                                {
                                  userID: userID,
                                  year: parseInt(selectedYear),
                                  mins: upDiff,
                                  leaveTypeID:
                                    annLeaveOrCustom === "annLeave"
                                      ? "annLeave"
                                      : selectedCustomLeaveType,
                                  // mins: getSnapOffset(totalMins, dailyMins).upBy,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message.includes("logout")) {
                                  window.location.href = "/signin";
                                }
                                if (response.data.message === "success") {
                                  if (!checkListDone.includes("allowances")) {
                                    setCheckListDone([
                                      ...checkListDone,
                                      "allowances",
                                    ]);
                                  }
                                  setScrollTop(!scrollTop);
                                  setAllowancesChanged(true);

                                  setAllowsUpdatedLocal(true);
                                  setAddedMins(response.data.data);

                                  setAddedMinsOnTop(0);
                                  setTimeout(() => {
                                    setRecalc(recalc + 1);
                                  }, 50);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }
                      }}
                    >
                      <img src={whiteGoBack} alt="up" className="snapUpImg" />{" "}
                      <p className="snapToTxt">
                        Round up
                        <br />
                        to {getSnapOffset(totalMins, dailyMins).upDay} days
                      </p>
                    </div>{" "}
                  </div>
                </div>
                <div
                  className={`${
                    showResetInstuction ? "resetInstructionDiv" : "none"
                  }`}
                >
                  <p>&nbsp;</p>
                  <div
                    className={`${
                      showResetInstuction ? "showResetInstruction" : "none"
                    }`}
                  >
                    <img
                      src={resetWarning}
                      className="resetWarningImg"
                      alt="Note"
                    />
                    <p className="firstYouMustInstructionTxt">
                      First, you need to reset
                      <br />
                      the adjustment below.
                    </p>
                  </div>
                </div>
                {dailyMins > 0 && (
                  <div className="adjAllowRow">
                    <div className="adjAllowInfo">
                      Each day is worth:
                      <br />
                      <span className="manAdjSpan">
                        {dateStringer.formatMinsDurationToHours(dailyMins)}
                      </span>
                      <br />
                      <p className="avgBase">
                        based on {dateStringer.possession(fName)}
                        <br />
                        current work pattern
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* additions container */}
            <div
              className={`${
                dailyMins > 0
                  ? `allowanceAdditionsContainer ${
                      showAdjustments ? "additionsExtended" : ""
                    }`
                  : "none"
              } ${
                checkUserState.permissions !== 1 && editUserState.isSelf
                  ? "greyscale"
                  : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                if (checkUserState.permissions !== 1 && editUserState.isSelf) {
                  setShowIsSelfNotAdminModal(true);
                } else {
                  setShowAdjustments(!showAdjustments);
                }
              }}
            >
              <div className="showMetaToggle">
                <img
                  src={allowDropNavy2}
                  alt="Show meta info"
                  className={`showAccrualDataArrowImg ${
                    showAdjustments ? "dataArrowShowMetaActive" : ""
                  }`}
                />
                <p className="accruInfoTxt">Adjust allowance</p>{" "}
              </div>

              <div
                className={`${
                  showAdjustments
                    ? `${memoVals.mobModal ? "" : "addOrDeductContent"}`
                    : "none"
                }`}
              >
                <div
                  className={`${allowLoading ? "loadingAdditionsDiv" : "none"}`}
                >
                  <img
                    src={blueLoader}
                    className="allowanceLoadingImg"
                    alt="loading"
                  />
                  &nbsp;
                </div>
                <p
                  className={`${
                    allowLoading ? "none" : "additionalMinsTxt fontSize14"
                  }`}
                >
                  {addedMins + addedMinsOnTop >= 0
                    ? "Manually added"
                    : "Manually deducted"}{" "}
                  <span
                    className={`addedMinsSpan ${
                      addedMinsOnTop === 0 ? "" : "manipulatedAddedMinsSpan"
                    }`}
                  >
                    {dateStringer.formatMinsDurationToHours(
                      (addedMins || 0) + (addedMinsOnTop || 0)
                    )}
                  </span>
                </p>

                <div
                  className={`basedOnHrsToDaysContainer ${
                    allowLoading ? "opac4SnapBtns" : ""
                  } none`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <p
                    onClick={() => {
                      console.log({ dailyMins });
                    }}
                    className={`${
                      dailyMins > 0
                        ? `hoursToDaysConversion ${
                            addedMinsOnTop !== 0
                              ? "manipulatedDaysConversion"
                              : ""
                          }`
                        : "none"
                    }`}
                  >
                    {addedMins + addedMinsOnTop >= 0 ? "+" : "-"}{" "}
                    {Math.abs(
                      ((addedMins + addedMinsOnTop) / dailyMins).toFixed(2)
                    )}{" "}
                    day
                    {Math.abs(
                      ((addedMins + addedMinsOnTop) / dailyMins).toFixed(2)
                    ) === 1
                      ? ""
                      : "s"}
                  </p>
                  <p
                    className={`${
                      dailyMins > 0 && selectedYear >= currYear
                        ? "equivalentHrsToDaysTxt"
                        : "none"
                    }`}
                  >
                    based on {fName}'s current contracted working pattern
                  </p>
                </div>
                <div
                  className={`additionsParent ${
                    window.innerWidth < 820 ? "noBorder" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="additionsBtnsContainer">
                    <div className="additionsBtnsContainerRow">
                      <p
                        className="plus1m"
                        onClick={() => {
                          setAddedMinsOnTop(addedMinsOnTop + 1);
                          setEditAddition(true);
                        }}
                      >
                        <span className="addOrMinusNumberSpan">+1</span>
                        <span className="allowBtnValSpan">min</span>
                      </p>
                      <div
                        className="plus1m"
                        onClick={() => {
                          setAddedMinsOnTop(addedMinsOnTop + 30);
                          setEditAddition(true);
                        }}
                      >
                        <div className="thirtyDiv">
                          <span className="addOrMinusNumberSpan">+</span>
                          <span className="thirtySpan">30</span>
                        </div>
                        <span className="allowBtnValSpan">mins</span>
                      </div>
                      <p
                        className="plus1m"
                        onClick={() => {
                          setAddedMinsOnTop(addedMinsOnTop + 60);
                          setEditAddition(true);
                        }}
                      >
                        <span className="addOrMinusNumberSpan">+1</span>
                        <span className="allowBtnValSpan">hour</span>
                      </p>
                      <p
                        className="plus1m"
                        onClick={() => {
                          setAddedMinsOnTop(addedMinsOnTop + dailyMins);
                          setEditAddition(true);
                        }}
                      >
                        <span className="addOrMinusNumberSpan">+1</span>
                        <span className="allowBtnValSpan">day</span>
                      </p>
                    </div>
                    <div className="additionsBtnsContainerRow">
                      <p
                        className="minus1m"
                        onClick={() => {
                          setAddedMinsOnTop(addedMinsOnTop - 1);
                          setEditAddition(true);
                        }}
                      >
                        <span className="addOrMinusNumberSpan">-1</span>
                        <span className="allowBtnValSpan">min</span>
                      </p>
                      <div
                        className="minus1m"
                        onClick={() => {
                          setAddedMinsOnTop(addedMinsOnTop - 30);
                          setEditAddition(true);
                        }}
                      >
                        <div className="thirtyDiv">
                          <span className="addOrMinusNumberSpan">-</span>
                          <span className="thirtySpan">30</span>
                        </div>
                        <span className="allowBtnValSpan">mins</span>
                      </div>
                      <p
                        className="minus1m"
                        onClick={() => {
                          setAddedMinsOnTop(addedMinsOnTop - 60);
                          setEditAddition(true);
                        }}
                      >
                        <span className="addOrMinusNumberSpan">-1</span>
                        <span className="allowBtnValSpan">hour</span>
                      </p>
                      <p
                        className="minus1m"
                        onClick={() => {
                          setAddedMinsOnTop(addedMinsOnTop - dailyMins);
                          setEditAddition(true);
                        }}
                      >
                        <span className="addOrMinusNumberSpan">-1</span>
                        <span className="allowBtnValSpan">day</span>
                      </p>
                    </div>
                  </div>
                  <div className="saveCanxBtnContainerAllows">
                    <p
                      className={`${
                        editAddition
                          ? "submitAdditionsBtn"
                          : "submitAdditionsBtn fadedSubmitAdditionsBtn"
                      }`}
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal("allow");
                        } else {
                          setEditAddition(false);
                          setAllowLoading(true);

                          if (showResetInstuction) {
                            setShowResetInstruction(false);
                          }
                          axios
                            .post(
                              `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                              {
                                userID: userID,
                                leaveTypeID:
                                  annLeaveOrCustom === "annLeave"
                                    ? "annLeave"
                                    : selectedCustomLeaveType,

                                year: parseInt(selectedYear),
                                mins:
                                  annLeaveOrCustom === "annLeave"
                                    ? addedMins + addedMinsOnTop
                                    : customOverride,
                                settingAsZero:
                                  annLeaveOrCustom !== "annLeave" &&
                                  customOverride === 0
                                    ? true
                                    : false,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                if (!checkListDone.includes("allowances")) {
                                  setCheckListDone([
                                    ...checkListDone,
                                    "allowances",
                                  ]);
                                }
                                setScrollTop(!scrollTop);
                                setAllowancesChanged(true);

                                setAllowsUpdatedLocal(true);
                                setAddedMins(response.data.data);

                                setAddedMinsOnTop(0);
                                setTimeout(() => {
                                  setRecalc(recalc + 1);
                                }, 250);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      Save
                    </p>

                    <p
                      className={`cancelAdditionsBtn ${
                        editAddition ? "" : "fadedSubmitAdditionsBtn"
                      }`}
                      onClick={() => {
                        setAddedMinsOnTop(0);
                        setEditAddition(false);
                      }}
                    >
                      Cancel
                    </p>
                    <p
                      className={`cancelAdditionsBtn ${
                        showResetInstuction
                          ? "resetInstructionBtnHighlight"
                          : ""
                      } ${
                        addedMins === 0 && addedMinsOnTop === 0
                          ? "fadedSubmitAdditionsBtn"
                          : ""
                      } ${flashReset ? "flashReset" : ""}`}
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal("allow");
                        } else {
                          setEditAddition(false);
                          setAllowLoading(true);
                          setShowResetInstruction(false);

                          if (showResetInstuction) {
                            setShowResetInstruction(false);
                          }
                          axios
                            .post(
                              `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                              {
                                userID: userID,
                                year: parseInt(selectedYear),
                                mins: 0,
                                leaveTypeID:
                                  annLeaveOrCustom === "annLeave"
                                    ? "annLeave"
                                    : selectedCustomLeaveType,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                if (!checkListDone.includes("allowances")) {
                                  setCheckListDone([
                                    ...checkListDone,
                                    "allowances",
                                  ]);
                                }
                                setScrollTop(!scrollTop);
                                setAllowancesChanged(true);

                                setAllowsUpdatedLocal(true);

                                setAddedMins(response.data.data);

                                setAddedMinsOnTop(0);
                                setTimeout(() => {
                                  setRecalc(recalc + 1);
                                }, 50);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      Reset
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* end of additions container */}

            {/* Conversions container */}
            {tilEnabled && (
              <div
                className={`${
                  annLeaveToToil || toilToAnnLeave
                    ? `allowanceAdditionsContainer ${
                        showConversions ? "additionsExtended" : ""
                      }`
                    : "none"
                } ${tilEnabled ? "" : "none"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    checkUserState.permissions !== 1 &&
                    editUserState.isSelf
                  ) {
                    setShowIsSelfNotAdminModal(true);
                  } else {
                    if (!showConversions) {
                      setShowConversions(true);
                    }
                  }
                }}
              >
                <div
                  className="showMetaToggle"
                  onClick={(e) => {
                    setShowConversions(!showConversions);
                    e.stopPropagation();
                  }}
                >
                  <img
                    src={allowDropNavy2}
                    alt="Show meta info"
                    className={`showAccrualDataArrowImg ${
                      showConversions ? "dataArrowShowMetaActive" : ""
                    }`}
                  />
                  <p className="accruInfoTxt">
                    TOIL conversions ({toilConversionArr.length})
                  </p>
                </div>

                <div
                  className={`${
                    showConversions
                      ? `${memoVals.mobModal ? "" : "addOrDeductContent"}`
                      : "none"
                  }`}
                >
                  <div
                    className={`${
                      allowLoading ? "loadingAdditionsDiv" : "none"
                    }`}
                  >
                    <img
                      src={blueLoader}
                      className="allowanceLoadingImg"
                      alt="loading"
                    />
                    &nbsp;
                  </div>

                  {toilToAnnLeave > 0 ? (
                    <p
                      className={`${
                        allowLoading
                          ? "none"
                          : `additionalMinsTxt ${
                              window.innerWidth < 820
                                ? "additionalMinsTxtMobLieu"
                                : ""
                            }`
                      }`}
                    >
                      TOIL converted to Annual Leave: <br />
                      <span className="addedMinsSpan manipulatedAddedMinsSpan">
                        {dateStringer.formatMinsDurationToHours(toilToAnnLeave)}
                      </span>{" "}
                      {dateStringer
                        .convertMinsToDays(toilToAnnLeave, dailyMins, true)
                        .includes("minute") || dailyMins <= 0 ? (
                        ""
                      ) : (
                        <span className="x9hh">
                          (
                          {dateStringer.convertMinsToDays(
                            toilToAnnLeave,
                            dailyMins,
                            true
                          )}
                          )
                        </span>
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                  {annLeaveToToil ? (
                    <p
                      className={`${
                        allowLoading
                          ? "none"
                          : `additionalMinsTxt additionalMinsTxt2 ${
                              window.innerWidth < 820
                                ? "additionalMinsTxtMobLieu"
                                : ""
                            }`
                      }`}
                    >
                      Annual leave converted to TOIL: <br />
                      <span className="addedMinsSpan manipulatedAddedMinsSpan">
                        {dateStringer.formatMinsDurationToHours(annLeaveToToil)}
                      </span>{" "}
                      {dateStringer
                        .convertMinsToDays(annLeaveToToil, dailyMins, true)
                        .includes("minute") || dailyMins <= 0 ? (
                        ""
                      ) : (
                        <span className="x9hh">
                          (
                          {dateStringer.convertMinsToDays(
                            annLeaveToToil,
                            dailyMins,
                            true
                          )}
                          )
                        </span>
                      )}
                    </p>
                  ) : (
                    ""
                  )}

                  <div
                    className={`userAllowsConversionsArr ${
                      window.innerWidth < 820 ? "noBorder" : ""
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {toilConversionArr
                      .sort((a, b) => {
                        return b.ts - a.ts;
                      })
                      .map((item, i) => {
                        return (
                          <div
                            className={`dashTilListItem defaultCursor x248924894 userAllowToilConvRow
                        `}
                            key={i}
                          >
                            <div className="dashTilListItemLeft">
                              <p className="dashTilType">Conversion</p>
                              <p className="dashTilListItemDs">
                                <img
                                  src={convArr}
                                  alt="Conversion"
                                  className="convArrinline"
                                />

                                {dateStringer.printedDateFromDs(item.ds, true)}
                              </p>
                              <p
                                className={`dashTilListItemTimeStrip ${
                                  item.toLeave ? "" : "colour00ccff"
                                }`}
                              >
                                {item.toLeave
                                  ? "TOIL to Annual Leave"
                                  : "Annual Leave to TOIL"}
                              </p>
                              {item.addedByFName && item.addedByLName ? (
                                <p className="itemconvAddedBy">
                                  By {item.addedByFName} {item.addedByLName[0]}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="dashTilListItemRight">
                              <p
                                className={`dashTilListItemDurMins ${
                                  item.toLeave
                                    ? "dashTilListItemDurMins"
                                    : "colour00ccff"
                                }`}
                              >
                                {!item.toLeave ? "-" : "+"}
                                {dateStringer.formatMinsDurationToHours(
                                  item.mins
                                )}
                              </p>
                              <p className="dashTilListItemType">
                                {item.toLeave
                                  ? `added to annual leave`
                                  : "deducted from annual leave"}
                              </p>
                              <p
                                className="deleteToilConvBtn"
                                onClick={() => {
                                  setSureDeleteConversion({
                                    show: true,
                                    conversionID: item.conversionID,
                                    userID: userID,
                                    toLeave: item.toLeave,
                                    mins: item.mins,
                                  });
                                }}
                              >
                                Remove
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    {tilEnabled && selectedYear == currYear ? (
                      <div
                        className={`dashAllowEditBtnHolder noBorder ${
                          !showConversions
                            ? `pullUp36px ${
                                window.innerWidth < 820 ? "convBtnMob" : ""
                              }`
                            : ""
                        }`}
                      >
                        <div
                          className={`convToilToLeaveEditUserBtn   ${
                            !showConversions && window.innerWidth < 820
                              ? "allLeaveDashControllerMobClosed"
                              : ""
                          } ${
                            !toilConversionArr[0] && !addedMins
                              ? "x88392727272"
                              : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();

                            memoVals.setShowConvertToilModal((x) => userID);
                            // console.log("!", user.userID);
                          }}
                        >
                          {/* <div className="dashAddDeductTitle"> */}
                          New conversion
                          <img
                            src={convert}
                            alt="Edit"
                            className="convertImg"
                          />
                          {/* </div> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )}

            {tilEnabled && selectedYear == currYear && (
              <div className="allowanceRow noBorder editUserToilBalRow">
                <p className="allowanceInfoKey">TOIL balance</p>
                <img
                  src={blueLoader}
                  className={`${allowLoading ? "allowanceLoadingImg" : "none"}`}
                  alt="loading"
                />
                <p
                  className={`${
                    allowLoading ? "none" : "allowanceInfoValue toilSpanBlue"
                  } ${
                    dateStringer.formatMinsDurationToHours(
                      toilBalance || 0,
                      false,
                      true
                    ) ===
                    dateStringer.convertMinsToDays(toilBalance || 0, dailyMins)
                      ? "flexEnd"
                      : ""
                  }`}
                >
                  {dateStringer.formatMinsDurationToHours(
                    toilBalance || 0,
                    false,
                    true
                  )}{" "}
                  {dateStringer.formatMinsDurationToHours(
                    toilBalance || 0,
                    false,
                    true
                  ) !==
                    dateStringer.convertMinsToDays(
                      toilBalance || 0,
                      dailyMins
                    ) && remainingMins >= 0 ? (
                    <span className="daysConvertSpan">
                      {dateStringer.convertMinsToDays(
                        toilBalance || 0,
                        dailyMins
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            )}

            {/* end of conversions container */}

            {/* <p className="userAllowCalcTitle">
            {selectedYear} annual leave settings
          </p> */}

            <div className="annLeaveToggleContainer">
              <div className="annLeaveToggleLeftTxtBox">
                <p className="annLeaveToggleTxt1">Allowance</p>
              </div>
              <div
                className={`${
                  mode === 3 ? "none" : "userAnnLeaveWeeksPerYearContainer"
                }`}
              >
                <select
                  className={`annLeavePercentageDropdownOnUser ${
                    checkUserState.permissions !== 1 && editUserState.isSelf
                      ? "greyscale"
                      : ""
                  }`}
                  value={annLeavePercentage}
                  onChange={(e) => {
                    if (
                      checkUserState.permissions !== 1 &&
                      editUserState.isSelf
                    ) {
                      setShowIsSelfNotAdminModal("allow");
                    } else {
                      setAllowLoading(true);

                      axios
                        .post(
                          `${serverURL}/update-ann-leave-percentage-on-user-year`,
                          {
                            userID: userID,
                            year: parseInt(selectedYear),
                            percentage: e.target.value,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setAllowsUpdatedLocal(true);
                            setScrollTop(!scrollTop);

                            setAnnLeavePercentage(response.data.data);
                            setAddedMins(response.data.additions);
                            setAddedMinsOnTop(response.data.additions);
                            setTimeout(() => {
                              setRecalc(recalc + 1);
                            }, 250);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  <option value="0">--</option>
                  <option value="5.051">2.5</option>
                  <option value="5.263">2.6</option>
                  <option value="5.477">2.7</option>
                  <option value="5.691">2.8</option>
                  <option value="5.906">2.9</option>
                  <option value="6.122">3</option>
                  <option value="6.339">3.1</option>
                  <option value="6.557">3.2</option>
                  <option value="6.776">3.3</option>
                  <option value="6.996">3.4</option>
                  <option value="7.216">3.5</option>
                  <option value="7.438">3.6</option>
                  <option value="7.660">3.7</option>
                  <option value="7.884">3.8</option>
                  <option value="8.108">3.9</option>
                  <option value="8.333">4</option>
                  <option value="8.559">4.1</option>
                  <option value="8.787">4.2</option>
                  <option value="9.015">4.3</option>
                  <option value="9.244">4.4</option>
                  <option value="9.474">4.5</option>
                  <option value="9.705">4.6</option>
                  <option value="9.937">4.7</option>
                  <option value="10.169">4.8</option>
                  <option value="10.403">4.9</option>
                  <option value="10.638">5</option>
                  <option value="10.874">5.1</option>
                  <option value="11.111">5.2</option>
                  <option value="11.349">5.3</option>
                  <option value="11.588">5.4</option>
                  <option value="11.828">5.5</option>
                  <option value="12.069">5.6</option>
                  <option value="12.311">5.7</option>
                  <option value="12.554">5.8</option>
                  <option value="12.798">5.9</option>
                  <option value="13.043">6</option>
                  <option value="13.290">6.1</option>
                  <option value="13.537">6.2</option>
                  <option value="13.786">6.3</option>
                  <option value="14.035">6.4</option>
                  <option value="14.286">6.5</option>
                  <option value="14.537">6.6</option>
                  <option value="14.790">6.7</option>
                  <option value="15.044">6.8</option>
                  <option value="15.299">6.9</option>
                  <option value="15.556">7</option>
                  <option value="15.813">7.1</option>
                  <option value="16.071">7.2</option>
                  <option value="16.331">7.3</option>
                  <option value="16.592">7.4</option>
                  <option value="16.854">7.5</option>
                  <option value="17.117">7.6</option>
                  <option value="17.381">7.7</option>
                  <option value="17.647">7.8</option>
                  <option value="17.914">7.9</option>
                  <option value="18.182">8</option>
                  <option value="18.451">8.1</option>
                  <option value="18.721">8.2</option>
                  <option value="18.993">8.3</option>
                  <option value="19.266">8.4</option>
                  <option value="19.540">8.5</option>
                  <option value="19.816">8.6</option>
                  <option value="20.092">8.7</option>
                  <option value="20.370">8.8</option>
                  <option value="20.650">8.9</option>
                  <option value="20.930">9</option>
                  <option value="21.212">9.1</option>
                  <option value="21.495">9.2</option>
                  <option value="21.780">9.3</option>
                  <option value="22.066">9.4</option>
                  <option value="22.353">9.5</option>
                  <option value="22.642">9.6</option>
                  <option value="22.931">9.7</option>
                  <option value="23.223">9.8</option>
                  <option value="23.515">9.9</option>
                  <option value="23.810">10</option>
                  <option value="24.105">10.1</option>
                  <option value="24.402">10.2</option>
                  <option value="24.700">10.3</option>
                  <option value="25.000">10.4</option>
                  <option value="25.301">10.5</option>
                  <option value="25.604">10.6</option>
                  <option value="25.908">10.7</option>
                  <option value="26.214">10.8</option>
                  <option value="26.521">10.9</option>
                  <option value="26.829">11</option>
                  <option value="27.139">11.1</option>
                  <option value="27.451">11.2</option>
                  <option value="27.764">11.3</option>
                  <option value="28.079">11.4</option>
                  <option value="28.395">11.5</option>
                  <option value="28.713">11.6</option>
                  <option value="29.032">11.7</option>
                  <option value="29.353">11.8</option>
                  <option value="29.676">11.9</option>
                  <option value="30">12</option>
                  <option value="30.326">12.1</option>
                  <option value="30.653">12.2</option>
                  <option value="30.982">12.3</option>
                  <option value="31.313">12.4</option>
                  <option value="31.646">12.5</option>
                  <option value="31.980">12.6</option>
                  <option value="32.316">12.7</option>
                  <option value="32.653">12.8</option>
                  <option value="32.992">12.9</option>
                  <option value="33.333">13</option>
                  <option value="33.676">13.1</option>
                  <option value="34.021">13.2</option>
                  <option value="34.367">13.3</option>{" "}
                </select>{" "}
                <span className="wksPerYrSpan">weeks per year</span>
                <span className="annLeavePercSpanSub">
                  ({annLeavePercentage}%)
                </span>
              </div>

              <div
                className={`${
                  mode === 3 ? "userAnnLeaveWeeksPerYearContainer" : "none"
                }`}
              >
                <span className="wksPerYrSpan">Manually set below</span>
              </div>
            </div>
            <div
              className={`editUserAllowsBox ${
                showMeAllowances ? "showMeRow" : ""
              }`}
              ref={showMeAllowances ? showMeAllowancesRef : null}
            >
              <p
                className="userAnnLeaveCalcToggleTitle"
                // onClick={() => {
                //   console.log("mode is: ", mode);
                // }}
              >
                Calculate {selectedYear} allowance based on:
              </p>
              {/* - - - - - - - - - - - - - - - - - - - - -  */}

              <div className="allowToggleDiv">
                <div
                  className={`salariedToggleRow salariedToggleRowFirst ${
                    mode === 1 ? "selectedSalariedToggleRow" : ""
                  } ${
                    checkUserState.permissions !== 1 && editUserState.isSelf
                      ? "greyscale"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      checkUserState.permissions !== 1 &&
                      editUserState.isSelf
                    ) {
                      setShowIsSelfNotAdminModal(true);
                    } else {
                      // fire request here
                      setAllowLoading(true);
                      setAccrualDataLoading(true);

                      axios
                        .post(
                          `${serverURL}/add-year-to-calc-ann-leave-on-contracted-hours-only`,
                          {
                            userID: userID,
                            year: selectedYear,
                            addOrRemove: contractedHoursOnly ? "remove" : "add",
                            override: false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          console.log("response is: ", response.data);

                          if (
                            response.data.message.includes("zero in this year")
                          ) {
                            setShowOverride(true);
                          } else {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              setScrollTop(!scrollTop);

                              setAllowsUpdatedLocal(true);

                              setMode(response.data.mode);
                              setTimeout(() => {
                                setRecalc(recalc + 1);
                              }, 250);

                              if (response.data.data === "add") {
                                setContractedHoursOnly(true);
                              } else {
                                setContractedHoursOnly(false);
                              }
                              setAccrualDataLoading(false);

                              setSelectedDays(
                                response.data.daysFromAnnLeaveOverrides
                              );
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  {/*  KNOB BEGIN */}
                  <div className="editDayFxSchedKnob salariedKnob1">
                    <div className="knobContainer knobContainerEditUser">
                      <div
                        className={`switchKnob ${
                          mode === 1 ? "knobOn" : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg"></div>
                    </div>
                  </div>
                  {/*  KNOB END */}
                  <p
                    className={`salariedToggleTitle workingPattInlineTxt ${
                      mode === 1 ? "salariedSel" : ""
                    }`}
                  >
                    Contracted hours per week
                  </p>
                </div>

                {mode === 1 ? (
                  <div
                    className={`annLeaveMode1Sub ${
                      checkUserState.permissions !== 1 && editUserState.isSelf
                        ? "greyscale"
                        : ""
                    }`}
                  >
                    <div
                      className={`annLeaveMode1SubRow ${
                        currContractedOrAvg === "curr"
                          ? "selectedSalariedToggleRow"
                          : ""
                      } ${!memoVals.mobModal ? "subRowDeskto" : ""}`}
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          // fire request here
                          axios
                            .post(
                              `${serverURL}/toggle-user-contracted-allowance-on-reavg`,
                              {
                                userID: userID,
                                year: selectedYear,
                                data:
                                  currContractedOrAvg === "curr" ? false : true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                setAllowsUpdatedLocal(true);
                                setScrollTop(!scrollTop);

                                if (response.data.data === false) {
                                  setCurrContractedOrAvg("avg");
                                  setUpdateData(!updateData);
                                } else {
                                  setCurrContractedOrAvg("curr");
                                  setUpdateData(!updateData);
                                }
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      {" "}
                      {/*  KNOB BEGIN */}
                      <div className="editDayFxSchedKnob salariedKnob">
                        <div className="knobContainer knobContainerEditUser">
                          <div
                            className={`switchKnob subKnob ${
                              currContractedOrAvg === "curr"
                                ? "knobOn subKnobOn"
                                : "knobOff subKnobOff"
                            }`}
                          ></div>
                          <div className="knobBg knobBgSub"></div>
                        </div>
                      </div>
                      {/*  KNOB END */}
                      <div className="annLeaveMode1SubWrap">
                        Current:{" "}
                        <p
                          className={`annLeaveMode1subHpw ${
                            currContractedOrAvg === "curr" ? "" : "fadeAllowHpw"
                          }`}
                        >
                          {" "}
                          {dateStringer.formatMinsDurationToHours(currMpw)} /
                          week
                        </p>
                      </div>
                    </div>
                    <div
                      className={`annLeaveMode1SubRow annLeaveMode1SubRow2 ${
                        currContractedOrAvg !== "curr"
                          ? "selectedSalariedToggleRow x10109192221"
                          : ""
                      } ${!memoVals.mobModal ? "subRowDeskto" : ""}`}
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          // fire request here
                          // fire request here
                          axios
                            .post(
                              `${serverURL}/toggle-user-contracted-allowance-on-reavg`,
                              {
                                userID: userID,
                                year: selectedYear,
                                data:
                                  currContractedOrAvg === "curr" ? false : true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                setAllowsUpdatedLocal(true);
                                setScrollTop(!scrollTop);

                                if (response.data.data === false) {
                                  setCurrContractedOrAvg("avg");
                                  setUpdateData(!updateData);
                                } else {
                                  setCurrContractedOrAvg("curr");
                                  setUpdateData(!updateData);
                                }
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      {/*  KNOB BEGIN */}
                      <div className="editDayFxSchedKnob salariedKnob">
                        <div className="knobContainer knobContainerEditUser">
                          <div
                            className={`switchKnob subKnob ${
                              currContractedOrAvg !== "curr"
                                ? "knobOn subKnobOn"
                                : "knobOff subKnobOff"
                            }`}
                          ></div>
                          <div className="knobBg knobBgSub"></div>
                        </div>
                      </div>
                      {/*  KNOB END */}
                      <div className="annLeaveMode1SubWrap">
                        {selectedYear} average:{" "}
                        <p
                          className={`annLeaveMode1subHpw ${
                            currContractedOrAvg !== "curr" ? "" : "fadeAllowHpw"
                          }`}
                        >
                          {dateStringer.formatMinsDurationToHours(avgMpw)} /
                          week
                        </p>
                      </div>
                    </div>
                    <div
                      className="reAvgTimelineBtn"
                      onClick={() => {
                        setShowMpwTimeline(true);
                      }}
                    >
                      History
                      <img
                        src={popup}
                        alt="Contract hours timeline"
                        className="contrhourstimelinepopup"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div
                  className={`salariedToggleRow ${
                    mode === 2 ? "selectedSalariedToggleRow" : ""
                  } ${
                    checkUserState.permissions !== 1 && editUserState.isSelf
                      ? "greyscale"
                      : ""
                  }`}
                  onClick={() => {
                    if (mode !== 2) {
                      // fire request here
                      setAllowLoading(true);
                      setAccrualDataLoading(true);

                      axios
                        .post(
                          `${serverURL}/add-year-to-calc-ann-leave-on-contracted-hours-only`,
                          {
                            userID: userID,
                            year: selectedYear,
                            addOrRemove: contractedHoursOnly
                              ? "remove"
                              : "remove",
                            override: false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          console.log("response is: ", response.data);

                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setAllowsUpdatedLocal(true);
                            setScrollTop(!scrollTop);
                            setAccrualDataLoading(false);
                            setMode(response.data.mode);
                            setTimeout(() => {
                              setRecalc(recalc + 1);
                            }, 1);

                            if (response.data.data === "add") {
                              setContractedHoursOnly(true);
                            } else {
                              setContractedHoursOnly(false);
                            }
                            setSelectedDays(
                              response.data.daysFromAnnLeaveOverrides
                            );
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  {/*  KNOB BEGIN */}
                  <div className="editDayFxSchedKnob salariedKnob">
                    <div className="knobContainer knobContainerEditUser">
                      <div
                        className={`switchKnob ${
                          mode === 2 ? "knobOn" : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg"></div>
                    </div>
                  </div>
                  {/*  KNOB END */}
                  <p
                    className={`salariedToggleTitle workingPattInlineTxt ${
                      mode === 2 ? "salariedSel" : ""
                    }`}
                  >
                    Payable hours in {selectedYear}
                  </p>
                </div>
                <div
                  className={`salariedToggleRow ${
                    mode === 3 ? "selectedSalariedToggleRow" : ""
                  } ${
                    checkUserState.permissions !== 1 && editUserState.isSelf
                      ? "greyscale"
                      : ""
                  }`}
                  onClick={() => {
                    // fire request here
                    setAllowLoading(true);
                    setAccrualDataLoading(true);

                    axios
                      .post(
                        `${serverURL}/update-ann-leave-override-on-user-year`,
                        {
                          userID: userID,
                          year: parseInt(selectedYear),
                          days: selectedDays > 0 ? 0 : 1,
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          // setScrollTop(!scrollTop);
                          if (addedMinsOnTop !== 0) {
                            setAddedMinsOnTop(0);
                          }
                          if (addedMins !== 0) {
                            setAddedMins(0);
                          }
                          setAllowsUpdatedLocal(true);

                          setMode(response.data.mode);
                          setAccrualDataLoading(false);

                          console.log("success!");
                          setSelectedDays(response.data.data);
                          // setTimeout(() => {
                          setRecalc(recalc + 1);
                          // }, 250);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  {/*  KNOB BEGIN */}
                  <div className="editDayFxSchedKnob salariedKnob">
                    <div className="knobContainer knobContainerEditUser">
                      <div
                        className={`switchKnob ${
                          mode === 3 ? "knobOn" : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg"></div>
                    </div>
                  </div>
                  {/*  KNOB END */}
                  <p
                    className={`salariedToggleTitle workingPattInlineTxt ${
                      mode === 3 ? "salariedSel" : ""
                    }`}
                  >
                    Manually set allowance
                  </p>
                </div>
              </div>
              {mode === 2 ? (
                <p
                  className={`allHoursRecordTxt ${
                    checkUserState.permissions !== 1 && editUserState.isSelf
                      ? "greyscale"
                      : ""
                  }`}
                >
                  {dateStringer.possession(fName)} annual leave allowance is{" "}
                  {annLeavePercentage}% of their payable hours in {selectedYear}
                  . Payable hours include any paid shifts, overtime, paid leave
                  and paid absence - shown in the accrual data table below.
                  {/* equal
              to the allowance percentage (based on {annLeavePercentage}%) is
              based on the allowance p dynamically calculated by counting their
              payable hours in {selectedYear} and applying the annual leave
              percentage - using shifts, overtime, paid leave and paid absence. */}
                </p>
              ) : (
                ""
              )}

              {/* - - - - - - - - - - - -  */}

              <div
                className={`${
                  joinDate && mode === 3 ? "joinDateMidTermDiv" : "none"
                } ${
                  checkUserState.permissions !== 1 && editUserState.isSelf
                    ? "greyscale"
                    : ""
                }`}
              >
                <img
                  src={exclaimGrey}
                  className="exclaimJoinDateImg"
                  alt="Exclaim"
                />
                <p className="joinDateMidTermInfoTxt">
                  {fName} joined {companyName || "the organisation"} on{" "}
                  <span className="allowjoinDateSpan">
                    {dateStringer.printedDateFromDs(joinDate)}
                  </span>{" "}
                  which is{" "}
                  <span className="allowjoinDateSpan">
                    {joinPercentIntoYear}%
                  </span>{" "}
                  into the company year of {selectedYear}.<br />
                  <br />
                  Therefore, {fName}'s eligible working period in {selectedYear}{" "}
                  is{" "}
                  <span className="allowjoinDateSpan">
                    {100 - joinPercentIntoYear}%
                  </span>
                  .
                </p>
              </div>
              {mode === 3 ? (
                <div
                  className={`manuallySeAllowContainer ${
                    checkUserState.permissions !== 1 && editUserState.isSelf
                      ? "greyscale"
                      : ""
                  }`}
                >
                  <p>&nbsp;</p>
                  <div className="annLeaveDropdownAndExplainInfoContainer">
                    <select
                      className={`${
                        mode === 3
                          ? "selectedMinsAllowanceDropdown"
                          : "selectedMinsAllowanceDropdown invis"
                      }`}
                      value={selectedDays}
                      onChange={(e) => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          setSelectedDays(e.target.value);
                          setAllowLoading(true);
                          setAccrualDataLoading(true);

                          // fire request here
                          axios
                            .post(
                              `${serverURL}/update-ann-leave-override-on-user-year`,
                              {
                                userID: userID,
                                year: parseInt(selectedYear),
                                days: e.target.value,
                              },
                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              console.log("response is: ", response.data);

                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                setAllowsUpdatedLocal(true);
                                if (addedMinsOnTop !== 0) {
                                  setAddedMinsOnTop(0);
                                }
                                if (addedMins !== 0) {
                                  setAddedMins(0);
                                }
                                // setScrollTop(!scrollTop);

                                setMode(response.data.mode);
                                setAccrualDataLoading(false);

                                console.log("success!");
                                setSelectedDays(response.data.data);
                                setTimeout(() => {
                                  setRecalc(recalc + 1);
                                }, 250);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      {generateManualAllowances}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* end of third */}
            </div>

            {/*  ann leave meta dropper */}
            {mode === 2 ? (
              <div
                className={`annLeaveMetaContainer ${
                  showMeta ? "metaExtended" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMeta(!showMeta);
                }}
              >
                {/* hello */}
                <div className="showMetaToggle">
                  <img
                    src={allowDropNavy2}
                    alt="Show meta info"
                    className={`showAccrualDataArrowImg ${
                      showMeta ? "dataArrowShowMetaActive" : ""
                    }`}
                  />
                  <p className="accruInfoTxt x2982911112f">
                    {selectedYear} accrual data
                  </p>
                </div>
                <div
                  className={`${
                    showMeta ? "allowanceInfoIfInitialYearContainer" : "none"
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div
                    className={`${
                      userCreatedThisYear
                        ? "allowanceRowAccrue noBorder "
                        : "none"
                    }`}
                  >
                    <p className="allowanceInfoKey">
                      {fName} added to FlowRota{" "}
                    </p>
                    <div className="allowanceInfoValue">
                      <p></p>
                      {created
                        ? dateStringer.printedDateWithYrFromDs(created)
                        : ""}
                    </div>
                  </div>
                  <div
                    className={`${
                      userCreatedThisYear ? "allowanceInfoRowDivider" : "none"
                    }`}
                  ></div>
                  <div
                    className={`${
                      userCreatedThisYear ? "allowanceRowAccrue" : "none"
                    }`}
                  >
                    <p className="allowanceInfoKey">
                      {/* hello */}
                      {fName} joined {companyName}{" "}
                    </p>
                    <div className="allowanceInfoValue">
                      <p></p>
                      {dateStringer.printedDateWithYrFromDs(joinDate)}
                    </div>
                  </div>
                  <div
                    className={`${
                      userCreatedThisYear ? "allowanceInfoRowDivider" : "none"
                    }`}
                  ></div>
                  <div
                    className={`${
                      joinPercentIntoYear > 0 ? "allowanceRowAccrue" : "none"
                    }`}
                  >
                    <p className="allowanceInfoKey">
                      {/* hello */}
                      Eligible percentage of the year worked
                    </p>
                    <div className="allowanceInfoValue">
                      <p></p>
                      {100 - joinPercentIntoYear}%
                    </div>
                  </div>
                  <div
                    className={`${
                      userCreatedThisYear ? "allowanceInfoRowDivider" : "none"
                    }`}
                  ></div>
                  <div
                    className={`${
                      userCreatedThisYear ? "allowanceRowAccrue" : "none"
                    }`}
                  >
                    <p className="allowanceInfoKey">
                      {/* hello */}
                      Acccruable hours before {fName} was added to FlowRota in{" "}
                      {selectedYear}
                    </p>
                    <div className="allowanceInfoValue">
                      <p></p>
                      {dateStringer.formatMinsDurationToHours(
                        historyTotalMins
                      )}{" "}
                    </div>
                  </div>
                  <div
                    className={`${
                      userCreatedThisYear ? "allowanceInfoRowDivider" : "none"
                    }`}
                  ></div>

                  <div
                    className={`${mode === 1 ? "none" : "allowanceRowAccrue"}`}
                  >
                    <p className="allowanceInfoKey">
                      {/* hello */}
                      Accruable shifts <br />
                      <span
                        className={`${
                          userCreatedThisYear ? "allowInfoBrackets" : "none"
                        }`}
                      >
                        (recorded by FlowRota since{" "}
                        {dateStringer.printedDateWithYrFromDs(created)})
                      </span>
                    </p>
                    <p
                      className={`${
                        accrualDataLoading ? "none" : "allowanceInfoValue"
                      }`}
                    >
                      {" "}
                      <p></p>
                      {dateStringer.formatMinsDurationToHours(shiftMins)}
                    </p>
                    <img
                      src={blueLoader}
                      className={`${
                        accrualDataLoading ? "allowanceLoadingImg" : "none"
                      }`}
                      alt="loading"
                    />
                  </div>
                  <div className="allowanceInfoRowDivider"></div>

                  <div
                    className={`${mode === 1 ? "none" : "allowanceRowAccrue"}`}
                  >
                    <p className="allowanceInfoKey">
                      {/* hello */}
                      Accruable overtime <br />
                      <span
                        className={`${
                          userCreatedThisYear ? "allowInfoBrackets" : "none"
                        }`}
                      ></span>
                    </p>
                    <p
                      className={`${
                        accrualDataLoading ? "none" : "allowanceInfoValue"
                      }`}
                    >
                      {" "}
                      <p></p>
                      {dateStringer.formatMinsDurationToHours(overtimMins)}
                    </p>
                    <img
                      src={blueLoader}
                      className={`${
                        accrualDataLoading ? "allowanceLoadingImg" : "none"
                      }`}
                      alt="loading"
                    />
                  </div>
                  <div className="allowanceInfoRowDivider"></div>

                  <div className="allowanceRowAccrue">
                    <p className="allowanceInfoKey">
                      {/* hello */}
                      Accruable leave & absence
                      <br />
                      <span
                        className={`${
                          userCreatedThisYear ? "allowInfoBrackets" : "none"
                        }`}
                      ></span>
                    </p>
                    <div className="allowanceInfoValue">
                      {" "}
                      <p></p>
                      {dateStringer.formatMinsDurationToHours(reqMins)}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="editUserTeamRow contractedHPDrow">
              <div className="editUserRowTitleAndInput">
                <div className="editUserEditTitle">
                  Contracted hours per day
                  <br />
                  <p className="varDiffSub">(average)</p>
                </div>
              </div>
              <div
                className={`allowsDailyMinsVal ${
                  memoVals.mobModal ? "allowsDailyMinsValMobHPD" : ""
                }`}
              >
                {dateStringer.formatMinsDurationToHours(dailyMins)}
              </div>
            </div>
            <div className="editUserTeamRow contractedHPDrow">
              <div className="editUserRowTitleAndInput">
                <div className="editUserEditTitle">
                  Actual hours worked
                  <br />
                  <p className="varDiffSub">
                    The number of hours {fName} has actually worked, avergaged
                    out per workable day.
                  </p>
                </div>
              </div>
              <div
                className={`allowsDailyMinsVal ${
                  memoVals.mobModal ? "allowsDailyMinsValMobHPD" : ""
                }`}
              >
                {dateStringer.formatMinsDurationToHours(avgMpd)}
              </div>
            </div>
            <div className="editUserTeamRow contractedHPDrow">
              <div className="editUserRowTitleAndInput">
                <div className="editUserEditTitle">
                  Hours variance
                  <br />
                  <p className="varDiffSub">
                    This is the difference between the number of{" "}
                    {dateStringer.possession(fName)} contracted hours the actual
                    hours they worked.
                  </p>
                </div>
              </div>
              <div
                className={`allowsDailyMinsVal ${
                  memoVals.mobModal ? "allowsDailyMinsValMobHPD" : ""
                }`}
              >
                {avgMpd - dailyMins < 0
                  ? `${Math.round(avgMpd - dailyMins)}m`
                  : `+${dateStringer.formatMinsDurationToHours(
                      avgMpd - dailyMins,
                      false,
                      true
                    )}`}
              </div>
            </div>
            {historyMins && (
              <div className="editUserTeamRow contractedHPDrow">
                <div className="editUserRowTitleAndInput">
                  <p className="editUserEditTitle">
                    Annual leave taken
                    <br />
                    before joining FlowRota
                  </p>
                </div>
                <div className="allowsDailyMinsVal userAllowHistoryDiv">
                  {dateStringer.formatMinsDurationToHours(historyMins)}
                  <img
                    src={editUserEdit1}
                    alt="Edit"
                    className="editUserEditImg"
                    onClick={() => {
                      setEditHistory(true);
                    }}
                  />
                </div>
              </div>
            )}
            <div
              className={`editUserTeamRow hoverRowColour_ excessAllowRow ${
                checkUserState.permissions !== 1 && editUserState.isSelf
                  ? "greyscale"
                  : ""
              }`}
            >
              <div className="editUserRowTitleAndInput">
                <p className="editUserEditTitle">Pay accrued overtime leave</p>
                {showUpdateReqsBtn ? (
                  <p
                    className="updateOtReqsBtn x9191k"
                    onClick={() => {
                      if (
                        checkUserState.permissions !== 1 &&
                        editUserState.isSelf
                      ) {
                        setShowIsSelfNotAdminModal(true);
                      } else {
                        axios
                          .post(
                            `${serverURL}/update-client-requests-with-excess`,
                            {
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                              userID,
                            },
                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              if (response.data.reqs && response.data.reqs[0]) {
                                // memoVals.setShowUpdatedReqsModal(
                                //   (x) => response.data.reqs
                                // );
                              }
                              memoVals.setIndicate((x) => {
                                return {
                                  show: true,
                                  message: "Requests updated",
                                  colour: "blue",
                                  duration: 4000,
                                };
                              });
                              setShowUpdateReqsBtn(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }}
                  >
                    Update requests from today{" "}
                    <img src={update} className="updatereqsImg" alt="Update" />
                  </p>
                ) : (
                  ""
                )}
                <p className="editUserOtPayReqsInfoTxt">
                  If enabled, FlowRota will calculate the cost of{" "}
                  {dateStringer.possession(fName)} paid leave periods and
                  absences based on their average hours worked across the
                  reference period ({refPeriod} week{refPeriod === 1 ? "" : "s"}
                  ), taking into account any overtime hours worked - not just
                  their contracted hours.
                </p>
                {/* <br /> */}
                <p className="editUserPTeamExplain x19834981">
                  {dateStringer.possession(fName)} current average hours per
                  week worked (calculated across the reference period) is{" "}
                  {dateStringer.formatMinsDurationToHours(
                    minsPerWeekIncludingExcess
                  )}
                  .
                  <br />
                  <br />
                  They are contracted to work{" "}
                  {dateStringer.formatMinsDurationToHours(contractedMpw)} per
                  week. If this setting is enabled, a week of paid leave for{" "}
                  {dateStringer.possession(fName)} would be worth{" "}
                  {dateStringer.formatMinsDurationToHours(
                    minsPerWeekIncludingExcess < contractedMpw
                      ? contractedMpw
                      : minsPerWeekIncludingExcess
                  )}
                  .
                </p>
              </div>
              <div className="editUserValDiv">
                {/*  KNOB BEGIN */}
                <div
                  className="editUserReqClockOutKnobContainer clockInKnob selfApproveKnob"
                  onClick={() => {
                    if (
                      checkUserState.permissions !== 1 &&
                      editUserState.isSelf
                    ) {
                      setShowIsSelfNotAdminModal(true);
                    } else {
                      axios
                        .post(
                          `${serverURL}/toggle-user-excess-paid-on-requests`,
                          {
                            userID: userID,
                            data: !payExcessOnReqsUser,
                          },
                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setPayExcessOnReqsUser(
                              response.data.overtimePaidOnReqs
                            );
                            setShowUpdateReqsBtn(true);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  <div className="knobContainer knobContainerEditUser">
                    <div
                      className={`switchKnob ${
                        payExcessOnReqsUser ? "knobOn" : "knobOff"
                      }`}
                    ></div>
                    <div className="knobBg"></div>
                  </div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
        {annLeaveOrCustom === "custom" ? (
          <div
            className={`editUserCustomScrollBox ${
              checkUserState.permissions !== 1 && editUserState.isSelf
                ? "greyscale"
                : ""
            }`}
          >
            {/* Cusom leaves go here */}
            <div
              className="settingsRow tilSettingsRow allowancesTilUserRow"
              onClick={(e) => {
                if (checkUserState.permissions !== 1 && editUserState.isSelf) {
                  setShowIsSelfNotAdminModal(true);
                } else {
                  setUserEnableTil(!userEnableTil);
                  axios
                    .post(
                      `${serverURL}/toggle-user-til-blocked`,
                      { data: !userEnableTil, userID },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        // code here
                        setUserEnableTil(!response.data.blockTil);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              {/* Cusom leaves go here */}
              <div className="rowWithSubText">
                {/* <div className= */}
                <p className="absenceRowMainText">Time off in lieu (TOIL)</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Allow {fName} to undertake unpaid work with the intention to
                  claim this time as time off instead
                  {!clientEnableTil ? "." : ""}
                  {!clientEnableTil ? <br /> : ""}{" "}
                  {!clientEnableTil ? <br /> : ""}
                  {!clientEnableTil
                    ? memoVals.checkUserState.permissions === 1
                      ? "Currently disabled. You must enable TOIL in the 'Leave' settings within the Admin panel of FlowRota first."
                      : "Your admin has disabled TOIL for all employees."
                    : ""}
                </p>
                <p className="editUserAllowOtherToilBal">
                  Balance:{" "}
                  <span
                    className={toilBalance < 0 ? "toilSpanRed" : "toilSpanBlue"}
                  >
                    {dateStringer.formatMinsDurationToHours(
                      !userEnableTil ? 0 : toilBalance
                    )}
                  </span>
                </p>
              </div>
              {/*  KNOB BEGIN */}
              <div
                className={`knobContainer knobContainerSettings marginLeft0   ${
                  clientEnableTil ? "" : "disable opac4"
                }`}
              >
                <div
                  className={`switchKnob ${
                    userEnableTil ? "knobOn" : "knobOff"
                  }`}
                ></div>

                <div className="knobBg"></div>
              </div>
              {/*  KNOB END */}
            </div>

            {customDataArr.map((custom) => {
              return (
                <div
                  className={`customArrItem ${
                    checkUserState.permissions !== 1 && editUserState.isSelf
                      ? "greyscale"
                      : ""
                  }`}
                >
                  <div className="customArrTitleRow">
                    <p className="customItemArr">{custom.name}</p>
                    <div className="allowanceInfoIfInitialYearContainer">
                      <div className="allowanceRow noBorder">
                        <p className="allowanceInfoKey">
                          {/* hello */}
                          Remaining
                        </p>
                        <img
                          src={blueLoader}
                          className={`${
                            loadingCustomLeaveTypeID === custom.leaveTypeID
                              ? "allowanceLoadingImg"
                              : "none"
                          }`}
                          alt="loading"
                        />
                        <p
                          className={`${
                            loadingCustomLeaveTypeID === custom.leaveTypeID
                              ? "none"
                              : "allowanceInfoValue"
                          }`}
                        >
                          {" "}
                          <p></p>
                          <span className={`daysConvertSpan noBorder`}>
                            {custom.unlimited ||
                            (custom.daysOrHours === "days" &&
                              custom.totAllow === 366) ||
                            (custom.daysOrHours === "hours" &&
                              custom.totAllow === 527040)
                              ? "Unlimited"
                              : custom.daysOrHours === "hours"
                              ? dateStringer.formatMinsDurationToHours(
                                  custom.remaining
                                )
                              : `${custom.remaining} days`}
                          </span>
                        </p>
                      </div>
                      <div className="allowanceInfoRowDivider"></div>

                      <div className="allowanceRow">
                        <p className="allowanceInfoKey">
                          {/* hello */}
                          Used
                        </p>
                        <img
                          src={blueLoader}
                          className={`${
                            loadingCustomLeaveTypeID === custom.leaveTypeID
                              ? "allowanceLoadingImg"
                              : "none"
                          }`}
                          alt="loading"
                        />
                        <p
                          className={`${
                            loadingCustomLeaveTypeID === custom.leaveTypeID
                              ? "none"
                              : "allowanceInfoValue"
                          }`}
                        >
                          {" "}
                          <p></p>
                          <span className={`daysConvertSpan noBorder`}>
                            {custom.daysOrHours === "hours"
                              ? dateStringer.formatMinsDurationToHours(
                                  custom.used
                                )
                              : `${custom.used} days`}
                          </span>
                        </p>
                      </div>
                      <div className="allowanceInfoRowDivider extraThickDivider"></div>

                      <div
                        className={`allowanceRow highlightedAllowanceRow xc8383 ${
                          custom.daysOrHours === "hours" && !custom.unlimited
                            ? "noBorderBottom"
                            : ""
                        }`}
                      >
                        <p className="allowanceInfoKey">Allowance</p>
                        <img
                          src={blueLoader}
                          className={`${
                            loadingCustomLeaveTypeID === custom.leaveTypeID
                              ? "allowanceLoadingImg"
                              : "none"
                          }`}
                          alt="loading"
                        />
                        <div
                          className={`${
                            loadingCustomLeaveTypeID === custom.leaveTypeID
                              ? "none"
                              : "allowanceInfoValue"
                          }`}
                        >
                          {" "}
                          <span></span>
                          <p
                            className={`daysConvertSpan totalAllowSpanHighlighted noBorder`}
                          >
                            {custom.daysOrHours === "hours" ||
                            custom.unlimited ? (
                              ""
                            ) : (
                              <img
                                src={customMinus}
                                alt="Add leave"
                                className={`customMinus ${
                                  custom.totAllow === 0 ? "disable" : ""
                                }`}
                                onClick={() => {
                                  if (
                                    checkUserState.permissions !== 1 &&
                                    editUserState.isSelf
                                  ) {
                                    setShowIsSelfNotAdminModal(true);
                                  } else {
                                    if (custom.totAllow - 1 < 0) {
                                    } else {
                                      axios
                                        .post(
                                          `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                                          {
                                            userID: userID,
                                            leaveTypeID: custom.leaveTypeID,
                                            year: parseInt(selectedYear),
                                            mins: custom.totAllow - 1,
                                            settingAsZero:
                                              custom.totAllow - 1 === 0
                                                ? true
                                                : false,
                                          },

                                          {
                                            withCredentials: true,
                                            credentials: "include",
                                          }
                                        )
                                        .then((response) => {
                                          if (
                                            response.data.message === "success"
                                          ) {
                                            setAllowancesChanged(true);
                                            if (
                                              !checkListDone.includes(
                                                "allowances"
                                              )
                                            ) {
                                              setCheckListDone([
                                                ...checkListDone,
                                                "allowances",
                                              ]);
                                            }
                                            let newArr = [];
                                            customDataArr.forEach((x) => {
                                              if (
                                                x.year === custom.year &&
                                                x.leaveTypeID ===
                                                  custom.leaveTypeID
                                              ) {
                                                newArr.push({
                                                  name: x.name,
                                                  leaveTypeID: x.leaveTypeID,
                                                  daysOrHours: x.daysOrHours,
                                                  totalAllowMinsBeforeExcessApplied:
                                                    x.totalAllowMinsBeforeExcessApplied,
                                                  totAllow: custom.totAllow - 1,
                                                  remaining:
                                                    custom.remaining - 1,
                                                  used: x.used,
                                                  overridden: x.overridden,
                                                  year: x.year,
                                                  excessMinsExtendsAllowance:
                                                    x.excessMinsExtendAllowance,
                                                });
                                              } else {
                                                newArr.push(x);
                                              }
                                            });

                                            setCustomDataArr(newArr);
                                          }
                                        })
                                        .catch((err) => {
                                          console.error(err);
                                        });
                                    }
                                  }
                                }}
                              />
                            )}
                            {custom.unlimited ||
                            (custom.daysOrHours === "days" &&
                              custom.totAllow === 366) ||
                            (custom.daysOrHours === "hours" &&
                              custom.totAllow === 527040)
                              ? "Unlimited"
                              : custom.daysOrHours === "hours"
                              ? dateStringer.formatMinsDurationToHours(
                                  Math.round(custom.totAllow)
                                )
                              : `${custom.totAllow} days`}
                            {custom.daysOrHours === "hours" ||
                            custom.unlimited ? (
                              ""
                            ) : (
                              <img
                                src={customPlus}
                                alt="Add leave"
                                className={`customPlus ${
                                  (custom.daysOrHours === "days" &&
                                    custom.totAllow === 366) ||
                                  (custom.daysOrHours === "hours" &&
                                    custom.totAllow === 527040)
                                    ? "disable"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (
                                    checkUserState.permissions !== 1 &&
                                    editUserState.isSelf
                                  ) {
                                    setShowIsSelfNotAdminModal(true);
                                  } else {
                                    axios
                                      .post(
                                        `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                                        {
                                          userID: userID,
                                          leaveTypeID: custom.leaveTypeID,
                                          year: parseInt(selectedYear),
                                          mins: custom.totAllow + 1,
                                          settingAsZero: false,
                                        },

                                        {
                                          withCredentials: true,
                                          credentials: "include",
                                        }
                                      )
                                      .then((response) => {
                                        if (
                                          response.data.message === "success"
                                        ) {
                                          if (
                                            !checkListDone.includes(
                                              "allowances"
                                            )
                                          ) {
                                            setCheckListDone([
                                              ...checkListDone,
                                              "allowances",
                                            ]);
                                          }
                                          let newArr = [];
                                          setAllowancesChanged(true);

                                          customDataArr.forEach((x) => {
                                            if (
                                              x.year === custom.year &&
                                              x.leaveTypeID ===
                                                custom.leaveTypeID
                                            ) {
                                              newArr.push({
                                                name: x.name,
                                                leaveTypeID: x.leaveTypeID,
                                                daysOrHours: x.daysOrHours,
                                                totalAllowMinsBeforeExcessApplied:
                                                  x.totalAllowMinsBeforeExcessApplied,
                                                totAllow: custom.totAllow + 1,
                                                remaining: custom.remaining + 1,
                                                used: x.used,
                                                overridden: x.overridden,
                                                year: x.year,
                                                excessMinsExtendsAllowance:
                                                  x.excessMinsExtendAllowance,
                                              });
                                            } else {
                                              newArr.push(x);
                                            }
                                          });

                                          setCustomDataArr(newArr);
                                        }
                                      })
                                      .catch((err) => {
                                        console.error(err);
                                      });
                                  }
                                }}
                              />
                            )}
                          </p>
                        </div>
                      </div>
                      {custom.daysOrHours === "hours" && !custom.unlimited ? (
                        <div className="amendCustomHoursRow">
                          <p
                            className={`customPlusHr customPlusHr2 customMinusColour ${
                              custom.totAllow - 60 < 0 ? "disable" : ""
                            }`}
                            onClick={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              } else {
                                if (custom.totAllow - 60 < 0) {
                                } else {
                                  axios
                                    .post(
                                      `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                                      {
                                        userID: userID,
                                        leaveTypeID: custom.leaveTypeID,
                                        year: parseInt(selectedYear),
                                        mins: custom.totAllow - 1,
                                        settingAsZero:
                                          custom.totAllow - 60 === 0
                                            ? true
                                            : false,
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (
                                        response.data.message.includes("logout")
                                      ) {
                                        window.location.href = "/signin";
                                      }
                                      if (response.data.message === "success") {
                                        if (
                                          !checkListDone.includes("allowances")
                                        ) {
                                          setCheckListDone([
                                            ...checkListDone,
                                            "allowances",
                                          ]);
                                        }
                                        setAllowancesChanged(true);

                                        let newArr = [];
                                        customDataArr.forEach((x) => {
                                          if (
                                            x.year === custom.year &&
                                            x.leaveTypeID === custom.leaveTypeID
                                          ) {
                                            newArr.push({
                                              name: x.name,
                                              leaveTypeID: x.leaveTypeID,
                                              daysOrHours: x.daysOrHours,
                                              totalAllowMinsBeforeExcessApplied:
                                                x.totalAllowMinsBeforeExcessApplied,
                                              totAllow: custom.totAllow - 60,
                                              remaining: custom.remaining - 60,
                                              used: x.used,
                                              overridden: x.overridden,
                                              year: x.year,
                                              excessMinsExtendsAllowance:
                                                x.excessMinsExtendAllowance,
                                            });
                                          } else {
                                            newArr.push(x);
                                          }
                                        });

                                        setCustomDataArr(newArr);
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                }
                              }
                            }}
                          >
                            -1h
                          </p>
                          <p
                            className={`customPlusHr customMinusColour customMinusHr2 ${
                              custom.totAllow - 1 < 0 ? "disable" : ""
                            }`}
                            onClick={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              } else {
                                if (custom.totAllow - 1 < 0) {
                                } else {
                                  axios
                                    .post(
                                      `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                                      {
                                        userID: userID,
                                        leaveTypeID: custom.leaveTypeID,
                                        year: parseInt(selectedYear),
                                        mins: custom.totAllow - 1,
                                        settingAsZero:
                                          custom.totAllow - 1 === 0
                                            ? true
                                            : false,
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (
                                        response.data.message.includes("logout")
                                      ) {
                                        window.location.href = "/signin";
                                      }
                                      if (response.data.message === "success") {
                                        if (
                                          !checkListDone.includes("allowances")
                                        ) {
                                          setCheckListDone([
                                            ...checkListDone,
                                            "allowances",
                                          ]);
                                        }
                                        setAllowancesChanged(true);

                                        let newArr = [];
                                        customDataArr.forEach((x) => {
                                          if (
                                            x.year === custom.year &&
                                            x.leaveTypeID === custom.leaveTypeID
                                          ) {
                                            newArr.push({
                                              name: x.name,
                                              leaveTypeID: x.leaveTypeID,
                                              daysOrHours: x.daysOrHours,
                                              totalAllowMinsBeforeExcessApplied:
                                                x.totalAllowMinsBeforeExcessApplied,
                                              totAllow: custom.totAllow - 1,
                                              remaining: custom.remaining - 1,
                                              used: x.used,
                                              overridden: x.overridden,
                                              year: x.year,
                                              excessMinsExtendsAllowance:
                                                x.excessMinsExtendAllowance,
                                            });
                                          } else {
                                            newArr.push(x);
                                          }
                                        });

                                        setCustomDataArr(newArr);
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                }
                              }
                            }}
                          >
                            -1m
                          </p>{" "}
                          <p
                            className={`customPlusHr  ${
                              custom.daysOrHours === "hours" &&
                              custom.totAllow === 527040
                                ? "disable"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              } else {
                                axios
                                  .post(
                                    `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                                    {
                                      userID: userID,
                                      leaveTypeID: custom.leaveTypeID,
                                      year: parseInt(selectedYear),
                                      mins: custom.totAllow + 1,
                                      settingAsZero: false,
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (
                                      response.data.message.includes("logout")
                                    ) {
                                      window.location.href = "/signin";
                                    }
                                    if (response.data.message === "success") {
                                      if (
                                        !checkListDone.includes("allowances")
                                      ) {
                                        setCheckListDone([
                                          ...checkListDone,
                                          "allowances",
                                        ]);
                                      }
                                      let newArr = [];
                                      setAllowancesChanged(true);

                                      customDataArr.forEach((x) => {
                                        if (
                                          x.year === custom.year &&
                                          x.leaveTypeID === custom.leaveTypeID
                                        ) {
                                          newArr.push({
                                            name: x.name,
                                            leaveTypeID: x.leaveTypeID,
                                            daysOrHours: x.daysOrHours,
                                            totalAllowMinsBeforeExcessApplied:
                                              x.totalAllowMinsBeforeExcessApplied,
                                            totAllow: custom.totAllow + 1,
                                            remaining: custom.remaining + 1,
                                            used: x.used,
                                            overridden: x.overridden,
                                            year: x.year,
                                            excessMinsExtendsAllowance:
                                              x.excessMinsExtendAllowance,
                                          });
                                        } else {
                                          newArr.push(x);
                                        }
                                      });

                                      setCustomDataArr(newArr);
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });
                              }
                            }}
                          >
                            +1m
                          </p>
                          <p
                            className={`customPlusHr customPlusHr2 ${
                              custom.daysOrHours === "hours" &&
                              custom.totAllow === 527040
                                ? "disable"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              } else {
                                axios
                                  .post(
                                    `${serverURL}/add-or-subtract-ann-leave-on-user-year`,
                                    {
                                      userID: userID,
                                      leaveTypeID: custom.leaveTypeID,
                                      year: parseInt(selectedYear),
                                      mins: custom.totAllow + 60,
                                      settingAsZero: false,
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (
                                      response.data.message.includes("logout")
                                    ) {
                                      window.location.href = "/signin";
                                    }
                                    if (response.data.message === "success") {
                                      if (
                                        !checkListDone.includes("allowances")
                                      ) {
                                        setCheckListDone([
                                          ...checkListDone,
                                          "allowances",
                                        ]);
                                      }
                                      let newArr = [];
                                      setAllowancesChanged(true);

                                      customDataArr.forEach((x) => {
                                        if (
                                          x.year === custom.year &&
                                          x.leaveTypeID === custom.leaveTypeID
                                        ) {
                                          newArr.push({
                                            name: x.name,
                                            leaveTypeID: x.leaveTypeID,
                                            daysOrHours: x.daysOrHours,
                                            totalAllowMinsBeforeExcessApplied:
                                              x.totalAllowMinsBeforeExcessApplied,
                                            totAllow: custom.totAllow + 60,
                                            remaining: custom.remaining + 60,
                                            used: x.used,
                                            overridden: x.overridden,
                                            year: x.year,
                                            excessMinsExtendsAllowance:
                                              x.excessMinsExtendAllowance,
                                          });
                                        } else {
                                          newArr.push(x);
                                        }
                                      });

                                      setCustomDataArr(newArr);
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });
                              }
                            }}
                          >
                            +1h
                          </p>
                          {/* plus minus here */}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="customAllowAmendRow">
                        {!custom.unlimited ? (
                          <div className="customAddPlusDiv"></div>
                        ) : (
                          ""
                        )}
                        <p
                          className={`customUnlimToggle ${
                            custom.unlimited ? "customUnlimCheckedTrue" : ""
                          }`}
                          onClick={() => {
                            if (
                              checkUserState.permissions !== 1 &&
                              editUserState.isSelf
                            ) {
                              setShowIsSelfNotAdminModal(true);
                            } else {
                              axios
                                .post(
                                  `${serverURL}/toggle-user-custom-leave-unlimited`,
                                  {
                                    userID: userID,
                                    year: parseInt(selectedYear),
                                    leaveTypeID: custom.leaveTypeID,
                                    data: !custom.unlimited,
                                  },
                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (
                                    response.data.message.includes("logout")
                                  ) {
                                    window.location.href = "/signin";
                                  }
                                  if (response.data.message === "success") {
                                    // setAllowsUpdatedLocal(true);
                                    let custFilt =
                                      response.data.doc.customLeaveData.filter(
                                        (x) => {
                                          return (
                                            x.leaveTypeID === custom.leaveTypeID
                                          );
                                        }
                                      );
                                    // setUpdateData(!updateData);

                                    let newArr = [];
                                    customDataArr.forEach((x) => {
                                      if (
                                        x.year === custom.year &&
                                        x.leaveTypeID === custom.leaveTypeID
                                      ) {
                                        newArr.push({
                                          name: x.name,
                                          leaveTypeID: x.leaveTypeID,
                                          daysOrHours: x.daysOrHours,
                                          totalAllowMinsBeforeExcessApplied:
                                            x.totalAllowMinsBeforeExcessApplied,
                                          totAllow: custom.totAllow,
                                          remaining: custom.remaining,
                                          used: x.used,
                                          overridden: x.overridden,
                                          year: x.year,
                                          excessMinsExtendsAllowance:
                                            x.excessMinsExtendAllowance,
                                          unlimited: !custom.unlimited,
                                        });
                                      } else {
                                        newArr.push(x);
                                      }
                                    });

                                    setCustomDataArr(newArr);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }
                          }}
                        >
                          Unlimited{" "}
                          <div className="customUnlimCheck">
                            {custom.unlimited ||
                            (custom.daysOrHours === "days" &&
                              custom.totAllow === 366) ||
                            (custom.daysOrHours === "hours" &&
                              custom.totAllow === 527040) ? (
                              <img
                                src={checkboxInstall4}
                                alt="Unlimited"
                                className="customCheckUnlimImg"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </p>
                      </div>
                      <div className="allowanceRow highlightedAllowanceRow">
                        <p className="allowanceInfoKey">Units</p>
                        <img
                          src={blueLoader}
                          className={`${
                            loadingCustomLeaveTypeID === custom.leaveTypeID
                              ? "allowanceLoadingImg"
                              : "none"
                          }`}
                          alt="loading"
                        />
                        <p
                          className={`${
                            loadingCustomLeaveTypeID === custom.leaveTypeID
                              ? "none"
                              : "allowanceInfoValue"
                          }`}
                        >
                          {" "}
                          <p></p>
                          <select
                            className="customDaysorHourSelect"
                            value={custom.daysOrHours}
                            onChange={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              } else {
                                setLoadingCustomLeaveTypeID(custom.leaveTypeID);
                                axios
                                  .post(
                                    `${serverURL}/toggle-user-custom-leave-days-or-hours`,
                                    {
                                      userID: userID,
                                      year: parseInt(selectedYear),
                                      leaveTypeID: custom.leaveTypeID,
                                      data:
                                        custom.daysOrHours === "days"
                                          ? "hours"
                                          : "days",
                                    },
                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (
                                      response.data.message.includes("logout")
                                    ) {
                                      window.location.href = "/signin";
                                    }
                                    if (response.data.message === "success") {
                                      // setAllowsUpdatedLocal(true);

                                      setUpdateData(!updateData);
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });
                              }
                            }}
                          >
                            <option value="days">Days</option>
                            <option value="hours">Hours</option>
                          </select>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* end of custom data container */}
          </div>
        ) : (
          ""
        )}
        {sureDeleteConversion.show ? (
          <div
            className="areYouSureModalUnderlay"
            onClick={() => {
              setSureDeleteConversion({ show: false });
            }}
          >
            <div
              className="formCreatedModal"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="overlapsRenTxt">
                Are you sure you want to remove this TOIL conversion?
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    setSureDeleteConversion({ show: false });
                    axios
                      .post(
                        `${serverURL}/delete-toil-conversion`,
                        {
                          userID: sureDeleteConversion.userID,
                          conversionID: sureDeleteConversion.conversionID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          memoVals.setUpdateSingleUserAllowances((x) => userID);
                          if (sureDeleteConversion.toLeave) {
                            let filt = toilConversionArr.filter((xx) => {
                              return (
                                xx.conversionID ===
                                sureDeleteConversion.conversionID
                              );
                            });
                            setToilToAnnLeave(toilToAnnLeave - filt[0].mins);
                          }
                          if (!sureDeleteConversion.toLeave) {
                            let filt = toilConversionArr.filter((xx) => {
                              return (
                                xx.conversionID ===
                                sureDeleteConversion.conversionID
                              );
                            });
                            setToilToAnnLeave(annLeaveToToil - filt[0].mins);
                          }
                          setToilConversionArr(
                            toilConversionArr.filter((x) => {
                              return (
                                x.conversionID !==
                                sureDeleteConversion.conversionID
                              );
                            })
                          );
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Yes
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setSureDeleteConversion({ show: false });
                  }}
                >
                  No
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {editHistory ? (
          <div
            className="areYouSureModalUnderlay"
            onClick={() => {
              setEditHistory(false);
            }}
          >
            <div
              className="formCreatedModal"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="overlapsRenTxt">
                Amend the historical hours of used annual leave {fName} has used
                between{" "}
                {dateStringer.printMonthAndYearFromMy(historyPeriodStartDs)} and{" "}
                {dateStringer.printMonthAndYearFromDs(historyPeriodEndDs)} - the
                day {fName} was added to FlowRota.
              </p>
              <br />
              {/* <br /> */}
              <input
                type="number"
                value={histVal}
                className="histValInput"
                onChange={(e) => {
                  let val = parseInt(e.target.value);
                  if (val >= 0) {
                    setHistVal(val);
                  }
                }}
              ></input>
              <p className="histValMins">minutes</p>
              <br />
              {/* <br /> */}
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn blueColours"
                  onClick={() => {
                    setEditHistory(false);
                    axios
                      .post(
                        `${serverURL}/edit-history-ann-leave-mins`,
                        {
                          userID: userID,
                          data: parseInt(histVal),
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setUpdateData(!updateData);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Save
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setEditHistory(false);
                  }}
                >
                  Cancel
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* ------- IS NOT ADMIN, IS SELF MODAL */}
        {showIsSelfNotAdminModal && (
          <div
            className="invalidClockShiftModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();

              setShowIsSelfNotAdminModal(false);
            }}
          >
            <div
              className="invalidClockShiftModalBody"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="unableToEditTxt">
                {showIsSelfNotAdminModal === "allow"
                  ? "You cannot amend your own leave allowances. Ask a FlowRota admin or another manager of your primary team to do this."
                  : "Sorry, these settings are only editable by system administrators or another manager of your primary team."}
              </p>

              <p
                className="closeInvalidShiftModalBtn"
                onClick={() => {
                  setShowIsSelfNotAdminModal(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAllowances;
