// keys done 4 march 25
// design done 4 march 25

import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 8 sep 24
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";

import axios from "axios";
import serverURL from "../serverURL";
import appURL from "../appURL";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import inReturnForArrow from "../img/general/inReturnForArrow.svg";
import navyClose from "../img/general/navycross.svg";
import unitTick from "../img/general/unitTick.svg";
import greyCross from "../img/general/greyCross.svg";
import profilePicturePlaceholder from "../img/general/profilePicturePlaceholder.svg";
import msg from "../img/general/chat.svg";
// import popup2 from "../img/general/popup2.svg";
import unclockCal from "../img/general/rotaDate.svg";
import unclockTime from "../img/general/rotaClock.svg";
import teamIcon from "../img/general/rotaTeam.svg";
import shiftBlockNavy from "../img/general/shiftBlockNavy.svg";
import doubleTick from "../img/general/doubleTick.svg";
import darkRedCross from "../img/general/darkRedCross.svg";

import navyTick from "../img/general/navyTick.svg";
const MgrAcceptanceModal = () => {
  const { mobModal } = useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);
  const {
    setIndicate,
    showEditShiftModal,
    setShowEditShiftModal,
    setShowMgrAcceptanceModal,
    setAcceptDecline,
    setShowMessages,
    modalOpen,
    setModalOpen,
    device,
    setShowDeclinedMShiftModal,
    removeDeclinedItemID,
    setRemoveDeclinedItemID,
    countData,
    setCountData,
    openManagerShiftModal,

    showReqMgrModal,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setIndicate,
      showEditShiftModal,
      setShowEditShiftModal,
      setShowMgrAcceptanceModal,
      setAcceptDecline,
      setShowMessages,
      checkUserState,
      mobModal,
      modalOpen,
      setModalOpen,
      device,
      setShowDeclinedMShiftModal,
      removeDeclinedItemID,
      setRemoveDeclinedItemID,
      openManagerShiftModal,

      showReqMgrModal,
    }),
    [
      setIndicate, //
      showEditShiftModal, //
      setShowEditShiftModal, //
      setShowMgrAcceptanceModal, //
      setAcceptDecline, //
      setShowMessages, //
      checkUserState, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
      setShowDeclinedMShiftModal,
      removeDeclinedItemID,
      setRemoveDeclinedItemID,
      openManagerShiftModal,

      showReqMgrModal,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [filterDeclined, setFilterDeclined] = useState(false);
  let [sureClearAll, setSureClearAll] = useState(false);
  useEffect(() => {
    if (dataLoaded && imagesLoaded === 12) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      {" "}
      <img
        src={shiftBlockNavy}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={darkRedCross}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={doubleTick}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyTick}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={inReturnForArrow}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={unclockCal}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={unclockTime}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      {/* <img
        src={popup2}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      /> */}
      <img
        src={msg}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyClose}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitTick}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyCross}
        alt="Grey Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let closeModal = () => {
    memoVals.setShowMgrAcceptanceModal((x) => false);
  };

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      closeModal(false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let [loaded, setLoaded] = useState(false);

  let [showApprovalInfo, setShowApprovalInfo] = useState(false);

  let [accepts, setAccepts] = useState([]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-acceptance`,
        {
          someData: 1,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setDataLoaded(true);
          setLoaded(true);
          setAccepts(response.data.accepts || []);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  useEffect(() => {
    if (memoVals.removeDeclinedItemID) {
      setAccepts(
        accepts.filter((x) => {
          return x.itemID !== memoVals.removeDeclinedItemID;
        })
      );
      memoVals.setRemoveDeclinedItemID((x) => "");
    }
  }, [memoVals.removeDeclinedItemID]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  // master return
  return (
    <div
      className={`clockOnModalUnderlay ${
        memoVals.showEditShiftModal.itemID ||
        memoVals.openManagerShiftModal.shiftID ||
        memoVals.showReqMgrModal
          ? "invis"
          : ""
      }`}
      onClick={() => {
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`mySwapsModal ${
            memoVals.device.ios ? "paddingBottom35" : ""
          } ${memoVals.mobModal ? "mobModalShoulder" : "x209dw022"} mgrModal`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperTeamSwaps"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="mySwapsHeader"
            onClick={() => {}}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={memoVals.mobModal ? navyClose : navyClose}
              alt="Close"
              className="closeMySwapsModalImg"
              onClick={() => {
                closeModal();
              }}
            />
            <p> Accepted & declined shifts</p>
          </div>

          <div
            className={`mySwapsContainer ${
              memoVals.mobModal ? "mgrAcceptanceContainer" : ""
            }`}
          >
            {!loaded ? (
              <img
                src={horizontalBalls}
                alt="Loading"
                className="loadingSwaps"
              />
            ) : (
              ""
            )}
            {!loaded ? (
              ""
            ) : accepts.length === 0 ? (
              <p className="noSwapsTxt">
                Shifts accepted or declined by your staff will be displayed here
              </p>
            ) : (
              accepts.map((unit, i) => {
                if ((unit.declined && filterDeclined) || !filterDeclined) {
                  return (
                    <div
                      key={i}
                      className={`acceptUnit ${
                        !memoVals.mobModal ? "acceptUnitDesktop" : ""
                      } ${
                        unit.declined
                          ? "acceptUnitDeclinedItem"
                          : "cursorPointer"
                      } `}
                      onClick={() => {
                        if (unit.accepted) {
                          memoVals.setShowEditShiftModal((x) => {
                            return {
                              itemID: unit.itemID,
                              itemType: unit.type,
                              itemFName: unit.fName,
                              itemLName: unit.lName,
                              itemIsNew: false,
                              userID: unit.userID,
                            };
                          });
                        }

                        if (unit.declined) {
                          memoVals.setShowDeclinedMShiftModal(
                            (x) => unit.itemID
                          );
                        }
                      }}
                    >
                      <div className="acceptUnitInner">
                        <div className="acceptUnitLeft">
                          <p className="acceptUnitName">
                            <img
                              src={unit.picUrl || profilePicturePlaceholder}
                              alt={unit.fName}
                              className="acceptUnitProPicImg"
                            />
                            {unit.fName} {unit.lName}
                          </p>

                          <div className="acceptTypeAndStrip">
                            {" "}
                            {/* <p className="acceptUnitType">
                          {unit.type === "til"
                            ? "TOIL"
                            : unit.type === "overtime"
                            ? "Overtime"
                            : "Shift"}
                        </p> */}
                            <img
                              src={shiftBlockNavy}
                              alt="Shift"
                              className="shiftBlockNavyAccept"
                            />
                            <p
                              className={`acceptUnitTimes ${
                                unit.declined ? "declinedAccUnitBorder" : ""
                              }`}
                            >
                              <img
                                src={unclockCal}
                                alt="Time"
                                className="accTimeCalIcon"
                              />{" "}
                              {dateStringer.printedDateFromDs(unit.startDs)}{" "}
                              {/* {unit.declined && (
                                <img
                                  src={popup2}
                                  alt="View rota"
                                  className="acceptanceDsPopupImg"
                                />
                              )} */}
                              <br />
                              <span className="acceptUnitDsSpan">
                                <img
                                  src={unclockTime}
                                  alt="Time"
                                  className="accTimeCalIcon accTimeCalIcon1"
                                />{" "}
                                {dateStringer.dsToTimeStrip(unit.startDs, true)}{" "}
                                - {dateStringer.dsToTimeStrip(unit.endDs, true)}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="acceptUnitRight">
                          <p className="acceptUnitType">
                            {unit.type === "til"
                              ? "Overtime (time in lieu)"
                              : unit.type === "overtime"
                              ? "Overtime"
                              : "Shift"}
                          </p>
                          <p
                            className={`acceptUnitString ${
                              unit.declined ? "acceptUnitDeclined" : ""
                            }`}
                          >
                            {unit.accepted ? "Accepted" : "Declined"}{" "}
                            {unit.accepted ? (
                              <img
                                src={doubleTick}
                                alt="Accepted"
                                className="x485895554"
                              />
                            ) : (
                              <img
                                src={darkRedCross}
                                alt="Accepted"
                                className="x239249842"
                              />
                            )}
                          </p>
                          <p className="acceptUnitDsMade">
                            {dateStringer.printedDateWithTimeFromDs(
                              unit.dsMade
                            )}
                          </p>
                          {/* {unit.declined &&
                            unit.userID !== memoVals.checkUserState.userID ? (
                              <p
                                className="acceptMsg"
                                onClick={() => {
                                  memoVals.setAcceptDecline((x) => unit.userID);
                                }}
                              >
                                Message{" "}
                                <img
                                  src={msg}
                                  alt="Message"
                                  className="accMsgImg"
                                />
                              </p>
                            ) : (
                              ""
                            )} */}
                        </div>
                      </div>
                      <p className="acceptDeclineTeamName">
                        <img
                          src={teamIcon}
                          alt="Team"
                          className="teamIconAccept"
                        />
                        {unit.teamName || "Teamless"}
                      </p>
                      {unit.reason ? (
                        <div className="acceptDeclineReason">
                          <span className="declineReasonName">
                            {unit.fName}:
                          </span>
                          {unit.reason}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                }
              })
            )}
          </div>
          <div className="mySwapsModalFooter">
            <div className="flex">
              {" "}
              <p
                className="pubShiftBtn"
                onClick={() => {
                  setSureClearAll(true);
                }}
              >
                Clear all
              </p>
              <div
                className="pubShiftBtn filtDecAccBtn"
                onClick={() => {
                  setFilterDeclined(!filterDeclined);
                }}
              >
                Show declined only{" "}
                <div className="x878748744">
                  <img
                    src={navyTick}
                    alt="Select"
                    className={`mgrAcceptanceFilterDecTick ${
                      filterDeclined ? "" : "invis"
                    }`}
                  />
                </div>
              </div>
            </div>
            <p
              className="closeReqInfo"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {sureClearAll ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setSureClearAll(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              Are you sure you want to clear all acceptance items?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setSureClearAll(false);
                  axios
                    .post(
                      `${serverURL}/clear-acceptance`,
                      {
                        someData: 1,
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "All acceptance items cleared",
                            colour: "blue",
                            duration: 4000,
                          };
                        });
                        setCountData({
                          accepted: 0,
                          declined: 0,
                        });
                        memoVals.setAcceptDecline((x) => {
                          return { accepted: 0, declined: 0 };
                        });
                        setAccepts([]);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes{" "}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureClearAll(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default MgrAcceptanceModal;
