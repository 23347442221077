import React, { useState, useContext, useEffect } from "react";
import axios from "axios";

import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";
import { CalendarContext } from "../contexts/CalendarContext";

import { NavLink } from "react-router-dom";
import "../styles/calendar.css";
import CheckUser from "../tools/CheckUser";
import dateStringer from "../tools/dateStringer";

import serverURL from "../serverURL";
import logo from "../img/general/logo.png";
import back from "../img/general/back.svg";

import Navbar from "./Navbar";
import { nextTick } from "process";

const ContactForm = () => {
  const { calPage, setCalPage, userAbsenceArr, setUserAbsencesArr } =
    useContext(CalendarContext);
  // const {} = useContext(UserContext);

  const { mob } = useContext(StyleContext);

  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [company, setCompany] = useState("");
  let [msg, setMsg] = useState("");
  let [sub, setSub] = useState("");

  let [nameInvalid, setNameInvalid] = useState(false);
  let [emailInvalid, setEmailInvalid] = useState(false);
  let [msgInvalid, setMsgInvalid] = useState(false);
  let [subInvalid, setSubInvalid] = useState(false);
  let [done, setDone] = useState(false);
  let [wait, setWait] = useState(false);

  return (
    <div className="resetPasswordPage">
      {done ? (
        <div className="contactDoneDiv">
          <p className="contactDone">Message sent&nbsp;&nbsp; 👍🏼</p>

          <div className="contactDoneBtnsDiv">
            <a href="https://FlowRota.app" className="contactDoneBtn">
              FlowRota app
            </a>
            <a href="https://FlowRota.com" className="contactDoneBtn">
              Main website
            </a>
            <a href="https://FlowRota.com/support" className="contactDoneBtn">
              Support docs
            </a>
          </div>
        </div>
      ) : (
        <div className={`contactFormDiv`}>
          <img
            src={back}
            alt="Back"
            className="backContactBtnImg"
            onClick={() => {
              history.go(-1);
            }}
          />
          <img
            src={logo}
            alt="FlowRota logo"
            className="resetPassLogo cursorPointer"
            onClick={() => {
              window.location.href = "https://FlowRota.com";
            }}
          />
          <p className="contactFormTitleTxt">
            Need help? Use the form below.
            <br />
            <br />
            Or email us at{" "}
            <a href="mailto: hello@FlowRota.com" className="emailContactInline">
              hello@FlowRota.com
            </a>
          </p>
          <div className="contactFormDivider"></div>
          <p className="contactFormTitle">Name</p>
          <input
            value={name}
            type="text"
            className={`contactFormInput ${
              nameInvalid ? "invalidContactInput" : ""
            }`}
            onChange={(e) => {
              setNameInvalid(false);
              setName(e.target.value);
            }}
          />
          <p className="contactFormTitle">Company</p>
          <p className="contactFormSub">
            Optional - helps us locate your account
          </p>
          <input
            value={company}
            type="text"
            className="contactFormInput"
            onChange={(e) => {
              setCompany(e.target.value);
            }}
          />
          <p className="contactFormTitle">Email</p>
          <input
            value={email}
            type="text"
            className={`contactFormInput ${
              emailInvalid ? "invalidContactInput" : ""
            }`}
            onChange={(e) => {
              setEmailInvalid(false);
              setEmail(e.target.value);
            }}
          />
          <p className="contactFormTitle">Subject</p>
          <input
            value={sub}
            type="text"
            className={`contactFormInput ${
              subInvalid ? "invalidContactInput" : ""
            }`}
            onChange={(e) => {
              setSubInvalid(false);
              setSub(e.target.value);
            }}
          />
          <p className="contactFormTitle">Your message</p>
          <textarea
            value={msg}
            className={`contactFormInput ${
              msgInvalid ? "invalidContactInput" : ""
            } msgtxtarea`}
            onChange={(e) => {
              setMsgInvalid(false);

              setMsg(e.target.value);
            }}
          />
          {!wait ? (
            <p
              className="sendContactMsgBtn"
              onClick={() => {
                let fail = false;
                if (!name) {
                  setNameInvalid(true);
                  fail = true;
                }

                if (!email || !email.includes("@") || !email.includes(".")) {
                  setEmailInvalid(true);
                  fail = true;
                }

                if (!msg || msg.length === 1) {
                  setMsgInvalid(true);
                  fail = true;
                }

                if (!sub) {
                  setSubInvalid(true);
                  fail = true;
                }

                if (!fail) {
                  setWait(true);
                  // axios send message here
                  axios
                    .post(
                      `${serverURL}/submit-contact`,
                      {
                        emailVal: email,
                        inputVal: msg,
                        companyName: company,
                        name,
                        sub,
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      console.log(response);
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setDone(true);
                        setWait(false);
                      }
                    });
                }
              }}
            >
              Send message
            </p>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default ContactForm;
