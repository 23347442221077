import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 9 sep 24

import { StyleContext } from "../contexts/StyleContext";
import { DataContext } from "../contexts/DataContext";

import axios from "axios";
import serverURL from "../serverURL";

import "../styles/navbar.css";

import navyClose from "../img/general/navycross.svg";

import editUserEdit1 from "../img/general/editUserEdit1.svg";
import { AdminContext } from "../contexts/AdminContext";
import dateStringer from "../tools/dateStringer";

const ViewAbsenceTypeModal = () => {
  const { mobModal, mob } = useContext(StyleContext);
  const {
    setIndicate,
    setSureRemoveAbsenceType,
    showAbsenceTypeModal,
    setShowAbsenceTypeModal,
    modalOpen,
    setModalOpen,
    device,
  } = useContext(DataContext);
  const { setAbsenceTypes } = useContext(AdminContext);

  const memoVals = useMemo(
    () => ({
      setIndicate, //
      setSureRemoveAbsenceType, //
      showAbsenceTypeModal, //
      setShowAbsenceTypeModal, //
      mobModal, //
      mob, //
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setIndicate, //
      setSureRemoveAbsenceType, //
      showAbsenceTypeModal, //
      setShowAbsenceTypeModal, //
      mobModal, //
      mob, //
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [sureReinstate, setSureReinstate] = useState(false);
  let [nameExists, setNameExists] = useState(false);

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowAbsenceTypeModal((x) => {
        return { show: false };
      });
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let [toggleEditName, setToggleEditName] = useState(
    memoVals.showAbsenceTypeModal.new ? true : false
  );

  let [newName, setNewName] = useState(
    memoVals.showAbsenceTypeModal.new ? "" : ""
  );

  let [rate, setRate] = useState(
    memoVals.showAbsenceTypeModal.new ? 1 : memoVals.showAbsenceTypeModal.rate
  );

  let nameRef = useRef();
  useEffect(() => {
    if (memoVals.showAbsenceTypeModal.new && !memoVals.mob) {
      if (nameRef && nameRef.current) {
        nameRef.current.focus();
      }
    }
  }, []);

  let closeModal = () => {
    memoVals.setShowAbsenceTypeModal((x) => {
      return { show: false };
    });
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className="clockOnModalUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      <div
        className={`dayCommentsModal ${
          memoVals.mobModal ? "mobModalShoulder" : "maxViewZoneModalWidth"
        } ${memoVals.device.ios ? "paddingBottom35" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {memoVals.mobModal && (
          <div
            className="modalSwiper modalSwiperViewLeaveType"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          ></div>
        )}
        <div
          className="mySwapsHeader"
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={memoVals.mobModal ? navyClose : navyClose}
            alt="Close"
            className="closeMySwapsModalImg"
            onClick={() => {
              closeModal();
            }}
          />
          <p>Absence type</p>

          <p></p>
        </div>
        <div className="dayCommentsModalBody maxHeight480">
          <div className="gpsZoneRow noBorder">
            <p className="lonLatTitles">Absence name</p>

            {toggleEditName ? (
              <div className={`lonLatInputDiv`}>
                {" "}
                <input
                  type="text"
                  defaultValue="Current location"
                  className={`latLonInput`}
                  placeholder={
                    memoVals.showAbsenceTypeModal.new
                      ? "New absence type"
                      : newName
                  }
                  ref={nameRef}
                  value={newName}
                  onChange={(e) => {
                    setNewName(e.target.value);
                  }}
                ></input>
                {!memoVals.showAbsenceTypeModal.new ? (
                  <p
                    className={`saveSettBtn ${
                      memoVals.showAbsenceTypeModal.new ? "none" : ""
                    }`}
                    onClick={() => {
                      setToggleEditName(false);

                      axios
                        .post(
                          `${serverURL}/update-custom-leave-type`,
                          {
                            updateWhat: "name",
                            absenceTypeID:
                              memoVals.showAbsenceTypeModal.absenceTypeID,
                            data: newName,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            memoVals.setShowAbsenceTypeModal((x) => {
                              return {
                                show: true,
                                absenceTypeID:
                                  memoVals.showAbsenceTypeModal.absenceTypeID,
                                rate: memoVals.showAbsenceTypeModal.rate,
                                name: newName,
                              };
                            });
                            setAbsenceTypes(response.data.absenceTypes);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    Save
                  </p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <p
                className={`lonLatVal ${
                  memoVals.showAbsenceTypeModal.name > 24
                    ? "smallZoneName2"
                    : ""
                }`}
              >
                {memoVals.showAbsenceTypeModal.name}
                {memoVals.showAbsenceTypeModal.removable && (
                  <img
                    src={editUserEdit1}
                    alt="Edit"
                    className="adminEditBtnImg"
                    onClick={() => {
                      setNewName(memoVals.showAbsenceTypeModal.name);

                      setToggleEditName(true);
                    }}
                  />
                )}
              </p>
            )}
          </div>{" "}
          <div className="gpsZoneRow">
            <p className="lonLatTitles">Paid absence</p>

            <p className={`lonLatVal x249898233`}>
              {memoVals.showAbsenceTypeModal.new
                ? rate === "0"
                  ? "No"
                  : "Yes"
                : memoVals.showAbsenceTypeModal.rate === 0 ||
                  memoVals.showAbsenceTypeModal.rate === "0"
                ? "No"
                : "Yes"}

              {/*  KNOB BEGIN */}
              {memoVals.showAbsenceTypeModal.new ? (
                <div
                  className="knobContainer specDayEditKnob"
                  onClick={() => {
                    let newData = rate !== "0" ? "0" : "1";
                    setRate(newData);
                  }}
                >
                  <div
                    className={`switchKnob ${
                      rate !== "0" && rate !== 0 ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              ) : (
                ""
              )}
              {/*  KNOB END */}
            </p>
          </div>
          {memoVals.showAbsenceTypeModal.new && rate === "0" ? (
            ""
          ) : memoVals.showAbsenceTypeModal.rate !== "0" &&
            memoVals.showAbsenceTypeModal.rate !== 0 ? (
            <div className="gpsZoneRow">
              <p className="lonLatTitles x248984234">Pay rate</p>

              <div
                className={`lonLatVal x240893823 x348598553  ${
                  memoVals.mobModal && memoVals.showAbsenceTypeModal.new
                    ? "x298484"
                    : ""
                }`}
              >
                {memoVals.showAbsenceTypeModal.new ? (
                  <select
                    className={`specialDaysRateDropDown x298492812 x4985896336 marginLeft167 ${
                      memoVals.mobModal ? "x095985858" : ""
                    }`}
                    onChange={(e) => {
                      let newData = e.target.value;
                      setRate(newData);
                    }}
                    value={rate}
                  >
                    <option value="0">x0</option>
                    <option value="0.05">x0.05</option>

                    <option value="0.1">0.1x</option>
                    <option value="0.15">0.15x</option>

                    <option value="0.2">0.2x</option>
                    <option value="0.25">0.25x</option>

                    <option value="0.3">0.3x</option>
                    <option value="0.35">0.35x</option>

                    <option value="0.4">0.4x</option>
                    <option value="0.45">0.45x</option>

                    <option value="0.5">0.5x</option>
                    <option value="0.55">0.55x</option>

                    <option value="0.6">0.6x</option>
                    <option value="0.65">0.65x</option>

                    <option value="0.7">0.7x</option>
                    <option value="0.75">0.75x</option>

                    <option value="0.75">0.75x</option>
                    <option value="0.8">0.8x</option>
                    <option value="0.85">0.85x</option>

                    <option value="0.9">0.9x</option>
                    <option value="0.95">0.95x</option>

                    <option value="1">1x</option>
                  </select>
                ) : (
                  <p className="showLeaveTypeVal">{rate}x</p>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {!memoVals.showAbsenceTypeModal.new &&
          !memoVals.showAbsenceTypeModal.removed ? (
            <div className="gpsZoneRow">
              {memoVals.showAbsenceTypeModal.removable ? (
                <p className="lonLatTitles x248984234">
                  You are unable to amend the rate or paid setting of this
                  absence type. Instead, you should archive this absence type
                  with the button below and create a new one.
                  <br />
                  <br />
                  This is because employees may already have historic absence
                  periods that rely on this absence type's rate. By archiving
                  this absence type instead, employees' historic absence periods
                  of this type will still be able to use the absence type's
                  rate. The absence type is never truly deleted (unless you
                  delete your FlowRota company account).
                  <br />
                  <br />
                </p>
              ) : (
                <p className="lonLatTitles x248984234">
                  This is a default absence type hard-wired into FlowRota.
                  <br />
                  <br /> You are unable to amend or archive a default absence
                  type. Instead, create additional absence types with different
                  pay rates.
                  <br />
                  <br />
                </p>
              )}

              <p></p>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="dayCommentsModalFooter">
          <div className="viewMapsAndSaveDiv">
            {memoVals.showAbsenceTypeModal.new ? (
              <p
                className="saveGpsZoneBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/create-new-custom-leave-type`,
                      {
                        name: newName,
                        // daysOrHours,
                        // allowance,
                        rate,
                        // blockRequests,
                        isAbsence: true,
                        // jwt, name, daysOrHours, allowance, rate
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "name exists") {
                        setNameExists(true);
                      }
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setToggleEditName(false);

                        setAbsenceTypes(response.data.absenceTypes);

                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Custom leave created",
                            colour: "blue",
                            duration: 4000,
                          };
                        });

                        memoVals.setShowAbsenceTypeModal((x) => {
                          return {
                            show: true,
                            absenceTypeID: response.data.newObj.absenceTypeID,
                            rate: response.data.newObj.rate,
                            name: response.data.newObj.name,
                            removed: false,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Save
              </p>
            ) : (
              ""
            )}
            <a
              className={`viewGpsZoneMapBtn ${
                memoVals.showAbsenceTypeModal.new ||
                !memoVals.showAbsenceTypeModal.removable
                  ? "none"
                  : ""
              }`}
              onClick={() => {
                if (memoVals.showAbsenceTypeModal.removed) {
                  setSureReinstate(true);
                } else {
                  memoVals.setSureRemoveAbsenceType(
                    (x) => memoVals.showAbsenceTypeModal.absenceTypeID
                  );
                }
              }}
            >
              {memoVals.showAbsenceTypeModal.removed ? "Reinstate" : "Archive"}
            </a>
          </div>

          <p
            className="closeSeeUserIDBtn"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </p>
        </div>
      </div>
      {sureReinstate ? (
        <div className="clockOnModalUnderlay">
          <div
            className={`formCreatedModal `}
            onClick={(e) => {
              e.stopPropagation();
              setSureReinstate(false);
            }}
          >
            <p className="overlapsRenTxt">
              Are you sure you want to reinstate this custom leave?
              <br /> <br />
              Employees will be able to request this leave again.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setSureReinstate(false);
                  axios
                    .post(
                      `${serverURL}/remove-custom-leave-type`,
                      {
                        absenceTypeID:
                          memoVals.showAbsenceTypeModal.absenceTypeID,
                        undoRemove: true,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setAbsenceTypes(response.data.absenceTypes);

                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Absence type reinstated",
                            colour: "blue",
                            duration: 4000,
                          };
                        });
                        memoVals.setShowAbsenceTypeModal((x) => {
                          return {
                            show: true,
                            absenceTypeID:
                              memoVals.showAbsenceTypeModal.absenceTypeID,
                            rate: memoVals.showAbsenceTypeModal.rate,
                            name: memoVals.showAbsenceTypeModal.name,
                            removed: false,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureReinstate(false);
                }}
              >
                No
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {nameExists ? (
        <div className="clockOnModalUnderlay">
          <div
            className={`formCreatedModal `}
            onClick={(e) => {
              e.stopPropagation();
              setNameExists(false);
            }}
          >
            <p className="overlapsRenTxt">
              The name of this absence already exists - either in use or
              archived.
              <br /> <br />
              Names of absence types must be unique.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setNameExists(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ViewAbsenceTypeModal;
