import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  Fragment,
} from "react";
import { NavLink } from "react-router-dom";
// ios done 7 aug 24
// cleaned wed 24 jul 23
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";

import axios from "axios";
import serverURL from "../serverURL";
import close from "../img/modals/close.svg";
import allowArr from "../img/general/allowArr.svg";
import greenTick from "../img/general/greenTick.svg";
import popup from "../img/general/popupNavy.svg";
import whiteGo from "../img/general/whiteGo.svg";

import plane2 from "../img/general/plane2navy.svg";
import plane from "../img/general/planeNavy.svg";
import desktopBurger from "../img/general/desktopBurger.svg";
import rotaDur from "../img/general/rotaDur.svg";
import rotaDate from "../img/general/rotaDate.svg";
import rotaClock from "../img/general/rotaClock.svg";
import whiteTick from "../img/general/navyTick.svg";
import rotaTeam from "../img/general/rotaTeam.svg";
import ts1 from "../img/general/ts1.svg";
import rotaPerson from "../img/general/rotaPerson.svg";
import rotaEdit from "../img/general/rotaEdit.svg";
import rotaReason from "../img/general/rotaReason.svg";
import unitCross from "../img/general/unitCross.svg";
import navyDots from "../img/general/navyDots.svg";
import navyTick from "../img/general/navyTick.svg";
import rotaCoffee from "../img/general/rotaCoffee.svg";
import shiftChangeArrow from "../img/general/shiftChangeArrow.svg";
import rightArr from "../img/general/rightArr.svg";
import unitTick from "../img/general/unitTick.svg";
import barChart from "../img/general/barChart.svg";
import shiftBlockNavy from "../img/general/shiftBlockNavy.svg";
import lightGreyCancel from "../img/general/lightGreyCancel.svg";
import inReturnForArrow from "../img/general/inReturnForArrow.svg";
import greyCross from "../img/general/greyCross.svg";
import tick from "../img/general/checkboxInstall4.svg";

import sun from "../img/general/sun.svg";
import profilePicturePlaceholder from "../img/general/profilePicturePlaceholder.svg";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";
import { DataContext } from "../contexts/DataContext";
import appURL from "../appURL";

const NotifReqs = ({}) => {
  const { mobModal, mob } = useContext(StyleContext);
  const { setCheckUserState, checkUserState } = useContext(UserContext);
  const {
    countData,
    setCountData,
    setShowReqMgrModal,
    setIndicate,
    setShowEditUser,
    updateTabData,
    setUpdateTabData,
    setClockOnID,
    setShowViewTimesheet,
    setOpenManagerShiftModal,
    approvedTsId,
    setApprovedTsId,
    approvedCCId,
    setApprovedCCId,
    setRemoveLoggedShiftID,
    setShowDeclinedMShiftModal,
    removeDeclinedItemID,
    setRemoveDeclinedItemID,
    setShowEditShiftModal,
    setDynamicSwap,
    removeSelfLoggedAbsenceItem,
    setRemoveSelfLoggedAbsenceItem,
    setShowForm,
  } = useContext(DataContext);

  let [recordLateItemIDs, setRecordLateItemIDs] = useState([]);
  let [recordLateUnpaidItemIDs, setRecordLateUnpaidItemIDs] = useState([]);

  let [approving, setApproving] = useState("");

  const memoVals = useMemo(
    () => ({
      setShowReqMgrModal,
      mob,
      checkUserState,
      setIndicate,
      setShowEditUser,
      updateTabData,
      setUpdateTabData,
      setClockOnID,
      setShowViewTimesheet,
      setOpenManagerShiftModal,
      setRemoveLoggedShiftID,
      setShowDeclinedMShiftModal,
      setShowEditShiftModal,
      setDynamicSwap,
      removeSelfLoggedAbsenceItem,
      setRemoveSelfLoggedAbsenceItem,
      setShowForm,
    }),
    [
      setShowReqMgrModal,
      mob,
      checkUserState,
      setIndicate,
      setShowEditUser,
      updateTabData,
      setUpdateTabData,
      setClockOnID,
      setShowViewTimesheet,
      setOpenManagerShiftModal,
      setRemoveLoggedShiftID,
      setShowDeclinedMShiftModal,
      setShowEditShiftModal,
      setDynamicSwap,
      removeSelfLoggedAbsenceItem,
      setRemoveSelfLoggedAbsenceItem,
      setShowForm,
    ]
  );

  let [approveLoading, setApproveLoading] = useState("");
  let [blockApprove, setBlockApprove] = useState("");
  let [itemsArr, setItemsArr] = useState([]);
  let [loading, setLoading] = useState(true);
  let [shiftReqsLoading, setShiftReqsLoading] = useState(false);
  let [group, setGroup] = useState("leave"); // leave, hours, changes, swaps, absence
  const cancelSource1 = axios.CancelToken.source();

  useEffect(() => {
    if (group === "hours" && approvedTsId) {
      setItemsArr(
        itemsArr.filter((x) => {
          if (x.type === "timesheet" && x.timesheetID === approvedTsId) {
          } else {
            return x;
          }
        })
      );

      setApprovedTsId("");
    }
    if (group === "hours" && approvedCCId) {
      setItemsArr(
        itemsArr.filter((x) => {
          if (x.on && x.clockOnID === approvedCCId) {
          } else {
            return x;
          }
        })
      );

      setApprovedCCId("");
    }

    if (group === "hours" && removeDeclinedItemID) {
      setItemsArr(
        itemsArr.filter((x) => {
          if (x.type === "declined" && x.itemID === removeDeclinedItemID) {
          } else {
            return x;
          }
        })
      );

      setRemoveDeclinedItemID("");
    }

    if (group === "absence" && removeSelfLoggedAbsenceItem) {
      setItemsArr(
        itemsArr.filter((x) => {
          if (x.sla && x.typeID === removeSelfLoggedAbsenceItem) {
          } else {
            return x;
          }
        })
      );
      setRemoveSelfLoggedAbsenceItem("");
    }
  }, [
    approvedTsId,
    approvedCCId,
    removeDeclinedItemID,
    removeSelfLoggedAbsenceItem,
  ]);

  let [reload, setReload] = useState(false);
  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    axios
      .post(
        `${serverURL}/get-mgr-requests`,
        {
          group,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setItemsArr(response.data.output);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [group, reload]);

  let [hoursTimesheets, setHoursTimesheets] = useState(false);
  let [hoursClocks, setHoursClocks] = useState(false);
  let [hoursLogged, setHoursLogged] = useState(false);
  let [hoursDeclined, setHoursDeclined] = useState(false);

  let [absenceForms, setAbsenceForms] = useState(false);
  let [absenceSLA, setAbsenceSLA] = useState(false);

  let sortSwaps = (theSwaps) => {
    let notYetApproved = [];
    let approved = [];

    theSwaps.forEach((s) => {
      if (s.mgrApproved) {
        approved.push(s);
      } else {
        notYetApproved.push(s);
      }
    });

    return [...notYetApproved, ...approved];
  };

  useEffect(() => {
    if (group === "hours") {
      if (hoursTimesheets) {
        setHoursTimesheets(false);
      }
      if (hoursClocks) {
        setHoursClocks(false);
      }
      if (hoursDeclined) {
        setHoursDeclined(false);
      }
      if (hoursLogged) {
        setHoursLogged(false);
      }
    }
    if (group === "absence") {
      if (absenceForms) {
        setAbsenceForms(false);
      }
      if (absenceSLA) {
        setAbsenceSLA(false);
      }
    }
  }, [group]);

  let [sureDeclineChange, setSureDeclineChange] = useState({ show: false });

  let deny = (s, allSwaps) => {
    axios
      .post(
        `${serverURL}/deny-swap`,
        {
          wantType: s.wantedType,
          shiftID: s.wantedTypeID,
          wantUserID: s.offeredUserID,
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((resposne) => {
        if (resposne.data.message === "success") {
          let newArr = [];

          allSwaps.forEach((sw) => {
            if (sw.id !== s.id) {
              newArr.push(sw);
            }
          });

          setItemsArr(sortSwaps(newArr));

          if (!s.mgrApproved) {
            setCountData({
              staffSwaps: countData.staffSwaps - 1,
            });
          }

          memoVals.setIndicate((x) => {
            return {
              show: true,
              message: `Denied swap between ${s.wantedFName} and ${s.offeredFName}`,
              colour: "red",
              duration: 4000,
              ts: new Date().getTime(),
            };
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let [showCannotManageOwnShift, setShowCannotManageOwnShift] = useState({
    show: false,
  });

  let scrollBody = useRef();
  useEffect(() => {
    // clean not needed
    if (scrollBody.current) {
      scrollBody.current.scrollTop = 0;
    }
  }, [
    group,
    hoursClocks,
    hoursDeclined,
    hoursTimesheets,
    hoursLogged,
    absenceForms,
    absenceSLA,
  ]);
  // master return
  return (
    <div className="notifReqsContentWrapper">
      <div className="notifReqsShoulder">
        <div className="notifReqsMenu">
          <div
            className="notifReqsMenuUnit"
            onClick={() => {
              setGroup("leave");
            }}
          >
            <div
              className={`notifReqsMenuItem ${
                group === "leave" ? "notifReqsMenuItemSelected" : ""
              }`}
            >
              Leave{" "}
              {countData.leaveReqs > 0 && (
                <p className="notifReqsMenuQty">
                  {countData.leaveReqs > 9 ? "9+" : countData.leaveReqs}
                </p>
              )}
            </div>
            <div
              className={`notifReqsSelBlob ${
                group === "leave" ? "bg00ccff zoomIn" : ""
              }`}
            ></div>
          </div>
          <div className="notifReqsMenuItemSplit"></div>{" "}
          <div
            className="notifReqsMenuUnit"
            onClick={() => {
              setGroup("hours");
            }}
          >
            <div
              className={`notifReqsMenuItem ${
                group === "hours" ? "notifReqsMenuItemSelected" : ""
              }`}
            >
              Hours{" "}
              {countData.clocks +
                countData.loggedShifts +
                countData.timesheets +
                countData.declined >
                0 && (
                <p className="notifReqsMenuQty">
                  {countData.clocks +
                    countData.loggedShifts +
                    countData.timesheets +
                    countData.declined >
                  9
                    ? "9+"
                    : countData.clocks +
                      countData.loggedShifts +
                      countData.timesheets +
                      countData.declined}
                </p>
              )}
            </div>{" "}
            <div
              className={`notifReqsSelBlob ${
                group === "hours" ? "bg00ccff zoomIn" : ""
              }`}
            ></div>
          </div>
          <div className="notifReqsMenuItemSplit"></div>{" "}
          <div className="notifReqsMenuUnit">
            <div
              onClick={() => {
                setGroup("changes");
              }}
              className={`notifReqsMenuItem ${
                group === "changes" ? "notifReqsMenuItemSelected" : ""
              }`}
            >
              Changes
              {countData.ttrs + countData.drops + countData.shiftEdits > 0 && (
                <p className="notifReqsMenuQty">
                  {countData.ttrs + countData.drops + countData.shiftEdits > 9
                    ? "9+"
                    : countData.ttrs + countData.drops + countData.shiftEdits}
                </p>
              )}
            </div>{" "}
            <div
              className={`notifReqsSelBlob ${
                group === "changes" ? "bg00ccff zoomIn" : ""
              }`}
            ></div>
          </div>
          <div className="notifReqsMenuItemSplit"></div>{" "}
          <div
            className="notifReqsMenuUnit"
            onClick={() => {
              setGroup("swaps");
            }}
          >
            <div
              className={`notifReqsMenuItem ${
                group === "swaps" ? "notifReqsMenuItemSelected" : ""
              }`}
            >
              Swaps{" "}
              {countData.staffSwaps > 0 && (
                <p className="notifReqsMenuQty">
                  {countData.staffSwaps > 9 ? "9+" : countData.staffSwaps}
                </p>
              )}
            </div>{" "}
            <div
              className={`notifReqsSelBlob ${
                group === "swaps" ? "bg00ccff zoomIn" : ""
              }`}
            ></div>
          </div>
          <div className="notifReqsMenuItemSplit"></div>{" "}
          <div
            className="notifReqsMenuUnit"
            onClick={() => {
              setGroup("absence");
            }}
          >
            <div
              className={`notifReqsMenuItem ${
                group === "absence" ? "notifReqsMenuItemSelected" : ""
              }`}
            >
              Absence{" "}
              {countData.forms + countData.selfLoggedAbsence > 0 && (
                <p className="notifReqsMenuQty">
                  {countData.forms + countData.selfLoggedAbsence > 9
                    ? "9+"
                    : countData.forms + countData.selfLoggedAbsence}
                </p>
              )}{" "}
            </div>{" "}
            <div
              className={`notifReqsSelBlob ${
                group === "absence" ? "bg00ccff zoomIn" : ""
              }`}
            ></div>
          </div>
        </div>
        <p></p>
      </div>
      {group === "hours" && (
        <div className="mgrReqNotifHoursKey">
          <div
            className={`hoursGroupFiltBtn ${
              hoursDeclined ? "colour143051" : ""
            }`}
            onClick={() => {
              setHoursDeclined(!hoursDeclined);
            }}
          >
            Declined{" "}
            {countData.declined > 0 && (
              <p className="notifReqSubQty">
                {countData.declined > 9 ? "9+" : countData.declined}
              </p>
            )}
            <img
              src={navyTick}
              alt="Hours"
              className={
                hoursDeclined ? "hoursGroupFiltActive" : "hoursGroupFiltImg"
              }
            />
          </div>{" "}
          <div
            className={`hoursGroupFiltBtn ${hoursLogged ? "colour143051" : ""}`}
            onClick={() => {
              setHoursLogged(!hoursLogged);
            }}
          >
            Logged{" "}
            {countData.loggedShifts > 0 && (
              <p className="notifReqSubQty">
                {countData.loggedShifts > 9 ? "9+" : countData.loggedShifts}
              </p>
            )}
            <img
              src={navyTick}
              alt="Hours"
              className={
                hoursLogged ? "hoursGroupFiltActive" : "hoursGroupFiltImg"
              }
            />
          </div>{" "}
          <div
            className={`hoursGroupFiltBtn ${
              hoursTimesheets ? "colour143051" : ""
            }`}
            onClick={() => {
              setHoursTimesheets(!hoursTimesheets);
            }}
          >
            Timesheets{" "}
            {countData.timesheets > 0 && (
              <p className="notifReqSubQty">
                {countData.timesheets > 9 ? "9+" : countData.timesheets}
              </p>
            )}
            <img
              src={navyTick}
              alt="Hours"
              className={
                hoursTimesheets
                  ? "hoursGroupFiltActive x42984982455Active"
                  : "hoursGroupFiltImg x42984982455"
              }
            />
          </div>{" "}
          <div
            className={`hoursGroupFiltBtn ${hoursClocks ? "colour143051" : ""}`}
            onClick={() => {
              setHoursClocks(!hoursClocks);
            }}
          >
            Clocked{" "}
            {countData.clocks > 0 && (
              <p className="notifReqSubQty">
                {countData.clocks > 9 ? "9+" : countData.clocks}
              </p>
            )}
            <img
              src={navyTick}
              alt="Hours"
              className={
                hoursClocks ? "hoursGroupFiltActive" : "hoursGroupFiltImg"
              }
            />
          </div>{" "}
        </div>
      )}
      {group === "absence" && (
        <div className="mgrReqNotifHoursKey">
          <div
            className={`hoursGroupFiltBtn ${absenceSLA ? "colour143051" : ""}`}
            onClick={() => {
              setAbsenceSLA(!absenceSLA);
            }}
          >
            Logged absence{" "}
            {countData.selfLoggedAbsence > 0 && (
              <p className="notifReqSubQty">
                {countData.selfLoggedAbsence > 9
                  ? "9+"
                  : countData.selfLoggedAbsence}
              </p>
            )}
            <img
              src={navyTick}
              alt="Hours"
              className={
                absenceSLA ? "hoursGroupFiltActive" : "hoursGroupFiltImg"
              }
            />
          </div>{" "}
          <div
            className={`hoursGroupFiltBtn ${
              absenceForms ? "colour143051" : ""
            }`}
            onClick={() => {
              setAbsenceForms(!absenceForms);
            }}
          >
            Absence forms{" "}
            {countData.forms > 0 && (
              <p className="notifReqSubQty">
                {countData.forms > 9 ? "9+" : countData.forms}
              </p>
            )}
            <img
              src={navyTick}
              alt="Hours"
              className={
                absenceForms ? "hoursGroupFiltActive" : "hoursGroupFiltImg"
              }
            />
          </div>{" "}
        </div>
      )}
      <div
        className={`notifReqsContentBody ${
          group === "hours" || group === "absence"
            ? `notifReqsContentBodyHours ${
                !memoVals.mob ? "notifReqsContentBodyHoursDesktop" : ""
              }`
            : ""
        }`}
        ref={scrollBody}
      >
        {loading ? (
          <img
            src={horizontalBalls}
            alt="Loading"
            className="notifReqsLoadingImg"
          />
        ) : !itemsArr || (Array.isArray(itemsArr) && itemsArr.length === 0) ? (
          <p className="noMgrReqsTxtStrip">
            {group === "leave" ? (
              <>
                <span className="upToDate">You're up to date!</span> <br />
                <br />
                There are no outstanding leave requests.
              </>
            ) : group === "swaps" ? (
              <>
                <span className="upToDate">You're up to date!</span> <br />
                <br />
                There are no outstanding shift swap requests.
              </>
            ) : group === "changes" ? (
              <>
                <span className="upToDate">You're up to date!</span> <br />
                <br />
                There are no shift change requests.
              </>
            ) : group === "absence" ? (
              <>
                <span className="upToDate">You're up to date!</span> <br />
                <br />
                There are no logged absences nor any completed absence forms.
              </>
            ) : group === "hours" ? (
              <>
                <span className="upToDate">You're up to date!</span> <br />
                <br />
                There are no newly logged shifts, timesheets, clock cards, or
                shift declines.
              </>
            ) : (
              ""
            )}
          </p>
        ) : group === "absence" ? (
          itemsArr.map((unit, i) => {
            if (unit.sla) {
              let printShiftDurationSubTxt = () => {
                // house
                if (unit.data.durMins === 0 || !unit.data.durMins) {
                  return "0 mins";
                }

                return unit.data.durMins < 1 && unit.data.durMins > 0
                  ? "1 minute"
                  : `${dateStringer.formatMinsDurationToHours(
                      unit.data.durMins || 0,
                      false,
                      true
                    )}`;
              };

              // rows: 3, 4, 5, 6
              return (
                <div
                  className={`reqUnitParent absSLAitem  ${
                    (!absenceForms && !absenceSLA) ||
                    ((absenceForms || absenceSLA) && absenceSLA)
                      ? ""
                      : "none"
                  }`}
                  key={i}
                >
                  <div
                    onClick={() => {
                      memoVals.setOpenManagerShiftModal((x) => {
                        return {
                          shiftID: unit.type === "shift" ? unit.typeID : "",
                          tilID: unit.type !== "shift" ? unit.typeID : "",
                          fName: unit.fName,
                          lName: unit.lName,
                          openedFromShiftsPage: true,
                          type: unit.type,
                        };
                      });
                    }}
                    className={`mgrReqItem pend ${
                      memoVals.mob ? "borderRadius10x" : ""
                    } leaveReqItem shiftItemRows4 x5958583355 minWidth94`}
                  >
                    {" "}
                    <div
                      className={`unsubmittedTsBlob annLeaveBlob annLeaveBlobRows4 xr85848822`}
                    ></div>
                    <div className="reqPlaneDiv opac06">
                      <img
                        src={shiftBlockNavy}
                        alt="Requested leave"
                        className={`reqPlaneIcon shIconImgTeamSh ${
                          memoVals.mob ? "reqPlaneIconMob" : ""
                        }`}
                      />
                    </div>
                    <div className="reqColBarDiv divPend"></div>
                    <div
                      className={`mgrReqItemCol2 ${
                        memoVals.mob ? "mgrReqItemCol2Mob" : ""
                      }`}
                    >
                      <div className="mgrReqTopRow">
                        <div className="mgrReqName mgrReqName2">
                          {/* ------ */}
                          <div
                            className={`mgrReqDateStrip pendDateDiv x29898988444 shContentLeftRows4 x585839842535 `}
                          >
                            <div
                              className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons displayFlexShiftType`}
                            >
                              <img
                                src={desktopBurger}
                                alt="Request duration"
                                className="rotaSunReqs leaveNameBurger leaveNameBurgerNotifReq"
                              />

                              <p className="shTypeNameStrip">
                                {unit.type === "shift" ? (
                                  "Shift"
                                ) : unit.type === "overtime" ? (
                                  "Paid overtime"
                                ) : unit.type === "til" ? (
                                  <>
                                    Overtime{" "}
                                    <span className="shTypeTilSmallSpan">
                                      (in lieu)
                                    </span>
                                  </>
                                ) : (
                                  "Other---"
                                )}
                              </p>
                            </div>
                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan marginTop6 x2389484455`}
                            >
                              <img
                                src={rotaDur}
                                alt="Request duration"
                                className="rotaSunReqs"
                              />
                              {printShiftDurationSubTxt()}
                            </div>
                            {/* <div
                            className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur `}
                          >
                            <img
                              src={rotaTeam}
                              alt="Request duration"
                              className="rotaSunReqs"
                            />

                            {dateStringer.shorten(
                              unit.teamName,
                              window.innerWidth >= 541 &&
                                window.innerWidth < 820
                                ? 42
                                : window.innerWidth < 360
                                ? 17
                                : window.innerWidth < 515 ||
                                  (window.innerWidth >= 1000 &&
                                    window.innerWidth <= 1144)
                                ? 22
                                : 30
                            )}
                          </div> */}

                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur  marginTop6ba0000 makeBold ba0000`}
                            >
                              <img
                                src={rotaPerson}
                                alt="Request duration"
                                className="rotaSunReqs rotaPersonNotifReq"
                              />
                              <p className="teamShiftStringTxt">
                                Logged absence
                              </p>
                            </div>
                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan marginTop6 x2389484455`}
                            >
                              <img
                                src={rotaTeam}
                                alt="Request duration"
                                className="rotaSunReqs rotaPersonNotifReq"
                              />
                              <p className="teamShiftStringTxt">
                                {unit.teamName}
                              </p>
                            </div>
                          </div>
                          {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                        </div>
                        <div className="teamShRightBlock">
                          <div className="absTimelineVal absTimelineValReqs rowReverse marginRight0">
                            <div className={`shItemTimes `}>
                              <p className="shItemTimeRow">
                                {unit.data.start.length > 10
                                  ? dateStringer.dsToTimeStrip(
                                      unit.data.start,
                                      true
                                    )
                                  : unit.data.start}
                              </p>
                              <div className="shItemTimeRowSplitter"></div>
                              <p className="shItemTimeRow">
                                {unit.data.end.length > 10
                                  ? dateStringer.dsToTimeStrip(
                                      unit.data.end,
                                      true
                                    )
                                  : unit.data.end}
                              </p>
                            </div>
                            <div className={`absCalDiv`}>
                              <div className="absCalDivUpper">
                                <div className="absDivCalNip"></div>
                                <div className="absDivCalNip"></div>
                              </div>
                              <div
                                className={`absCalDivLower absCalDivLowerAllTime`}
                              >
                                {/* <p className="calDivUpperThreeLetterDay">{dateStringer.getThreeDayLettersFromDs(unit.startDs)}</p> */}
                                <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                                  {parseInt(unit.startDs.substr(9, 2))}
                                </p>
                                <p className="absCalDivLowerMonth absCalDivLowerMonthReqs absCalDivLowerMonthReqs2_">
                                  {dateStringer.getThreeDayLettersFromDs(
                                    unit.startDs
                                  )}
                                </p>{" "}
                                <p className="absCalDivLowerYear">
                                  {dateStringer.getMonthThreeLettersFromDs(
                                    unit.startDs
                                  )}
                                  {/* {unit.startDs.substr(1, 4)} */}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <p
                            className={`teamClockBreakDurStrip bbcada_ marginTop20`}
                          >
                            {dateStringer.shorten(unit.teamName, 20)}{" "}
                            <img
                              src={rotaTeam}
                              alt="Team"
                              className="teamIconHours opac05"
                            />
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`mgrReqNameTab pendClockName shNameTabRows4 marginLeft3pcx x59858985553`}
                    onClick={() => {
                      memoVals.setShowEditUser((x) => unit.userID);
                    }}
                  >
                    <img
                      src={unit.picUrl || profilePicturePlaceholder}
                      alt={unit.fName}
                      className="itemNameTabImg"
                    />{" "}
                    {unit.fName} {unit.lName}
                    {unit.userID === memoVals.checkUserState.userID ? (
                      <span className="youSpan">(You)</span>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                  <div
                    className={`mgrClockSubBar ${
                      memoVals.mob ? "mgrClockSubBarMob" : ""
                    }
                  pendClockSub teamShiftsSubAppBar `}
                  >
                    <div
                      className={`clocksSchedSubLeft ${
                        memoVals.mob ? "font12px" : ""
                      } `}
                    >
                      {/* <div className={`clockSchedTitle`}>Title here</div> */}
                      <p className="clockSchedValue fontSize14 lineHeight15">
                        {/* <span className="subBarLoggedTitle subBarLoggedTitle2">
                          Requires action
                        </span>{" "} */}
                      </p>
                    </div>
                    <div
                      className={`clocksSchedSubRight ${
                        memoVals.mob ? "flexEnd" : ""
                      }`}
                    >
                      {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                      <p
                        className={`approveClock ${
                          unit.blockApprove ? "invis" : ""
                        } reqActBtn lilacColours`}
                        onClick={() => {
                          memoVals.setOpenManagerShiftModal((x) => {
                            return {
                              shiftID: unit.type === "shift" ? unit.typeID : "",
                              tilID: unit.type !== "shift" ? unit.typeID : "",
                              fName: unit.fName,
                              lName: unit.lName,
                              openedFromShiftsPage: true,
                              type: unit.type,
                            };
                          });
                        }}
                      >
                        Action{" "}
                        <img
                          src={popup}
                          alt="Approve clock card"
                          className="approveCCtickimg reqActpopup x3589456894554"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              );
            }
            if (unit.form) {
              return (
                <div
                  className={`formUnitParent formUnitParentNotifReq  ${
                    (!absenceForms && !absenceSLA) ||
                    ((absenceForms || absenceSLA) && absenceForms)
                      ? ""
                      : "none"
                  }`}
                  index={i}
                >
                  <div className="clockNameAndTeamDiv">
                    <div
                      className="mgrClockNameDiv  pendClockName_ marginLeft3pcx x389498483433"
                      onClick={(e) => {
                        e.stopPropagation();

                        memoVals.setShowEditUser((x) => unit.userID);
                      }}
                    >
                      <img
                        src={unit.picUrl || profilePicturePlaceholder}
                        alt={unit.fName}
                        className="itemNameTabImg"
                      />{" "}
                      {unit.fName} {unit.lName}{" "}
                      {unit.userID === memoVals.checkUserState.userID ? (
                        <span className="youSpan">(You)</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div
                    className={`mgrClockUnit noBorderRadiusBottomRight ${
                      memoVals.mob ? "borderRadius10" : ""
                    } minWidth94`}
                    onClick={() => {
                      memoVals.setShowForm((x) => {
                        return { isMgr: true, formID: unit.formID };
                      });
                    }}
                  >
                    {unit.filledIn && !unit.signedByMgr ? (
                      <div className={`unsubmittedTsBlob clockAwaitBlob`}></div>
                    ) : (
                      ""
                    )}
                    {!unit.filledIn ? (
                      <div className={`unsubmittedTsBlob clockAwaitBlob`}></div>
                    ) : (
                      ""
                    )}
                    <div className="teamClockLeftParent">
                      <div className="tilShiftSection0"></div>
                      <div
                        className={`tilShiftSection1Clock pendingClockSection1 ${
                          window.innerWidth < 1080 && memoVals.full
                            ? "pendingClockSection1CorrectionMidFull"
                            : ""
                        } ${
                          window.innerWidth < 458
                            ? "pendingClockSection1Under458With"
                            : ""
                        }`}
                      >
                        <p
                          className={`tilShiftDs tilShiftDsFirstRow flexStart`}
                        >
                          {/* x222 */}
                          <img
                            src={desktopBurger}
                            alt="Clocked times"
                            className="teamClockRotaClockImg leaveNameBurgerNotifReq"
                          />

                          {unit.title}
                        </p>

                        <p
                          className={`tilShiftDs tilShiftDs flexStart teamClocksLowerRow teamClocksLowerRow2`}
                        >
                          <img
                            src={rotaDur}
                            alt="Clocked times"
                            className="teamClockRotaClockImg teamClockRotaClockImgCoffee"
                          />
                          {dateStringer.formatMinsDurationToHours(unit.mins)}{" "}
                          absent
                        </p>
                        <p
                          className={`tilShiftDs tilShiftDs flexStart teamClocksLowerRow teamClocksLowerRow2 makeBold colour00aaff`}
                        >
                          <img
                            src={rotaPerson}
                            alt="Clocked times"
                            className="teamClockRotaClockImg teamClockRotaClockImgCoffee rotaPersonNotifReq"
                          />
                          Completed absence form
                        </p>
                      </div>
                    </div>
                    <div className={`teamClockRightParent`}>
                      <div className="tilShiftSection2 pendingClockSection2">
                        <div className="tsIllus absFormIllus">
                          <p className="tsIllusTitle">
                            Absence form{" "}
                            {/* <img
                          src={editGrey}
                          alt="Timesheet"
                          className="tsIllusPencil"
                        /> */}
                          </p>
                          <p className="tsIllusValue absFormIllusVal">
                            {unit.questionsLength} question
                            {unit.questionsLength === 1 ? "" : "s"}
                            {/* {unit.itemsLength === 1 ? "" : "s"} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* {clock.oneOfTwo === "two" ? (
                <div className="x485487597252"></div>
              ) : (
                ""
              )}{" "} */}
                  <div
                    className={`mgrClockSubBar ${
                      memoVals.mob ? "mgrClockSubBarMob" : ""
                    } pendClockSub notifReqSubBarReq x45678657`}
                  >
                    <div
                      className={`clocksSchedSubLeft ${
                        memoVals.mob ? "font12px" : ""
                      }`}
                    ></div>
                    <div
                      className={`clocksSchedSubRight  ${
                        memoVals.mob ? "flexEnd" : ""
                      }`}
                    >
                      {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                      <p
                        className={`approveClock x2978457 openBtnReqMgrBtn ${
                          unit.blockApprove
                            ? "unableToApproveOwn greyColours"
                            : ""
                        } ${
                          approving === unit.reqID ? "disabledApprove" : ""
                        } `}
                        onClick={() => {
                          if (unit.blockApprove) {
                            setBlockApprove("form");
                          } else {
                            memoVals.setShowForm((x) => {
                              return { isMgr: true, formID: unit.formID };
                            });
                          }
                        }}
                      >
                        Sign off{" "}
                        <img
                          src={popup}
                          alt="Approve clock card"
                          className="approveCCtickimg x34875478545656 x3589456894554"
                        />
                      </p>
                    </div>
                  </div>

                  {/* - - - - - - - */}
                </div>
              );
            }
          })
        ) : group === "swaps" ? (
          itemsArr.map((swap, i) => {
            // swaps here
            if (swap.swap) {
              return (
                <div className="swapUnitParent noPaddingTop" key={i}>
                  <div
                    className={`swapUnit teamSwapUnit ${
                      swap.mgrApproved ? "swapApprovedGreenLeftBorder" : ""
                    }`}
                  >
                    <div
                      className={`swapBlueBar ${
                        swap.mgrApproved && "swapBlueBarMgrApproved"
                      } ${memoVals.mobModal ? "swapBlueBarMob" : ""}`}
                    ></div>
                    <div className="teamSwapMainContent">
                      <p className="swapUnitLeftTxt">
                        <span className="swapFName">
                          {swap.offeredFName}{" "}
                          {swap.offeredLName ? swap.offeredLName[0] : ""}
                        </span>{" "}
                        wants {swap.wantedFName}{" "}
                        {swap.wantedLName && swap.wantedLName[0]}
                        's{" "}
                        <span className="swapTypeSpan">
                          {swap.wantedType === "til"
                            ? "overtime"
                            : swap.wantedType}
                        </span>{" "}
                        in return for{" "}
                        {swap.offerNothing || swap.deleted ? (
                          <span className="swapTypeSpan">
                            {swap.deleted
                              ? `Nothing. A ${
                                  swap.offeredType === "til"
                                    ? "overtime"
                                    : swap.offeredType
                                } was offered, but it has since been changed by a manager`
                              : " nothing"}
                          </span>
                        ) : (
                          <span className="swapTypeSpan">
                            their{" "}
                            {swap.offeredType === "til"
                              ? "overtime"
                              : swap.offeredType}
                          </span>
                        )}
                      </p>
                      <div
                        className={`swapUnitInnerFlexNotifs ${
                          memoVals.mobModal
                            ? "swapUnitInnerFlexNotifsMobModal"
                            : ""
                        }`}
                      >
                        <div className="swapUnitLeft">
                          <p className="swapUnitLeftName">
                            {dateStringer.possession(swap.wantedFName)}{" "}
                            {swap.wantedType === "til"
                              ? "overtime"
                              : swap.wantedType}
                            :
                          </p>
                          <div className="swapUnitTeamLeftUpperMinimum none"></div>
                          <div
                            className={`swapUnitWantItemDiv ${
                              memoVals.mobModal
                                ? "swapUnitWantItemDivMobModal"
                                : ""
                            }`}
                            onClick={() => {
                              memoVals.setShowEditShiftModal((x) => {
                                return {
                                  itemID: swap.wantedTypeID,
                                  itemType: swap.wantedType,
                                  // itemFName: change.fName,
                                  // itemLName: change.lName,
                                  itemIsNew: false,
                                  userID: swap.wantedUserID,
                                };
                              });

                              // memoVals.setShowMyShiftModal((x) => {
                              //   return {
                              //     unitType: swap.wantedType,
                              //     unitID: swap.wantedTypeID,
                              //     otherUser:
                              //       swap.wantedUserID === memoVals.checkUserState.userID
                              //         ? false
                              //         : true,
                              //   };
                              // });
                            }}
                          >
                            <p
                              className={`swapUnitWantDs ${
                                memoVals.mobModal ? "font12" : ""
                              }`}
                            >
                              <img
                                src={rotaDate}
                                alt="swap rota date"
                                className="rotaDateMySwapsImg"
                              />
                              {!swap.wantedStartDs
                                ? ""
                                : memoVals.mobModal
                                ? `${dateStringer.printedDateFromDs(
                                    swap.wantedStartDs
                                  )} '${swap.wantedStartDs.substr(3, 2)}`
                                : `${dateStringer.printedDateWithYrFromDs(
                                    swap.wantedStartDs,
                                    false,
                                    true
                                  )} '${swap.wantedStartDs.substr(3, 2)}`}
                            </p>
                            <p
                              className={`swapUnitWantDs ${
                                memoVals.mobModal ? "font12" : ""
                              }`}
                            >
                              <img
                                src={rotaClock}
                                alt="swap rota date"
                                className="rotaDateMySwapsImg"
                              />
                              {dateStringer.dsToTimeStrip(swap.wantedStartDs)} -{" "}
                              {dateStringer.dsToTimeStrip(swap.wantedEndDs)}
                            </p>
                            <p
                              className={`swapUnitWantDsSub noBorder ${
                                memoVals.mobModal ? "font12" : ""
                              }`}
                            >
                              <img
                                src={rotaDur}
                                alt="swap rota date"
                                className="rotaDateMySwapsImg rotaDateMySwapsImgDur"
                              />{" "}
                              {dateStringer.formatMinsDurationToHours(
                                swap.wantedDurMins
                              )}
                            </p>
                          </div>
                          {/* {swap.wantTags && swap.wantTags[0] ? (
                    <div className="wantShiftTags">
                      <p className="wantShiftTagsTitle">Tags:</p>
                      <div className="wantShiftTagsList">{generateWantTags}</div>
                    </div>
                  ) : (
                    ""
                  )} */}
                        </div>
                        <div
                          className={`teamSwapUnitMiddle ${
                            memoVals.mobModal ? "hidden" : ""
                          }`}
                        >
                          <img
                            src={inReturnForArrow}
                            alt="In return for"
                            className="inReturnForArrowImg"
                          />
                          {/* <p
                    className={`inReturnForTxt ${
                      memoVals.mobModal ? "font12px" : ""
                    }`}
                  >
                    in return for
                  </p> */}
                          <img
                            src={inReturnForArrow}
                            alt="In return for"
                            className="inReturnForArrowImg flipH"
                          />
                        </div>

                        <div
                          className={`swapUnitRight ${
                            swap.autoApprovable
                              ? !memoVals.mobModal
                                ? "paddingBottom26px"
                                : "paddingBottom82px"
                              : ""
                          }`}
                        >
                          <p className="swapUnitRightName">
                            {swap.offerNothing || swap.deleted
                              ? ""
                              : `${dateStringer.possession(
                                  swap.offeredFName
                                )} ${
                                  swap.offeredType === "til"
                                    ? "overtime"
                                    : swap.offeredType
                                }:`}
                          </p>
                          {swap.offerNothing || swap.deleted ? (
                            <div
                              className={`nothingSwapItem ${
                                !memoVals.mobModal
                                  ? "nothingSwapItemTeam"
                                  : "nothingSwapItemMob"
                              }`}
                            >
                              Nothing
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="swapUnitTeamRightUpperMinimum none">
                            {swap.offerNothing || swap.deleted ? (
                              <p
                                className={`offerSwapNothingTxt ${
                                  swap.offerNothing ? "x9242323" : ""
                                }`}
                              >
                                {/* {swap.deleted
                          ? `Nothing. A ${
                              swap.offeredType === "til"
                                ? "overtime"
                                : swap.offeredType
                            } was offered, but it has since been changed by a manager`
                          : " ...no shift offered"} */}
                              </p>
                            ) : (
                              <p className="swapUnitRightTxt swap500">
                                {/* ...their{" "}
                        <span className="swapTypeSpan">
                          {swap.offeredType === "til"
                            ? "overtime"
                            : swap.offeredType}
                        </span>
                        : */}
                              </p>
                            )}
                          </div>

                          {swap.offerNothing || swap.deleted ? (
                            <img
                              src={greyCross}
                              alt="Nothing offered"
                              className="teamSwapOfferNothingCross"
                            />
                          ) : (
                            <div
                              className={`swapUnitWantItemDiv ${
                                memoVals.mobModal
                                  ? "swapUnitWantItemDivMobModal"
                                  : ""
                              }`}
                              onClick={() => {
                                // console.log({
                                //   itemID: swap.offeredTypeID,
                                //   itemType: swap.offeredType,

                                //   userID: swap.offeredUserID,
                                // });
                                memoVals.setShowEditShiftModal((x) => {
                                  return {
                                    itemID: swap.offeredTypeID,
                                    itemType: swap.offeredType,
                                    // itemFName: change.fName,
                                    // itemLName: change.lName,
                                    itemIsNew: false,
                                    userID: swap.offeredUserID,
                                  };
                                });
                              }}
                            >
                              <p
                                className={`swapUnitWantDs txtAlignRight ${
                                  memoVals.mobModal ? "font12" : ""
                                }`}
                              >
                                <img
                                  src={rotaDate}
                                  alt="swap rota date"
                                  className="rotaDateMySwapsImg"
                                />{" "}
                                {!swap.offeredStartDs
                                  ? ""
                                  : memoVals.mobModal
                                  ? `${dateStringer.printedDateFromDs(
                                      swap.offeredStartDs
                                    )} '${swap.offeredStartDs.substr(3, 2)}`
                                  : `${dateStringer.printedDateWithYrFromDs(
                                      swap.offeredStartDs,
                                      false,
                                      true
                                    )} '${swap.offeredStartDs.substr(3, 2)}`}
                              </p>
                              <p
                                className={`swapUnitWantDs txtAlignRight ${
                                  memoVals.mobModal ? "font12" : ""
                                }`}
                              >
                                <img
                                  src={rotaClock}
                                  alt="swap rota date"
                                  className="rotaDateMySwapsImg"
                                />{" "}
                                {dateStringer.dsToTimeStrip(
                                  swap.offeredStartDs
                                )}{" "}
                                -{" "}
                                {dateStringer.dsToTimeStrip(swap.offeredEndDs)}
                              </p>
                              <p
                                className={`swapUnitWantDsSub txtAlignRight noBorder ${
                                  memoVals.mobModal ? "font12" : ""
                                }`}
                              >
                                <img
                                  src={rotaDate}
                                  alt="swap rota date"
                                  className="rotaDateMySwapsImg rotaDateMySwapsImgDur"
                                />{" "}
                                {dateStringer.formatMinsDurationToHours(
                                  swap.offeredDurMins
                                )}
                              </p>
                            </div>
                          )}
                          {/* {swap.offerTags && swap.offerTags[0] ? (
                    <div className="wantShiftTags">
                      <p className="wantShiftTagsTitle txtAlignRight">Tags:</p>
                      <div className="wantShiftTagsList">{generateOfferTags}</div>
                    </div>
                  ) : (
                    ""
                  )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`swapUnitCtaBar teamSwapUnitCtaBar ${
                      swap.selfMade ? "x92478922" : ""
                    }`}
                  >
                    <p className="swapDsMade">
                      Requested {dateStringer.dsToTimeStrip(swap.dsMade)}
                      <br />
                      {dateStringer.printedDateFromDs(swap.dsMade)}
                    </p>
                    <div
                      className={`swapCtaBtns marginRight10 ${
                        swap.autoApprovable ? "flexEnd" : ""
                      }`}
                    >
                      {swap.mgrApproved || swap.blockApprove ? (
                        ""
                      ) : (
                        <p
                          className="swapDecline"
                          onClick={() => {
                            deny(swap, itemsArr);
                          }}
                        >
                          Deny
                        </p>
                      )}
                      {swap.mgrApproved ? (
                        ""
                      ) : swap.blockApprove ? (
                        <p
                          className="approveSwapBlocked"
                          onClick={() => {
                            setShowCannotManageOwnShift({
                              show: true,
                              teamName: swap.teamName,
                            });
                          }}
                        >
                          You cannot approve your own swaps as a manager
                        </p>
                      ) : (
                        <p
                          className={`teamSwapAccept lightBlueColours ${
                            swap.autoApprovable ? "none" : ""
                          } ${swap.me ? "approveAndAcceptBtn" : ""}`}
                          onClick={() => {
                            axios
                              .post(
                                `${serverURL}/approve-swap`,
                                {
                                  wantType: swap.wantedType,
                                  shiftID: swap.wantedTypeID,
                                  wantUserID: swap.offeredUserID,
                                  nowDs: dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                  returnShiftData:
                                    window.location.href.includes("rota")
                                      ? true
                                      : false,
                                },
                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message.includes("logout")) {
                                  window.location.href = "/signin";
                                }
                                if (response.data.message === "success") {
                                  setCountData({
                                    staffSwaps: countData.staffSwaps - 1,
                                  });

                                  if (response.data.mgrApproved) {
                                    let newArr = [];

                                    itemsArr.forEach((sw) => {
                                      if (sw.id !== swap.id) {
                                        newArr.push(sw);
                                      }
                                    });

                                    setItemsArr(sortSwaps(newArr));
                                  } else {
                                    if (response.data.swapInitiated) {
                                      // remove it because the swap has been initiated
                                      let newArr = [];

                                      itemsArr.forEach((sw) => {
                                        if (sw.id !== swap.id) {
                                          newArr.push(sw);
                                        }
                                      });

                                      setItemsArr(sortSwaps(newArr));

                                      if (
                                        window.location.href.includes("rota")
                                      ) {
                                        memoVals.setDynamicSwap((x) => {
                                          return {
                                            getRidOfTypeIDs:
                                              response.data.getRidOfTypeIDs,
                                            newItem1: response.data.newItem1,
                                            newItem2: response.data.newItem2,
                                          };
                                        });
                                      }
                                    }
                                  }
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });

                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: `Approved swap between ${swap.wantedFName} and ${swap.offeredFName}`,
                                colour: "green",
                                duration: 4000,
                                ts: new Date().getTime(),
                              };
                            });
                          }}
                        >
                          Approve
                          {swap.me ? <br /> : ""}
                          {swap.me ? "& accept" : ""}
                        </p>
                      )}
                      {swap.mgrApproved ? (
                        <div className="swapApprovedAndDeclineDiv">
                          <div
                            className={`swapMgrApprovedTxt ${
                              swap.selfMade ? "x2984212_" : ""
                            }`}
                          >
                            {swap.selfMade ? "Auto-approved" : "Approved"} by:
                            {swap.selfMade ? <br /> : " "}
                            {swap.mgrApproved}
                            {/* <img
                          src={unitTick}
                          className="swapAcceptTickImg"
                          alt="Accepted"
                        /> */}
                          </div>
                          <div
                            className="declineSwapOnceApproved"
                            onClick={() => {
                              setSureDeny({
                                show: true,
                                fName1: swap.offeredFName,
                                fName2: swap.wantedFName,
                                item: swap,
                                items: swaps,
                              });
                            }}
                          >
                            Deny
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {swap.wantedApproved ? (
                    <div className="usrApprovedSwap">
                      {swap.wantedFName} has accepted{" "}
                      <img
                        src={unitTick}
                        className="swapAcceptTickImg"
                        alt="Accepted"
                      />
                    </div>
                  ) : (
                    <div
                      className={`usrApprovedSwap notYetAccept ${
                        memoVals.mobModal ? "mobNotYetAccept" : ""
                      } ${swap.selfMade ? "x2498293" : ""}`}
                    >
                      {swap.wantedFName} yet to accept{" "}
                    </div>
                  )}
                  {swap.autoApprovable ? (
                    <div
                      className={`mgrSwapApprovalNotRequired ${
                        !memoVals.mobModal
                          ? "mgrSwapApprovalNotRequiredNonMob"
                          : ""
                      } ${swap.selfMade ? "x29845922" : ""}`}
                    >
                      Manager approval not required
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            }
          })
        ) : group === "changes" ? (
          itemsArr.map((change, i) => {
            if (change.ch) {
              if (!change.split) {
                if (!change.takeTilRequest) {
                  return (
                    <div className="shiftChangeRowParent" key={i}>
                      {!change.approved && !change.declined && (
                        <div
                          className={`shiftChangeBlueBar shiftChangeBlueBar2 ${
                            change.drop
                              ? "shiftChangeBlueBarDrop shiftChangeBlueBarDrop2"
                              : ""
                          }`}
                        ></div>
                      )}

                      <div
                        className={`shiftChangeRow shiftChangeRow2 selectedShiftChangeRow ${
                          !change.approved && !change.declined
                            ? "undeciedBorderLeftChange"
                            : ""
                        }
                
                ${
                  !change.approved && change.declined
                    ? "declinedBorderLeftChange"
                    : ""
                }
    
                ${
                  change.approved && !change.declined
                    ? "approvedBorderLeftChange"
                    : ""
                } ${memoVals.mob ? "borderRadius10" : ""} marginBottom0`}
                        key={change.editID}
                      >
                        <div className="shiftChangeTitleAndNote paddingLeft0">
                          {change.drop ? (
                            <p className="shiftChangeTitleTilOrChange">
                              <img
                                src={
                                  change.profPicUrl || profilePicturePlaceholder
                                }
                                alt={change.fName}
                                className="shChProPic"
                              />
                              Drop request
                            </p>
                          ) : (
                            <p className="shiftChangeTitleTilOrChange">
                              <img
                                src={
                                  change.profPicUrl || profilePicturePlaceholder
                                }
                                alt={change.fName}
                                className="shChProPic"
                              />
                              {change.newType === "shift"
                                ? "Shift"
                                : `${
                                    change.newType === "overtime"
                                      ? "Overtime"
                                      : "Overtime (time in lieu)"
                                  }`}{" "}
                              change request
                            </p>
                          )}
                          <p></p>
                        </div>

                        <div className="shiftChangeTitleRow paddingLeft0">
                          <p className="shiftChangeType">
                            {change.you ? (
                              <span className="shiftTypeSpan">You</span>
                            ) : (
                              <span className="shiftTypeSpan">{`${
                                change.fName
                              } ${change.lName ? change.lName[0] : ""}`}</span>
                            )}{" "}
                            {change.drop
                              ? `requested to drop ${
                                  change.you ? "your" : "their"
                                } `
                              : `requested a change to ${
                                  change.you ? "your" : "their"
                                } `}
                            <span className="shiftTypeSpan">
                              {change.newType === "shift"
                                ? "shift"
                                : `${
                                    change.newType === "overtime"
                                      ? "overtime"
                                      : "overtime (time in lieu)"
                                  }`}
                            </span>{" "}
                          </p>

                          {/* {change.drop && change.coverFName && !change.declined ? (
                          <div className="dropReqSuggOtherUser">
                            <p>
                              {change.you ? "You have" : `${change.fName} has`} put{" "}
                              {change.coverFName} {change.coverLName[0]} forward to
                              cover it
                            </p>
                            <img
                              src={
                                change.coverProfPicUrl || profilePicturePlaceholder
                              }
                              alt="Swap"
                              className="shChSwapImg"
                            />
                          </div>
                        ) : (
                          ""
                        )} */}
                        </div>
                        <div className="changeTitleRowSubBar paddingLeft0">
                          <p className="shiftChangeMadeDate">
                            <span className="reqOnSpan">
                              {`${
                                !change.approved && !change.declined
                                  ? "Requested"
                                  : `${
                                      change.approved && !change.declined
                                        ? "Approved"
                                        : "Declined"
                                    }`
                              }`}{" "}
                              on
                            </span>{" "}
                            {dateStringer.printedDateFromDs(change.dateMade)}
                            <span className="reqOnSpan"> at</span>{" "}
                            {dateStringer.tsToHHMM(
                              dateStringer.createTimestampFromString(
                                change.dateMade
                              )
                            )}
                          </p>
                          <p
                            className={`${
                              change.requested
                                ? `${
                                    change.approved && !change.declined
                                      ? "changeStatusApproved"
                                      : `${
                                          change.declined
                                            ? "changeStatusDeclined"
                                            : "changeStatus"
                                        }`
                                  } `
                                : "none"
                            }`}
                          >
                            {`${
                              change.approved && !change.declined
                                ? "Approved"
                                : `${change.declined ? "Declined" : "Pending"}`
                            }`}
                          </p>
                        </div>
                        <div
                          className={`changeRowMain ${
                            change.requested && change.declined
                              ? "declinedChange"
                              : ""
                          }`}
                        >
                          <div
                            className={`changeRowLeft marginLeft0  paddingLeft0 ${
                              memoVals.mob ? "changeRowLeft2Mob" : ""
                            }`}
                            onClick={() => {
                              memoVals.setShowEditShiftModal((x) => {
                                return {
                                  itemID: change.shiftID,
                                  itemType: change.newType || change.type,
                                  itemFName: change.fName,
                                  itemLName: change.lName,
                                  itemIsNew: false,
                                  userID: change.userID,
                                };
                              });
                            }}
                          >
                            <p className="oldShiftDate textAlignLeft">
                              <img
                                src={rotaDate}
                                alt="Shift change date"
                                className="shShIcon shShIcon1"
                              />{" "}
                              {dateStringer.printedDateWithYrFromDs(
                                change.oldDate,
                                false,
                                memoVals.mob ? true : false
                              )}
                            </p>
                            <p className="oldShiftTimes textAlignLeft">
                              <img
                                src={rotaClock}
                                alt="Shift change date"
                                className="shShIcon"
                              />{" "}
                              {change.oldStart} - {change.oldEnd}
                            </p>
                            <p className="oldShiftBrk textAlignLeft">
                              <img
                                src={rotaCoffee}
                                alt="Shift change date"
                                className="shShIcon"
                              />{" "}
                              {dateStringer.formatMinsDurationToHours(
                                change.oldBrk
                              )}
                            </p>
                            <p className="oldShiftBrk textAlignLeft bbcada_">
                              <img
                                src={rotaTeam}
                                alt="Shift change date"
                                className="shShIcon"
                              />{" "}
                              {change.teamName || "Unknown team"}
                            </p>
                          </div>

                          <div className="changeRowMiddle">
                            <img
                              src={shiftChangeArrow}
                              alt="arrow"
                              className={`changeShiftArrowImg ${
                                change.drop ? "invis" : ""
                              }`}
                            />
                          </div>
                          {change.drop && change.coverUserID ? (
                            !change.declined ? (
                              <div className="dropReqSuggOtherUser">
                                <p>
                                  {change.you
                                    ? "You have"
                                    : `${change.fName} has`}{" "}
                                  put {change.coverFName} {change.coverLName[0]}{" "}
                                  forward to cover it
                                </p>
                                <img
                                  src={
                                    change.coverProfPicUrl ||
                                    profilePicturePlaceholder
                                  }
                                  alt="Swap"
                                  className="shChSwapImg"
                                  onClick={() => {
                                    memoVals.setShowEditUser(
                                      (x) => change.coverUserID
                                    );
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )
                          ) : (
                            <div
                              className={`changeRowRight ${
                                change.drop ? "invis" : ""
                              }`}
                              onClick={() => {
                                memoVals.setShowEditShiftModal((x) => {
                                  return {
                                    itemID: change.shiftID,
                                    itemType: change.newType || change.type,
                                    itemFName: change.fName,
                                    itemLName: change.lName,
                                    itemIsNew: false,
                                    userID: change.userID,
                                  };
                                });
                              }}
                            >
                              <p
                                className={`oldShiftDate newShiftDate ${
                                  change.oldDate !== change.newDate
                                    ? "colour0077ff"
                                    : ""
                                }`}
                              >
                                {dateStringer.printedDateWithYrFromDs(
                                  change.newDate,
                                  false,
                                  memoVals.mob ? true : false
                                )}

                                <img
                                  src={rotaDate}
                                  alt="TOIL to use"
                                  className="shChImgRight"
                                />
                              </p>
                              <p
                                className={`oldShiftDate newShiftDate x239499211`}
                              >
                                <span
                                  className={
                                    change.newStart !== change.oldStart
                                      ? "colour0077ff"
                                      : ""
                                  }
                                >
                                  {change.newStart}
                                </span>{" "}
                                <span className="newShTimesSpaceSpan2"></span>-{" "}
                                <span className="newShTimesSpaceSpan2"></span>
                                <span
                                  className={
                                    change.newEnd !== change.oldEnd
                                      ? "colour0077ff"
                                      : ""
                                  }
                                >
                                  {change.newEnd}
                                </span>
                                <img
                                  src={rotaClock}
                                  alt="TOIL to use"
                                  className="shChImgRight"
                                />
                              </p>
                              <div
                                className={`tilTimesStrip tilTimesStripNewSh x2202055 _ ${
                                  change.newBrk !== change.oldBrk
                                    ? "colour0077ff"
                                    : ""
                                }`}
                              >
                                {/* <p className="tilStripTitle">TOIL: </p> */}
                                {dateStringer.formatMinsDurationToHours(
                                  change.newBrk
                                )}
                                <img
                                  src={rotaCoffee}
                                  alt="TOIL to use"
                                  className="rotaClockNewShiMG x200205552"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="decisionRequestShiftDiv">
                          {change.note ? (
                            <p className="changeNoteDiv">
                              <span className="changeNoteTitleSpan">
                                {change.fName}:{" "}
                              </span>
                              {change.note}
                            </p>
                          ) : (
                            ""
                          )}
                          <div className="deleteChangeUnit paddingLeft2pc">
                            <div className="deleteChangeUnitLeft">
                              <img
                                src={rotaDur}
                                alt="Old new duration"
                                className="chShRotaDur"
                              />{" "}
                              {dateStringer.formatMinsDurationToHours(
                                change.oldDuration,
                                false,
                                true
                              )}{" "}
                              <img
                                src={rightArr}
                                alt="Duration"
                                className="oldDurRightArrImg"
                              />{" "}
                              <span
                                className={`${
                                  change.drop ||
                                  change.newDuration !== change.oldDuration
                                    ? "colour0077ff"
                                    : ""
                                }`}
                              >
                                {dateStringer.formatMinsDurationToHours(
                                  change.drop ? 0 : change.newDuration,
                                  false,
                                  true
                                )}
                              </span>
                              {/* <p className="oldChangeUnitDur">
                              <span className="oldDurationTitleSpan">
                                Old duration:&nbsp;
                              </span>
                              {dateStringer.formatMinsDurationToHours(
                                change.oldDuration,
                                false,
                                true
                              )}
                            </p>
                            <p className="oldChangeUnitDur">
                              <span className="oldDurationTitleSpan">
                                New duration:
                              </span>{" "}
                              {dateStringer.formatMinsDurationToHours(
                                change.newDuration,
                                false,
                                true
                              )}
                            </p> */}
                            </div>
                            {change.approved || change.declined ? (
                              <p
                                className={`${
                                  change.approved || change.declined
                                    ? "shiftEditDecidedBy"
                                    : "none"
                                }`}
                              >
                                <span className="decidedBySpan">{`${
                                  change.approved
                                    ? "Approved by "
                                    : "Declined by "
                                }`}</span>
                                <br />
                                {change.decidedBy || "unknown"}
                              </p>
                            ) : change.blockApprove ? (
                              <p
                                className="approveSwapBlocked"
                                onClick={() => {
                                  setShowCannotManageOwnShift({
                                    show: true,
                                    teamName: change.teamName,
                                  });
                                }}
                              >
                                You cannot approve your own swaps as a manager
                              </p>
                            ) : (
                              <div
                                className={`approveOrDeclineChangeReqDiv ${
                                  memoVals.mob
                                    ? "approveOrDeclineChangeReqDivMob"
                                    : ""
                                } ${shiftReqsLoading ? "disabled opac05" : ""}`}
                              >
                                <div className="approveDecShDropParent">
                                  <p
                                    className="approveShChangeReqBtn"
                                    onClick={() => {
                                      // setApproveLoading(true);
                                      let today00 = new Date();
                                      today00.setHours(0, 0, 0, 0);
                                      setShiftReqsLoading(true);
                                      let newArr = [];
                                      itemsArr.forEach((obj) => {
                                        if (
                                          obj.editID !== change.editID ||
                                          change.takeTilRequest
                                        ) {
                                          newArr.push(obj);
                                        }
                                      });

                                      // let qty =
                                      //   countData.shiftEdits +
                                      //   countData.ttrs +
                                      //   countData.drops;
                                      if (
                                        dateStringer.createTimestampFromString(
                                          change.oldDate
                                        ) >= today00.getTime()
                                      ) {
                                        if (change.drop) {
                                          setCountData({
                                            drops: countData.drops - 1,
                                          });
                                        } else {
                                          setCountData({
                                            shiftEdits:
                                              countData.shiftEdits - 1,
                                          });
                                        }

                                        // qty--;

                                        // setReqQty(qty);
                                      }

                                      setItemsArr(newArr);
                                      axios
                                        .post(
                                          `${serverURL}/approve-or-decline-shift-edit`,
                                          {
                                            editID: change.editID,
                                            userID: change.userID,
                                            decision: "approve",
                                            nowDs:
                                              dateStringer.createStringFromTimestamp(
                                                new Date().getTime()
                                              ),
                                            coverUserID:
                                              change.drop && change.coverUserID
                                                ? true
                                                : "",
                                          },

                                          {
                                            withCredentials: true,
                                            credentials: "include",
                                          }
                                        )
                                        .then((response) => {
                                          if (
                                            response.data.message === "success"
                                          ) {
                                            // setApproveLoading(false);

                                            let newArr = [];
                                            itemsArr.forEach((obj) => {
                                              if (
                                                obj.editID !== change.editID ||
                                                change.takeTilRequest
                                              ) {
                                                newArr.push(obj);
                                              }
                                            });

                                            // let qty =
                                            //   countData.shiftEdits +
                                            //   countData.ttrs +
                                            //   countData.drops;
                                            if (
                                              dateStringer.createTimestampFromString(
                                                change.oldDate
                                              ) >= today00.getTime()
                                            ) {
                                              if (change.drop) {
                                                setCountData({
                                                  drops: countData.drops - 1,
                                                });
                                              } else {
                                                setCountData({
                                                  shiftEdits:
                                                    countData.shiftEdits - 1,
                                                });
                                              }
                                              // qty--;

                                              // setReqQty(qty);
                                            }
                                            setItemsArr(newArr);
                                            setTimeout(() => {
                                              setShiftReqsLoading(false);
                                            }, 100);

                                            // setUpdateShiftReqs(!memoVals.updateShiftReqs);
                                          }

                                          if (
                                            response.data.message.includes(
                                              "no longer exist"
                                            )
                                          ) {
                                            // setApproveLoading(false);

                                            let newArr = [];
                                            itemsArr.forEach((obj) => {
                                              if (
                                                obj.editID !== change.editID ||
                                                change.takeTilRequest
                                              ) {
                                                newArr.push(obj);
                                              }
                                            });

                                            // let qty =
                                            //   countData.shiftEdits +
                                            //   countData.ttrs +
                                            //   countData.drops;
                                            if (
                                              dateStringer.createTimestampFromString(
                                                change.oldDate
                                              ) >= today00.getTime()
                                            ) {
                                              if (change.drop) {
                                                setCountData({
                                                  drops: countData.drops - 1,
                                                });
                                              } else {
                                                setCountData({
                                                  shiftEdits:
                                                    countData.shiftEdits - 1,
                                                });
                                              }
                                              // qty--;

                                              // setReqQty(qty);
                                            }
                                            setItemsArr(newArr);
                                            setShiftReqsLoading(false);
                                          }
                                        })
                                        .catch((err) => {
                                          console.error(err);
                                        });
                                    }}
                                  >
                                    Approve{" "}
                                    {change.drop && change.coverUserID
                                      ? "cover"
                                      : ""}
                                    <img
                                      src={whiteTick}
                                      alt="Approve"
                                      className="shAppWhiteTickImg"
                                    />
                                  </p>

                                  {change.drop && change.coverUserID ? (
                                    <p
                                      className="approveShChangeReqBtn dropOnlyApp"
                                      onClick={() => {
                                        // setApproveLoading(true);
                                        setShiftReqsLoading(true);
                                        let newArr = [];
                                        itemsArr.forEach((obj) => {
                                          if (
                                            obj.editID !== change.editID ||
                                            change.takeTilRequest
                                          ) {
                                            newArr.push(obj);
                                          }
                                        });
                                        // let qty =
                                        //   countData.shiftEdits +
                                        //   countData.ttrs +
                                        //   countData.drops;
                                        let today00 = new Date();
                                        today00.setHours(0, 0, 0, 0);
                                        if (
                                          dateStringer.createTimestampFromString(
                                            change.oldDate
                                          ) >= today00.getTime()
                                        ) {
                                          if (change.drop) {
                                            setCountData({
                                              drops: countData.drops - 1,
                                            });
                                          } else {
                                            setCountData({
                                              shiftEdits:
                                                countData.shiftEdits - 1,
                                            });
                                          }
                                          // qty--;

                                          // setReqQty(qty);
                                        }
                                        setItemsArr(newArr);
                                        axios
                                          .post(
                                            `${serverURL}/approve-or-decline-shift-edit`,
                                            {
                                              editID: change.editID,
                                              userID: change.userID,
                                              decision: "approve",
                                              nowDs:
                                                dateStringer.createStringFromTimestamp(
                                                  new Date().getTime()
                                                ),
                                              coverUserID: false,
                                            },
                                            {
                                              withCredentials: true,
                                              credentials: "include",
                                            }
                                          )
                                          .then((response) => {
                                            if (
                                              response.data.message ===
                                              "success"
                                            ) {
                                              // setApproveLoading(false);
                                              let newArr = [];
                                              itemsArr.forEach((obj) => {
                                                if (
                                                  obj.editID !==
                                                    change.editID ||
                                                  change.takeTilRequest
                                                ) {
                                                  newArr.push(obj);
                                                }
                                              });
                                              // let qty =
                                              //   countData.shiftEdits +
                                              //   countData.ttrs +
                                              //   countData.drops;

                                              if (
                                                dateStringer.createTimestampFromString(
                                                  change.oldDate
                                                ) >= today00.getTime()
                                              ) {
                                                if (change.drop) {
                                                  setCountData({
                                                    drops: countData.drops - 1,
                                                  });
                                                } else {
                                                  setCountData({
                                                    shiftEdits:
                                                      countData.shiftEdits - 1,
                                                  });
                                                }
                                                // qty--;

                                                // setReqQty(qty);
                                              }
                                              setItemsArr(newArr);
                                              setTimeout(() => {
                                                setShiftReqsLoading(false);
                                              }, 100);
                                              // setUpdateShiftReqs(!memoVals.updateShiftReqs);
                                            }
                                            if (
                                              response.data.message.includes(
                                                "no longer exist"
                                              )
                                            ) {
                                              // setApproveLoading(false);
                                              let newArr = [];
                                              itemsArr.forEach((obj) => {
                                                if (
                                                  obj.editID !==
                                                    change.editID ||
                                                  change.takeTilRequest
                                                ) {
                                                  newArr.push(obj);
                                                }
                                              });
                                              // let qty =
                                              //   countData.shiftEdits +
                                              //   countData.ttrs +
                                              //   countData.drops;

                                              if (
                                                dateStringer.createTimestampFromString(
                                                  change.oldDate
                                                ) >= today00.getTime()
                                              ) {
                                                if (change.drop) {
                                                  setCountData({
                                                    drops: countData.drops - 1,
                                                  });
                                                } else {
                                                  setCountData({
                                                    shiftEdits:
                                                      countData.shiftEdits - 1,
                                                  });
                                                }
                                                // qty--;

                                                // setReqQty(qty);
                                              }
                                              setItemsArr(newArr);
                                              setShiftReqsLoading(false);
                                            }
                                          })
                                          .catch((err) => {
                                            console.error(err);
                                          });
                                      }}
                                    >
                                      Drop only
                                      <img
                                        src={whiteTick}
                                        alt="Approve"
                                        className="shAppWhiteTickImg"
                                      />
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div
                                  className="declineShChangeReqBtn"
                                  onClick={() => {
                                    // setApproveLoading(true);

                                    setSureDeclineChange({
                                      show: true,
                                      change: true,
                                      toil: false,
                                      editID: change.editID,
                                      userID: change.userID,
                                      ds: change.oldDate || change.oldDs,
                                    });
                                  }}
                                >
                                  {memoVals.mob ? (
                                    <img
                                      src={lightGreyCancel}
                                      alt="Decline"
                                      className="x293298484994"
                                    />
                                  ) : (
                                    "Decline"
                                  )}
                                </div>
                              </div>
                            )}
                          </div>

                          {/* end of sub bar */}
                        </div>
                        {/* end of decision sub bar */}

                        {/* <p>{change.oldDate}</p> */}
                      </div>
                    </div>
                  );
                }

                if (change.takeTilRequest) {
                  return (
                    <div className="shiftChangeRowParent" key={i}>
                      {!change.approved && !change.declined && (
                        <div className="shiftChangeBlueBar shiftChangeBlueBar2 shiftChangeBlueBarToil shiftChangeBlueBarToil2"></div>
                      )}
                      <div
                        className={`shiftChangeRow shiftChangeRow2 ${
                          !change.approved && !change.declined
                            ? "undeciedBorderLeftChange"
                            : ""
                        }
                
                ${
                  !change.approved && change.declined
                    ? "declinedBorderLeftChange"
                    : ""
                }
    
                ${
                  change.approved && !change.declined
                    ? "approvedBorderLeftChange"
                    : ""
                } ${memoVals.mob ? "borderRadius10" : ""}
                
                `}
                        key={change.takeTilID}
                      >
                        <div className="shiftChangeTitleAndNote paddingLeft0">
                          <p className="shiftChangeTitleTilOrChange">
                            <img
                              src={
                                change.profPicUrl || profilePicturePlaceholder
                              }
                              alt={change.fName}
                              className="shChProPic"
                            />
                            Request to use TOIL
                          </p>
                          <p></p>
                        </div>{" "}
                        <div className="shiftChangeTitleRow paddingLeft0">
                          <p className="shiftChangeType">
                            {change.you ? (
                              <span className="shiftTypeSpan">You</span>
                            ) : (
                              <span className="shiftTypeSpan">{`${change.fName} ${change.lName[0]}`}</span>
                            )}{" "}
                            asked to shortern {change.you ? "your" : "their"}{" "}
                            <span className="shiftTypeSpan">
                              {change.type === "shift" ? "shift" : "overtime"}
                            </span>{" "}
                            with {change.you ? "your" : "their"} TOIL allowance
                          </p>
                        </div>{" "}
                        <div className="changeTitleRowSubBar paddingLeft0">
                          <p className="shiftChangeMadeDate">
                            <span className="reqOnSpan">
                              {`${
                                !change.approved && !change.declined
                                  ? "Requested"
                                  : `${
                                      change.approved && !change.declined
                                        ? "Approved"
                                        : "Declined"
                                    }`
                              }`}{" "}
                              on
                            </span>{" "}
                            {dateStringer.printedDateFromDs(change.dateMade)}
                            <span className="reqOnSpan"> at</span>{" "}
                            {dateStringer.tsToHHMM(
                              dateStringer.createTimestampFromString(
                                change.dateMade
                              )
                            )}
                          </p>
                          <p
                            className={`${
                              change.requested
                                ? `${
                                    change.approved && !change.declined
                                      ? "changeStatusApproved"
                                      : `${
                                          change.declined
                                            ? "changeStatusDeclined"
                                            : "changeStatus"
                                        }`
                                  } `
                                : "none"
                            }`}
                          >
                            {`${
                              change.approved && !change.declined
                                ? "Approved"
                                : `${change.declined ? "Declined" : "Pending"}`
                            }`}
                          </p>
                        </div>
                        {!change.approved && !change.declined ? (
                          <div className="changeTitleRowSubBar paddingLeft0 toilAllowRow">
                            <div className="shiftChangeMadeDate">
                              {change.tilMins <= change.availableTil ? (
                                <span className="sufficientTil">
                                  Sufficient allowance{" "}
                                  <img
                                    src={unitTick}
                                    alt="Sufficient"
                                    className="sufficientTilTick"
                                  />
                                </span>
                              ) : (
                                <span className="insufficientTil">
                                  Insufficient allowance{" "}
                                  <img
                                    src={unitCross}
                                    alt="Sufficient"
                                    className="insufficientTilTick"
                                  />
                                </span>
                              )}{" "}
                              <p
                                className={`x2831981s1981 ${
                                  change.availableTil <= 0 ? "ba0000" : ""
                                }`}
                              >
                                {dateStringer.formatMinsDurationToHours(
                                  change.availableTil,
                                  false,
                                  true
                                )}{" "}
                                remaining
                              </p>
                            </div>
                            {/* <p
                    className={`${
                      change.requested
                        ? `${
                            change.approved && !change.declined
                              ? "changeStatusApproved"
                              : `${
                                  change.declined
                                    ? "changeStatusDeclined"
                                    : "changeStatus"
                                }`
                          } `
                        : "none"
                    }`}
                  >
                    {`${
                      change.approved && !change.declined
                        ? "Approved"
                        : `${change.declined ? "Declined" : "Pending"}`
                    }`}
                  </p> */}
                          </div>
                        ) : (
                          ""
                        )}
                        {/*  */}
                        <div
                          className={`changeRowMain ${
                            change.requested && change.declined
                              ? "declinedChange"
                              : ""
                          }`}
                        >
                          <div
                            className={`changeRowLeft marginLeft0  paddingLeft0 ${
                              memoVals.mob ? "changeRowLeft2Mob" : ""
                            }`}
                            onClick={() => {
                              memoVals.setShowEditShiftModal((x) => {
                                return {
                                  itemID: change.shiftID,
                                  itemType: change.newType || change.type,
                                  itemFName: change.fName,
                                  itemLName: change.lName,
                                  itemIsNew: false,
                                  userID: change.userID,
                                };
                              });
                            }}
                          >
                            <p className="oldShiftDate textAlignLeft">
                              <img
                                src={rotaDate}
                                alt="Shift change date"
                                className="shShIcon shShIcon1"
                              />{" "}
                              {dateStringer.printedDateWithYrFromDs(
                                change.oldDs,
                                false,
                                memoVals.mob ? true : false
                              )}
                            </p>
                            <p className="oldShiftTimes textAlignLeft">
                              <img
                                src={rotaClock}
                                alt="Shift change date"
                                className="shShIcon"
                              />{" "}
                              {change.oldStart} - {change.oldEnd}
                            </p>
                            <p className="oldShiftBrk textAlignLeft">
                              <img
                                src={rotaCoffee}
                                alt="Shift change date"
                                className="shShIcon"
                              />{" "}
                              {change.oldBrk}m break
                            </p>
                            <p className="oldShiftBrk textAlignLeft bbcada_">
                              <img
                                src={rotaTeam}
                                alt="Shift change date"
                                className="shShIcon"
                              />{" "}
                              {change.teamName || "Unknown team"}
                            </p>
                          </div>
                          <div className="changeRowMiddle">
                            <img
                              src={`${
                                change.requested
                                  ? `${
                                      !change.declined && change.approved
                                        ? shiftChangeArrowGreen
                                        : `${
                                            change.declined
                                              ? shiftChangeArrowRed
                                              : shiftChangeArrow
                                          }`
                                    }`
                                  : shiftChangeArrow
                              }`}
                              alt="arrow"
                              className="changeShiftArrowImg"
                            />
                          </div>
                          <div
                            className="changeRowRight"
                            onClick={() => {
                              memoVals.setShowEditShiftModal((x) => {
                                return {
                                  itemID: change.shiftID,
                                  itemType: change.newType || change.type,
                                  itemFName: change.fName,
                                  itemLName: change.lName,
                                  itemIsNew: false,
                                  userID: change.userID,
                                };
                              });
                            }}
                          >
                            <p
                              className={`oldShiftDate newShiftDate ${
                                change.tilMins > change.availableTil
                                  ? "colorRed"
                                  : ""
                              }`}
                            >
                              <span className="useTilTxt">TOIL to use:</span>{" "}
                              <span className="spanNoBreakLine cx020948">
                                {dateStringer.formatMinsDurationToHours(
                                  change.tilMins,
                                  true,
                                  true
                                )}
                              </span>
                              <img
                                src={barChart}
                                alt="TOIL to use"
                                className="shChImgRight"
                              />
                            </p>
                            <div
                              className={`tilTimesStrip tilTimesStripNewSh ${
                                dateStringer
                                  .generateTilShiftString(
                                    change.start,
                                    change.end,
                                    change.oldStart,
                                    change.oldEnd,
                                    false,
                                    false,
                                    change.type,
                                    true
                                  )
                                  .includes("Whole")
                                  ? "wholeTilLift"
                                  : ""
                              } `}
                            >
                              {/* <p className="tilStripTitle">TOIL: </p> */}
                              {change.start === change.oldStart &&
                              change.end === change.oldEnd ? (
                                <p className="oldShiftTimes highlighedTilTimes noBorder">
                                  Whole shift
                                </p>
                              ) : (
                                <p className="oldShiftTimes highlighedTilTimes">
                                  <span className="">{change.start}</span> -{" "}
                                  <span className="">{change.end}</span>
                                </p>
                              )}
                              <img
                                src={rotaClock}
                                alt="TOIL to use"
                                className="rotaClockNewShiMG"
                              />
                            </div>
                            <div
                              className={`tilTimesStrip tilTimesStripNewSh bbcada x2202055 x2202055B ${
                                dateStringer
                                  .generateTilShiftString(
                                    change.start,
                                    change.end,
                                    change.oldStart,
                                    change.oldEnd,
                                    false,
                                    false,
                                    change.type,
                                    true
                                  )
                                  .includes("Whole")
                                  ? "none"
                                  : ""
                              }  ${
                                dateStringer.generateTilShiftString(
                                  change.start,
                                  change.end,
                                  change.oldStart,
                                  change.oldEnd,
                                  false,
                                  false,
                                  change.type,
                                  true
                                ).length > 25 &&
                                dateStringer
                                  .generateTilShiftString(
                                    change.start,
                                    change.end,
                                    change.oldStart,
                                    change.oldEnd,
                                    false,
                                    false,
                                    change.type,
                                    true
                                  )
                                  .includes(":")
                                  ? "x2829848484848999"
                                  : ""
                              }`}
                            >
                              {/* <p className="tilStripTitle">TOIL: </p> */}
                              {dateStringer.generateTilShiftString(
                                change.start,
                                change.end,
                                change.oldStart,
                                change.oldEnd,
                                false,
                                false,
                                change.type,
                                true
                              )}
                              <img
                                src={shiftBlockNavy}
                                alt="TOIL to use"
                                className="rotaClockNewShiMG x200205552"
                              />
                            </div>
                            {/* <p
                            className={`tilNewShiftTime ${
                              change.start !== change.oldStart &&
                              change.end !== change.oldEnd
                                ? "smallerTilTwoShifts"
                                : ""
                            } ${
                              dateStringer
                                .generateTilShiftString(
                                  change.start,
                                  change.end,
                                  change.oldStart,
                                  change.oldEnd,
                                  false,
                                  false,
                                  change.type,
                                  true
                                )
                                .includes("Whole")
                                ? "none"
                                : ""
                            }`}
                          >
                            {dateStringer.generateTilShiftString(
                              change.start,
                              change.end,
                              change.oldStart,
                              change.oldEnd,
                              false,
                              false,
                              change.type,
                              true
                            )}
                          </p> */}
                          </div>
                        </div>
                        {change.note ? (
                          <p className="changeNoteDiv">
                            <span className="changeNoteTitleSpan">
                              {change.fName}:{" "}
                            </span>
                            {change.note}
                          </p>
                        ) : (
                          ""
                        )}
                        <div className={`deleteChangeUnit paddingLeft2pc`}>
                          <div className="deleteChangeUnitLeft">
                            <img
                              src={rotaDur}
                              alt="Old new duration"
                              className="chShRotaDur"
                            />{" "}
                            {dateStringer.formatMinsDurationToHours(
                              change.oldDur,
                              false,
                              true
                            )}{" "}
                            <img
                              src={rightArr}
                              alt="Duration"
                              className="oldDurRightArrImg"
                            />{" "}
                            {dateStringer.formatMinsDurationToHours(
                              change.oldDur - change.tilMins,
                              false,
                              true
                            )}
                          </div>

                          {change.approved || change.declined ? (
                            <p
                              className={`${
                                change.approved || change.declined
                                  ? "shiftEditDecidedBy"
                                  : "none"
                              }`}
                            >
                              <span className="decidedBySpan">{`${
                                change.approved
                                  ? "Approved by "
                                  : "Declined by "
                              }`}</span>
                              <br />
                              {change.decidedBy || "unknown"}
                            </p>
                          ) : change.blockApprove ? (
                            <p
                              className="approveSwapBlocked"
                              onClick={() => {
                                setShowCannotManageOwnShift({
                                  show: true,
                                  teamName: change.teamName,
                                });
                              }}
                            >
                              You cannot approve your own swaps as a manager
                            </p>
                          ) : (
                            <div
                              className={`approveOrDeclineChangeReqDiv ${
                                memoVals.mob
                                  ? "approveOrDeclineChangeReqDivMob"
                                  : ""
                              } ${shiftReqsLoading ? "disabled opac05" : ""}`}
                            >
                              {" "}
                              <p
                                className="approveShChangeReqBtn"
                                onClick={() => {
                                  // setApproveLoading(true);
                                  setShiftReqsLoading(true);

                                  let newArr = [];
                                  itemsArr.forEach((obj) => {
                                    if (
                                      !obj.takeTilRequest ||
                                      obj.takeTilID !== change.takeTilID
                                    ) {
                                      newArr.push(obj);
                                    }
                                  });
                                  // let qty =
                                  //   countData.shiftEdits +
                                  //   countData.ttrs +
                                  //   countData.drops;
                                  let today00 = new Date();
                                  today00.setHours(0, 0, 0, 0);
                                  if (
                                    dateStringer.createTimestampFromString(
                                      change.oldDs
                                    ) >= today00.getTime()
                                  ) {
                                    // if (change.drop) {
                                    //   setCountData({
                                    //     drops:
                                    //       countData.drops - 1 < 0
                                    //         ? 0
                                    //         : countData.drops - 1,
                                    //   });
                                    // } else {
                                    setCountData({
                                      ttrs: countData.ttrs - 1,
                                    });
                                    // }
                                    // qty--;

                                    // setReqQty(qty);
                                  }
                                  setItemsArr(newArr);
                                  axios
                                    .post(
                                      `${serverURL}/approve-or-decline-take-til-request`,
                                      {
                                        takeTilID: change.takeTilID,
                                        userID: change.userID,
                                        decision: "approve",
                                        nowDs:
                                          dateStringer.createStringFromTimestamp(
                                            new Date().getTime()
                                          ),
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (
                                        response.data.message === "success" ||
                                        response.data.message.includes(
                                          "no longer"
                                        )
                                      ) {
                                        // setApproveLoading(false);
                                        if (
                                          response.data.message.includes(
                                            "no longer"
                                          )
                                        ) {
                                          memoVals.setIndicate((x) => {
                                            return {
                                              show: true,
                                              message:
                                                "Request no longer exists",
                                              colour: "red",
                                              duration: 4000,
                                            };
                                          });
                                        }

                                        setItemsArr(newArr);
                                        setTimeout(() => {
                                          setShiftReqsLoading(false);
                                        }, 100);
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                }}
                              >
                                Approve{" "}
                                <img
                                  src={whiteTick}
                                  alt="Approve"
                                  className="shAppWhiteTickImg"
                                />
                              </p>
                              <p
                                className={`declineShChangeReqBtn ${
                                  memoVals.mob ? "x2093094040499444" : ""
                                }`}
                                onClick={() => {
                                  setSureDeclineChange({
                                    show: true,
                                    change: false,
                                    toil: true,
                                    takeTilID: change.takeTilID,
                                    userID: change.userID,
                                    ds: change.oldDs || change.oldDate,
                                  });
                                }}
                              >
                                {memoVals.mob ? (
                                  <img
                                    src={lightGreyCancel}
                                    alt="Decline"
                                    className="x293298484994"
                                  />
                                ) : (
                                  "Decline"
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                        {/*  */}
                        {/* end of decision sub bar */}
                      </div>
                    </div>
                  );
                }
              }
            }
          })
        ) : (
          itemsArr.map((item, i) => {
            if (group === "leave" && item.reqID && item.leaveTypeName) {
              return (
                <div key={i} className="reqUnitParentNotif">
                  <div
                    onClick={() => {
                      memoVals.setShowReqMgrModal((x) => item.reqID);
                    }}
                    className={`mgrReqItem mgrReqItemNotifReq pend ${
                      memoVals.mob ? "borderRadius10x" : ""
                    } leaveReqItem`}
                  >
                    {" "}
                    <div className="unsubmittedTsBlob annLeaveBlob"></div>
                    {/* <div className="reqPlaneDiv">
                      <img
                        src={item.annLeave ? plane2 : plane}
                        alt="Requested leave"
                        className={`reqPlaneIcon ${
                          memoVals.mob ? "reqPlaneIconMob" : ""
                        }`}
                      />
                    </div> */}
                    <div className="reqColBarDiv divPend"></div>
                    <div
                      className={`mgrReqItemCol2 ${
                        memoVals.mob ? "mgrReqItemCol2Mob" : ""
                      }`}
                    >
                      <div className="mgrReqTopRow">
                        <div className="mgrReqName mgrReqName2">
                          {/* ------ */}
                          <div
                            className={`mgrReqDateStrip pendDateDiv x29898988444`}
                          >
                            <div
                              className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons `}
                            >
                              <img
                                src={item.annLeave ? plane2 : plane}
                                alt="Request duration"
                                className={`rotaSunReqs ${
                                  item.annLeave
                                    ? "notifMgrReqsPlaneImg"
                                    : "notifMgrReqsPlaneImg2"
                                }`}
                              />
                              {item.leaveTypeName}
                            </div>

                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow `}
                            >
                              <img
                                src={sun}
                                alt="Request duration"
                                className="rotaSunReqs"
                              />
                              {item.days} day
                              {item.days !== 1 ? "s" : ""}{" "}
                              <span className="colour00aaff x384849283455">
                                requested
                              </span>
                            </div>
                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow upcomingMgrReqsSpanDur `}
                            >
                              <img
                                src={rotaDur}
                                alt="Request duration"
                                className="rotaSunReqs"
                              />
                              {/* {item.ongoing ? "Ongoing" : durationString()} */}
                              {dateStringer.formatMinsDurationToHours(
                                item.contractedMins
                              )}
                              {item.excessMins ? (
                                <span className="notifReqExcMinsSpan">
                                  {" "}
                                  +{" "}
                                  {dateStringer.formatMinsDurationToHours(
                                    item.excessMins
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                        </div>
                        <div className="absTimelineVal absTimelineValReqs">
                          <div
                            className={`absCalDiv ${
                              item.startDs.substr(0, 11) ===
                              item.endDs.substr(0, 11)
                                ? "invis"
                                : ""
                            }`}
                          >
                            <div className="absCalDivUpper">
                              <div className="absDivCalNip"></div>
                              <div className="absDivCalNip"></div>
                            </div>
                            <div
                              className={`absCalDivLower ${
                                1 + 1 === 2
                                  ? "absCalDivLowerAllTime"
                                  : "absCalDivLowerAllTime"
                              }`}
                            >
                              {" "}
                              <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                                {parseInt(item.startDs.substr(9, 2))}
                              </p>{" "}
                              <p className="absCalDivLowerMonth absCalDivLowerMonthReqs absCalDivLowerMonthReqs2">
                                {dateStringer.getMonthThreeLettersFromDs(
                                  item.startDs
                                )}
                              </p>
                              <p className="absCalDivLowerYear">
                                {item.startDs.substr(1, 4)}
                              </p>
                            </div>
                          </div>
                          <div
                            className={`absCanDivSplitter ${
                              item.startDs === item.endDs ? "invis" : ""
                            }`}
                          ></div>
                          <div className={`absCalDiv`}>
                            <div className="absCalDivUpper">
                              <div className="absDivCalNip"></div>
                              <div className="absDivCalNip"></div>
                            </div>
                            <div
                              className={`absCalDivLower ${
                                1 + 1 === 2
                                  ? "absCalDivLowerAllTime"
                                  : "absCalDivLowerAllTime"
                              }`}
                            >
                              {" "}
                              <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                                {parseInt(item.endDs.substr(9, 2))}
                              </p>{" "}
                              <p className="absCalDivLowerMonth absCalDivLowerMonthReqs absCalDivLowerMonthReqs2">
                                {dateStringer.getMonthThreeLettersFromDs(
                                  item.endDs
                                )}
                              </p>{" "}
                              <p className="absCalDivLowerYear">
                                {item.startDs.substr(1, 4)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`mgrReqNameTab mgrReqNameTab2 pendClockName x34986498542`}
                    onClick={() => {
                      memoVals.setShowEditUser((x) => {
                        return { userID: item.userID };
                      });
                    }}
                  >
                    <img
                      src={item.profPicUrl || profilePicturePlaceholder}
                      alt={item.fName}
                      className="itemNameTabImg"
                    />{" "}
                    {item.fName} {item.lName}
                    {item.you ? <span className="youSpan">(You)</span> : ""}
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}

                  <div
                    className={`mgrClockSubBar ${
                      memoVals.mob ? "mgrClockSubBarMob" : ""
                    } pendClockSub notifReqSubBarReq`}
                  >
                    <div
                      className={`clocksSchedSubLeft ${
                        memoVals.mob ? "font12px" : ""
                      }`}
                    >
                      <p className="mgrNotifDsMade">
                        <span
                          className={
                            item.daysOrHours === "days" && item.tot === 366
                              ? "reqAllowPositiveGreen"
                              : item.daysOrHours === "hours"
                              ? item.remaining < item.contractedMins
                                ? "reqAllowNegativeRed"
                                : "reqAllowPositiveGreen"
                              : item.daysOrHours === "days"
                              ? item.remaining < item.days
                                ? "reqAllowNegativeRed"
                                : "reqAllowPositiveGreen"
                              : ""
                          }
                        >
                          {item.daysOrHours === "days" && item.tot === 366
                            ? "Unlimited"
                            : item.daysOrHours === "days"
                            ? item.remaining
                            : dateStringer.formatMinsDurationToHours(
                                item.remaining || 0
                              )}{" "}
                          {item.daysOrHours === "days" && item.tot !== 366
                            ? "day"
                            : ""}
                          {item.remaining === 1 ||
                          item.daysOrHours === "hours" ||
                          (item.daysOrHours === "days" && item.tot === 366)
                            ? ""
                            : "s"}{" "}
                        </span>
                        remaining
                      </p>
                    </div>
                    <div
                      className={`clocksSchedSubRight  ${
                        memoVals.mob ? "flexEnd" : ""
                      }`}
                    >
                      {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                      <p
                        className={`approveClock x2978457 ${
                          item.blockApprove
                            ? "unableToApproveOwn greyColours"
                            : ""
                        } ${approving === item.reqID ? "disabledApprove" : ""}`}
                        onClick={() => {
                          if (item.blockApprove) {
                            setBlockApprove("req");
                          } else {
                            if (approving === item.reqID) {
                            } else {
                              setApproving(item.reqID);
                              // approve request here

                              axios
                                .post(
                                  `${serverURL}/approve-request`,
                                  {
                                    reqID: item.reqID,
                                    approvedBy: `${memoVals.checkUserState.fName} ${memoVals.checkUserState.lName}`,
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (
                                    response.data.message.includes("logout")
                                  ) {
                                    window.location.href = "/signin";
                                  }
                                  if (response.data.message === "success") {
                                    // setTimeout(() => {

                                    setCountData({
                                      leaveReqs: countData.leaveReqs - 1,
                                    });
                                    setIndicate((x) => {
                                      return {
                                        show: true,
                                        message: `${dateStringer.possession(
                                          item.fName
                                        )} leave request approved`,
                                        colour: "green",
                                        duration: 4000,
                                      };
                                    });

                                    setTimeout(() => {
                                      setApproving("");
                                      setItemsArr(
                                        itemsArr.filter((x) => {
                                          return x.reqID !== item.reqID;
                                        })
                                      );
                                    }, 1);

                                    memoVals.setUpdateTabData(
                                      (x) => !memoVals.updateTabData
                                    );
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }
                          }
                        }}
                      >
                        Approve{" "}
                        <img
                          src={whiteTick}
                          alt="Approve clock card"
                          className="approveCCtickimg"
                        />
                      </p>
                    </div>
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}
                </div>
              );
            }

            if (group === "hours" && item.type === "clock") {
              return (
                <div
                  key={i}
                  className={`reqUnitParentNotif ${
                    (!hoursLogged &&
                      !hoursClocks &&
                      !hoursTimesheets &&
                      !hoursDeclined) ||
                    ((hoursLogged ||
                      hoursDeclined ||
                      hoursClocks ||
                      hoursTimesheets) &&
                      hoursClocks)
                      ? ""
                      : "none"
                  }`}
                >
                  <div
                    onClick={() => {
                      memoVals.setClockOnID((x) => {
                        return {
                          clockOnID: item.clockOnID,
                          isMgr: true,
                        };
                      });
                    }}
                    className={`mgrReqItem mgrReqItemNotifReq pend ${
                      memoVals.mob ? "borderRadius10x" : ""
                    } leaveReqItem ccNotifItem`}
                  >
                    {" "}
                    <div className="unsubmittedTsBlob annLeaveBlob ccMgrNotifBlob ccMgrNotifBlobClockCard"></div>
                    {/* <div className="reqPlaneDiv">
                      <img
                        src={rotaClockCard}
                        alt="Requested leave"
                        className={`reqPlaneIcon ${
                          memoVals.mob ? "reqPlaneIconMob" : ""
                        }`}
                      />
                    </div> */}
                    <div className="reqColBarDiv divPend"></div>
                    <div
                      className={`mgrReqItemCol2 ${
                        memoVals.mob ? "mgrReqItemCol2Mob" : ""
                      }`}
                    >
                      <div className="mgrReqTopRow">
                        <div className="mgrReqName mgrReqName2">
                          {/* ------ */}
                          <div
                            className={`mgrReqDateStrip pendDateDiv x29898988444 x29898988444233 x29898988444233cc`}
                          >
                            <div
                              className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons `}
                            >
                              <img
                                src={desktopBurger}
                                alt="Request duration"
                                className="rotaSunReqs leaveNameBurger leaveNameBurgerNotifReq"
                              />
                              {item.itemType === "shift"
                                ? "Clocked shift"
                                : item.itemType === "overtime"
                                ? "Clocked overtime"
                                : "Clocked overtime"}
                            </div>
                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow `}
                            >
                              <img
                                src={rotaDate}
                                alt="Request duration"
                                className="rotaSunReqs x4985982567876 x29849835"
                              />

                              {dateStringer.printedDateFromDs(
                                item.startDs,
                                true
                              )}
                            </div>
                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow upcomingMgrReqsSpanDur`}
                            >
                              <img
                                src={rotaDur}
                                alt="Request duration"
                                className="rotaSunReqs x29849835"
                              />
                              {dateStringer.formatMinsDurationToHours(
                                item.clockedDurMins
                              )}
                              {/* <span className="colour00aaff">requested</span> */}
                            </div>

                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow upcomingMgrReqsSpanDur bbcada_`}
                            >
                              <img
                                src={rotaTeam}
                                alt="Request team"
                                className="rotaSunReqs x4985982567876 x29849835"
                              />

                              {dateStringer.shorten(item.teamName, 22)}
                            </div>
                          </div>
                          {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                        </div>
                        <div className="mgrReqCCRight">
                          <div className="absTimelineVal ccDivTimeline">
                            <div className="ccDiv">
                              <div className="ccDivMid">
                                <div className="ccDivTs">
                                  <p className="ccDivTitle">On</p>

                                  <p>
                                    {" "}
                                    {dateStringer.dsToTimeStrip(
                                      item.startDs,
                                      true
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`absCanDivSplitter ccMgrSplitter`}
                            ></div>

                            <div className="ccDiv ccDiv2">
                              <div className="ccDivMid">
                                <div className="ccDivTs">
                                  <p className="ccDivTitle ccDivTitle2">Off</p>

                                  <p>
                                    {" "}
                                    {dateStringer.dsToTimeStrip(
                                      item.endDs,
                                      true
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {item.tmw && <p className="ccTmw">+{item.tmw}d</p>}

                            {/*  */}
                          </div>
                          <div className="menuMgrReqClockSubRow">
                            {item.lateStart ? (
                              <p className="clockInlineLateSpan clockInlineLateSpanLeft">
                                {dateStringer.formatMinsDurationToHours(
                                  item.lateStart
                                )}{" "}
                                late
                              </p>
                            ) : item.earlyStart ? (
                              <p className="clockInlineLateSpan clockInlineLateSpanLeft colour516b8b">
                                {dateStringer.formatMinsDurationToHours(
                                  item.earlyStart
                                )}{" "}
                                early
                              </p>
                            ) : (
                              <p></p>
                            )}
                            {item.earlyEnd ? (
                              <p className="clockInlineLateSpan clockInlineLateSpanRight">
                                {dateStringer.formatMinsDurationToHours(
                                  item.earlyEnd
                                )}{" "}
                                early
                              </p>
                            ) : item.lateEnd ? (
                              <p className="clockInlineLateSpan clockInlineLateSpanRight colour516b8b">
                                {dateStringer.formatMinsDurationToHours(
                                  item.lateEnd
                                )}{" "}
                                late
                              </p>
                            ) : (
                              <p></p>
                            )}
                          </div>
                        </div>
                        {/* {item.lateStart && (
                              <span className="clockInlineLateSpan">
                                (
                                {dateStringer.formatMinsDurationToHours(
                                  item.lateStart
                                )}{" "}
                                late)
                              </span>
                            )} */}
                      </div>{" "}
                    </div>
                  </div>
                  <div
                    className={`mgrReqNameTab mgrReqNameTab2 pendClockName x34986498542 x435397859383`}
                    onClick={() => {
                      memoVals.setShowEditUser((x) => {
                        return { userID: item.userID };
                      });
                    }}
                  >
                    <img
                      src={item.profPicUrl || profilePicturePlaceholder}
                      alt={item.fName}
                      className="itemNameTabImg"
                    />{" "}
                    {item.fName} {item.lName}
                    {item.you ? <span className="youSpan">(You)</span> : ""}
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}

                  <div
                    className={`mgrClockSubBar ${
                      memoVals.mob ? "mgrClockSubBarMob" : ""
                    } pendClockSub notifReqSubBarReq ${
                      item.assocStartDs && item.lateStart > 0
                        ? "x48949849843899834"
                        : ""
                    }`}
                  >
                    <div
                      className={`clocksSchedSubLeft ${
                        memoVals.mob ? "font12px" : ""
                      }`}
                    >
                      {item.assocStartDs ? (
                        <div className="mgrNotifDsMade">
                          <p className="mgrNotifSchedTitle">Scheduled:</p>
                          <p className="mgrNotifSchedMain">
                            {dateStringer.dsToTimeStrip(item.assocStartDs)} -{" "}
                            {dateStringer.dsToTimeStrip(item.assocEndDs)}
                          </p>
                        </div>
                      ) : (
                        <p></p>
                      )}
                      {/* - - - - - - - - - - - - - - - -  */}
                      {item.lateStart > 0 ? (
                        <div
                          className={`mgrReqsLateSelBox x328438944452 ${
                            recordLateItemIDs.includes(item.clockOnID)
                              ? "cursorDefault x328438944452Selected"
                              : ""
                          }`}
                          onClick={() => {
                            if (!recordLateItemIDs.includes(item.clockOnID)) {
                              setRecordLateItemIDs([
                                ...recordLateItemIDs,
                                item.clockOnID,
                              ]);
                            }
                          }}
                        >
                          {" "}
                          <div
                            className={`mgrLoggedExcToilImgWrap mgrLoggedExcToilImgWrapReqs `}
                            onClick={() => {
                              if (recordLateItemIDs.includes(item.clockOnID)) {
                                setRecordLateItemIDs(
                                  recordLateItemIDs.filter(
                                    (x) => x !== item.clockOnID
                                  )
                                );
                              } else {
                                setRecordLateItemIDs([
                                  ...recordLateItemIDs,
                                  item.clockOnID,
                                ]);
                              }
                            }}
                          >
                            <img
                              src={navyTick}
                              alt="Apply to TOIL"
                              className={`mgrLoggedExcToilImg mgrLoggedExcToilImgMgrReqs  ${
                                recordLateItemIDs.includes(item.clockOnID)
                                  ? ""
                                  : "invis"
                              }`}
                            />
                          </div>
                          <div className="recLateReqRow">
                            <p>
                              {" "}
                              Record{" "}
                              <span className="colourBa0000">
                                {dateStringer.formatMinsDurationToHours(
                                  item.lateStart,
                                  true
                                )}
                              </span>{" "}
                              late start
                            </p>

                            {recordLateItemIDs.includes(item.clockOnID) ? (
                              <div
                                className="recLateReqRowSub"
                                onClick={() => {
                                  if (
                                    recordLateUnpaidItemIDs.includes(
                                      item.clockOnID
                                    )
                                  ) {
                                    setRecordLateUnpaidItemIDs(
                                      recordLateUnpaidItemIDs.filter(
                                        (x) => x !== item.clockOnID
                                      )
                                    );
                                  } else {
                                    setRecordLateUnpaidItemIDs([
                                      ...recordLateUnpaidItemIDs,
                                      item.clockOnID,
                                    ]);
                                  }
                                }}
                              >
                                <span className="x23498439843">Unpaid</span>{" "}
                                <div className="mgrLoggedExcToilImgWrap mgrLoggedExcToilImgWrapReqs mgrLoggedExcToilImgWrapReqsUnpaid">
                                  <img
                                    src={navyTick}
                                    alt="Apply to TOIL"
                                    className={`mgrLoggedExcToilImg mgrLoggedExcToilImgMgrReqs unpaidTickImgMgReqs ${
                                      recordLateUnpaidItemIDs.includes(
                                        item.clockOnID
                                      )
                                        ? ""
                                        : "invis"
                                    }`}
                                  />
                                </div>{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* - - - - - - - - - - - - - - - - - - - - - -  */}
                    </div>
                    <div
                      className={`clocksSchedSubRight  ${
                        memoVals.mob ? "flexEnd" : ""
                      }`}
                    >
                      {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                      <p
                        className={`approveClock x2978457 ${
                          item.blockApprove
                            ? "unableToApproveOwn greyColours"
                            : ""
                        } ${
                          approveLoading === item.clockOnID
                            ? "disabledApprove"
                            : ""
                        }`}
                        onClick={() => {
                          if (item.blockApprove) {
                            setBlockApprove("clock");
                          } else {
                            setApproveLoading(item.clockOnID);
                            console.log({ item });
                            // setApproveLoading(false);
                            axios
                              .post(
                                `${serverURL}/approve-clock-card`,
                                {
                                  clockOnID: item.clockOnID,
                                  nowDs: dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                  recordLate: recordLateItemIDs.includes(
                                    item.clockOnID
                                  )
                                    ? parseInt(item.lateStart)
                                    : false,
                                  recordLateUnpaid:
                                    recordLateUnpaidItemIDs.includes(
                                      item.clockOnID
                                    )
                                      ? true
                                      : false,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message.includes("logout")) {
                                  window.location.href = "/signin";
                                }
                                if (response.data.message === "success") {
                                  setCountData({
                                    clocks: countData.clocks - 1,
                                  });
                                  memoVals.setIndicate((x) => {
                                    return {
                                      show: true,
                                      message: `${dateStringer.possession(
                                        item.fName
                                      )} clock card approved`,
                                      colour: "green",
                                      duration: 4000,
                                    };
                                  });
                                  setApproveLoading("");
                                  setApprovedCCId(item.clockOnID);
                                  // setReload(!reload);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                      >
                        Approve{" "}
                        <img
                          src={whiteTick}
                          alt="Approve clock card"
                          className="approveCCtickimg"
                        />
                      </p>
                    </div>
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}
                </div>
              );
            }

            if (group === "hours" && item.type === "timesheet") {
              return (
                <div
                  key={i}
                  className={`reqUnitParentNotif ${
                    (!hoursLogged &&
                      !hoursClocks &&
                      !hoursTimesheets &&
                      !hoursDeclined) ||
                    ((hoursLogged ||
                      hoursDeclined ||
                      hoursClocks ||
                      hoursTimesheets) &&
                      hoursTimesheets)
                      ? ""
                      : "none"
                  }`}
                >
                  <div
                    onClick={() => {
                      memoVals.setShowViewTimesheet((x) => {
                        return {
                          show: true,
                          timesheetID: item.timesheetID,
                          isMgr: true,
                        };
                      });
                    }}
                    className={`mgrReqItem mgrReqItemNotifReq pend ${
                      memoVals.mob ? "borderRadius10x" : ""
                    } leaveReqItem ccNotifItem`}
                  >
                    <div className="unsubmittedTsBlob annLeaveBlob ccMgrNotifBlob notifMgrTsSubBlob"></div>

                    <div className="reqColBarDiv divPend"></div>
                    <div
                      className={`mgrReqItemCol2 ${
                        memoVals.mob ? "mgrReqItemCol2Mob" : ""
                      }`}
                    >
                      <div className="mgrReqTopRow">
                        <div className="mgrReqName mgrReqName2">
                          {/* ------ */}
                          <div
                            className={`mgrReqDateStrip pendDateDiv x29898988444 x29898988444233 x2928498494455`}
                          >
                            <div
                              className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons `}
                            >
                              <img
                                src={desktopBurger}
                                alt="Request duration"
                                className="rotaSunReqs leaveNameBurger leaveNameBurgerNotifReq"
                              />
                              Timesheet submission
                            </div>
                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow `}
                            >
                              <img
                                src={rotaDate}
                                alt="Request duration"
                                className="rotaSunReqs x4985982567876 x29849835"
                              />
                              {dateStringer.printedDateFromDs(item.startDs)}{" "}
                              {item.startDs !==
                              `${item.endDs.substr(0, 11)}H00M00`
                                ? "-"
                                : ""}{" "}
                              {item.startDs !==
                              `${item.endDs.substr(0, 11)}H00M00`
                                ? dateStringer.printedDateFromDs(item.endDs)
                                : ""}{" "}
                            </div>
                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow upcomingMgrReqsSpanDur`}
                            >
                              <img
                                src={rotaDur}
                                alt="Request duration"
                                className="rotaSunReqs x29849835"
                              />
                              {dateStringer.formatMinsDurationToHours(
                                item.durMins
                              )}
                              {/* <span className="colour00aaff">requested</span> */}
                            </div>

                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow upcomingMgrReqsSpanDur bbcada_`}
                            >
                              <img
                                src={rotaTeam}
                                alt="Request team"
                                className="rotaSunReqs x4985982567876 x29849835"
                              />

                              {dateStringer.shorten(item.teamName, 22)}
                            </div>
                          </div>
                          {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                        </div>
                        <div className="tsIllus">
                          <p className="tsIllusTitle">
                            Timesheet{" "}
                            <img
                              src={ts1}
                              alt="Timesheet"
                              className="tsIllusPencil"
                            />
                          </p>
                          <p className="tsIllusValue">
                            {item.itemsQty} record
                            {item.itemsQty === 1 ? "" : "s"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`mgrReqNameTab mgrReqNameTab2 pendClockName x34986498542 x435397859383`}
                    onClick={() => {
                      memoVals.setShowEditUser((x) => {
                        return { userID: item.userID };
                      });
                    }}
                  >
                    <img
                      src={item.profPicUrl || profilePicturePlaceholder}
                      alt={item.fName}
                      className="itemNameTabImg"
                    />{" "}
                    {item.fName} {item.lName}
                    {item.you ? <span className="youSpan">(You)</span> : ""}
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}

                  <div
                    className={`mgrClockSubBar ${
                      memoVals.mob ? "mgrClockSubBarMob" : ""
                    } pendClockSub notifReqSubBarReq`}
                  >
                    <div
                      className={`clocksSchedSubLeft ${
                        memoVals.mob ? "font12px" : ""
                      }`}
                    >
                      <p></p>
                    </div>
                    <div
                      className={`clocksSchedSubRight  ${
                        memoVals.mob ? "flexEnd" : ""
                      }`}
                    >
                      {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                      <p
                        className={`openBtnReqMgrBtn`}
                        onClick={() => {
                          memoVals.setShowViewTimesheet((x) => {
                            return {
                              show: true,
                              timesheetID: item.timesheetID,
                              isMgr: true,
                            };
                          });
                        }}
                      >
                        Open{" "}
                        <img
                          src={popup}
                          alt="Approve clock card"
                          className="approveCCtickimg approveCCtickimgOpen"
                        />
                      </p>
                    </div>
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}
                </div>
              );
            }
            if (group === "hours" && item.type === "logged") {
              return (
                <div
                  key={i}
                  className={`reqUnitParentNotif ${
                    (!hoursLogged &&
                      !hoursClocks &&
                      !hoursTimesheets &&
                      !hoursDeclined) ||
                    ((hoursLogged ||
                      hoursDeclined ||
                      hoursClocks ||
                      hoursTimesheets) &&
                      hoursLogged)
                      ? ""
                      : "none"
                  }`}
                >
                  <div
                    onClick={() => {
                      console.log({
                        shiftID: item.shiftID,
                        tilID: item.shiftID,
                        fName: item.fName,
                        lName: item.lName,
                        openedFromShiftsPage: true,
                        type: item.itemType || "shift",
                      });
                      memoVals.setOpenManagerShiftModal((x) => {
                        return {
                          shiftID:
                            item.itemType === "shift" ? item.shiftID : "",
                          tilID: item.itemType !== "shift" ? item.shiftID : "",
                          fName: item.fName,
                          lName: item.lName,
                          openedFromShiftsPage: true,
                          type: item.itemType || "shift",
                        };
                      });
                    }}
                    className={`mgrReqItem mgrReqItemNotifReq pend ${
                      memoVals.mob ? "borderRadius10x" : ""
                    } leaveReqItem ccNotifItem ccNotifItemLogged`}
                  >
                    {" "}
                    <div className="unsubmittedTsBlob annLeaveBlob ccMgrNotifBlob"></div>
                    {/* <div className="reqPlaneDiv">
                      <img
                        src={rotaClockCard}
                        alt="Requested leave"
                        className={`reqPlaneIcon ${
                          memoVals.mob ? "reqPlaneIconMob" : ""
                        }`}
                      />
                    </div> */}
                    <div className="reqColBarDiv divPend"></div>
                    <div
                      className={`mgrReqItemCol2 ${
                        memoVals.mob ? "mgrReqItemCol2Mob" : ""
                      }`}
                    >
                      <div className="mgrReqTopRow">
                        <div className="mgrReqName mgrReqName2">
                          {/* ------ */}
                          <div
                            className={`mgrReqDateStrip pendDateDiv x29898988444 x29898988444233 x29898988444233Logged`}
                          >
                            <div
                              className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons `}
                            >
                              <img
                                src={desktopBurger}
                                alt="Request duration"
                                className="rotaSunReqs leaveNameBurger leaveNameBurgerNotifReq"
                              />
                              Logged{" "}
                              {item.itemType === "shift" ? "shift" : "overtime"}{" "}
                              times
                            </div>
                            {/* <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow `}
                            >
                              <img
                                src={rotaDate}
                                alt="Request duration"
                                className="rotaSunReqs x4985982567876 x29849835"
                              />

                              {dateStringer.printedDateFromDs(
                                item.startDs,
                                true
                              )}
                            </div> */}
                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow upcomingMgrReqsSpanDur colour0077ff fontWeight600`}
                            >
                              <img
                                src={rotaEdit}
                                alt="Request duration"
                                className="rotaSunReqs x29849835 fullOpac"
                              />
                              {item.logged}
                              {/* <span className="colour00aaff">requested</span> */}
                            </div>

                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow upcomingMgrReqsSpanDur bbcada_`}
                            >
                              <img
                                src={rotaTeam}
                                alt="Request team"
                                className="rotaSunReqs x4985982567876 x29849835"
                              />

                              {dateStringer.shorten(item.teamName, 22)}
                            </div>
                          </div>
                          {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                        </div>
                        <div className="teamShRightBlock">
                          <div className="absTimelineVal absTimelineValReqs rowReverse marginRight0">
                            <div className={`shItemTimes`}>
                              <p className="shItemTimeRow">
                                {dateStringer.dsToTimeStrip(item.startDs, true)}
                              </p>
                              <div className="shItemTimeRowSplitter"></div>
                              <p className="shItemTimeRow">
                                {dateStringer.dsToTimeStrip(item.endDs, true)}
                              </p>
                            </div>
                            <div className={`absCalDiv`}>
                              <div className="absCalDivUpper">
                                <div className="absDivCalNip"></div>
                                <div className="absDivCalNip"></div>
                              </div>
                              <div
                                className={`absCalDivLower absCalDivLowerAllTime`}
                              >
                                {/* <p className="calDivUpperThreeLetterDay">{dateStringer.getThreeDayLettersFromDs(unit.startDs)}</p> */}
                                <p className="absCalDivLowerMonth absCalDivLowerMonthReqs absCalDivLowerMonthReqs2_ absCalDivLowerMonthReqs3_">
                                  {dateStringer.getThreeDayLettersFromDs(
                                    item.startDs
                                  )}
                                </p>{" "}
                                <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                                  {parseInt(item.startDs.substr(9, 2))}
                                </p>
                                <p className="absCalDivLowerYear">
                                  {dateStringer.getMonthThreeLettersFromDs(
                                    item.startDs
                                  )}
                                  {/* {unit.startDs.substr(1, 4)} */}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`mgrReqNameTab mgrReqNameTab2 pendClockName x34986498542 x435397859383 x435397859383Logged`}
                    onClick={() => {
                      memoVals.setShowEditUser((x) => {
                        return { userID: item.userID };
                      });
                    }}
                  >
                    <img
                      src={item.profPicUrl || profilePicturePlaceholder}
                      alt={item.fName}
                      className="itemNameTabImg"
                    />{" "}
                    {item.fName} {item.lName}
                    {item.you ? <span className="youSpan">(You)</span> : ""}
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}

                  <div
                    className={`mgrClockSubBar ${
                      memoVals.mob ? "mgrClockSubBarMob" : ""
                    } pendClockSub notifReqSubBarReq ${
                      item.loggedExcess
                        ? `minHeight64 ${
                            item.lateMins > 0 ? "minHeight101" : ""
                          }`
                        : ""
                    }`}
                  >
                    <div
                      className={`clocksSchedSubLeft ${
                        memoVals.mob ? "font12px" : ""
                      }`}
                    >
                      {item.loggedExcess ? (
                        <div className="mgrReqsLogSelRow">
                          {item.lateMins > 0 ? (
                            <div
                              className={`mgrReqsLateSelBox ${
                                recordLateItemIDs.includes(
                                  item.shiftID || item.tilID || item.itemID
                                )
                                  ? "cursorDefault"
                                  : ""
                              }`}
                              onClick={() => {
                                if (
                                  !recordLateItemIDs.includes(
                                    item.shiftID || item.tilID || item.itemID
                                  )
                                ) {
                                  setRecordLateItemIDs([
                                    ...recordLateItemIDs,
                                    item.shiftID || item.tilID || item.itemID,
                                  ]);
                                }
                              }}
                            >
                              {" "}
                              <div
                                className={`mgrLoggedExcToilImgWrap mgrLoggedExcToilImgWrapReqs `}
                                onClick={() => {
                                  if (
                                    recordLateItemIDs.includes(
                                      item.shiftID || item.tilID || item.itemID
                                    )
                                  ) {
                                    setRecordLateItemIDs(
                                      recordLateItemIDs.filter(
                                        (x) =>
                                          x !== item.shiftID ||
                                          item.tilID ||
                                          item.itemID
                                      )
                                    );
                                  } else {
                                    setRecordLateItemIDs([
                                      ...recordLateItemIDs,
                                      item.shiftID || item.tilID || item.itemID,
                                    ]);
                                  }
                                }}
                              >
                                <img
                                  src={navyTick}
                                  alt="Apply to TOIL"
                                  className={`mgrLoggedExcToilImg mgrLoggedExcToilImgMgrReqs  ${
                                    recordLateItemIDs.includes(
                                      item.shiftID || item.tilID || item.itemID
                                    )
                                      ? ""
                                      : "invis"
                                  }`}
                                />
                              </div>
                              <div className="recLateReqRow">
                                <p>
                                  {" "}
                                  Record{" "}
                                  <span className="colourBa0000">
                                    {dateStringer.formatMinsDurationToHours(
                                      item.lateMins,
                                      true
                                    )}
                                  </span>{" "}
                                  late start
                                </p>

                                {recordLateItemIDs.includes(
                                  item.shiftID || item.tilID || item.itemID
                                ) ? (
                                  <div
                                    className="recLateReqRowSub"
                                    onClick={() => {
                                      if (
                                        recordLateUnpaidItemIDs.includes(
                                          item.shiftID ||
                                            item.tilID ||
                                            item.itemID
                                        )
                                      ) {
                                        setRecordLateUnpaidItemIDs(
                                          recordLateUnpaidItemIDs.filter(
                                            (x) =>
                                              x !== item.shiftID ||
                                              item.tilID ||
                                              item.itemID
                                          )
                                        );
                                      } else {
                                        setRecordLateUnpaidItemIDs([
                                          ...recordLateUnpaidItemIDs,
                                          item.shiftID ||
                                            item.tilID ||
                                            item.itemID,
                                        ]);
                                      }
                                    }}
                                  >
                                    <span className="x23498439843">Unpaid</span>{" "}
                                    <div className="mgrLoggedExcToilImgWrap mgrLoggedExcToilImgWrapReqs mgrLoggedExcToilImgWrapReqsUnpaid">
                                      <img
                                        src={navyTick}
                                        alt="Apply to TOIL"
                                        className={`mgrLoggedExcToilImg mgrLoggedExcToilImgMgrReqs unpaidTickImgMgReqs ${
                                          recordLateUnpaidItemIDs.includes(
                                            item.shiftID ||
                                              item.tilID ||
                                              item.itemID
                                          )
                                            ? ""
                                            : "invis"
                                        }`}
                                      />
                                    </div>{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className={`tsDiffDiv tsDiffDivMgrReqs`}>
                            <p>
                              <span className="colour00aaff">
                                {dateStringer.formatMinsDurationToHours(
                                  item.loggedExcess,
                                  true
                                )}
                              </span>{" "}
                              {item.loggedExcess < 0 ? "short of" : "over"}{" "}
                              scheduled duration
                            </p>
                            <div
                              className={`tsDiffDivSelBox`}
                              onClick={() => {
                                //
                                axios
                                  .post(
                                    `${serverURL}/amend-logged-excess-toil-in-timesheet`,
                                    {
                                      timesheetID: "none",

                                      shiftID:
                                        item.shiftID ||
                                        item.tilID ||
                                        item.itemID,
                                      data: !item.loggedExcessToil,
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (
                                      response.data.message.includes("logout")
                                    ) {
                                      window.location.href = "/signin";
                                    }
                                    if (response.data.message === "success") {
                                      // daisy
                                      let newArr = [];
                                      itemsArr.map((x) => {
                                        if (
                                          item.type === "logged" &&
                                          (x.shiftID || x.tilID || x.itemID) ===
                                            (item.shiftID ||
                                              item.tilID ||
                                              item.itemID)
                                        ) {
                                          let newObj = x;
                                          newObj["loggedExcessToil"] =
                                            !item.loggedExcessToil;
                                          newArr.push(newObj);
                                        } else {
                                          newArr.push(x);
                                        }
                                      });
                                      setItemsArr(newArr);
                                      // axios
                                      //   .post(
                                      //     `${serverURL}/get-mgr-requests`,
                                      //     {
                                      //       group,
                                      //     },

                                      //     {
                                      //       withCredentials: true,
                                      //       credentials: "include",
                                      //     }
                                      //   )
                                      //   .then((response2) => {
                                      //     if (
                                      //       response2.data.message === "success"
                                      //     ) {
                                      //       setItemsArr(response2.data.output);
                                      //       setLoading(false);
                                      //     }
                                      //   })
                                      //   .catch((err) => {
                                      //     console.error(err);
                                      //   });
                                    }
                                  });
                              }}
                            >
                              <div className="tsDiffTickBox">
                                <img
                                  src={tick}
                                  alt="TOIL"
                                  className={`tsDiffTickBoxImg ${
                                    item.loggedExcessToil ? "" : "invis"
                                  }`}
                                />
                              </div>

                              {item.loggedExcess > 0
                                ? `Add to TOIL balance`
                                : item.loggedExcess < 0
                                ? "Deduct from TOIL balance"
                                : ""}
                            </div>
                          </div>
                        </div>
                      ) : item.earlyMins || item.lateMins ? (
                        item.lateMins > 0 ? (
                          <div
                            className={`mgrReqsLateSelBox mgrReqsLateSelBox2  ${
                              recordLateItemIDs.includes(
                                item.shiftID || item.tilID || item.itemID
                              )
                                ? "cursorDefault"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                !recordLateItemIDs.includes(
                                  item.shiftID || item.tilID || item.itemID
                                )
                              ) {
                                setRecordLateItemIDs([
                                  ...recordLateItemIDs,
                                  item.shiftID || item.tilID || item.itemID,
                                ]);
                              }
                            }}
                          >
                            {" "}
                            <div
                              className={`mgrLoggedExcToilImgWrap mgrLoggedExcToilImgWrapReqs `}
                            >
                              <img
                                onClick={() => {
                                  if (
                                    recordLateItemIDs.includes(
                                      item.shiftID || item.tilID || item.itemID
                                    )
                                  ) {
                                    setRecordLateItemIDs(
                                      recordLateItemIDs.filter(
                                        (x) =>
                                          x !== item.shiftID ||
                                          item.tilID ||
                                          item.itemID
                                      )
                                    );
                                  } else {
                                    setRecordLateItemIDs([
                                      ...recordLateItemIDs,
                                      item.shiftID || item.tilID || item.itemID,
                                    ]);
                                  }
                                }}
                                src={navyTick}
                                alt="Apply to TOIL"
                                className={`mgrLoggedExcToilImg mgrLoggedExcToilImgMgrReqs  ${
                                  recordLateItemIDs.includes(
                                    item.shiftID || item.tilID || item.itemID
                                  )
                                    ? ""
                                    : "invis"
                                }`}
                              />
                            </div>
                            <div className="recLateReqRow">
                              <p>
                                {" "}
                                Record{" "}
                                <span className="colourBa0000">
                                  {dateStringer.formatMinsDurationToHours(
                                    item.lateMins,
                                    true
                                  )}
                                </span>{" "}
                                late start
                              </p>

                              {recordLateItemIDs.includes(
                                item.shiftID || item.tilID || item.itemID
                              ) ? (
                                <div
                                  className="recLateReqRowSub"
                                  onClick={() => {
                                    if (
                                      recordLateUnpaidItemIDs.includes(
                                        item.shiftID ||
                                          item.tilID ||
                                          item.itemID
                                      )
                                    ) {
                                      setRecordLateUnpaidItemIDs(
                                        recordLateUnpaidItemIDs.filter(
                                          (x) =>
                                            x !== item.shiftID ||
                                            item.tilID ||
                                            item.itemID
                                        )
                                      );
                                    } else {
                                      setRecordLateUnpaidItemIDs([
                                        ...recordLateUnpaidItemIDs,
                                        item.shiftID ||
                                          item.tilID ||
                                          item.itemID,
                                      ]);
                                    }
                                  }}
                                >
                                  <span className="x23498439843">Unpaid</span>{" "}
                                  <div className="mgrLoggedExcToilImgWrap mgrLoggedExcToilImgWrapReqs mgrLoggedExcToilImgWrapReqsUnpaid">
                                    <img
                                      src={navyTick}
                                      alt="Apply to TOIL"
                                      className={`mgrLoggedExcToilImg mgrLoggedExcToilImgMgrReqs unpaidTickImgMgReqs ${
                                        recordLateUnpaidItemIDs.includes(
                                          item.shiftID ||
                                            item.tilID ||
                                            item.itemID
                                        )
                                          ? ""
                                          : "invis"
                                      }`}
                                    />
                                  </div>{" "}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="mgrNotifDsMade">
                            <p className="mgrNotifSchedTitle">Started</p>
                            <p
                              // logger here
                              className={`mgrNotifSchedMain fontSize14 ${
                                item.lateMins ? "ba0000" : ""
                              }`}
                            >
                              {dateStringer.formatMinsDurationToHours(
                                item.earlyMins || item.lateMins
                              )}{" "}
                              {item.earlyMins ? "early" : "late"}
                            </p>
                          </div>
                        )
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <div
                      className={`clocksSchedSubRight ${
                        item.loggedExcess ? "x493984892" : ""
                      } ${memoVals.mob ? "flexEnd" : ""}`}
                    >
                      {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                      <p
                        className={`approveClock x2978457 ${
                          item.loggedExcess ? "x498984398334" : ""
                        } ${
                          item.blockApprove
                            ? "unableToApproveOwn greyColours"
                            : ""
                        } ${
                          approveLoading === item.shiftID
                            ? "disabledApprove"
                            : ""
                        }`}
                        onClick={() => {
                          if (item.blockApprove) {
                            setBlockApprove("logged");
                          } else {
                            setApproveLoading(item.shiftID);
                            // setApproveLoading(false);
                            axios
                              .post(
                                `${serverURL}/approve-logged-times`,
                                {
                                  shiftID: item.shiftID,
                                  type: item.itemType,

                                  nowDs: dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                  recordLate: recordLateItemIDs.includes(
                                    item.shiftID
                                  )
                                    ? parseInt(item.lateMins)
                                    : false,
                                  recordLateUnpaid:
                                    recordLateUnpaidItemIDs.includes(
                                      item.shiftID
                                    )
                                      ? true
                                      : false,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message.includes("logout")) {
                                  window.location.href = "/signin";
                                }
                                if (response.data.message === "success") {
                                  setCountData({
                                    loggedShifts: countData.loggedShifts - 1,
                                  });
                                  //
                                  memoVals.setRemoveLoggedShiftID(
                                    (x) => item.shiftID
                                  );
                                  setItemsArr(
                                    itemsArr.filter((xx) => {
                                      if (
                                        xx.type === "logged" &&
                                        xx.shiftID === item.shiftID
                                      ) {
                                      } else {
                                        return xx;
                                      }
                                    })
                                  );
                                  memoVals.setIndicate((x) => {
                                    return {
                                      show: true,
                                      message: `Logged times approved`,
                                      colour: "green",
                                      duration: 4000,
                                    };
                                  });
                                }
                              });
                          }
                        }}
                      >
                        {item.loggedExcess ? "" : "Approve"}{" "}
                        <img
                          src={whiteTick}
                          alt="Approve clock card"
                          className={`approveCCtickimg ${
                            item.loggedExcess ? "x94893843" : ""
                          }`}
                        />
                      </p>
                    </div>
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}
                </div>
              );
            }
            if (group === "hours" && item.type === "declined") {
              return (
                <div
                  key={i}
                  className={`reqUnitParentNotif ${
                    (!hoursLogged &&
                      !hoursClocks &&
                      !hoursTimesheets &&
                      !hoursDeclined) ||
                    ((hoursLogged ||
                      hoursDeclined ||
                      hoursClocks ||
                      hoursTimesheets) &&
                      hoursDeclined)
                      ? ""
                      : "none"
                  }`}
                >
                  <div
                    onClick={() => {
                      // open declined shift modal here
                      memoVals.setShowDeclinedMShiftModal((x) => item.itemID);
                    }}
                    className={`mgrReqItem mgrReqItemNotifReq pend ${
                      memoVals.mob ? "borderRadius10x" : ""
                    } leaveReqItem ccNotifItem ccNotifItemLogged`}
                  >
                    {" "}
                    <div className="unsubmittedTsBlob annLeaveBlob ccMgrNotifBlob bgBa00002"></div>
                    <div className="reqColBarDiv divPend"></div>
                    <div
                      className={`mgrReqItemCol2 ${
                        memoVals.mob ? "mgrReqItemCol2Mob" : ""
                      }`}
                    >
                      <div className="mgrReqTopRow">
                        <div className="mgrReqName mgrReqName2">
                          {/* ------ */}
                          <div
                            className={`mgrReqDateStrip pendDateDiv x29898988444 x29898988444233 x29898988444233Logged`}
                          >
                            <div
                              className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons `}
                            >
                              <img
                                src={unitCross}
                                alt="Request duration"
                                className="rotaSunReqs leaveNameBurger leaveNameBurgerNotifReq fullOpac"
                              />
                              Declined{" "}
                              {item.itemType === "shift" ? "shift" : "overtime"}
                            </div>
                            {/* <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow `}
                            >
                              <img
                                src={rotaDate}
                                alt="Request duration"
                                className="rotaSunReqs x4985982567876 x29849835"
                              />

                              {dateStringer.printedDateFromDs(
                                item.startDs,
                                true
                              )}
                            </div> */}
                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow upcomingMgrReqsSpanDur`}
                            >
                              <img
                                src={rotaReason}
                                alt="Request duration"
                                className="rotaSunReqs x29849835"
                              />
                              {dateStringer.shorten(
                                item.reason || "No reason",
                                16
                              )}
                              {/* <span className="colour00aaff">requested</span> */}
                            </div>

                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan mgrNotifDataRow upcomingMgrReqsSpanDur bbcada_`}
                            >
                              <img
                                src={rotaTeam}
                                alt="Request team"
                                className="rotaSunReqs x4985982567876 x29849835"
                              />

                              {dateStringer.shorten(item.teamName, 22)}
                            </div>
                          </div>
                          {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                        </div>
                        <div className="teamShRightBlock">
                          <div className="absTimelineVal absTimelineValReqs rowReverse marginRight0">
                            <div className={`shItemTimes`}>
                              <p className="shItemTimeRow decShBorder">
                                {dateStringer.dsToTimeStrip(item.startDs, true)}
                              </p>
                              <div className="shItemTimeRowSplitter"></div>
                              <p className="shItemTimeRow decShBorder">
                                {dateStringer.dsToTimeStrip(item.endDs, true)}
                              </p>
                            </div>
                            <div className={`absCalDiv`}>
                              <div className="absCalDivUpper">
                                <div className="absDivCalNip"></div>
                                <div className="absDivCalNip"></div>
                              </div>
                              <div
                                className={`absCalDivLower absCalDivLowerAllTime decShBorder`}
                              >
                                {/* <p className="calDivUpperThreeLetterDay">{dateStringer.getThreeDayLettersFromDs(unit.startDs)}</p> */}
                                <p className="absCalDivLowerMonth absCalDivLowerMonthReqs absCalDivLowerMonthReqs2_">
                                  {dateStringer.getThreeDayLettersFromDs(
                                    item.startDs
                                  )}
                                </p>{" "}
                                <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                                  {parseInt(item.startDs.substr(9, 2))}
                                </p>
                                <p className="absCalDivLowerYear">
                                  {dateStringer.getMonthThreeLettersFromDs(
                                    item.startDs
                                  )}
                                  {/* {unit.startDs.substr(1, 4)} */}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`mgrReqNameTab mgrReqNameTab2 pendClockName x34986498542 x435397859383 x435397859383Logged`}
                    onClick={() => {
                      memoVals.setShowEditUser((x) => {
                        return { userID: item.userID };
                      });
                    }}
                  >
                    <img
                      src={item.profPicUrl || profilePicturePlaceholder}
                      alt={item.fName}
                      className="itemNameTabImg"
                    />{" "}
                    {item.fName} {item.lName}
                    {item.you ? <span className="youSpan">(You)</span> : ""}
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}

                  <div
                    className={`mgrClockSubBar ${
                      memoVals.mob ? "mgrClockSubBarMob" : ""
                    } pendClockSub notifReqSubBarReq`}
                  >
                    <div
                      className={`clocksSchedSubLeft ${
                        memoVals.mob ? "font12px" : ""
                      }`}
                    >
                      {item.earlyMins || item.lateMins ? (
                        <div className="mgrNotifDsMade">
                          <p className="mgrNotifSchedTitle">Started</p>
                          <p
                            className={`mgrNotifSchedMain fontSize14 ${
                              item.lateMins ? "ba0000" : ""
                            }`}
                          >
                            {dateStringer.formatMinsDurationToHours(
                              item.earlyMins || item.lateMins
                            )}{" "}
                            {item.earlyMins ? "early" : "late"}
                          </p>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <div
                      className={`clocksSchedSubRight  ${
                        memoVals.mob ? "flexEnd" : ""
                      }`}
                    >
                      {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                      <p
                        className={`approveClock x2978457 staffMgrReqsActionBtn ${
                          item.blockApprove
                            ? "unableToApproveOwn greyColours"
                            : ""
                        } ${
                          approveLoading === item.shiftID
                            ? "disabledApprove"
                            : ""
                        }`}
                        onClick={() => {
                          if (item.blockApprove) {
                            setBlockApprove("hours");
                          } else {
                            // open declined shift modal here
                            memoVals.setShowDeclinedMShiftModal(
                              (x) => item.itemID
                            );
                          }
                        }}
                      >
                        Action
                        <img
                          src={navyDots}
                          alt="Approve clock card"
                          className="approveCCtickimg threeWdotsImg"
                        />
                      </p>
                    </div>
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}
                </div>
              );
            }
          })
        )}
      </div>
      {blockApprove ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              You cannot{" "}
              {blockApprove === "form"
                ? "sign off"
                : blockApprove === "hours"
                ? "amend"
                : "approve"}{" "}
              your own{" "}
              {blockApprove === "form"
                ? "absence forms"
                : blockApprove === "req"
                ? "requests"
                : blockApprove === "clock"
                ? "clock cards"
                : blockApprove === "timesheet"
                ? "timesheets"
                : blockApprove === "logged"
                ? "logged shifts"
                : blockApprove === "hours"
                ? "hours"
                : ""}
              . <br />
              <br />
              Only another manager from your{" "}
              {blockApprove === "form" || blockApprove === "req"
                ? "primary team"
                : "team"}{" "}
              or a FlowRota admin can approve them.
              <br />
              <br />
              If you require permission to{" "}
              {blockApprove === "form"
                ? "sign off"
                : blockApprove === "hours"
                ? "amend"
                : "approve"}{" "}
              your own{" "}
              {blockApprove === "form"
                ? "forms"
                : blockApprove === "req"
                ? "requests"
                : blockApprove === "clock"
                ? "clock cards"
                : blockApprove === "timesheet"
                ? "timesheets"
                : blockApprove === "logged"
                ? "logged shifts"
                : blockApprove === "hours"
                ? "hours"
                : ""}
              , a FlowRota admin can grant it by following{" "}
              <a
                href="https://flowrota.com/support/self-approve-permissions"
                target="_blank"
                className="colour00aaff"
              >
                this guide
              </a>
              .
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setBlockApprove("");
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {sureDeclineChange.show ? (
        <div
          className="areYouSureModalUnderlay"
          onClick={() => {
            setSureDeclineChange({ show: false });
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {sureDeclineChange.change ? (
              <p className="overlapsRenTxt">
                Are you sure you want to decline this{" "}
                {sureDeclineChange.drop ? "drop" : "change"} request?
              </p>
            ) : (
              <p className="overlapsRenTxt">
                Are you sure you want to decline this TOIL request?
              </p>
            )}

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  let today00 = new Date();
                  today00.setHours(0, 0, 0, 0);
                  if (sureDeclineChange.change) {
                    setShiftReqsLoading(true);

                    let newArr = [];
                    itemsArr.forEach((obj) => {
                      if (obj.editID !== sureDeclineChange.editID) {
                        newArr.push(obj);
                      }
                    });

                    // let qty =
                    //   countData.shiftEdits + countData.ttrs + countData.drops;

                    // if (
                    //   dateStringer.createTimestampFromString(
                    //     sureDeclineChange.ds
                    //   ) >= today00.getTime()
                    // ) {

                    // qty--;

                    // setReqQty(qty);
                    // }
                    setItemsArr(newArr);
                    setSureDeclineChange({ show: false });

                    axios
                      .post(
                        `${serverURL}/approve-or-decline-shift-edit`,
                        {
                          editID: sureDeclineChange.editID,
                          userID: sureDeclineChange.userID,
                          decision: "decline",
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          // setApproveLoading(false);

                          let newArr = [];
                          itemsArr.forEach((obj) => {
                            if (
                              obj.takeTilRequest ||
                              obj.editID !== sureDeclineChange.editID
                            ) {
                              newArr.push(obj);
                            }
                          });
                          // let qty =
                          //   countData.shiftEdits +
                          //   countData.ttrs +
                          //   countData.drops;
                          if (
                            dateStringer.createTimestampFromString(
                              sureDeclineChange.ds
                            ) >= today00.getTime()
                          ) {
                            if (sureDeclineChange.drop) {
                              setCountData({
                                drops: countData.drops - 1,
                              });
                            } else {
                              setCountData({
                                shiftEdits: countData.shiftEdits - 1,
                              });
                            }
                            // qty--;

                            // setReqQty(qty);
                          }
                          setItemsArr(newArr);
                          setTimeout(() => {
                            setShiftReqsLoading(false);
                          }, 100);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }

                  if (sureDeclineChange.toil) {
                    // axios here we go
                    setShiftReqsLoading(true);

                    let newArr = [];
                    itemsArr.forEach((obj) => {
                      if (
                        !obj.takeTilRequest ||
                        obj.takeTilID !== sureDeclineChange.takeTilID
                      ) {
                        newArr.push(obj);
                      }
                    });
                    // let qty =
                    //   countData.shiftEdits + countData.ttrs + countData.drops;
                    if (
                      dateStringer.createTimestampFromString(
                        sureDeclineChange.ds
                      ) >= today00.getTime()
                    ) {
                      setCountData({
                        ttrs: countData.ttrs - 1,
                      });
                      // if (sureDeclineChange.drop) {
                      // setCountData({
                      //   ttrs: countData.ttrs - 1 < 0 ? 0 : countData.ttrs - 1,
                      // });
                      // } else {
                      //   setCountData({
                      //     shiftEdits:
                      //       countData.shiftEdits - 1 < 0
                      //         ? 0
                      //         : countData.shiftEdits - 1,
                      //   });
                      // }
                      // qty--;
                      // setReqQty(qty);
                    }
                    setSureDeclineChange({ show: false });

                    axios
                      .post(
                        `${serverURL}/approve-or-decline-take-til-request`,
                        {
                          takeTilID: sureDeclineChange.takeTilID,
                          userID: sureDeclineChange.userID,
                          decision: "decline",
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (
                          response.data.message === "success" ||
                          response.data.message.includes("no longer")
                        ) {
                          // setApproveLoading(false);
                          if (response.data.message.includes("no longer")) {
                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: "Request no longer exists",
                                colour: "red",
                                duration: 4000,
                              };
                            });
                          }

                          setItemsArr(newArr);
                          setTimeout(() => {
                            setShiftReqsLoading(false);
                          }, 100);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeclineChange({ show: false });
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showCannotManageOwnShift.show ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setShowCannotManageOwnShift({ show: false });
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <div className="overlapsRenTxt">
              You cannot approve your own swap requests as a manager of your own
              team - you can only accept them (which is not the same as manager
              approval).
              <br />
              <br />
              Only another manager from {showCannotManageOwnShift.teamName} can
              approve your own swap requests unless a FlowRota administrator
              grants you the permissions to self-manage them.
              <br />
              <br />
              For permissions, please speak to a FlowRota administrator.
              <br />
              <br />
              Share this{" "}
              <a
                className="makeBold cursorPointer x8499282"
                href="https://flowrota.com/support/manager-self-permissions"
                target="_blank"
              >
                guidance link
              </a>{" "}
              with them.
            </div>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setShowCannotManageOwnShift({ show: false });
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default NotifReqs;
