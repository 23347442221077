// design and keys done 12/3/25
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import { StyleContext } from "../contexts/StyleContext";
import { CalendarContext } from "../contexts/CalendarContext";
import { DataContext } from "../contexts/DataContext";

import axios from "axios";
import serverURL from "../serverURL";

import "../styles/navbar.css";

import navyClose from "../img/general/navycross.svg";
//
import tagAdd from "../img/general/tagAdd.svg";

import editUserEdit1 from "../img/general/editUserEdit1.svg";
import { AdminContext } from "../contexts/AdminContext";
import dateStringer from "../tools/dateStringer";

const AddShellShiftModal = ({
  addShift,
  setAddShift,
  teamID,
  jobTitles,
  updateShell,
  availableLocations,
  locationID,
  fromRota,
  selectedJt,
  dayNum,
  rotaShift,
  setShowShellID,
  userObjects, // for assign if opened fromRota
  rotaShellID,
  addOpen,
  assignShift,
  setDuplicateShell,
}) => {
  const { mobModal, mob } = useContext(StyleContext);
  const {
    showSpecialDayModal,

    showLeaveTypeModal,
    setShowLeaveTypeModal,
    sureRemoveLeaveType,
    setSureRemoveLeaveType,
    setIndicate,
    modalOpen,
    device,
    setModalOpen,
    updateRotaShell,
    setUpdateRotaShell,
  } = useContext(DataContext);
  const { setCustomLeaveTypes } = useContext(AdminContext);
  const { updateInfo, setUpdateInfo } = useContext(CalendarContext);

  const memoVals = useMemo(
    () => ({
      showSpecialDayModal, //
      showLeaveTypeModal, //
      setShowLeaveTypeModal, //
      setSureRemoveLeaveType, //
      setIndicate, //
      setCustomLeaveTypes, //
      mobModal, //
      mob, //
      modalOpen,
      setModalOpen,
      device,
      updateRotaShell,
      setUpdateRotaShell,
      updateInfo,
      setUpdateInfo,
    }),
    [
      showSpecialDayModal, //
      showLeaveTypeModal, //
      setShowLeaveTypeModal, //
      setSureRemoveLeaveType, //
      setIndicate, //
      setCustomLeaveTypes, //
      mobModal, //
      mob, //
      modalOpen,
      setModalOpen,
      device,
      updateRotaShell,
      setUpdateRotaShell,
      updateInfo,
      setUpdateInfo,
    ]
  );

  let [addShiftType, setAddShiftType] = useState("shift");
  let [tags, setTags] = useState(fromRota ? rotaShift.tags : []);
  let [addNewTag, setAddNewTag] = useState(false);
  let [newTagValue, setNewTagValue] = useState("");
  let addTagInputRef = useRef();
  let [newlyAddedTags, setNewlyAddedTags] = useState([]);
  let [start, setStart] = useState(fromRota ? rotaShift.start : "");
  let [end, setEnd] = useState(fromRota ? rotaShift.end : "");
  let [brk, setBrk] = useState(fromRota ? rotaShift.brk : 0);
  let [durMins, setDurMins] = useState(0);
  let [jtValFromRota, setJtValFromRota] = useState(fromRota ? selectedJt : "");
  let [dayNumFromRota, setDayNumFromRota] = useState(fromRota ? dayNum : "");
  let [selectedLocationID, setSelectedLocationID] = useState(
    fromRota ? rotaShift.locationID : locationID
  );
  let [fromRotaType, setFromRotaType] = useState(
    fromRota ? rotaShift.type : "shift"
  );

  useEffect(() => {
    if (brk) {
      if (parseInt(brk) < 0) {
        setBrk(0);
      }
    }

    if (start && start.length === 5 && end && end.length === 5) {
      // calculate duration
    }
    if (start && start.length === 5 && end && end.length === 5) {
      let startDateObj = new Date();
      startDateObj.setHours(parseInt(start.split(":")[0]));
      startDateObj.setMinutes(parseInt(start.split(":")[1]));
      let endDateObj = new Date();
      endDateObj.setHours(parseInt(end.split(":")[0]));
      endDateObj.setMinutes(parseInt(end.split(":")[1]));
      if (endDateObj.getTime() <= startDateObj.getTime()) {
        endDateObj.setDate(endDateObj.getDate() + 1);
      }
      let totMins = (endDateObj.getTime() - startDateObj.getTime()) / 60000;

      if (brk) {
        let grossMins = totMins - brk;
        setDurMins(grossMins);
        if (grossMins <= 0) {
          setBrk(0);
        }
      } else {
        let grossMins = totMins;
        setDurMins(grossMins);
      }
    }
  }, [start, end, brk]);

  let enterFct = (e) => {
    if (e.key === "Enter") {
      if (newTagValue.length > 0) {
        axios
          .post(
            `${serverURL}/add-tag-to-bank`,
            {
              teamID,
              tag: newTagValue,
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message.includes("logout")) {
              window.location.href = "/signin";
            }
            if (response.data.message === "success") {
              setAvailableTags([...availableTags, newTagValue]);
              setTags([...tags, newTagValue]);
              setNewlyAddedTags([...newlyAddedTags, newTagValue]);
              setAddNewTag(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  useEffect(() => {
    // clean not needed
    if (addTagInputRef.current) {
      addTagInputRef.current.focus();
    }
  }, [addNewTag]);

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);
  //

  let [availableTags, setAvailableTags] = useState([]);

  useEffect(() => {
    if (!availableTags[0]) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-available-tags`,
          {
            teamID: teamID,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((resp) => {
          if (resp.data.message === "success") {
            setAvailableTags(resp.data.tags);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, []);
  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowLeaveTypeModal((x) => {
        return { show: false };
      });
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  let [sureReinstate, setSureReinstate] = useState(false);

  let closeModal = () => {
    if (fromRota) {
      setShowShellID({ id: "" });
    } else {
      setAddShift({ show: false });
    }
  };

  let [makeOpenLoading, setMakeOpenLoading] = useState(false);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  useEffect(() => {
    if (sureReinstate) {
      setSureReinstate(false);
    }
    if (sureRemoveLeaveType) {
      setSureRemoveLeaveType(false);
    }
  }, []);

  let [invalidModal, setInvalidModal] = useState(false);

  let [updated, setUpdated] = useState(false);

  // master return

  return (
    <div
      className={`clockOnModalUnderlay  ${
        sureReinstate || sureRemoveLeaveType ? "hidden_" : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div
        className={`dayCommentsModal ${
          memoVals.mobModal
            ? `mobModalShoulder ${memoVals.device.ios ? "paddingBottom35" : ""}`
            : "maxViewZoneModalWidth"
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {memoVals.mobModal ? (
          <div
            className="modalSwiper modalSwiperViewLeaveType"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          ></div>
        ) : (
          ""
        )}
        <div
          className="mySwapsHeader mySwapsHeaderLT"
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={memoVals.mobModal ? navyClose : navyClose}
            alt="Close"
            className="closeMySwapsModalImg"
            onClick={() => {
              closeModal();
            }}
          />
          <p>
            {fromRota && rotaShift.id === "new"
              ? rotaShift.type === "til"
                ? "New time in lieu shell"
                : rotaShift.type === "overtime"
                ? "New overtime shell"
                : "New shift shell"
              : fromRota
              ? `${
                  rotaShift.type === "til"
                    ? "Time in lieu"
                    : rotaShift.type === "overtime"
                    ? "Overtime"
                    : "Shift"
                } shell`
              : "Add shift to shell"}
          </p>

          <p></p>
        </div>
        <div className="dayCommentsModalBody maxHeight480">
          {fromRota && userObjects && Array.isArray(userObjects) ? (
            <div className="gpsZoneRow noBorder">
              {/* {rotaShift.id === "new" ? (
                <p className="lonLatTitles addShShellTitle createNewShellTitle">
                  Create a new shift shell
                </p>
              ) : (
                ""
              )} */}
              {rotaShift.id === "new" ? (
                <div className="modalExplainer">
                  <div className="modalExplainLeftBlob x23848498844"></div>
                  <p className="modalExplainRightTxt">
                    A shift scheduled for a
                    {selectedJt[0].toLowerCase() === "a" ||
                    selectedJt[0].toLowerCase() === "e" ||
                    selectedJt[0].toLowerCase() === "i" ||
                    selectedJt[0].toLowerCase() === "o" ||
                    selectedJt[0].toLowerCase() === "u"
                      ? "n"
                      : ""}{" "}
                    {selectedJt.toLowerCase()} on a given day, waiting to be
                    assigned to an employee
                  </p>
                </div>
              ) : (
                ""
              )}
              {rotaShift.id !== "new" ? (
                <p className="lonLatTitles addShShellTitle">
                  Assign {rotaShift.type === "shift" ? "shift" : "overtime"}
                </p>
              ) : (
                ""
              )}
              {rotaShift.id !== "new" ? (
                <select
                  className={`assignOpenDropdown assignDropShell ${
                    makeOpenLoading ? "disabled opac05" : ""
                  }`}
                  value=""
                  onChange={(e) => {
                    let val = e.target.value;
                    if (!val) {
                      return;
                    }
                    // assign shell shift to user here
                    setMakeOpenLoading(true);
                    axios
                      .post(
                        `${serverURL}/assign-shell-shift`,
                        {
                          rotaShellID,
                          id: rotaShift.id,
                          userID: val,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          assignShift({
                            newItem: response.data.newItem,
                            shellIDtoRemove: rotaShift.id,
                            payRoll: response.data.payRoll,
                            durMins: response.data.durMins,
                            teamName: response.data.teamName,
                            locationName: response.data.locationName,
                            requireAccept: response.data.requireAccept,
                          });
                          // memoVals.setUpdateInfo(!memoVals.updateInfo);
                          closeModal();
                          setMakeOpenLoading(false);
                        } else {
                          setMakeOpenLoading(false);
                        }
                      });
                  }}
                >
                  <option value="">-- Employee</option>
                  {userObjects
                    .filter((x) => {
                      return x.jobTitle === jtValFromRota;
                    })
                    .sort((a, b) => {
                      return a.fName.localeCompare(b.fName);
                    })
                    .map((user) => {
                      return (
                        <option value={user.userID} key={user.userID}>
                          {user.fName} {user.lName}
                        </option>
                      );
                    })}
                </select>
              ) : (
                ""
              )}

              {rotaShellID && rotaShift.id && rotaShift.id !== "new" ? (
                <p
                  className={`shellMakeOpenBtn ${
                    makeOpenLoading ? "invis" : ""
                  }`}
                  onClick={() => {
                    setMakeOpenLoading(true);
                    axios
                      .post(
                        `${serverURL}/make-shell-shift-open`,
                        {
                          rotaShellID,
                          id: rotaShift.id,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          addOpen({
                            openShift: response.data.openShift,
                            shellIDtoRemove: rotaShift.id,
                          });
                          closeModal();
                          setMakeOpenLoading(false);
                        } else {
                          setMakeOpenLoading(false);
                        }
                      });
                  }}
                >
                  Make open
                </p>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <div
            className={`gpsZoneRow ${
              fromRota && userObjects && Array.isArray(userObjects)
                ? "x4389589456986565 noBorder"
                : "noBorder"
            }`}
          >
            <p className="lonLatTitles addShShellTitle">Job title</p>
            <select
              className="shiftRateDropdown shellJtDrop"
              value={fromRota ? jtValFromRota : addShift.jobTitle}
              onChange={(e) => {
                if (e.target.value) {
                  if (fromRota) {
                    setJtValFromRota(e.target.value);
                    if (!updated) {
                      setUpdated(true);
                    }
                  } else {
                    setAddShift({
                      show: true,
                      jobTitle: e.target.value,
                      day: addShift.day,
                    });
                  }
                }
              }}
            >
              {jobTitles.map((jt) => {
                return (
                  <option value={jt} key={jt}>
                    {fromRota
                      ? selectedJt === jt
                        ? dateStringer.shorten(jt, 25)
                        : jt
                      : addShift.jobTitle === jt
                      ? dateStringer.shorten(jt, 25)
                      : jt}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="gpsZoneRow">
            <p className="lonLatTitles addShShellTitle">Day</p>

            <select
              className="shiftRateDropdown shellDayDrop"
              value={
                fromRota
                  ? dayNumFromRota === 0
                    ? "mon"
                    : dayNumFromRota === 1
                    ? "tue"
                    : dayNumFromRota === 2
                    ? "wed"
                    : dayNumFromRota === 3
                    ? "thu"
                    : dayNumFromRota === 4
                    ? "fri"
                    : dayNumFromRota === 5
                    ? "sat"
                    : "sun"
                  : addShift.day
              }
              onChange={(e) => {
                if (e.target.value) {
                  if (fromRota) {
                    let val = e.target.value;

                    setDayNumFromRota(
                      val === "mon"
                        ? 0
                        : val === "tue"
                        ? 1
                        : val === "wed"
                        ? 2
                        : val === "thu"
                        ? 3
                        : val === "fri"
                        ? 4
                        : val === "sat"
                        ? 5
                        : 6
                    );
                    if (!updated) {
                      setUpdated(true);
                    }
                  } else {
                    setAddShift({
                      show: true,
                      jobTitle: addShift.jobTitle,
                      day: e.target.value,
                    });
                  }
                }
              }}
            >
              <option value="mon">Monday</option>
              <option value="tue">Tuesday</option>
              <option value="wed">Wednesday</option>
              <option value="thu">Thursday</option>
              <option value="fri">Friday</option>
              <option value="sat">Saturday</option>
              <option value="sun">Sunday</option>
            </select>
          </div>
          <div className="gpsZoneRow">
            <p className="lonLatTitles addShShellTitle">Times</p>

            <div className="addShShellTimeDiv">
              <input
                className={`shiftTimeInput`}
                type="time"
                id="toilStart"
                value={start}
                placeholder="09:00"
                onChange={(e) => {
                  setStart(e.target.value);
                  if (!updated && fromRota) {
                    setUpdated(true);
                  }
                }}
                onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
              />{" "}
              until
              <input
                className={`shiftTimeInput `}
                type="time"
                id="toilStart"
                value={end}
                placeholder="17:00"
                onChange={(e) => {
                  setEnd(e.target.value);
                  if (!updated && fromRota) {
                    setUpdated(true);
                  }
                }}
                onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
              />
            </div>
            <div className="addShShellTimeDiv addShShellTimeDivBrk">
              <input
                className={`shiftTimeInput x298489458954`}
                type="number"
                id="toilStart"
                value={brk}
                placeholder="0"
                onChange={(e) => {
                  let value = e.target.value; // Get the raw value directly

                  if (value.includes("-")) {
                    // No need for JSON.stringify
                    setBrk(0); // Reset break to 0 if it contains '-'
                  } else {
                    if (value.length > 1 && value[0] === "0") {
                      setBrk(value.slice(1)); // Remove leading zero
                    } else {
                      setBrk(value); // Otherwise, set normally
                    }
                  }
                  if (!updated && fromRota) {
                    setUpdated(true);
                  }
                }}
              />{" "}
              <p className="addShShellUnpBrkTxt">mins unpaid break</p>
            </div>
            <p className={`addShShellDur ${durMins <= 0 ? "opac05" : ""}`}>
              <span className="colour00aaff">
                {dateStringer.formatMinsDurationToHours(durMins)}
              </span>{" "}
              paid duration
            </p>
          </div>
          <div className="gpsZoneRow">
            <p className="lonLatTitles addShShellTitle">Type</p>

            <select
              className="shiftRateDropdown shellTypeDrop"
              value={fromRota ? fromRotaType : addShiftType}
              onChange={(e) => {
                if (e.target.value) {
                  if (fromRota) {
                    setFromRotaType(e.target.value);
                    if (!updated) {
                      setUpdated(true);
                    }
                  } else {
                    setAddShiftType(e.target.value);
                  }
                }
              }}
            >
              <option value="shift">Shift</option>
              <option value="overtime">Overtime (paid)</option>
              <option value="til">Overtime (time in lieu)</option>
            </select>
          </div>
          <div className="gpsZoneRow">
            <p className="lonLatTitles addShShellTitle">Location</p>

            <select
              className="shiftRateDropdown shellTypeDrop"
              value={selectedLocationID}
              onChange={(e) => {
                setSelectedLocationID(e.target.value);
                if (!updated && fromRota) {
                  setUpdated(true);
                }
              }}
            >
              <option value="">No location</option>
              {availableLocations.map((loc) => {
                return (
                  <option value={loc.locationID} key={loc.locationID}>
                    {loc.locationName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="gpsZoneRow">
            <p className="lonLatTitles addShShellTitle">Tags</p>
            {/* tags here */}
            <div className="tsInlineTags marginTop0 noBorder addShellTagsInline">
              {tags.length === 0 ? (
                <p className="noSchedTagsTsTxt addShellTagsNoTags">No tags</p>
              ) : (
                tags.map((tag, i) => {
                  return (
                    <p
                      className={`tsInlineTagItem activeTsTagsInline newTsTag`}
                      key={i}
                    >
                      {tag}

                      <img
                        src={navyClose}
                        alt="Remove tag"
                        className={`removeTagTsSchedImg `}
                        onClick={() => {
                          setTags(
                            tags.filter((x) => {
                              return x !== tag;
                            })
                          );
                          if (newlyAddedTags.includes(tag)) {
                            axios
                              .post(
                                `${serverURL}/exclude-tag-from-bank`,
                                {
                                  teamID,
                                  tag: tag,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message.includes("logout")) {
                                  window.location.href = "/signin";
                                }
                                if (response.data.message === "success") {
                                  setNewlyAddedTags(
                                    newlyAddedTags.filter((x) => {
                                      return x !== tag;
                                    })
                                  );
                                  setTags(tags.filter((x) => x !== tag));
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                          if (!updated && fromRota) {
                            setUpdated(true);
                          }
                        }}
                      />
                    </p>
                  );
                })
              )}
            </div>{" "}
            {addNewTag ? (
              <div
                className="addTagUnit addTagUnitShell"
                onClick={() => {
                  setNewTagValue("");
                }}
              >
                <input
                  className="tagInput"
                  placeholder="New tag"
                  ref={addTagInputRef}
                  onKeyPress={(e) => {
                    enterFct(e);
                  }}
                  type="text"
                  // defaultValue={newTagValue}
                  value={newTagValue}
                  onChange={(e) => {
                    // if (newTagValue.length < 18) {
                    setNewTagValue(e.target.value);
                    // }
                  }}
                ></input>
                <img
                  className="addTagIcon"
                  src={tagAdd}
                  alt="Add tag"
                  onClick={() => {
                    if (newTagValue.length > 0) {
                      axios
                        .post(
                          `${serverURL}/add-tag-to-bank`,
                          {
                            teamID,
                            tag: newTagValue,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setAvailableTags([...availableTags, newTagValue]);
                            setTags([...tags, newTagValue]);
                            setNewlyAddedTags([...newlyAddedTags, newTagValue]);
                            setAddNewTag(false);
                            if (!updated && fromRota) {
                              setUpdated(true);
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <select
              type="number"
              className="shiftRateDropdown shellDayDrop"
              value=""
              onChange={(e) => {
                let val = e.target.value;

                if (!val) {
                  return;
                }

                if (val === `~new`) {
                  setAddNewTag(true);
                }

                setTags([...tags, val]);
                if (!updated && fromRota) {
                  setUpdated(true);
                }
              }}
            >
              {" "}
              <option value="">Add tag</option>
              {availableTags.map((tag, i) => {
                if (!tags.includes(tag)) {
                  return (
                    <option key={i} value={tag}>
                      {tag}
                    </option>
                  );
                }
              })}{" "}
              <option value="~new">-- New tag</option>
            </select>
          </div>
        </div>
        <div className="dayCommentsModalFooter">
          <div className="viewMapsAndSaveDiv">
            <p
              className={`saveGpsZoneBtn x34895895677 ${
                start && start.length === 5 && end && end.length === 5
                  ? ""
                  : "disabled defaultCursor"
              } ${
                fromRota && !updated && rotaShift.id !== "new"
                  ? "x48484585666"
                  : ""
              }`}
              onClick={() => {
                if (start && start.length === 5 && end && end.length === 5) {
                  if (fromRota) {
                    // update shell item here
                    axios
                      .post(
                        `${serverURL}/update-shell-item`,
                        {
                          rotaShellID,
                          start,
                          end,
                          brk: parseInt(brk),
                          tags,
                          locationID: selectedLocationID,
                          type: fromRotaType,
                          id: rotaShift.id,
                          dayNum: dayNumFromRota,
                          jobTitle: jtValFromRota,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          memoVals.setUpdateRotaShell(
                            !memoVals.updateRotaShell
                          );
                          closeModal();
                        }
                      });
                  } else {
                    // add to shell here
                    updateShell({
                      jt: addShift.jobTitle,
                      day: addShift.day,
                      locationID: selectedLocationID,
                      start,
                      end,
                      brk,
                      durMins,
                      type: addShiftType,
                      tags,
                    });
                    closeModal();
                  }
                } else {
                  setInvalidModal(true);
                }
              }}
            >
              {fromRota ? "Save" : "Add to shell"}
            </p>
            {fromRota ? (
              <p
                className={`saveGpsZoneBtn x34895895677 greyColoursDelete deleteFromShell ${
                  rotaShift.id === "new" ? "invis" : ""
                }`}
                onClick={() => {
                  // remove from shell
                  axios
                    .post(
                      `${serverURL}/update-shell-item`,
                      {
                        rotaShellID,
                        start,
                        end,
                        brk,
                        tags,
                        locationID: selectedLocationID,
                        type: fromRotaType,
                        id: rotaShift.id,
                        dayNum: dayNumFromRota,
                        jobTitle: jtValFromRota,
                        remove: true,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        memoVals.setUpdateRotaShell(!memoVals.updateRotaShell);
                        closeModal();
                      }
                    });
                }}
              >
                Remove
              </p>
            ) : (
              ""
            )}
            {fromRota &&
            rotaShift &&
            rotaShift.id &&
            rotaShift.id !== "new" &&
            !updated ? (
              <p
                className={`saveGpsZoneBtn x34895895677 lilacColours deleteFromShell `}
                onClick={() => {
                  // duplicate button
                  setDuplicateShell(rotaShift);
                  memoVals.setIndicate((x) => {
                    return {
                      show: true,
                      message: "Select where to duplicate shell item",
                      colour: "orange",
                      duration: 1500,
                    };
                  });
                  closeModal();
                }}
              >
                Duplicate
              </p>
            ) : (
              ""
            )}
          </div>

          <p
            className="closeReqInfo"
            onClick={() => {
              closeModal();
            }}
          >
            {rotaShift && rotaShift.id && rotaShift.id === "new"
              ? "Cancel"
              : "Close"}
          </p>
        </div>
      </div>

      {invalidModal ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">Please enter a start and end time.</p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setInvalidModal(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AddShellShiftModal;
