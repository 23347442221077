// css is in calendar.css
import React, { useContext, useMemo } from "react";
// cleaned wed 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";

import swap2 from "../img/general/swap2white.svg";
import whiteQuestionMark from "../img/general/whiteQuestionMark.svg";
import topBarClock from "../img/general/topBarClock.svg";
import msg from "../img/general/msg.svg";
import person from "../img/general/whiteTick.svg";

const TopBar = ({ teamSchedule }) => {
  const { checkUserState } = useContext(UserContext);

  const {
    setShowUnclocked,
    activeClocksQty,
    setShowActiveClocks,
    setShowMessages,
    msgQty,
    inboundSwapsQty,
    outboundSwapsQty,
    setShowSwapsModal,
    setShowTeamSwapsModal,
    teamSwapsQty,
    upcomingRequiringClocks,
    unclockedQty,
    mgrHasAcceptTeams,
    // acceptDecline,
    setShowMgrAcceptanceModal,
    setShowTrialExpiredModal,
    countData,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      checkUserState, //
      setShowUnclocked, //
      activeClocksQty, //
      setShowActiveClocks, //
      setShowMessages, //
      msgQty, //
      inboundSwapsQty, //
      outboundSwapsQty, //
      setShowSwapsModal, //
      setShowTeamSwapsModal, //
      teamSwapsQty, //
      upcomingRequiringClocks, //
      unclockedQty, //
      mgrHasAcceptTeams, //
      // acceptDecline, //
      setShowMgrAcceptanceModal, //
      setShowTrialExpiredModal,
      // countData,
    }),
    [
      checkUserState, //
      setShowUnclocked, //
      activeClocksQty, //
      setShowActiveClocks, //
      setShowMessages, //
      msgQty, //
      inboundSwapsQty, //
      outboundSwapsQty, //
      setShowSwapsModal, //
      setShowTeamSwapsModal, //
      teamSwapsQty, //
      upcomingRequiringClocks, //
      unclockedQty, //
      mgrHasAcceptTeams, //
      // acceptDecline, //
      setShowMgrAcceptanceModal, //
      setShowTrialExpiredModal,
      // countData,
    ]
  );

  return (
    <div
      className={`TopBar ${
        teamSchedule
          ? `teamSchedTopBarSize ${
              window.location.href.includes("rota") ||
              window.location.href.includes("staff-calendar") ||
              window.location.href.includes("report")
                ? "teamSchedTopBarSizeRota"
                : ""
            }`
          : ""
      }`}
    >
      {/* <div
        className={`unclockedTab ${memoVals.msgQty > 0 ? "blueBg" : ""}`}
        onClick={() => {
          memoVals.setShowMessages((x) => true);
        }}
      >
        <img src={msg} className="topBarMsgIcon" alt="Messages" />
        <p className={`unclockedQty xc209233 ${memoVals.msgQty > 0 ? "" : ""}`}>
          {memoVals.msgQty}
        </p>
      </div> */}
      <div
        className={`${
          countData.unclocked > 0 ? "unclockedTab pinkColoursTopBar" : "none"
        }`}
        onClick={() => {
          if (memoVals.checkUserState.expired) {
            memoVals.setShowTrialExpiredModal((x) => true);
          } else {
            memoVals.setShowUnclocked((x) => true);
          }
        }}
      >
        <img
          src={whiteQuestionMark}
          className="unclockedTabMessagesImg"
          alt="Unclocked"
        />

        <p className="unclockedTabTxt">No shows</p>
        <p className="unclockedQty">{countData.unclocked}</p>
      </div>

      <div
        className={`unclockedTab pinkColoursTopBar ${
          countData.currClocked > 0 || memoVals.upcomingRequiringClocks
            ? ""
            : "none"
        }`}
        onClick={() => {
          if (memoVals.checkUserState.expired) {
            memoVals.setShowTrialExpiredModal((x) => true);
          } else {
            memoVals.setShowActiveClocks((x) => true);
          }
        }}
      >
        {" "}
        <img src={topBarClock} className="topBarClockedOnIcon" alt="Messages" />
        <p className="unclockedTabTxt">
          Today
          {/* {memoVals.activeClocksQty > 0 ? "Clocked on" : "Upcoming"} */}
        </p>
        {countData.currClocked > 0 ? (
          <p className="unclockedQty greenBg">
            {countData.currClocked > 99 ? "99+" : countData.currClocked}
          </p>
        ) : memoVals.upcomingRequiringClocks > 0 ? (
          <p className="unclockedQty">
            {memoVals.upcomingRequiringClocks > 99
              ? `99+`
              : memoVals.upcomingRequiringClocks}
          </p>
        ) : (
          ""
        )}
      </div>
      {memoVals.checkUserState.permissions < 3 &&
      memoVals.mgrHasAcceptTeams &&
      countData.accepted + countData.declined > 0 ? (
        <div
          className="unclockedTab pinkColoursTopBar"
          onClick={() => {
            if (memoVals.checkUserState.expired) {
              memoVals.setShowTrialExpiredModal((x) => true);
            } else {
              memoVals.setShowMgrAcceptanceModal((x) => true);
            }
          }}
        >
          <img
            src={person}
            className="unclockedTabMessagesImg x238929j"
            alt="Messages"
          />
          <p className="unclockedTabTxt">Accepted</p>
          <p
            className={`unclockedQty greenBg ${
              countData.accepted <= 0 ? "none" : ""
            }`}
          >
            {countData.accepted > 99
              ? "99+"
              : countData.accepted < 0
              ? 0
              : countData.accepted}
          </p>{" "}
          <p
            className={`unclockedQty redBg ${
              countData.declined <= 0 ? "none" : ""
            }`}
          >
            {countData.declined > 99
              ? "99+"
              : countData.declined < 0
              ? 0
              : countData.declined}
          </p>
        </div>
      ) : (
        ""
      )}
      {memoVals.checkUserState.permissions < 3 ? (
        <div
          className={`unclockedTab pinkColoursTopBar ${
            countData.staffSwaps > 0 ? "" : "navyColoursTopBar"
          }`}
          onClick={() => {
            if (memoVals.checkUserState.expired) {
              memoVals.setShowTrialExpiredModal((x) => true);
            } else {
              memoVals.setShowTeamSwapsModal((x) => true);
            }
          }}
        >
          <img src={swap2} className="unclockedTabMessagesImg" alt="Messages" />
          <p className="unclockedTabTxt">Staff swaps</p>
          <p
            className={`unclockedQty  ${
              countData.staffSwaps <= 0 ? "none" : ""
            }`}
          >
            {countData.staffSwaps > 99 ? "99+" : countData.staffSwaps}
          </p>
        </div>
      ) : (
        ""
      )}
      <div
        className="unclockedTab blueColours border1px"
        onClick={() => {
          if (memoVals.checkUserState.expired) {
            memoVals.setShowTrialExpiredModal((x) => true);
          } else {
            memoVals.setShowSwapsModal((x) => true);
          }
        }}
      >
        <img src={swap2} className="unclockedTabMessagesImg" alt="Messages" />
        <p className="unclockedTabTxt">My swaps</p>
        {countData.inboundSwaps > 0 || countData.outboundSwaps > 0 ? (
          <p
            className={`unclockedQty ${
              countData.inboundSwaps > 0 ? "" : "none"
            }`}
          >
            {countData.inboundSwaps > 99 ? "99+" : countData.inboundSwaps}
          </p>
        ) : (
          ""
        )}
        {countData.inboundSwaps > 0 || countData.outboundSwaps > 0 ? (
          <p
            className={`unclockedQty ${
              countData.outboundSwaps > 0 ? "blueBg" : "none"
            }`}
          >
            {countData.outboundSwaps > 99 ? "99+" : countData.outboundSwaps}
          </p>
        ) : (
          <p className="emptyOutboundQty"></p>
        )}
      </div>
    </div>
  );
};

export default TopBar;
