import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 aug 24

import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";

import axios from "axios";
import serverURL from "../serverURL";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import rotaClock from "../img/general/rotaClock.svg";
import rotaClockWhite from "../img/general/rotaClockWhite.svg";
import rotaCoffee from "../img/general/rotaCoffee.svg";
import navyClose from "../img/general/navycross.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

const ViewTeamDayModal = ({ teamID, ds, closeModal, isMgr }) => {
  const { mobModal } = useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);
  const {
    showMessages,
    setShowEditUser,
    showEditUser,
    setShowMyShiftModal,
    showUserReqFromNav,
    setShowUserReqFromNav,
    setShowReqMgrModal,
    setShowEditShiftModal,
    showReqMgrModal,
    modalOpen,
    device,
    setModalOpen,
  } = useContext(DataContext);

  let [isAtTop, setIsAtTop] = useState(true);
  const memoVals = useMemo(
    () => ({
      mobModal, //
      checkUserState, //
      showMessages, //
      setShowEditUser, //
      showEditUser, //
      setShowMyShiftModal, //
      setShowUserReqFromNav, //
      setShowReqMgrModal,
      setShowEditShiftModal,
      showUserReqFromNav,
      showReqMgrModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      mobModal, //
      checkUserState, //
      showMessages, //
      setShowEditUser, //
      showEditUser, //
      setShowMyShiftModal, //
      setShowUserReqFromNav, //
      setShowReqMgrModal,
      setShowEditShiftModal,
      showUserReqFromNav,
      showReqMgrModal,
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 4) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={rotaClock}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyClose}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClockWhite}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaCoffee}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      closeModal(false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let [jts, setJts] = useState([]);

  let [teamName, setTeamName] = useState("");

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-team-data-for-day`,
        {
          teamID,
          ds,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setJts(response.data.jtArr);
          setTeamName(response.data.teamName || "Team view");
          setDataLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal(false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  // master return
  return (
    <div
      className={`clockOnModalUnderlay ${
        memoVals.showMessages ? "msgsShownUserSchedUnderlay" : ""
      } ${
        memoVals.showEditUser ||
        memoVals.showUserReqFromNav ||
        memoVals.showReqMgrModal
          ? "none"
          : ""
      }`}
      onClick={() => {
        closeModal(false);
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`seeUserNonWorkingDaysModal teamCalDayModalBody ${
            memoVals.mobModal
              ? `seeUserModalMob mobModalShoulder ${
                  memoVals.device.ios ? "paddingBottom35" : ""
                }`
              : ""
          } `}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {" "}
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperViewTeamDay"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="mySwapsHeader fontSize16"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={memoVals.mobModal ? navyClose : navyClose}
              alt="Close"
              className="closeMySwapsModalImg"
              onClick={() => {
                closeModal(false);
              }}
            />
            <p>
              {" "}
              {dateStringer.shorten(
                teamName,
                memoVals.mobModal ? 27 : 18
              )} - {dateStringer.printedDateWithYrFromDs(ds)}
            </p>

            <p></p>
          </div>
          <div
            className={`seeUserSchedTable ${
              isAtTop ? "" : "viewTeamDayScrollTopBorder"
            }`}
            onScroll={(e) => {
              if (e.target.scrollTop > 0 && isAtTop) {
                setIsAtTop(false);
              } else {
                if (!isAtTop) {
                  setIsAtTop(true);
                }
              }
            }}
          >
            {/* - - - */}

            {jts.map((jt) => {
              let generateItem = (item) => {
                if (item) {
                  if (item.shiftID) {
                    return (
                      <div
                        className="viewTeamItemShift"
                        onClick={() => {
                          if (isMgr && item.userCanManage) {
                            // open mgr mogdal
                            setShowEditShiftModal((x) => {
                              return {
                                itemID: item.shiftID,
                                itemType: "shift",
                                itemFName: jt.fName,
                                itemLName: jt.lName,
                                itemIsNew: false,
                                itemUserID: jt.userID,
                              };
                            });
                          } else {
                            setShowMyShiftModal((x) => {
                              return {
                                unitType: "shift",
                                unitID: item.shiftID,
                                otherUser:
                                  memoVals.checkUserState.userID !== item.userID
                                    ? true
                                    : false,
                              };
                            });
                          }
                        }}
                      >
                        <p className="viewTeamItemTitle">Shift</p>

                        <div className="viewTeamItemRowParent">
                          <div className="viewTeamItemRow">
                            <img
                              src={rotaClock}
                              alt="Shift time"
                              className="viewTeamRotaClock"
                            />{" "}
                            {dateStringer.dsToTimeStrip(item.start)} -{" "}
                            {dateStringer.dsToTimeStrip(item.end)}
                          </div>{" "}
                          <div className="viewTeamItemRow">
                            <img
                              src={rotaCoffee}
                              alt="View team shift break"
                              className="viewTeamRotaBreak"
                            />{" "}
                            {dateStringer.formatMinsDurationToHours(item.break)}
                          </div>
                        </div>
                      </div>
                    );
                  }

                  if (item.tilID) {
                    let startDs = `${item.ds.substr(0, 11)}H${item.start.substr(
                      0,
                      2
                    )}M${item.start.substr(3, 2)}`;
                    let endDs = `${item.ds.substr(0, 11)}H${item.end.substr(
                      0,
                      2
                    )}M${item.end.substr(3, 2)}`;

                    return (
                      <div
                        className="viewTeamItemShift"
                        onClick={() => {
                          if (isMgr && item.userCanManage) {
                            // open mgr mogdal
                            setShowEditShiftModal((x) => {
                              return {
                                itemID: item.tilID,
                                itemType: item.tilType,
                                itemFName: jt.fName,
                                itemLName: jt.lName,
                                itemIsNew: false,
                                itemUserID: jt.userID,
                              };
                            });
                          } else {
                            setShowMyShiftModal((x) => {
                              return {
                                unitType: item.tilType,
                                unitID: item.tilID,
                                otherUser:
                                  memoVals.checkUserState.userID !== item.userID
                                    ? true
                                    : false,
                              };
                            });
                          }
                        }}
                      >
                        <p className="viewTeamItemTitle">
                          {item.tilType === "til" ? "Time in lieu" : "Overtime"}
                        </p>

                        <div className="viewTeamItemRowParent">
                          <div className="viewTeamItemRow">
                            <img
                              src={rotaClock}
                              alt="Shift time"
                              className="viewTeamRotaClock"
                            />{" "}
                            {dateStringer.dsToTimeStrip(startDs)} -{" "}
                            {dateStringer.dsToTimeStrip(endDs)}
                          </div>{" "}
                          <div className="viewTeamItemRow">
                            <img
                              src={rotaCoffee}
                              alt="View team shift break"
                              className="viewTeamRotaBreak"
                            />{" "}
                            {dateStringer.formatMinsDurationToHours(item.brk)}
                          </div>
                        </div>
                      </div>
                    );
                  }

                  if (item.reqID) {
                    return (
                      <div
                        className={`${
                          item.isAbsence
                            ? "viewTeamItemShiftAbs viewTeamShiftReqAbs"
                            : "viewTeamItemShift viewTeamShiftReq"
                        } ${
                          !item.userCanManage
                            ? memoVals.checkUserState.userID === item.userID
                              ? ""
                              : "disableOpenReq"
                            : ""
                        } ${
                          item.isAbsence
                            ? "viewTeamShiftReqAbs greyColours"
                            : ""
                        }`}
                        onClick={() => {
                          // open leave req here  -- but only if it belongs to the user
                          if (
                            memoVals.checkUserState.userID === item.userID &&
                            !item.userCanManage
                          ) {
                            setShowUserReqFromNav((x) => item.reqID);
                          }

                          if (item.userCanManage) {
                            // open mgr req
                            memoVals.setShowReqMgrModal((x) => item.reqID);
                          }
                        }}
                      >
                        <p
                          className={`viewTeamItemTitl ${
                            item.isAbsence ? "whiteBorderLeft" : "x77"
                          }`}
                        >
                          {item.isAbsence ? "Absence" : "Leave"}
                        </p>

                        <div className="viewTeamItemRowParent">
                          <div className="viewTeamItemRow">
                            {item.leaveName}
                          </div>
                          <div className="viewTeamItemRow">
                            <img
                              src={item.isAbsence ? rotaClockWhite : rotaClock}
                              alt="Shift time"
                              className="viewTeamRotaClock"
                            />{" "}
                            {dateStringer.formatMinsDurationToHours(item.mins)}
                          </div>
                        </div>
                      </div>
                    );
                  }
                }
              };

              return (
                <div className="viewTeamJTrow">
                  <div className="viewTeamDayUserRowJt">
                    {jt.jt}
                    {jt.jt[jt.jt.length - 1] === "s" ? "" : "s"}
                  </div>
                  <div className="viewTeamDayUserRowItems">
                    {jt.users.map((user, i) => {
                      return (
                        <div
                          className={`viewTeamJtUserRow ${
                            jt.users.length === i + 1 ? "noBorder" : ""
                          }`}
                        >
                          <div
                            className={`viewTeamJtLeftUser  ${
                              isMgr ? "cursorPointer" : ""
                            }`}
                            onClick={() => {
                              if (isMgr) {
                                memoVals.setShowEditUser((x) => user.userID);
                              }
                            }}
                          >
                            {user.profPicUrl ? (
                              <img
                                src={user.profPicUrl}
                                alt={user.fName}
                                className="viewTeamDayProPicImg"
                              />
                            ) : (
                              <div className="viewTeamInitials">
                                {user.fName[0]}
                                {user.lName[0]}
                              </div>
                            )}
                            <p className="viewTeamUserName">
                              {user.fName}
                              <br />
                              {user.lName}
                            </p>
                          </div>
                          <div className="viewTeamJtRightUserItems">
                            {user.items && user.items[0] ? (
                              user.items.map((item) => {
                                return generateItem(item);
                              })
                            ) : (
                              <p className="viewTeamNil">Nothing scheduled</p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="userSchedPopUpFooter">
            <p> </p>

            <p
              className="closeSeeUserIDBtn"
              onClick={() => {
                closeModal(false);
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default ViewTeamDayModal;
