// design done 27/2/25
// keys done 5/3/25

import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 sep 24
import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";
import { AdminContext } from "../../../contexts/AdminContext";
import dateStringer from "../../../tools/dateStringer";
import CheckUser from "../../../tools/CheckUser";
import serverURL from "../../../serverURL";

import navyClose from "../../../img/general/navyClose.svg";

const ClosedDaysTimelineModal = ({ userID }) => {
  const { setShowClosedDaysHistory, modalOpen, setModalOpen, device } =
    useContext(DataContext);

  const { mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setShowClosedDaysHistory,
      mobModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setShowClosedDaysHistory, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
    ]
  );
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);
  let [historyData, setHistoryData] = useState([]);

  let [fName, setFName] = useState("");
  let [lName, setLName] = useState("");

  let [loading, setLoading] = useState(true);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    const cancelSource2 = axios.CancelToken.source();

    if (userID) {
      axios
        .post(
          `${serverURL}/get-user-closed-days-timeline`,
          {
            userID,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setFName(response.data.fName);
            setLName(response.data.lName);
            if (response.data.timeline && response.data.timeline[0]) {
              // response.data.timeline.sort(function (a, b) {
              //   return b.ts - a.ts;
              // });
              setHistoryData(response.data.timeline);
            }
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      axios
        .post(
          `${serverURL}/get-client-closed-days-timeline`,
          {
            someData: 1,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource2.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            if (response.data.timeline && response.data.timeline[0]) {
              response.data.timeline.sort(function (a, b) {
                return b.ts - a.ts;
              });
              setHistoryData(response.data.timeline);
            }
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
    };
  }, []);

  let generateHistoryUnits = historyData.map((item) => {
    let dateObj = new Date(item.ts);
    // let closed = true;
    let closedArr = item.closedDays
      ? item.closedDays
      : item.daysClosed
      ? item.daysClosed
      : [];
    let closed = closedArr.includes(dateObj.getDay());
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (item.ds !== dateStringer.createStringFromTimestamp(today.getTime())) {
      return (
        <div
          className={`historyDayUnit ${
            dateObj.getDay() === 0 ? "historySunBorder" : ""
          }`}
          key={item.ts}
        >
          <div className="historyDayUnitMain">
            <p className="historyDayTitle">
              {dateStringer.printedDateFromDs(item.ds)}{" "}
              {`${item.ds ? item.ds.substr(1, 4) : ""}`}
            </p>{" "}
            <p
              className={`${
                item.specOrPublicHol ? "historyDayUnitpublicHol" : "none"
              }`}
            >
              {item.specOrPublicHol}
            </p>
          </div>

          <p
            className={`historyDataTxtStatus ${
              closed ? "historyDataTxtStatusClosed" : ""
            }`}
          >
            {closed ? "Non-workable" : "Workable"}
          </p>
          {/*  KNOB BEGIN */}
          <div
            className={`knobContainer knobContainerHistoryDay `}
            onClick={() => {
              let today = new Date();
              today.setHours(0, 0, 0, 0);
              axios
                .post(
                  `${serverURL}/update-historical-closed-days-object`,
                  {
                    ds: item.ds,
                    data: !closed,
                    userID, // only is this is for a user. If not present, will load for client only
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data) {
                    if (response.data.message.includes("logout")) {
                      window.location.href = "/signin";
                    }
                    if (response.data.message.includes("logout")) {
                      window.location.href = "/signin";
                    }
                    if (response.data.message === "success") {
                      let newArr = [];
                      historyData.forEach((obj) => {
                        if (obj.ds !== item.ds) {
                          newArr.push(obj);
                        } else {
                          newArr.push({
                            ds: item.ds,
                            closed: !closed,
                            ts: item.ts,
                            closedDays: response.data.newClosedDays,
                            specOrPublicHol: response.data.specOrPublicHol,
                          });
                        }
                      });
                      newArr.sort(function (a, b) {
                        return b.ts - a.ts;
                      });
                      setHistoryData(newArr);
                    }
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div
              className={`switchKnob ${!closed ? "knobOn" : "knobOff"}`}
            ></div>

            <div className="knobBg"></div>
          </div>
          {/*  KNOB END */}
        </div>
      );
    }
  });

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      memoVals.setShowClosedDaysHistory((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  // master return
  return (
    <div
      className="bulkEditLimitUnderlay"
      onClick={() => {
        memoVals.setShowClosedDaysHistory((x) => false);
      }}
    >
      <div
        className={`availModalBox ${
          memoVals.mobModal
            ? `closedDaysTimelineModalBoxMob ${
                memoVals.device.ios ? "mobileHourlyRateTimelineIos" : ""
              } mobModalShoulder`
            : ""
        } xr4899555`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {memoVals.mobModal && (
          <div className="modalSwiper closeDaysSwiper"></div>
        )}
        <div
          className={`closedDaysHistoryModalHeader`}
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={navyClose}
            alt="Close"
            className="closeBulkEditLimitModalIcon"
            onClick={() => {
              memoVals.setShowClosedDaysHistory((x) => false);
            }}
          />
          <p
            className={`bulkEditLimitModalHeaderTitle ${
              userID ? "fontSize16" : ""
            } ${loading ? "invis" : ""}`}
          >
            {userID
              ? `${dateStringer.possession(fName)} workable days history`
              : "Closed days history"}
          </p>
          {!userID ? (
            <img
              src={navyClose}
              alt="Close"
              className="closeBulkEditLimitModalIcon bulkEditCloseBlank"
            />
          ) : (
            ""
          )}
        </div>
        {/* <div className="bulkEditTextDiv noBorder" onClick={() => {}}>
          <p className="historicalClosedDaysTxt">
            FlowRota uses this historical data when calculating{" "}
            {userID && !loading
              ? dateStringer.possession(`${fName} ${lName}`)
              : "an employee's"}{" "}
            average hours worked across the reference period
          </p>
        </div> */}

        <div className="modalExplainer modalExplainerWhite">
          <div className="modalExplainLeftBlob modalExplainLeftBlobClosedDays"></div>
          <p className="modalExplainRightTxt fontSize12">
            FlowRota uses this historical data when calculating{" "}
            {userID && !loading
              ? dateStringer.possession(`${fName} ${lName}`)
              : "an employee's"}{" "}
            average hours worked across the reference period.
            <br />
            <br />
            Workable days are where {fName} could have worked regardless of
            whether they were scheduled to work or not. Unworkable days are days
            where {fName} was not contracted to work, such as contracted days
            off.
          </p>
        </div>
        <div className="closedDaysTimelineMainBody">
          {historyData[1] ? (
            generateHistoryUnits
          ) : (
            <p className="noHistAvail">No history data yet</p>
          )}
        </div>
        <div className="closedDaysTimelineFooter">
          <p></p>
          <p
            className="closeReqInfo"
            onClick={() => {
              memoVals.setShowClosedDaysHistory((x) => false);
            }}
          >
            Close
          </p>
        </div>
      </div>
    </div>
  );
};

export default ClosedDaysTimelineModal;
