// design done 27/2/25
// keys done 5/3/25
import React, {
  useContext,
  useEffect,
  useState,
  useReducer,
  useRef,
  useMemo,
} from "react";
// All use effects cleaned as of 22 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 sep 24

import axios from "axios";
import serverURL from "../serverURL";
import websiteURL from "../websiteURL";

import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import close from "../img/modals/close.svg";
import gps1 from "../img/general/gps1.svg";
import gps2 from "../img/general/gps2.svg";
import popup from "../img/general/popup.svg";
import tag from "../img/general/rotaTag.svg";
import greyCross from "../img/general/greyCross.svg";
import undoNavy from "../img/general/undoNavy.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const ClockOnModal = () => {
  const {
    setShowClockOnModal,
    showClockOnModal,
    setIndicate,
    setClockOnID,
    setUserIsClockedOn,
    updateHeaderClocks,
    setUpdateHeaderClocks,
    setUserIsOnClockedBreak,
    setShowMyShiftModal,
    modalOpen,
    setModalOpen,
    device,
    setCountData,
    countData,
  } = useContext(DataContext);
  const { mobModal } = useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      setShowClockOnModal,
      showClockOnModal,
      setIndicate,
      setClockOnID,
      setUserIsClockedOn,
      updateHeaderClocks,
      setUpdateHeaderClocks,
      setUserIsOnClockedBreak,
      mobModal,
      checkUserState,
      modalOpen,
      setModalOpen,
      device,
      setShowMyShiftModal,
    }),
    [
      showClockOnModal,
      setShowClockOnModal, //
      setIndicate, //
      setClockOnID, //
      setUserIsClockedOn, //
      updateHeaderClocks, //
      setUpdateHeaderClocks, //
      setUserIsOnClockedBreak, //
      mobModal, //
      checkUserState, //
      modalOpen,
      setModalOpen,
      device,
      setShowMyShiftModal,
    ]
  );
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let [splitExcessMins, setSplitExcessMins] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 7) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={undoNavy}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={gps1}
        alt="GPS 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={gps2}
        alt="GPS 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup}
        alt="Popup"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={tag}
        alt="Tag"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyCross}
        alt="Grey Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [showBreakNotLongEnoughModal, setShowBreakNotLongEnoughModal] =
    useState(false);
  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      clockOnID1: "",
      teamID: "",
      teamName: "",
      teamsArr: [],
      actualTsClockedOn: 0,
      assocShiftID: "",
      assocTilID: "",
      scheduledStartDs: "",
      scheduledEndDs: "",
      scheduledBreak: 0,
      clockedOnDs: "",
      break1startDs: "",
      break1endDs: "",
      break1mins: 0,
      break1secs: 0,
      break2startDs: "",
      break2endDs: "",
      break2mins: 0,
      break3startDs: "",
      break2endDs: "",
      break3mins: 0,
      break2secs: 0,
      break3secs: 0,

      break1startTs: 0,
      break1endTs: 0,
      break2startTs: 0,
      break2endTs: 0,
      break3startTs: 0,
      break3endTs: 0,
    }
  );
  let nowDs = dateStringer.createStringFromTimestamp(new Date().getTime());

  let shortenZone = (val) => {
    if (val.length > 25) {
      return `${val.substr(0, 23)}...`;
    } else {
      return val;
    }
  };

  let [h, setH] = useState(nowDs.substr(12, 2));
  let [m, setM] = useState(nowDs.substr(15, 2));
  let [s, setS] = useState();
  let [colon, setColon] = useState(false);
  let [clockOnDs, setClockOnDs] = useState(
    dateStringer.createStringFromTimestamp(new Date().getTime())
  );

  let [availableTags, setAvailableTags] = useState([]);
  let [appliedTags, setAppliedTags] = useState([]);
  let [showTags, setShowTags] = useState(false);
  let [teamID, setTeamID] = useState("");
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-available-tags`,
        {
          teamID: teamID,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setAvailableTags(response.data.tags || []);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [teamID]);

  let [showSuggestRefreshGps, setShowSuggestRefreshGps] = useState(false);

  let [durString, setDurString] = useState("");
  let [gpsNeededButUnavail, setGpsNeededButUnavail] = useState(false);
  let [userDeniedPermissions, setUserDeniedPermissions] = useState(false);
  let [clockOnAllowed, setClockOnAllowed] = useState(false);
  let [updateLoading, setUpdateLoading] = useState(true);
  let [acc, setAcc] = useState(0);
  let [zone, setZone] = useState("");
  let [dist, setDist] = useState(0);
  // useEffect(() => {
  //   // cleaned - not needed
  //   let totBrkSecs =
  //     state.break1mins * 60 + state.break2mins * 60 + state.break3mins * 60;
  //   let run = true;
  //   if (state.break1startDs && !state.break1endDs) {
  //     run = false;
  //   }

  //   if (state.break2startDs && !state.break2endDs) {
  //     run = false;
  //   }

  //   if (state.break3startDs && !state.break3endDs) {
  //     run = false;
  //   }

  //   if (run) {
  //     if (durString >= totBrkSecs) {
  //       setDurString2(printSecondsToTimeStrip(durString - totBrkSecs));
  //     }
  //   }
  // }, [durString]);

  let [updateClock, setUpdateClock] = useState(false);
  let [ignorePermissions, setIgnorePermissions] = useState(false);

  let [currLon, setCurrLon] = useState();
  let [currLat, setCurrLat] = useState();
  let [currAcc, setCurrAcc] = useState();
  let [currDis, setCurrDis] = useState();

  let shortenTeamNameDrop = (val) => {
    if (val) {
      if (val.length > 27) {
        return `${val.substr(0, 24)}...`;
      } else {
        return val;
      }
    }
  };

  let [gpsLoaded, setGpsLoaded] = useState(false);
  let [gpsRequired, setGpsRequired] = useState(false);
  useEffect(() => {
    // cleaned - with chatGPT
    if (gpsRequired) {
      let attempts = 0;
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      // Store the GPS watch ID
      let gpsWatchId = null;

      function success(pos) {
        // ... your existing success logic ...
        const crd = pos.coords;

        if (crd.accuracy > 200) {
          setShowSuggestRefreshGps(true);
        } else {
        }
        setAcc(crd.accuracy);

        setCurrLat(crd.latitude);
        setCurrLon(crd.longitude);
        setCurrAcc(crd.accuracy);
        axios
          .post(
            `${serverURL}/find-zone`,
            {
              lon: crd.longitude,
              lat: crd.latitude,
              acc: crd.accuracy, // metres
            },
            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message.includes("logout")) {
              window.location.href = "/signin";
            }
            if (response.data.message === "success") {
              setTimeout(() => {
                setGpsLoaded(true);
              }, 1000);

              setZone(response.data.nearByZone);
              setDist(response.data.dist);
              setCurrDis(response.data.dist);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

      function error(err) {
        // ... your existing error logic ...

        if (err) {
          setGpsNeededButUnavail(true);
          setGpsLoaded(true);
        }
        if (err.code === 1) {
          setUserDeniedPermissions(true);
          setGpsLoaded(true);
        }

        if (err.code === 3) {
          if (attempts < 3) {
            navigator.geolocation.getCurrentPosition(success, error, options);
            attempts++;
          } else {
            setUserDeniedPermissions(true);
            setGpsLoaded(true);
          }
        }
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }

      // Start the GPS watch and store the watch ID
      gpsWatchId = navigator.geolocation.getCurrentPosition(
        success,
        error,
        options
      );

      // Clean up the GPS watch when the component unmounts
      return () => {
        if (gpsWatchId !== null) {
          navigator.geolocation.clearWatch(gpsWatchId);
        }
      };
    }
  }, [gpsRequired]);
  let generateTeams = state.teamsArr.map((team) => {
    return (
      <option key={team.teamID} value={team.teamID}>
        {shortenTeamNameDrop(team.teamName)}
      </option>
    );
  });

  let undoTxt = () => {
    if (state.clockedOnDs && !state.break1startDs) {
      return "Undo clock on";
    }

    if (state.break1startDs && !state.break1endDs) {
      return "Undo start break 1";
    }

    if (state.break1endDs && !state.break2startDs) {
      return "Undo end break 1";
    }

    if (state.break2startDs && !state.break2endDs) {
      return "Undo start break 2";
    }

    if (state.break2endDs && !state.break3startDs) {
      return "Undo end break 2";
    }

    if (state.break3startDs && !state.break3endDs) {
      return "Undo start break 3";
    }
    if (state.break3endDs) {
      return "Undo end break 3";
    }
  };

  let breakStartOrEnd = (returnNum) => {
    if (!state.break1startDs) {
      if (returnNum) {
        return 1;
      }
      return "Start";
    }

    if (state.break1startDs && !state.break1endDs) {
      if (returnNum) {
        return 1;
      }
      return "End";
    }

    if (state.break1endDs && !state.break2startDs) {
      if (returnNum) {
        return 2;
      }
      return "Start";
    }

    if (state.break2startDs && !state.break2endDs) {
      if (returnNum) {
        return 2;
      }
      return "End";
    }

    if (state.break2endDs && !state.break3startDs) {
      if (returnNum) {
        return 3;
      }
      return "Start";
    }

    if (state.break3startDs && !state.break3endDs) {
      if (returnNum) {
        return 3;
      }
      return "End";
    }
  };

  let printSecondsToTimeStrip = (value) => {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
  };

  let [durString2, setDurString2] = useState("");

  useEffect(() => {
    // cleaned up - with ChatGPT
    if (state.clockedOnDs) {
      let factory = () => {
        let secDiff = 0;
        let clockedOnTs = state.actualTsClockedOn;
        let now = new Date();
        secDiff = Math.round((now.getTime() - clockedOnTs) / 1000);

        setDurString(secDiff);

        let prepend0 = (val) => {
          if (val < 10) {
            return `0${val}`;
          } else {
            return val;
          }
        };
        let hr = now.getHours();
        let mins = now.getMinutes();
        let secs = now.getSeconds();

        setH(prepend0(hr));
        setM(prepend0(mins));

        setS(prepend0(secs));

        let sec = now.getSeconds();
        const flash = sec % 2 == 0 ? true : false;
        if (flash) {
          setColon(true);
        } else {
          setColon(false);
        }

        setClockOnDs(dateStringer.createStringFromTimestamp(now.getTime()));

        let break1Active = state.break1startDs && !state.break1endDs;
        let break2Active = state.break2startDs && !state.break2endDs;
        let break3Active = state.break3startDs && !state.break3endDs;

        let break1Complete = state.break1startDs && state.break1endDs;
        let break2Complete = state.break2startDs && state.break2endDs;
        let break3Complete = state.break3startDs && state.break3endDs;

        let brkSecs = 0;
        if (break1Complete) {
          brkSecs += state.break1endTs - state.break1startTs;
        }

        if (break2Complete) {
          brkSecs += state.break2endTs - state.break2startTs;
        }

        if (break3Complete) {
          brkSecs += state.break3endTs - state.break3startTs;
        }

        if (break1Active) {
          brkSecs += new Date().getTime() - state.break1startTs;
        }

        if (break2Active) {
          brkSecs += new Date().getTime() - state.break2startTs;
        }

        if (break3Active) {
          brkSecs += new Date().getTime() - state.break3startTs;
        }

        let paidMins = (new Date().getTime() - state.actualTsClockedOn) / 1000;

        let currBreakSecs = 0;
        if (break1Complete) {
          let break1Secs = (state.break1endTs - state.break1startTs) / 1000;
          currBreakSecs += break1Secs;
        }
        if (break2Complete) {
          let break2Secs = (state.break2endTs - state.break2startTs) / 1000;
          currBreakSecs += break2Secs;
        }
        if (break3Complete) {
          let break3Secs = (state.break3endTs - state.break3startTs) / 1000;
          currBreakSecs += break3Secs;
        }

        if (break1Active) {
          let break1Secs = (new Date().getTime() - state.break1startTs) / 1000;
          currBreakSecs += break1Secs;
        }

        if (break2Active) {
          let break2Secs = (new Date().getTime() - state.break2startTs) / 1000;
          currBreakSecs += break2Secs;
        }

        if (break3Active) {
          let break3Secs = (new Date().getTime() - state.break3startTs) / 1000;
          currBreakSecs += break3Secs;
        }
        setDurString2(paidMins - currBreakSecs);
        setLiveBrkSecs(Math.round(currBreakSecs));

        // setLiveBrkSecs(Math.round(brkSecs / 1000));
      };
      factory();

      // Set up the interval and store the interval ID in a variable
      const intervalId = setInterval(() => {
        factory();
      }, 1000);

      // Return the cleanup function to clear the interval when the effect is cleaned up
      return () => {
        clearInterval(intervalId);
      };
    } else {
      let factoryBeforeClockedOn = () => {
        let now = new Date();

        let prepend0 = (val) => {
          if (val < 10) {
            return `0${val}`;
          } else {
            return val;
          }
        };
        let hr = now.getHours();
        let mins = now.getMinutes();
        let secs = now.getSeconds();

        setH(prepend0(hr));
        setM(prepend0(mins));

        setS(prepend0(secs));

        let sec = now.getSeconds();

        const flash = sec % 2 == 0 ? true : false;
        if (flash) {
          setColon(true);
        } else {
          setColon(false);
        }
      };

      factoryBeforeClockedOn();

      // Set up the interval and store the interval ID in a variable
      const intervalId = setInterval(() => {
        factoryBeforeClockedOn();
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [state]);

  useEffect(() => {
    if (gpsLoaded || userDeniedPermissions || gpsNeededButUnavail) {
      setUpdateLoading(false);
    }
  }, [userDeniedPermissions, gpsNeededButUnavail, gpsLoaded, state]);
  let [clockOnDisabled, setClockOnDisabled] = useState(false);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    setUpdateLoading(true);
    setModalPosition({ bottom: "0%", left: "0%" });
    axios
      .post(
        `${serverURL}/initialise-clock-on`,
        {
          startDs: clockOnDs,
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          clockOnID: state.clockOnID1,
          actualTsClockedOn: new Date().getTime(),
          userID:
            typeof memoVals.showClockOnModal === "object" &&
            memoVals.showClockOnModal.userID
              ? memoVals.showClockOnModal.userID
              : "",
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "clock on disabled") {
          setClockOnDisabled(true);
        } else {
          if (
            response.data.message.includes(
              "user clock on/off not enabled on the user"
            )
          ) {
            setClockOnAllowed(false);
          } else {
            setClockOnAllowed(true);
          }
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            let teamID = "";
            let pTeamFilt = response.data.teamsArr.filter(
              (item) => item.order === 0
            );
            if (pTeamFilt[0]) {
              teamID = pTeamFilt[0].teamID;
            }
            if (response.data.forcedTeamID) {
              teamID = response.data.forcedTeamID;
            }

            if (response.data.availableTags && response.data.availableTags[0]) {
              setAvailableTags(response.data.availableTags || []);
            }
            setTeamID(teamID);
            setState({
              clockOnID1: response.data.clockOnID || "",
              // teamID: teamID,
              teamName: response.data.teamName || "",
              actualTsClockedOn: response.data.actualTsClockedOn || 0,
              assocShiftID: response.data.assocShiftID || "",
              assocTilID: response.data.assocTilID || "",
              scheduledStartDs: response.data.scheduledStartDs || "",
              scheduledEndDs: response.data.scheduledEndDs || "",
              scheduledBreak: response.data.scheduledBreak || 0,
              clockedOnDs: response.data.startDs || "",

              break1startDs: response.data.break1startDs || "",
              break1endDs: response.data.break1endDs || "",
              break1mins: response.data.break1durMins || 0,
              break1secs: response.data.break1secs || 0,
              break2startDs: response.data.break2startDs || "",
              break2endDs: response.data.break2endDs || "",
              break2mins: response.data.break2durMins || 0,
              teamsArr: response.data.teamsArr || [],

              break3startDs: response.data.break3startDs || "",
              break3endDs: response.data.break3endDs || "",
              break3mins: response.data.break3durMins || 0,
              break2secs: response.data.break2secs || 0,
              break3secs: response.data.break3secs || 0,

              break1startTs: response.data.break1startTs || 0,
              break1endTs: response.data.break1endTs || 0,
              break2startTs: response.data.break2startTs || 0,
              break2endTs: response.data.break2endTs || 0,
              break3startTs: response.data.break3startTs || 0,
              break3endTs: response.data.break3endTs || 0,
            });

            setAppliedTags(response.data.tags);
            setGpsRequired(
              typeof memoVals.showClockOnModal === "object" &&
                memoVals.showClockOnModal.userID
                ? false
                : response.data.gpsNeeded
            );
            setDataLoaded(true);
            if (
              typeof memoVals.showClockOnModal === "object" &&
              memoVals.showClockOnModal.userID
            ) {
              setGpsLoaded(true);
            }
            setTimeout(() => {
              // setUpdateLoading(false);
              if (!response.data.gpsNeeded) {
                setGpsLoaded(true);
              }
            }, 1000);

            let breakActive = false;
            let breakMins =
              response.data.break1durMins ||
              0 + response.data.break2durMins ||
              0 + response.data.break3durMins;

            let secsToDeduct = 0;
            let ifBreakWhichOne = false;
            if (response.data.break1startDs && !response.data.break1endDs) {
              breakActive = true;
              ifBreakWhichOne = 1;
              let ts = dateStringer.createTimestampFromString(
                response.data.break1startDs
              );
              let nowTs = new Date().getTime();
              secsToDeduct - (nowTs - ts) / 1000;
            }
            if (response.data.break2startDs && !response.data.break2endDs) {
              breakActive = true;
              ifBreakWhichOne = 2;

              let ts = dateStringer.createTimestampFromString(
                response.data.break2startDs
              );
              let nowTs = new Date().getTime();
              secsToDeduct - (nowTs - ts) / 1000;
            }
            if (response.data.break3startDs && !response.data.break3endDs) {
              breakActive = true;
              ifBreakWhichOne = 3;

              let ts = dateStringer.createTimestampFromString(
                response.data.break3startDs
              );
              let nowTs = new Date().getTime();
              secsToDeduct - (nowTs - ts) / 1000;
            }

            memoVals.setUserIsClockedOn(
              (x) =>
                response.data.break3endDs ||
                response.data.break3startDs ||
                response.data.break2endDs ||
                response.data.break2startDs ||
                response.data.break1endDs ||
                response.data.break1startDs ||
                response.data.startDs
            );
            memoVals.setUserIsOnClockedBreak((x) => ifBreakWhichOne);

            // if (breakActive) {
            //   setDurString2(response.data.stampedDurString);
            // }
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [updateClock]);

  let shortenTeamName = (val) => {
    if (val) {
      if (val.length > 13) {
        return `${val.substr(0, 11)}...`;
      } else {
        return val;
      }
    }
  };

  let convertDurStringToMins = (durS) => {
    if (durS) {
      // durS: HH:MM:SS

      // let secs = parseInt(durS.substr(6, 2));
      let mins = parseInt(durS.substr(3, 2));
      let hours = parseInt(durS.substr(0, 2)) * 60;

      return dateStringer.formatMinsDurationToHours(mins + hours);
    }
  };

  let [liveBrkSecs, setLiveBrkSecs] = useState("");

  let status = () => {
    if (state.clockedOnDs && !state.break1startDs) {
      return `Working${dots}`;
    }

    if (state.clockedOnDs && state.break1startDs && !state.break1endDs) {
      return "On break 1";
    }

    if (state.clockedOnDs && state.break1endDs && !state.break2startDs) {
      return `Working${dots}`;
    }

    if (state.clockedOnDs && state.break2startDs && !state.break2endDs) {
      return "On break 2";
    }
    if (state.clockedOnDs && state.break2endDs && !state.break3startDs) {
      return `Working${dots}`;
    }

    if (state.clockedOnDs && state.break3startDs && !state.break3endDs) {
      return "On break 3";
    }

    if (state.clockedOnDs && state.break3endDs) {
      return `Working${dots}`;
    }
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      memoVals.setShowClockOnModal((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  const tagsModalRef = useRef(null);

  const [isDraggingTags, setIsDraggingTags] = useState(false);
  const [dragStartTags, setDragStartTags] = useState({ x: 0, y: 0 });
  const [modalPositionTags, setModalPositionTags] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPositionTags, setDragPositionTags] = useState(0);

  const handleDownTags = (e) => {
    setIsDraggingTags(true);
    setDragStartTags({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMoveTags = (e) => {
    if (isDraggingTags) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStartTags.y;

      if (deltaY > 0) {
        setDragPositionTags(deltaY);
        setModalPositionTags({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };
  useEffect(() => {
    if (showTags) {
      setModalPositionTags({
        bottom: `0px`,
        // left: `calc(0% - ${deltaX}px)`,
      });
    }
  }, [showTags]);

  const handleUpTags = () => {
    setIsDraggingTags(false);

    if (dragPositionTags > 10) {
      if (loading) {
      } else {
        setShowTags(false);
      }
    } else {
      setModalPositionTags({ bottom: "0%", left: "0%" });
    }
  };

  const [dots, setDots] = useState(".");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) =>
        prev === "" ? "." : prev === "." ? ".." : prev === ".." ? "..." : ""
      );
    }, 333); // 1/3 of a second

    return () => clearInterval(interval);
  }, []);

  let [sureUndo, setSureUndo] = useState(false);
  // master return
  return (
    <div
      className="clockOnModalUnderlay"
      onClick={() => {
        memoVals.setShowClockOnModal((x) => false);
      }}
    >
      {clockOnDisabled ? (
        ""
      ) : loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`clockOnModalBox ${
            memoVals.mobModal ? "mobClockModalBox slideUp" : "zoomIn"
          } ${
            memoVals.mobModal && !state.clockedOnDs && !state.scheduledStartDs
              ? `mobNotYetClockedModalBox ${
                  memoVals.device.ios ? "mobNotYetClockedModalBoxIos" : ""
                }`
              : ""
          } ${
            memoVals.mobModal && state.clockedOnDs && !state.break1startDs
              ? `mobClockOnButNoBreakBox ${
                  memoVals.device.ios ? "mobClockOnButNoBreakBoxIos" : ""
                }`
              : ""
          } ${
            memoVals.mobModal &&
            state.clockedOnDs &&
            state.break1startDs &&
            !state.break1endDs
              ? `mobClockOnWithBreak1StartOnlyBox ${
                  memoVals.device.ios
                    ? "mobClockOnWithBreak1StartOnlyBoxIos"
                    : ""
                }`
              : ""
          } ${
            memoVals.mobModal && !state.clockedOnDs && state.scheduledStartDs
              ? `mobScheduledNotClockOnBox ${
                  memoVals.device.ios ? "mobScheduledNotClockOnBoxIos" : ""
                }`
              : ""
          } ${
            memoVals.mobModal &&
            state.clockedOnDs &&
            state.scheduledStartDs &&
            !state.break1startDs
              ? `clockedOnButNoBreak1MobScheduledBox ${
                  memoVals.device.ios
                    ? "clockedOnButNoBreak1MobScheduledBoxIos"
                    : ""
                }`
              : ""
          } ${
            memoVals.mobModal &&
            state.clockedOnDs &&
            state.scheduledStartDs &&
            state.break1startDs
              ? `clockedOnButBreak1MobScheduledBox ${
                  memoVals.device.ios
                    ? "clockedOnButBreak1MobScheduledBoxIos"
                    : ""
                }`
              : ""
          } ${
            memoVals.mobModal &&
            state.clockedOnDs &&
            state.scheduledStartDs &&
            state.break1startDs &&
            state.break1endDs
              ? `clockedOnButBreak1EndMobScheduledBox ${
                  memoVals.device.ios
                    ? "clockedOnButBreak1EndMobScheduledBoxIos"
                    : ""
                }`
              : ""
          }
          ${
            memoVals.mobModal && !state.scheduledStartDs && state.break1endDs
              ? `mobBreak1FinishedNoSchedStartContentHeight ${
                  memoVals.device.ios
                    ? "mobBreak1FinishedNoSchedStartContentHeightIos"
                    : ""
                }`
              : ""
          }
          ${loading ? "none" : ""} ${
            memoVals.mobModal ? "mobModalShoulder" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div className="modalSwiper modalSwiperClockOn"></div>
          )}
          <div
            className={`clockOnModalHeader ${
              memoVals.mobModal && !state.clockedOnDs
                ? "x298429712s"
                : "x394304023d"
            }`}
            onClick={() => {
              if (memoVals.mobModal) {
                memoVals.setShowClockOnModal((x) => false);
              }
            }}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <div className="clockOnModalHeaderSideUnit">
              <img
                src={close}
                alt="Close"
                className="closeClockOnModalImg"
                onClick={() => {
                  memoVals.setShowClockOnModal((x) => false);
                }}
              />{" "}
              <p className="clockOnModalHeaderTitle">Work clock</p>
            </div>

            <div className="clockOnModalHeaderSideUnit">
              {typeof memoVals.showClockOnModal === "object" &&
              memoVals.showClockOnModal.userID ? (
                <p className="clockOnTermTxt">Terminal</p>
              ) : gpsRequired ? (
                <div className="clockGPSBar">
                  {!gpsLoaded ? (
                    <p
                      className={` ${
                        userDeniedPermissions ? "deniedFailedTxt" : "gpsLoading"
                      }`}
                    >
                      {!userDeniedPermissions
                        ? "GPS loading"
                        : "GPS unavailable"}{" "}
                      {userDeniedPermissions ? (
                        <a
                          href={`${websiteURL}/support/gps-settings`}
                          target="_blank"
                          className="gpsSupportBtnTopRight"
                        >
                          Help{" "}
                          <img
                            src={popup}
                            alt="GPS support"
                            className="gpsSettingsSupportPopup"
                          />
                        </a>
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    ""
                  )}

                  {gpsLoaded && userDeniedPermissions ? (
                    <a
                      href={`${websiteURL}/support/gps-settings`}
                      target="_blank"
                      className="clockDenyPerm"
                    >
                      You need to enable location permissions
                    </a>
                  ) : gpsLoaded && !ignorePermissions ? (
                    <div className="gpsConfDiv textAlignLeft">
                      <p className="gpsLoadedTxt">
                        {" "}
                        <img
                          src={gpsLoaded && !ignorePermissions ? gps2 : gps1}
                          className={`gpsIcon ${!gpsLoaded ? "none" : ""} ${
                            gpsLoaded && ignorePermissions ? "none" : ""
                          }
                    topRightGPSicon`}
                          alt="GPS"
                        />
                        GPS confirmed{" "}
                        {acc > 100 ? (
                          <span className="gpsLoadedSubTxtAcc">
                            Accuracy: {parseInt(acc < 1000 ? acc : acc / 1000)}
                            {acc < 1000 ? "m" : "km"}
                          </span>
                        ) : (
                          <span className="gpsLoadedSubTxtAcc">
                            {zone ? "Nearest zone" : "Co-ordinates"}
                          </span>
                        )}
                      </p>
                      <p
                        className={`gpsLoadedSubTxt ${
                          shortenZone(zone || "").length > 20
                            ? "smallZoneName"
                            : ""
                        }`}
                      >
                        {shortenZone(
                          zone ||
                            `${
                              currLat && currLon
                                ? `${currLat}, ${currLon}`
                                : "Location recorded"
                            }`
                        )}{" "}
                        {zone ? (
                          <span
                            className={`gpsLoadedSubTxtDist ${
                              parseInt(dist) > 1000 ? "colourRed" : ""
                            }`}
                          >
                            {dateStringer.convertMetresToKm(parseInt(dist))}{" "}
                            away
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {gpsLoaded && ignorePermissions && state.clockedOnDs ? (
                <a
                  href={`${websiteURL}/support/gps-settings`}
                  className="gpsIgnoredTopRight"
                >
                  No GPS info
                </a>
              ) : (
                ""
              )}

              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}

              {/* <div className="clockOnStatusDiv">
              <p className="clockOnStatusTitle">Status</p>
              <p className="clockOnStatusValue">{status()}</p>
            </div> */}
            </div>
            {/* <div className={`clockTicker ${durString ? "" : "clockTickerInvis"}`}>
            {durString}
          </div> */}
          </div>
          <div className={`${state.clockedOnDs ? "none" : ""}`}>
            <div
              className={`${loading ? "none" : "liveClock"} ${
                state.clockedOnDs ? "" : "clockOffClockColours"
              }`}
            >
              <p className="liveClockHours">{h}</p>
              <p className={`liveClockColon ${colon ? "" : "hideColon"}`}>:</p>
              <p className="liveClockMins">{m}</p>
              <p className="liveClockSecs">{s}</p>
            </div>
          </div>

          <div
            className={`${
              state.clockedOnDs ? "clockedOnClockAndTeamDiv" : "none"
            }`}
          >
            <div
              className={`${loading ? "none" : "liveClock"} ${
                state.clockedOnDs ? "" : "clockOffClockColours"
              }`}
            >
              <p className="liveClockHours">{h}</p>
              <p className={`liveClockColon ${colon ? "" : "hideColon"}`}>:</p>
              <p className="liveClockMins">{m}</p>
              <p className="liveClockSecsInline">{s}</p>
            </div>
          </div>
          <div className={`${!clockOnAllowed ? "clockOnNotAllowed" : "none"}`}>
            <p className="clockOnNotAllowedTxt">
              The clock on and off function is disabled for your profile.
            </p>
          </div>
          <div
            className={`${loading ? "none" : "clockOnContentBox"} ${
              memoVals.mobModal ? "mobClockModalBody" : ""
            } ${
              memoVals.mobModal && !state.clockedOnDs && !state.scheduledStartDs
                ? "smallerWhiteBoxMobBeforeClockedOn"
                : ""
            } ${
              memoVals.mobModal && state.clockedOnDs && !state.break1startDs
                ? "mobClockOnButNoBreak"
                : ""
            } ${
              memoVals.mobModal &&
              state.clockedOnDs &&
              state.break1startDs &&
              !state.break1endDs
                ? "mobClockOnWithBreak1StartOnly"
                : ""
            } ${
              memoVals.mobModal && !state.clockedOnDs && state.scheduledStartDs
                ? "mobClockOnBodyScheduledWithoutClockOn"
                : ""
            }  ${
              memoVals.mobModal &&
              state.clockedOnDs &&
              state.scheduledStartDs &&
              !state.break1startDs
                ? "clockedOnButNoBreak1MobScheduledBody"
                : ""
            }  ${
              memoVals.mobModal &&
              state.clockedOnDs &&
              state.scheduledStartDs &&
              state.break1startDs
                ? "clockedOnButBreak1MobScheduledBody"
                : ""
            }   ${
              memoVals.mobModal &&
              state.clockedOnDs &&
              state.scheduledStartDs &&
              state.break1startDs &&
              state.break1endDs
                ? `clockedOnButBreak1EndMobScheduledBody ${
                    memoVals.device.ios
                      ? "clockedOnButBreak1EndMobScheduledBodyIos"
                      : ""
                  }`
                : ""
            } ${
              memoVals.mobModal &&
              state.clockedOnDs &&
              !state.scheduledStartDs &&
              state.break1endDs
                ? "clockedOnWithFullBreakNoSchedBody"
                : ""
            } ${
              !memoVals.mobModal && state.clockedOnDs
                ? "nonMobModalClockContentBox"
                : ""
            } ${
              !memoVals.mobModal && !state.scheduledStartDs
                ? "minHeight170"
                : ""
            }`}
          >
            {/* jamie */}
            {state.teamName ? (
              <div
                className={`${
                  state.clockedOnDs ? "none" : "clockOnSchedTeamNameContainer"
                }`}
              >
                <p className="clockOnSchedTeamNameTxt">
                  {dateStringer.shorten(state.teamName, 44)}
                </p>
              </div>
            ) : (
              ""
            )}
            <div
              className={`${
                state.scheduledStartDs && state.scheduledEndDs
                  ? "scheduledClockOnContainer"
                  : "none"
              }`}
            >
              <div className="schedClockOnLeft">
                <p className="schedClockOnLeftTitle">Scheduled start</p>
                <p
                  className={`schedClockOnLeftValue ${
                    dateStringer.printedDateWithTimeFromDs(
                      state.scheduledStartDs,
                      true,
                      true
                    ).length > 7
                      ? "schedClockSmallTxtL"
                      : ""
                  }`}
                  onClick={() => {
                    memoVals.setShowMyShiftModal((x) => {
                      return {
                        unitType: state.assocShiftID ? "shift" : "til",
                        unitID: state.assocShiftID || state.assocTilID,
                      };
                    });
                  }}
                >
                  {dateStringer.printedDateWithTimeFromDs(
                    state.scheduledStartDs,
                    true,
                    true
                  )}
                </p>
              </div>

              <div className="schedClockBreakMiddle">
                <p className="schedClockBreakMiddleTitle">Scheduled break</p>
                <p
                  className="schedClockBreakMiddleValue"
                  onClick={() => {
                    memoVals.setShowMyShiftModal((x) => {
                      return {
                        unitType: state.assocShiftID ? "shift" : "til",
                        unitID: state.assocShiftID || state.assocTilID,
                      };
                    });
                  }}
                >
                  {dateStringer.formatMinsDurationToHours(state.scheduledBreak)}
                </p>
              </div>

              <div className="schedClockOnRight">
                <p className="schedClockOnRightTitle">Scheduled finish</p>
                <p
                  className={`schedClockOnRightValue ${
                    dateStringer.printedDateWithTimeFromDs(
                      state.scheduledEndDs,
                      true,
                      true
                    ).length > 7
                      ? "schedClockSmallTxtR"
                      : ""
                  }`}
                  onClick={() => {
                    memoVals.setShowMyShiftModal((x) => {
                      return {
                        unitType: state.assocShiftID ? "shift" : "til",
                        unitID: state.assocShiftID || state.assocTilID,
                      };
                    });
                  }}
                >
                  {dateStringer.printedDateWithTimeFromDs(
                    state.scheduledEndDs,
                    true,
                    true
                  )}
                </p>
              </div>
            </div>
            <div
              className={`${state.clockedOnDs ? "clockedOnDataBox" : "none"} ${
                memoVals.mobModal ? "clockedOnDataBoxMob" : ""
              } ${state.scheduledStartDs ? "pushDataBoxDown" : ""}`}
            >
              <div className={`${state.teamName ? "clockTeamName" : "none"}`}>
                <p className="clockOnDataTitle">Team</p>
                <p className="clockOnDataValue _">
                  {shortenTeamName(state.teamName)}
                </p>
              </div>
              <div
                className={`${durString2 ? "clockTeamName" : "clockTeamName"}`}
              >
                <p className="clockOnDataTitle">
                  Clocked <span className="clockUnpaidBreakSpan">paid</span>
                </p>{" "}
                <p
                  className={`clockOnDataValue ${
                    status() && !status().includes("break")
                      ? "breakPulsateTick"
                      : ""
                  }`}
                >
                  {/* {convertDurStringToMins(durString2)} */}
                  {/* {durString2 !== "00:00:00" ? durString2 : ""} */}
                  {printSecondsToTimeStrip(durString2)}
                </p>
              </div>
              <div className="clockTeamName">
                <p className="clockOnDataTitle">
                  Break <span className="clockUnpaidBreakSpan">unpaid</span>
                </p>
                <p
                  className={`clockOnDataValue ${
                    status() && status().includes("break")
                      ? "breakPulsateTick"
                      : ""
                  }`}
                >
                  {/* {liveBrkSecs} */}
                  {printSecondsToTimeStrip(liveBrkSecs)}
                </p>
              </div>
            </div>
            <div
              className={`${
                state.clockedOnDs
                  ? "scheduledClockOnContainer scheduledFixContainer x76543456"
                  : "none"
              }`}
            >
              <div
                className={`schedClockOnLeft ${
                  state.scheduledStartDs ? "pullClockOnUp" : ""
                }`}
              >
                <p className="schedClockOnLeftTitleClockedOn">Clocked on</p>
                <p
                  className={`schedClockOnLeftValue noBorder ${
                    state.clockedOnDs ? "clockedActive" : ""
                  }`}
                >
                  {dateStringer.printedDateWithTimeFromDs(
                    state.clockedOnDs,
                    true,
                    true
                  )}
                </p>
                <div
                  className={`clockStatusBox ${
                    status() && status().includes("Working") && 1 + 1 === 3
                      ? "workingStatus"
                      : ""
                  }`}
                >
                  <p
                    className={`clockOnStatusTitle ${
                      status() && status().includes("Working") && 1 + 1 === 3
                        ? "colorWhite"
                        : ""
                    }`}
                  >
                    Current status
                  </p>
                  <p
                    className={`clockOnStatusValue ${
                      status() && status().includes("Working") && 1 + 1 === 3
                        ? "workingPulsate"
                        : "breakPulsate"
                    }`}
                  >
                    {status()}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={`${
                state.break1startDs
                  ? "scheduledClockOnContainer x2345676543"
                  : "none"
              }`}
            >
              <div
                className={`clockBreakMap ${
                  state.break1endDs ? "marginBottom4" : ""
                }`}
              >
                <div
                  className={`${
                    state.break1startDs
                      ? `breakClockUnit ${
                          !state.break1endDs ? "smallerBreak1" : ""
                        }`
                      : "breakClockUnitInactive"
                  }`}
                >
                  <p className="breakClockNum">Break 1</p>
                  <p className="breakClockStartTitle">Start </p>{" "}
                  <p className="breackClockStartSpan">
                    {dateStringer.dsToTimeStrip(state.break1startDs, true)}
                  </p>
                  <div
                    className={`${state.break1endDs ? "breakEndUnit" : "none"}`}
                  >
                    <p className="breakClockStartTitle">End </p>{" "}
                    <p className="breackClockStartSpan">
                      {dateStringer.dsToTimeStrip(state.break1endDs, true)}
                    </p>
                    <p className="breakClockStartTitleDuration">Duration </p>{" "}
                    <p className="breackClockStartSpan clockOnDurSub">
                      {dateStringer.secsToString(state.break1secs)}

                      {/* {state.break1secs < 60
                      ? `${Math.round(state.break1secs)}s`
                      : dateStringer.formatMinsDurationToHours(
                          state.break1mins
                        )} */}
                    </p>
                  </div>
                </div>
                <div
                  className={`${
                    state.break2startDs
                      ? "breakClockUnit"
                      : "breakClockUnitInactive"
                  }`}
                >
                  <p className="breakClockNum">Break 2</p>
                  <p className="breakClockStartTitle">Start </p>{" "}
                  <p className="breackClockStartSpan">
                    {dateStringer.dsToTimeStrip(state.break2startDs, true)}
                  </p>
                  <div
                    className={`${state.break2endDs ? "breakEndUnit" : "none"}`}
                  >
                    <p className="breakClockStartTitle">End </p>{" "}
                    <p className="breackClockStartSpan">
                      {dateStringer.dsToTimeStrip(state.break2endDs, true)}
                    </p>
                    <p className="breakClockStartTitleDuration">Duration </p>{" "}
                    <p className="breackClockStartSpan clockOnDurSub">
                      {dateStringer.secsToString(state.break2secs)}

                      {/* {state.break2secs < 60
                      ? `${Math.round(state.break2secs)}s`
                      : dateStringer.formatMinsDurationToHours(
                          state.break2mins
                        )} */}
                    </p>
                  </div>
                </div>
                <div
                  className={`${
                    state.break3startDs
                      ? "breakClockUnit"
                      : "breakClockUnitInactive"
                  }`}
                >
                  <p className="breakClockNum">Break 3</p>
                  <p className="breakClockStartTitle">Start </p>{" "}
                  <p className="breackClockStartSpan">
                    {dateStringer.dsToTimeStrip(state.break3startDs, true)}
                  </p>
                  <div
                    className={`${state.break3endDs ? "breakEndUnit" : "none"}`}
                  >
                    <p className="breakClockStartTitle">End </p>{" "}
                    <p className="breackClockStartSpan">
                      {dateStringer.dsToTimeStrip(state.break3endDs, true)}
                    </p>
                    <p className="breakClockStartTitleDuration">Duration </p>
                    <p className="breackClockStartSpan clockOnDurSub">
                      {dateStringer.secsToString(state.break3secs)}
                      {/* {state.break3secs < 60
                      ? `${Math.round(state.break3secs)}s`
                      : dateStringer.formatMinsDurationToHours(
                          state.break3mins
                        )} */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                !state.clockedOnDs
                  ? `clockOnBtnContainer ${
                      state.scheduledStartDs ? "x3891813" : ""
                    }`
                  : "none"
              }`}
            >
              <select
                className={`clockTeamDropdown ${
                  state.scheduledStartDs ? "none" : ""
                }`}
                value={teamID}
                onChange={(e) => {
                  setTeamID(e.target.value);
                }}
              >
                {generateTeams}
              </select>
              {!gpsLoaded &&
              memoVals.checkUserState.enableGPS &&
              !userDeniedPermissions &&
              !ignorePermissions &&
              state.clockedOnDs ? (
                <p className="awaitGPStxt">Waiting for GPS</p>
              ) : (
                <p
                  className={`actionsClockOnBtn blueColours ${
                    updateLoading ? "invis" : ""
                  } ${
                    !gpsLoaded && memoVals.checkUserState.enableGPS
                      ? "gpsLoadingDisableClockOnBtn"
                      : ""
                  } ${userDeniedPermissions ? "none" : ""} ${
                    state.scheduledStartDs
                      ? `x183911 ${memoVals.mobModal ? "x88349829" : ""}`
                      : ""
                  }`}
                  onClick={() => {
                    setUpdateLoading(true);
                    axios
                      .post(
                        `${serverURL}/clock-on`,
                        {
                          startDs: clockOnDs,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                          nowTs: new Date().getTime(),
                          assocShiftID: state.assocShiftID,
                          assocTilID: state.assocTilID,
                          teamID: teamID,
                          actualTsClockedOn: new Date().getTime(),
                          lon: currLon,
                          lat: currLat,
                          acc: currAcc,
                          dis: currDis,
                          zone,
                          userID:
                            typeof memoVals.showClockOnModal === "object" &&
                            memoVals.showClockOnModal.userID
                              ? memoVals.showClockOnModal.userID
                              : "",
                          photo:
                            typeof memoVals.showClockOnModal === "object" &&
                            memoVals.showClockOnModal.photo
                              ? memoVals.showClockOnModal.photo
                              : "",
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setCountData({
                            currClocked: countData.currClocked + 1,
                            online: countData.online + 1,
                          });
                          if (
                            typeof memoVals.showClockOnModal === "object" &&
                            memoVals.showClockOnModal.userID
                          ) {
                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: "Clocked on",
                                colour: "green",
                                duration: 4000,
                              };
                            });
                            memoVals.setShowClockOnModal((x) => false);
                          } else {
                            setState({ clockOnID1: response.data.clockonID });
                            setUpdateClock(!updateClock);
                            memoVals.setUserIsClockedOn((x) => clockOnDs);
                            memoVals.setUpdateHeaderClocks(
                              !memoVals.updateHeaderClocks
                            );

                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: "Clocked on",
                                colour: "green",
                                duration: 4000,
                              };
                            });
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Clock on
                </p>
              )}
              {userDeniedPermissions ? (
                <div className="deniedGPSdiv">
                  <p className="deniedGPStxt">
                    You need to allow FlowRota access to your location
                  </p>
                  <p
                    className="ignoreGPSdenied"
                    onClick={() => {
                      setUserDeniedPermissions(false);
                      setGpsLoaded(true);
                      setIgnorePermissions(true);
                    }}
                  >
                    Ignore
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            {!gpsLoaded &&
            memoVals.checkUserState.enableGPS &&
            !userDeniedPermissions &&
            !ignorePermissions ? (
              <p
                className={`awaitingGPSAlreadyClockedOn ${
                  memoVals.mobModal ? "awaitingGPSAlreadyClockedOnMob" : ""
                } ${
                  !state.scheduledStartDs
                    ? `x2831421 ${memoVals.mobModal ? "x1398471" : ""} ${
                        !memoVals.mobModal && !state.clockedOnDs
                          ? "x98349724"
                          : ""
                      }`
                    : `x324567 ${
                        state.clockedOnDs
                          ? `x3719741 ${memoVals.mobModal ? "x9173811" : ""}`
                          : ""
                      }`
                }`}
              >
                Waiting for GPS
              </p>
            ) : (
              <div
                className={`${
                  state.clockedOnDs
                    ? `clockOffBtnContainer ${
                        state.break1endDs && memoVals.device.ios
                          ? "x2392398234984"
                          : ""
                      }`
                    : "none"
                } ${updateLoading ? "updateLoading" : ""}`}
              >
                <p
                  className={`actionsClockOnBtn startBreakBtn ${
                    state.break3endDs ? "disableBrkBtn" : ""
                  }`}
                  onClick={() => {
                    setUpdateLoading(true);
                    let onOrOff = "";
                    let breakNum = 0;

                    if (!state.break1startDs) {
                      onOrOff = "on";
                      breakNum = 1;
                    }

                    if (state.break1startDs && !state.break1endDs) {
                      onOrOff = "off";
                      breakNum = 1;
                    }

                    if (state.break1endDs && !state.break2startDs) {
                      onOrOff = "on";
                      breakNum = 2;
                    }

                    if (state.break2startDs && !state.break2endDs) {
                      onOrOff = "off";
                      breakNum = 2;
                    }

                    if (state.break2endDs && !state.break3startDs) {
                      onOrOff = "on";
                      breakNum = 3;
                    }
                    if (state.break3startDs && !state.break3endDs) {
                      onOrOff = "off";
                      breakNum = 3;
                    }

                    if (!state.break3endDs) {
                      axios
                        .post(
                          `${serverURL}/clock-break`,
                          {
                            clockOnID: state.clockOnID1,
                            ds: clockOnDs,
                            onOrOff,
                            breakNum,
                            ts: new Date().getTime(),
                            durString: durString2,
                            lon: currLon,
                            lat: currLat,
                            acc: currAcc,
                            dis: currDis,
                            zone,
                            userID:
                              typeof memoVals.showClockOnModal === "object" &&
                              memoVals.showClockOnModal.userID
                                ? memoVals.showClockOnModal.userID
                                : "",
                            photo:
                              typeof memoVals.showClockOnModal === "object" &&
                              memoVals.showClockOnModal.photo
                                ? memoVals.showClockOnModal.photo
                                : "",
                          },
                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            if (
                              typeof memoVals.showClockOnModal === "object" &&
                              memoVals.showClockOnModal.userID
                            ) {
                              let firstSecondOrThird = "First";
                              if (breakNum === 2) {
                                firstSecondOrThird = "Second";
                              } else if (breakNum === 3) {
                                firstSecondOrThird = "Third";
                              } else {
                              }
                              memoVals.setIndicate((x) => {
                                return {
                                  show: true,
                                  message: `${firstSecondOrThird} break ${
                                    onOrOff === "on" ? "started" : "ended"
                                  }`,
                                  colour: "green",
                                  duration: 4000,
                                };
                              });
                              memoVals.setShowClockOnModal((x) => false);
                            } else {
                              setUpdateClock(!updateClock);
                              let firstSecondOrThird = "First";
                              if (breakNum === 2) {
                                firstSecondOrThird = "Second";
                              } else if (breakNum === 3) {
                                firstSecondOrThird = "Third";
                              } else {
                              }
                              if (response.data.breakNotLongEnough) {
                                setShowBreakNotLongEnoughModal(true);
                              } else {
                                memoVals.setIndicate((x) => {
                                  return {
                                    show: true,
                                    message: `${firstSecondOrThird} break ${
                                      onOrOff === "on" ? "started" : "ended"
                                    }`,
                                    colour: "blue",
                                    duration: 4000,
                                  };
                                });
                              }
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  {breakStartOrEnd()} break {breakStartOrEnd(true)}
                </p>
                <p
                  className="actionsClockOnBtn blueColours clockOffBtnSmall"
                  onClick={() => {
                    setUpdateLoading(true);
                    axios
                      .post(
                        `${serverURL}/clock-off`,
                        {
                          clockOnID: state.clockOnID1,
                          ds: clockOnDs,
                          clockOffTs: new Date().getTime(),
                          lon: currLon,
                          lat: currLat,
                          acc: currAcc,
                          dis: currDis,
                          zone,
                          userID:
                            typeof memoVals.showClockOnModal === "object" &&
                            memoVals.showClockOnModal.userID
                              ? memoVals.showClockOnModal.userID
                              : "",
                          photo:
                            typeof memoVals.showClockOnModal === "object" &&
                            memoVals.showClockOnModal.photo
                              ? memoVals.showClockOnModal.photo
                              : "",
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.splitExcessMins) {
                          setSplitExcessMins(response.data.splitExcessMins);
                        }
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setCountData({
                            currClocked: countData.currClocked - 1,
                            online: countData.online - 1,
                          });
                          if (
                            typeof memoVals.showClockOnModal === "object" &&
                            memoVals.showClockOnModal.userID
                          ) {
                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: "Clocked off",
                                colour: "green",
                                duration: 4000,
                              };
                            });
                            memoVals.setShowClockOnModal((x) => false);
                          } else {
                            memoVals.setClockOnID((x) => {
                              return {
                                clockOnID: state.clockOnID1,
                                isMgr: false,
                                openedOnClockOff: true,
                              };
                            });
                            memoVals.setUserIsClockedOn((x) => false);
                            memoVals.setUserIsOnClockedBreak((x) => false);
                            memoVals.setShowClockOnModal((x) => false);
                            memoVals.setUpdateHeaderClocks(
                              !memoVals.updateHeaderClocks
                            );
                          }

                          // window.location.href = `/clock-ons/?clockOnID=${clockOnID}&clockoff=${new Date().getTime()}`;
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Clock off
                </p>
              </div>
            )}
          </div>
          <div className="clockOnActionsDiv">
            {(memoVals.checkUserState.enableGPS && !state.clockedOnDs) ||
            loading ? (
              <div className="clockGPSBar">
                <img
                  src={gpsLoaded && !ignorePermissions ? gps2 : gps1}
                  className={`gpsIcon ${!gpsLoaded ? "gps1only" : ""} `}
                  alt="GPS"
                />
                {!gpsLoaded ? (
                  <p
                    className={` ${
                      userDeniedPermissions ? "deniedFailedTxt" : "gpsLoading"
                    }`}
                  >
                    {!userDeniedPermissions ? "GPS loading" : "GPS unavailable"}
                    {userDeniedPermissions ? (
                      <a
                        href={`${websiteURL}/support/gps-settings`}
                        target="_blank"
                        className="gpsSupportBtnTopRight"
                      >
                        Help{" "}
                        <img
                          src={popup}
                          alt="GPS support"
                          className="gpsSettingsSupportPopup"
                        />
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                ) : (
                  ""
                )}

                {gpsLoaded && !ignorePermissions ? (
                  <div className="gpsConfDiv">
                    <p className="gpsLoadedTxt textAlignLeft">
                      GPS confirmed{" "}
                      {acc > 100 ? (
                        <span className="gpsLoadedSubTxtAcc">
                          Accuracy: {parseInt(acc)}m
                        </span>
                      ) : (
                        <span className="gpsLoadedSubTxtAcc">Nearest zone</span>
                      )}
                    </p>
                    <p
                      className={`gpsLoadedSubTxt ${
                        shortenZone(zone || "").length > 20
                          ? "smallZoneName"
                          : ""
                      }`}
                    >
                      {shortenZone(
                        zone ||
                          `${
                            currLat && currLon
                              ? `${(currLat, currLon)}`
                              : "Location recorded"
                          }`
                      )}{" "}
                      {zone ? (
                        <span
                          className={`gpsLoadedSubTxtDist ${
                            parseInt(dist) > 1000 ? "colourRed" : ""
                          }`}
                        >
                          {dateStringer.convertMetresToKm(parseInt(dist))} away
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {gpsLoaded && ignorePermissions && !state.clockedOnDs ? (
              <p className="gpsIgnored">No GPS info</p>
            ) : (
              ""
            )}

            <div className="clockFooterLeft">
              {state.clockedOnDs ? (
                <div
                  className="clockTagsBtn"
                  onClick={() => {
                    setShowTags(true);
                  }}
                >
                  <img src={tag} alt="Tags" className="clockTagsIcon" />
                  Tags{" "}
                  {appliedTags && appliedTags[0] ? (
                    <span className="appliedTagsQty">
                      {" "}
                      | {appliedTags.length}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <p
                className={`${
                  state.clockedOnDs && !loading ? "undoClockBtn" : "none"
                } ${updateLoading ? "updateLoading hidden" : ""}`}
                onClick={() => {
                  let undoWhat = "";
                  if (undoTxt() === "Undo clock on") {
                    undoWhat = "clockOn";
                  }
                  if (undoTxt() === "Undo start break 1") {
                    undoWhat = "startBreak1";
                  }
                  if (undoTxt() === "Undo start break 2") {
                    undoWhat = "startBreak2";
                  }
                  if (undoTxt() === "Undo start break 3") {
                    undoWhat = "startBreak3";
                  }
                  if (undoTxt() === "Undo end break 1") {
                    undoWhat = "endBreak1";
                  }
                  if (undoTxt() === "Undo end break 2") {
                    undoWhat = "endBreak2";
                  }
                  if (undoTxt() === "Undo end break 3") {
                    undoWhat = "endBreak3";
                  }

                  setSureUndo(undoWhat);
                }}
              >
                {/* {undoTxt()} */}
                <img src={undoNavy} alt="Undo" className="ccUndo" /> Undo
              </p>
              <span className={`${state.clockedOnDs ? "none" : ""}`}>
                &nbsp;
              </span>
            </div>
            <p
              className="closeReqInfo"
              onClick={() => {
                memoVals.setShowClockOnModal((x) => false);
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {showBreakNotLongEnoughModal ? (
        <div
          className="editUserUnderlay"
          onClick={() => {
            setShowBreakNotLongEnoughModal(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              Your unpaid break was cancelled.
              <br />
              <br />
              Unpaid breaks must be at least
              <br />
              one minute long.
              <br />
            </p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setShowBreakNotLongEnoughModal(false);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {clockOnDisabled ? (
        <div
          className="editUserUnderlay"
          onClick={() => {
            memoVals.setShowClockOnModal((x) => false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              Sorry - clocking on and off is disabled for your account. <br />
              <br />
              Ask your manager or a FlowRota admin to enable this feature for
              you.
            </p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                memoVals.setShowClockOnModal((x) => false);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      {sureUndo ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setSureUndo(false);
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              Are you sure you want to
              <br />
              undo your{" "}
              {sureUndo === "clockOn"
                ? "clock on"
                : sureUndo === "startBreak1" ||
                  sureUndo === "startBreak2" ||
                  sureUndo === "startBreak3"
                ? "clocked start of your break"
                : "clocked end of your break"}
              {sureUndo === "clockOn" ? " and cancel this clock card" : ""}?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  // canteen
                  setUpdateLoading(true);
                  setSureUndo(false);
                  axios
                    .post(
                      `${serverURL}/undo-clock-action`,
                      {
                        clockOnID: state.clockOnID1,
                        undoWhat: sureUndo,
                        userID:
                          typeof memoVals.showClockOnModal === "object" &&
                          memoVals.showClockOnModal.userID
                            ? memoVals.showClockOnModal.userID
                            : "",
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setUpdateClock(!updateClock);
                        if (sureUndo === "clockOn") {
                          setCountData({
                            currClocked: countData.currClocked - 1,
                            online: countData.online - 1,
                          });
                          // memoVals.setShowClockOnModal((x) => false);
                          // memoVals.setUserIsClockedOn((x) => false);
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Cancelled clock on",
                              colour: "orange",
                              duration: 4000,
                            };
                          });
                        }

                        if (sureUndo === "startBreak1") {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Cancelled first break start",
                              colour: "orange",
                              duration: 4000,
                            };
                          });
                        }

                        if (sureUndo === "startBreak2") {
                          // memoVals.setUserIsClockedOn((x) => true);
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Cancelled second break start",
                              colour: "orange",
                              duration: 4000,
                            };
                          });
                        }

                        if (sureUndo === "startBreak3") {
                          // memoVals.setUserIsClockedOn((x) => true);
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Cancelled third break start",
                              colour: "orange",
                              duration: 4000,
                            };
                          });
                        }

                        if (sureUndo === "endBreak1") {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Cancelled first break end",
                              colour: "orange",
                              duration: 4000,
                            };
                          });
                        }

                        if (sureUndo === "endBreak2") {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Cancelled second break end",
                              colour: "orange",
                              duration: 4000,
                            };
                          });
                        }

                        if (sureUndo === "endBreak3") {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Cancelled third break end",
                              colour: "orange",
                              duration: 4000,
                            };
                          });
                        }
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureUndo(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showSuggestRefreshGps ? (
        <div
          className="editUserUnderlay"
          onClick={() => {
            setShowSuggestRefreshGps(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              It looks like your device's GPS is struggling to determine an
              accurate reading.
              <br />
              <br /> Your current accuracy reading is {Math.round(acc)}m.
              <br />
              <br />
              {memoVals.mobModal
                ? "If you are on WiFi, try turning it off and use 4G or 5G only, or try restarting the app."
                : "Try refreshing the page, or using a different WiFi network."}{" "}
              <br />
              <br />
              If the accuracy is still poor, your manager will be able to see
              that you had an accuracy issue. <br />
              <br />
            </p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setShowSuggestRefreshGps(false);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {splitExcessMins > 0 ? (
        <div
          className="editUserUnderlay"
          onClick={() => {
            setSplitExcessMins(0);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              You are about to clock off{" "}
              {dateStringer.formatMinsDurationToHours(splitExcessMins)} after
              your scheduled end time.
              <br />
              <br />
              Do you want to split this clock card into two, with the excess{" "}
              {dateStringer.formatMinsDurationToHours(splitExcessMins)} being
              logged as overtime?
              <br />
              <br />
              If you choose no, your current clock card will be clocked off with
              the current time.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setUpdateLoading(true);
                  memoVals.setShowClockOnModal((x) => false);

                  axios
                    .post(
                      `${serverURL}/clock-off`,
                      {
                        clockOnID: state.clockOnID1,
                        ds: clockOnDs,
                        clockOffTs: new Date().getTime(),
                        lon: currLon,
                        lat: currLat,
                        acc: currAcc,
                        dis: currDis,
                        zone,
                        split: true,
                        userID:
                          typeof memoVals.showClockOnModal === "object" &&
                          memoVals.showClockOnModal.userID
                            ? memoVals.showClockOnModal.userID
                            : "",
                        photo:
                          typeof memoVals.showClockOnModal === "object" &&
                          memoVals.showClockOnModal.photo
                            ? memoVals.showClockOnModal.photo
                            : "",
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.splitExcessMins) {
                        setSplitExcessMins(response.data.splitExcessMins);
                      }
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setCountData({
                          currClocked: countData.currClocked - 1,
                          online: countData.online - 1,
                        });
                        if (
                          typeof memoVals.showClockOnModal === "object" &&
                          memoVals.showClockOnModal.userID
                        ) {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Clocked off",
                              colour: "green",
                              duration: 4000,
                            };
                          });
                          memoVals.setShowClockOnModal((x) => false);
                        } else {
                          memoVals.setClockOnID((x) => {
                            return {
                              clockOnID: state.clockOnID1,
                              isMgr: false,
                              openedOnClockOff: true,
                            };
                          });
                          memoVals.setUserIsClockedOn((x) => false);
                          memoVals.setUserIsOnClockedBreak((x) => false);
                          memoVals.setShowClockOnModal((x) => false);
                          memoVals.setUpdateHeaderClocks(
                            !memoVals.updateHeaderClocks
                          );
                        }
                        // window.location.href = `/clock-ons/?clockOnID=${clockOnID}&clockoff=${new Date().getTime()}`;
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setUpdateLoading(true);
                  memoVals.setShowClockOnModal((x) => false);

                  axios
                    .post(
                      `${serverURL}/clock-off`,
                      {
                        clockOnID: state.clockOnID1,
                        ds: clockOnDs,
                        clockOffTs: new Date().getTime(),
                        lon: currLon,
                        lat: currLat,
                        acc: currAcc,
                        dis: currDis,
                        zone,
                        avoidSplit: true,
                        userID:
                          typeof memoVals.showClockOnModal === "object" &&
                          memoVals.showClockOnModal.userID
                            ? memoVals.showClockOnModal.userID
                            : "",
                        photo:
                          typeof memoVals.showClockOnModal === "object" &&
                          memoVals.showClockOnModal.photo
                            ? memoVals.showClockOnModal.photo
                            : "",
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.splitExcessMins) {
                        setSplitExcessMins(response.data.splitExcessMins);
                      }
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        if (
                          typeof memoVals.showClockOnModal === "object" &&
                          memoVals.showClockOnModal.userID
                        ) {
                          setCountData({
                            currClocked: countData.currClocked - 1,
                            online: countData.online - 1,
                          });
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Clocked off",
                              colour: "green",
                              duration: 4000,
                            };
                          });
                          memoVals.setShowClockOnModal((x) => false);
                        } else {
                          memoVals.setClockOnID((x) => {
                            return {
                              clockOnID: state.clockOnID1,
                              isMgr: false,
                              openedOnClockOff: true,
                            };
                          });
                          memoVals.setUserIsClockedOn((x) => false);
                          memoVals.setUserIsOnClockedBreak((x) => false);
                          memoVals.setUpdateHeaderClocks(
                            !memoVals.updateHeaderClocks
                          );
                        }
                        // window.location.href = `/clock-ons/?clockOnID=${clockOnID}&clockoff=${new Date().getTime()}`;
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                No
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showTags ? (
        <div
          className="availableTagsUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setShowTags(false);
          }}
        >
          <div
            className={`clockAvailTagsModal ${
              memoVals.device.ios ? "clockAvailTagsModalIos" : ""
            } ${memoVals.mobModal ? "mobModalShoulder" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={modalPositionTags}
            ref={tagsModalRef}
          >
            {memoVals.mobModal && (
              <div
                className="modalSwiper modalSwiperAddTags"
                onTouchStart={handleDownTags}
                onTouchMove={memoVals.mobModal ? handleMoveTags : null}
                onTouchEnd={memoVals.mobModal ? handleUpTags : null}
                onMouseDown={memoVals.mobModal ? handleDownTags : null}
                onMouseMove={memoVals.mobModal ? handleMoveTags : null}
                onMouseUp={memoVals.mobModal ? handleUpTags : null}
              ></div>
            )}
            <div
              className="addTagModalHeader"
              onTouchStart={handleDownTags}
              onTouchMove={memoVals.mobModal ? handleMoveTags : null}
              onTouchEnd={memoVals.mobModal ? handleUpTags : null}
              onMouseDown={memoVals.mobModal ? handleDownTags : null}
              onMouseMove={memoVals.mobModal ? handleMoveTags : null}
              onMouseUp={memoVals.mobModal ? handleUpTags : null}
            >
              <img
                className="closeAddTagModalImg"
                src={close}
                alt="Close"
                onClick={() => {
                  setShowTags(false);
                }}
              />
              <p className="addTagModalHeaderTitle">Add tags</p>
              {/* <p className="phantomModalHeaderUnit">&nbsp;</p> */}
            </div>

            <div className="clockAvailTagsScrollBox">
              <div className="clockAvailTagsDiv">
                {!appliedTags[0] ? (
                  availableTags[0] ? (
                    <p className="noAppliedTags">Add a tag below</p>
                  ) : (
                    <div className="noAppliedTags">
                      There are no tags for {state.teamName} available for you
                      to apply to this clock card.
                      <br />
                      <br />
                      Find out more information{" "}
                      <a
                        href={`${websiteURL}/support/tags`}
                        target="_blank"
                        className="moreInfoAvailTagsBtn"
                      >
                        here{" "}
                        <img
                          src={popup}
                          alt="More info"
                          className="moreInfoTagsAvailPopupImg"
                        />
                      </a>
                    </div>
                  )
                ) : (
                  ""
                )}
                {appliedTags &&
                  appliedTags[0] &&
                  appliedTags.map((tag, i) => {
                    return (
                      <p className="teamTagIemX x13145361" key={i}>
                        {tag}{" "}
                        <img
                          src={greyCross}
                          className="removeTeamTagImgX"
                          alt="Remove tag"
                          onClick={() => {
                            axios
                              .post(
                                `${serverURL}/add-or-remove-clock-tag`,
                                {
                                  tag: tag,
                                  clockOnID: state.clockOnID1,
                                  remove: true,
                                  userID:
                                    typeof memoVals.showClockOnModal ===
                                      "object" &&
                                    memoVals.showClockOnModal.userID
                                      ? memoVals.showClockOnModal.userID
                                      : "",
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message.includes("logout")) {
                                  window.location.href = "/signin";
                                }
                                if (response.data.message === "success") {
                                  if (response.data.cannotRemoveSchedTag) {
                                    memoVals.setIndicate((x) => {
                                      return {
                                        show: true,
                                        message: "Cannot remove scheduled tag",
                                        colour: "red",
                                        duration: 4000,
                                      };
                                    });
                                  }
                                  setAppliedTags(
                                    response.data.tags.sort((a, b) => {
                                      return a.localeCompare(b);
                                    })
                                  );
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }}
                        />
                      </p>
                    );
                  })}
              </div>
            </div>
            <div className="tagMgrDiv">
              <select
                className={`clockTagSelector ${
                  !availableTags[0] ? "invis" : ""
                }`}
                onChange={(e) => {
                  if (e.target.value !== "-") {
                    axios
                      .post(
                        `${serverURL}/add-or-remove-clock-tag`,
                        {
                          tag: e.target.value,
                          clockOnID: state.clockOnID1,
                          remove: false,
                          userID:
                            typeof memoVals.showClockOnModal === "object" &&
                            memoVals.showClockOnModal.userID
                              ? memoVals.showClockOnModal.userID
                              : "",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setAppliedTags(
                            response.data.tags.sort((a, b) => {
                              return a.localeCompare(b);
                            })
                          );
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                {" "}
                <option value="-">-- Add tag</option>
                {availableTags.map((tag) => {
                  if (!appliedTags.includes(tag, i)) {
                    return (
                      <option value={tag} key={i}>
                        {tag}
                      </option>
                    );
                  } else return;
                })}
              </select>
              <p
                className="closeReqInfo"
                onClick={() => {
                  setShowTags(false);
                }}
              >
                Close
              </p>

              {/* <p className="phantomModalHeaderUnit">&nbsp;</p> */}
              {/* <p
              className="addNewTagBtn"
              onClick={() => {
                setNewTagValue("");
                setAddingNewTag(true);
                setShowAvailableTagsModal(false);
              }}
            >
              Add new tag
            </p> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {imgPreload}
    </div>
  );
};

export default ClockOnModal;
