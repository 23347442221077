import React, { useContext, useState, useEffect, useMemo } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";

import "../../../styles/settings.css";
import "../../../styles/admin.css";

import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";
import { AdminContext } from "../../../contexts/AdminContext";

import serverURL from "../../../serverURL";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import navyBin from "../../../img/general/navyBin.svg";
import whiteAddThin from "../../../img/general/whiteAddThin.svg";
import popup from "../../../img/general/popupNavy.svg";
import popupWhite from "../../../img/general/popup.svg";

import { UserContext } from "../../../contexts/UserContext";
import dateStringer from "../../../tools/dateStringer";

const Billing = () => {
  const { setAccNav, mob } = useContext(StyleContext);
  const { checkUserState, currencySymbol } = useContext(UserContext);
  const {
    setSelectedNav,

    setShowAbsenceTypeModal,
    setSureRemoveAbsenceType,
    setShowDeleteAccountModal,
    device,
  } = useContext(DataContext);

  const {
    allowSelfLogAbsence,
    setAllowSelfLogAbsence,
    enableAbsenceRanking,
    setEnableAbsenceRanking,
    rtwFormEnabled, //
    setRtwFormEnabled,
    rtwQuestions,
    setRtwQuestions,
    absenceTypes,
    setAbsenceTypes,
    showAddRtwModal,
    setShowAddRtwModal,
    question,
    setQuestion, //
  } = useContext(AdminContext);

  const memoVals = useMemo(
    () => ({
      setAccNav,
      mob,
      setSelectedNav,
      setShowAbsenceTypeModal,
      setSureRemoveAbsenceType,
      allowSelfLogAbsence,
      setAllowSelfLogAbsence,
      enableAbsenceRanking,
      setEnableAbsenceRanking,
      rtwFormEnabled,
      setRtwFormEnabled,
      rtwQuestions,
      setRtwQuestions,
      absenceTypes,
      setAbsenceTypes,
      showAddRtwModal,
      setShowAddRtwModal,
      question,
      setQuestion,
      checkUserState,
      setShowDeleteAccountModal,
      currencySymbol,
      device,
    }),
    [
      setAccNav,
      mob,
      setSelectedNav,
      setShowAbsenceTypeModal,
      setSureRemoveAbsenceType,
      allowSelfLogAbsence,
      setAllowSelfLogAbsence,
      enableAbsenceRanking,
      setEnableAbsenceRanking,
      rtwFormEnabled,
      setRtwFormEnabled,
      rtwQuestions,
      setRtwQuestions,
      absenceTypes,
      setAbsenceTypes,
      showAddRtwModal,
      setShowAddRtwModal,
      question,
      setQuestion,
      checkUserState,
      setShowDeleteAccountModal,
      currencySymbol,
      device,
    ]
  );

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 2) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyBin}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />

      <img
        src={whiteAddThin}
        alt="Edit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [companyName, setCompanyName] = useState("");
  let [expiresDs, setExpiresDs] = useState("");
  let [expiresInDays, setExpiresInDays] = useState(0);
  let [expired, setExpired] = useState(false);
  let [inTrial, setInTrial] = useState(false);
  let [charge, setCharge] = useState(150);
  let [activeUsers, setActiveUsers] = useState(0);
  let [logoutLoading, setLogoutLoading] = useState(false);

  // if the page is refreshed, set the AccNav to settings
  useEffect(() => {
    memoVals.setAccNav((x) => "billing");
    memoVals.setSelectedNav((x) => 5.1);
  }, []);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-account-and-billing-data`,
        { nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()) },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setCompanyName(response.data.companyName);
          setExpiresDs(response.data.expiresDs);
          setExpiresInDays(response.data.expiresInDays);
          setExpired(response.data.expired);
          setInTrial(response.data.inTrial);
          setDataLoaded(true);
          setCharge(response.data.charge);
          setActiveUsers(response.data.activeUsers);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [showRemoved, setShowRemoved] = useState(false);
  let [selfLogPaid, setSelfLogPaid] = useState(false);

  let shortenLocation = (val) => {
    if (!val) {
      return "";
    }
    if (val.length > 25) {
      return `${val.substr(0, 22)}...`;
    } else return val;
  };

  useEffect(() => {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Update the slug
    currentURL.pathname = "/admin/billing";

    // Change the URL without reloading the page
    window.history.pushState({}, "", currentURL.href);
  }, []);

  let generateTypes = absenceTypes
    .filter((x) => {
      return !x.removed;
    })
    .map((item) => {
      return (
        <div key={item._id}>
          <div
            className="specialDayUnit customLeaveTypeUnit"
            onClick={() => {
              memoVals.setShowAbsenceTypeModal((x) => {
                return {
                  show: true,
                  absenceTypeID: item.absenceTypeID,
                  rate: item.rate,
                  name: item.name,
                  removed: item.removed,
                };
              });
            }}
          >
            <div className="customTypeLeft">
              <span onClick={() => {}}>
                <p
                  className={`specialDayDate ${
                    shortenLocation(item.name).length > 18 ? "x283819123" : ""
                  }`}
                >
                  {shortenLocation(item.name)}
                </p>
              </span>
              <p className="customLeaveTypeSubTxt">
                {item.rate === 0 || item.rate === "0"
                  ? "Unpaid"
                  : `Paid | ${item.rate}x`}
              </p>
            </div>
            <img
              className="gpsZoneDeleteCrossImg"
              src={navyBin}
              alt="Delete"
              onClick={(e) => {
                e.stopPropagation();
                if (!item.removed) {
                  memoVals.setSureRemoveAbsenceType((x) => item.absenceTypeID);
                }
              }}
            />
          </div>
        </div>
      );
    });

  let generateRemovedTypes = absenceTypes
    .filter((x) => {
      return x.removed;
    })
    .map((item) => {
      return (
        <div key={item._id}>
          <div
            className="specialDayUnit customLeaveTypeUnit zoomInSlow"
            onClick={() => {
              memoVals.setShowAbsenceTypeModal((x) => {
                return {
                  show: true,
                  absenceTypeID: item.absenceTypeID,
                  rate: item.rate,
                  name: item.name,
                  removed: item.removed,
                };
              });
            }}
          >
            <div className="customTypeLeft">
              <span onClick={() => {}}>
                <p
                  className={`specialDayDate ${
                    shortenLocation(item.name).length > 18 ? "x283819123" : ""
                  }`}
                >
                  {shortenLocation(item.name)}
                </p>
              </span>
              <p className="customLeaveTypeSubTxt">
                {item.paid ? `Paid | ${item.rate}x` : "Unpaid"}
              </p>
            </div>
            <p className="removedTypeTxt">Archived</p>
          </div>
        </div>
      );
    });

  return (
    <div
      className={`AdminSettingsContainer ${
        memoVals.mob
          ? `AdminSettingsContainerMob ${
              memoVals.device.ios ? "AdminSettingsContainerMobIos" : ""
            }`
          : "borderRightGrey"
      }`}
    >
      {/* <div className='whiteGradientAdmin'></div> */}

      {loading ? (
        <img
          src={horizontalBalls}
          alt="Loading"
          className="teamSchedLoadingBallsImg"
        />
      ) : (
        <div className="AdminInner">
          {(expired || inTrial) && (
            <div
              className={`AdminRowContainer absenceAdminRow ${
                memoVals.mob ? "mobileAdminRow" : ""
              } trialExpAdminRow`}
            >
              <div className="settingsRow settingsRowAlignItemsCenter">
                <div className="rowWithSubText deleteAccSubTxt">
                  <p className="absenceRowMainText">
                    Your trial{" "}
                    {inTrial
                      ? `ends in ${expiresInDays} day${
                          expiresInDays === 1 ? "" : "s"
                        }`
                      : "has expired"}
                  </p>
                  <p className="absenceRowSubText paddingRight30">
                    <span>
                      Start your subscription for {memoVals.currencySymbol}
                      {JSON.stringify((charge / 100).toFixed(2)).includes(".00")
                        ? charge / 100
                        : (charge / 100).toFixed(2)}{" "}
                      per user per month to continue using FlowRota.
                    </span>
                    <br />
                    <br />
                    Based on {activeUsers} user
                    {activeUsers === 1 ? "" : "s"}, your monthly subscription
                    would be {memoVals.currencySymbol}
                    {JSON.stringify(
                      ((activeUsers * charge) / 100).toFixed(2)
                    ).includes(".00")
                      ? (activeUsers * charge) / 100
                      : ((activeUsers * charge) / 100).toFixed(2)}{" "}
                    (VAT included)
                  </p>
                </div>

                <p
                  className="deleteAccBtn subscribeBtn_ lilacColours"
                  onClick={() => {}}
                >
                  Start subscription
                  <img
                    src={popupWhite}
                    alt="Checklist"
                    className="showCheckListAdminImg"
                  />
                </p>
              </div>{" "}
              {expired ? (
                <div className="expiredSignoutParent">
                  <p
                    className={`expiredSignout ${logoutLoading ? "invis" : ""}`}
                    onClick={() => {
                      setLogoutLoading(true);
                      axios
                        .post(
                          `${serverURL}/logout`,
                          { someData: 1 },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            window.location.href = "/signin";
                          } else {
                            setLogoutLoading(false);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                          setLogoutLoading(false);
                        });
                    }}
                  >
                    Sign out
                  </p>{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow settingsRowAlignItemsCenter">
              <div className="rowWithSubText deleteAccSubTxt">
                <p className="absenceRowMainText">Delete account</p>
                <p className="absenceRowSubText paddingRight30">
                  Remove all users associated with {companyName} from FlowRota
                  and remove the entire account.
                  <br />
                  <br />
                  You won't be able to undo this.
                </p>
              </div>

              <p
                className="deleteAccBtn"
                onClick={() => {
                  memoVals.setShowDeleteAccountModal((x) => true);
                }}
              >
                Delete account
                <img
                  src={popup}
                  alt="Checklist"
                  className="showCheckListAdminImg"
                />
              </p>
            </div>
          </div>
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow clickShiftRowAdmin ${
              memoVals.mob ? "clickShiftRowAdminMob mobileAdminRow" : ""
            }`}
            onClick={(e) => {
              axios
                .post(
                  `${serverURL}/toggle-allow-absence-ranking`,
                  { toggleAllowAbsenceRanking: !memoVals.enableAbsenceRanking },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message.includes("logout")) {
                    window.location.href = "/signin";
                  }
                  if (response.data.message === "success") {
                    memoVals.setEnableAbsenceRanking(
                      (x) => !memoVals.enableAbsenceRanking
                    );
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">
                  Make absence ranking visible to staff
                </p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Enables employees to view how their absence performance ranks
                  against other staff.
                </p>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                <div className="knobContainer knobContainerSettings   ">
                  <div
                    className={`switchKnob ${
                      memoVals.enableAbsenceRanking ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}

          <br />
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default Billing;
