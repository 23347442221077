// keys done 5/3/25
// design 21 feb 25
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 aug 24

import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";

import axios from "axios";
import serverURL from "../serverURL";
import appURL from "../appURL";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import navyClose from "../img/general/navycross.svg";
import whiteBack from "../img/general/whiteBack.svg";
import addUserSingle from "../img/general/addUserSingle.svg";
import addUserGroup from "../img/general/addUserGroup.svg";
import greyCross from "../img/general/greyCross.svg";
import popup from "../img/general/popup.svg";

const AddUserModal = () => {
  const { mobModal, mob } = useContext(StyleContext);
  const {
    setIndicate,
    setShowAddUserModal,
    setShowEditUser,
    setAddNewUserDynamic,
    setShowChecklist,
    modalOpen,
    setModalOpen,
    device,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setIndicate,
      setShowAddUserModal,
      setShowEditUser,
      setAddNewUserDynamic,
      mobModal,
      mob,
      setShowChecklist,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setIndicate, //
      setShowAddUserModal, //
      setShowEditUser, //
      setAddNewUserDynamic, //
      mobModal, //
      mob, //
      setShowChecklist,
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let dateObj = new Date();
  dateObj.setHours(0, 0, 0, 0);

  let [availableTeams, setAvailableTeams] = useState([]);
  let [fName, setFName] = useState("");
  let [lName, setLName] = useState("");
  let [email, setEmail] = useState("");
  let [bulk, setBulk] = useState(false);
  let [showBulkMob, setShowBulkMob] = useState(false);
  let [startDateInput, setStartDateInput] = useState(
    dateStringer.createDateInputFromDateString(
      dateStringer.createStringFromTimestamp(new Date().getTime())
    )
  );

  // let [showAvgTool, setShowAvgTool] = useState({
  //   show: false,
  //   startDs: "Y2023M10D03H00M00",
  //   hours: true,
  // });

  // let [avgArr, setAvgArr] = useState([]);

  // let

  // useEffect(() => {
  //   let yesterday = new Date();
  //   yesterday.setDate(yesterday.getDate() - 1);
  //   yesterday.setHours(0, 0, 0, 0);
  //   if (showAvgTool.show) {
  //     setAvgArr([
  //       {
  //         firstDs: showAvgTool.startDs,
  //         lastDs: dateStringer.createStringFromTimestamp(yesterday.getTime()),
  //         value: 0,
  //       },
  //     ]);
  //   }
  // }, [showAvgTool]);

  let scrollRef = useRef();
  let fNameRef = useRef();
  let [hasHistory, setHasHistory] = useState(false);
  let [currYearStart, setCurrYearStart] = useState("");
  let today00ds = dateStringer.createStringFromTimestamp(dateObj.getTime());
  let [mpwBetweenStartDsAndToday, setMpwBetweenStartDsAndToday] = useState(0);
  let [primaryTeamID, setPrimaryTeamID] = useState("");
  let [emailAlreadyInUse, setEmailAlreadyInUse] = useState(false);

  let [histHours, setHistHours] = useState(0);
  let [histMins, setHistMins] = useState(0);

  let [histAnnLeaveHours, setHistAnnLeaveHours] = useState(0);
  let [histAnnLeaveMins, setHistAnnLeaveMins] = useState(0);

  let [paidAbsHours, setPaidAbsHours] = useState(0);
  let [paidAbsMins, setPaidAbsMins] = useState(0);
  let [unpaidAbsHours, setUnpaidAbsHours] = useState(0);
  let [unpaidAbsMins, setUnpaidAbsMins] = useState(0);

  let [avgHistoricalClosedDaysNum, setAvgHistoricalClosedDaysNum] = useState(0);

  let [teams, setTeams] = useState([]);

  // let [multiValid, setMultiValid]

  let [toggleAddToOtherTeam, setToggleAddToOtherTeam] = useState(false);

  let [addLoading, setAddLoading] = useState(false);
  // try {
  //   const addUserDateInput = document.getElementById("addUserDateInput");

  //   if (addUserDateInput && !memoVals.mobModal) {
  //     addUserDateInput.addEventListener("focus", function (evt) {
  //       try {
  //         if (this.getAttribute("type") === "date") {
  //           this.showPicker();
  //         }
  //       } catch (error) {
  //         console.error(
  //           "Error in addUserDateInput focus event listener:",
  //           error
  //         );
  //       }
  //     });
  //   }
  // } catch (error) {
  //   console.error("Error in setting up addUserDateInput:", error);
  // }

  let [inputsValid, setInputsValid] = useState(false);
  useEffect(() => {
    // clean not needed
    let valid = true;
    if (!fName || typeof fName !== "string" || fName.length > 40) {
      valid = false;
    }

    if (!lName || typeof lName !== "string" || lName.length > 40) {
      valid = false;
    }

    if (
      !email ||
      typeof email !== "string" ||
      !email.includes("@") ||
      !email.includes(".")
    ) {
      valid = false;
    }
    if (
      startDateInput &&
      startDateInput[0] &&
      startDateInput[1] &&
      (startDateInput[0] === "1" || startDateInput[0] === "2") &&
      (startDateInput[1] === "9" || startDateInput[1] === "0")
    ) {
    } else {
      valid = false;
    }

    if (!teams[0]) {
      let teamlessFilt = availableTeams.filter((t) => {
        return t.teamName === "Teamless";
      });
      if (teamlessFilt[0]) {
        setPrimaryTeamID(teamlessFilt[0].teamID);
      } else {
        valid = false;
      }
    }

    if (valid) {
      setInputsValid(true);
    } else {
      setInputsValid(false);
    }
  }, [
    fName,
    lName,
    email,
    startDateInput,
    mpwBetweenStartDsAndToday,
    teams,
    primaryTeamID,
  ]);

  useEffect(() => {
    // clean not needed
    setTimeout(() => {
      if (teams[0]) {
        if (scrollRef.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
      }
    }, 25);
  }, [teams]);

  let [refPeriodStartDs, setRefPeriodStartDs] = useState("");
  let [originalRefPeriodStartDs, setOriginalRefPeriodStartDs] = useState("");
  let [totPaidHoursInRefPeriod, setTotPaidHoursInRefPeriod] = useState(0);

  let [multiUsers, setMultiUsers] = useState([
    {
      fName: "",
      lName: "",
      email: "",
      startDateInput: "",
      jobTitle: "",
      hasHistory: false,
      teams: [],
      primaryTeamID: "",
      totPaidHoursInRefPeriod: 0,
      avgHistoricalClosedDaysNum: 0,
      histHours: 0,
      histAnnLeaveHours: 0,
      paidAbsHours: 0,
      unpaidAbsHours: 0,
      key: Math.random(),
    },
  ]);

  let [multiUsersValid, setMultiUsersValid] = useState(false);
  let [showRed, setShowRed] = useState(false);
  let [errorArr, setErrorArr] = useState([]); // bulk only

  useEffect(() => {
    let fine = true;
    let errors = [];
    multiUsers.forEach((user) => {
      let dupeEmailFilt = multiUsers.filter((x) => {
        return x.key !== user.key && user.email === x.email;
      });
      if (
        dupeEmailFilt[0] ||
        !user.fName ||
        !user.lName ||
        !user.email ||
        !user.email.includes(".") ||
        !user.email.includes("@") ||
        user.startDateInput.length !== 10 ||
        !user.jobTitle ||
        !user.teams[0] ||
        !user.primaryTeamID
      ) {
        fine = false;
      }
      errors.push({
        key: user.key,
        fName: user.fName ? true : false,
        lName: user.lName ? true : false,
        email:
          user.email &&
          user.email.includes(".") &&
          user.email.includes("@") &&
          !dupeEmailFilt[0]
            ? true
            : false,
        startDateInput: user.startDateInput.length === 10 ? true : false,
        teams: user.teams[0] ? true : false,
        jobTitle: user.jobTitle ? true : false,
        primaryTeamID: user.primaryTeamID ? true : false,
      });
    });

    if (fine) {
      setErrorArr([]);
      if (!multiUsersValid) {
        setMultiUsersValid(true);
      }
    } else {
      setErrorArr(errors);
      if (multiUsersValid) {
        setMultiUsersValid(false);
      }
    }
  }, [multiUsers]);
  let [checklistRequired, setChecklistRequired] = useState(false);
  useEffect(() => {
    if (fNameRef.current) {
      if (!memoVals.mobModal) {
        fNameRef.current.focus();
      }
    }
    const cancelSource1 = axios.CancelToken.source();

    // get add user options
    axios
      .post(
        `${serverURL}/add-user-options`,
        {
          someData: 1,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          if (response.data.checklistRequired) {
            setChecklistRequired(true);
          } else {
            let teamlessObj = {};
            let remaining = [];
            response.data.teams.forEach((t) => {
              if (t.teamName === "Teamless") {
                teamlessObj = t;
              } else {
                remaining.push(t);
              }
            });

            remaining.sort((a, b) => {
              return a.teamName.localeCompare(b.teamName);
            });
            setAvailableTeams([teamlessObj, ...remaining]);
            let refPeriodDateObj = new Date();
            let refPeriodDays = response.data.refPeriod * 7;
            refPeriodDateObj.setDate(
              refPeriodDateObj.getDate() - refPeriodDays
            );
            refPeriodDateObj.setHours(0, 0, 0, 0);
            setRefPeriodStartDs(
              dateStringer.createStringFromTimestamp(refPeriodDateObj.getTime())
            );
            setOriginalRefPeriodStartDs(
              dateStringer.createStringFromTimestamp(refPeriodDateObj.getTime())
            );
            setCurrYearStart(response.data.currYearStart);
          }
          setDataLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let generateAvailableTeams = availableTeams.map((t) => {
    let alreadyAdded = false;
    teams.forEach((item) => {
      if (item === t.teamID) {
        alreadyAdded = true;
      }
    });

    let valid = true;

    if (!alreadyAdded) {
      return (
        <option key={t.teamID} value={t.teamID}>
          {t.teamName}
        </option>
      );
    }
  });

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowAddUserModal((x) => false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let closeModal = () => {
    memoVals.setShowAddUserModal((x) => false);
  };

  let [page, setPage] = useState(1);
  let [loading, setLoading] = useState(true);
  let [imagesLoaded, setImagesLoaded] = useState(0);
  useEffect(() => {
    // clean not needed
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [page]);

  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (imagesLoaded === 5 && dataLoaded) {
      setLoading(false);
    }
  }, [imagesLoaded, dataLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyClose}
        alt="Day Limit Power 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteBack}
        alt="Day Limit Power 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={addUserSingle}
        alt="Bulk Plus"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={addUserGroup}
        alt="Bulk Minus"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />

      <img
        src={greyCross}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  useEffect(() => {
    if (startDateInput && startDateInput.length === 10) {
      let ts = dateStringer.createTimestampFromString(
        dateStringer.createStringFromDateInput(startDateInput)
      );

      let refPeriodStartTs = dateStringer.createTimestampFromString(
        originalRefPeriodStartDs
      );

      if (ts > refPeriodStartTs) {
        setRefPeriodStartDs(dateStringer.createStringFromTimestamp(ts));
      } else {
        setRefPeriodStartDs(originalRefPeriodStartDs);
      }
    }
  }, [startDateInput]);

  // master return

  return (
    <div
      className="clockOnModalUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      {!addLoading && !checklistRequired && !loading ? (
        <div
          className={`dayCommentsModal addUserModalHeight maxViewZoneModalWidth ${
            memoVals.mobModal ? "addUserMobMmodal mobModalShoulder" : ""
          } ${bulk ? "bulkAddUserModal" : ""} ${
            memoVals.device.ios ? "paddingBottom35" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperAddUser"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="mySwapsHeader minWidth100Pad2"
            onClick={() => {
              if (memoVals.mobModal) {
                closeModal();
              }
            }}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={memoVals.mobModal ? navyClose : navyClose}
              alt="Close"
              className="closeMySwapsModalImg"
              onClick={() => {
                closeModal();
              }}
            />
            <p>Add {bulk ? "multiple employees" : "an employee"}</p>

            {/* <p></p> */}
          </div>
          {bulk ? (
            <div className="bulkAddUsersBox">
              {multiUsers.map((user, i) => {
                return (
                  <div className="multiUserRow" key={i}>
                    <div className="multiUserRow1">
                      <div className="multiRowLeft">
                        <div className="multiUserRowFNameBlock">
                          <p className="multiRowUserTitle">First name</p>
                          <input
                            type="text"
                            className={`addUserTextInput multiNameInputter ${
                              !user.fName ? "invalidMultiEmailInput" : ""
                            } ${
                              errorArr &&
                              errorArr.filter((x) => {
                                return x.key === user.key && !x.fName;
                              }).length > 0 &&
                              showRed
                                ? "borderRed"
                                : ""
                            }`}
                            value={user.fName}
                            onChange={(e) => {
                              let newVal = e.target.value;

                              let newArr = [];
                              multiUsers.map((usr, ind) => {
                                if (ind === i) {
                                  usr.fName = newVal;
                                }
                                newArr.push(usr);
                              });
                              setMultiUsers(newArr);

                              let errFilt = errorArr.filter((x) => {
                                return x.key === user.key;
                              });

                              if (errFilt[0]) {
                                let newErrArr = [];
                                errorArr.map((x) => {
                                  if (x.key === user.key) {
                                    x.fName = newVal ? true : false;
                                    newErrArr.push(x);
                                  } else {
                                    newErrArr.push(x);
                                  }
                                });
                                setErrorArr(newErrArr);
                              }
                            }}
                          ></input>
                        </div>
                        <div className="multiUserRowFNameBlock">
                          <p className="multiRowUserTitle">Last name</p>
                          <input
                            type="text"
                            className={`addUserTextInput multiNameInputter ${
                              !user.lName ? "invalidMultiEmailInput" : ""
                            } ${
                              errorArr &&
                              errorArr.filter((x) => {
                                return x.key === user.key && !x.lName;
                              }).length > 0 &&
                              showRed
                                ? "borderRed"
                                : ""
                            }`}
                            value={user.lName}
                            onChange={(e) => {
                              let newVal = e.target.value;

                              let newArr = [];
                              multiUsers.map((usr, ind) => {
                                if (ind === i) {
                                  usr.lName = newVal;
                                }
                                newArr.push(usr);
                              });
                              setMultiUsers(newArr);
                              let errFilt = errorArr.filter((x) => {
                                return x.key === user.key;
                              });

                              if (errFilt[0]) {
                                let newErrArr = [];
                                errorArr.map((x) => {
                                  if (x.key === user.key) {
                                    x.lName = newVal ? true : false;
                                    newErrArr.push(x);
                                  } else {
                                    newErrArr.push(x);
                                  }
                                });
                                setErrorArr(newErrArr);
                              }
                            }}
                          ></input>
                        </div>
                        <div className="multiUserRowFNameBlock">
                          <p className="multiRowUserTitle">Email</p>
                          <input
                            type="text"
                            className={`addUserTextInput multiNameInputter multiEmailInputter ${
                              !user.email.includes("@") ||
                              !user.email.includes(".")
                                ? "invalidMultiEmailInput"
                                : ""
                            } ${
                              errorArr &&
                              errorArr.filter((x) => {
                                return x.key === user.key && !x.email;
                              }).length > 0 &&
                              showRed
                                ? "borderRed"
                                : ""
                            }`}
                            value={user.email}
                            onChange={(e) => {
                              let newVal = e.target.value;

                              let newArr = [];
                              multiUsers.map((usr, ind) => {
                                if (ind === i) {
                                  usr.email = newVal;
                                }
                                newArr.push(usr);
                              });
                              setMultiUsers(newArr);
                              let errFilt = errorArr.filter((x) => {
                                return x.key === user.key;
                              });
                              if (errFilt[0]) {
                                let newErrArr = [];
                                errorArr.map((x) => {
                                  if (x.key === user.key) {
                                    x.email =
                                      newVal &&
                                      newVal.includes("@") &&
                                      newVal.includes(".")
                                        ? true
                                        : false;
                                    newErrArr.push(x);
                                  } else {
                                    newErrArr.push(x);
                                  }
                                });
                                setErrorArr(newErrArr);
                              }
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="multiRowRight">
                        <div className="multiUserRowDateBlock">
                          <p className="multiRowUserTitle">
                            Joined the company
                          </p>
                          <input
                            type="date"
                            onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                            id="addUserDateInput"
                            className={`addUserDate ${
                              !user.startDateInput ? "opac05_" : ""
                            } multiUserStartDate ${
                              errorArr &&
                              errorArr.filter((x) => {
                                return x.key === user.key && !x.startDateInput;
                              }).length > 0 &&
                              showRed
                                ? "borderRed"
                                : ""
                            }`}
                            value={user.startDateInput}
                            onChange={(e) => {
                              let newVal = e.target.value;

                              let newArr = [];
                              multiUsers.map((usr, ind) => {
                                if (ind === i) {
                                  usr.startDateInput = newVal;
                                  if (
                                    e.target.value.length === 10 &&
                                    e.target.value[0]
                                  ) {
                                    let ts =
                                      dateStringer.createTimestampFromString(
                                        dateStringer.createStringFromDateInput(
                                          e.target.value
                                        )
                                      );

                                    if (
                                      ts <
                                      dateStringer.createTimestampFromString(
                                        today00ds
                                      )
                                    ) {
                                      usr.hasHistory = true;
                                    } else {
                                      usr.hasHistory = false;
                                    }
                                  }
                                }
                                newArr.push(usr);
                              });

                              setMultiUsers(newArr);
                              let errFilt = errorArr.filter((x) => {
                                return x.key === user.key;
                              });
                              if (errFilt[0]) {
                                let newErrArr = [];
                                errorArr.map((x) => {
                                  if (x.key === user.key) {
                                    x.startDateInput =
                                      newVal && newVal.length === 10
                                        ? true
                                        : false;
                                    newErrArr.push(x);
                                  } else {
                                    newErrArr.push(x);
                                  }
                                });
                                setErrorArr(newErrArr);
                              }
                            }}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="multiUserRow2">
                      {/* teams here */}
                      <div className="multiRowLeft">
                        {" "}
                        <div className="multiUserRowFNameBlock multiTeamBlock">
                          <p className="multiRowUserTitle">Teams</p>
                          {user.teams[0] && (
                            <div className="partOfTeamsDivBulk">
                              {user.teams.map((teamID) => {
                                let teamName = availableTeams.filter((item) => {
                                  return item.teamID === teamID;
                                });

                                if (teamName[0]) {
                                  return (
                                    <div
                                      key={teamID}
                                      className={`editUserTeamUnit`}
                                    >
                                      <p className="editUserTeamUnitName">
                                        {dateStringer.shorten(
                                          teamName[0].teamName,
                                          30
                                        )}
                                      </p>
                                      <img
                                        src={greyCross}
                                        alt="remove"
                                        className="removeTeamUnitCrossImg"
                                        onClick={() => {
                                          let newArr = [];
                                          let newTeams = [];
                                          user.teams.forEach((t) => {
                                            if (t !== teamID) {
                                              newTeams.push(t);
                                            }
                                          });
                                          multiUsers.map((usr, ind) => {
                                            if (ind === i) {
                                              usr.teams = newTeams;
                                              usr.primaryTeamID = newTeams[0]
                                                ? newTeams[0]
                                                : "";
                                            }
                                            newArr.push(usr);
                                          });
                                          setMultiUsers(newArr);
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          )}
                          <select
                            className={`addUserTeamDropdown ${
                              !memoVals.mob ? "addUserTeamDropdownHover" : ""
                            } bulkTeamSel ${
                              user.teams.length === 0 ? "marginTop2" : ""
                            } ${
                              errorArr &&
                              errorArr.filter((x) => {
                                return x.key === user.key && !x.teams;
                              }).length > 0 &&
                              showRed
                                ? "borderRed"
                                : ""
                            }`}
                            defaultValue="Add to a team"
                            value="default-team-drop"
                            onChange={(e) => {
                              if (e.target.value !== "default-team-drop") {
                                // let pTeamID = user.primaryTeamID;
                                let teamsArr = user.teams || [];

                                // if (!teamsArr) {
                                //   pTeamID = e.target.value;
                                // }

                                teamsArr.push(e.target.value);

                                let teamlessID = availableTeams[0].teamID;

                                if (e.target.value === teamlessID) {
                                  teamsArr = [teamlessID];
                                } else {
                                  if (
                                    teamsArr.includes(teamlessID) &&
                                    e.target.value !== teamlessID
                                  ) {
                                    // remove teamlessID
                                    let arrWithoutTeamless = [];
                                    teamsArr.forEach((t) => {
                                      if (t !== teamlessID) {
                                        arrWithoutTeamless.push(t);
                                      }
                                    });

                                    teamsArr = arrWithoutTeamless;
                                  }
                                }
                                // - - -

                                let newArr = [];
                                multiUsers.map((usr, ind) => {
                                  if (ind === i) {
                                    usr.primaryTeamID =
                                      teamsArr.length === 1
                                        ? teamsArr[0]
                                        : usr.primaryTeamID;
                                    usr.teams = teamsArr;
                                  }
                                  newArr.push(usr);
                                });
                                setMultiUsers(newArr);
                                let errFilt = errorArr.filter((x) => {
                                  return x.key === user.key;
                                });
                                if (errFilt[0]) {
                                  let newErrArr = [];
                                  errorArr.map((x) => {
                                    if (x.key === user.key) {
                                      x.teams = newArr[0] ? true : false;
                                      newErrArr.push(x);
                                    } else {
                                      newErrArr.push(x);
                                    }
                                  });
                                  setErrorArr(newErrArr);
                                }
                              }
                            }}
                          >
                            <option value="default-team-drop">
                              {user.teams[0] ? "Select team" : "Add to a team"}
                            </option>
                            {availableTeams.map((t) => {
                              if (!user.teams.includes(t.teamID)) {
                                return (
                                  <option key={t.teamID} value={t.teamID}>
                                    {t.teamName}
                                  </option>
                                );
                              }

                              // let alreadyAdded = false;
                              // teams.forEach((item) => {
                              //   if (item === t.teamID) {
                              //     alreadyAdded = true;
                              //   }
                              // })}
                            })}
                          </select>
                        </div>
                        <div className="multiUserRowFNameBlock multiTeamBlock">
                          <p className="multiRowUserTitle">Primary team</p>

                          {user.teams[0] ? (
                            <select
                              className={`addUserTeamDropdown ${
                                !memoVals.mob ? "addUserTeamDropdownHover" : ""
                              } bulkTeamSel primBulkDrop ${
                                user.teams.length === 0 ? "marginTop2" : ""
                              } ${
                                errorArr &&
                                errorArr.filter((x) => {
                                  return x.key === user.key && !x.primaryTeamID;
                                }).length > 0 &&
                                showRed
                                  ? "borderRed"
                                  : ""
                              }`}
                              defaultValue="Select team"
                              value={user.primaryTeamID}
                              onChange={(e) => {
                                if (e.target.value !== "default-team-drop") {
                                  let newArr = [];
                                  multiUsers.map((usr, ind) => {
                                    if (ind === i) {
                                      usr.primaryTeamID = e.target.value;
                                    }
                                    newArr.push(usr);
                                  });
                                  setMultiUsers(newArr);
                                }
                              }}
                            >
                              <option value="default-team-drop">
                                {user.teams[0]
                                  ? "Select team"
                                  : "Add to a team"}
                              </option>
                              {user.teams.map((t) => {
                                let getTeam = availableTeams.filter((x) => {
                                  return x.teamID === t;
                                });
                                return (
                                  <option key={t} value={t}>
                                    {getTeam[0].teamName}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <p className="bulkAddTeamFirst">
                              Add to a team first
                            </p>
                          )}
                        </div>
                        <div className="multiUserRowFNameBlock multiTeamBlock">
                          <p className="multiRowUserTitle">Job title</p>

                          <input
                            type="text"
                            className={`addUserTextInput multiNameInputter multiJtInput ${
                              !user.jobTitle ? "invalidMultiEmailInput" : ""
                            } ${
                              errorArr &&
                              errorArr.filter((x) => {
                                return x.key === user.key && !x.jobTitle;
                              }).length > 0 &&
                              showRed
                                ? "borderRed"
                                : ""
                            }`}
                            value={user.jobTitle}
                            onChange={(e) => {
                              let newVal = e.target.value;

                              let newArr = [];
                              multiUsers.map((usr, ind) => {
                                if (ind === i) {
                                  usr.jobTitle = newVal;
                                }
                                newArr.push(usr);
                              });
                              setMultiUsers(newArr);
                              let errFilt = errorArr.filter((x) => {
                                return x.key === user.key;
                              });
                              if (errFilt[0]) {
                                let newErrArr = [];
                                errorArr.map((x) => {
                                  if (x.key === user.key) {
                                    x.jobTitle = newVal ? true : false;
                                    newErrArr.push(x);
                                  } else {
                                    newErrArr.push(x);
                                  }
                                });
                                setErrorArr(newErrArr);
                              }
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="multiRowRight">
                        <p
                          className={`removeMultiUser ${
                            !multiUsers[1] ? "none" : ""
                          }`}
                          onClick={() => {
                            setMultiUsers(
                              multiUsers.filter((x) => {
                                return x.key !== user.key;
                              })
                            );
                          }}
                        >
                          Remove
                        </p>
                        {/* <img
                          src={bin1}
                          alt="Remove user row"
                          className="removeUserRowBinImg"
                          onClick={() => {
                            setMultiUsers(
                              multiUsers.filter((x) => {
                                return x.key !== user.key;
                              })
                            );
                          }}
                        /> */}
                      </div>
                    </div>
                    {user.teams[0] &&
                      user.hasHistory &&
                      user.startDateInput.length === 10 && (
                        <div className="multiUserRow3">
                          <div className="multiUserRow3Block">
                            <div className="row3BlockLeft">
                              <p className="addUserNum">Question 1 / 6</p>

                              <p className="lonLatTitles adminAddUserLargerTitle">
                                How many{" "}
                                <span className="makeBold color00aaff">
                                  total hours
                                </span>{" "}
                                between{" "}
                                <span className="makeBold color00aaff">
                                  {dateStringer.printedDateWithYrFromDWithoutDay(
                                    refPeriodStartDs
                                  )}
                                </span>{" "}
                                and{" "}
                                <span className="makeBold color00aaff">
                                  today
                                </span>{" "}
                                (excluding today) was {user.fName} paid for -
                                including paid work, paid leave and paid
                                absence?
                                <br />
                              </p>
                              <p className="x2329829119912">
                                This is for employees who regularly work
                                overtime. By skipping this question, FlowRota
                                will be unable to accurately calculate{" "}
                                {dateStringer.possession(fName)} accrued
                                overtime holiday pay from their overtime worked
                                before today.
                              </p>
                            </div>

                            <div
                              className={`lonLatInputDiv historyMinsAddUserDiv row3BlockRight`}
                            >
                              {" "}
                              <input
                                type="number"
                                className={`addUserTextInput addUserHistNumInput`}
                                value={user.totPaidHoursInRefPeriod}
                                onChange={(e) => {
                                  let newArr = [];
                                  multiUsers.map((usr, ind) => {
                                    if (ind === i) {
                                      usr.totPaidHoursInRefPeriod =
                                        dateStringer.roundToNearestHalf(
                                          parseFloat(e.target.value)
                                        );
                                    }
                                    newArr.push(usr);
                                  });
                                  setMultiUsers(newArr);
                                }}
                              ></input>{" "}
                              <p className="addUserHistHoursTxt multiMetric">
                                total hours
                              </p>
                              {/* <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={histMins}
                    onChange={(e) => {
                      setHistMins(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">minutes</p> */}
                            </div>
                          </div>
                          <div className="multiUserRow3Block">
                            <div className="row3BlockLeft">
                              <p className="addUserNum">Question 2 / 6</p>

                              <p className="lonLatTitles adminAddUserLargerTitle">
                                How many{" "}
                                <span className="makeBold color00aaff">
                                  days per week
                                </span>{" "}
                                between{" "}
                                <span className="makeBold color00aaff">
                                  {dateStringer.printedDateWithYrFromDWithoutDay(
                                    refPeriodStartDs
                                  )}
                                </span>{" "}
                                and{" "}
                                <span className="makeBold color00aaff">
                                  today
                                </span>{" "}
                                (excluding today) was {user.fName} contracted to
                                work?
                              </p>
                              <p className="x2329829119912">
                                If this number changed within the period, please
                                provide an average figure.
                                {/* If this number changed within the period, use{" "}
                  <span
                    className="avgTool"
                    onClick={() => {
                      setShowAvgTool({
                        show: true,
                        startDs: refPeriodStartDs,
                        days: true,
                      });
                    }}
                  >
                    this tool
                  </span>{" "} */}
                                {/* to calculate an average. */}
                              </p>
                            </div>
                            {/* <p className="x2398911991">
                            This is for employees who regularly work overtime.
                            By skipping this question, FlowRota will be unable
                            to accurately calculate{" "}
                            {dateStringer.possession(fName)} accrued overtime
                            holiday pay from their overtime worked before today.
                          </p> */}
                            <div
                              className={`lonLatInputDiv historyMinsAddUserDiv row3BlockRight`}
                            >
                              {" "}
                              <input
                                type="number"
                                className={`addUserTextInput addUserHistNumInput`}
                                value={user.avgHistoricalClosedDaysNum}
                                min={0}
                                max={7}
                                onChange={(e) => {
                                  let newArr = [];
                                  multiUsers.map((usr, ind) => {
                                    if (ind === i) {
                                      usr.avgHistoricalClosedDaysNum =
                                        dateStringer.roundToNearestHalf(
                                          parseFloat(e.target.value)
                                        );
                                    }
                                    newArr.push(usr);
                                  });
                                  setMultiUsers(newArr);
                                }}
                              ></input>{" "}
                              <p className="addUserHistHoursTxt multiMetric">
                                days / week
                              </p>
                              {/* <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={histMins}
                    onChange={(e) => {
                      setHistMins(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">minutes</p> */}
                            </div>
                          </div>
                          <div className="multiUserRow3Block">
                            <div className="row3BlockLeft">
                              <p className="addUserNum">Question 3 / 6</p>

                              <p className="lonLatTitles adminAddUserLargerTitle">
                                How many{" "}
                                <span className="makeBold color00aaff">
                                  hours per week
                                </span>{" "}
                                between{" "}
                                <span className="makeBold color00aaff">
                                  {dateStringer.createTimestampFromString(
                                    dateStringer.createStringFromDateInput(
                                      user.startDateInput
                                    )
                                  ) >=
                                  dateStringer.createTimestampFromString(
                                    currYearStart
                                  )
                                    ? dateStringer.printedDateWithYrFromDWithoutDay(
                                        dateStringer.createStringFromDateInput(
                                          user.startDateInput
                                        )
                                      )
                                    : dateStringer.printedDateWithYrFromDWithoutDay(
                                        currYearStart
                                      )}
                                </span>{" "}
                                and{" "}
                                <span className="makeBold color00aaff">
                                  today
                                </span>{" "}
                                (excluding today) was {user.fName} contracted to
                                work?
                                <br />
                              </p>
                              <p className="x2329829119912">
                                If this number changed within the period, please
                                provide an average figure.
                                {/* If this number changed within the period, use{" "}
                  <span
                    className="avgTool"
                    onClick={() => {
                      setShowAvgTool({
                        show: true,
                        startDs: refPeriodStartDs,
                        hours: true,
                      });
                    }}
                  >
                    this tool
                  </span>{" "}
                  to calculate an average. */}
                              </p>
                            </div>
                            {/* <p className="x2398911991">
                            This is for employees who regularly work overtime.
                            By skipping this question, FlowRota will be unable
                            to accurately calculate{" "}
                            {dateStringer.possession(fName)} accrued overtime
                            holiday pay from their overtime worked before today.
                          </p> */}
                            <div
                              className={`lonLatInputDiv historyMinsAddUserDiv row3BlockRight`}
                            >
                              {" "}
                              <input
                                type="number"
                                className={`addUserTextInput addUserHistNumInput`}
                                value={user.histHours}
                                onChange={(e) => {
                                  let newArr = [];
                                  multiUsers.map((usr, ind) => {
                                    if (ind === i) {
                                      usr.histHours =
                                        dateStringer.roundToNearestHalf(
                                          parseFloat(e.target.value)
                                        );
                                    }
                                    newArr.push(usr);
                                  });
                                  setMultiUsers(newArr);
                                }}
                              ></input>{" "}
                              <p className="addUserHistHoursTxt multiMetric">
                                hours / week
                              </p>
                              {/* <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={histMins}
                    onChange={(e) => {
                      setHistMins(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">minutes</p> */}
                            </div>
                          </div>
                          <div className="multiUserRow3Block">
                            <div className="row3BlockLeft">
                              <p className="addUserNum">Question 4 / 6</p>

                              <p className="lonLatTitles adminAddUserLargerTitle">
                                How many{" "}
                                <span className="makeBold color00aaff">
                                  Annual Leave
                                </span>{" "}
                                hours has {user.fName} taken between{" "}
                                <span className="makeBold color00aaff">
                                  {dateStringer.createTimestampFromString(
                                    dateStringer.createStringFromDateInput(
                                      user.startDateInput
                                    )
                                  ) >=
                                  dateStringer.createTimestampFromString(
                                    currYearStart
                                  )
                                    ? dateStringer.printedDateWithYrFromDWithoutDay(
                                        dateStringer.createStringFromDateInput(
                                          user.startDateInput
                                        )
                                      )
                                    : dateStringer.printedDateWithYrFromDWithoutDay(
                                        currYearStart
                                      )}
                                </span>{" "}
                                and{" "}
                                <span className="makeBold color00aaff">
                                  today
                                </span>{" "}
                                (excluding today)? {/* (excluding today)? */}
                                {/* <br /> */}
                                {/* <br /> */}
                                {/* Exclude any leave booked for today onwards. */}
                              </p>
                            </div>
                            {/* <p className="x2398911991">
                            This is for employees who regularly work overtime.
                            By skipping this question, FlowRota will be unable
                            to accurately calculate{" "}
                            {dateStringer.possession(fName)} accrued overtime
                            holiday pay from their overtime worked before today.
                          </p> */}
                            <div
                              className={`lonLatInputDiv historyMinsAddUserDiv row3BlockRight`}
                            >
                              {" "}
                              <input
                                type="number"
                                className={`addUserTextInput addUserHistNumInput`}
                                value={user.histAnnLeaveHours}
                                onChange={(e) => {
                                  let newArr = [];
                                  multiUsers.map((usr, ind) => {
                                    if (ind === i) {
                                      usr.histAnnLeaveHours =
                                        dateStringer.roundToNearestHalf(
                                          parseFloat(e.target.value)
                                        );
                                    }
                                    newArr.push(usr);
                                  });
                                  setMultiUsers(newArr);
                                }}
                              ></input>{" "}
                              <p className="addUserHistHoursTxt multiMetric">
                                total hours
                              </p>
                              {/* <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={histMins}
                    onChange={(e) => {
                      setHistMins(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">minutes</p> */}
                            </div>
                          </div>
                          <div className="multiUserRow3Block">
                            <div className="row3BlockLeft">
                              <p className="addUserNum">Question 5 / 6</p>

                              <p className="lonLatTitles adminAddUserLargerTitle">
                                How many{" "}
                                <span className="makeBold color00aaff">
                                  paid absent
                                </span>{" "}
                                hours has {user.fName} had between{" "}
                                <span className="makeBold color00aaff">
                                  {dateStringer.createTimestampFromString(
                                    dateStringer.createStringFromDateInput(
                                      user.startDateInput
                                    )
                                  ) >=
                                  dateStringer.createTimestampFromString(
                                    currYearStart
                                  )
                                    ? dateStringer.printedDateWithYrFromDWithoutDay(
                                        dateStringer.createStringFromDateInput(
                                          user.startDateInput
                                        )
                                      )
                                    : dateStringer.printedDateWithYrFromDWithoutDay(
                                        currYearStart
                                      )}
                                </span>{" "}
                                and{" "}
                                <span className="makeBold color00aaff">
                                  today
                                </span>{" "}
                                (excluding today)?
                              </p>
                            </div>
                            {/* <p className="x2398911991">
                            This is for employees who regularly work overtime.
                            By skipping this question, FlowRota will be unable
                            to accurately calculate{" "}
                            {dateStringer.possession(fName)} accrued overtime
                            holiday pay from their overtime worked before today.
                          </p> */}
                            <div
                              className={`lonLatInputDiv historyMinsAddUserDiv row3BlockRight`}
                            >
                              {" "}
                              <input
                                type="number"
                                className={`addUserTextInput addUserHistNumInput`}
                                value={user.paidAbsHours}
                                onChange={(e) => {
                                  let newArr = [];
                                  multiUsers.map((usr, ind) => {
                                    if (ind === i) {
                                      usr.paidAbsHours =
                                        dateStringer.roundToNearestHalf(
                                          parseFloat(e.target.value)
                                        );
                                    }
                                    newArr.push(usr);
                                  });
                                  setMultiUsers(newArr);
                                }}
                              ></input>{" "}
                              <p className="addUserHistHoursTxt multiMetric">
                                total hours
                              </p>
                              {/* <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={histMins}
                    onChange={(e) => {
                      setHistMins(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">minutes</p> */}
                            </div>
                          </div>
                          <div className="multiUserRow3Block noBorder">
                            <div className="row3BlockLeft">
                              <p className="addUserNum">Question 6 / 6</p>
                              <p className="lonLatTitles adminAddUserLargerTitle">
                                Finally, how many{" "}
                                <span className="makeBold color00aaff">
                                  unpaid absent
                                </span>{" "}
                                hours has {user.fName} had between{" "}
                                <span className="makeBold color00aaff">
                                  {dateStringer.createTimestampFromString(
                                    dateStringer.createStringFromDateInput(
                                      user.startDateInput
                                    )
                                  ) >=
                                  dateStringer.createTimestampFromString(
                                    currYearStart
                                  )
                                    ? dateStringer.printedDateWithYrFromDWithoutDay(
                                        dateStringer.createStringFromDateInput(
                                          user.startDateInput
                                        )
                                      )
                                    : dateStringer.printedDateWithYrFromDWithoutDay(
                                        currYearStart
                                      )}
                                </span>{" "}
                                and{" "}
                                <span className="makeBold color00aaff">
                                  today
                                </span>{" "}
                                (excluding today)?
                              </p>
                            </div>
                            {/* <p className="x2398911991">
                            This is for employees who regularly work overtime.
                            By skipping this question, FlowRota will be unable
                            to accurately calculate{" "}
                            {dateStringer.possession(fName)} accrued overtime
                            holiday pay from their overtime worked before today.
                          </p> */}
                            <div
                              className={`lonLatInputDiv historyMinsAddUserDiv row3BlockRight`}
                            >
                              {" "}
                              <input
                                type="number"
                                className={`addUserTextInput addUserHistNumInput`}
                                value={user.unpaidAbsHours}
                                onChange={(e) => {
                                  let newArr = [];
                                  multiUsers.map((usr, ind) => {
                                    if (ind === i) {
                                      usr.unpaidAbsHours =
                                        dateStringer.roundToNearestHalf(
                                          parseFloat(e.target.value)
                                        );
                                    }
                                    newArr.push(usr);
                                  });
                                  setMultiUsers(newArr);
                                }}
                              ></input>{" "}
                              <p className="addUserHistHoursTxt multiMetric">
                                total hours
                              </p>
                              {/* <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={histMins}
                    onChange={(e) => {
                      setHistMins(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">minutes</p> */}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                );
              })}
              <p
                className={`addUserBulkRow ${
                  multiUsers.length === 0 ? "addUserBulkRowNil" : ""
                }`}
                onClick={() => {
                  if (showRed) {
                    setShowRed(false);
                  }
                  setMultiUsers([
                    ...multiUsers,
                    {
                      fName: "",
                      lName: "",
                      hasHistory: false,
                      email: "",
                      startDateInput: "",
                      teams: [],
                      primaryTeamID: "",
                      key: Math.random(),
                    },
                  ]);
                }}
              >
                Add employee
              </p>
            </div>
          ) : page === 1 ? (
            <div
              className={`dayCommentsModalBody addUserScroll ${
                !email ||
                !lName ||
                !fName ||
                !email.includes("@") ||
                !email.includes(".")
                  ? "addUserPre"
                  : ""
              } `}
              ref={scrollRef}
            >
              <div className="gpsZoneRow noBorder">
                <p className="lonLatTitles">First name</p>

                <div className={`lonLatInputDiv`}>
                  {" "}
                  <input
                    ref={fNameRef}
                    type="text"
                    className={`addUserTextInput addUserNameInputs`}
                    value={fName}
                    onChange={(e) => {
                      setFName(e.target.value);
                    }}
                  ></input>
                </div>
              </div>

              <div className="gpsZoneRow">
                <p className="lonLatTitles">Last name</p>

                <div className={`lonLatInputDiv`}>
                  {" "}
                  <input
                    type="text"
                    className={`addUserTextInput addUserNameInputs`}
                    value={lName}
                    onChange={(e) => {
                      setLName(e.target.value);
                    }}
                  ></input>
                </div>

                <div className="gpsZoneRow">
                  <p className="lonLatTitles">Email address</p>

                  <div className={`lonLatInputDiv`}>
                    {" "}
                    <input
                      type="text"
                      className={`addUserTextInput ${
                        ((!email.includes("@") || !email.includes(".")) &&
                          email) ||
                        emailAlreadyInUse
                          ? "invalidAddUserEmailInput"
                          : ""
                      }`}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailAlreadyInUse(false);
                      }}
                    ></input>
                  </div>
                </div>

                {
                  (fName,
                  lName,
                  email ? (
                    <div className="gpsZoneRow">
                      <p className="lonLatTitles">
                        The date they joined your company
                      </p>

                      <div className={`lonLatInputDiv`}>
                        {" "}
                        <input
                          type="date"
                          onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                          id="addUserDateInput"
                          className={`addUserDate ${
                            !startDateInput ? "opac05" : ""
                          } multiUserStartDate x384298821 ${
                            memoVals.device.ios ? "iosDateInput" : ""
                          }`}
                          value={startDateInput}
                          onChange={(e) => {
                            setStartDateInput(e.target.value);

                            if (
                              e.target.value.length === 10 &&
                              e.target.value[0]
                            ) {
                              let ts = dateStringer.createTimestampFromString(
                                dateStringer.createStringFromDateInput(
                                  e.target.value
                                )
                              );

                              if (
                                ts <
                                dateStringer.createTimestampFromString(
                                  today00ds
                                )
                              ) {
                                setHasHistory(true);
                              } else {
                                setHasHistory(false);
                              }
                            }
                          }}
                        ></input>
                      </div>
                    </div>
                  ) : (
                    ""
                  ))
                }

                {
                  (fName,
                  lName,
                  email ? (
                    <div className="gpsZoneRow">
                      <p className="lonLatTitles">Teams</p>
                      {teams[0] ? (
                        <div className="partOfTeamsDiv">
                          {teams.map((teamID) => {
                            let teamName = availableTeams.filter((item) => {
                              return item.teamID === teamID;
                            });

                            if (teamName[0]) {
                              return (
                                <div
                                  key={teamID}
                                  className={`editUserTeamUnit`}
                                >
                                  <p className="editUserTeamUnitName">
                                    {teamName[0].teamName}
                                  </p>
                                  <img
                                    src={greyCross}
                                    alt="remove"
                                    className="removeTeamUnitCrossImg"
                                    onClick={() => {
                                      let newArr = [];
                                      teams.forEach((team) => {
                                        if (team !== teamID) {
                                          newArr.push(team);
                                        }
                                      });

                                      setTeams(newArr);
                                    }}
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className={`lonLatInputDiv`}>
                        {" "}
                        {teams[0] &&
                        availableTeams.length > 1 &&
                        !toggleAddToOtherTeam ? (
                          <p
                            className="adminAddUserAddToOtherTeam"
                            onClick={() => {
                              setToggleAddToOtherTeam(true);
                            }}
                          >
                            Add to another team
                          </p>
                        ) : (
                          <select
                            className={`addUserTeamDropdown ${
                              !memoVals.mob ? "addUserTeamDropdownHover" : ""
                            }`}
                            defaultValue="Add to a team"
                            value="default-team-drop"
                            onChange={(e) => {
                              if (e.target.value !== "default-team-drop") {
                                let newArr = [...teams];

                                if (!newArr[0]) {
                                  setPrimaryTeamID(e.target.value);
                                }
                                newArr.push(e.target.value);

                                let teamlessID = availableTeams[0].teamID;

                                if (e.target.value === teamlessID) {
                                  newArr = [teamlessID];
                                } else {
                                  if (
                                    newArr.includes(teamlessID) &&
                                    e.target.value !== teamlessID
                                  ) {
                                    // remove teamlessID
                                    let arrWithoutTeamless = [];
                                    newArr.forEach((t) => {
                                      if (t !== teamlessID) {
                                        arrWithoutTeamless.push(t);
                                      }
                                    });

                                    newArr = arrWithoutTeamless;
                                  }
                                }
                                setTeams(newArr);
                                setToggleAddToOtherTeam(false);
                              }
                            }}
                          >
                            <option value="default-team-drop">
                              {teams[0] ? "Select team" : "Add to a team"}
                            </option>
                            {generateAvailableTeams}
                          </select>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  ))
                }

                {teams[0] && fName && lName && email ? (
                  <div className="gpsZoneRow">
                    <p className="lonLatTitles">Primary team</p>
                    <div className="modalExplainer">
                      <div className="modalExplainLeftBlob"></div>
                      <p className="modalExplainRightTxt">
                        {dateStringer.possession(fName)} requests may only be
                        approved by managers of their primary team
                      </p>
                    </div>
                    {/* <p className="adminAddUserExplainer">
                      {dateStringer.possession(fName)} requests may only be
                      approved by managers of their primary team
                    </p> */}
                    <div className={`lonLatInputDiv`}>
                      {" "}
                      <select
                        className={`addUserTeamDropdown ${
                          !memoVals.mob ? "addUserTeamDropdownHover" : ""
                        } pTeamDrop`}
                        defaultValue="Select primary team"
                        value={primaryTeamID}
                        onChange={(e) => {
                          if (e.target.value !== "default-team-drop") {
                            setPrimaryTeamID(e.target.value);
                          }
                        }}
                      >
                        {primaryTeamID ? (
                          ""
                        ) : (
                          <option value="default-team-drop">
                            Select primary team
                          </option>
                        )}
                        {teams.map((t) => {
                          let teamName = availableTeams.filter((item) => {
                            return item.teamID === t;
                          });
                          return (
                            <option value={t} key={t}>
                              {teamName[0].teamName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : page === 2 ? (
            <div className="dayCommentsModalBody addUserScroll" ref={scrollRef}>
              <p className="addUserPageInfo">
                Next, we need some information about{" "}
                {dateStringer.possession(fName)} hours and leave before today.
              </p>
              <div className="gpsZoneRow">
                <p className="addUserNum">Question 1 / 6</p>

                <p className="lonLatTitles adminAddUserLargerTitle">
                  How many{" "}
                  <span className="makeBold color00aaff">total hours</span>{" "}
                  between <br />
                  <span className="makeBold color00aaff">
                    {dateStringer.printedDateWithYrFromDWithoutDay(
                      refPeriodStartDs
                    )}
                  </span>{" "}
                  and <span className="makeBold color00aaff">today</span>{" "}
                  (excluding today) was {fName} paid for - including paid work,
                  paid leave and paid absence?
                  <br />
                </p>
                <p className="x2398911991">
                  This is for employees who regularly work overtime. By skipping
                  this question, FlowRota will be unable to accurately calculate{" "}
                  {dateStringer.possession(fName)} accrued overtime holiday pay
                  from their overtime worked before today.
                </p>
                <div className={`lonLatInputDiv historyMinsAddUserDiv`}>
                  {" "}
                  <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={totPaidHoursInRefPeriod}
                    onChange={(e) => {
                      setTotPaidHoursInRefPeriod(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">hours</p>
                  {/* <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={histMins}
                    onChange={(e) => {
                      setHistMins(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">minutes</p> */}
                </div>
              </div>

              <div className="gpsZoneRow">
                <p className="addUserNum">Question 2 / 6</p>

                <p className="lonLatTitles adminAddUserLargerTitle">
                  How many{" "}
                  <span className="makeBold color00aaff">days per week</span>{" "}
                  between <br />
                  <span className="makeBold color00aaff">
                    {dateStringer.printedDateWithYrFromDWithoutDay(
                      refPeriodStartDs
                    )}
                  </span>{" "}
                  and <span className="makeBold color00aaff">today</span>{" "}
                  (excluding today) was {fName} contracted to work?
                </p>
                <p className="x2398911991">
                  If this number changed within the period, please provide an
                  average figure.
                  {/* If this number changed within the period, use{" "}
                  <span
                    className="avgTool"
                    onClick={() => {
                      setShowAvgTool({
                        show: true,
                        startDs: refPeriodStartDs,
                        days: true,
                      });
                    }}
                  >
                    this tool
                  </span>{" "} */}
                  {/* to calculate an average. */}
                </p>
                <div className={`lonLatInputDiv historyMinsAddUserDiv`}>
                  {" "}
                  <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={avgHistoricalClosedDaysNum}
                    onChange={(e) => {
                      setAvgHistoricalClosedDaysNum(e.target.value);
                    }}
                    min={0}
                    max={7}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">days per week</p>
                </div>
              </div>
              <div className="gpsZoneRow">
                <p className="addUserNum">Question 3 / 6</p>

                <p className="lonLatTitles adminAddUserLargerTitle">
                  How many{" "}
                  <span className="makeBold color00aaff">hours per week</span>{" "}
                  between <br />
                  <span className="makeBold color00aaff">
                    {dateStringer.createTimestampFromString(
                      dateStringer.createStringFromDateInput(startDateInput)
                    ) >= dateStringer.createTimestampFromString(currYearStart)
                      ? dateStringer.printedDateWithYrFromDWithoutDay(
                          dateStringer.createStringFromDateInput(startDateInput)
                        )
                      : dateStringer.printedDateWithYrFromDWithoutDay(
                          currYearStart
                        )}
                  </span>{" "}
                  and <span className="makeBold color00aaff">today</span>{" "}
                  (excluding today) was {fName} contracted to work?
                  <br />
                </p>
                <p className="x2398911991">
                  If this number changed within the period, please provide an
                  average figure.
                  {/* If this number changed within the period, use{" "}
                  <span
                    className="avgTool"
                    onClick={() => {
                      setShowAvgTool({
                        show: true,
                        startDs: refPeriodStartDs,
                        hours: true,
                      });
                    }}
                  >
                    this tool
                  </span>{" "}
                  to calculate an average. */}
                </p>
                <div className={`lonLatInputDiv historyMinsAddUserDiv`}>
                  {" "}
                  <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={histHours}
                    onChange={(e) => {
                      setHistHours(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">hours</p>
                  <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={histMins}
                    onChange={(e) => {
                      setHistMins(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">minutes</p>
                </div>
              </div>
              <div className="gpsZoneRow">
                <p className="addUserNum">Question 4 / 6</p>

                <p className="lonLatTitles adminAddUserLargerTitle">
                  How many{" "}
                  <span className="makeBold color00aaff">Annual Leave</span>{" "}
                  hours has {fName} taken between{" "}
                  <span className="makeBold color00aaff">
                    {dateStringer.createTimestampFromString(
                      dateStringer.createStringFromDateInput(startDateInput)
                    ) >= dateStringer.createTimestampFromString(currYearStart)
                      ? dateStringer.printedDateWithYrFromDWithoutDay(
                          dateStringer.createStringFromDateInput(startDateInput)
                        )
                      : dateStringer.printedDateWithYrFromDWithoutDay(
                          currYearStart
                        )}
                  </span>{" "}
                  and <span className="makeBold color00aaff">today</span>{" "}
                  (excluding today)?
                  {/* <br /> */}
                  {/* <br /> */}
                  {/* Exclude any leave booked for today onwards. */}
                </p>

                <div className={`lonLatInputDiv historyMinsAddUserDiv`}>
                  {" "}
                  <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={histAnnLeaveHours}
                    onChange={(e) => {
                      setHistAnnLeaveHours(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">hours</p>
                  <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={histAnnLeaveMins}
                    onChange={(e) => {
                      setHistAnnLeaveMins(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">minutes</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="dayCommentsModalBody addUserScroll" ref={scrollRef}>
              <p className="addUserPageInfo">
                FlowRota needs to know if {fName} incurred any absence before
                today.
              </p>
              <div className="gpsZoneRow">
                <p className="addUserNum">Question 5 / 6</p>

                <p className="lonLatTitles adminAddUserLargerTitle">
                  How many{" "}
                  <span className="makeBold color00aaff">paid absent</span>{" "}
                  hours has {fName} had between{" "}
                  <span className="makeBold color00aaff">
                    {dateStringer.createTimestampFromString(
                      dateStringer.createStringFromDateInput(startDateInput)
                    ) >= dateStringer.createTimestampFromString(currYearStart)
                      ? dateStringer.printedDateWithYrFromDWithoutDay(
                          dateStringer.createStringFromDateInput(startDateInput)
                        )
                      : dateStringer.printedDateWithYrFromDWithoutDay(
                          currYearStart
                        )}
                  </span>{" "}
                  and <span className="makeBold color00aaff">today</span>{" "}
                  (excluding today)?
                </p>

                <div className={`lonLatInputDiv historyMinsAddUserDiv`}>
                  {" "}
                  <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={paidAbsHours}
                    onChange={(e) => {
                      setPaidAbsHours(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">hours</p>
                  <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={paidAbsMins}
                    onChange={(e) => {
                      setPaidAbsMins(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">minutes</p>
                </div>
              </div>

              <div className="gpsZoneRow">
                <p className="addUserNum">Question 6 / 6</p>

                <p className="lonLatTitles adminAddUserLargerTitle">
                  Finally, how many{" "}
                  <span className="makeBold color00aaff">unpaid absent</span>{" "}
                  hours has {fName} had between{" "}
                  <span className="makeBold color00aaff">
                    {dateStringer.createTimestampFromString(
                      dateStringer.createStringFromDateInput(startDateInput)
                    ) >= dateStringer.createTimestampFromString(currYearStart)
                      ? dateStringer.printedDateWithYrFromDWithoutDay(
                          dateStringer.createStringFromDateInput(startDateInput)
                        )
                      : dateStringer.printedDateWithYrFromDWithoutDay(
                          currYearStart
                        )}
                  </span>{" "}
                  and <span className="makeBold color00aaff">today</span>{" "}
                  (excluding today)?
                </p>

                <div className={`lonLatInputDiv historyMinsAddUserDiv`}>
                  {" "}
                  <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={unpaidAbsHours}
                    onChange={(e) => {
                      setUnpaidAbsHours(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">hours</p>
                  <input
                    type="number"
                    className={`addUserTextInput addUserHistNumInput`}
                    value={unpaidAbsMins}
                    onChange={(e) => {
                      setUnpaidAbsMins(e.target.value);
                    }}
                  ></input>{" "}
                  <p className="addUserHistHoursTxt">minutes</p>
                </div>
              </div>
            </div>
          )}
          <div className="dayCommentsModalFooter">
            <div className="viewMapsAndSaveDiv">
              {page === 2 || page === 3 ? (
                <div
                  className={`addUserBackBtn ${
                    memoVals.mob ? "" : "addUserBackBtnDesktop"
                  }`}
                  onClick={() => {
                    if (page === 3) {
                      setPage(2);
                    }

                    if (page === 2) {
                      setPage(1);
                    }
                  }}
                >
                  {" "}
                  <img src={whiteBack} alt="Back" className="addUserBackImg" />
                </div>
              ) : (
                ""
              )}
              <p
                className={`saveGpsZoneBtn ${
                  !hasHistory || page === 3 ? "adminAddUserSubmitColours" : ""
                } ${
                  (!inputsValid && !bulk) || (bulk && !multiUsers[0])
                    ? "invalidAddUser"
                    : ""
                }`}
                onClick={() => {
                  // add user here
                  if (bulk) {
                    if (!multiUsersValid && errorArr[0]) {
                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "Not all fields are filled in correctly",
                          colour: "red",
                          duration: 4000,
                        };
                      });
                      setShowRed(true);
                    } else {
                      // bulk-add users here with axios
                      let today00 = new Date();
                      today00.setHours(0, 0, 0, 0);
                      axios
                        .post(
                          `${serverURL}/add-user`,
                          {
                            todayDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                            bulk,
                            bulkArr: multiUsers,
                            refPeriodStartDs: originalRefPeriodStartDs,
                            refPeriodEndDs:
                              dateStringer.createStringFromTimestamp(
                                today00.getTime()
                              ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (
                            response.data.message === "bad data" &&
                            response.data.errorArr &&
                            Array.isArray(response.data.errorArr)
                          ) {
                            setErrorArr(response.data.errorArr);
                            setShowRed(true);
                          }
                          if (response.data.message === "emails exist") {
                            let dupes = response.data.duplicateEmails;
                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: `${
                                  dupes.length === 1 ? "Email" : "Emails"
                                } already in use`,
                                colour: "red",
                                duration: 4000,
                              };
                            });

                            let newArr = [];
                            dupes.forEach((email) => {
                              let multiRowFilt = multiUsers.filter((x) => {
                                return x.email === email;
                              });
                              if (multiRowFilt[0]) {
                                newArr.push({
                                  key: multiRowFilt[0].key,
                                  email: false,
                                  fName: true,
                                  lName: true,
                                  startDateInput: true,
                                  teams: ["1"],
                                  primaryTeamID: "1",
                                  jobTitle: "1",
                                });
                              }
                            });
                            if (newArr[0]) {
                              setErrorArr(newArr);
                              setShowRed(true);
                            }
                          }

                          if (
                            response.data.message === "success" &&
                            response.data.userIDs
                          ) {
                            const result = response.data.userIDs.join("_");
                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: `Added ${multiUsers.length} employee${
                                  multiUsers.length === 1 ? "" : "s"
                                }`,
                                colour: "green",
                                duration: 4000,
                              };
                            });
                            closeModal();

                            setTimeout(() => {
                              window.location.href = `${appURL}/admin/employees?multi=true&userIDs=${result}`;
                            }, 2000);
                          }
                        });
                    }
                  } else {
                    if (inputsValid) {
                      if (hasHistory && page !== 3) {
                        // go to page 2
                        if (page === 1) {
                          setPage(2);
                        }
                        if (page === 2) {
                          setPage(3);
                        }
                      } else {
                        // add user axios here

                        let teamlessID = "";
                        let teamlessFilt = availableTeams.filter((item) => {
                          return item.teamName === "Teamless";
                        });

                        if (teamlessFilt[0]) {
                          teamlessID = teamlessFilt[0].teamID;
                        }
                        let today00 = new Date();
                        today00.setHours(0, 0, 0, 0);
                        today00.setDate(today00.getDate() - 1);
                        axios
                          .post(
                            `${serverURL}/add-user`,
                            {
                              todayDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                              fName,
                              lName,
                              email,
                              startDate:
                                dateStringer.createStringFromDateInput(
                                  startDateInput
                                ),
                              hasHistory,
                              teams: teams[0] ? teams : [teamlessID],
                              primaryTeamID,
                              historyMpw: parseInt(histHours * 60 + histMins),
                              historyAnnLeaveMins: parseInt(
                                histAnnLeaveHours * 60 + histAnnLeaveMins
                              ),
                              historyPaidAbsenceMins: parseInt(
                                paidAbsHours * 60 + paidAbsMins
                              ),
                              historyUnpaidAbsenceMins: parseInt(
                                unpaidAbsHours * 60 + unpaidAbsMins
                              ),
                              avgHistoricalClosedDaysNum,
                              totPaidHoursInRefPeriod,
                              refPeriodStartDs,
                              refPeriodEndDs:
                                dateStringer.createStringFromTimestamp(
                                  today00.getTime()
                                ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (
                              response.data.message.includes("already in use")
                            ) {
                              setEmailAlreadyInUse(true);

                              memoVals.setIndicate((x) => {
                                return {
                                  show: true,
                                  message: "Email already in use",
                                  colour: "red",
                                  duration: 4000,
                                };
                              });
                            }
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              memoVals.setShowEditUser(
                                (x) => response.data.userID
                              );
                              memoVals.setShowAddUserModal((x) => false);

                              // add user to parent users
                              memoVals.setAddNewUserDynamic((x) => {
                                return {
                                  fName: response.data.fName,
                                  lName: response.data.lName,
                                  timelineIssues: response.data.timelineIssues,
                                  userID: response.data.userID,
                                  jobTitle: response.data.jobTitle,
                                  profPicUrl: response.data.profPicUrl,
                                  teamName: response.data.teamName,
                                  otherTeams: response.data.otherTeams,
                                  startDate: response.data.startDate,
                                  startTs: response.data.startTs,
                                  remainingAnnLeave:
                                    response.data.remainingAnnLeave,
                                  userDailyMins: response.data.userDailyMins,
                                  removed: response.data.removed,
                                  removedDs: response.data.removedDs,
                                };
                              });
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }
                  }
                }}
              >
                {bulk
                  ? "Submit"
                  : hasHistory && (page === 1 || page === 2)
                  ? "Next page"
                  : page === 3
                  ? "Finish"
                  : "Add employee"}
              </p>
              {page === 1 && (
                <p
                  className={`addMultiUserBtn ${loading ? "invis" : ""}`}
                  onClick={() => {
                    if (window.innerWidth < 820 && !bulk) {
                      setShowBulkMob(true);
                    } else {
                      setBulk(!bulk);
                      setRefPeriodStartDs(originalRefPeriodStartDs);
                    }
                  }}
                >
                  {" "}
                  <img
                    src={bulk ? addUserSingle : addUserGroup}
                    alt="Bulk mode"
                    className={`addUserSingleImg ${
                      bulk ? "addUserSingleImgSingle" : ""
                    }`}
                  />
                  {bulk ? "Single mode" : "Bulk add"}
                </p>
              )}
            </div>

            <p
              className="closeReqInfo"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {showBulkMob && !checklistRequired && !loading ? (
        <div
          className="editUserUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setShowBulkMob(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              To add employees in bulk, please do this on a desktop computer.
            </p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setShowBulkMob(false);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {checklistRequired && !loading ? (
        <div
          className="editUserUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              You need to complete the initial setup checklist before you can
              add users to FlowRota.
            </p>
            <p
              className="tempSavedCloseBtn pinkCta"
              onClick={() => {
                memoVals.setShowChecklist((x) => true);
                closeModal();
              }}
            >
              Open checklist{" "}
              <img src={popup} alt="Checklist" className="checklistpopupimg" />
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        ""
      )}
      {/* {showAvgTool.show && (
        <div
          className="clockOnModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();

            setShowAvgTool({ show: false });
          }}
        >
          <div className="availModalBox">Avg tool</div>
        </div>
      )} */}
      {imgPreload}
    </div>
  );
};

export default AddUserModal;
