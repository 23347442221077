import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wed 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";

import axios from "axios";
import serverURL from "../serverURL";
import { NavLink } from "react-router-dom";

import chat from "../img/general/chat.svg";
import close from "../img/general/navyClose.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

const UserSchedulePopUp = ({ openedFromUserRota, openedFromMyAAccount }) => {
  const { mobModal } = useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);
  const {
    setSeeUserID,
    seeUserID,
    setShowMessages,
    showMessages,
    modalOpen,
    setModalOpen,
    device,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setSeeUserID,
      seeUserID,
      setShowMessages,
      showMessages,
      checkUserState,
      mobModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setSeeUserID, //
      seeUserID, //
      setShowMessages, //
      showMessages, //
      checkUserState, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [seeUserIDData, setSeeUserIDData] = useState({ teamNamesArr: [] });

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (memoVals.seeUserID && memoVals.seeUserID.userID) {
      axios
        .post(
          `${serverURL}/get-user-scheduled-working-days`,
          {
            userID: memoVals.seeUserID.userID,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setSeeUserIDData(response.data.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.seeUserID]);

  let generateTeams =
    seeUserIDData.teamNamesArr &&
    seeUserIDData.teamNamesArr.map((team) => {
      return <div className="seeUserIDTeam">{team}</div>;
    });

  let generateMgrOfTeams =
    seeUserIDData.mgrOfNamesArr &&
    seeUserIDData.mgrOfNamesArr.map((team) => {
      return <div className="seeUserIDTeam">{team}</div>;
    });

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setSeeUserID((x) => "");
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let closeModal = () => {
    memoVals.setSeeUserID((x) => {
      return { userID: "", openedFromUserRota: false };
    });
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return

  return (
    <div
      className={`clockOnModalUnderlay ${memoVals.showMessages ? "none" : ""}`}
      onClick={() => {
        closeModal();
      }}
    >
      {seeUserIDData.workingPattern ? (
        <div
          className={`seeUserNonWorkingDaysModal ${
            memoVals.mobModal
              ? `seeUserModalMob mobModalShoulder x2f34222 ${
                  memoVals.device.ios ? "seeUserModalMobIos" : ""
                }`
              : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {" "}
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperUserWorkPat"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className={`clockOnModalHeader x239898292 x2398911d ${
              !memoVals.mobModal ? "xx283819812" : ""
            }`}
            onClick={() => {
              if (memoVals.mobModal) {
                closeModal();
              }
            }}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <div className="clockOnModalHeaderSideUnit">
              <img
                src={close}
                alt="Close"
                className="closeClockOnModalImg"
                onClick={() => {
                  closeModal();
                }}
              />
            </div>
            <p className="clockOnModalHeaderTitle" onClick={() => {}}>
              {seeUserIDData && seeUserIDData.fName ? (
                <p className="seeUserNameTitle">
                  {seeUserIDData.fName}
                  {seeUserIDData.fName[seeUserIDData.fName.length - 1] === "s"
                    ? "'"
                    : "'s"}{" "}
                  weekly{" "}
                  {seeUserIDData.workingPattern === "fixedScheduled"
                    ? "schedule"
                    : "pattern"}
                </p>
              ) : (
                ""
              )}
            </p>
            {/* <div className="clockOnModalHeaderSideUnit"></div> */}
            {/* <div className={`clockTicker ${durString ? "" : "clockTickerInvis"}`}>
            {durString}
          </div> */}
          </div>
          {/* <div className="userSchedPopUpHeader">
            {seeUserIDData && seeUserIDData.fName ? (
              <p className="seeUserNameTitle">
                {seeUserIDData.fName}
                {seeUserIDData.fName[seeUserIDData.fName.length - 1] === "s"
                  ? "'"
                  : "'s"}{" "}
                weekly{" "}
                {seeUserIDData.workingPattern === "fixedScheduled"
                  ? "schedule"
                  : "pattern"}
              </p>
            ) : (
              ""
            )}
          </div> */}
          <div
            className={`seeUserSchedTable ${
              memoVals.mobModal ? "seeUserSchedTableMob" : ""
            }`}
          >
            <p className="userSchedTeamsTitle">
              {dateStringer.possession(seeUserIDData.fName)} primary team
            </p>
            <div className="seeUserIDTeamsDiv">
              <div className="seeUserIDTeam">{seeUserIDData.pTeamName}</div>
            </div>
            <p className="userSchedTeamsTitle">
              Teams {seeUserIDData.fName} is part of
            </p>
            <div className="seeUserIDTeamsDiv">{generateTeams}</div>
            {seeUserIDData.mgrOfNamesArr && seeUserIDData.mgrOfNamesArr[0] ? (
              <p className="userSchedTeamsTitle">
                Teams {seeUserIDData.fName} manages
              </p>
            ) : (
              ""
            )}
            {seeUserIDData.mgrOfNamesArr && seeUserIDData.mgrOfNamesArr[0] ? (
              <div className="seeUserIDTeamsDiv">{generateMgrOfTeams}</div>
            ) : (
              ""
            )}

            <div className="seeUserRow noBorder">
              <div className="seeUserRowTitle ">Working pattern</div>
              <div
                className="seeUserRowValue"
                onClick={() => {
                  console.log({ seeUserIDData });
                }}
              >
                {seeUserIDData.workingPattern === "fixedScheduled"
                  ? "Fixed shifts"
                  : seeUserIDData.workingPattern === "fixedVariable"
                  ? "Varied shifts"
                  : "Zero-hours"}
              </div>
            </div>
            {seeUserIDData.workingPattern !== "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Monday{" "}
                  {seeUserIDData.closedDays.includes(1) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.nonWorkingDays.includes(1) ? (
                  <div className="seeUserRowValue">Non-working day</div>
                ) : (
                  <div className="seeUserRowValue">Workable</div>
                )}
              </div>
            ) : (
              ""
            )}
            {seeUserIDData.workingPattern !== "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Tuesday{" "}
                  {seeUserIDData.closedDays.includes(2) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.nonWorkingDays.includes(2) ? (
                  <div className="seeUserRowValue">Non-working day</div>
                ) : (
                  <div className="seeUserRowValue">Workable</div>
                )}
              </div>
            ) : (
              ""
            )}

            {seeUserIDData.workingPattern !== "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Wednesday{" "}
                  {seeUserIDData.closedDays.includes(3) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.nonWorkingDays.includes(3) ? (
                  <div className="seeUserRowValue">Non-working day</div>
                ) : (
                  <div className="seeUserRowValue">Workable</div>
                )}
              </div>
            ) : (
              ""
            )}

            {seeUserIDData.workingPattern !== "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Thursday{" "}
                  {seeUserIDData.closedDays.includes(4) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.nonWorkingDays.includes(4) ? (
                  <div className="seeUserRowValue">Non-working day</div>
                ) : (
                  <div className="seeUserRowValue">Workable</div>
                )}
              </div>
            ) : (
              ""
            )}

            {seeUserIDData.workingPattern !== "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Friday{" "}
                  {seeUserIDData.closedDays.includes(5) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.nonWorkingDays.includes(5) ? (
                  <div className="seeUserRowValue">Non-working day</div>
                ) : (
                  <div className="seeUserRowValue">Workable</div>
                )}
              </div>
            ) : (
              ""
            )}

            {seeUserIDData.workingPattern !== "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Saturday{" "}
                  {seeUserIDData.closedDays.includes(6) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.nonWorkingDays.includes(6) ? (
                  <div className="seeUserRowValue">Non-working day</div>
                ) : (
                  <div className="seeUserRowValue">Workable</div>
                )}
              </div>
            ) : (
              ""
            )}

            {seeUserIDData.workingPattern !== "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Sunday{" "}
                  {seeUserIDData.closedDays.includes(0) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.nonWorkingDays.includes(0) ? (
                  <div className="seeUserRowValue">Non-working day</div>
                ) : (
                  <div className="seeUserRowValue">Workable</div>
                )}
              </div>
            ) : (
              ""
            )}

            {seeUserIDData.workingPattern === "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Monday
                  {seeUserIDData.closedDays.includes(1) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.monStart ? (
                  <div className="seeUserRowValue">
                    {seeUserIDData.monStart} - {seeUserIDData.monEnd}
                  </div>
                ) : (
                  <div className="seeUserRowValue">Non-working day</div>
                )}
              </div>
            ) : (
              ""
            )}
            {seeUserIDData.workingPattern === "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Tuesday{" "}
                  {seeUserIDData.closedDays.includes(2) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.tueStart ? (
                  <div className="seeUserRowValue">
                    {seeUserIDData.tueStart} - {seeUserIDData.tueEnd}
                  </div>
                ) : (
                  <div className="seeUserRowValue">Non-working day</div>
                )}
              </div>
            ) : (
              ""
            )}

            {seeUserIDData.workingPattern === "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Wednesday{" "}
                  {seeUserIDData.closedDays.includes(3) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.wedStart ? (
                  <div className="seeUserRowValue">
                    {seeUserIDData.wedStart} - {seeUserIDData.wedEnd}
                  </div>
                ) : (
                  <div className="seeUserRowValue">Non-working day</div>
                )}
              </div>
            ) : (
              ""
            )}

            {seeUserIDData.workingPattern === "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Thursday{" "}
                  {seeUserIDData.closedDays.includes(4) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.thuStart ? (
                  <div className="seeUserRowValue">
                    {seeUserIDData.thuStart} - {seeUserIDData.thuEnd}
                  </div>
                ) : (
                  <div className="seeUserRowValue">Non-working day</div>
                )}
              </div>
            ) : (
              ""
            )}

            {seeUserIDData.workingPattern === "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Friday{" "}
                  {seeUserIDData.closedDays.includes(5) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.friStart ? (
                  <div className="seeUserRowValue">
                    {seeUserIDData.friStart} - {seeUserIDData.friEnd}
                  </div>
                ) : (
                  <div className="seeUserRowValue">Non-working day</div>
                )}
              </div>
            ) : (
              ""
            )}

            {seeUserIDData.workingPattern === "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Saturday{" "}
                  {seeUserIDData.closedDays.includes(6) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.satStart ? (
                  <div className="seeUserRowValue">
                    {seeUserIDData.satStart} - {seeUserIDData.satEnd}
                  </div>
                ) : (
                  <div className="seeUserRowValue">Non-working day</div>
                )}
              </div>
            ) : (
              ""
            )}

            {seeUserIDData.workingPattern === "fixedScheduled" ? (
              <div className="seeUserRow">
                <div className="seeUserRowTitle">
                  Sunday{" "}
                  {seeUserIDData.closedDays.includes(0) ? (
                    <span className="nwDayClosedSpan">Closed</span>
                  ) : (
                    ""
                  )}
                </div>
                {seeUserIDData.sunStart ? (
                  <div className="seeUserRowValue">
                    {seeUserIDData.sunStart} - {seeUserIDData.sunEnd}
                  </div>
                ) : (
                  <div className="seeUserRowValue">Non-working day</div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="userSchedPopUpFooter">
            {memoVals.showMessages.userID !== memoVals.seeUserID.userID ? (
              // <div
              //   className="messageUserFromRotaBtn"
              //   onClick={() => {
              //     memoVals.setShowMessages((x) => memoVals.seeUserID.userID);
              //   }}
              // >
              //   <img src={chat} alt="Message" className="msgImgUserSchedImg" />{" "}
              //   Message
              // </div>
              <p></p>
            ) : openedFromMyAAccount ? (
              <p></p>
            ) : (
              <NavLink
                to="/account"
                onClick={() => {
                  setSeeUserIDData({});
                  memoVals.setSeeUserID((x) => false);
                }}
              >
                <div className="messageUserFromRotaBtn">My profile</div>
              </NavLink>
            )}

            <p
              className="closeSeeUserIDBtn"
              onClick={() => {
                setSeeUserIDData({});
                memoVals.setSeeUserID((x) => false);
              }}
            >
              Close
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserSchedulePopUp;
