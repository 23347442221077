import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";
import { AdminContext } from "../../../contexts/AdminContext";
import dateStringer from "../../../tools/dateStringer";
import CheckUser from "../../../tools/CheckUser";
import serverURL from "../../../serverURL";

import navyClose from "../../../img/general/navyClose.svg";

const EditUserHistoryModal = ({
  userID,
  historicHoursData,
  setHistoricHoursData,
  setHistoryAnnLeaveMins,
  setHistoryPaidAbsenceMins,
  setHistoryUnpaidAbsenceMins,
  historicMpw,
  setHistoricMpw,
  fName,
  setShowHistory,
  startDate,
  historyEndDs,
  historyStartDs,
  setHistoryEndDs,
  setHistoryStartDs,
}) => {
  const { setShowClosedDaysHistory, modalOpen, setModalOpen, setIndicate } =
    useContext(DataContext);

  const { mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setShowClosedDaysHistory,
      mobModal,
      modalOpen,
      setModalOpen,
      setIndicate,
    }),
    [
      setShowClosedDaysHistory, //
      mobModal, //
      modalOpen,
      setModalOpen,
      setIndicate,
    ]
  );

  let [hasHistory, setHasHistory] = useState(false);
  let [loading, setLoading] = useState(true);
  let closeModal = () => {
    setShowHistory(false);
  };

  let [dpw, setDpw] = useState(0);
  let [historyMpwHours, setHistoryMpwHours] = useState(0);
  let [historyMpwMins, setHistoryMpwMins] = useState(0);
  let [daysPerWeekWorked, setDaysPerWeekWorked] = useState(0);
  let [totalHoursPaidFor, setTotalHoursPaidFor] = useState(0);
  let [annLeaveHours, setAnnLeaveHours] = useState(0);
  let [annLeaveMins, setAnnLeaveMins] = useState(0);
  let [paidAbsHours, setPaidAbsHours] = useState(0);
  let [paidAbsMins, setPaidAbsMins] = useState(0);
  let [unpaidAbsHours, setUnpaidAbsHours] = useState(0);
  let [unpaidAbsMins, setUnpaidAbsMins] = useState(0);
  let [cappedAtYearStart, setCappedAtYearStart] = useState(false);
  let [companyName, setCompanyName] = useState("");
  //   let [joinedDs, setJoinedDs] = useState("");
  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [update, setUpdate] = useState(false);
  const cancelSource1 = axios.CancelToken.source();

  function convertMinsToHoursMins(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const mins = totalMinutes % 60;
    return { hours: hours, mins: mins };
  }

  useEffect(() => {
    axios
      .post(
        `${serverURL}/get-edit-user-history-data`,
        { userID },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          if (response.data.historicMpw) {
            setHistoryMpwHours(
              convertMinsToHoursMins(response.data.historicMpw).hours || 0
            );
            setHistoryMpwMins(
              convertMinsToHoursMins(response.data.historicMpw).mins || 0
            );
          }
        }
        setDpw(response.data.daysPerWeekWorked || 0);
        setDaysPerWeekWorked(response.data.daysPerWeekWorked || 0);
        if (response.data.historicHoursData[0]) {
          setTotalHoursPaidFor(
            parseInt(response.data.historicHoursData[0].totHoursPaid || 0)
          );
        }

        setAnnLeaveHours(
          convertMinsToHoursMins(response.data.historyAnnLeaveMins).hours || 0
        );
        setAnnLeaveMins(
          convertMinsToHoursMins(response.data.historyAnnLeaveMins).mins || 0
        );

        setPaidAbsHours(
          convertMinsToHoursMins(response.data.historyPaidAbsenceMins).hours ||
            0
        );
        setPaidAbsMins(
          convertMinsToHoursMins(response.data.historyPaidAbsenceMins).mins || 0
        );

        setUnpaidAbsHours(
          convertMinsToHoursMins(response.data.historyUnpaidAbsenceMins || 0)
            .hours || 0
        );
        setUnpaidAbsMins(
          convertMinsToHoursMins(response.data.historyUnpaidAbsenceMins).mins ||
            0
        );

        setHistoricHoursData(response.data.historicHoursData || []);
        setHistoryAnnLeaveMins(response.data.historyAnnLeaveMins || 0);
        setHistoryPaidAbsenceMins(response.data.historyPaidAbsenceMins || 0);
        setHistoryUnpaidAbsenceMins(
          response.data.historyUnpaidAbsenceMins || 0
        );
        setHistoryEndDs(response.data.historyEndDs);
        let histObj = new Date(
          dateStringer.createTimestampFromString(response.data.historyEndDs)
        );
        // histObj.setDate(histObj.getDate() + 1);
        // histObj.setHours(0, 0, 0, 0);
        // setJoinedDs(histObj.getTime());
        setHistoryStartDs(response.data.historyStartDs);
        setHistoricMpw(response.data.historicMpw);
        setHasHistory(response.data.hasHistory);
        setDpw(response.data.daysPerWeekWorked);
        setCompanyName(response.data.companyName);
        setCappedAtYearStart(response.data.cappedAtYearStart);
        setLoading(false);

        //   set;
        // }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [update]);

  let [saveA, setSaveA] = useState(false);
  let [saveB, setSaveB] = useState(false);
  let [saveC, setSaveC] = useState(false);
  let [saveD, setSaveD] = useState(false);
  let [saveE, setSaveE] = useState(false);
  let [saveF, setSaveF] = useState(false);

  let [savedA, setSavedA] = useState(false);
  let [savedB, setSavedB] = useState(false);
  let [savedC, setSavedC] = useState(false);
  let [savedD, setSavedD] = useState(false);
  let [savedE, setSavedE] = useState(false);
  let [savedF, setSavedF] = useState(false);
  let updateItem = (item) => {
    if (
      item !== 1 &&
      item !== 2 &&
      item !== 3 &&
      item !== 4 &&
      item !== 5 &&
      item !== 6
    ) {
      return;
    }
    let data;
    if (item === 1) {
      data = {
        mpw: parseInt(historyMpwHours * 60) + parseInt(historyMpwMins),
        historyStartDs,
        historyEndDs,
      };
    }

    if (item === 2) {
      data = {
        dpw: parseInt(dpw),
        historyStartDs,
        historyEndDs,
      };
    }
    if (item === 3) {
      data = {
        historyEndDs,
        historyStartDs,
        totalHoursPaidFor: parseInt(totalHoursPaidFor),
      };
    }
    if (item === 4) {
      data = {
        // historyEndDs,
        // historyStartDs,
        annLeaveMins: parseInt(annLeaveHours * 60) + parseInt(annLeaveMins),
      };
    }

    if (item === 5) {
      data = {
        // historyEndDs,
        // historyStartDs,
        paidAbsMins: parseInt(paidAbsHours * 60) + parseInt(paidAbsMins),
      };
    }

    if (item === 6) {
      data = {
        historyEndDs,
        // historyStartDs,
        unpaidAbsMins: parseInt(unpaidAbsHours * 60) + parseInt(unpaidAbsMins),
      };
    }
    axios
      .post(
        `${serverURL}/update-user-history-item`,
        { userID, data, item },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setUpdate(!update);
          if (item === 1) {
            setSaveA(false);
            setSavedA(true);
            memoVals.setIndicate((x) => {
              return {
                show: true,
                message: "Historical hours per week updated",
                colour: "green",
                duration: 4000,
              };
            });
            setTimeout(() => {
              setSavedA(false);
            }, 3000);
          }
          if (item === 2) {
            setSaveB(false);
            setSavedB(true);
            memoVals.setIndicate((x) => {
              return {
                show: true,
                message: "Historical days per week updated",
                colour: "green",
                duration: 4000,
              };
            });
            setTimeout(() => {
              setSavedB(false);
            }, 3000);
          }
          if (item === 3) {
            setSaveC(false);
            setSavedC(true);
            memoVals.setIndicate((x) => {
              return {
                show: true,
                message: "Historical total paid hours updated",
                colour: "green",
                duration: 4000,
              };
            });
            setTimeout(() => {
              setSavedC(false);
            }, 3000);
          }
          if (item === 4) {
            setSaveD(false);
            setSavedD(true);
            memoVals.setIndicate((x) => {
              return {
                show: true,
                message: "Historical annual leave taken updated",
                colour: "green",
                duration: 4000,
              };
            });
            setTimeout(() => {
              setSavedD(false);
            }, 3000);
          }

          if (item === 5) {
            setSaveE(false);
            setSavedE(true);
            memoVals.setIndicate((x) => {
              return {
                show: true,
                message: "Historical paid absence updated",
                colour: "green",
                duration: 4000,
              };
            });
            setTimeout(() => {
              setSavedE(false);
            }, 3000);
          }

          if (item === 6) {
            setSaveF(false);
            setSavedF(true);
            memoVals.setIndicate((x) => {
              return {
                show: true,
                message: "Historical unpaid absence updated",
                colour: "green",
                duration: 4000,
              };
            });
            setTimeout(() => {
              setSavedF(false);
            }, 3000);
          }
        }
      });
  };

  // master return
  return (
    <div
      className="bulkEditLimitUnderlay"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div
        className={`availModalBox ${
          memoVals.mobModal
            ? "closedDaysTimelineModalBoxMob mobModalShoulder mobHistModal"
            : "histModalDesktop"
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {memoVals.mobModal && (
          <div className="modalSwiper closeDaysSwiper"></div>
        )}
        <div
          className={`closedDaysHistoryModalHeader`}
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={navyClose}
            alt="Close"
            className="closeBulkEditLimitModalIcon"
            onClick={() => {
              closeModal();
            }}
          />
          <p
            className={`bulkEditLimitModalHeaderTitle ${
              userID ? "fontSize16" : ""
            }`}
          >
            {`${dateStringer.possession(fName)} historic data`}
          </p>
          {!userID ? (
            <img
              src={navyClose}
              alt="Close"
              className="closeBulkEditLimitModalIcon bulkEditCloseBlank"
            />
          ) : (
            ""
          )}
        </div>
        <div
          className="bulkEditTextDiv noBorder noFlex histTxtSec"
          onClick={() => {}}
        >
          <p className={`historicalClosedDaysTxt ${loading ? "invis" : ""}`}>
            There is a historical gap between{" "}
            {cappedAtYearStart
              ? "the start of the business year "
              : `${dateStringer.possession(
                  fName
                )} start date at ${companyName} `}
            and when they joined FlowRota -{" "}
            <span className="makeBold">
              {dateStringer.printedDateWithYrFromDs(startDate)}
            </span>
            .{" "}
          </p>
          {/* <br /> */}
          {/* <br /> */}
          <p className="historicalClosedDaysTxt">
            Without the historical information displayed below,{" "}
            {dateStringer.possession(`${fName}`)} tracked allowances and absence
            won't be accurate for their first year of usage of the app.{" "}
          </p>
        </div>

        <div
          className={`closedDaysTimelineMainBody ${
            memoVals.mobModal ? "mobHistModalBody" : "desktopHistModalBody"
          }`}
        >
          {!hasHistory && !loading && (
            <p className="noHistAvail">No history data</p>
          )}
          {hasHistory && (
            <div className="beforeCheckInputs">
              {/* 3.1 */}
              <div className={`checklistSubRow noBorder`}>
                <div className="checklistSubRowLeft">
                  <div className="checklist3subTitle">
                    <p className="check3subNum">a</p>
                    <p className={`sub3atitleSpan`}>
                      How many hours per week was {fName} contracted to work
                      between{" "}
                      <span className="lightBlue">
                        {dateStringer.printedDateWithYrFromDWithoutDay(
                          historyStartDs
                        )}
                      </span>{" "}
                      and{" "}
                      <span className="lightBlue">
                        {" "}
                        {dateStringer.printedDateWithYrFromDWithoutDay(
                          historyEndDs
                        )}
                      </span>
                      ?
                    </p>
                  </div>
                  <p className="check3Ainfo check3AinfoEditUser">
                    Provide an average if this varied
                  </p>
                  <div className="hrMinPerWeekDiv x1098101">
                    <input
                      type="number"
                      className={`hrMinInputter ${
                        savedA ? "savedInputter" : ""
                      }`}
                      value={historyMpwHours}
                      onChange={(e) => {
                        setHistoryMpwHours(e.target.value);
                        if (!saveA) {
                          setSaveA(true);
                        }
                      }}
                    ></input>
                    <p className="preHpwValTxt">h</p>
                    <input
                      type="number"
                      className={`hrMinInputter ${
                        savedA ? "savedInputter" : ""
                      }`}
                      value={historyMpwMins}
                      onChange={(e) => {
                        setHistoryMpwMins(e.target.value);
                        if (!saveA) {
                          setSaveA(true);
                        }
                      }}
                    ></input>
                    <p className="preHpwValTxt">m</p>

                    <p
                      className={`checklistSaveBtn ${
                        isNaN(parseInt(historyMpwHours)) ||
                        isNaN(parseInt(historyMpwMins))
                          ? "disabled"
                          : ""
                      } ${
                        !saveA ||
                        parseInt(historyMpwHours) < 0 ||
                        parseInt(historyMpwMins) < 0 ||
                        parseInt(historyMpwHours) * 60 +
                          parseInt(historyMpwMins) >
                          1440 * 7
                          ? "unsaveHistBtn"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          isNaN(parseInt(historyMpwHours)) ||
                          isNaN(parseInt(historyMpwMins))
                        ) {
                        } else {
                          updateItem(1);
                        }
                      }}
                    >
                      Save
                    </p>
                  </div>
                </div>
              </div>
              {/* 3.2 */}
              <div className={`checklistSubRow`}>
                <div className="checklistSubRowLeft">
                  <div className="checklist3subTitle">
                    <p className="check3subNum">b</p>
                    <p className={`sub3atitleSpan`}>
                      How many days per week was {fName} contracted to work
                      between{" "}
                      <span className="lightBlue">
                        {dateStringer.printedDateWithYrFromDWithoutDay(
                          historyStartDs
                        )}
                      </span>{" "}
                      and{" "}
                      <span className="lightBlue">
                        {dateStringer.printedDateWithYrFromDWithoutDay(
                          historyEndDs
                        )}
                      </span>
                      ?
                    </p>
                  </div>
                  <p className="check3Ainfo check3AinfoEditUser">
                    Provide an average if this varied
                  </p>
                  <div className="hrMinPerWeekDiv x1098101">
                    <input
                      type="number"
                      className={`hrMinInputter ${
                        savedB ? "savedInputter" : ""
                      }`}
                      value={dpw}
                      onChange={(e) => {
                        setDpw(parseInt(e.target.value));
                        if (!saveB) {
                          setSaveB(true);
                        }
                      }}
                    ></input>
                    <p className="preHpwValTxt">days per week</p>

                    <p
                      className={`checklistSaveBtn ${
                        isNaN(parseInt(dpw)) ||
                        parseInt(dpw) < 0 ||
                        parseInt(dpw) > 7
                          ? "disabled"
                          : ""
                      } ${
                        !saveB ||
                        isNaN(parseInt(dpw)) ||
                        parseInt(dpw) < 0 ||
                        parseInt(dpw) > 7
                          ? "unsaveHistBtn"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          isNaN(parseInt(dpw)) ||
                          parseInt(dpw) < 0 ||
                          parseInt(dpw) > 7
                        ) {
                          console.log({ dpw });
                        } else {
                          updateItem(2);
                        }
                      }}
                    >
                      Save
                    </p>
                  </div>
                </div>
              </div>
              {/* 3.3 */}
              <div className={`checklistSubRow`}>
                <div className="checklistSubRowLeft">
                  <div className="checklist3subTitle">
                    <p className="check3subNum">c</p>
                    <p className={`sub3atitleSpan`}>
                      How many total hours was {fName} paid for between{" "}
                      <span className="lightBlue">
                        {dateStringer.printedDateWithYrFromDWithoutDay(
                          historyStartDs
                        )}
                      </span>{" "}
                      and{" "}
                      <span className="lightBlue">
                        {dateStringer.printedDateWithYrFromDWithoutDay(
                          historyEndDs
                        )}
                      </span>
                      ?
                    </p>
                  </div>
                  <p className="check3Ainfo check3AinfoEditUser">
                    Include shifts, overtime, leave and absence (paid)
                  </p>
                  <div className="hrMinPerWeekDiv x1098101">
                    <input
                      type="number"
                      className={`hrMinInputter ${
                        savedC ? "savedInputter" : ""
                      } hrMinInputterWide`}
                      value={totalHoursPaidFor}
                      onChange={(e) => {
                        if (!saveC) {
                          setSaveC(true);
                        }
                        setTotalHoursPaidFor(e.target.value);
                      }}
                    ></input>
                    <p className="preHpwValTxt">hours</p>

                    <p
                      className={`checklistSaveBtn ${
                        isNaN(parseInt(totalHoursPaidFor)) ||
                        parseInt(totalHoursPaidFor) < 0
                          ? "disabled"
                          : ""
                      } ${
                        !saveC ||
                        isNaN(parseInt(totalHoursPaidFor)) ||
                        parseInt(totalHoursPaidFor) < 0
                          ? "unsaveHistBtn"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          isNaN(parseInt(totalHoursPaidFor)) ||
                          parseInt(totalHoursPaidFor) < 0
                        ) {
                        } else {
                          updateItem(3);
                        }
                      }}
                    >
                      Save
                    </p>
                  </div>
                </div>
              </div>
              {/* 3.4 */}
              <div className={`checklistSubRow`}>
                <div className="checklistSubRowLeft">
                  <div className="checklist3subTitle">
                    <p className="check3subNum">d</p>
                    <p className={`sub3atitleSpan`}>
                      How much annual leave has {fName} taken between{" "}
                      <span className="lightBlue">
                        {dateStringer.printedDateWithYrFromDWithoutDay(
                          historyStartDs
                        )}
                      </span>{" "}
                      and{" "}
                      <span className="lightBlue">
                        {dateStringer.printedDateWithYrFromDWithoutDay(
                          historyEndDs
                        )}
                      </span>
                      ?
                    </p>
                  </div>

                  <div className="hrMinPerWeekDiv x1098101">
                    <input
                      type="number"
                      className={`hrMinInputter ${
                        savedD ? "savedInputter" : ""
                      }`}
                      value={annLeaveHours}
                      onChange={(e) => {
                        if (!saveD) {
                          setSaveD(true);
                        }
                        setAnnLeaveHours(e.target.value);
                      }}
                    ></input>
                    <p className="preHpwValTxt">h</p>
                    <input
                      type="number"
                      className={`hrMinInputter ${
                        savedD ? "savedInputter" : ""
                      }`}
                      value={annLeaveMins}
                      onChange={(e) => {
                        if (!saveD) {
                          setSaveD(true);
                        }
                        setAnnLeaveMins(e.target.value);
                      }}
                    ></input>
                    <p className="preHpwValTxt">m</p>
                    <p
                      className={`checklistSaveBtn ${
                        isNaN(parseInt(annLeaveHours)) ||
                        isNaN(parseInt(annLeaveMins))
                          ? "disabled"
                          : ""
                      } ${
                        !saveD ||
                        isNaN(parseInt(annLeaveHours)) ||
                        isNaN(parseInt(annLeaveMins))
                          ? "unsaveHistBtn"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          isNaN(parseInt(annLeaveHours)) ||
                          isNaN(parseInt(annLeaveMins))
                        ) {
                        } else {
                          updateItem(4);
                        }
                      }}
                    >
                      Save
                    </p>
                  </div>
                </div>
              </div>
              {/* 3.5 */}
              <div className={`checklistSubRow`}>
                <div className="checklistSubRowLeft">
                  <div className="checklist3subTitle">
                    <p className="check3subNum">e</p>
                    <p className={`sub3atitleSpan`}>
                      How much paid absence did {fName} receive between{" "}
                      <span className="lightBlue">
                        {dateStringer.printedDateWithYrFromDWithoutDay(
                          historyStartDs
                        )}
                      </span>{" "}
                      and{" "}
                      <span className="lightBlue">
                        {dateStringer.printedDateWithYrFromDWithoutDay(
                          historyEndDs
                        )}
                      </span>
                      ?
                    </p>
                  </div>

                  <div className="hrMinPerWeekDiv x1098101">
                    <input
                      type="number"
                      className={`hrMinInputter ${
                        savedE ? "savedInputter" : ""
                      }`}
                      value={paidAbsHours}
                      onChange={(e) => {
                        setPaidAbsHours(e.target.value);
                        if (!saveE) {
                          setSaveE(true);
                        }
                      }}
                    ></input>
                    <p className="preHpwValTxt">h</p>
                    <input
                      type="number"
                      className={`hrMinInputter ${
                        savedE ? "savedInputter" : ""
                      }`}
                      value={paidAbsMins}
                      onChange={(e) => {
                        setPaidAbsMins(e.target.value);
                        if (!saveE) {
                          setSaveE(true);
                        }
                      }}
                    ></input>
                    <p className="preHpwValTxt">m</p>

                    <p
                      className={`checklistSaveBtn ${
                        isNaN(parseInt(paidAbsHours)) ||
                        isNaN(parseInt(paidAbsMins))
                          ? "disabled"
                          : ""
                      }  ${
                        !saveE ||
                        isNaN(parseInt(paidAbsHours)) ||
                        isNaN(parseInt(paidAbsMins))
                          ? "unsaveHistBtn"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          !saveE ||
                          isNaN(parseInt(paidAbsHours)) ||
                          isNaN(parseInt(paidAbsMins))
                        ) {
                        } else {
                          updateItem(5);
                        }
                      }}
                    >
                      Save
                    </p>
                  </div>
                </div>
              </div>

              {/* 3.6 */}
              <div className={`checklistSubRow`}>
                <div className="checklistSubRowLeft">
                  <div className="checklist3subTitle">
                    <p className="check3subNum">f</p>
                    <p className={`sub3atitleSpan`}>
                      How much unpaid absence leave did {fName} take between{" "}
                      <span className="lightBlue">
                        {dateStringer.printedDateWithYrFromDWithoutDay(
                          historyStartDs
                        )}
                      </span>{" "}
                      and{" "}
                      <span className="lightBlue">
                        {dateStringer.printedDateWithYrFromDWithoutDay(
                          historyEndDs
                        )}
                      </span>
                      ?
                    </p>
                  </div>

                  <div className="hrMinPerWeekDiv x1098101">
                    <input
                      type="number"
                      className={`hrMinInputter ${
                        savedF ? "savedInputter" : ""
                      }`}
                      value={unpaidAbsHours}
                      onChange={(e) => {
                        if (!saveF) {
                          setSaveF(true);
                        }
                        setUnpaidAbsHours(e.target.value);
                      }}
                    ></input>
                    <p className="preHpwValTxt">h</p>
                    <input
                      type="number"
                      className={`hrMinInputter ${
                        savedF ? "savedInputter" : ""
                      }`}
                      value={unpaidAbsMins}
                      onChange={(e) => {
                        if (!saveF) {
                          setSaveF(true);
                        }
                        setUnpaidAbsMins(e.target.value);
                      }}
                    ></input>
                    <p className="preHpwValTxt">m</p>
                    <p
                      className={`checklistSaveBtn ${
                        isNaN(parseInt(unpaidAbsHours)) ||
                        isNaN(parseInt(unpaidAbsMins))
                          ? "disabled"
                          : ""
                      } ${
                        !saveF ||
                        isNaN(parseInt(unpaidAbsHours)) ||
                        isNaN(parseInt(unpaidAbsMins))
                          ? "unsaveHistBtn"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          !saveF ||
                          isNaN(parseInt(unpaidAbsHours)) ||
                          isNaN(parseInt(unpaidAbsMins))
                        ) {
                        } else {
                          updateItem(6);
                        }
                      }}
                    >
                      Save
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="closedDaysTimelineFooter">
          <p></p>
          <p
            className="closeReqInfo"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </p>
        </div>
      </div>
      {!hasHistory && !loading ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              {fName} was added to FlowRota after your company joined - so we
              have all the data we need, providing it's recorded accurately.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setHasHistory(false);
                  closeModal();
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default EditUserHistoryModal;
