import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 aug 24
// This is a team when opened as an admin
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";

import axios from "axios";
import serverURL from "../serverURL";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";
import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import navyClose from "../img/general/navycross.svg";
import whitebin from "../img/general/whitebin.svg";
import navyTick from "../img/general/navyTick.svg";
import unitCrossBlue from "../img/general/unitCrossBlue.svg";
import editUserEdit1 from "../img/general/editUserEdit1.svg";
import whiteAdd from "../img/general/whiteAdd.svg";
import whitecross from "../img/general/whitecross.svg";

const ViewTeamModal = ({ teamID, limitEditsToMgrLevel }) => {
  const { mob, mobModal } = useContext(StyleContext);

  const {
    showViewTeamModal,
    setShowViewTeamModal,
    setShowEditUser,
    showEditUser,
    setUpdateTeam,
    teamCount,
    setTeamCount,
    updateAccount,
    setUpdateAccount,
    modalOpen,
    setModalOpen,
    device,
    showAddUserModal,
    setShowAddUserModal,
  } = useContext(DataContext);
  const { checkUserState } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      showViewTeamModal, //
      setShowViewTeamModal, //
      setShowEditUser, //
      showEditUser, //
      setUpdateTeam, //
      teamCount, //
      setTeamCount, //
      updateAccount, //
      setUpdateAccount, //
      mob, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
      checkUserState, //
      showAddUserModal,

      setShowAddUserModal,
    }),
    [
      showViewTeamModal, //
      setShowViewTeamModal, //
      setShowEditUser, //
      showEditUser, //
      setUpdateTeam, //
      teamCount, //
      setTeamCount, //
      updateAccount, //
      setUpdateAccount, //
      mob, //
      mobModal, //
      checkUserState, //
      modalOpen,
      setModalOpen,
      device,
      showAddUserModal,

      setShowAddUserModal,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 7) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyClose}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitebin}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyTick}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitCrossBlue}
        alt="4"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editUserEdit1}
        alt="5"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteAdd}
        alt="6"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="7"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  let [users, setUsers] = useState([]);
  let [mgrs, setMgrs] = useState([]);
  let [teamName, setTeamName] = useState("");
  let [toggleEditName, setToggleEditName] = useState(false);
  let [nameVal, setNameVal] = useState("");
  let [allowSelfApproveUserIDs, setAllowSelfApproveUserIDs] = useState([]);

  let [filterMgrNotUsr, setFilterMgrNotUsr] = useState(false);
  let [toggleAddMgr, setToggleAddMgr] = useState(false);
  let [toggleAddUser, setToggleAddUser] = useState(false);
  let [showUnable, setShowUnable] = useState({ show: false });
  let [suggestAddMbr, setSuggestAddMbr] = useState(false);
  let [showAllowSelfApprove, setShowAllowSelfApprove] = useState(false);
  let [sureRemove, setSureRemove] = useState({});
  //   let []
  let [showCannotArchive, setShowCannotArchive] = useState(false);
  let [addableUsers, setAddableUsers] = useState([]);
  let [sureRemoveTeam, setSureRemoveTeam] = useState(false);

  // useEffect(()=>{

  // }, [show])

  let [numberOfMgrsWhoAreNotMbrs, setNumberOfMgrsWhoAreNotMbrs] = useState(0);
  useEffect(() => {
    // clean not needed
    let count = 0;
    mgrs.forEach((m) => {
      let userFilt = users.filter((item) => {
        return item.userID === m.userID;
      });

      if (!userFilt[0]) {
        count++;
      }
    });

    setNumberOfMgrsWhoAreNotMbrs(count);
  }, [users, mgrs]);

  let doesMgrExist = (uid) => {
    let filt = mgrs.filter((item) => {
      return item.userID === uid;
    });

    if (filt[0]) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (
      memoVals.showViewTeamModal &&
      !memoVals.showAddUserModal &&
      !memoVals.showEditUser
    ) {
      axios
        .post(
          `${serverURL}/get-team`,
          { teamID, getAddable: true },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setUsers(response.data.users || []);
            setMgrs(response.data.mgrs || []);
            setTeamName(response.data.teamName || "");
            setAllowSelfApproveUserIDs(
              response.data.allowSelfApproveUserIDs || []
            );
            // setLoading(false);
            let selfApproves = response.data.mgrs.filter((item) => {
              return item.allowSelfApprove;
            });

            let arr = [];
            selfApproves.forEach((item) => {
              arr.push(item.userID);
            });
            setDataLoaded(true);
            setAllowSelfApproveUserIDs(arr);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.showAddUserModal, memoVals.showEditUser]);

  let [initDone, setInitDone] = useState(false);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (toggleAddMgr || toggleAddUser) {
      if (!addableUsers[0]) {
        axios
          .post(
            `${serverURL}/get-all-users`,
            { myCal: true, includeSelf: true },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message.includes("logout")) {
              window.location.href = "/signin";
            }
            if (response.data.message === "success") {
              setInitDone(true);
              let arr = response.data.usersArray;

              arr.sort((a, b) => {
                a.fName.localeCompare(b.fName);
              });

              setAddableUsers(arr);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [toggleAddMgr, toggleAddUser]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (!memoVals.showAddUserModal && initDone) {
      axios
        .post(
          `${serverURL}/get-all-users`,
          { myCal: true, includeSelf: true },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            let arr = response.data.usersArray;

            arr.sort((a, b) => {
              a.fName.localeCompare(b.fName);
            });

            setAddableUsers(arr);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.showAddUserModal]);

  let closeModal = () => {
    memoVals.setShowViewTeamModal((x) => "");
    let userNames = [];
    let mgrNames = [];

    users.forEach((usr) => {
      userNames.push({
        fName: usr.fName,
        lName: usr.lName,
        userID: usr.userID,
      });
    });

    mgrs.forEach((mgr) => {
      mgrNames.push({ fName: mgr.fName, lName: mgr.lName, userID: mgr.userID });
    });

    setUpdateTeam((x) => {
      return {
        editing: true,
        teamName: teamName,
        teamID,
        mgrs: mgrNames,
        users: userNames,
      };
    });
  };
  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  // master return
  return (
    <div
      className={`clockOnModalUnderlay ${
        memoVals.showEditUser || memoVals.showAddUserModal ? "invis" : ""
      }`}
      onClick={() => {
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`userReqInfoModalBox ${
            window.innerWidth < 500 ? "mobReqInfoModalBox slideUp" : "zoomIn"
          } ${
            memoVals.mobModal
              ? `mobModalShoulder mobModalTeamModal ${
                  memoVals.device.ios ? "paddingBottom35" : ""
                }`
              : ""
          } ${loading ? "invis" : ""} `}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperViewTeamModal"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="reqInfoHeader"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={window.innerWidth < 500 ? navyClose : navyClose}
              className="closeReqInfoModalImg"
              alt="Close"
              onClick={() => {
                closeModal();
              }}
            />
            <p
              className={`adminTeamNameTitle ${
                teamName.length > 20
                  ? "teamNameOver20"
                  : teamName.length > 26
                  ? "teamNameOver26"
                  : ""
              }`}
              onClick={() => {
                console.log({ allowSelfApproveUserIDs });
              }}
            >
              {toggleEditName ? (
                <input
                  type="text"
                  className={`adminTeamNameInput`}
                  value={nameVal}
                  onChange={(e) => {
                    setNameVal(e.target.value);
                  }}
                ></input>
              ) : (
                dateStringer.shorten(teamName, 25)
              )}
              {!toggleEditName ? (
                <img
                  src={editUserEdit1}
                  alt="Edit"
                  className={`editTeamNameImg ${
                    limitEditsToMgrLevel ? "none" : ""
                  }`}
                  onClick={() => {
                    if (!limitEditsToMgrLevel) {
                      setToggleEditName(true);
                      setNameVal(teamName);
                    }
                  }}
                />
              ) : (
                <p
                  className="saveGpsZoneBtn saveAdminTeamName"
                  onClick={() => {
                    setToggleEditName(false);
                    setTeamName(nameVal);
                    if (!limitEditsToMgrLevel) {
                      axios
                        .post(
                          `${serverURL}/edit-team-name`,
                          {
                            teamID,
                            newData: nameVal,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setNameVal("");
                            setTeamName(response.data.teamName);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  Save
                </p>
                //   <img
                //     src={greyClose}
                //     alt="Cancel"
                //     className="editTeamNameImgCanx"
                //     onClick={() => {
                //       setToggleEditName(false);
                //       setNameVal("");
                //     }}
                //   />
              )}
            </p>
            {/* <div className="closeReqInfoModalImg"></div> */}
          </div>
          <div className="userReqBody">
            <div
              className={`teamAdminLeft ${
                window.innerWidth < 500 ? "teamAdminLeftMob" : ""
              }`}
            >
              <div className="adminTeamTitleBar">
                <p
                  className={`adminTeamRowTitle ${
                    filterMgrNotUsr ? "adminTeamRowTitleBorder" : ""
                  }`}
                >
                  {filterMgrNotUsr ? "Managers who aren't members" : "Managers"}
                </p>
                {!filterMgrNotUsr && !limitEditsToMgrLevel ? (
                  <p
                    className={`addMgrTeam addMgrTeamMgr ${
                      toggleAddMgr ? "canxAddToTeam" : ""
                    }`}
                    onClick={() => {
                      setToggleAddMgr(!toggleAddMgr);
                    }}
                  >
                    Add manager{" "}
                    <img
                      src={toggleAddMgr ? whitecross : whiteAdd}
                      className="addMgrWhiteAddImg"
                    />
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
              {toggleAddMgr ? (
                <div className="addToTeamDiv">
                  <select
                    className="adminTeamAddUsrDropdown"
                    value="_"
                    onChange={(e) => {
                      let uid = e.target.value;
                      axios
                        .post(
                          `${serverURL}/make-user-team-manager`,
                          { teamID, userID: uid },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            let usr = addableUsers.filter((item) => {
                              return item.userID === response.data.userID;
                            });
                            let arr = [
                              ...mgrs,
                              {
                                fName: response.data.fName,
                                lName: response.data.lName,
                                userID: response.data.userID,
                                primaryTeamID: response.data.primaryTeamID,
                                jobTitle: response.data.jobTitle,
                              },
                            ];
                            setMgrs(arr || []);
                            setToggleAddMgr(false);

                            if (!response.data.isUser) {
                              setSuggestAddMbr({
                                fName: response.data.fName,
                                lName: response.data.lName,
                                userID: uid,
                              });
                            } else {
                              if (response.data.isPrim) {
                                console.log(
                                  "Open modal to ask whether to self approve leave reqs"
                                );
                                setSuggestAddMbr({
                                  fName: response.data.fName,
                                  lName: response.data.lName,
                                  userID: uid,
                                });
                                setShowAllowSelfApprove(response.data.fName);
                              }
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <option value="_">Add a manager</option>

                    {addableUsers.map((usr) => {
                      let alreadyExists = mgrs.filter((item) => {
                        return item.userID === usr.userID;
                      });
                      if (alreadyExists[0]) {
                      } else {
                        return (
                          <option value={usr.userID}>
                            {usr.fName} {usr.lName}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              ) : (
                ""
              )}
              <div className="adminTeamUsrRow">
                {mgrs[0] ? (
                  mgrs
                    .sort((a, b) => {
                      return a.fName.localeCompare(b.fName);
                    })
                    .map((mgr) => {
                      let userFilt = filterMgrNotUsr
                        ? users.filter((item) => {
                            return item.userID === mgr.userID;
                          })
                        : [];

                      let doesUserExist = users.filter((item) => {
                        return item.userID === mgr.userID;
                      });
                      if (
                        !filterMgrNotUsr ||
                        (filterMgrNotUsr && !userFilt[0])
                      ) {
                        return (
                          <div className="teamAdminUsrItem">
                            <div className="teamAdminItemLeft">
                              <p
                                className={`adminTeamMgrTxt ${
                                  !memoVals.mob ? "adminTeamMgrTxtHover" : ""
                                }`}
                                onClick={() => {
                                  memoVals.setShowEditUser((x) => mgr.userID);
                                }}
                              >
                                {" "}
                                {mgr.fName}{" "}
                                {mgr.fName.length + mgr.lName.length > 18
                                  ? mgr.lName[0]
                                  : mgr.lName}
                              </p>
                              <p className="viewTeamUsrJt x23892492849852">
                                {mgr.jobTitle}
                              </p>
                              {doesUserExist[0] &&
                              !limitEditsToMgrLevel &&
                              mgr.primaryTeamID === teamID ? (
                                <p
                                  className={`adminTeamSelfApproveTxt ${
                                    !memoVals.mob
                                      ? "adminTeamSelfApproveTxtNonMob"
                                      : ""
                                  } ${
                                    !allowSelfApproveUserIDs.includes(
                                      mgr.userID
                                    )
                                      ? `selfAppInActive ${
                                          !memoVals.mob
                                            ? "selfAppInActiveHover"
                                            : ""
                                        }`
                                      : ""
                                  } `}
                                  onClick={() => {
                                    axios
                                      .post(
                                        `${serverURL}/toggle-allow-self-approve`,
                                        {
                                          data: !allowSelfApproveUserIDs.includes(
                                            mgr.userID
                                          ),
                                          userID: mgr.userID,
                                        },

                                        {
                                          withCredentials: true,
                                          credentials: "include",
                                        }
                                      )
                                      .then((response) => {
                                        if (
                                          response.data.message === "success"
                                        ) {
                                          if (
                                            !allowSelfApproveUserIDs.includes(
                                              mgr.userID
                                            )
                                          ) {
                                            setAllowSelfApproveUserIDs([
                                              ...allowSelfApproveUserIDs,
                                              mgr.userID,
                                            ]);
                                          } else {
                                            let arr = [];
                                            allowSelfApproveUserIDs.forEach(
                                              (uid) => {
                                                if (uid !== mgr.userID) {
                                                  arr.push(uid);
                                                }
                                              }
                                            );

                                            setAllowSelfApproveUserIDs(arr);
                                          }
                                        }
                                      })
                                      .catch((err) => {
                                        console.error(err);
                                      });
                                  }}
                                >
                                  <img
                                    src={
                                      allowSelfApproveUserIDs.includes(
                                        mgr.userID
                                      )
                                        ? navyTick
                                        : unitCrossBlue
                                    }
                                    alt="Allow self approve"
                                    className="allowSelfApproveTick"
                                  />{" "}
                                  Self-approve own leave requests
                                </p>
                              ) : (
                                ""
                              )}
                              {doesUserExist[0] && !limitEditsToMgrLevel ? (
                                <p
                                  className={`adminTeamSelfApproveTxt x2389741r ${
                                    !memoVals.mob
                                      ? "adminTeamSelfApproveTxtNonMob"
                                      : ""
                                  } ${
                                    mgr.allowSelfHoursApprove &&
                                    Array.isArray(mgr.allowSelfHoursApprove) &&
                                    !mgr.allowSelfHoursApprove.includes(teamID)
                                      ? `selfAppInActive ${
                                          !memoVals.mob
                                            ? "selfAppInActiveHover"
                                            : ""
                                        }`
                                      : ""
                                  } `}
                                  onClick={() => {
                                    let currTrue = false;
                                    if (
                                      mgr.allowSelfHoursApprove &&
                                      Array.isArray(mgr.allowSelfHoursApprove)
                                    ) {
                                      let filt =
                                        mgr.allowSelfHoursApprove.filter(
                                          (x) => {
                                            return x === teamID;
                                          }
                                        );

                                      if (filt[0]) {
                                        currTrue = true;
                                      }
                                    }
                                    console.log(
                                      "Currently can manage own hours? ",
                                      currTrue ? " yes" : " no"
                                    );
                                    axios
                                      .post(
                                        `${serverURL}/toggle-mgr-can-manage-own-team-hours`,
                                        {
                                          userID: mgr.userID,
                                          teamID,
                                          add: currTrue ? false : true,
                                          pull: currTrue ? true : false,
                                        },

                                        {
                                          withCredentials: true,
                                          credentials: "include",
                                        }
                                      )
                                      .then((response) => {
                                        if (
                                          response.data.message === "success"
                                        ) {
                                          let newArr = [];
                                          mgrs.forEach((m) => {
                                            let selfAppHoursArr =
                                              m.allowSelfHoursApprove || [];
                                            newArr.push({
                                              userID: m.userID,
                                              fName: m.fName,
                                              lName: m.lName,
                                              primaryTeamID: m.primaryTeamID,
                                              jobTitle: m.jobTitle,
                                              allowSelfApprove:
                                                m.allowSelfApprove,
                                              allowSelfClockApprove:
                                                mgr.allowSelfClockApprove,
                                              allowSelfHoursApprove:
                                                m.userID === mgr.userID
                                                  ? !currTrue
                                                    ? [
                                                        ...selfAppHoursArr,
                                                        teamID,
                                                      ]
                                                    : selfAppHoursArr.filter(
                                                        (x) => {
                                                          return x !== teamID;
                                                        }
                                                      )
                                                  : m.allowSelfHoursApprove,
                                            });
                                          });
                                          setMgrs(newArr);
                                        }
                                      })
                                      .catch((err) => {
                                        console.error(err);
                                      });
                                  }}
                                >
                                  <img
                                    src={
                                      mgr.allowSelfHoursApprove &&
                                      Array.isArray(
                                        mgr.allowSelfHoursApprove
                                      ) &&
                                      mgr.allowSelfHoursApprove.includes(teamID)
                                        ? navyTick
                                        : unitCrossBlue
                                    }
                                    alt="Allow self approve"
                                    className="allowSelfApproveTick"
                                  />{" "}
                                  Allow {mgr.fName} to amend own shifts and
                                  approve own swaps & changes
                                </p>
                              ) : (
                                ""
                              )}

                              {doesUserExist[0] && !limitEditsToMgrLevel ? (
                                <p
                                  className={`adminTeamSelfApproveTxt x2389741r ${
                                    !memoVals.mob
                                      ? "adminTeamSelfApproveTxtNonMob"
                                      : ""
                                  } ${
                                    mgr.allowSelfClockApprove &&
                                    Array.isArray(mgr.allowSelfClockApprove) &&
                                    !mgr.allowSelfClockApprove.includes(teamID)
                                      ? `selfAppInActive ${
                                          !memoVals.mob
                                            ? "selfAppInActiveHover"
                                            : ""
                                        }`
                                      : ""
                                  } `}
                                  onClick={() => {
                                    let currTrue = false;
                                    if (
                                      mgr.allowSelfClockApprove &&
                                      Array.isArray(mgr.allowSelfClockApprove)
                                    ) {
                                      let filt =
                                        mgr.allowSelfClockApprove.filter(
                                          (x) => {
                                            return x === teamID;
                                          }
                                        );

                                      if (filt[0]) {
                                        currTrue = true;
                                      }
                                    }

                                    // axios here to add or remove teamID from allowSelfClockApprove array on the User doc
                                    axios
                                      .post(
                                        `${serverURL}/toggle-allow-self-clock-approve`,
                                        {
                                          userID: mgr.userID,
                                          data: mgr.allowSelfClockApprove?.includes(
                                            teamID
                                          )
                                            ? false
                                            : true,
                                          teamID: teamID,
                                        },

                                        {
                                          withCredentials: true,
                                          credentials: "include",
                                        }
                                      )
                                      .then((response) => {
                                        if (
                                          response.data.message === "success"
                                        ) {
                                          let newArr = [];
                                          mgrs.forEach((m) => {
                                            let selfClockArr =
                                              m.allowSelfClockApprove || [];
                                            newArr.push({
                                              userID: m.userID,
                                              fName: m.fName,
                                              lName: m.lName,
                                              primaryTeamID: m.primaryTeamID,
                                              jobTitle: m.jobTitle,
                                              allowSelfApprove:
                                                m.allowSelfApprove,
                                              allowSelfHoursApprove:
                                                m.allowSelfHoursApprove,
                                              allowSelfClockApprove:
                                                m.userID === mgr.userID
                                                  ? !currTrue
                                                    ? [...selfClockArr, teamID]
                                                    : selfClockArr.filter(
                                                        (x) => {
                                                          return x !== teamID;
                                                        }
                                                      )
                                                  : m.allowSelfClockApprove,
                                            });
                                          });
                                          setMgrs(newArr);
                                        }
                                      })
                                      .catch((err) => {
                                        console.error(err);
                                      });
                                  }}
                                >
                                  <img
                                    src={
                                      mgr.allowSelfClockApprove &&
                                      Array.isArray(
                                        mgr.allowSelfClockApprove
                                      ) &&
                                      mgr.allowSelfClockApprove.includes(teamID)
                                        ? navyTick
                                        : unitCrossBlue
                                    }
                                    alt="Allow self approve"
                                    className="allowSelfApproveTick"
                                  />{" "}
                                  Allow {mgr.fName} to approve and amend own
                                  clock cards within this team
                                </p>
                              ) : (
                                ""
                              )}

                              {doesUserExist[0] && !limitEditsToMgrLevel ? (
                                <a
                                  href="https://flowrota.com/support/self-approve-permissions"
                                  target="_blank"
                                  className="helpSelfAppInfoBtn"
                                >
                                  What do these mean?
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                            {!limitEditsToMgrLevel ? (
                              <img
                                src={navyClose}
                                className="removeUsrFromTeamImg"
                                alt="Remove"
                                onClick={() => {
                                  setSureRemove({
                                    fName: mgr.fName,
                                    userID: mgr.userID,
                                    pullingMgr: true,
                                    pullingUsr: false,
                                  });
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      }
                    })
                ) : (
                  <p className="noUsersTeamTxt">This team has no managers</p>
                )}
              </div>
              {!filterMgrNotUsr ? (
                <div className="adminTeamTitleBar">
                  <p className="adminTeamRowTitle">Members</p>

                  <p
                    className={`addMgrTeam ${
                      toggleAddUser ? "canxAddToTeam" : ""
                    }`}
                    onClick={() => {
                      setToggleAddUser(!toggleAddUser);
                    }}
                  >
                    Add member{" "}
                    <img
                      src={toggleAddUser ? whitecross : whiteAdd}
                      className="addMgrWhiteAddImg"
                    />
                  </p>
                </div>
              ) : (
                ""
              )}
              {toggleAddUser && !filterMgrNotUsr ? (
                <div className="addToTeamDiv">
                  <select
                    className="adminTeamAddUsrDropdown addUsrToTeamBorder"
                    value="_"
                    onChange={(e) => {
                      let uid = e.target.value;

                      if (uid === "new") {
                        memoVals.setShowAddUserModal((x) => true);
                      } else {
                        axios
                          .post(
                            `${serverURL}/add-user-to-team`,
                            { teamID, userID: uid },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              let usr = addableUsers.filter((item) => {
                                return item.userID === uid;
                              });
                              let arr = [
                                ...users,
                                {
                                  fName: usr[0].fName,
                                  lName: usr[0].lName,
                                  userID: usr[0].userID,
                                  jobTitle: usr[0].jobTitle,
                                },
                              ];
                              setUsers(arr || []);
                              setToggleAddUser(false);

                              let mgrFilt = mgrs.filter((item) => {
                                return item.userID === uid;
                              });

                              if (uid === memoVals.checkUserState.userID) {
                                memoVals.setUpdateAccount(
                                  (x) => !memoVals.updateAccount
                                );
                              }
                              if (mgrFilt[0] && !limitEditsToMgrLevel) {
                                setSuggestAddMbr({
                                  fName: mgrFilt[0].fName,
                                  lName: mgrFilt[0].lName,

                                  userID: mgrFilt[0].userID,
                                });
                                setShowAllowSelfApprove("*");
                              }
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }}
                  >
                    <option value="_">Select employee</option>

                    {addableUsers.map((usr) => {
                      let alreadyExists = users.filter((item) => {
                        return item.userID === usr.userID;
                      });
                      if (alreadyExists[0]) {
                      } else {
                        return (
                          <option value={usr.userID}>
                            {usr.fName} {usr.lName}
                          </option>
                        );
                      }
                    })}
                    {checkUserState.permissions === 1 ? (
                      <option value="new">-- Add an employee</option>
                    ) : (
                      ""
                    )}
                  </select>
                </div>
              ) : (
                ""
              )}

              {!filterMgrNotUsr ? (
                <div className="adminTeamUsrRow">
                  {users[0] ? (
                    users
                      .sort((a, b) => {
                        return a.fName.localeCompare(b.fName);
                      })
                      .map((usr) => {
                        return (
                          <div className="teamAdminUsrItem">
                            <div className="teamAdminItemLeft">
                              <p
                                className="adminTeamUsrTxt"
                                onClick={() => {
                                  memoVals.setShowEditUser((x) => usr.userID);
                                }}
                              >
                                {usr.fName}{" "}
                                {usr.fName.length + usr.lName.length > 18
                                  ? usr.lName[0]
                                  : usr.lName}
                              </p>
                              <p className="viewTeamUsrJt">{usr.jobTitle}</p>
                              <p
                                className={`adminTeamSelfApproveTxt ${
                                  !memoVals.mob
                                    ? "adminTeamSelfApproveTxtNonMob"
                                    : ""
                                } ${
                                  usr.primaryTeamID !== teamID
                                    ? `selfAppInActive ${
                                        !memoVals.mob
                                          ? "selfAppInActiveHover"
                                          : ""
                                      }`
                                    : ""
                                } paddingTop2px ${
                                  teamID === usr.primaryTeamID
                                    ? "blockTeamPedit_"
                                    : ""
                                } alignCenter`}
                                onClick={() => {
                                  if (usr.primaryTeamID === teamID) {
                                    setShowUnable({
                                      show: true,
                                      userID: usr.userID,
                                      fName: usr.fName,
                                    });
                                  } else {
                                    axios
                                      .post(
                                        `${serverURL}/edit-user-primary-team`,
                                        {
                                          userID: usr.userID,
                                          newData: teamID,
                                        },

                                        {
                                          withCredentials: true,
                                          credentials: "include",
                                        }
                                      )
                                      .then((response) => {
                                        if (
                                          response.data.message === "success"
                                        ) {
                                          let newArr = users.map((x) => {
                                            if (x.userID === usr.userID) {
                                              x.primaryTeamID = teamID;
                                            }
                                            return x;
                                          });
                                          setUsers(newArr);
                                        }
                                      })
                                      .catch((err) => {
                                        console.error(err);
                                      });
                                  }
                                }}
                              >
                                <img
                                  src={
                                    usr.primaryTeamID === teamID
                                      ? navyTick
                                      : unitCrossBlue
                                  }
                                  alt="Allow self approve"
                                  className="allowSelfApproveTick"
                                />{" "}
                                Primary team
                              </p>
                            </div>
                            <img
                              src={navyClose}
                              className="removeUsrFromTeamImg"
                              alt="Remove"
                              onClick={() => {
                                setSureRemove({
                                  fName: usr.fName,
                                  userID: usr.userID,
                                  pullingMgr: false,
                                  pullingUsr: true,
                                });
                              }}
                            />
                          </div>
                        );
                      })
                  ) : (
                    <p className="noUsersTeamTxt">This team has no members</p>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="staffReqModalContentRight">
              <div className="adminTeamStat adminTeamStatMgrs">
                <p className="adminTeamStatNum">{mgrs.length}</p>
                <p className="adminTeamStatVal">managers</p>
              </div>
              {numberOfMgrsWhoAreNotMbrs > 0 ? (
                <div className="mgrsNonMbrsLine"></div>
              ) : (
                ""
              )}
              {numberOfMgrsWhoAreNotMbrs > 0 ? (
                <div
                  className={`adminTeamStat numberOfMgrsWhoAreNotMbrs ${
                    window.innerWidth > 500 ? "nonMbrHover" : ""
                  } ${filterMgrNotUsr ? "filterMgrNotUsr" : ""}`}
                  onClick={() => {
                    !setFilterMgrNotUsr(!filterMgrNotUsr);
                    console.log({ allowSelfApproveUserIDs });
                  }}
                >
                  <p className="adminTeamStatNum">
                    {numberOfMgrsWhoAreNotMbrs}
                  </p>
                  <p className="adminTeamStatVal">
                    Managers who aren't members
                  </p>
                  {/*  KNOB BEGIN */}
                  <div className="editUserReqClockOutKnobContainer adminTeamFilterKnob">
                    <div className="knobContainer knobContainerEditUser">
                      <div
                        className={`switchKnob ${
                          filterMgrNotUsr ? "knobOn" : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg"></div>
                    </div>
                  </div>
                  {/*  KNOB END */}
                </div>
              ) : (
                ""
              )}
              <div className="adminTeamStat">
                <p className="adminTeamStatNum">{users.length}</p>
                <p className="adminTeamStatVal">members</p>
              </div>
            </div>
          </div>
          <div className="adminTeamFooter">
            <div
              className="viewMapsAndSaveDiv"
              onClick={() => {
                if (users[0] || mgrs[0]) {
                  setShowCannotArchive(true);
                } else {
                  setSureRemoveTeam(true);
                }
              }}
            >
              {!limitEditsToMgrLevel ? (
                <a
                  // href={`https://www.google.com/maps/search/?api=1&query=`}
                  className="viewGpsZoneMapBtn"
                  target="_blank"
                >
                  Remove team
                  <img src={whitebin} alt="View map" className="mapImg" />
                </a>
              ) : (
                <p></p>
              )}
            </div>

            <p
              className="closeSeeUserIDBtn"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {suggestAddMbr.fName ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            setSuggestAddMbr({});
          }}
        >
          {showAllowSelfApprove ? (
            <div
              className={`formCreatedModal `}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="overlapsRenTxt">
                {showAllowSelfApprove === "*"
                  ? `${suggestAddMbr.fName} is a manager of this team.`
                  : ""}
                {showAllowSelfApprove === "*" ? <br /> : ""}{" "}
                {showAllowSelfApprove === "*" ? <br /> : ""}
                Should {suggestAddMbr.fName || showAllowSelfApprove} be allowed
                to approve their own leave requests?
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    setShowAllowSelfApprove({});

                    // make user allowed to approve their own requests
                    axios
                      .post(
                        `${serverURL}/toggle-allow-self-approve`,
                        {
                          data: true,
                          userID: suggestAddMbr.userID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setAllowSelfApproveUserIDs([
                            ...allowSelfApproveUserIDs,
                            suggestAddMbr.userID,
                          ]);
                          setSuggestAddMbr("");
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Yes
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    // make user not allowed to approve their own requests
                    setShowAllowSelfApprove({});

                    axios
                      .post(
                        `${serverURL}/toggle-allow-self-approve`,
                        {
                          data: false,
                          userID: suggestAddMbr.userID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          let arr = [];
                          allowSelfApproveUserIDs.forEach((uid) => {
                            if (suggestAddMbr.userID !== uid) {
                              arr.push(uid);
                            }
                          });

                          setAllowSelfApproveUserIDs(arr);
                          setSuggestAddMbr("");
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  No
                </p>
              </div>
            </div>
          ) : (
            <div
              className={`formCreatedModal `}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="overlapsRenTxt">
                Do you want to make {suggestAddMbr.fName} a member of this team
                as well? <br />
                {/* <br />
                By doing so, {suggestAddMbr} may be able to approve their own
                requests. */}
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    let val = suggestAddMbr.userID;
                    let val2 = suggestAddMbr;
                    setSuggestAddMbr({});
                    axios
                      .post(
                        `${serverURL}/add-user-to-team`,
                        {
                          teamID,
                          userID: val,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          if (!limitEditsToMgrLevel) {
                            if (
                              !response.data.allowSelfApprove &&
                              response.data.isPrim
                            ) {
                              setShowAllowSelfApprove(true);
                            } else {
                              setAllowSelfApproveUserIDs([
                                ...allowSelfApproveUserIDs,
                                val,
                              ]);
                            }
                          }
                          if (val === memoVals.checkUserState.userID) {
                            memoVals.setUpdateAccount(
                              (x) => !memoVals.updateAccount
                            );
                          }
                          let newUsersArr = [...users];
                          newUsersArr.push(val2);
                          setUsers(newUsersArr || []);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Yes
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setSuggestAddMbr({});
                  }}
                >
                  No
                </p>
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}

      {sureRemoveTeam ? (
        <div className="clockOnModalUnderlay">
          <div
            className={`formCreatedModal `}
            onClick={(e) => {
              e.stopPropagation();
              setSureRemove(false);
            }}
          >
            <p className="overlapsRenTxt">
              Are you sure you want to remove {teamName}?
              <p className="stillMgrSub">
                <br />
                <br /> <br />
                This team will be archived (rather than deleted) to ensure that
                any historical shifts associated with it remain accessible for
                reporting purposes.
                {/* Employees whose primary team is {teamName} will be assigned
                another one of their teams as their primary team. <br />
                <br />
                Employees without another team will be set to 'Teamless'. */}
              </p>
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/delete-team`,
                      {
                        teamID,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("cannot archive")) {
                        setShowCannotArchive(true);
                      }
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setUpdateTeam((x) => {
                          return {
                            teamID,
                            delete: true,
                          };
                        });
                        memoVals.setTeamCount((x) => memoVals.teamCount - 1);

                        memoVals.setShowViewTeamModal((x) => false);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureRemoveTeam(false);
                }}
              >
                No
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {sureRemove && sureRemove.fName ? (
        <div className="clockOnModalUnderlay">
          <div
            className={`formCreatedModal `}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              {sureRemove.pullingUsr
                ? `Are you sure you want to remove ${
                    sureRemove.fName
                  } from ${teamName}${
                    doesMgrExist(sureRemove.userID) && sureRemove.pullingUsr
                      ? " as a member"
                      : ""
                  }?`
                : `Are you sure you want to demote ${sureRemove.fName} as a manager of  ${teamName}?`}
              {sureRemove.pullingUsr && <br />}
              {sureRemove.pullingUsr && <br />}
              {sureRemove.pullingUsr &&
                `Any shifts scheduled for ${sureRemove.fName} within ${teamName} from today will be deleted.`}
            </p>
            {doesMgrExist(sureRemove.userID) && sureRemove.pullingUsr ? (
              <br />
            ) : (
              ""
            )}
            {doesMgrExist(sureRemove.userID) && sureRemove.pullingUsr ? (
              <br />
            ) : (
              ""
            )}
            {doesMgrExist(sureRemove.userID) && sureRemove.pullingUsr ? (
              <p className="overlapsRenTxt stillMgrSub">
                {sureRemove.fName} will still be a manager unless{" "}
                {limitEditsToMgrLevel
                  ? "an admin demotes them"
                  : "you demote them"}
                .
              </p>
            ) : (
              ""
            )}
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  if (sureRemove.pullingMgr) {
                    setSureRemove({});
                    axios
                      .post(
                        `${serverURL}/demote-team-manager`,
                        {
                          teamID,
                          userID: sureRemove.userID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          let arr = [];
                          mgrs.forEach((m) => {
                            if (m.userID !== sureRemove.userID) {
                              arr.push(m);
                            }
                          });
                          setMgrs(arr || []);

                          let arr2 = [];
                          allowSelfApproveUserIDs.forEach((uid) => {
                            if (sureRemove.userID !== uid) {
                              arr2.push(uid);
                            }
                          });
                          if (
                            sureRemove.userID === memoVals.checkUserState.userID
                          ) {
                            memoVals.setUpdateAccount(
                              (x) => !memoVals.updateAccount
                            );
                          }
                          setAllowSelfApproveUserIDs(arr2);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }

                  if (sureRemove.pullingUsr) {
                    setSureRemove({});

                    axios
                      .post(
                        `${serverURL}/remove-user-from-team`,
                        {
                          teamID,
                          userID: sureRemove.userID,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          let newArr = [];
                          if (
                            sureRemove.userID === memoVals.checkUserState.userID
                          ) {
                            memoVals.setUpdateAccount(
                              (x) => !memoVals.updateAccount
                            );
                          }
                          users.forEach((u) => {
                            if (u.userID !== sureRemove.userID) {
                              newArr.push(u);
                            }
                          });
                          setUsers(newArr || []);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureRemove(false);
                }}
              >
                No
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {showUnable.show ? (
        <div className="clockOnModalUnderlay">
          <div
            className={`formCreatedModal `}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              To change {dateStringer.possession(showUnable.fName)} primary team
              from {teamName} to a different team, you can do this in their
              profile.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setShowUnable({ show: false });
                  memoVals.setShowEditUser((x) => {
                    return { userID: showUnable.userID, teams: true };
                  });
                }}
              >
                Profile
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setShowUnable({ show: false });
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showCannotArchive ? (
        <div className="clockOnModalUnderlay">
          <div
            className={`formCreatedModal `}
            onClick={(e) => {
              e.stopPropagation();
              setShowCannotArchive(false);
            }}
          >
            <p className="overlapsRenTxt">
              You cannot remove a team that employees currently belong to.
              <br /> <br />
              Assign all employees to other teams first.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setShowCannotArchive(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default ViewTeamModal;
