import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  Fragment,
} from "react";
// use effects all cleaned up wef 22 july 2023
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import "../../styles/leaveRequests.css";
import { NavLink } from "react-router-dom";

import dateStringer from "../../tools/dateStringer";

import { DataContext } from "../../contexts/DataContext";
import { UserContext } from "../../contexts/UserContext";
import { CalendarContext } from "../../contexts/CalendarContext";

import commentRota from "../../img/general/commentRota.svg";
import view from "../../img/general/view.svg";
import whitePower from "../../img/general/whitePower.svg";
import whiteCog from "../../img/general/whiteCog.svg";

import serverURL from "../../serverURL";

import horizontalBalls from "../../img/loaders/horizontalBalls.svg";
import schedLimitPowerOff from "../../img/general/schedLimitPowerOff.svg";
import schedLimitMinus from "../../img/general/schedLimitMinus.svg";
import schedLimitPlus from "../../img/general/schedLimitPlus.svg";
import limArrow from "../../img/general/limArrow.svg";
import popup2 from "../../img/general/popup2.svg";

const TeamCalendarInlet = ({
  isMgr,
  selectedMy,
  selectedTeam,
  loading,
  setLoading,
  mob,
  setShowDayCommentModal,
  setMyArr,
  myArr,
  leaveTypesArr,
  setLeaveTypesArr,
  selectedType,
  setShowClosedDay,
}) => {
  const { setBulkEditLimitObj, updateInfo, updateLimits } =
    useContext(CalendarContext);
  const {
    setShowReqMgrModal,
    setShowAddLeaveModal,
    setShowEditUser,
    setSeeUserID,
    setShowUserReqFromNav,
    setShowViewTeamDayModal,
    updateCalComments,
    setUpdateCalComments,
  } = useContext(DataContext);
  const { checkUserState } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      setBulkEditLimitObj, //
      updateInfo, //
      setShowReqMgrModal, //
      setShowAddLeaveModal, //
      setShowEditUser, //
      setSeeUserID, //
      setShowUserReqFromNav, //
      setShowViewTeamDayModal, //
      updateCalComments, //
      setUpdateCalComments, //
      checkUserState, //
      updateLimits,
    }),
    [
      setBulkEditLimitObj, //
      updateInfo, //
      setShowReqMgrModal, //
      setShowAddLeaveModal, //
      setShowEditUser, //
      setSeeUserID, //
      setShowUserReqFromNav, //
      setShowViewTeamDayModal, //
      updateCalComments, //
      setUpdateCalComments, //
      checkUserState, //
      updateLimits,
    ]
  );

  let [jtLimDsArr, setJtLimDsArr] = useState([]);
  let [jtLimIndexArr, setJtLimIndexArr] = useState([]);

  let [jtLimActive, setJtLimActive] = useState(false);
  let [todayDs, setTodayDs] = useState(
    `${dateStringer
      .createStringFromTimestamp(new Date().getTime())
      .substr(0, 11)}H00M00`
  );

  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [ds, setDs] = useState(
    dateStringer.createStringFromTimestamp(new Date().getTime())
  );

  let imgPreload = (
    <div className="imagesHidden">
      {/* Previously added images */}
      {/* ... */}

      {/* Additional images */}
      <img
        src={popup2}
        alt="White cog"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={schedLimitPowerOff}
        alt="White cog"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={limArrow}
        alt="White cog"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={schedLimitMinus}
        alt="White cog"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={schedLimitPlus}
        alt="White cog"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteCog}
        alt="White cog"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={commentRota}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={view}
        alt="Rota Clock 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitePower}
        alt="Rota Clock 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 9) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let [hoveredUserID, setHoveredUserID] = useState("");

  let [hoveredIndex, setHoveredIndex] = useState("");

  let [dayLetterArr, setDayLetterArr] = useState([]);
  let [hoveredReqID, setHoveredReqID] = useState({ absence: false, reqID: "" });
  let [limitEnabled, setLimitEnabled] = useState(false);
  let nowObj = new Date();

  let [currMy, setCurrMy] = useState(
    `${nowObj.getMonth() < 10 ? "0" : ""}${nowObj.getMonth()}${JSON.stringify(
      nowObj.getFullYear()
    ).substr(2, 2)}`
  );

  let [jobTitles, setJobTitles] = useState([]);
  let [limitSnake, setLimitSnake] = useState([]);
  const targetRef = useRef(null);
  const containerRef = useRef(null);
  let nullRef = useRef();

  const handleScroll = () => {
    if (
      !loading &&
      selectedTeam &&
      selectedMy &&
      targetRef.current &&
      containerRef.current
    ) {
      console.log("-- ", targetRef.current);
      setTimeout(() => {
        if (
          containerRef.current &&
          targetRef.current &&
          currMy === selectedMy
        ) {
          console.log("SCROLLING");

          const scrollTop = containerRef.current.scrollLeft;
          const targetOffset = targetRef.current.offsetLeft;
          const position = targetOffset - scrollTop;
          containerRef.current.scrollLeft = position - (mob ? 210 : 210);
        } else {
          if (containerRef.current) {
            containerRef.current.scrollLeft = 0;
          }
        }
      }, 250);
    }
  };

  useEffect(() => {
    // clean up not needed
    if (!loading) {
      handleScroll();
    }
  }, [loading, selectedMy, selectedTeam]);
  // let [teams, setTeams] = useState([]);
  let [dayComments, setDayComments] = useState([]);

  useEffect(() => {
    // cleaned
    const cancelSource1 = axios.CancelToken.source();

    if (!myArr[0]) {
      axios
        .post(
          `${serverURL}/get-available-years`,
          {
            userOnly: false,
            getMyArr: true,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            if (response.data.my1 && response.data.myArrLength) {
              if (!response.data.my2) {
                setMyArr([response.data.my1]);
              } else {
                let arr = [];
                let dateObj = new Date();
                dateObj.setMonth(parseInt(response.data.my1.substr(0, 2)));
                dateObj.setFullYear(
                  parseInt(`20${response.data.my1.substr(2, 2)}`)
                );
                for (var i = 0; i < response.data.myArrLength; i++) {
                  arr.push(
                    `${
                      dateObj.getMonth() < 10 ? "0" : ""
                    }${dateObj.getMonth()}${JSON.stringify(
                      dateObj.getFullYear()
                    ).substr(2, 2)}`
                  );
                  dateObj.setMonth(dateObj.getMonth() + 1);
                }

                setMyArr(arr);
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  // let teamsBar = useRef();

  let shortenJt = (jt) => {
    if (jt && jt.length > 40) {
      return `${jt.substr(0, 23)}...`;
    } else {
      return jt;
    }
  };

  let [closedDayNumbers, setClosedDayNumbers] = useState([]);
  let [pHolsSnake, setPHolsSnake] = useState([]);
  let [closedDayNums, setClosedDayNums] = useState([]); // from spec days and phols only
  let [todayDayNum, setTodayDayNum] = useState(0);
  let [dateArr, setDateArr] = useState([]);
  let [dsArr, setDsArr] = useState([]);
  let [commentsExist, setCommentsExist] = useState(false);
  let [commentsSnake, setCommentsSnake] = useState([]);

  useEffect(() => {
    if (memoVals.updateCalComments && memoVals.updateCalComments.ds) {
      let newArr = [];

      if (memoVals.updateCalComments.adding) {
        commentsSnake.forEach((x) => {
          if (x.ds === memoVals.updateCalComments.ds) {
            let newObj = {
              comments: x.comments + 1,
              ds: memoVals.updateCalComments.ds,
            };
            newArr.push(newObj);
          } else {
            newArr.push(x);
          }
        });
      }

      if (memoVals.updateCalComments.removing) {
        commentsSnake.forEach((x) => {
          if (x.ds === memoVals.updateCalComments.ds) {
            let newObj = {
              comments: memoVals.updateCalComments.noMoreComments
                ? 0
                : x.comments - 1,
              ds: memoVals.updateCalComments.ds,
            };
            newArr.push(newObj);
          } else {
            newArr.push(x);
          }
        });
      }

      setCommentsSnake(newArr);
      memoVals.setUpdateCalComments((x) => false);
    }
  }, [memoVals.updateCalComments]);

  let generateComments = commentsSnake.map((x) => {
    return (
      <div
        className={`calCom ${x.comments ? "commentDivActive" : ""} ${
          limitEnabled
            ? `calCommDivLimEnabled ${
                jtLimDsArr.includes(x.ds) ? "jtLimWidth" : ""
              }`
            : ""
        }`}
        onClick={() => {
          if (x.comments) {
            setShowDayCommentModal({
              show: true,
              isMgr: true,
              teamID: selectedTeam,
              ds: x.ds,
            });
          }
        }}
      >
        {x.comments ? (
          <img src={commentRota} alt="Comment" className="calCommentIcon"></img>
        ) : (
          <img
            onClick={() => {
              setShowDayCommentModal({
                show: true,
                isMgr: true,
                teamID: selectedTeam,
                ds: x.ds,
              });
            }}
            src={commentRota}
            alt="Comment"
            className="calCommentIcon commentFade"
          ></img>
        )}
      </div>
    );
  });
  useEffect(() => {
    // cleaned
    setLoading(true);

    const cancelSource1 = axios.CancelToken.source();

    // console.log("USING ", selectedTeam, selectedMy, setSelectedTe1am);
    if (selectedTeam && selectedMy) {
      axios
        .post(
          `${serverURL}/get-team-cal`,
          {
            teamID: selectedTeam,
            my: selectedMy,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            getLeaveTypes: !isMgr || leaveTypesArr[0] ? false : true,
            filter: selectedType,
            mgr: isMgr,
            // getDayComments: dayComments[0] ? false : true,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            if (!isMgr || leaveTypesArr[0]) {
            } else {
              if (setLeaveTypesArr) {
                setLeaveTypesArr(response.data.leaveTypes);
              }
            }

            // if (!dayComments[0]) {
            //   setDayComments(response.data.dayComments);
            // }
            if (isMgr) {
              setLimitEnabled(response.data.limitEnabled);
            }
            let dateObj = new Date();
            dateObj.setMonth(parseInt(selectedMy.substr(0, 2)));
            dateObj.setFullYear(`20${parseInt(selectedMy.substr(2, 2))}`);
            dateObj.setDate(1);
            dateObj.setHours(0, 0, 0, 0);
            let arrOfDs = [];
            setDs(dateStringer.createStringFromTimestamp(dateObj.getTime()));
            let cdn = response.data.closedDaysArr || [];
            let cdn2 = [];
            let arr = [];

            let dayArr = [];
            let dateArrForDayViewer = [];
            if (
              response.data.numberOfDays &&
              typeof response.data.numberOfDays === "number"
            ) {
              for (var i = 0; i < response.data.numberOfDays; i++) {
                dateArrForDayViewer.push(dateObj.getTime());

                arrOfDs.push(
                  dateStringer.createStringFromTimestamp(dateObj.getTime())
                );
                dateArr.push(dateObj.getDate());
                if (cdn.includes(dateObj.getDay())) {
                  cdn2.push(dateObj.getDate());
                }
                if (dateObj.getDay() === 0) {
                  arr.push(
                    response.data.limitEnabled && isMgr ? `${i + 1} Sun` : "S"
                  );
                }

                if (dateObj.getDay() === 1) {
                  arr.push(
                    response.data.limitEnabled && isMgr ? `${i + 1} Mon` : "M"
                  );
                }

                if (dateObj.getDay() === 2) {
                  arr.push(
                    response.data.limitEnabled && isMgr ? `${i + 1} Tue` : "T"
                  );
                }

                if (dateObj.getDay() === 3) {
                  arr.push(
                    response.data.limitEnabled && isMgr ? `${i + 1} Wed` : "W"
                  );
                }

                if (dateObj.getDay() === 4) {
                  arr.push(
                    response.data.limitEnabled && isMgr ? `${i + 1} Thu` : "T"
                  );
                }

                if (dateObj.getDay() === 5) {
                  arr.push(
                    response.data.limitEnabled && isMgr ? `${i + 1} Fri` : "F"
                  );
                }

                if (dateObj.getDay() === 6) {
                  arr.push(
                    response.data.limitEnabled && isMgr ? `${i + 1} Sat` : "S"
                  );
                }

                dateObj.setDate(dateObj.getDate() + 1);
              }
            }
            setLimitSnake(response.data.limitSnake);
            setDsArr(arrOfDs);
            let jtActive = false;
            let jtLimDs = [];

            let jtLimIndex = [];
            response.data.limitSnake.forEach((x, i) => {
              if (x.jt) {
                jtLimDs.push(x.ds);
                jtLimIndex.push(i);
                jtActive = true;
              }
            });

            setJtLimDsArr(jtLimDs);
            setJtLimIndexArr(jtLimIndex);
            setJtLimActive(jtActive);
            setDayLetterArr(arr);
            setDateArr(dateArrForDayViewer);
            if (response.data.commentsArr) {
              let comFilt = response.data.commentsArr.filter((x) => {
                return x.comments > 0;
              });

              if (comFilt[0]) {
                setCommentsExist(true);
              } else {
                if (commentsExist) {
                  setCommentsExist(true);
                }
              }
            }
            setCommentsSnake(response.data.commentsArr);

            let pHolsArrPre = [];
            dateArr.forEach((dayNum) => {
              let pHolsFilt = response.data.pHolsArr
                ? response.data.pHolsArr.filter((item) => {
                    return item.date === dayNum;
                  })
                : [];

              let specDaysFilt = response.data.specDaysArr
                ? response.data.specDaysArr.filter((item) => {
                    return item.date === dayNum;
                  })
                : [];

              pHolsArrPre.push({
                dayNum: parseInt(dayNum),
                pHolTitle: pHolsFilt[0] ? pHolsFilt[0].title : "",
                specDayTitle: specDaysFilt[0] ? specDaysFilt[0].title : "",
              });
            });

            setPHolsSnake(pHolsArrPre);

            setJobTitles(response.data.output);

            let closedDayNumsFromPHolsAndSpecDays = [];
            let dateObj2 = new Date();
            dateObj2.setMonth(parseInt(selectedMy.substr(0, 2)));
            dateObj2.setFullYear(`20${parseInt(selectedMy.substr(2, 2))}`);
            dateObj2.setDate(1);
            dateObj2.setHours(0, 0, 0, 0);
            if (
              response.data.numberOfDays &&
              typeof response.data.numberOfDays === "number"
            ) {
              for (var i = 0; i < response.data.numberOfDays; i++) {
                let pHolSpecDayFilt = pHolsArrPre.filter((item) => {
                  return (
                    item.dayNum === i + 1 &&
                    (item.pHolTitle || item.specDayTitle)
                  );
                });

                if (pHolSpecDayFilt[0]) {
                  closedDayNumsFromPHolsAndSpecDays.push(i + 1);
                }

                dateObj.setDate(dateObj.getDate() + 1);
              }
            }
            setClosedDayNums(closedDayNumsFromPHolsAndSpecDays);
            setClosedDayNumbers(cdn2);
            setTodayDayNum(response.data.todayDayNum);
            // setLoading(false);
            if (dataLoaded) {
              setDataLoaded(false);
            }
            setDataLoaded(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [
    selectedMy,
    selectedTeam,
    selectedType,
    limitEnabled,
    memoVals.updateInfo,
    memoVals.updateLimits,
  ]);

  let getLim = (ds, jtData, jt) => {
    let jtFilt = jtData.filter((x) => {
      return x.jt === jt && x.active;
    });
    if (!jtFilt[0]) {
      return (
        <div className={`calJtBox`}>
          <p className="calJtBoxTxt">No limit</p>
          <img
            src={schedLimitPowerOff}
            alt="Job title limit"
            className="jtLimitPowerIcon"
            onClick={() => {
              axios
                .post(
                  `${serverURL}/activate-and-edit-sched-limit`,
                  {
                    teamID: selectedTeam,
                    ds: ds,
                    jobTitle: jt,
                    activate: true,
                    newMaxOff: 2,
                    plusOne: false,
                    deductOne: false,
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message.includes("logout")) {
                    window.location.href = "/signin";
                  }
                  if (response.data.message === "success") {
                    // here we go
                    axios
                      .post(
                        `${serverURL}/get-curr-off-in-team`,
                        {
                          teamID: selectedTeam,
                          ds: ds,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((r) => {
                        if (r.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (r.data.message === "success") {
                          let newArr = [];
                          limitSnake.forEach((x) => {
                            if (x.ds === ds) {
                              newArr.push({
                                ds: ds,
                                team: false,
                                jt: true,
                                currOff: r.data.totalOffInTeam,
                                maxOff: r.data.maxOff,
                                jtData: r.data.jtArr,
                              });
                            } else {
                              newArr.push(x);
                            }
                          });
                          setLimitSnake(newArr);
                        }
                      });
                  }
                });
            }}
          />
        </div>
      );
    } else {
      return (
        <div className={`jtUserSchedLimitDiv spaceBetween marginLeft0`}>
          <div className="calJtBtnsDiv">
            <img
              src={schedLimitMinus}
              alt="Deduct one"
              className={`calJtBtnMinus ${
                jtFilt[0].maxOff < 1 ? "x9ood23" : ""
              }`}
              onClick={() => {
                if (jtFilt[0].maxOff >= 1) {
                  axios
                    .post(
                      `${serverURL}/activate-and-edit-sched-limit`,
                      {
                        teamID: selectedTeam,
                        ds: ds,
                        jobTitle: jt,
                        activate: true,
                        newMaxOff: jtFilt[0].maxOff - 1,
                        plusOne: false,
                        deductOne: true,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        // here we go
                        axios
                          .post(
                            `${serverURL}/get-curr-off-in-team`,
                            {
                              teamID: selectedTeam,
                              ds: ds,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((r) => {
                            if (r.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (r.data.message === "success") {
                              let newArr = [];
                              limitSnake.forEach((x) => {
                                if (x.ds === ds) {
                                  newArr.push({
                                    ds: ds,
                                    team: false,
                                    jt: true,
                                    currOff: r.data.totalOffInTeam,
                                    maxOff: r.data.maxOff,
                                    jtData: r.data.jtArr,
                                  });
                                } else {
                                  newArr.push(x);
                                }
                              });
                              setLimitSnake(newArr);
                            }
                          });
                      }
                    });
                }
              }}
            />

            <p
              className={`calJtBtnsMidTxt ${
                jtFilt[0].currOff > jtFilt[0].maxOff
                  ? "limitOKMaxOff xj1389"
                  : ""
              }`}
            >
              {jtFilt[0].currOff} <span className="offSmall">off</span> /{" "}
              {jtFilt[0].maxOff}
            </p>
            <img
              src={schedLimitPlus}
              alt="Deduct one"
              className="calJtBtnMinus"
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/activate-and-edit-sched-limit`,
                    {
                      teamID: selectedTeam,
                      ds: ds,
                      jobTitle: jt,
                      activate: true,
                      newMaxOff: jtFilt[0].maxOff + 1,
                      plusOne: true,
                      deductOne: false,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message.includes("logout")) {
                      window.location.href = "/signin";
                    }
                    if (response.data.message === "success") {
                      // here we go
                      axios
                        .post(
                          `${serverURL}/get-curr-off-in-team`,
                          {
                            teamID: selectedTeam,
                            ds: ds,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((r) => {
                          if (r.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (r.data.message === "success") {
                            let newArr = [];
                            limitSnake.forEach((x) => {
                              if (x.ds === ds) {
                                newArr.push({
                                  ds: ds,
                                  team: false,
                                  jt: true,
                                  currOff: r.data.totalOffInTeam,
                                  maxOff: r.data.maxOff,
                                  jtData: r.data.jtArr,
                                });
                              } else {
                                newArr.push(x);
                              }
                            });
                            setLimitSnake(newArr);
                          }
                        });
                    }
                  });
              }}
            />
          </div>
          <img
            src={schedLimitPowerOff}
            alt="Job title limit"
            className="jtLimitPowerIcon jtPowerLimOn"
            onClick={() => {
              axios
                .post(
                  `${serverURL}/activate-and-edit-sched-limit`,
                  {
                    teamID: selectedTeam,
                    ds: ds,
                    jobTitle: jt,
                    activate: false,
                    newMaxOff: 2,
                    plusOne: false,
                    deductOne: false,
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message.includes("logout")) {
                    window.location.href = "/signin";
                  }
                  if (response.data.message === "success") {
                    // here we go
                    axios
                      .post(
                        `${serverURL}/get-curr-off-in-team`,
                        {
                          teamID: selectedTeam,
                          ds: ds,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((r) => {
                        if (r.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (r.data.message === "success") {
                          let newArr = [];
                          limitSnake.forEach((x) => {
                            if (x.ds === ds) {
                              newArr.push({
                                ds: ds,
                                team: false,
                                jt: true,
                                currOff: r.data.totalOffInTeam,
                                maxOff: r.data.maxOff,
                                jtData: r.data.jtArr,
                              });
                            } else {
                              newArr.push(x);
                            }
                          });
                          setLimitSnake(newArr);
                        }
                      });
                  }
                });
            }}
          />
        </div>
      );
    }
    // if (jtData[0]) {
    //   let jtFilt = jtData.filter((x) => {
    //     return x.jt === jt;
    //   });
    //   if (jtFilt[0]) {
    //     return jtFilt[0].jt;
    //   }
    //   // return "data here";
    // }
    // return ds;
  };
  // master return
  return (
    <Fragment>
      {" "}
      {loading ? (
        <img
          src={horizontalBalls}
          alt="loading"
          className="blueLoadingReqs c22"
        />
      ) : (
        ""
      )}
      {!loading ? (
        <div
          className={`staffCalJTcolumn ${
            jobTitles.length === 0 ? "noBorder" : ""
          } ${mob ? "paddingLeft10_" : ""} ${loading ? "none" : ""}`}
        >
          {jobTitles.map((jt, i) => {
            return (
              <div className="staffCalJtItem" key={jt.jt}>
                {i !== 0 ? (
                  <div
                    className={`staffCalJtDivider ${
                      limitEnabled && isMgr ? "navyDivider" : ""
                    }`}
                  ></div>
                ) : (
                  ""
                )}
                {i === 0 ? (
                  <div
                    className={`pHolsRowA1 ${!isMgr ? "invis" : ""} ${
                      commentsExist || !commentsExist
                        ? `commentsExistA1 ${
                            limitEnabled
                              ? isMgr
                                ? "commentsExistA1limitEnabled"
                                : ""
                              : isMgr
                              ? "pHolsRowA1LimitEnabled"
                              : ""
                          }`
                        : limitEnabled
                        ? "limEnableNoComms"
                        : ""
                    }`}
                  >
                    {isMgr && (
                      <div className="staffCalLimLeft">
                        <p className="staffCalLimTxt" onClick={() => {}}>
                          Limits
                        </p>
                        <p className="staffCalDisabledIndicate">
                          {limitEnabled ? "Enabled" : "Disabled"}
                        </p>
                      </div>
                    )}
                    <div
                      className={`staffCalLimRight ${
                        limitEnabled && mob ? "x183291x29191" : ""
                      }`}
                    >
                      <img
                        src={whiteCog}
                        className={!limitEnabled ? "none" : "x29283824122"}
                        alt="Limit settings"
                        onClick={() => {
                          memoVals.setBulkEditLimitObj((x) => {
                            return {
                              teamID: selectedTeam,
                              monWeek: ds,
                            };
                          });
                        }}
                      />
                      <img
                        src={whitePower}
                        // src={state.hideSchedLimits ? dayLimitPower1 : dayLimitPower2}
                        className={`${
                          !limitEnabled
                            ? "limitTabPowerIconOff x1738h121"
                            : `limitTabPowerIcon ${mob ? "marginLeft0" : ""}`
                        }`}
                        alt="Activate limits"
                        onClick={() => {
                          let hideIt = false;
                          if (limitEnabled) {
                            hideIt = true;
                          }
                          axios
                            .post(
                              `${serverURL}/toggle-hide-sched-limits-for-team`,
                              {
                                data: hideIt,
                                teamID: selectedTeam,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                let hiding = response.data.data;
                                if (hiding) {
                                  setLimitEnabled(false);
                                } else {
                                  setLimitEnabled(true);
                                }
                                // setLimitEnabled(response.data.data);
                              }
                            });
                        }}
                      />
                    </div>
                    {/* <p
                      className="toggler"
                      onClick={() => {
                        // toggle-hide-sched-limits-for-team
                        let hideIt = false;
                        if (limitEnabled) {
                          hideIt = true;
                        }
                        axios
                          .post(
                            `${serverURL}/toggle-hide-sched-limits-for-team`,
                            {
                              data: hideIt,
                              teamID: selectedTeam,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              let hiding = response.data.data;
                              if (hiding) {
                                setLimitEnabled(false);
                              } else {
                                setLimitEnabled(true);
                              }
                              // setLimitEnabled(response.data.data);
                            }
                          });
                      }}
                    >
                      toggle lim
                    </p> */}
                  </div>
                ) : (
                  ""
                )}
                <p
                  className={`staffCalJobTitleTitle ${
                    !isMgr ? "colour0077ff" : ""
                  } ${
                    limitEnabled && jtLimActive
                      ? "staffCalJtPushDownJtLimActive"
                      : ""
                  }`}
                >
                  {shortenJt(jt.jt)}
                </p>

                <div className="staffCalUsers">
                  {jt.users.map((usr, ind) => {
                    return (
                      <div
                        key={ind}
                        onMouseEnter={() => {
                          if (!mob) {
                            setHoveredUserID(usr.userID);
                          }
                        }}
                        onMouseLeave={() => {
                          if (!mob) {
                            setHoveredUserID("");
                          }
                        }}
                        onClick={() => {
                          if (isMgr) {
                            memoVals.setShowEditUser((x) => usr.userID);
                          } else {
                            memoVals.setSeeUserID((x) => {
                              return {
                                userID: usr.userID,
                                openedFromUserRota: false,
                              };
                            });
                          }
                        }}
                        className={`staffCalUsrBox ${
                          hoveredUserID === usr.userID && !mob
                            ? "hoveredUserCalBg"
                            : memoVals.checkUserState.userID === usr.userID &&
                              !isMgr
                            ? "meUserTeamCal"
                            : ""
                        }`}
                      >
                        {usr.profPicUrl ? (
                          <img
                            src={usr.profPicUrl}
                            alt={usr.fName}
                            className="staffCalProPic"
                          />
                        ) : (
                          <div className="viewTeamInitials">
                            {usr.fName[0]}
                            {usr.lName[0]}
                          </div>
                        )}
                        <p
                          className={`staffCalUsrName ${
                            mob ? "paddingLeft4" : ""
                          } `}
                        >
                          {usr.fName}
                          <br />
                          {usr.lName}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
      {!loading ? (
        <div className="staffCalBody" ref={containerRef}>
          <div className="viewTeamDayRow">
            {dateArr.map((d) => {
              return (
                <div
                  className={`viewTeamDivItem ${
                    limitEnabled
                      ? `viewTeamDivItemLimEnabled ${
                          jtLimDsArr.includes(
                            dateStringer.createStringFromTimestamp(d)
                          )
                            ? "jtLimDsViewWidth"
                            : ""
                        } `
                      : ""
                  }`}
                  onClick={() => {}}
                >
                  Day view
                </div>
              );
            })}
          </div>

          {limitEnabled && isMgr ? (
            <div className="calLimitsRow">
              {limitSnake.map((lim, i) => {
                return (
                  <div
                    className={`calLimItem ${
                      limitEnabled
                        ? `calLimItemLimEnabled ${lim.jt ? "jtLimWidth" : ""} ${
                            closedDayNumbers.includes(i + 1)
                              ? `closedLimBg `
                              : ""
                          }`
                        : ""
                    } ${i + 1 === todayDayNum ? "todayItemCalHeader" : ""}`}
                  >
                    {/* <p className="staffCalLimTitle">Leave limit</p> */}

                    <div className="calLimPowerAndDrop">
                      <img
                        src={schedLimitPowerOff}
                        alt="Limits"
                        className={`calLimitPowerImg ${
                          lim.jt || lim.team ? "calLimPowerOn" : "c29838"
                        }`}
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-day-limit-data-for-ds-on-team`,
                              {
                                teamID: selectedTeam,
                                ds: lim.ds,
                                enable: lim.jt || lim.team ? false : true,
                                limitByAll: true,
                                maxAllOff: 2,
                                limitByJobTitle: false,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                setJtLimDsArr(
                                  jtLimDsArr.filter((x) => {
                                    return x !== lim.ds;
                                  })
                                );
                                setJtLimIndexArr(
                                  jtLimIndexArr.filter((x) => {
                                    return x !== i;
                                  })
                                );

                                axios
                                  .post(
                                    `${serverURL}/get-curr-off-in-team`,
                                    {
                                      teamID: selectedTeam,
                                      ds: lim.ds,
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((r) => {
                                    if (r.data.message.includes("logout")) {
                                      window.location.href = "/signin";
                                    }
                                    if (r.data.message === "success") {
                                      let newArr = [];
                                      limitSnake.forEach((x) => {
                                        if (x.ds === lim.ds) {
                                          newArr.push({
                                            ds: lim.ds,
                                            team:
                                              lim.jt || lim.team ? false : true,
                                            currOff: r.data.totalOffInTeam,
                                            maxOff: r.data.maxOff,
                                            jtData: r.data.jtArr,
                                          });
                                        } else {
                                          newArr.push(x);
                                        }
                                      });
                                      setLimitSnake(newArr);
                                    }
                                  });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />

                      {lim.jt || lim.team ? (
                        <select
                          className="calLimDrop"
                          value={lim.jt ? "jt" : "team"}
                          onChange={(e) => {
                            let val = e.target.value;

                            axios
                              .post(
                                `${serverURL}/edit-day-limit-data-for-ds-on-team`,
                                {
                                  teamID: selectedTeam,
                                  ds: lim.ds,
                                  enable: true,
                                  limitByAll:
                                    e.target.value === "team" ? true : false,
                                  maxAllOff: 2,
                                  limitByJobTitle:
                                    e.target.value === "jt" ? true : false,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message.includes("logout")) {
                                  window.location.href = "/signin";
                                }
                                if (response.data.message === "success") {
                                  if (val === "jt") {
                                    if (!limitEnabled) {
                                      setLimitEnabled(true);
                                    }
                                    if (!jtLimActive) {
                                      setJtLimActive(true);
                                    }
                                    setJtLimDsArr([...jtLimDsArr, lim.ds]);
                                    setJtLimIndexArr([...jtLimIndexArr, i]);
                                  }
                                  if (val === "team") {
                                    if (!limitEnabled) {
                                      setLimitEnabled(true);
                                    }

                                    let jtFilt = jtLimDsArr.filter((x) => {
                                      return x !== lim.ds;
                                    });
                                    setJtLimDsArr(jtFilt);

                                    if (!jtFilt[0]) {
                                      setJtLimActive(false);
                                    }
                                    setJtLimIndexArr(
                                      jtLimIndexArr.filter((x) => {
                                        return x !== i;
                                      })
                                    );
                                  }
                                  axios
                                    .post(
                                      `${serverURL}/get-curr-off-in-team`,
                                      {
                                        teamID: selectedTeam,
                                        ds: lim.ds,
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((r) => {
                                      if (r.data.message.includes("logout")) {
                                        window.location.href = "/signin";
                                      }
                                      if (r.data.message === "success") {
                                        // update limit arr
                                        let newArr = [];
                                        limitSnake.forEach((x) => {
                                          if (x.ds === lim.ds) {
                                            newArr.push({
                                              ds: lim.ds,
                                              team:
                                                val === "team" ? true : false,
                                              jt: val === "jt" ? true : false,
                                              currOff: r.data.totalOffInTeam,
                                              maxOff: r.data.maxOff,
                                              jtData: r.data.jtArr,
                                            });
                                          } else {
                                            newArr.push(x);
                                          }
                                        });
                                        setLimitSnake(newArr);
                                      }
                                    });
                                }
                              });
                          }}
                        >
                          <option value="team">Team</option>{" "}
                          <option value="jt">Job</option>
                        </select>
                      ) : (
                        ""
                      )}
                    </div>

                    <p
                      className={`calDayLimInfo ${
                        lim.jt ? "calDayLimInfoJt" : ""
                      }`}
                    >
                      {/* {lim.ds.substr(9, 2)}{" "} */}
                      {lim.team
                        ? "Limit:"
                        : lim.jt
                        ? "Limited by job titles below"
                        : "No limit for this day"}
                    </p>
                    {lim.jt ? (
                      <img
                        src={limArrow}
                        className="limArrow"
                        alt="Limited by job roles below"
                      />
                    ) : (
                      ""
                    )}
                    {lim.team ? (
                      <div className={`dayLimRight `}>
                        <img
                          src={schedLimitMinus}
                          className={`dayLimPlus  ${
                            lim.maxOff === 0 ? "x9ood23" : ""
                          } schedLimitMinus`}
                          onClick={() => {
                            axios
                              .post(
                                `${serverURL}/edit-day-limit-data-for-ds-on-team`,
                                {
                                  teamID: selectedTeam,
                                  ds: lim.ds,
                                  enable: true,
                                  limitByAll: true,
                                  maxAllOff:
                                    lim.maxOff - 1 < 0 ? 0 : lim.maxOff - 1,
                                  limitByJobTitle: false,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message.includes("logout")) {
                                  window.location.href = "/signin";
                                }
                                if (response.data.message === "success") {
                                  setJtLimDsArr(
                                    jtLimDsArr.filter((x) => {
                                      return x !== lim.ds;
                                    })
                                  );
                                  setJtLimIndexArr(
                                    jtLimIndexArr.filter((x) => {
                                      return x !== i;
                                    })
                                  );
                                  let newArr = [];
                                  limitSnake.forEach((x) => {
                                    if (x.ds === lim.ds) {
                                      newArr.push({
                                        ds: lim.ds,
                                        team: true,
                                        currOff: lim.currOff,
                                        maxOff:
                                          lim.maxOff - 1 < 0
                                            ? 0
                                            : lim.maxOff - 1,
                                      });
                                    } else {
                                      newArr.push(x);
                                    }
                                  });

                                  setLimitSnake(newArr);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }}
                        />
                        <div
                          className={`dayLimLimitValue colorWhite ${
                            lim.maxoff < lim.currOff ? "limitExceedDayRed" : ""
                          }`}
                        >
                          {" "}
                          <p
                            className={`dayLimCurrOffTxt ${
                              lim.maxoff < lim.currOff ? "" : "none"
                            }`}
                          >
                            Limit:
                          </p>
                          {lim.maxOff}{" "}
                        </div>

                        <img
                          src={schedLimitPlus}
                          className={`dayLimPlus schedLimitMinus ${
                            lim.maxoff < lim.currOff ? "limitColourPlusBtn" : ""
                          }`}
                          onClick={() => {
                            axios
                              .post(
                                `${serverURL}/edit-day-limit-data-for-ds-on-team`,
                                {
                                  teamID: selectedTeam,
                                  ds: lim.ds,
                                  enable: true,
                                  limitByAll: true,
                                  maxAllOff: lim.maxOff + 1,
                                  limitByJobTitle: false,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message.includes("logout")) {
                                  window.location.href = "/signin";
                                }
                                if (response.data.message === "success") {
                                  setJtLimDsArr(
                                    jtLimDsArr.filter((x) => {
                                      return x !== lim.ds;
                                    })
                                  );
                                  setJtLimIndexArr(
                                    jtLimIndexArr.filter((x) => {
                                      return x !== i;
                                    })
                                  );
                                  let newArr = [];
                                  limitSnake.forEach((x) => {
                                    if (x.ds === lim.ds) {
                                      newArr.push({
                                        ds: lim.ds,
                                        team: true,
                                        currOff: lim.currOff,
                                        maxOff: lim.maxOff + 1,
                                      });
                                    } else {
                                      newArr.push(x);
                                    }
                                  });

                                  setLimitSnake(newArr);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {lim.team ? (
                      <p
                        className="calDayLimInfo xi138187"
                        onClick={() => {
                          console.log({ lim });
                        }}
                      >
                        Currently off:
                      </p>
                    ) : (
                      ""
                    )}

                    {lim.team ? (
                      <div
                        className={`calTeamCurrOff ${
                          lim.currOff > lim.maxOff
                            ? "calTeamCurrOffLimExceeded"
                            : ""
                        }`}
                      >
                        {lim.currOff}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
          <div className="calCommentsRow">{generateComments}</div>

          {jobTitles.map((jt, i) => {
            return (
              <div className="staffCalJtItem" key={i}>
                {i !== 0 ? (
                  <div
                    className={`staffCalJtDivider2 ${
                      limitEnabled && isMgr ? "navyDivider" : ""
                    }`}
                  ></div>
                ) : (
                  <div className={`pHolStaffCalRow ${mob ? "bgMgrGrey" : ""}`}>
                    {dayLetterArr.map((ltr, i) => {
                      let closedFilt = pHolsSnake.filter((x) => {
                        return (
                          x.dayNum === i + 1 && (x.pHolTitle || x.specDayTitle)
                        );
                      });

                      let title = closedFilt[0]
                        ? closedFilt[0].pHolTitle || closedFilt[0].specDayTitle
                        : "";

                      return (
                        <p
                          className={`staffCalTeamSnakeBlock staffCalDayLtrItem  ${
                            i + 1 === todayDayNum
                              ? `todayStaffCalSnake ${
                                  limitEnabled ? "xx372874821" : ""
                                }`
                              : ""
                          } ${!isMgr ? "cursorDefault_" : ""} ${
                            i + 1 === todayDayNum ? "ssss" : ""
                          } ${
                            limitEnabled
                              ? `staffCalDayLtrItemLimEnabled  ${
                                  jtLimIndexArr.includes(i) ? "jtLimWidth" : ""
                                }`
                              : ""
                          } ${
                            closedDayNumbers.includes(i + 1)
                              ? `closedStaffCalBlockBg closedTxtColour x237858728128121 ${
                                  i + 1 === todayDayNum ? "x10839242782d2" : ""
                                } ${
                                  !isMgr ? "isMgrClosedStaffCalBlockBg" : ""
                                } ${
                                  closedFilt[0]
                                    ? "teamCalClosedDayYesCl"
                                    : "teamCalClosedDayNoCl"
                                }`
                              : ""
                          } dayClosedStatusHeight ${
                            closedFilt[0]
                              ? "teamCalClosedDayYes"
                              : "teamCalClosedDayNo"
                          } noBorder`}
                          key={i}
                          onClick={() => {
                            if (closedFilt[0]) {
                              setShowClosedDay(title);
                            }
                          }}
                        >
                          {closedFilt[0] ? "Closed" : ""}
                        </p>
                      );
                    })}
                    {/* {pHolsSnake.map((ph, index) => {
                      let commentExists = false;
                      let commsFilt =
                        dayComments && dayComments[0]
                          ? dayComments.filter((item) => {
                              return item.d === index + 1;
                            })
                          : [];

                      if (commsFilt[0] && commsFilt[0].c) {
                        commentExists = commsFilt[0].c;
                      }
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            if (ph.pHolTitle || ph.specDayTitle) {
                              setShowClosedDay(ph.pHolTitle || ph.specDayTitle);
                            } else {
                            }
                          }}
                          className={`phSnakeItem ${
                            ph.pHolTitle || ph.specDayTitle || commentExists
                              ? ""
                              : "invis"
                          }`}
                        >
                          {ph.pHolTitle || ph.specDayTitle ? (
                            "Closed"
                          ) : commentExists ? (
                            <div className={`calCommDiv`}>
                              <img
                                src={comment}
                                alt="Comments"
                                className="staffCalCommentIcon"
                              />
                              {commentExists}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })} */}
                  </div>
                )}

                <div className="staffCalUsrSnakeDivDayStrip">
                  {dayLetterArr.map((ltr, i) => {
                    return (
                      <p
                        ref={i + 1 === todayDayNum ? targetRef : nullRef}
                        className={`staffCalTeamSnakeBlock staffCalDayLtrItem ${
                          i + 1 === todayDayNum
                            ? `todayStaffCalSnake ${
                                limitEnabled ? "xx372874821" : ""
                              }`
                            : ""
                        } ${!isMgr ? "cursorDefault_" : ""} ${
                          i + 1 === todayDayNum ? "ssss" : ""
                        } ${
                          limitEnabled
                            ? `staffCalDayLtrItemLimEnabled ${
                                jtLimIndexArr.includes(i) ? "jtLimWidth" : ""
                              }`
                            : ""
                        } ${
                          closedDayNumbers.includes(i + 1)
                            ? `closedStaffCalBlockBg closedTxtColour x237858728128121 ${
                                i + 1 === todayDayNum ? "x10839242782d2" : ""
                              } ${!isMgr ? "isMgrClosedStaffCalBlockBg" : ""}`
                            : ""
                        }`}
                        key={i}
                        onClick={() => {
                          console.log(dsArr);
                          memoVals.setShowViewTeamDayModal((x) => {
                            return {
                              teamID: selectedTeam,
                              ds: dsArr[i],
                              isMgr,
                            };
                          });
                        }}
                      >
                        {ltr}{" "}
                        <img
                          src={popup2}
                          alt="View day"
                          className="viewDayPopUp"
                        />
                      </p>
                    );
                  })}
                </div>
                {limitEnabled && jtLimActive ? (
                  <div className="jtLimRow">
                    {limitSnake.map((lim, i) => {
                      return (
                        <div
                          className={`jtLimBox ${
                            closedDayNumbers.includes(i + 1)
                              ? `closedStaffCalBlockBg ${
                                  !isMgr ? "isMgrClosedStaffCalBlockBg" : ""
                                }`
                              : ""
                          } ${lim.ds === todayDs ? "todayStaffCalSnake" : ""} ${
                            jtLimDsArr.includes(lim.ds) ? "jtLimWidth" : ""
                          }`}
                          onClick={() => {
                            console.log(lim);
                            // console.log("jt:", jt);
                          }}
                        >
                          {lim.jt && getLim(lim.ds, lim.jtData || [], jt.jt)}
                          {/* currOff: "Unknown" */}
                          {/* ds:"Y2023M10D22H00M00"
jt: true
jtData: Array(4)
0: 
{ds: 'Y2023M10D22H00M00', active: true, maxOff: 0, currOff: 1, jt: 'Clinical Risk Manager'}
maxOff:2
team:false */}
                          {/* Hello */}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
                <div className="staffCalUsers">
                  {jt.users.map((usr) => {
                    return (
                      <div
                        key={usr.userID}
                        className={`staffCalUsrSnakeDiv ${
                          hoveredUserID === usr.userID && !mob
                            ? "hoveredUserCalBg"
                            : ""
                        } ${
                          !isMgr &&
                          memoVals.checkUserState.userID === usr.userID
                            ? "meTeamCalRow"
                            : ""
                        }`}
                      >
                        {usr.snake.map((day, i) => {
                          return (
                            <div
                              key={i}
                              onClick={() => {
                                if (isMgr) {
                                  if (!day.reqID) {
                                    let dateObj = new Date();
                                    dateObj.setMonth(
                                      parseInt(selectedMy.substr(0, 2))
                                    );
                                    dateObj.setFullYear(
                                      parseInt(
                                        `20${parseInt(selectedMy.substr(2, 2))}`
                                      )
                                    );
                                    dateObj.setDate(i + 1);
                                    dateObj.setHours(0, 0, 0, 0);

                                    memoVals.setShowAddLeaveModal((x) => {
                                      return {
                                        show: true,
                                        startDs:
                                          dateStringer.createStringFromTimestamp(
                                            dateObj.getTime()
                                          ),
                                        userID: usr.userID,
                                        leaveTypeID: "annLeave",
                                        fName: usr.fName,
                                      };
                                    });
                                  } else {
                                    memoVals.setShowReqMgrModal(
                                      (x) => day.reqID
                                    );
                                  }
                                }

                                if (!isMgr) {
                                  if (
                                    usr.userID ===
                                      memoVals.checkUserState.userID &&
                                    day.reqID
                                  ) {
                                    // open my req here
                                    memoVals.setShowUserReqFromNav(
                                      (x) => day.reqID
                                    );
                                  }
                                }
                              }}
                              onMouseEnter={() => {
                                if (!mob) {
                                  setHoveredUserID(usr.userID);
                                  setHoveredReqID({
                                    reqID: day.reqID,
                                    absence: day.absence,
                                  });
                                  setHoveredIndex(i);
                                }
                              }}
                              onMouseLeave={() => {
                                if (!mob) {
                                  setHoveredUserID("");
                                  setHoveredReqID({
                                    reqID: "",
                                    absence: false,
                                  });
                                  setHoveredIndex("");
                                }
                              }}
                              className={`staffCalTeamSnakeBlock  ${
                                closedDayNumbers.includes(i + 1)
                                  ? `closedStaffCalBlockBg ${
                                      !isMgr ? "isMgrClosedStaffCalBlockBg" : ""
                                    }`
                                  : ""
                              } ${
                                closedDayNums.includes(i + 1)
                                  ? `closedStaffCalBlockBgPhol ${
                                      isMgr ? "closedHoverer" : ""
                                    }`
                                  : ""
                              } ${
                                !day.reqID
                                  ? isMgr
                                    ? "noItemsSnakeBlock"
                                    : ""
                                  : `reqExistsSnakeBlock ${
                                      hoveredReqID.reqID === day.reqID
                                        ? `  ${
                                            day.reqID === hoveredReqID.reqID &&
                                            (isMgr ||
                                              memoVals.checkUserState.userID ===
                                                usr.userID)
                                              ? "hoveredStaffCalLeave"
                                              : ""
                                          } ${
                                            memoVals.checkUserState.userID ===
                                            usr.userID
                                              ? "cursorPointer"
                                              : ""
                                          }`
                                        : ""
                                    } ${
                                      day.firstOfSeq
                                        ? `firstOfSeq ${
                                            day.abs ? "firstOfSeqAbs" : ""
                                          }`
                                        : ""
                                    }  ${
                                      day.lastOfSeq
                                        ? `lastOfSeq ${
                                            day.abs ? "lastOfSeqAbs" : ""
                                          }`
                                        : ""
                                    } ${
                                      day.soloSeq
                                        ? `soloSeq ${
                                            day.abs ? "soloSeqAbs" : ""
                                          }`
                                        : ""
                                    } ${
                                      day.abs
                                        ? `reqExistsSnakeBlockAbsence ${
                                            day.reqID === hoveredReqID.reqID &&
                                            (isMgr ||
                                              memoVals.checkUserState.userID ===
                                                usr.userID)
                                              ? "hoveredStaffCalAbsence"
                                              : ""
                                          } ${
                                            memoVals.checkUserState.userID ===
                                            usr.userID
                                              ? "cursorPointer"
                                              : ""
                                          } ${
                                            day.lastHalfOff
                                              ? `snakeLastHalfOffAbs ${
                                                  day.reqID ===
                                                    hoveredReqID.reqID &&
                                                  (isMgr ||
                                                    memoVals.checkUserState
                                                      .userID === usr.userID)
                                                    ? "snakeLastHalfOffAbsHovered"
                                                    : ""
                                                }`
                                              : ""
                                          }  ${
                                            day.firstHalfOff
                                              ? `snakeFirstHalfOffAbs ${
                                                  day.reqID ===
                                                    hoveredReqID.reqID &&
                                                  (isMgr ||
                                                    memoVals.checkUserState
                                                      .userID === usr.userID)
                                                    ? "snakeFirstHalfOffAbsHovered"
                                                    : ""
                                                }`
                                              : ""
                                          }`
                                        : `${
                                            day.lastHalfOff
                                              ? `snakeLastHalfOff ${
                                                  day.reqID ===
                                                    hoveredReqID.reqID &&
                                                  (isMgr ||
                                                    memoVals.checkUserState
                                                      .userID === usr.userID)
                                                    ? "snakeLastHalfOffHovered"
                                                    : ""
                                                }`
                                              : ""
                                          }  ${
                                            day.firstHalfOff
                                              ? `snakeFirstHalfOff ${
                                                  day.reqID ===
                                                    hoveredReqID.reqID &&
                                                  (isMgr ||
                                                    memoVals.checkUserState
                                                      .userID === usr.userID)
                                                    ? "snakeFirstHalfOffHovered"
                                                    : ""
                                                }`
                                              : ""
                                          }`
                                    }`
                              } ${
                                i + 1 === todayDayNum
                                  ? `todayStaffCalSnake ${
                                      limitEnabled ? "xx372874821" : ""
                                    }`
                                  : ""
                              }  ${!isMgr ? "cursorDefault" : ""} ${
                                limitEnabled
                                  ? `staffCalTeamSnakeBlockLimEnabled ${
                                      jtLimIndexArr.includes(i)
                                        ? "jtLimWidth"
                                        : ""
                                    }`
                                  : ""
                              } ${hoveredIndex === i ? "hoveredIndexCal" : ""}`}
                            >
                              {i + 1}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </Fragment>
  );
};

export default TeamCalendarInlet;
