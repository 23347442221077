// design and keys done 19 mar 25
import React, {
  useContext,
  useEffect,
  useState,
  useReducer,
  useMemo,
  Fragment,
  useRef,
} from "react";
// cleaned 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";
import "../../styles/leaveRequests.css";

import dateStringer from "../../tools/dateStringer";

// import CheckUser from "../../tools/CheckUser";

import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";

import { UserContext } from "../../contexts/UserContext";
import { RequestContext } from "../../contexts/RequestContext";
import { CalendarContext } from "../../contexts/CalendarContext";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import reqLoader from "../../img/general/reqLoader2.gif";
import editReq from "../../img/general/editReq.svg";
import redTriangle from "../../img/general/redTriangle.svg";
import close from "../../img/modals/close.svg";
import whiteGo from "../../img/general/allowArr.svg";
import plane2 from "../../img/general/plane2.svg";
import addToCal from "../../img/general/addToCal.svg";
import editNavy from "../../img/general/editNavy.svg";
import cancelNavy from "../../img/general/cancelNavy.svg";

import serverURL from "../../serverURL";

import ViewTeamOnRequest from "./ViewTeamOnRequest";

const UserLeaveReqModal = ({ unitReqID, reqIsPast }) => {
  // CheckUser();
  const {
    setShowUserReqFromNav,
    showEditUser,
    setPage,
    setReqsPage,
    setIndicate,
    setUpdatedReq,
    showMyShiftModal,
    updateUserReqs,
    setUpdateUserReqs,
    modalOpen,
    setModalOpen,
    device,
    countData,
    setCountData,
    setShowReqMgrModal,
  } = useContext(DataContext);

  const { fName, lName, currencySymbol, checkUserState } =
    useContext(UserContext);
  const { setUpdatedItem } = useContext(CalendarContext);

  let [canManage, setCanManage] = useState(false);

  let [showLimits, setShowLimits] = useState(false);
  let [rate, setRate] = useState(1);
  const {
    showViewTeamOnRequest,
    setShowViewTeamOnRequest,
    showEditDateModal,
    setShowEditDateModal,
    setShowUserReqModal,
    updateReq,
    setShowReqModal,
  } = useContext(RequestContext);
  const { mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setShowUserReqFromNav,
      showEditUser,
      setPage,
      setReqsPage,
      setIndicate,
      setUpdatedReq,
      showMyShiftModal,
      fName,
      lName,
      currencySymbol,
      checkUserState,
      showViewTeamOnRequest,
      setShowViewTeamOnRequest,
      showEditDateModal,
      setShowEditDateModal,
      setShowUserReqModal,
      updateReq,
      mobModal,
      setShowReqModal,
      updateUserReqs,
      setUpdateUserReqs,

      modalOpen,
      setModalOpen,
      device,
      setShowReqMgrModal,
    }),
    [
      setShowUserReqFromNav, //
      showEditUser, //
      setPage, //
      setReqsPage, //
      setIndicate, //
      setUpdatedReq, //
      showMyShiftModal, //
      fName, //
      lName, //
      currencySymbol, //
      checkUserState, //
      showViewTeamOnRequest, //
      setShowViewTeamOnRequest, //
      showEditDateModal, //
      setShowEditDateModal, //
      setShowUserReqModal, //
      updateReq, //
      mobModal, //
      setShowReqModal,
      updateUserReqs,
      setUpdateUserReqs,

      modalOpen,
      setModalOpen,
      device,
      setShowReqMgrModal,
    ]
  );

  let [showEarnings, setShowEarnings] = useState(false);

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [sureEdit, setSureEdit] = useState(false);
  useEffect(() => {
    if (dataLoaded && imagesLoaded === 9) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={reqLoader}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={cancelNavy}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={editNavy}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={addToCal}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={plane2}
        alt="Cancel Request 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editReq}
        alt="Edit Request"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={redTriangle}
        alt="Red Triangle"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteGo}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [sureDeleteReq, setSureDeleteReq] = useState(false);
  let [deletingReqLoading, setDeletingReqLoading] = useState(false);

  let [showNoteModal, setShowNoteModal] = useState(false);

  let [showCostDiv, setShowCostDiv] = useState(false);
  let shortenHeaderLeaveName = (val) => {
    if (val && val.length > 26) {
      return `${val.substr(0, 23)}...`;
    } else {
      return val;
    }
  };
  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loaded: false,
      staffReq: {},
      startTime: "",
      endTime: "",
      dontShowStartTime: false,
      dontShowEndTime: false,
      approvedReqModal: false,
      reqInfoTitle: "",
      reqSubmitted: 0,
      declinedReqModal: false,
      reqIsPending: false,
      approvedBy: "",
      declinedBy: "",
      declineReason: "",
      excessDuration: 0,
      showPayableOnMyReqs: false,
      reqStartDs: "",
      reqEndDs: "",
      requestStartHalf: false,
      requestEndHalf: false,
      requestDsArr: [],
      contractedDuration: 0,
      deletingReqLoading: false,
      reqID: "",
      reqWorkPattern: "",
      userID: "",
      reqNote: "",
      reqIsPast: reqIsPast,
      cost: 0,
      leaveTypeID: "",
      days: 0,
      costArr: [],
      limitArr: [],
      isAbsence: false,
      totAllow: 0,
      usedMins: 0,
      usedDays: 0,
      remaining: 0,
      userDailyMins: 0,
      sufficient: false,
      year: 0,
      ongoing: false,
    }
  );

  let [totalCost, setTotalCost] = useState(0);

  let [totSal, setTotSal] = useState(0);
  let [includesSalaried, setIncludesSalaried] = useState(false);
  useEffect(() => {
    // clean not needed
    let c = 0;
    let deduct = 0;
    let incSal = false;
    let tSal = 0;
    if (state.costArr && state.costArr[0]) {
      state.costArr.forEach((obj) => {
        if (state.staffReq.unpaid) {
          deduct += obj.cost;
        } else {
          if (!obj.salaried) {
            c += obj.cost;
          } else {
            tSal += obj.cost;
            if (obj.excessOrActual === "excess") {
              c += obj.cost;
            } else {
              incSal = true;
            }
          }
        }
      });
    }

    if (tSal) {
      setTotSal(tSal);
    } else {
      setTotSal(0);
    }
    if (c) {
      setTotalCost(c);
    } else {
      setTotalCost(0);
    }

    if (incSal) {
      setIncludesSalaried(incSal);
    } else {
      setIncludesSalaried(false);
    }
  }, [state]);
  let generateCostDiv = useMemo(() => {
    return state.costArr
      ? state.costArr.map((item, i) => {
          if (
            item.cost > 0 ||
            item.salaried ||
            (!item.cost && !item.salaried && item.excessOrActual === "actual")
          ) {
            return (
              <div key={i} className="costArrRow">
                <p className="costArrTitle">
                  {/* • */}
                  {dateStringer.printedDateFromDs(item.ds)}
                </p>

                {item.cost === 0 && item.salaried ? (
                  <p className="incSal3">Salary</p>
                ) : (
                  <p className="costArrValue">
                    {memoVals.currencySymbol}
                    {item.cost.toFixed(2)}
                    <br />
                    <span className="costContractedExcessSpan">
                      {item.excessOrActual === "actual"
                        ? `${
                            item.mpwAtTheTime === 0
                              ? "based on average"
                              : `${
                                  item.salaried
                                    ? "based on salary"
                                    : "based on hourly rate"
                                }`
                          }`
                        : "from overtime"}
                    </span>
                  </p>
                )}
              </div>
            );
          }
        })
      : "";
  }, [showCostDiv]);

  let update = (deleting, makePending, updatedObj) => {
    if (deleting) {
      memoVals.setUpdatedReq((x) => {
        return {
          delete: true,
          reqID: state.reqID,
          pending: state.reqIsPending,
          approved: state.approvedReqModal,
          declined: state.declinedReqModal,
        };
      });
      memoVals.setShowUserReqModal((x) => "");
      memoVals.setShowUserReqFromNav((x) => "");
    }

    if (makePending) {
      memoVals.setUpdatedReq((x) => {
        return {
          updatedObj,
        };
      });
    }
  };

  let formatDurationWithHalf = (startHalf, endHalf, days) => {
    let numberToReturn = days;
    if (startHalf) {
      numberToReturn -= 0.5;
    }
    if (endHalf) {
      numberToReturn -= 0.5;
    }
    return numberToReturn;
  };

  let formatMonthCalIcon = (val) => {
    if (val === "00") {
      return "Jan";
    } else if (val === "01") {
      return "Feb";
    } else if (val === "02") {
      return "Mar";
    } else if (val === "03") {
      return "Apr";
    } else if (val === "04") {
      return "May";
    } else if (val === "05") {
      return "Jun";
    } else if (val === "06") {
      return "Jul";
    } else if (val === "07") {
      return "Aug";
    } else if (val === "08") {
      return "Sep";
    } else if (val === "09") {
      return "Oct";
    } else if (val === "10") {
      return "Nov";
    } else if (val === "11") {
      return "Dec";
    }
  };

  let formatMinsDurationToHours = (mins) => {
    if (mins === 0) {
      return "None";
    }
    let hours = Math.floor(mins / 60);
    let m = mins % 60;

    let hString = "hr";
    let mString = "m";

    if (m === 0) {
      mString = "";
      m = "";
    } else if (m === 1) {
      mString = "m";
    }

    if (hours === 0) {
      hString = "";
      hours = "";
      mString = "mins";
    } else if (hours === 1) {
      hString = "hr";
    }

    if (!m && hours > 1) {
      hString = " hours";
    } else if (!m && hours === 1) {
      hString = " hour";
    }

    return `${hours}${hString}${hString && mString ? " " : ""}${
      m === "" ? m : Math.round(m)
    }${!hString && mins < 60 ? " " : ""}${mString}`;
  };

  let convertCreated = (ts) => {
    let dateObj = new Date(ts);
    let month = dateObj.getMonth();
    let monthOutput = "";
    if (month === 0) {
      monthOutput = "Jan";
    } else if (month === 1) {
      monthOutput = "Feb";
    } else if (month === 2) {
      monthOutput = "Mar";
    } else if (month === 3) {
      monthOutput = "Apr";
    } else if (month === 4) {
      monthOutput = "May";
    } else if (month === 5) {
      monthOutput = "Jun";
    } else if (month === 6) {
      monthOutput = "Jul";
    } else if (month === 7) {
      monthOutput = "Aug";
    } else if (month === 8) {
      monthOutput = "Sep";
    } else if (month === 9) {
      monthOutput = "Oct";
    } else if (month === 10) {
      monthOutput = "Nov";
    } else if (month === 11) {
      monthOutput = "Dec";
    }
    return `${dateObj.getDate()} ${monthOutput} ${dateObj.getFullYear()}`;
  };

  let [showAllowInfo, setShowAllowInfo] = useState(false);
  let generateAllowDiv = useMemo(() => {
    return (
      <Fragment>
        <div className="costArrRow">
          <p className="costArrTitle">Remaining</p>

          <p className="costArrValue">
            {/* {dateStringer.formatMinsDurationToHours(state.remaining)} */}

            {state.daysOrHours && state.daysOrHours === "days"
              ? `${state.remaining} ${state.remaining <= 1 ? "day" : "days"}`
              : dateStringer.formatMinsDurationToHours(state.remaining)}
            <br />
            {state.daysOrHours && state.daysOrHours === "days" ? (
              ""
            ) : (
              <span className="costContractedExcessSpan">
                {dateStringer.convertMinsToDays(
                  state.remaining,
                  state.userDailyMins,
                  true
                )}
              </span>
            )}
          </p>
        </div>
        <div className="costArrRow">
          <p className="costArrTitle">This request</p>

          <p className="costArrValue colour00aaff">
            {state.daysOrHours && state.daysOrHours === "days" && state.dayCount
              ? `${state.dayCount} ${state.dayCount <= 1 ? "day" : "days"}`
              : formatMinsDurationToHours(state.contractedDuration)}
            <br />
            {state.daysOrHours && state.daysOrHours === "days" ? (
              ""
            ) : (
              <span className="costContractedExcessSpan">
                {" "}
                {`${formatDurationWithHalf(
                  state.requestStartHalf,
                  state.requestEndHalf,
                  state.requestDsArr.length
                )}`}{" "}
                days{" "}
              </span>
            )}
          </p>
        </div>{" "}
        <div className="costArrRow">
          <p className="costArrTitle">Allowance year</p>

          <p className="costArrValue">{state.year}</p>
        </div>
        <div className="costArrRow">
          <p className="costArrTitle">Total allowance</p>

          <p className="costArrValue">
            {state.daysOrHours && state.daysOrHours === "days"
              ? `${state.totAllow} ${state.totAllow <= 1 ? "day" : "days"}`
              : dateStringer.formatMinsDurationToHours(state.totAllow)}
          </p>
        </div>
      </Fragment>
    );
  }, [showAllowInfo]);

  let generateLimits = state.limitArr
    ? state.limitArr.map((lim, i) => {
        return (
          <div className="costArrRow" key={i}>
            <p className="costArrTitle">
              {dateStringer.printedDateFromDs(lim.ds)}
            </p>

            <div className="limArr">
              <div className="limRowFlex firstLimRowFlex">
                <p className="limRowTitleSpan">Limit:</p> {lim.maxOff}
              </div>
              <br />{" "}
              <div className="limRowFlex">
                <p className="limRowTitleSpan">Already off:</p> {lim.currOff}
              </div>
              <br />{" "}
              <p className="limitBtLimRowTxt">
                Limit by: <br />
                <span className="limitByJtOrTeamSpan">
                  {lim.limitByTeamName
                    ? `All staff in ${lim.limitByTeamName}`
                    : `${lim.limitByJt}${
                        lim.limitByJt[lim.limitByJt.length - 1] === "s"
                          ? "es"
                          : "s"
                      }`}
                </span>
              </p>
            </div>
          </div>
        );
      })
    : "";

  let [showNoLongerExistsModal, setShowNoLongerExistsModal] = useState(false);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-single-request-data`,
        {
          reqID: unitReqID,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "getReq not valid") {
          setShowNoLongerExistsModal(true);
        }
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setShowEarnings(response.data.showEarnings);
          let days = 0;
          response.data.data.durationArray.forEach((dur) => {
            if (dur.half) {
              days += 0.5;
            } else {
              days += 1;
            }
          });
          setDataLoaded(true);

          response.data.cost.costArr.sort(function (a, b) {
            return (
              dateStringer.createTimestampFromString(a.ds) -
              dateStringer.createTimestampFromString(b.ds)
            );
          });

          setCanManage(response.data.canManage);
          setRate(response.data.rate);
          let endDs = `${
            response.data.data.dateStringArray
              ? response.data.data.dateStringArray[
                  response.data.data.dateStringArray.length - 1
                ]
              : "Y2000M00D01H00M00"
          }`;

          let dateObj = new Date();
          dateObj.setHours(0, 0, 0, 0);

          setState({
            loaded: true,
            staffReq: response.data.data,
            costArr: response.data.cost.costArr,

            approvedReqModal: response.data.data.approved,
            reqInfoTitle: response.data.leaveTypeName,
            reqSubmitted: convertCreated(response.data.data.created),
            declinedReqModal: response.data.data.declined,
            reqIsPending:
              !response.data.data.approved && !response.data.data.declined,
            approvedBy: response.data.data.approvedBy,
            declinedBy: response.data.data.declinedBy,
            declineReason: response.data.data.declineReason,
            excessDuration:
              response.data.data.excessMins && response.data.data.excessMins > 0
                ? response.data.data.excessMins
                : response.data.data.excessMinsExtendAllowance &&
                  response.data.data.excessMinsExtendAllowance > 0
                ? response.data.data.excessMinsExtendAllowance
                : 0,
            showPayableOnMyReqs:
              response.data.data.excessMins && response.data.data.excessMins > 0
                ? true
                : false,
            reqStartDs: `${
              response.data.data.dateStringArray
                ? response.data.data.dateStringArray[0]
                : "Y2000M00D01H00M00"
            }`,
            reqEndDs: endDs,
            requestStartHalf: response.data.data.reqStartHalf,
            requestEndHalf: response.data.data.reqEndHalf,
            requestDsArr: response.data.data.dateStringArray,
            contractedDuration: response.data.data.zMins,
            reqID: response.data.data.reqID,
            reqWorkPattern: "",
            userID: response.data.data.userID,
            reqNote: "",
            cost: response.data.cost ? response.data.cost.cost : 0,
            leaveTypeID: response.data.data.leaveTypeID,
            days: days,
            startTime: response.data.startEndTimeObj
              ? response.data.startEndTimeObj.start
              : "",
            endTime: response.data.startEndTimeObj
              ? response.data.startEndTimeObj.end
              : "",
            dontShowStartTime: response.data.startEndTimeObj
              ? response.data.startEndTimeObj.dontShowStart
              : false,
            dontShowEndTime: response.data.startEndTimeObj
              ? response.data.startEndTimeObj.dontShowEnd
              : false,
            limitArr: response.data.isAbsence ? [] : response.data.limitData,
            isAbsence: response.data.isAbsence,

            year: response.data.year ? response.data.year : 0,
            reqIsPast:
              dateStringer.createTimestampFromString(endDs) < dateObj.getTime(),
          });

          if (response.data.data.leaveTypeID === "annLeave") {
            setState({
              totAllow: response.data.allowInfo
                ? response.data.allowInfo.totalAllowMins
                : 0,
              usedMins: response.data.allowInfo
                ? response.data.allowInfo.usedMins
                : 0,
              usedDays: response.data.allowInfo
                ? response.data.allowInfo.usedDays
                : 0,
              remaining: response.data.allowInfo
                ? response.data.allowInfo.remaining
                : 0,
              userDailyMins: response.data.allowInfo
                ? response.data.allowInfo.userDailyMins
                : 0,
              sufficient:
                (response.data.data.zMins || response.data.data.duration) <=
                (response.data.allowInfo
                  ? response.data.allowInfo.remaining
                  : 0)
                  ? true
                  : false,
            });
          }

          if (response.data.data.leaveTypeID !== "annLeave") {
            let customFilt =
              response.data.allowInfo &&
              response.data.allowInfo.customData &&
              response.data.allowInfo.customData[0]
                ? response.data.allowInfo.customData.filter((x) => {
                    return x.leaveTypeID === response.data.data.leaveTypeID;
                  })
                : [];

            if (customFilt[0]) {
              let dayCount = 0;
              if (response.data.data.durationArray) {
                response.data.data.durationArray.forEach((d) => {
                  if (d.half) {
                    dayCount += 0.5;
                  } else {
                    dayCount++;
                  }
                });
              }
              let thisDuration =
                customFilt[0].daysOrHours === "hours"
                  ? response.data.data.zMins
                    ? response.data.data.zMins
                    : response.data.data.duration
                  : dayCount;
              setState({
                totAllow: customFilt[0].totAllow ? customFilt[0].totAllow : 0,
                usedMins: customFilt[0].used ? customFilt[0].used : 0,
                usedDays: customFilt[0].used ? customFilt[0].used : 0,
                remaining: customFilt[0].remaining
                  ? customFilt[0].remaining
                  : 0,
                userDailyMins: response.data.allowInfo
                  ? response.data.allowInfo.userDailyMins
                  : 0,
                sufficient:
                  thisDuration <= customFilt[0].remaining ? true : false,
                daysOrHours: customFilt[0].daysOrHours,
                dayCount,
              });
            }
          }

          let updatedData = {
            makePending: true,
            leaveTypeName: response.data.leaveTypeName,
            cost: response.data.cost.cost,
            zMins: response.data.data.zMins,
            excessMins: response.data.data.excessMins,
            reqID: response.data.data.reqID,
            leaveTypeID: response.data.data.leaveTypeID,
            reqStart: response.data.data.reqStart,
            reqEnd: response.data.data.reqEnd,
            approved: false,
            declined: false,
            reqStartHalf: response.data.data.reqStartHalf,
            reqEndHalf: response.data.data.reqEndHalf,
            dateStringArray: response.data.data.dateStringArray,
            days: days,
          };

          if (response.data.data.declined) {
            memoVals.setPage((x) => "declined");
            memoVals.setReqsPage((x) => "declined");
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.updateReq]);

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowUserReqModal((x) => "");
      memoVals.setShowUserReqFromNav((x) => false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      memoVals.setShowUserReqModal((x) => "");
      memoVals.setShowUserReqFromNav((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // MASTER RETURN
  return (
    <div
      className={`reqInfoUnderlay ${
        memoVals.showEditUser ? "editUserIsShownOnVtor" : ""
      } ${memoVals.showMyShiftModal.unitID ? "none" : ""}`}
      onClick={() => {
        memoVals.setShowUserReqModal((x) => "");
        memoVals.setShowUserReqFromNav((x) => false);
      }}
    >
      {memoVals.showViewTeamOnRequest ? (
        <ViewTeamOnRequest reqID={state.reqID} />
      ) : (
        ""
      )}
      {showNoteModal ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setShowNoteModal(false);
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">{state.reqNote}</p>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setShowNoteModal(false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`userReqInfoModalBox ${
            memoVals.mobModal
              ? "mobReqInfoModalBox mobModalShoulder slideUp"
              : "zoomIn"
          } ${memoVals.mobModal && state.staffReq.declined ? "removeRed" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperUserLeave"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="reqInfoHeader"
            onClick={() => {
              if (memoVals.mobModal) {
                memoVals.setShowUserReqModal((x) => "");
                memoVals.setShowUserReqFromNav((x) => false);

                setSureDeleteReq(false);
              }
            }}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={memoVals.mobModal ? close : close}
              className="closeReqInfoModalImg"
              alt="Close"
              onClick={() => {
                memoVals.setShowUserReqModal((x) => "");
                memoVals.setShowUserReqFromNav((x) => false);

                setSureDeleteReq(false);
              }}
            />
            <p className="reqInfoTitleTxtMy">
              My {shortenHeaderLeaveName(state.reqInfoTitle).toLowerCase()}{" "}
              {state.leaveTypeID === "annLeave" ? (
                <img
                  src={plane2}
                  alt="Annual leave"
                  className="annLeavePlaneTopRight"
                />
              ) : (
                ""
              )}
            </p>
          </div>
          <div
            className={`userReqBody ${
              memoVals.mobModal
                ? `mobUserReqBody ${
                    memoVals.device.ios ? "mobUserReqBodyIos" : ""
                  }`
                : ""
            } ${
              state.staffReq.declined || state.staffReq.approved
                ? "noBorder"
                : ""
            }`}
          >
            <div
              className={`${
                state.declinedReqModal
                  ? "userReqLeftContent bottomCurvedDeclined"
                  : `${
                      state.reqIsPast
                        ? "userReqLeftContent bottomCurvedApproved"
                        : "userReqLeftContent"
                    }`
              } ${
                state.declinedReqModal && !state.declineReason
                  ? "noReasonDeclinedLeftBox"
                  : ""
              } ${!state.loaded ? "invisLeftContentOfReq" : ""} ${
                memoVals.mobModal
                  ? `mobUserReqLeftContent ${
                      memoVals.device.ios ? "mobUserReqLeftContentIos" : ""
                    }`
                  : ""
              }`}
            >
              {canManage ? (
                <p
                  className="canManageUsrLeaveBtn"
                  onClick={() => {
                    memoVals.setShowReqMgrModal((x) => state.reqID);
                    memoVals.setShowUserReqModal((x) => "");
                    memoVals.setShowUserReqFromNav((x) => false);
                    setSureDeleteReq(false);
                  }}
                >
                  Manage request{" "}
                  <img
                    src={editNavy}
                    alt="Manage request"
                    className="mgrReqEditNavyImg"
                  />
                </p>
              ) : (
                ""
              )}
              {state.isAbsence ? (
                ""
              ) : (
                <p
                  className={`${
                    state.approvedReqModal ||
                    state.declinedReqModal ||
                    !state.approvedReqModal ||
                    !state.declinedReqModal
                      ? `approvedDeclinedBubble ${
                          state.approvedReqModal
                            ? "greenBubble"
                            : `${
                                state.declinedReqModal
                                  ? "redBubble"
                                  : "navyBubble"
                              }`
                        }
                   userReqGreenRedBubble`
                      : "none"
                  }`}
                >{`${
                  state.approvedReqModal
                    ? "Approved"
                    : `${
                        state.declinedReqModal
                          ? `${
                              state.declineReason &&
                              state.declineReason.includes("cancelled")
                                ? "Cancelled"
                                : "Declined"
                            }`
                          : "Pending approval"
                      }`
                }`}</p>
              )}
              <div
                className={`${
                  state.declinedReqModal || state.approvedReqModal ? "none" : ""
                }`}
              >
                <p className="contractedTitle">Submitted</p>
                <p className="requestDurationValue">{state.reqSubmitted}</p>
              </div>
              <div
                className={`${
                  state.staffReq.zMins === 0 ? "noLeaveReqDiv" : "none"
                }`}
              >
                <div
                  className={`${
                    !state.staffReq.zMins ? "noLeaveReqTimelineItem" : "none"
                  }`}
                >
                  {" "}
                  <div className="requestDurationValue">
                    <p className="noLeaveReqLightSpan">
                      No leave required{" "}
                      {state.staffReq.reqStart === state.staffReq.reqEnd
                        ? "for"
                        : "between"}{" "}
                      <br />
                      {/* {staffReq.reqEnd} */}
                      {dateStringer.printedDateFromDs(
                        state.staffReq.reqStart
                      )}{" "}
                      {state.staffReq.reqStart !== state.staffReq.reqEnd
                        ? "and "
                        : ""}
                      {state.staffReq.reqStart !== state.staffReq.reqEnd
                        ? dateStringer.printedDateFromDs(state.staffReq.reqEnd)
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  state.declinedReqModal ? "none" : "requestDurationDiv"
                }`}
              >
                <div
                  className={`requestDurationInfoSplitter ${
                    state.staffReq.approved || state.staffReq.declined
                      ? "none"
                      : ""
                  }`}
                ></div>
                <p className="contractedTitle">{`${
                  state.isAbsence
                    ? "Absence duration"
                    : state.declinedReqModal
                    ? "Allowance to have been used"
                    : `${
                        state.reqIsPast
                          ? "Allowance used"
                          : state.staffReq.ongoing
                          ? "Allowance to use so far"
                          : `Allowance to use`
                      }`
                }`}</p>
                <p className="requestDurationValue">
                  {formatMinsDurationToHours(state.contractedDuration)}
                </p>

                <div
                  className={`${
                    state.showPayableOnMyReqs
                      ? "requestDurationInfoSplitter"
                      : "none"
                  }`}
                ></div>
                <div
                  className={`${
                    state.showPayableOnMyReqs ? "payableContainer" : "none"
                  }`}
                >
                  <p
                    className={`${
                      state.excessDuration > 0 ? "excessTitle" : "none"
                    }`}
                  >
                    {`${
                      state.declinedReqModal
                        ? "To have been paid"
                        : `${
                            state.reqIsPast
                              ? "Paid for"
                              : `Payable${
                                  state.staffReq.ongoing ? " so far" : ""
                                }`
                          }`
                    }`}{" "}
                    {state.reqIsPast ? "" : "(estimated)"}
                  </p>{" "}
                </div>
                <div
                  className={`${
                    state.showPayableOnMyReqs
                      ? "youWillBePaidForContainer"
                      : "none"
                  }`}
                >
                  <p className="requestDurationValue">
                    {formatMinsDurationToHours(
                      state.excessDuration + state.contractedDuration
                    )}
                  </p>
                  <div
                    className={`${
                      state.reqIsPending ? "invisibleHeightOf15px" : "none"
                    }`}
                  ></div>
                </div>
              </div>
              {state.staffReq.unpaid ? (
                <div className="requestDurationDiv">
                  <div className={`requestDurationInfoSplitter `}></div>

                  <br />
                  <p className="requestDurationValue">Unpaid leave</p>
                </div>
              ) : (
                <div
                  className={`${
                    totalCost > 0 ? "userCostBoxContainer" : "costBoxNonSelect"
                  } ${
                    state.declinedReqModal ||
                    !memoVals.checkUserState.showCosts ||
                    !showEarnings
                      ? "none"
                      : ""
                  }`}
                  onClick={() => {
                    if (totalCost > 0) {
                      setShowCostDiv(!showCostDiv);
                    } else {
                    }
                  }}
                >
                  <div className="allowanceUsedTxt">
                    {`Payable${state.staffReq.ongoing ? " so far" : ""}`}{" "}
                  </div>
                  {includesSalaried && totalCost > 0 ? (
                    <p
                      className={`incSal ${totSal ? "totSalIncludedSize" : ""}`}
                    >
                      Salary
                      {totSal ? (
                        <span className="totSalIncl">
                          {memoVals.currencySymbol}
                          {totSal.toFixed(0)}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                  <div
                    className={`reqCostDivToggle ${
                      memoVals.mobModal ? "mobReqCostDivToggle" : ""
                    } ${showCostDiv ? "noBorder" : ""} ${
                      !showEarnings && "none"
                    }`}
                  >
                    <div className={`staffReqDuration noBorder`}>
                      {totalCost === 0 && includesSalaried ? (
                        <p className="incSal2">Salary</p>
                      ) : (
                        ""
                      )}

                      {totalCost === 0 && !includesSalaried
                        ? `${memoVals.currencySymbol}0`
                        : ""}
                      {totalCost > 0 && includesSalaried ? "+" : ""}
                      {totalCost > 0 ? memoVals.currencySymbol : ""}
                      {totalCost > 0 ? totalCost.toFixed(2) : ""}
                      {totalCost > 0 ? (
                        <img
                          src={whiteGo}
                          alt="Show costs"
                          className={`reqCostDropImg ${
                            showCostDiv
                              ? "showCostImgInactive"
                              : "whiteGoActive"
                          } ${state.costArr[0] ? "" : "none"}`}
                          onClick={() => {
                            setShowCostDiv(!showCostDiv);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className={`${showCostDiv ? "reqCostDiv" : "none"}`}>
                    {showEarnings ? generateCostDiv : ""}
                  </div>
                </div>
              )}
              {state.reqIsPending ? (
                <div
                  className="costBoxContainer usrAllowBoxContainer"
                  onClick={() => {
                    setShowAllowInfo(!showAllowInfo);
                  }}
                >
                  <div className="allowanceUsedTxt">Allowance</div>

                  <div
                    className={`reqCostDivToggle ${
                      memoVals.mobModal ? "mobReqCostDivToggle" : ""
                    } ${showAllowInfo ? "noBorder" : ""}`}
                    onClick={() => {
                      setShowAllowInfo(!showAllowInfo);
                    }}
                  >
                    <div className={`staffReqDuration noBorder`}>
                      <p
                        className={`allowStatus ${
                          state.sufficient ? "suffLeaveReq" : "insuffLeaveReq"
                        }`}
                      >
                        {state.sufficient
                          ? "Sufficient allowance"
                          : "Insufficient allowance"}
                      </p>
                      <img
                        src={whiteGo}
                        alt="Show costs"
                        className={`reqCostDropImg ${
                          showAllowInfo
                            ? "showCostImgInactive"
                            : "whiteGoActive"
                        }`}
                        onClick={() => {
                          setShowAllowInfo(!showAllowInfo);
                        }}
                      />{" "}
                    </div>
                  </div>
                  <div className={`${showAllowInfo ? "reqCostDiv" : "none"}`}>
                    {generateAllowDiv}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className={`${
                  state.declinedReqModal || state.showPayableOnMyReqs
                    ? ""
                    : "none"
                }`}
              >
                <p
                  className={`${
                    !state.declinedReqModal ? "none" : "contractedTitle"
                  }`}
                >
                  {state.declineReason &&
                  state.declineReason.includes("cancelled")
                    ? "Cancelled by"
                    : "Declined by"}
                </p>
                <p
                  className={`${
                    !state.declinedReqModal ? "none" : "requestDurationValue"
                  }`}
                >
                  {dateStringer.nameFormat(state.declinedBy || "")}
                </p>
                <div
                  className={`${
                    state.declineReason ||
                    (state.declinedReqModal &&
                      state.declineReason &&
                      !state.declineReason.includes("cancelled")) ||
                    (state.declinedReqModal && !state.declineReason)
                      ? "none"
                      : "requestDurationInfoSplitter xylo"
                  }`}
                ></div>

                <div
                  className={`${
                    state.declinedReqModal &&
                    state.declineReason &&
                    !state.declineReason.includes("cancelled")
                      ? "requestDurationInfoSplitter cycler"
                      : "none xylo"
                  }`}
                ></div>

                <p
                  className={`${
                    state.declinedReqModal &&
                    state.declineReason &&
                    !state.declineReason.includes("cancelled")
                      ? "submittedReqTxt submittedPushDown"
                      : "none"
                  }`}
                >
                  Reason
                </p>
                <p
                  className={`${
                    state.declinedReqModal &&
                    state.declineReason &&
                    !state.declineReason.includes("cancelled")
                      ? "submissionDateTxt"
                      : "none"
                  }`}
                >
                  {state.declineReason || "No reason has been given"}
                </p>
              </div>
              <div className={`${state.contractedDuration ? "" : "none"}`}>
                {state.startTime &&
                !state.declineReason.includes("cancelled") ? (
                  <div className="requestDurat ionInfoSplitter"></div>
                ) : (
                  ""
                )}

                {state.startTime &&
                !state.declinedReqModal &&
                !state.reqIsPast ? (
                  <p className="contractedTitle">
                    Start{state.staffReq.ongoing ? "ed" : "s"}{" "}
                    {state.requestStartHalf && !state.startTime
                      ? "half way through"
                      : ""}
                  </p>
                ) : (
                  ""
                )}
                {state.startTime &&
                !state.declinedReqModal &&
                !state.reqIsPast ? (
                  <p className="requestDurationValue">
                    {state.dontShowStartTime
                      ? dateStringer.printedDateFromDs(state.startTime)
                      : dateStringer.printedDateWithTimeFromDs(state.startTime)}
                  </p>
                ) : (
                  ""
                )}

                {state.startTime &&
                !state.declinedReqModal &&
                !state.reqIsPast ? (
                  <div className="requestDurationInfoSplitter"></div>
                ) : (
                  ""
                )}

                {state.endTime &&
                !state.declinedReqModal &&
                !state.reqIsPast ? (
                  state.staffReq.ongoing ? (
                    <p className="contractedTitle">No end date</p>
                  ) : (
                    <p className="contractedTitle">
                      Ends{" "}
                      {state.requestEndHalf && state.endTime
                        ? "half way through"
                        : ""}
                    </p>
                  )
                ) : (
                  ""
                )}
                {state.endTime &&
                !state.declinedReqModal &&
                !state.reqIsPast ? (
                  state.staffReq.ongoing ? (
                    <p
                      className={`${
                        state.endTime ? "requestDurationValue" : "none"
                      }`}
                    >
                      This leave is on-going
                    </p>
                  ) : (
                    <p
                      className={`${
                        state.endTime ? "requestDurationValue" : "none"
                      }`}
                    >
                      {state.dontShowEndTime
                        ? dateStringer.printedDateFromDs(state.endTime)
                        : dateStringer.printedDateWithTimeFromDs(
                            state.endTime
                          )}{" "}
                    </p>
                  )
                ) : (
                  ""
                )}
              </div>
              {state.limitArr[0] ? (
                <div
                  className="userReqLimitsContainer"
                  onClick={() => {
                    setShowLimits(!showLimits);
                  }}
                >
                  <p className="approveByTxtTitle">Limits exceeded</p>
                  <div className="exceededDaysDiv">
                    <p className="nOutOfDaysSpan">
                      <span className="colourDarkRed">
                        {state.limitArr.length}
                      </span>{" "}
                      out of {state.staffReq.dateStringArray.length}{" "}
                      {state.staffReq.dateStringArray.length === 1
                        ? "day"
                        : "days"}
                    </p>
                    <img
                      src={whiteGo}
                      alt="Show costs"
                      className={`limitsDrop ${
                        showLimits ? "showCostImgInactive" : "whiteGoActive"
                      }`}
                      onClick={() => {
                        setShowLimits(!showLimits);
                      }}
                    />{" "}
                  </div>

                  {showLimits ? (
                    <div className="reqLimitsArray">{generateLimits}</div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <div className={`${state.approvedReqModal ? "" : "none"}`}>
                {!state.declinedReqModal &&
                !state.reqIsPast &&
                !state.limitArr[0] ? (
                  <div className="requestDurationInfoSplitter happiness"></div>
                ) : (
                  ""
                )}

                <p className="contractedTitle">
                  {state.isAbsence ? "Added" : "Approved"} by
                </p>
                <p className="requestDurationValue">
                  {dateStringer.nameFormat(state.approvedBy)}
                </p>
                <br />
              </div>
            </div>
            <div
              className="staffReqModalContentRight"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            >
              <p
                className={`${state.staffReq.note ? "reqNoteBtn" : "none"}`}
                onClick={() => {
                  setState({ reqNote: state.staffReq.note });
                  setShowNoteModal(true);
                }}
              >
                Note
              </p>{" "}
              <div className="teamBtnContainerUserReq">
                <div
                  className={`viewReqTeamCal ${
                    state.limitArr[0] ? "viewReqTeamCalWarning" : ""
                  }`}
                  onClick={() => {
                    memoVals.setShowViewTeamOnRequest((x) => true);
                  }}
                >
                  Team{" "}
                  {state.limitArr[0] ? (
                    <img
                      src={redTriangle}
                      alt="Limits active"
                      className="limitsRedTriangleImg"
                    />
                  ) : (
                    ""
                  )}
                </div>
                {state.limitArr[0] ? (
                  <p className={`reqUnderstaffedTxt `}>Understaffed</p>
                ) : (
                  ""
                )}
              </div>
              <div
                className={`${
                  state.staffReq.zMins > 0 ? "reqRightBarDivider" : "none"
                }`}
              ></div>
              <div
                className={`${
                  state.staffReq.zMins > 0 ? "reqTimelineDiv" : "none"
                }`}
              >
                <div
                  className={`${
                    state.reqStartDs === state.reqEndDs
                      ? "timelineIcon pushDownIfSingle"
                      : "timelineIcon"
                  } ${
                    state.approvedReqModal && !state.declinedReqModal
                      ? "timelineIconGreenBg"
                      : ""
                  } ${
                    state.declinedReqModal && !state.approvedReqModal
                      ? "timelineIconRedBg"
                      : ""
                  }
                ${state.isAbsence ? "timelineIconAbsenceBg" : ""}

              `}
                >
                  <p className="iconCalDateNumber">
                    {state.reqStartDs.substr(9, 2)}
                    <span
                      className={`${
                        state.requestStartHalf ||
                        (state.requestEndHalf &&
                          state.requestDsArr.length === 1)
                          ? "halfDayIconAppend"
                          : "none"
                      }`}
                    >
                      1/2
                    </span>
                  </p>{" "}
                  <div className="iconBlob">
                    {formatMonthCalIcon(state.reqStartDs.substr(6, 2))}
                    <span className="iconBlobyr">
                      {state.reqStartDs.substr(3, 2)}
                    </span>
                  </div>
                </div>
                <div
                  className={`${
                    state.requestDsArr.length === 1 &&
                    (state.requestStartHalf || state.requestEndHalf)
                      ? "halfDayInfoHook"
                      : "none"
                  }`}
                ></div>
                <p
                  className={`${
                    state.requestDsArr.length === 1 &&
                    (state.requestStartHalf || state.requestEndHalf)
                      ? "showHalfDayInfo"
                      : "none"
                  }`}
                >{`${state.requestEndHalf ? "First" : "Last"} half`}</p>
                <div
                  className={`${
                    state.reqStartDs === state.reqEndDs
                      ? "none"
                      : "middleTimelineBar"
                  }`}
                >
                  <div className="timelineBarCircle"></div>
                  <div className="timelineBarLine"></div>
                  <p
                    className={`timelineDur ${
                      state.approvedReqModal
                        ? `approvedTimelineDurColour ${
                            state.staffReq.ongoing ? "onGoingPullUp" : ""
                          }`
                        : ""
                    } ${
                      state.declinedReqModal ? "declinedTimelineDurColour" : ""
                    } ${state.isAbsence ? "absenceTimelineDurColour" : ""}`}
                  >
                    {`${formatDurationWithHalf(
                      state.requestStartHalf,
                      state.requestEndHalf,
                      state.requestDsArr.length
                    )}`}{" "}
                    days {state.staffReq.ongoing ? <br /> : ""}
                    {state.staffReq.ongoing ? (
                      <p className="soFar">so far</p>
                    ) : (
                      ""
                    )}
                  </p>
                  <div className="timelineBarCircle"></div>
                </div>
                {/* SECOND ICON --USER, not mgr */}
                {!state.staffReq.ongoing ? (
                  <div
                    className={`timelineIcon ${
                      state.reqStartDs === state.reqEndDs ? "none" : ""
                    }
              ${state.approvedReqModal ? "timelineIconGreenBg" : ""}
              ${state.declinedReqModal ? "timelineIconRedBg" : ""}
              ${state.isAbsence ? "timelineIconAbsenceBg" : ""}
              `}
                  >
                    <p className="iconCalDateNumber">
                      {state.reqEndDs.substr(9, 2)}

                      <span
                        className={`${
                          state.requestEndHalf ? "halfDayIconAppend" : "none"
                        }`}
                      >
                        1/2
                      </span>
                    </p>
                    <div className="iconBlob">
                      {formatMonthCalIcon(state.reqEndDs.substr(6, 2))}
                      <span className="iconBlobyr">
                        {state.reqEndDs.substr(3, 2)}
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {!state.staffReq.declined && (
                <div className="reqRate">
                  <p className="reqRate0">Rate</p>

                  <p className={`reqRate1 ${rate === 0 ? "x2948911342" : ""}`}>
                    {rate === 0 ? "Unpaid" : `${rate}x`}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div
            className={`${
              state.declinedReqModal || state.reqIsPast
                ? `${
                    state.declinedReqModal
                      ? "pastDecReqModalFooterDeclined"
                      : "pastDecReqModalFooterApproved"
                  } ${!memoVals.mobModal ? "mobDeclinedOrPastBottom" : ""}`
                : "none"
            }`}
          >
            <p
              className="closeReqInfo"
              onClick={() => {
                memoVals.setShowUserReqModal((x) => "");
                memoVals.setShowUserReqFromNav((x) => false);
              }}
            >
              Close
            </p>
          </div>
          <div
            className={`${
              state.declinedReqModal || state.reqIsPast
                ? "none"
                : "reqInfoFooter"
            }`}
          >
            {state.reqID ? (
              state.isAbsence ? (
                state.staffReq.ongoing ? (
                  <div className="absenceModalFooterLeft">On-going absence</div>
                ) : (
                  <div className="absenceModalFooterLeft">Absence</div>
                )
              ) : state.staffReq.ongoing ? (
                <div className="absenceModalFooterLeft">On-going leave</div>
              ) : (
                <div
                  className={`${
                    sureDeleteReq ? "none" : "cancelReqImgContainerDefault"
                  }`}
                >
                  <img
                    className="cancelReqBtnImg"
                    src={cancelNavy}
                    onClick={() => {
                      setSureDeleteReq(!sureDeleteReq);
                    }}
                  />{" "}
                  {state.approvedReqModal && (
                    <img
                      className="editReqBtnImg"
                      src={editNavy}
                      onClick={() => {
                        setSureEdit(true);
                      }}
                    />
                  )}
                  {!state.staffReq.ongoing && state.approvedReqModal && (
                    <div
                      className="editReqBtnImg addToCalBtn"
                      onClick={() => {
                        axios({
                          method: "get",
                          url: `${serverURL}/download-ics/${state.reqID}`,
                          responseType: "blob", // Set the responseType to 'blob' to handle binary data
                        })
                          .then((response) => {
                            // Create a URL for the blob data
                            const blobUrl = window.URL.createObjectURL(
                              response.data
                            );

                            // Create a temporary link element
                            const link = document.createElement("a");
                            link.href = blobUrl;

                            // Set the download attribute to specify the file name
                            link.download = "FlowRota leave";
                            // "your-file-name.jpg"; // Replace 'your-file-name.jpg' with the desired file name

                            // Append the link to the body
                            document.body.appendChild(link);

                            // Trigger the download by programmatically clicking on the link
                            link.click();

                            // Clean up: remove the temporary link
                            document.body.removeChild(link);
                            // setShowIndicate({ show: false });
                          })
                          .catch((error) => {
                            // Handle errors
                            console.error("Error downloading file:", error);
                          });
                      }}
                    >
                      <p className="atcTxt"> Add to calendar</p>
                      <img
                        src={addToCal}
                        alt="Add to calendar"
                        className="atcimg"
                      />
                    </div>
                  )}
                </div>
              )
            ) : (
              ""
            )}

            <img
              src={reqLoader}
              className={`${
                deletingReqLoading ? "deleteLoadingOpacLow" : "none"
              }`}
            />
            <div
              className={`${deletingReqLoading ? "none" : "emptyLoaderSpace"}`}
            ></div>

            <p
              className={`closeReqInfo ${state.declinedReqModal ? "none" : ""}`}
              onClick={() => {
                memoVals.setShowUserReqModal((x) => false);
                memoVals.setShowUserReqFromNav((x) => false);
                setSureDeleteReq(false);
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {sureDeleteReq ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setSureDeleteReq(false);
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              Are you sure you want to cancel this request?
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setDeletingReqLoading(true);

                  axios
                    .post(
                      `${serverURL}/decline-request`,
                      {
                        reqID: state.reqID,
                        reason: `Request was cancelled by ${memoVals.fName} ${memoVals.lName}`,
                        userDeletingOwnRequest: true,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((resposne) => {
                      if (resposne.status === 200) {
                        update(true);
                        memoVals.setUpdateUserReqs(
                          (x) => !memoVals.updateUserReqs
                        );
                        if (state.reqIsPending) {
                          setCountData({
                            userLeave: countData.userLeave - 1,
                          });
                        }
                        setIndicate((x) => {
                          return {
                            show: true,
                            message: "Request cancelled",
                            colour: "blue",
                            duration: 4000,
                          };
                        });
                        setUpdatedItem({
                          deleted: true,
                          reqID: state.reqID,
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeleteReq(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {sureEdit ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setSureEdit(false);
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              To make an amedment to this leave request, your existing request
              will be cancelled.
              <br />
              <br />
              Are you sure you want to cancel this request in order to make a
              new one?
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setDeletingReqLoading(true);

                  axios
                    .post(
                      `${serverURL}/decline-request`,
                      {
                        reqID: state.reqID,
                        reason: `Request was cancelled by ${memoVals.fName} ${memoVals.lName}`,
                        userDeletingOwnRequest: true,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((resposne) => {
                      if (resposne.status === 200) {
                        memoVals.setShowReqModal((x) => true);
                        memoVals.setUpdateUserReqs(
                          (x) => !memoVals.updateUserReqs
                        );

                        update(true);

                        setIndicate((x) => {
                          return {
                            show: true,
                            message: "Request cancelled",
                            colour: "blue",
                            duration: 4000,
                          };
                        });
                        setUpdatedItem({
                          deleted: true,
                          reqID: state.reqID,
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureEdit(false);
                }}
              >
                Go back
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showNoLongerExistsModal ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              This leave request no longer exists.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setShowNoLongerExistsModal(false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default UserLeaveReqModal;
