import React, { createContext, useState, useReducer } from "react";
import axios from "axios";

export const UserContext = createContext();
// context for the logged in user

const UserContextProvider = (props) => {
  // let [validUser, setValidUser] = useState(true);

  // let [allowancesData, setAllowancesData] = useState({
  //   workPattern: "",
  //   dataFormat: "",
  //   previous: [],
  //   current: [],
  //   next: [],
  // });
  // let [workingPattern, setWorkingPattern] = useState();
  // let [fixedSchedOdd, setFixedSchedOdd] = useState();
  // let [daysOrHours, setDaysOrHours] = useState();
  // let [workingPatternChangedMidTerm, setWorkingPatternChangedMidTerm] =
  //   useState();

  // set in checkUser

  // C L I E N T
  // let [companyName, setCompanyName] = useState("");
  // let [logoURL, setLogoURL] = useState("");
  // let [useLogo, setUseLogo] = useState(false);
  // let [publicHolsRegion, setPublicHolsRegion] = useState("None");
  // let [publicHolsRate, setPublicHolsRate] = useState(1);
  // let [specialDays, setSpecialDays] = useState([{}]);
  // let [replaceLogo, setReplaceLogo] = useState();

  let [country, setCountry] = useState("");
  let [currencySymbol, setCurrencySymbol] = useState("£");

  let [clientID, setClientID] = useState("");

  // let [tilEnabled, setTilEnabled] = useState(false);
  // let [clockOnAllowed, setClockOnAllowed] = useState(false);
  let [checkUserState, setCheckUserState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      validUser: false, // will be last checked timestamp
      fName: "",
      lName: "",
      companyName: "",
      permissions: 3,
      companyName: "",
      tilEnabled: false,
      indiOnly: false,
      clockOnAllowed: false,
      showMobSideBtns: false,
      userID: "",
      workingPattern: "",
      hideInstall: "",
      lastClosedInstall: "",
      enableGPS: false,
      currClientYear: new Date().getFullYear(),
      version: "",
      todo: false,
      hideChat: true,
      proPicUrl: "",
    }
  );

  return (
    <UserContext.Provider
      value={{
        checkUserState,
        setCheckUserState,

        // allowancesData,
        // setAllowancesData,

        country,
        setCountry,
        currencySymbol,
        setCurrencySymbol,
        clientID,
        setClientID,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
