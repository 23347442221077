import React, { useContext, useEffect, useState, useMemo } from "react";
// cleaned wed 24 jul 23
// ios done

import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";

import axios from "axios";
import serverURL from "../serverURL";
import close from "../img/modals/close.svg";

// import iosAddToHomeScreen from "../img/general/iosAddToHomeScreen.svg";
// import chromeAddToHomeScreen from "../img/general/chromeAddToHomeScreen.svg";
// import addToHomeScreen from "../img/general/addToHomeScreen.svg";

// import installCloud from "../img/general/installCloud.svg";
// import logo from "../img/general/logo.svg";
// import androidIns from "../img/general/androidIns.svg";

import installAndroid from "../img/general/installAndroid.png";
import installIos from "../img/general/installIos.png";
import installTick from "../img/general/installTick.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

const InstallModal = ({ setShowInstallModal, device, popUp }) => {
  const { mobModal } = useContext(StyleContext);
  const { setCheckUserState, checkUserState } = useContext(UserContext);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={installAndroid}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={close}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={installTick}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={installIos}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      {/* <img
        src={iosAddToHomeScreen}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={close}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={chromeAddToHomeScreen}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={addToHomeScreen}
        alt="4"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={installCloud}
        alt="5"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={logo}
        alt="6"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={androidIns}
        alt="7"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      /> */}
    </div>
  );

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (imagesLoaded === 4 && dataLoaded) {
      setLoading(false);
    }
  }, [imagesLoaded, dataLoaded]);

  const memoVals = useMemo(
    () => ({
      mobModal,
      setCheckUserState,
      checkUserState, //
    }),
    [mobModal, setCheckUserState, checkUserState]
  );

  let [deviceToggler, setDeviceToggler] = useState("chrome"); // or "chrome"
  useEffect(() => {
    // cleaned - not needed
    // console.log("*****", device.browser.toLowerCase());
    // if (device.browser.toLowerCase() === "chrome") {
    //   setDeviceToggler("chrome");
    // }

    function getOperatingSystem() {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.platform;

      if (/Win/i.test(platform)) {
        return "Windows";
      } else if (/Mac/i.test(platform)) {
        return "Mac OS";
      } else if (/Android/i.test(userAgent)) {
        return "Android";
      } else if (/Linux/i.test(platform)) {
        return "Linux";
      } else if (/iOS|iPhone|iPad|iPod/i.test(userAgent)) {
        return "iOS";
      } else {
        return "Unknown";
      }
    }
    if (getOperatingSystem() === "iOS" || getOperatingSystem() === "Mac OS") {
      setDeviceToggler("ios");
      setDataLoaded(true);
    } else {
      setDataLoaded(true);
    }
    // console.log({ device });
  }, [device]);

  // useEffect(() => {
  //   window.history.pushState(null, document.title, window.location.href);
  //   window.addEventListener("popstate", function (event) {
  //     window.history.pushState(null, document.title, window.location.href);
  //     setShowInstallModal(false);
  //   });
  // }, []);

  let [hide, setHide] = useState(false);

  let closeModal = () => {
    sessionStorage.setItem("blockInstall", true);

    setShowInstallModal(false);
  };

  return (
    <div className={`installUnderlay ${loading ? "invis" : ""}`}>
      <div className={`installModalBox zoomInInstall`}>
        <div className="installModalBoxUpper">
          <div className="installModalLeft">
            <img
              src={close}
              alt="Close"
              className="closeInstallImg"
              onClick={() => {
                closeModal();
              }}
            />{" "}
            <p
              className="progressier-install-button installInfoTxt pointerEventsNone"
              data-install="Install FlowRota"
              data-installed="App installed"
            >
              <span className="progCorrection">Install FlowRota</span>
            </p>
            <p
              className="progressier-install-button installInfoTxt2 pointerEventsNone"
              data-install="FlowRota is a web app installable from your browser."
              data-installed="FlowRota is installed on your phone."
            >
              {" "}
              <span className="progCorrectionParent">
                <span className="progCorrection">
                  FlowRota is a web app installable from your browser.
                </span>{" "}
              </span>
            </p>
            <div className="installBenefits">
              <div className="installBenRow">
                <img
                  src={installTick}
                  className="installBenImg"
                  alt="Installation benefit"
                />
                Full-screen app
              </div>
              <div className="installBenRow">
                <img
                  src={installTick}
                  className="installBenImg"
                  alt="Installation benefit"
                />
                Push notifications
              </div>
              <div className="installBenRow">
                <img
                  src={installTick}
                  className="installBenImg"
                  alt="Installation benefit"
                />
                Quick access from your home screen
              </div>
            </div>
            <div className="installBenRow">
              <img
                src={installTick}
                className="installBenImg"
                alt="Installation benefit"
              />
              No app store required
            </div>
          </div>

          {deviceToggler === "chrome" ? (
            <img
              src={installAndroid}
              alt="Android device"
              className="installAndroid"
            />
          ) : (
            // hello
            <img src={installIos} alt="iOs device" className="installIos" />
          )}
        </div>
        {/* {deviceToggler} */}
        <div
          className={`installModalBoxLower ${
            deviceToggler === "chrome" ? "" : "installModalBoxLowerIos"
          }`}
        >
          <div
            className="progressier-install-button installCtaBtn blueColours"
            data-icons="false"
            data-install="Install"
            data-installed="Use the app"
          >
            Install
          </div>
          <div
            className="installCtaBtn greyColours contInBrowser"
            onClick={() => {
              closeModal();
            }}
          >
            Continue in browser
          </div>
        </div>
      </div>

      {imgPreload}
    </div>
    // <div
    //   className={`clockOnModalUnderlay underlayInstall ${
    //     loading ? "invis" : ""
    //   }`}
    //   onClick={() => {
    //     closeModal();
    //   }}
    // >
    //   <div
    //     className={`clockOnModalBox ${
    //       deviceToggler === "chrome" ? "chromeInstallModalBox" : "x3841941"
    //     } ${
    //       memoVals.mobModal
    //         ? "mobInstallModal mobModalShoulder slideUp"
    //         : "zoomIn"
    //     } x3841941`}
    //     onClick={(e) => {
    //       e.stopPropagation();
    //     }}
    //   >
    //     <div
    //       className="clockOnModalHeader none"
    //       onClick={() => {
    //         if (memoVals.mobModal) {
    //           closeModal();
    //         }
    //       }}
    //     >
    //       <div className="clockOnModalHeaderSideUnit">
    //         <img
    //           src={close}
    //           alt="Close"
    //           className="closeClockOnModalImg"
    //           onClick={() => {
    //             closeModal();
    //           }}
    //         />
    //       </div>
    //       <p className="clockOnModalHeaderTitle"></p>
    //     </div>

    //     {deviceToggler === "chrome" ? (
    //       <div className="installModalContentBox">
    //         <p className="dockInstructTxt inSafari fontSize16">
    //           Install the app
    //         </p>

    //         <img
    //           src={installCloud}
    //           alt="Install FlowRota"
    //           className="installCloudImg"
    //         />
    //         <p className="installPWAinfo">
    //           FlowRota is a web app installable from your mobile web browser.
    //           {/* This provides users with a better experience increasing privacy
    //           and security whilst using a fraction of your smartphone’s storage
    //           compared to apps downloaded from the app store. */}
    //         </p>
    //         <div className="dockInstructDiv">
    //           <p className="dockInstructNum">1</p>
    //           <p className="dockInstructTxt">Tap on</p>
    //           <img
    //             src={chromeAddToHomeScreen}
    //             alt="add to home screen"
    //             className="addToHSiosImg"
    //           />
    //           <p className="dockInstructTxt">in the top-right corner</p>
    //         </div>

    //         <div className="dockInstructDiv">
    //           <p className="dockInstructNum">2</p>
    //           <p className="dockInstructTxt">Scroll and select</p>
    //           <div className="addToHomeScreenBubble androidBub">
    //             <img
    //               src={androidIns}
    //               alt="add to home screen"
    //               className="aths aths2"
    //             />
    //             <p className="addToHomeScreenTxt2">Add to home screen</p>
    //           </div>
    //         </div>

    //         <div className="dockInstructDiv">
    //           <p className="dockInstructNum">3</p>
    //           <p className="dockInstructTxt">Look for the </p>
    //           <img
    //             src={logo}
    //             alt="add to home screen"
    //             className="addToHSiosImg lookForTheLogo"
    //           />
    //           <p className="dockInstructTxt">icon</p>
    //         </div>
    //         <div className="dockInstructDiv finalLineInstallModal">
    //           <p className="dockInstructNum invis">3</p>
    //           <p className="dockInstructTxt">in your app menu </p>
    //         </div>
    //       </div>
    //     ) : (
    //       <div className="installModalContentBox">
    //         <p className="dockInstructTxt inSafari fontSize16">
    //           Install the app
    //         </p>

    //         <img
    //           src={installCloud}
    //           alt="Install FlowRota"
    //           className="installCloudImg"
    //         />

    //         <div className="dockInstructDiv">
    //           <p className="dockInstructNum">1</p>
    //           <p className="dockInstructTxt">Tap on</p>
    //           <img
    //             src={iosAddToHomeScreen}
    //             alt="add to home screen"
    //             className="addToHSiosImg"
    //           />
    //           <p className="dockInstructTxt">in the tab bar</p>
    //         </div>

    //         <div className="dockInstructDiv">
    //           <p className="dockInstructNum">2</p>
    //           <p className="dockInstructTxt">Scroll and select</p>
    //           <div className="addToHomeScreenBubble">
    //             <p className="addToHomeScreenTxt">Add to home screen</p>
    //             <img
    //               src={addToHomeScreen}
    //               alt="add to home screen"
    //               className="aths"
    //             />
    //           </div>
    //         </div>

    //         <div className="dockInstructDiv">
    //           <p className="dockInstructNum">3</p>
    //           <p className="dockInstructTxt">Look for the </p>
    //           <img
    //             src={logo}
    //             alt="add to home screen"
    //             className="addToHSiosImg lookForTheLogo"
    //           />
    //           <p className="dockInstructTxt">icon on your home screen</p>
    //         </div>
    //       </div>
    //     )}

    //     {device.browser === "chrome" ? (
    //       <button
    //         className="progressier-install-button closeClockOnDiv x9183812 x32984197411 blueColours"
    //         data-icons="false"
    //         data-install="Install now"
    //         data-installed="Launch the app"
    //       ></button>
    //     ) : (
    //       ""
    //     )}

    //     <p
    //       className="closeClockOnDiv x9183812 "
    //       onClick={() => {
    //         sessionStorage.setItem("blockInstall", true);
    //         closeModal();
    //       }}
    //     >
    //       Use in browser
    //     </p>
    //   </div>
    // </div>
  );
};

export default InstallModal;
