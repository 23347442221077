import React, { useState, useContext, useEffect, useMemo } from "react";
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios not needed - is a child
import axios from "axios";

import { NavLink } from "react-router-dom";

import serverURL from "../../serverURL";

import Navbar from "../Navbar";
import CheckUser from "../../tools/CheckUser";
import MgrAccountNavbar from "./MgrAccountNavbar";

import "../../styles/settings.css";
import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";

import navyTick from "../../img/general/navyTick.svg";
import dateStringer from "../../tools/dateStringer";

const NotifSettings = ({ state, setState, isMob }) => {
  const { setIndicate } = useContext(DataContext);
  const { mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setIndicate,
    }),
    [setIndicate]
  );

  let [blockEmailTest, setBlockEmailTest] = useState(false);
  let [blockPushTest, setBlockPushTest] = useState(false);

  return (
    <div
      className={`notifSettingsDiv ${
        state.isMgr ? "" : "userNotifSettingHeightDynamic"
      } ${isMob ? "notifSettingsMob" : ""} ${
        isMob && !state.isMgr ? "userNotifMob" : ""
      } ${isMob && state.isMgr ? "mgrNotifMob" : ""} ${
        !state.pushNotifs && !state.emailNotifs ? "noNotifsSize" : ""
      }`}
    >
      <div className="desktopDataBoxHeader" onClick={() => {}}>
        <span className="dataBoxHeading">Notification settings</span>
        &nbsp;
      </div>
      <div className="notifSettingsEnableDiv">
        <div
          className={`${
            state.emailNotifs
              ? "notifEnableItem notifEnableItemDisabled"
              : "notifEnableItem"
          }`}
          onClick={() => {
            axios
              .post(
                `${serverURL}/edit-notifications`,
                { notifName: "email", enable: !state.emailNotifs },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message.includes("logout")) {
                  window.location.href = "/signin";
                }
                if (response.data.message === "success") {
                  console.log(response.data);
                  setState({
                    emailNotifs: response.data.data,
                  });
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          <p className="notifEnableItemTxt">Email</p>
          {/*  KNOB BEGIN */}
          <div className="knobContainer notifTypeKnob">
            <div
              className={`switchKnob ${
                state.emailNotifs ? "knobOn" : "knobOff"
              }`}
            ></div>
            <div className="knobBg"></div>
          </div>
          {/*  KNOB END */}
          {/* <img
            src={state.emailNotifs ? checkbox2 : checkbox1}
            alt="Email"
            className="notifCheckBoxImg"
          /> */}
        </div>

        <div
          className={`${
            state.pushNotifs
              ? "notifEnableItem notifEnableItemDisabled"
              : "notifEnableItem"
          }`}
          onClick={() => {
            axios
              .post(
                `${serverURL}/edit-notifications`,
                { notifName: "pushNotifs", enable: !state.pushNotifs },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message.includes("logout")) {
                  window.location.href = "/signin";
                }
                if (response.data.message === "success") {
                  setState({
                    pushNotifs: response.data.data,
                  });
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          <p className="notifEnableItemTxt">Push notifications</p>
          <div className="knobContainer notifTypeKnob">
            <div
              className={`switchKnob ${
                state.pushNotifs ? "knobOn" : "knobOff"
              }`}
            ></div>
            <div className="knobBg"></div>
          </div>
        </div>
      </div>
      {state.pushNotifs ? (
        <div className="enablePushDiv">
          {/* <p></p> */}
          <button
            className="progressier-subscribe-button progessierSubBtn"
            data-icons="false"
            data-eligible="Enable notifications"
            data-subscribed="Notifications enabled"
            data-blocked="Notifications blocked"
          ></button>
        </div>
      ) : (
        ""
      )}
      {state.emailNotifs ? (
        <p className="emailSpamBoldTitle">Important!</p>
      ) : (
        ""
      )}

      {state.emailNotifs ? (
        <p className="addToContactsWarnTxt">
          Add{" "}
          <span className="emailSpamEmailSpan">notifications@flowrota.com</span>{" "}
          to your email provider's address book to ensure important emails and
          notifications don't go into your spam folder
        </p>
      ) : (
        ""
      )}

      {state.emailNotifs || state.pushNotifs ? (
        <div className="testNotifDiv">
          {state.emailNotifs && (
            <p
              className={`sendTextBtn ${blockEmailTest ? "disable" : ""}`}
              onClick={() => {
                setBlockEmailTest(true);
                axios
                  .post(
                    `${serverURL}/test-notif`,
                    {
                      nowDs: dateStringer.createStringFromTimestamp(
                        new Date().getTime()
                      ),
                      email: true,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message.includes("logout")) {
                      window.location.href = "/signin";
                    }
                    if (response.data.message === "success") {
                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "Email sent",
                          colour: "green",
                          duration: 4000,
                        };
                      });
                      setTimeout(() => {
                        setBlockEmailTest(false);
                      }, 10000);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Send test email
            </p>
          )}
          {state.pushNotifs && (
            <p
              className={`sendTextBtn ${blockPushTest ? "disable" : ""}`}
              onClick={() => {
                setBlockPushTest(true);

                axios
                  .post(
                    `${serverURL}/test-notif`,
                    {
                      nowDs: dateStringer.createStringFromTimestamp(
                        new Date().getTime()
                      ),
                      push: true,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message.includes("logout")) {
                      window.location.href = "/signin";
                    }
                    if (response.data.message === "success") {
                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "Push notification sent",
                          colour: "green",
                          duration: 4000,
                        };
                      });
                      setTimeout(() => {
                        setBlockPushTest(false);
                      }, 10000);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Send test push
            </p>
          )}
        </div>
      ) : (
        ""
      )}

      <div
        className={`notifSettingsScrollBox ${
          isMob || memoVals.mobModal ? "noScrollNotifs" : ""
        } ${!state.pushNotifs && !state.emailNotifs ? "none" : ""}`}
      >
        {state.isMgr ? (
          <div className="notifsIsMgrTitle">Notifications as an employee</div>
        ) : (
          ""
        )}
        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
        <div
          className={`notifSettingsRow ${
            isMob ? "notifSettingsRowMob" : ""
          } noBorderBottom`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">Shift reminders</p>

            <div className="notifExplainRow">
              Get a reminder sent to you by email before your shift is scheduled
              to start
            </div>
          </div>

          <div className="notifRight">
            {state.pushNotifs ? (
              <div className="notifBtnCol invis">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("reminder")
                      ? "activeNotifImg"
                      : ""
                  }`}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("reminder")
                        ? "invis"
                        : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("reminder")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "reminder",
                          enable: state.disabledNotifsEmail.includes("reminder")
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("reminder")
                        ? "invis"
                        : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="reminderSel">
          <select
            value={state.reminderMins}
            className="reminderSelDrop"
            onChange={(e) => {
              axios
                .post(
                  `${serverURL}/shift-reminder`,
                  {
                    durMins: parseInt(e.target.value),
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message.includes("logout")) {
                    window.location.href = "/signin";
                  }
                  if (response.data.message === "success") {
                    setState({
                      reminderMins: response.data.reminderMins,
                    });
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <option value="1">1 minute before</option>
            <option value="5">5 minutes before</option>
            <option value="10">10 minutes before</option>
            <option value="15">15 minutes before</option>
            <option value="20">20 minutes before</option>
            <option value="30">30 minutes before</option>
            <option value="45">45 minutes before</option>
            <option value="60">1 hour before</option>
            <option value="90">1.5 hours before</option>
            <option value="120">2 hours before</option>
            <option value="180">3 hours before</option>
            <option value="240">4 hours before</option>
            <option value="300">5 hours before</option>
            <option value="360">6 hours before</option>
            <option value="420">7 hours before</option>
            <option value="480">8 hours before</option>
            <option value="540">9 hours before</option>
            <option value="600">10 hours before</option>
            <option value="660">11 hours before</option>
            <option value="720">12 hours before</option>
            <option value="1440">24 hours before</option>
          </select>
        </div>

        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">Leave & absence periods</p>

            <div className="notifExplainRow">
              When your manager approves, declines or amends your leave requests
              - or adds, removes, amends or deletes an absence period
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("leave")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "leave",
                          enable: state.disabledNotifsEmail.includes("leave")
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("leave") ? "invis" : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("leave")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "leave",
                          enable: state.disabledNotifsPush.includes("leave")
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("leave") ? "invis" : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">Clock off reminders</p>

            <div className="notifExplainRow">
              If you are clocked on, FlowRota will notify you three minutes
              before your scheduled finish time reminding you to clock off.
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("clockOffReminder")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "clockOffReminder",
                          enable: state.disabledNotifsEmail.includes(
                            "clockOffReminder"
                          )
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("clockOffReminder")
                        ? "invis"
                        : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("clockOffReminder")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "clockOffReminder",
                          enable: state.disabledNotifsPush.includes(
                            "clockOffReminder"
                          )
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("clockOffReminder")
                        ? "invis"
                        : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
        {/* 
        // 
        // 
        // 
        // 
        // 
        //  */}
        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">Shift swaps</p>

            <div className="notifExplainRow">
              When a colleague requests a shift swap with you, or they approve
              or decline your swap request with them
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("swaps")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "swaps",
                          enable: state.disabledNotifsEmail.includes("swaps")
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("swaps") ? "invis" : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("swaps")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "swaps",
                          enable: state.disabledNotifsPush.includes("swaps")
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("swaps") ? "invis" : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">Clock card approvals</p>

            <div className="notifExplainRow">
              When a manager approves or declines your clock card
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("clockCard")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "clockCard",
                          enable: state.disabledNotifsEmail.includes(
                            "clockCard"
                          )
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("clockCard")
                        ? "invis"
                        : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("clockCard")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "clockCard",
                          enable: state.disabledNotifsPush.includes("clockCard")
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("clockCard")
                        ? "invis"
                        : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* new notif row */}
        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">Time off in lieu (TOIL)</p>

            <div className="notifExplainRow">
              When a manager approves or declines your request to shorten a
              shift with your time off in lieu balance
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("til")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "til",
                          enable: state.disabledNotifsEmail.includes("til")
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("til") ? "invis" : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("til")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "til",
                          enable: state.disabledNotifsPush.includes("til")
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("til") ? "invis" : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of notif row */}
        {/* end of notif row */}
        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">New shifts</p>

            <div className="notifExplainRow">
              When a manager creates a new shift for you to work or when they
              remove one of your existing shifts.
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("openShift")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "addShift",
                          enable: state.disabledNotifsEmail.includes("addShift")
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("addShift")
                        ? "invis"
                        : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("openShift")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "addShift",
                          enable: state.disabledNotifsPush.includes("addShift")
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("addShift")
                        ? "invis"
                        : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of notif row */}
        {/* new notif row */}
        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">Shift changes</p>

            <div className="notifExplainRow">
              When a manager approves or declines your request to change a shift
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("changeRequests")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "changeRequests",
                          enable: state.disabledNotifsEmail.includes(
                            "changeRequests"
                          )
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("changeRequests")
                        ? "invis"
                        : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("changeRequests")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "changeRequests",
                          enable: state.disabledNotifsPush.includes(
                            "changeRequests"
                          )
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("changeRequests")
                        ? "invis"
                        : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of notif row */}
        {/* end of notif row */}
        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">Shift amendments</p>

            <div className="notifExplainRow">
              When a manager amends one of your shifts
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("change")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "change",
                          enable: state.disabledNotifsEmail.includes("change")
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("change")
                        ? "invis"
                        : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("change")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "change",
                          enable: state.disabledNotifsPush.includes("change")
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("change") ? "invis" : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of notif row */}
        {/* end of notif row */}
        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">Open shifts</p>

            <div className="notifExplainRow">
              When an open shift you have claimed is successful or unsuccessful
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("openShift")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "openShift",
                          enable: state.disabledNotifsEmail.includes(
                            "openShift"
                          )
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("openShift")
                        ? "invis"
                        : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("openShift")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "openShift",
                          enable: state.disabledNotifsPush.includes("openShift")
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("openShift")
                        ? "invis"
                        : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of notif row */}
        {/* end of notif row */}
        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">Availability requests</p>

            <div className="notifExplainRow">
              When your manager requests your availability for a particular week
              (you cannot opt out of availabilty requests by email)
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio x2384898488523 ${
                    !state.disabledNotifsEmail.includes("myAvail")
                      ? "activeNotifImg"
                      : ""
                  }`}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("myAvail")
                        ? "invis"
                        : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("myAvail")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "myAvail",
                          enable: state.disabledNotifsPush.includes("myAvail")
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("myAvail")
                        ? "invis"
                        : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of notif row */}
        {/* end of notif row */}
        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">Timesheet updates</p>

            <div className="notifExplainRow">
              When your manager approves, declines or amends a timesheet you
              have submitted
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("ts")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "ts",
                          enable: state.disabledNotifsEmail.includes("ts")
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("ts") ? "invis" : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("myAvail")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "ts",
                          enable: state.disabledNotifsPush.includes("ts")
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("ts") ? "invis" : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of notif row */}
        {/* new notif row */}
        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">Notes</p>

            <div className="notifExplainRow">
              When a note is added to any of your shifts or leave requests,
              including when a colleague adds a note to an open shift you have
              claimed, and when a colleague adds a rota comment to a day you are
              scheduled to work
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("notes")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "notes",
                          enable: state.disabledNotifsEmail.includes("notes")
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("notes") ? "invis" : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("notes")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "notes",
                          enable: state.disabledNotifsPush.includes("notes")
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("notes") ? "invis" : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of notif row */}
        {/* new notif row */}
        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">
              Acknowledged notes and comments
            </p>

            <div className="notifExplainRow">
              When a note or comment you have made on a shift or day is
              acknowledged by a colleague
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("ack")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "ack",
                          enable: state.disabledNotifsEmail.includes("ack")
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("ack") ? "invis" : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("ack")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "ack",
                          enable: state.disabledNotifsPush.includes("ack")
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("ack") ? "invis" : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of notif row */}
        {/* new notif row */}
        <div
          className={`notifSettingsRow ${isMob ? "notifSettingsRowMob" : ""}`}
        >
          <div className="notifLeft">
            <p className="notifSettingsRowTitle">Return to work forms</p>

            <div className="notifExplainRow">
              When a return to work form is signed off by your manager.
            </div>
          </div>

          <div className="notifRight">
            {state.emailNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("form")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "form",
                          enable: state.disabledNotifsEmail.includes("form")
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("form") ? "invis" : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {state.pushNotifs ? (
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">Push</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsPush.includes("form")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "form",
                          enable: state.disabledNotifsPush.includes("form")
                            ? true
                            : false,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsPush:
                              response.data.disabledNotifsPush,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsPush.includes("form") ? "invis" : ""
                    }`}
                  />{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of notif row */}
        {state.isMgr ? (
          <div className="notifsIsMgrTitleMgr">Notifications as a manager</div>
        ) : (
          ""
        )}
        {/* new notif row */}
        {state.isMgr ? (
          <div
            className={`notifSettingsRow  notifPurBorder ${
              isMob ? "notifSettingsRowMob" : ""
            }`}
          >
            <div className="notifLeft">
              <p className="notifSettingsRowTitle">Employee leave requests</p>

              <div className="notifExplainRow">
                When an employee you manage makes a new leave request
              </div>
            </div>

            <div className="notifRight">
              {state.emailNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Email</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsEmail.includes("requestsMgr")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "requestsMgr",
                            enable: state.disabledNotifsEmail.includes(
                              "requestsMgr"
                            )
                              ? true
                              : false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsEmail:
                                response.data.disabledNotifsEmail,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsEmail.includes("requestsMgr")
                          ? "invis"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.pushNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Push</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsPush.includes("requestsMgr")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "requestsMgr",
                            enable: state.disabledNotifsPush.includes(
                              "requestsMgr"
                            )
                              ? true
                              : false,
                            pushOrEmail: "push",
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsPush:
                                response.data.disabledNotifsPush,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsPush.includes("requestsMgr")
                          ? "invis"
                          : ""
                      }`}
                    />{" "}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* end of notif row */}
        {/* new notif row */}
        {state.isMgr ? (
          <div
            className={`notifSettingsRow  notifPurBorder ${
              isMob ? "notifSettingsRowMob" : ""
            }`}
          >
            <div className="notifLeft">
              <p className="notifSettingsRowTitle">Clocking on and off</p>

              <div className="notifExplainRow">
                When an employee you manage clocks on to or off from a shift
              </div>
            </div>

            <div className="notifRight">
              <div className="notifBtnCol invis">
                <p className="notifTitleBtnTxt">Email</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("clockedOn")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "clockedOn",
                          enable: state.disabledNotifsEmail.includes(
                            "clockedOn"
                          )
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("clockedOn")
                        ? "invis"
                        : ""
                    }`}
                  />
                </div>
              </div>
              <div className="notifBtnCol">
                <p className="notifTitleBtnTxt">In-app</p>
                <div
                  className={`pushEmailRadio ${
                    !state.disabledNotifsEmail.includes("clockedOn")
                      ? "activeNotifImg"
                      : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "clockedOn",
                          enable: state.disabledNotifsEmail.includes(
                            "clockedOn"
                          )
                            ? true
                            : false,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setState({
                            disabledNotifsEmail:
                              response.data.disabledNotifsEmail,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <img
                    src={navyTick}
                    alt="Notify type"
                    className={`pushEmailTickImg ${
                      state.disabledNotifsEmail.includes("clockedOn")
                        ? "invis"
                        : ""
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* end of notif row */}
        {/* new notif row */}
        {state.isMgr ? (
          <div
            className={`notifSettingsRow  notifPurBorder ${
              isMob ? "notifSettingsRowMob" : ""
            }`}
          >
            <div className="notifLeft">
              <p className="notifSettingsRowTitle">Accepted shifts</p>

              <div className="notifExplainRow">
                When an employee you manage accepts or declines a shift
              </div>
            </div>

            <div className="notifRight">
              {state.emailNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Email</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsEmail.includes("accepted")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "accepted",
                            enable: state.disabledNotifsEmail.includes(
                              "accepted"
                            )
                              ? true
                              : false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsEmail:
                                response.data.disabledNotifsEmail,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsEmail.includes("accepted")
                          ? "invis"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.pushNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Push</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsPush.includes("accepted")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "accepted",
                            enable: state.disabledNotifsPush.includes(
                              "accepted"
                            )
                              ? true
                              : false,
                            pushOrEmail: "push",
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsPush:
                                response.data.disabledNotifsPush,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsPush.includes("accepted")
                          ? "invis"
                          : ""
                      }`}
                    />{" "}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* end of notif row */}
        {/* new notif row */}
        {state.isMgr ? (
          <div
            className={`notifSettingsRow  notifPurBorder ${
              isMob ? "notifSettingsRowMob" : ""
            }`}
          >
            <div className="notifLeft">
              <p className="notifSettingsRowTitle">Shift change requests</p>

              <div className="notifExplainRow">
                When an employee you manage makes a request to change the start
                time, end time, break or date of a scheduled shift
              </div>
            </div>

            <div className="notifRight">
              {state.emailNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Email</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsEmail.includes("newChange")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "newChange",
                            enable: state.disabledNotifsEmail.includes(
                              "newChange"
                            )
                              ? true
                              : false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsEmail:
                                response.data.disabledNotifsEmail,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsEmail.includes("newChange")
                          ? "invis"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.pushNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Push</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsPush.includes("newChange")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "newChange",
                            enable: state.disabledNotifsPush.includes(
                              "newChange"
                            )
                              ? true
                              : false,
                            pushOrEmail: "push",
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsPush:
                                response.data.disabledNotifsPush,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsPush.includes("newChange")
                          ? "invis"
                          : ""
                      }`}
                    />{" "}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* end of notif row */}
        {/* new notif row */}
        {state.isMgr ? (
          <div
            className={`notifSettingsRow  notifPurBorder ${
              isMob ? "notifSettingsRowMob" : ""
            }`}
          >
            <div className="notifLeft">
              <p className="notifSettingsRowTitle">TOIL requests</p>

              <div className="notifExplainRow">
                When an employee you manage makes a request to shorten their
                shift with their time off in lieu (TOIL) allowance
              </div>
            </div>

            <div className="notifRight">
              {state.emailNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Email</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsEmail.includes("newTil")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "newTil",
                            enable: state.disabledNotifsEmail.includes("newTil")
                              ? true
                              : false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsEmail:
                                response.data.disabledNotifsEmail,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsEmail.includes("newTil")
                          ? "invis"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.pushNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Push</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsPush.includes("newTil")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "newTil",
                            enable: state.disabledNotifsPush.includes("newTil")
                              ? true
                              : false,
                            pushOrEmail: "push",
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsPush:
                                response.data.disabledNotifsPush,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsPush.includes("newTil")
                          ? "invis"
                          : ""
                      }`}
                    />{" "}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* end of notif row */}
        {/* new notif row */}
        {state.isMgr ? (
          <div
            className={`notifSettingsRow notifPurBorder  ${
              isMob ? "notifSettingsRowMob" : ""
            }`}
          >
            <div className="notifLeft">
              <p className="notifSettingsRowTitle">Shift swaps</p>

              <div className="notifExplainRow">
                When an employee makes a swap request with a colleague,
                including when the colleague approves or declines it
              </div>
            </div>

            <div className="notifRight">
              {state.emailNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Email</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsEmail.includes("swapMade")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "swapMade",
                            enable: state.disabledNotifsEmail.includes(
                              "swapMade"
                            )
                              ? true
                              : false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsEmail:
                                response.data.disabledNotifsEmail,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsEmail.includes("swapMade")
                          ? "invis"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.pushNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Push</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsPush.includes("swapMade")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "swapMade",
                            enable: state.disabledNotifsPush.includes(
                              "swapMade"
                            )
                              ? true
                              : false,
                            pushOrEmail: "push",
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsPush:
                                response.data.disabledNotifsPush,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsPush.includes("swapMade")
                          ? "invis"
                          : ""
                      }`}
                    />{" "}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* end of notif row */}
        {/* new notif row */}
        {state.isMgr ? (
          <div
            className={`notifSettingsRow notifPurBorder  ${
              isMob ? "notifSettingsRowMob" : ""
            }`}
          >
            <div className="notifLeft">
              <p className="notifSettingsRowTitle">Open shift bids</p>

              <div className="notifExplainRow">
                When an employee bids for an open shift in a team you manage
              </div>
            </div>

            <div className="notifRight">
              {state.emailNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Email</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsEmail.includes("openShiftMgr")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "openShiftMgr",
                            enable: state.disabledNotifsEmail.includes(
                              "openShiftMgr"
                            )
                              ? true
                              : false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsEmail:
                                response.data.disabledNotifsEmail,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsEmail.includes("openShiftMgr")
                          ? "invis"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.pushNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Push</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsPush.includes("openShiftMgr")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "openShiftMgr",
                            enable: state.disabledNotifsPush.includes(
                              "openShiftMgr"
                            )
                              ? true
                              : false,
                            pushOrEmail: "push",
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsPush:
                                response.data.disabledNotifsPush,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsPush.includes("openShiftMgr")
                          ? "invis"
                          : ""
                      }`}
                    />{" "}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* end of notif row */}
        {/* end of notif row */}
        {/* new notif row */}
        {state.isMgr ? (
          <div
            className={`notifSettingsRow notifPurBorder  ${
              isMob ? "notifSettingsRowMob" : ""
            }`}
          >
            <div className="notifLeft">
              <p className="notifSettingsRowTitle">Return to work forms</p>

              <div className="notifExplainRow">
                When an employee completes a return-to-work form
              </div>
            </div>

            <div className="notifRight">
              {state.emailNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Email</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsEmail.includes("rtwFormFilled")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "rtwFormFilled",
                            enable: state.disabledNotifsEmail.includes(
                              "rtwFormFilled"
                            )
                              ? true
                              : false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsEmail:
                                response.data.disabledNotifsEmail,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsEmail.includes("rtwFormFilled")
                          ? "invis"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.pushNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Push</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsPush.includes("rtwFormFilled")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "rtwFormFilled",
                            enable: state.disabledNotifsPush.includes(
                              "rtwFormFilled"
                            )
                              ? true
                              : false,
                            pushOrEmail: "push",
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsPush:
                                response.data.disabledNotifsPush,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsPush.includes("rtwFormFilled")
                          ? "invis"
                          : ""
                      }`}
                    />{" "}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* end of notif row */}
        {/* new notif row */}
        {state.isMgr ? (
          <div
            className={`notifSettingsRow  notifPurBorder ${
              isMob ? "notifSettingsRowMob" : ""
            }`}
          >
            <div className="notifLeft">
              <p className="notifSettingsRowTitle">Availability submissions</p>

              <div className="notifExplainRow">
                When an employee you manage submits availability for a week
              </div>
            </div>

            <div className="notifRight">
              {state.emailNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Email</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsEmail.includes("mgrAvail")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "mgrAvail",
                            enable: state.disabledNotifsEmail.includes(
                              "mgrAvail"
                            )
                              ? true
                              : false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsEmail:
                                response.data.disabledNotifsEmail,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsEmail.includes("mgrAvail")
                          ? "invis"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.pushNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Push</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsPush.includes("mgrAvail")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "mgrAvail",
                            enable: state.disabledNotifsPush.includes(
                              "mgrAvail"
                            )
                              ? true
                              : false,
                            pushOrEmail: "push",
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsPush:
                                response.data.disabledNotifsPush,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsPush.includes("mgrAvail")
                          ? "invis"
                          : ""
                      }`}
                    />{" "}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* end of notif row */}

        {/* new notif row */}
        {state.isMgr ? (
          <div
            className={`notifSettingsRow  notifPurBorder ${
              isMob ? "notifSettingsRowMob" : ""
            }`}
          >
            <div className="notifLeft">
              <p className="notifSettingsRowTitle">Employee timesheets</p>

              <div className="notifExplainRow">
                When an employee you manage submits a timesheet, or updates an
                existing one
              </div>
            </div>

            <div className="notifRight">
              {state.emailNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Email</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsEmail.includes("mgrTs")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "mgrTs",
                            enable: state.disabledNotifsEmail.includes("mgrTs")
                              ? true
                              : false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsEmail:
                                response.data.disabledNotifsEmail,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsEmail.includes("mgrTs")
                          ? "invis"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.pushNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Push</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsPush.includes("mgrTs")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "mgrTs",
                            enable: state.disabledNotifsPush.includes("mgrTs")
                              ? true
                              : false,
                            pushOrEmail: "push",
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsPush:
                                response.data.disabledNotifsPush,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsPush.includes("mgrTs")
                          ? "invis"
                          : ""
                      }`}
                    />{" "}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* end of notif row */}
        {/* new notif row */}

        {state.isMgr ? (
          <div
            className={`notifSettingsRow notifPurBorder  ${
              isMob ? "notifSettingsRowMob" : ""
            }`}
          >
            <div className="notifLeft">
              <p className="notifSettingsRowTitle">Staff notes</p>

              <div className="notifExplainRow">
                When an employee that you manage adds a note to any of their
                shifts or leave requests, including when they add a note to an
                open shift, and when they add a rota comment
              </div>
            </div>

            <div className="notifRight">
              {state.emailNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Email</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsEmail.includes("mgrNotes")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "mgrNotes",
                            enable: state.disabledNotifsEmail.includes(
                              "mgrNotes"
                            )
                              ? true
                              : false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsEmail:
                                response.data.disabledNotifsEmail,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsEmail.includes("mgrNotes")
                          ? "invis"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.pushNotifs ? (
                <div className="notifBtnCol">
                  <p className="notifTitleBtnTxt">Push</p>
                  <div
                    className={`pushEmailRadio ${
                      !state.disabledNotifsPush.includes("mgrNotes")
                        ? "activeNotifImg"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/edit-notifications`,
                          {
                            notifName: "mgrNotes",
                            enable: state.disabledNotifsPush.includes(
                              "mgrNotes"
                            )
                              ? true
                              : false,
                            pushOrEmail: "push",
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              disabledNotifsPush:
                                response.data.disabledNotifsPush,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={navyTick}
                      alt="Notify type"
                      className={`pushEmailTickImg ${
                        state.disabledNotifsPush.includes("mgrNotes")
                          ? "invis"
                          : ""
                      }`}
                    />{" "}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* end of notif row */}
        {/* end of notif row */}
        <br />
        <br />
      </div>
      {/* end of scroll box  */}
    </div>
  );
};

export default NotifSettings;
