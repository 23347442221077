import React, { useContext, useState, useEffect, useMemo, useRef } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";

import "../../../styles/settings.css";
import "../../../styles/admin.css";

import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";
import { AdminContext } from "../../../contexts/AdminContext";
import { UserContext } from "../../../contexts/UserContext";

import serverURL from "../../../serverURL";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import purpleCog from "../../../img/general/whiteCog.svg";
import navyBin from "../../../img/general/navyBin.svg";
import whiteAddThin from "../../../img/general/whiteAddThin.svg";
import whiteTick from "../../../img/general/whiteTick.svg";
import dateStringer from "../../../tools/dateStringer";
const LeaveTypes = ({
  sureDeleteShiftLeaveType,
  setSureDeleteLeaveType,
  shiftLeaveTypes,
  setShiftLeaveTypes,
}) => {
  const { setAccNav, mob, setAdminPage } = useContext(StyleContext);
  const { currencySymbol, checkUserState, setCheckUserState } =
    useContext(UserContext);

  const {
    annLeavePercentage,
    setAnnLeavePercentage,
    enableTil,
    setEnableTil,
    customLeaveTypes,
    setCustomLeaveTypes,
  } = useContext(AdminContext);
  const {
    setSelectedNav,
    setShowLeaveTypeModal,
    setSureRemoveLeaveType,
    device,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setAccNav,
      mob,
      setAdminPage,
      currencySymbol,
      annLeavePercentage,
      setAnnLeavePercentage,
      enableTil,
      setEnableTil,
      customLeaveTypes,
      setCustomLeaveTypes,
      setSelectedNav,
      setShowLeaveTypeModal,
      setSureRemoveLeaveType,
      device,
    }),
    [
      setAccNav,
      mob,
      setAdminPage,
      currencySymbol,
      annLeavePercentage,
      setAnnLeavePercentage,
      enableTil,
      setEnableTil,
      customLeaveTypes,
      setCustomLeaveTypes,
      setSelectedNav,
      setShowLeaveTypeModal,
      setSureRemoveLeaveType,
      device,
    ]
  );

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let [selectedTilWks, setSelectedTilWks] = useState(52);
  let [pHols, setPHols] = useState([]);
  let [selectedYr, setSelectedYr] = useState();

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 3) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyBin}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />

      <img
        src={whiteAddThin}
        alt="Edit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={purpleCog}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [fromChecklist, setFromChecklist] = useState(false);
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && dateStringer.cleanParam(params.check)) {
      setFromChecklist(true);
    }
  }, []);

  // if the page is refreshed, set the AccNav to settings
  useEffect(() => {
    memoVals.setAccNav((x) => "admin");
    memoVals.setSelectedNav((x) => 5.1);
  }, []);
  let [showRemoved, setShowRemoved] = useState(true);

  // useEffect(() => {
  //   if (showRemoved) {
  //     let filt = memoVals.customLeaveTypes.filter((x) => {
  //       return x.removed;
  //     });

  //     if (!filt[0]) {
  //       setShowRemoved(false);
  //     }
  //   }
  // }, [memoVals.customLeaveTypes]);

  let shortenLocation = (val) => {
    if (!val) {
      return "";
    }
    if (val.length > 25) {
      return `${val.substr(0, 22)}...`;
    } else return val;
  };

  useEffect(() => {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Update the slug
    currentURL.pathname = "/admin/leave-settings";

    // Change the URL without reloading the page
    window.history.pushState({}, "", currentURL.href);
  }, []);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-admin-leave-types-all-data`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          let annualLeaveObj = Object.assign({}, response.data.annualLeaveData);
          setSelectedTilWks(response.data.toilWeeksLimit);
          memoVals.setAnnLeavePercentage(
            (x) => response.data.annLeavePercentage
          );
          memoVals.setEnableTil((x) => response.data.enableTil);
          setPHols(response.data.publicHolsArr || []);
          if (response.data.publicHolsArr && response.data.publicHolsArr[0]) {
            setSelectedYr(response.data.publicHolsArr[1].yr);
          }
          setShiftLeaveTypes(response.data.shiftLeaveTypes);

          memoVals.setCustomLeaveTypes((x) => response.data.customLeaveTypes);
          setDataLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [pHolQty, setPHolQty] = useState(0);

  let [tilWeeks, setTilWeeks] = useState([]);
  useEffect(() => {
    let wkArr = [];
    for (var i = 0; i < 520; i++) {
      wkArr.push(i + 1);
    }

    setTilWeeks(wkArr);

    let filt = pHols.filter((x) => {
      return x.yr === selectedYr;
    });

    setPHolQty("  ");
    if (filt[0]) {
      setPHolQty(filt[0].qty);
    } else {
      setPHolQty(0);
    }
  }, [selectedYr]);

  let [hideP, setHideP] = useState(false);

  let calcALdaysPerYr = (daysPerWeek) => {
    let percent = parseFloat(memoVals.annLeavePercentage);
    let percentToDec = percent * 0.01 + 1;
    let dummyHpw = 35;
    let allowanceWeeks = 52 - 52 / percentToDec; // 46.4

    let totHours = dummyHpw * (52 - allowanceWeeks) * (percent * 0.1);
    let trueAllowanceWeeks = totHours / dummyHpw;
    let allowDays = trueAllowanceWeeks * daysPerWeek;

    let absoluteFigure = Math.round(allowDays / 10 / 0.05) * 0.05;

    let rounded = Math.ceil(absoluteFigure / 0.5) * 0.5;
    return rounded;
  };

  let [showAddShiftLeave, setShowAddShiftLeave] = useState(false);
  let [addShiftLeaveName, setAddShiftLeaveName] = useState("");

  let generateTypes = memoVals.customLeaveTypes
    .filter((x) => {
      return !x.removed;
    })
    .map((item) => {
      return (
        <div key={item._id}>
          <div
            className="specialDayUnit customLeaveTypeUnit"
            onClick={() => {
              memoVals.setShowLeaveTypeModal((x) => {
                return {
                  show: true,
                  leaveTypeID: item.leaveTypeID,
                  daysOrHours: item.daysOrHours,
                  allowance: item.customLeaveDefaultDays,
                  rate: item.rate,
                  unlimited: item.unlimited,
                  name: item.name,
                  blockRequests: item.blockRequests,
                };
              });
            }}
          >
            <div className="customTypeLeft">
              <span onClick={() => {}}>
                <p
                  className={`specialDayDate ${
                    shortenLocation(item.name).length > 18 ? "x283819123" : ""
                  }`}
                >
                  {shortenLocation(item.name)}
                </p>
              </span>
              <p className="customLeaveTypeSubTxt">
                {item.rate === 0 || item.rate === "0"
                  ? "Unpaid"
                  : `Paid | ${item.rate}x`}{" "}
                |{" "}
                {item.customLeaveDefaultDays === 366
                  ? "Unlimited"
                  : item.daysOrHours === "days"
                  ? `${item.customLeaveDefaultDays} day${
                      item.customLeaveDefaultDays === 1 ? "" : "s"
                    }`
                  : `${item.customLeaveDefaultDays} hour${
                      item.customLeaveDefaultDays === 1 ? "" : "s"
                    }`}
              </p>
            </div>
            <img
              className="gpsZoneDeleteCrossImg"
              src={navyBin}
              alt="Delete"
              onClick={(e) => {
                e.stopPropagation();
                if (!item.removed) {
                  memoVals.setSureRemoveLeaveType((x) => item.leaveTypeID);
                }
              }}
            />
          </div>
        </div>
      );
    });

  let generateShiftLeaveTypes = shiftLeaveTypes.map((item) => {
    return (
      <div key={item.leaveTypeID}>
        <div className="specialDayUnit customLeaveTypeUnit shiftLeaveTypeNoHover">
          <div className="customTypeLeft">
            <span onClick={() => {}}>
              <p
                className={`specialDayDate ${
                  shortenLocation(item.name).length > 18 ? "x283819123" : ""
                }`}
              >
                {shortenLocation(item.name)}
              </p>
            </span>
            <p className="customLeaveTypeSubTxt">Rate depends on the shift</p>
          </div>
          <img
            className="gpsZoneDeleteCrossImg"
            src={navyBin}
            alt="Delete"
            onClick={() => {
              setSureDeleteLeaveType({
                show: true,
                leaveTypeID: item.leaveTypeID,
                name: item.name,
              });
            }}
          />
        </div>
      </div>
    );
  });

  let generateRemovedTypes = memoVals.customLeaveTypes
    .filter((x) => {
      return x.removed;
    })
    .map((item) => {
      return (
        <div key={item._id}>
          <div
            className="specialDayUnit customLeaveTypeUnit zoomInSlow"
            onClick={() => {
              memoVals.setShowLeaveTypeModal((x) => {
                return {
                  show: true,
                  leaveTypeID: item.leaveTypeID,
                  daysOrHours: item.daysOrHours,
                  allowance: item.customLeaveDefaultDays,
                  rate: item.rate,
                  unlimited: item.unlimited,
                  name: item.name,
                  removed: true,
                  blockRequests: item.blockRequests,
                };
              });
            }}
          >
            <div className="customTypeLeft">
              <span onClick={() => {}}>
                <p
                  className={`specialDayDate ${
                    shortenLocation(item.name).length > 18 ? "x283819123" : ""
                  }`}
                >
                  {shortenLocation(item.name)}
                </p>
              </span>
              <p className="customLeaveTypeSubTxt">
                {item.paid ? `Paid | ${item.rate}x | ` : "Unpaid | "}
                {item.customLeaveDefaultDays === 366
                  ? "Unlimited"
                  : item.daysOrHours === "days"
                  ? `${item.customLeaveDefaultDays} day${
                      item.customLeaveDefaultDays === 1 ? "" : "s"
                    }`
                  : `${item.customLeaveDefaultDays} hour${
                      item.customLeaveDefaultDays === 1 ? "" : "s"
                    }`}
              </p>
            </div>
            <p className="removedTypeTxt">Archived</p>
          </div>
        </div>
      );
    });

  let addShiftLeaveRef = useRef();
  let enterFct = (e) => {
    if (e.key === "Enter" || e === "add") {
      axios
        .post(
          `${serverURL}/add-shift-leave-type`,
          { name: addShiftLeaveName },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setShiftLeaveTypes(response.data.shiftLeaveTypes);
            setShowAddShiftLeave(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (addShiftLeaveRef.current) {
      addShiftLeaveRef.current.focus();
    }
  }, [showAddShiftLeave]);
  // master

  return (
    <div
      className={`AdminSettingsContainer ${
        memoVals.mob
          ? `AdminSettingsContainerMob ${
              memoVals.device.ios ? "AdminSettingsContainerMobIos" : ""
            }`
          : "borderRightGrey"
      }`}
    >
      {/* <div className='whiteGradientAdmin'></div> */}

      {loading ? (
        <img
          src={horizontalBalls}
          alt="Loading"
          className="teamSchedLoadingBallsImg"
        />
      ) : (
        <div className="AdminInner">
          {/* Annual Leave box */}
          <div
            className={`annLeaveBox AdminRowContainer ${
              !memoVals.mob ? "annLeaveBoxDesktop" : ""
            }`}
          >
            <div className="annLeaveHeader">
              <p className="annLeaveTitle">Annual Leave</p>

              {window.innerWidth < 544 ? (
                <div
                  className={`mobAnnLeavSelector ${
                    window.innerWidth < 415 ? "x2989423" : ""
                  }`}
                >
                  <select
                    className="minsPerHourDropdownWidth"
                    value={memoVals.annLeavePercentage}
                    onChange={(e) => {
                      axios
                        .post(
                          `${serverURL}/edit-ann-leave-percentage`,
                          { data: e.target.value },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            if (!checkUserState.checklistDone.includes("4")) {
                              setCheckUserState({
                                checklistDone: [
                                  ...checkUserState.checklistDone,
                                  "4",
                                ],
                              });
                            }
                            memoVals.setAnnLeavePercentage(
                              (x) => response.data.annLeavePercentage
                            );
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <option value="0">--</option>
                    <option value="5.051">2.5</option>
                    <option value="5.263">2.6</option>
                    <option value="5.477">2.7</option>
                    <option value="5.691">2.8</option>
                    <option value="5.906">2.9</option>
                    <option value="6.122">3</option>
                    <option value="6.339">3.1</option>
                    <option value="6.557">3.2</option>
                    <option value="6.776">3.3</option>
                    <option value="6.996">3.4</option>
                    <option value="7.216">3.5</option>
                    <option value="7.438">3.6</option>
                    <option value="7.660">3.7</option>
                    <option value="7.884">3.8</option>
                    <option value="8.108">3.9</option>
                    <option value="8.333">4</option>
                    <option value="8.559">4.1</option>
                    <option value="8.787">4.2</option>
                    <option value="9.015">4.3</option>
                    <option value="9.244">4.4</option>
                    <option value="9.474">4.5</option>
                    <option value="9.705">4.6</option>
                    <option value="9.937">4.7</option>
                    <option value="10.169">4.8</option>
                    <option value="10.403">4.9</option>
                    <option value="10.638">5</option>
                    <option value="10.874">5.1</option>
                    <option value="11.111">5.2</option>
                    <option value="11.349">5.3</option>
                    <option value="11.588">5.4</option>
                    <option value="11.828">5.5</option>

                    <option value="12.069">5.6</option>

                    <option value="12.311">5.7</option>
                    <option value="12.554">5.8</option>
                    <option value="12.798">5.9</option>
                    <option value="13.043">6</option>
                    <option value="13.290">6.1</option>
                    <option value="13.537">6.2</option>
                    <option value="13.786">6.3</option>
                    <option value="14.035">6.4</option>
                    <option value="14.286">6.5</option>
                    <option value="14.537">6.6</option>
                    <option value="14.790">6.7</option>
                    <option value="15.044">6.8</option>
                    <option value="15.299">6.9</option>
                    <option value="15.556">7</option>
                    <option value="15.813">7.1</option>
                    <option value="16.071">7.2</option>
                    <option value="16.331">7.3</option>
                    <option value="16.592">7.4</option>
                    <option value="16.854">7.5</option>
                    <option value="17.117">7.6</option>
                    <option value="17.381">7.7</option>
                    <option value="17.647">7.8</option>
                    <option value="17.914">7.9</option>
                    <option value="18.182">8</option>
                    <option value="18.451">8.1</option>
                    <option value="18.721">8.2</option>
                    <option value="18.993">8.3</option>
                    <option value="19.266">8.4</option>
                    <option value="19.540">8.5</option>
                    <option value="19.816">8.6</option>
                    <option value="20.092">8.7</option>
                    <option value="20.370">8.8</option>
                    <option value="20.650">8.9</option>
                    <option value="20.930">9</option>
                    <option value="21.212">9.1</option>
                    <option value="21.495">9.2</option>
                    <option value="21.780">9.3</option>
                    <option value="22.066">9.4</option>
                    <option value="22.353">9.5</option>
                    <option value="22.642">9.6</option>
                    <option value="22.931">9.7</option>
                    <option value="23.223">9.8</option>
                    <option value="23.515">9.9</option>
                    <option value="23.810">10</option>
                    <option value="24.105">10.1</option>
                    <option value="24.402">10.2</option>
                    <option value="24.700">10.3</option>
                    <option value="25.000">10.4</option>
                    <option value="25.301">10.5</option>
                    <option value="25.604">10.6</option>
                    <option value="25.908">10.7</option>
                    <option value="26.214">10.8</option>
                    <option value="26.521">10.9</option>
                    <option value="26.829">11</option>
                    <option value="27.139">11.1</option>
                    <option value="27.451">11.2</option>
                    <option value="27.764">11.3</option>
                    <option value="28.079">11.4</option>
                    <option value="28.395">11.5</option>
                    <option value="28.713">11.6</option>
                    <option value="29.032">11.7</option>
                    <option value="29.353">11.8</option>
                    <option value="29.676">11.9</option>
                    <option value="30">12</option>
                    <option value="30.326">12.1</option>
                    <option value="30.653">12.2</option>
                    <option value="30.982">12.3</option>
                    <option value="31.313">12.4</option>
                    <option value="31.646">12.5</option>
                    <option value="31.980">12.6</option>
                    <option value="32.316">12.7</option>
                    <option value="32.653">12.8</option>
                    <option value="32.992">12.9</option>
                    <option value="33.333">13</option>
                    <option value="33.676">13.1</option>
                    <option value="34.021">13.2</option>
                    <option value="34.367">13.3</option>
                  </select>
                  <p
                    className={`annLeaveLowerTxt3 ${
                      window.innerWidth < 415 ? "x2484882" : ""
                    }`}
                  >
                    weeks per year
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className={`${
                window.innerWidth < 544 ? "mobAnnLeaveContainer" : ""
              }`}
            >
              <div
                className={`annLeaveRow ${
                  window.innerWidth < 544 ? "annLeaveRowBlock" : ""
                }`}
              >
                <div
                  className={`annLeaveRowLower ${
                    window.innerWidth < 544 ? "x198348927133" : ""
                  } ${window.innerWidth < 415 ? "x289492894892" : ""}`}
                >
                  {/* <p className="annLeaveLowerTxt1">Give</p> */}
                  {window.innerWidth >= 544 ? (
                    <select
                      className="minsPerHourDropdownWidth"
                      value={memoVals.annLeavePercentage}
                      onChange={(e) => {
                        axios
                          .post(
                            `${serverURL}/edit-ann-leave-percentage`,
                            { data: e.target.value },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              if (!checkUserState.checklistDone.includes("4")) {
                                setCheckUserState({
                                  checklistDone: [
                                    ...checkUserState.checklistDone,
                                    "4",
                                  ],
                                });
                              }
                              memoVals.setAnnLeavePercentage(
                                (x) => response.data.annLeavePercentage
                              );
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      <option value="0">--</option>
                      <option value="5.051">2.5</option>
                      <option value="5.263">2.6</option>
                      <option value="5.477">2.7</option>
                      <option value="5.691">2.8</option>
                      <option value="5.906">2.9</option>
                      <option value="6.122">3</option>
                      <option value="6.339">3.1</option>
                      <option value="6.557">3.2</option>
                      <option value="6.776">3.3</option>
                      <option value="6.996">3.4</option>
                      <option value="7.216">3.5</option>
                      <option value="7.438">3.6</option>
                      <option value="7.660">3.7</option>
                      <option value="7.884">3.8</option>
                      <option value="8.108">3.9</option>
                      <option value="8.333">4</option>
                      <option value="8.559">4.1</option>
                      <option value="8.787">4.2</option>
                      <option value="9.015">4.3</option>
                      <option value="9.244">4.4</option>
                      <option value="9.474">4.5</option>
                      <option value="9.705">4.6</option>
                      <option value="9.937">4.7</option>
                      <option value="10.169">4.8</option>
                      <option value="10.403">4.9</option>
                      <option value="10.638">5</option>
                      <option value="10.874">5.1</option>
                      <option value="11.111">5.2</option>
                      <option value="11.349">5.3</option>
                      <option value="11.588">5.4</option>
                      <option value="11.828">5.5</option>

                      <option value="12.069">5.6</option>

                      <option value="12.311">5.7</option>
                      <option value="12.554">5.8</option>
                      <option value="12.798">5.9</option>
                      <option value="13.043">6</option>
                      <option value="13.290">6.1</option>
                      <option value="13.537">6.2</option>
                      <option value="13.786">6.3</option>
                      <option value="14.035">6.4</option>
                      <option value="14.286">6.5</option>
                      <option value="14.537">6.6</option>
                      <option value="14.790">6.7</option>
                      <option value="15.044">6.8</option>
                      <option value="15.299">6.9</option>
                      <option value="15.556">7</option>
                      <option value="15.813">7.1</option>
                      <option value="16.071">7.2</option>
                      <option value="16.331">7.3</option>
                      <option value="16.592">7.4</option>
                      <option value="16.854">7.5</option>
                      <option value="17.117">7.6</option>
                      <option value="17.381">7.7</option>
                      <option value="17.647">7.8</option>
                      <option value="17.914">7.9</option>
                      <option value="18.182">8</option>
                      <option value="18.451">8.1</option>
                      <option value="18.721">8.2</option>
                      <option value="18.993">8.3</option>
                      <option value="19.266">8.4</option>
                      <option value="19.540">8.5</option>
                      <option value="19.816">8.6</option>
                      <option value="20.092">8.7</option>
                      <option value="20.370">8.8</option>
                      <option value="20.650">8.9</option>
                      <option value="20.930">9</option>
                      <option value="21.212">9.1</option>
                      <option value="21.495">9.2</option>
                      <option value="21.780">9.3</option>
                      <option value="22.066">9.4</option>
                      <option value="22.353">9.5</option>
                      <option value="22.642">9.6</option>
                      <option value="22.931">9.7</option>
                      <option value="23.223">9.8</option>
                      <option value="23.515">9.9</option>
                      <option value="23.810">10</option>
                      <option value="24.105">10.1</option>
                      <option value="24.402">10.2</option>
                      <option value="24.700">10.3</option>
                      <option value="25.000">10.4</option>
                      <option value="25.301">10.5</option>
                      <option value="25.604">10.6</option>
                      <option value="25.908">10.7</option>
                      <option value="26.214">10.8</option>
                      <option value="26.521">10.9</option>
                      <option value="26.829">11</option>
                      <option value="27.139">11.1</option>
                      <option value="27.451">11.2</option>
                      <option value="27.764">11.3</option>
                      <option value="28.079">11.4</option>
                      <option value="28.395">11.5</option>
                      <option value="28.713">11.6</option>
                      <option value="29.032">11.7</option>
                      <option value="29.353">11.8</option>
                      <option value="29.676">11.9</option>
                      <option value="30">12</option>
                      <option value="30.326">12.1</option>
                      <option value="30.653">12.2</option>
                      <option value="30.982">12.3</option>
                      <option value="31.313">12.4</option>
                      <option value="31.646">12.5</option>
                      <option value="31.980">12.6</option>
                      <option value="32.316">12.7</option>
                      <option value="32.653">12.8</option>
                      <option value="32.992">12.9</option>
                      <option value="33.333">13</option>
                      <option value="33.676">13.1</option>
                      <option value="34.021">13.2</option>
                      <option value="34.367">13.3</option>
                    </select>
                  ) : (
                    ""
                  )}
                  {window.innerWidth >= 544 ? (
                    <p className="annLeaveLowerTxt2">weeks per year</p>
                  ) : (
                    ""
                  )}

                  {/* {window.innerWidth >= 544 ? ( */}
                  <p className="annLeaveSubTxt">
                    This is the default allowance. Managers and administrators
                    can individually amend employees' leave allowances.
                  </p>

                  {fromChecklist && (
                    <p
                      className="saveCheckListDaysBtn lilacColours saveCheckLeave"
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/check-done`,
                            {
                              number: "4",
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              window.history.pushState(
                                {},
                                document.title,
                                window.location.pathname
                              );

                              // action data here
                              setCheckUserState({
                                checklistDone: response.data.checklistDone,
                              });
                              setFromChecklist(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      Complete checklist action{" "}
                      <img
                        src={whiteTick}
                        alt="Complete"
                        className="compChTickWhiteImg"
                      />
                    </p>
                  )}
                </div>

                <div
                  className={`annLeaveIllustrationBox ${
                    window.innerWidth < 544 ? "alignStart" : ""
                  }`}
                >
                  {/* <div className="annLeaveVisualiserContainer"> */}
                  {/* <p className="annLeaveUpperTxt">How many days is that?</p> */}
                  {/* </div> */}
                  <div
                    className={`annleaveIllustrationTable ${
                      window.innerWidth < 544 ? "mobIllusTable" : ""
                    }`}
                  >
                    <div className="illusRow topIllusRow">
                      <p className="illusTitleHeader">Days worked weekly</p>
                      <p className="illusTitleHeader">Days off yearly</p>
                    </div>
                    <div className="illusRow">
                      <p className="illusTitle">1 day</p>
                      <p className="illusContent">{calcALdaysPerYr(1)}</p>
                    </div>
                    <div className="illusRow illusRowHighlighted">
                      <p className="illusTitle">2 days</p>
                      <p className="illusContent">{calcALdaysPerYr(2)}</p>
                    </div>
                    <div className="illusRow">
                      <p className="illusTitle">3 days</p>
                      <p className="illusContent">{calcALdaysPerYr(3)}</p>
                    </div>
                    <div className="illusRow illusRowHighlighted">
                      <p className="illusTitle">4 days</p>
                      <p className="illusContent">{calcALdaysPerYr(4)}</p>
                    </div>
                    <div className="illusRow">
                      <p className="illusTitle">5 days</p>
                      <p className="illusContent">{calcALdaysPerYr(5)}</p>
                    </div>
                    <div className="illusRow illusRowHighlighted">
                      <p className="illusTitle">6 days</p>
                      <p className="illusContent">{calcALdaysPerYr(6)}</p>
                    </div>
                    <div className="illusRow noBorder">
                      <p className="illusTitle">7 days</p>
                      <p className="illusContent">{calcALdaysPerYr(7)}</p>
                    </div>
                  </div>

                  {pHols[0] && pHols[1] && pHols[2] ? (
                    <div
                      className={`pHolsLeaveDiv ${
                        memoVals.mob
                          ? `pHolsLeaveDivMob ${
                              window.innerWidth < 544
                                ? "pHolsLeaveDivMobSub544"
                                : ""
                            }`
                          : ""
                      }`}
                    >
                      <p className={`plusPholsTitle  ${hideP ? "invis_" : ""}`}>
                        + <span className="x283819834">{pHolQty}</span> paid{" "}
                        {memoVals.currencySymbol === "£" ? "bank" : "public"}{" "}
                        holidays
                        <br />
                        <div className={`pHolYrSelDiv`}>
                          <p className="pHolIn">in:</p>
                          <p
                            className={`pHolYr ${
                              selectedYr === pHols[0].yr ? "selPHolYr" : ""
                            }`}
                            onClick={() => {
                              setSelectedYr(pHols[0].yr);
                              setHideP(true);
                              setTimeout(() => {
                                setHideP(false);
                              }, 250);
                            }}
                          >
                            {pHols[0].yr}
                          </p>
                          <p
                            onClick={() => {
                              setSelectedYr(pHols[1].yr);
                              setHideP(true);
                              setTimeout(() => {
                                setHideP(false);
                              }, 250);
                            }}
                            className={`pHolYr ${
                              selectedYr === pHols[1].yr ? "selPHolYr" : ""
                            }`}
                          >
                            {pHols[1].yr}
                          </p>
                          <p
                            onClick={() => {
                              setSelectedYr(pHols[2].yr);
                              setHideP(true);
                              setTimeout(() => {
                                setHideP(false);
                              }, 250);
                            }}
                            className={`pHolYr ${
                              selectedYr === pHols[2].yr ? "selPHolYr" : ""
                            }`}
                          >
                            {pHols[2].yr}
                          </p>
                        </div>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="pHolLeaveSettsBtn navyColours"
                    onClick={() => {
                      memoVals.setAdminPage((x) => "Closed Days");
                    }}
                  >
                    {memoVals.currencySymbol === "£" ? "Bank" : "Public"}{" "}
                    holiday settings{" "}
                    <img
                      src={purpleCog}
                      alt="Purple cog"
                      className="purCogPH"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End of annual leave box */}
          {/* ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow ${
              !memoVals.enableTil ? "clickShiftRowAdmin" : ""
            } ${memoVals.mob ? "clickShiftRowAdminMob mobileAdminRow" : ""}`}
            onClick={(e) => {
              if (!memoVals.enableTil) {
                memoVals.setEnableTil((x) => !memoVals.enableTil);
                axios
                  .post(
                    `${serverURL}/admin-enable-til`,
                    { data: !memoVals.enableTil },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message.includes("logout")) {
                      window.location.href = "/signin";
                    }
                    if (response.data.message === "success") {
                      // code here
                      memoVals.setEnableTil((x) => response.data.enableTil);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <div className="settingsRow tilSettingsRow">
              {" "}
              <div className="tilRowInline">
                <div className="rowWithSubText">
                  <p className="absenceRowMainText">Time-off-in-lieu (TOIL)</p>
                  <p className="absenceRowSubText overtimeRowSubText">
                    Allow employees to undertake unpaid work with the intention
                    to claim this time as time off instead
                  </p>
                </div>
                <div>
                  {/*  KNOB BEGIN */}
                  <div
                    className="knobContainer knobContainerSettings   "
                    onClick={(e) => {
                      memoVals.setEnableTil((x) => !memoVals.enableTil);
                      axios
                        .post(
                          `${serverURL}/admin-enable-til`,
                          { data: !memoVals.enableTil },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            // code here
                            memoVals.setEnableTil(
                              (x) => response.data.enableTil
                            );
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <div
                      className={`switchKnob ${
                        memoVals.enableTil ? "knobOn" : "knobOff"
                      }`}
                    ></div>

                    <div className="knobBg"></div>
                  </div>
                  {/*  KNOB END */}
                </div>
              </div>{" "}
              {memoVals.enableTil ? (
                <div className="tilRowInline">
                  <div className="rowWithSubText x198292ss">
                    <p className="absenceRowMainText">TOIL period</p>
                    <p className="absenceRowSubText overtimeRowSubText">
                      How many weeks do employees have to take off their lieu
                      before it expires?
                    </p>
                  </div>
                  <div>
                    <select
                      className="minsPerHourDropdownWidth tilWkDrop"
                      value={selectedTilWks}
                      onChange={(e) => {
                        let val = e.target.value;
                        setSelectedTilWks(val);
                        axios
                          .post(
                            `${serverURL}/edit-toil-period`,
                            { data: parseInt(val) },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              setSelectedTilWks(response.data.data);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      {tilWeeks.map((wk) => {
                        return (
                          <option key={wk} value={parseInt(wk)}>
                            {wk === 52
                              ? "1 year"
                              : wk === 104
                              ? "2 years"
                              : wk === 156
                              ? "3 years"
                              : wk === 208
                              ? "4 years"
                              : wk === 260
                              ? "5 years"
                              : wk === 312
                              ? "6 years"
                              : wk === 364
                              ? "7 years"
                              : wk === 416
                              ? "8 years"
                              : wk === 468
                              ? "9 years"
                              : wk === 520
                              ? "10 years"
                              : `${wk} week${wk === 1 ? "" : "s"}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}

          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`gpsAdminRow ${memoVals.mob ? "gpsAdminRowMob" : ""}`}
          >
            <div className={`settingsRow`}>
              <div className="rowWithSubTextGPS">
                <p className="absenceRowMainText">Custom leave types</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Create types of leave with individual rules (eg. unpaid leave,
                  or half-paid leave).
                </p>
              </div>{" "}
            </div>
            <div
              className={`${
                memoVals.customLeaveTypes.length > 0
                  ? "gpsList paddingBottom0"
                  : "none"
              }`}
            >
              <div className="specialDayUnitsContainer">
                {generateTypes}
                {memoVals.customLeaveTypes && memoVals.customLeaveTypes[0] ? (
                  <div className="showRemovedTypesDivider"></div>
                ) : (
                  ""
                )}
                {generateRemovedTypes}
              </div>
            </div>{" "}
            <div className={`addZoneParent`}>
              <p
                className="addGPSBtn addCustomBtn blueColours"
                onClick={() => {
                  memoVals.setShowLeaveTypeModal((x) => {
                    return {
                      new: true,
                      show: true,
                      leaveTypeID: "",
                      daysOrHours: "days",
                      allowance: 2,
                      rate: 1,
                      name: "",
                    };
                  });
                }}
              >
                Add leave type{" "}
                <img
                  src={whiteAddThin}
                  alt="Add zone"
                  className="addZonePlus addTypePlusImg"
                />
              </p>
            </div>
            {/* {memoVals.customLeaveTypes.filter((x) => {
              return x.removed;
            }).length > 0 ? (
              <p
                className="showRemovedTypes"
                onClick={() => {
                  setShowRemoved(!showRemoved);
                }}
              >
                {showRemoved ? "Hide" : "Show"} archived types
              </p>
            ) : (
              ""
            )} */}
          </div>
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`gpsAdminRow ${memoVals.mob ? "gpsAdminRowMob" : ""}`}
          >
            <div className={`settingsRow`}>
              <div className="rowWithSubTextGPS">
                <p className="absenceRowMainText">Shift leave</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Create categories of discretionary paid leave that can be
                  taken during shifts for activities such as attending a
                  doctor's appointment or donating blood. Only managers are
                  authorized to add these types of leave, and they are paid at
                  the rate corresponding to the relevant shift.
                </p>
              </div>{" "}
            </div>
            <div
              className={`${
                memoVals.customLeaveTypes.length > 0
                  ? "gpsList paddingBottom0"
                  : "none"
              }`}
            >
              <div className="specialDayUnitsContainer">
                {generateShiftLeaveTypes}
              </div>
            </div>{" "}
            <div className={`addZoneParent`}>
              {showAddShiftLeave ? (
                <div className="addShiftleaveTypeDiv">
                  <input
                    onKeyPress={(e) => {
                      enterFct(e);
                    }}
                    ref={addShiftLeaveRef}
                    type="text"
                    className="addShiftleaveNameInput"
                    placeholder="Shift leave type name"
                    value={addShiftLeaveName}
                    onChange={(e) => {
                      setAddShiftLeaveName(e.target.value);
                    }}
                  ></input>
                  <p
                    className={`saveAddShLeaveBtn ${
                      !addShiftLeaveName ? "disabled" : ""
                    }`}
                    onClick={() => {
                      enterFct("add");
                    }}
                  >
                    Add
                  </p>
                </div>
              ) : (
                <p
                  className="addGPSBtn addCustomBtn blueColours"
                  onClick={() => {
                    setAddShiftLeaveName("");
                    setShowAddShiftLeave(true);
                  }}
                >
                  Add leave type{" "}
                  <img
                    src={whiteAddThin}
                    alt="Add zone"
                    className="addZonePlus addTypePlusImg"
                  />
                </p>
              )}
            </div>
            {/* {memoVals.customLeaveTypes.filter((x) => {
              return x.removed;
            }).length > 0 ? (
              <p
                className="showRemovedTypes"
                onClick={() => {
                  setShowRemoved(!showRemoved);
                }}
              >
                {showRemoved ? "Hide" : "Show"} archived types
              </p>
            ) : (
              ""
            )} */}
          </div>
          <br />
        </div>
      )}

      {imgPreload}
    </div>
  );
};

export default LeaveTypes;
