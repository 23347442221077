import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 aug 24
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";
import { AdminContext } from "../contexts/AdminContext";
import axios from "axios";
import serverURL from "../serverURL";

import "../styles/navbar.css";

import navyClose from "../img/general/navycross.svg";
import editUserEdit1 from "../img/general/editUserEdit1.svg";
import profilePicturePlaceholder from "../img/general/profilePicturePlaceholder.svg";
import dateStringer from "../tools/dateStringer";

const ViewMemoModal = () => {
  const { mobModal } = useContext(StyleContext);
  const { showViewMemoModal, setShowViewMemoModal, device, setModalOpen } =
    useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      showViewMemoModal,
      setShowViewMemoModal,
      device,
      setModalOpen,
      mobModal,
    }),
    [showViewMemoModal, setShowViewMemoModal, device, setModalOpen, mobModal]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let closeModal = () => {
    memoVals.setShowViewMemoModal((x) => {
      return { show: false };
    });
  };

  let [profPicUrl, setProfPicUrl] = useState("");
  let [name, setName] = useState("");
  let [ds, setDs] = useState("");
  let [memo, setMemo] = useState("");
  const cancelSource1 = axios.CancelToken.source();

  useEffect(() => {
    axios
      .post(
        `${serverURL}/open-memo`,
        {
          memoID: memoVals.showViewMemoModal.memoID,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setName(
            `${response.data.senderFName} ${response.data.senderLName[0]}`
          );
          setProfPicUrl(response.data.senderProfPicUrl);
          setDs(response.data.dsMade);
          setMemo(response.data.memo);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  // master return
  return (
    <div
      className="clockOnModalUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      <div
        style={modalPosition}
        ref={modalRef}
        className={`dayCommentsModal ${
          memoVals.mobModal
            ? `mobModalShoulder ${memoVals.device.ios ? "paddingBottom35" : ""}`
            : "maxViewZoneModalWidth"
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {memoVals.mobModal ? (
          <div
            className="modalSwiper modalSwiperViewLeaveType"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          ></div>
        ) : (
          ""
        )}
        <div
          className="mySwapsHeader mySwapsHeaderLT"
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={memoVals.mobModal ? navyClose : navyClose}
            alt="Close"
            className="closeMySwapsModalImg"
            onClick={() => {
              closeModal();
            }}
          />

          <p>
            Memo from {name} {}
          </p>
        </div>
        <div className="dayCommentsModalBody memoBodyHeight">
          <div className="memoHead">
            <img
              src={profPicUrl || profilePicturePlaceholder}
              alt={name}
              className="memoHeadPP"
            />
            <div className="memoHeadRow">
              {" "}
              <p className="memoHeadName">{name} sent</p>
              <p className="memoHeadDs">
                on {dateStringer.printedDateFromDs(ds)}
              </p>
            </div>
          </div>
          <p className="memoText">{memo}</p>
        </div>
        <div className="dayCommentsModalFooter">
          <p></p>

          <p
            className="closeReqInfo"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViewMemoModal;
