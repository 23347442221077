import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useRef,
  Fragment,
} from "react";

// this is a subcomponent of the Dashboard -- the dashboard file is already big enough

import axios from "axios";
import { UserContext } from "../../contexts/UserContext";
import { StyleContext } from "../../contexts/StyleContext";
import { DataContext } from "../../contexts/DataContext";

import { NavLink } from "react-router-dom";
import imageCompression from "browser-image-compression";

import serverURL from "../../serverURL";

import Navbar from "../Navbar";
import CheckUser from "../../tools/CheckUser";
// import MgrAccountNavbar from "./MgrAccountNavbar";

import "../../styles/settings.css";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";
import download from "../../img/general/download.svg";

import close from "../../img/modals/close.svg";
import dateStringer from "../../tools/dateStringer";

const DashboardPayRoll = ({ userID }) => {
  // check the user is valid - this fct will update the UserContext
  //   CheckUser(contact ? "whatsapp" : false);
  const { checkUserState, currencySymbol } = useContext(UserContext);

  const { mob, full, fullButNarrow, mobModal } = useContext(StyleContext);
  const { device } = useContext(DataContext);

  let [selectedDataMinTs, setSelectedDataMinTs] = useState(0);
  let [dataMinTs, setDataMinTs] = useState(0);
  let [dataMaxTs, setDataMaxTs] = useState(0);
  let [minDateInput, setMinDateInput] = useState("");
  let [maxDateInput, setMaxDateInput] = useState("");
  let [salaried, setSalaried] = useState(false);
  let [salariedPercentage, setSalariedPercentage] = useState(0);
  let [salaryTotal, setSalaryTotal] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [dataObj, setDataObj] = useState({ done: false });
  let [fName, setFName] = useState("");
  let [lName, setLName] = useState("");
  let [exportDisable, setExportDisable] = useState(false);
  let dateInvalid = (dateInput) => {
    if (dateInput.length !== 10) {
      return true;
    }

    function isValidDate(dateString) {
      // Parse the input date string
      const parts = dateString.split("-");
      const year = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10);
      const day = parseInt(parts[2], 10);

      // Create a new Date object and check if it's valid
      const date = new Date(year, month - 1, day);
      const isValid =
        date.getFullYear() === year &&
        date.getMonth() === month - 1 &&
        date.getDate() === day;

      // Check for specific cases of invalid dates
      if (!isValid) {
        return false;
      }

      // Check for February 29 on a non-leap year
      if (month === 2 && day === 29 && !isLeapYear(year)) {
        return false;
      }

      // Check for months with 31 days
      if ([1, 3, 5, 7, 8, 10, 12].includes(month) && day > 31) {
        return false;
      }

      // Check for months with 30 days
      if ([4, 6, 9, 11].includes(month) && day > 30) {
        return false;
      }

      return true;
    }

    // Function to check if a year is a leap year
    function isLeapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }

    if (!isValidDate(dateInput)) {
      return true;
    } else {
      return false;
    }
  };

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [dataMonthArr, setDataMonthArr] = useState([]);
  const cancelSource1 = axios.CancelToken.source();
  const cancelSource2 = axios.CancelToken.source();

  useEffect(() => {
    axios
      .post(
        `${serverURL}/get-user-min-max-dates`,
        {
          userID,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          console.log("!!!!");
          let startDs = response.data.minDs;

          let endDs = response.data.maxDs;

          console.log({ startDs, endDs });

          if (startDs && endDs && startDs[0] === "Y" && endDs[0] === "Y") {
            let minTs = dateStringer.createTimestampFromString(startDs);
            let maxTs = dateStringer.createTimestampFromString(endDs);
            // console.log({ minTs, maxTs });

            let monthsArray = [];
            let currentDate = new Date(minTs);

            // Loop through each month until currentDate is after maxTs
            while (currentDate <= maxTs) {
              // Get the start and end timestamps for the current month
              const startOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                1
              );
              const endOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0,
                23,
                59,
                59
              );

              console.log({ startOfMonth, endOfMonth });

              // Create the month object with the desired format
              let nowTs = new Date().getTime();
              const monthObject = {
                startTs: startOfMonth.getTime(),
                endTs: endOfMonth.getTime(),
                current:
                  nowTs >= startOfMonth.getTime() &&
                  nowTs <= endOfMonth.getTime(),
                startDs: dateStringer.createStringFromTimestamp(
                  startOfMonth.getTime()
                ),
                endDs: dateStringer.createStringFromTimestamp(
                  endOfMonth.getTime()
                ),

                title: `${startOfMonth
                  .toLocaleString("default", {
                    month: "short",
                  })
                  .substr(0, 3)} ${startOfMonth.getFullYear()}`,
              };

              // Push the month object into the monthsArray
              monthsArray.push(monthObject);

              // Move to the next month
              currentDate.setMonth(currentDate.getMonth() + 1);
            }
            setDataMonthArr(monthsArray);

            let filt = monthsArray.filter((x) => {
              return x.current;
            });

            setSelectedDataMinTs(filt[0] ? filt[0].startTs : 0);

            setMinDateInput(
              dateStringer.createDateInputFromDateString(
                filt[0] ? filt[0].startDs : ""
              )
            );
            setMaxDateInput(
              dateStringer.createDateInputFromDateString(
                filt[0] ? filt[0].endDs : ""
              )
            );
          }

          // ----- --- - - - - - - -
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  useEffect(() => {
    if (
      minDateInput &&
      minDateInput.length === 10 &&
      maxDateInput &&
      maxDateInput.length === 10 &&
      !dateInvalid(minDateInput) &&
      !dateInvalid(maxDateInput) &&
      dateStringer.createTimestampFromString(
        dateStringer.createStringFromDateInput(maxDateInput)
      ) >=
        dateStringer.createTimestampFromString(
          dateStringer.createStringFromDateInput(minDateInput)
        )
    ) {
      setLoading(true);
      axios
        .post(
          `${serverURL}/report-hours`,
          {
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            userIDs: [userID],
            startDs: dateStringer.createStringFromDateInput(minDateInput),
            endDs: dateStringer.createStringFromDateInput(maxDateInput),
            indiPayRoll: true,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setSalaryTotal(response.data.indiDataObj.salaryTotal);
            setSalariedPercentage(response.data.indiDataObj.salariedPercentage);
            setSalaried(response.data.indiDataObj.salariedAtAnyPoint);
            setFName(response.data.indiDataObj.fName);
            setLName(response.data.indiDataObj.lName);

            setDataObj(response.data.indiDataObj);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource2.cancel("Component unmounted");
      };
    }
  }, [minDateInput, maxDateInput]);
  // master return
  return (
    <div className={`userPayRollDashComponent `}>
      {/* Hello from the pay roll component */}
      <div className={`dashUserPayRollTop`}>
        <div className={`editUserDataDateRow `}>
          {" "}
          <div className="editUserDataDateLeft">
            <div className="editUserDataDateBlock">
              <p className="editUserDataDateTitle">First date</p>
              <input
                type="date"
                onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                className={`editUserDataDateInput ${
                  (!loading && dateInvalid(minDateInput)) ||
                  (!loading &&
                    dateStringer.createTimestampFromString(
                      dateStringer.createStringFromDateInput(maxDateInput)
                    ) <
                      dateStringer.createTimestampFromString(
                        dateStringer.createStringFromDateInput(minDateInput)
                      ))
                    ? "invalidPayRollDateInput"
                    : ""
                }`}
                value={minDateInput}
                onChange={(e) => {
                  setMinDateInput(e.target.value);
                  if (e.target.value.length === 10) {
                    let ts = dateStringer.createTimestampFromString(
                      dateStringer.createStringFromDateInput(e.target.value)
                    );
                    if (selectedDataMinTs !== "-") {
                      setSelectedDataMinTs("-");
                    }
                    setDataMinTs(ts);
                  }
                }}
              ></input>
            </div>
            <div className="editUserDataDateBlock editUserDataDateBlock2">
              <p className="editUserDataDateTitle">Last date</p>
              <input
                value={maxDateInput}
                onChange={(e) => {
                  setMaxDateInput(e.target.value);
                  if (e.target.value.length === 10) {
                    let ts = dateStringer.createTimestampFromString(
                      dateStringer.createStringFromDateInput(e.target.value)
                    );
                    let dateObj = new Date(ts);
                    dateObj.setHours(23, 59, 0, 0);
                    setDataMaxTs(dateObj.getTime());
                    if (selectedDataMinTs !== "-") {
                      setSelectedDataMinTs("-");
                    }
                  }
                }}
                type="date"
                onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                className={`editUserDataDateInput ${
                  (!loading && dateInvalid(maxDateInput)) ||
                  (!loading &&
                    dateStringer.createTimestampFromString(
                      dateStringer.createStringFromDateInput(maxDateInput)
                    ) <
                      dateStringer.createTimestampFromString(
                        dateStringer.createStringFromDateInput(minDateInput)
                      ))
                    ? "invalidPayRollDateInput"
                    : ""
                }`}
              ></input>
            </div>
          </div>
          <div className="editUserDataDateLeft">
            <div className="editUserDataDateBlock">
              <p className="editUserDataDateTitle">Month</p>

              <select
                className="editUserDataDrop"
                value={selectedDataMinTs}
                onChange={(e) => {
                  let val = parseInt(e.target.value);
                  console.log({ val });
                  setSelectedDataMinTs(val);
                  let dateObj = new Date(val);
                  dateObj.setMonth(dateObj.getMonth() + 1);
                  dateObj.setDate(dateObj.getDate() - 1);
                  dateObj.setHours(23, 59, 0, 0);
                  console.log({ dateObj });
                  setDataMaxTs(dateObj.getTime());
                  setDataMinTs(val);
                  setMinDateInput(
                    dateStringer.createDateInputFromDateString(
                      dateStringer.createStringFromTimestamp(val)
                    )
                  );
                  setMaxDateInput(
                    dateStringer.createDateInputFromDateString(
                      dateStringer.createStringFromTimestamp(dateObj.getTime())
                    )
                  );
                }}
              >
                <option value="-">Custom</option>
                {dataMonthArr.map((x, i) => {
                  return (
                    <option key={i} value={x.startTs}>
                      {x.title}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <img
          src={horizontalBalls}
          alt="Loading user"
          className="dashUserLoadingimg"
        />
      ) : (
        <div
          className={`dashUserPayRollSheet ${
            device.ios ? "dashUserPayRollSheetIos" : ""
          }`}
        >
          {salaried && (
            <div className="dashPayRollRow totalPayableDashRow">
              <p
                className="dashPayTitle noBorder"
                onClick={() => {
                  console.log({ dataObj, salaryTotal });
                }}
              >
                Salary
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum totPayDashPayTxt">
                {currencySymbol}
                {dateStringer.formatCost(salaryTotal)}
              </p>
            </div>
          )}
          {salaried && salariedPercentage !== 100 ? (
            <p className="trackedInfo">
              {fName} is salaried for {Math.round(salariedPercentage)}% of this
              period. For the remaining {Math.round(100 - salariedPercentage)}%,{" "}
              {fName} is either paid hourly, or there is no pay data available.
            </p>
          ) : (
            ""
          )}
          {salaried && (
            <div className="dashPayRollRow totalPayableDashRow">
              <p className="dashPayTitle noBorder flowRotaTrackedTitle">
                Salary deductions
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum totPayDashPayTxt">
                {currencySymbol}
                {dateStringer.formatCost(dataObj.unpaidSalary)}
              </p>
            </div>
          )}
          {salaried && (
            <div className="dashPayRollRow totalPayableDashRow">
              <p className="dashPayTitle noBorder flowRotaTrackedTitle">
                Salary additions
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum totPayDashPayTxt">
                {currencySymbol}
                {dateStringer.formatCost(dataObj.salaryAdditions)}
              </p>
            </div>
          )}
          {salaried && (
            <div className="dashPayRollRow totalPayableDashRow">
              <p className="dashPayTitle noBorder flowRotaTrackedTitle">
                Overtime pay (in addition to salary)
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum totPayDashPayTxt">
                {currencySymbol}
                {dateStringer.formatCost(dataObj.paidOvertimeCost)}
              </p>
            </div>
          )}
          {salaried && (
            <div className="dashPayRollRow totalPayableDashRow netSal">
              <p className="dashPayTitle noBorder flowRotaTrackedTitle colourWhite">
                Net salary (after deductions & additions)
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum totPayDashPayTxt colourWhite">
                {currencySymbol}
                {dateStringer.formatCost(dataObj.netPay)}
              </p>
            </div>
          )}
          <div className="dashPayRollRow totalPayableDashRow">
            <p className="dashPayTitle noBorder flowRotaTrackedTitle">
              Total pay from tracked hours
            </p>
            <p className="dashPayValueCost makeBoldPayRollNum totPayDashPayTxt">
              {currencySymbol}
              {dateStringer.formatCost(dataObj.totalCost)}
            </p>
          </div>{" "}
          {salaried && (
            <p className="trackedInfo">
              The pay calculated from all hours tracked in FlowRota from shifts,
              overtime, leave and absence - minus any unpaid salary deductions
              from unpaid absence and lateness etc.
            </p>
          )}
          <div className="dashPayRollRowSub noBorder_">
            <p className="dashPayTitleSub"></p>
            <div className="dashPayValueWrap">
              <p className="dashPayValue payRollDashHeader">Rate</p>
              <p className="dashPayValue payRollDashHeader">Duration</p>
              <p className="dashPayValueCost payRollDashHeader">
                {currencySymbol}
              </p>
            </div>
          </div>
          <div className="dashPayRollRow totUnderline totPaidHoursDashRow">
            <p className="dashPayTitle dashPayTitle2">Total hours</p>
            <div className="dashPayValueWrap">
              {" "}
              <p className="dashPayValueRate"></p>
              <p className="dashPayValue makeBoldPayRollNum">
                {dateStringer.formatMinsDurationToHours(
                  dataObj.totalPaidHoursMins
                )}
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum">
                {currencySymbol}
                {dateStringer.formatCost(dataObj.totalPaidHoursCost)}
              </p>
            </div>{" "}
          </div>
          <div className="dashPayRollRow totUnderline">
            <p className="dashPayTitle">Total worked hours</p>
            <div className="dashPayValueWrap">
              <p className="dashPayValueRate"></p>

              <p className="dashPayValue makeBoldPayRollNum">
                {dateStringer.formatMinsDurationToHours(
                  dataObj.totalWorkedMins
                )}
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum">
                {currencySymbol}
                {dateStringer.formatCost(dataObj.totalWorkedCost)}
              </p>
            </div>
          </div>
          <div className="dashPayRollRowSub">
            <p className="dashPayTitleSub">Paid shift hours</p>
            <div className="dashPayValueWrap">
              <p className="dashPayValueRate"></p>

              <p className="dashPayValue makeBoldPayRollNum">
                {dateStringer.formatMinsDurationToHours(dataObj.paidShiftMins)}
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum">
                {" "}
                {currencySymbol}
                {dateStringer.formatCost(dataObj.paidShiftCost)}
              </p>
            </div>
          </div>
          {dataObj.paidShiftRateArr &&
            dataObj.paidShiftRateArr.map((x) => {
              return (
                <div className="dashPayRollRowSub">
                  <p className="dashPayTitleSub"></p>
                  <div className="dashPayValueWrap">
                    <p className="dashPayValueRate">{x.rate}x</p>
                    <p className="dashPayValue">
                      {dateStringer.formatMinsDurationToHours(x.mins)}
                    </p>
                    <p className="dashPayValueCost">
                      {currencySymbol}
                      {dateStringer.formatCost(x.cost)}
                    </p>
                  </div>
                </div>
              );
            })}
          <div className="dashPayRollRowSub x8881122">
            <p className="dashPayTitleSub">Unpaid shift hours</p>
            <div className="dashPayValueWrap">
              <p className="dashPayValueRate"></p>
              <p className="dashPayValue makeBoldPayRollNum">
                {dateStringer.formatMinsDurationToHours(
                  dataObj.unpaidShiftMins
                )}
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum">-</p>
            </div>
          </div>
          <div className="dashPayRollRowSub x8881122">
            <p className="dashPayTitleSub">Paid overtime hours</p>
            <div className="dashPayValueWrap">
              <p className="dashPayValueRate"></p>

              <p className="dashPayValue makeBoldPayRollNum">
                {dateStringer.formatMinsDurationToHours(
                  dataObj.paidOvertimeMins
                )}
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum">
                {currencySymbol}
                {dateStringer.formatCost(dataObj.paidOvertimeCost)}
              </p>
            </div>
          </div>
          {dataObj.paidOvertimeRateArr &&
            dataObj.paidOvertimeRateArr.map((x) => {
              return (
                <div className="dashPayRollRowSub">
                  <p className="dashPayTitleSub"></p>
                  <div className="dashPayValueWrap">
                    <p className="dashPayValueRate">{x.rate}x</p>
                    <p className="dashPayValue">
                      {dateStringer.formatMinsDurationToHours(x.mins)}
                    </p>
                    <p className="dashPayValueCost">
                      {currencySymbol}
                      {dateStringer.formatCost(x.cost)}
                    </p>
                  </div>
                </div>
              );
            })}
          <div className="dashPayRollRowSub noBorder">
            <p className="dashPayTitleSub">Unpaid overtime hours</p>
            <div className="dashPayValueWrap">
              <p className="dashPayValueRate"></p>
              <p className="dashPayValue makeBoldPayRollNum">
                {dateStringer.formatMinsDurationToHours(
                  dataObj.unpaidOvertimeMins
                )}
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum">-</p>
            </div>
          </div>{" "}
          <div className="dashPayRollRow totUnderline">
            <p className="dashPayTitle">Total leave hours</p>
            <div className="dashPayValueWrap">
              <p className="dashPayValueRate makeBoldPayRollNum"></p>
              <p className="dashPayValue makeBoldPayRollNum">
                {dateStringer.formatMinsDurationToHours(
                  dataObj.totalLeaveHoursMins
                )}
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum">
                {currencySymbol}
                {dateStringer.formatCost(dataObj.totalLeaveHoursCost)}
              </p>
            </div>{" "}
          </div>
          <div className="dashPayRollRowSub">
            <p className="dashPayTitleSub">Paid leave hours</p>
            <div className="dashPayValueWrap">
              <p className="dashPayValueRate"></p>
              <p className="dashPayValue makeBoldPayRollNum">
                {" "}
                {dateStringer.formatMinsDurationToHours(dataObj.paidLeaveMins)}
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum">
                {" "}
                {currencySymbol}
                {dateStringer.formatCost(dataObj.paidLeaveCost)}
              </p>
            </div>
          </div>
          {dataObj.paidLeaveRateArr &&
            dataObj.paidLeaveRateArr.map((x) => {
              return (
                <div className="dashPayRollRowSub">
                  <p className="dashPayTitleSub"></p>
                  <div className="dashPayValueWrap">
                    <p className="dashPayValueRate">{x.rate}x</p>
                    <p className="dashPayValue">
                      {dateStringer.formatMinsDurationToHours(x.mins)}
                    </p>
                    <p className="dashPayValueCost">
                      {currencySymbol}
                      {dateStringer.formatCost(x.cost)}
                    </p>
                  </div>
                </div>
              );
            })}
          <div className="dashPayRollRowSub x8881122 noBorder">
            <p className="dashPayTitleSub">Unpaid leave hours</p>
            <div className="dashPayValueWrap">
              <p className="dashPayValueRate"></p>
              <p className="dashPayValue makeBoldPayRollNum">
                {dateStringer.formatMinsDurationToHours(
                  dataObj.unpaidLeaveMins
                )}
              </p>
              <p className="dashPayValueCost">-</p>
            </div>
          </div>{" "}
          <div className="dashPayRollRow totUnderline">
            <p className="dashPayTitle">Total absence hours</p>
            <div className="dashPayValueWrap">
              <p className="dashPayValueRate"></p>
              <p className="dashPayValue makeBoldPayRollNum">
                {dateStringer.formatMinsDurationToHours(
                  dataObj.totalAbsenceHoursMins
                )}
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum">
                {" "}
                {currencySymbol}
                {dateStringer.formatCost(dataObj.totalAbsenceHoursCost)}
              </p>
            </div>{" "}
          </div>
          <div className="dashPayRollRowSub">
            <p className="dashPayTitleSub">Paid absence hours</p>
            <div className="dashPayValueWrap">
              <p className="dashPayValueRate"></p>
              <p className="dashPayValue makeBoldPayRollNum">
                {dateStringer.formatMinsDurationToHours(
                  dataObj.paidAbsenceMins
                )}
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum">
                {" "}
                {currencySymbol}
                {dateStringer.formatCost(dataObj.paidAbsenceCost)}
              </p>
            </div>
          </div>
          {dataObj.paidAbsenceRateArr &&
            dataObj.paidAbsenceRateArr.map((x) => {
              return (
                <div className="dashPayRollRowSub">
                  <p className="dashPayTitleSub"></p>
                  <div className="dashPayValueWrap">
                    <p className="dashPayValueRate">{x.rate}x</p>
                    <p className="dashPayValue">
                      {dateStringer.formatMinsDurationToHours(x.mins)}
                    </p>
                    <p className="dashPayValueCost">
                      {currencySymbol}
                      {dateStringer.formatCost(x.cost)}
                    </p>
                  </div>
                </div>
              );
            })}
          <div className="dashPayRollRowSub x8881122 noBorder">
            <p className="dashPayTitleSub">Unpaid absence hours</p>
            <div className="dashPayValueWrap">
              <p className="dashPayValueRate"></p>
              <p className="dashPayValue makeBoldPayRollNum">
                {dateStringer.formatMinsDurationToHours(
                  dataObj.unpaidAbsenceMins
                )}
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum">-</p>
            </div>
          </div>
          <div className="dashPayRollRowSub payRollLieuRow">
            <p className="dashPayTitleSub">
              Hours worked in lieu
              <br />
              <span className="lieuPayRowExplain">added to TOIL balance</span>
            </p>
            <div className="dashPayValueWrap">
              <p className="dashPayValueRate"></p>
              <p className="dashPayValue makeBoldPayRollNum">
                {dateStringer.formatMinsDurationToHours(dataObj.tilMins)}
              </p>
              <p className="dashPayValueCost makeBoldPayRollNum"></p>
            </div>
          </div>
          <div
            className={`editUserMessageBtn unarchBtn exportDashPayRollBtn ${
              exportDisable ? "disable" : ""
            }`}
            onClick={() => {
              setExportDisable(true);
              axios
                .post(
                  `${serverURL}/report-hours`,
                  {
                    nowDs: dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    ),
                    userIDs: [userID],
                    startDs:
                      dateStringer.createStringFromDateInput(minDateInput),
                    endDs: dateStringer.createStringFromDateInput(maxDateInput),
                    indiPayRoll: true,
                    csv: true,
                  },
                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  // Create a Blob from the response data
                  const blob = new Blob([response.data], {
                    type: "text/csv",
                  });
                  setTimeout(() => {
                    setExportDisable(false);
                  }, 5000);
                  // Create a URL for the Blob
                  const url = window.URL.createObjectURL(blob);
                  // Create a link element to trigger the download
                  const link = document.createElement("a");
                  link.href = url;
                  link.download = `${fName.toLowerCase()}_${lName.toLowerCase()}_pay_roll_${dateStringer.createShortStripFromDateString(
                    dateStringer.createStringFromDateInput(minDateInput)
                  )}-${dateStringer.createShortStripFromDateString(
                    dateStringer.createStringFromDateInput(maxDateInput)
                  )}.csv`; // Specify the filename
                  document.body.appendChild(link);
                  // Trigger the download
                  link.click();
                  // Cleanup
                  window.URL.revokeObjectURL(url);
                  document.body.removeChild(link);
                })
                .catch((error) => {
                  console.error("Error downloading CSV:", error);
                });
            }}
          >
            Export to CSV{" "}
            <img src={download} alt="Message" className="downloadCsvImg" />
          </div>
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default DashboardPayRoll;
