// keys and design done 12/3/25
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import { StyleContext } from "../../../contexts/StyleContext";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { DataContext } from "../../../contexts/DataContext";

import axios from "axios";
import serverURL from "../../../serverURL";

import "../../../styles/navbar.css";

import navyClose from "../../../img/general/navycross.svg";

import greenTick from "../../../img/general/greenTick.svg";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import dateStringer from "../../../tools/dateStringer";

const TakeToilModal = ({
  unitType,
  unitID,
  unitDate,
  unitShiftMins,
  unitStart,
  unitEnd,
  unitTeamID,
  unitBrkMins,
  isMgr,
  setShowTilTrimModalParent,
  userID,
  fName,
  setReload,
  reload,
  editing,
  editStart,
  editEnd,
  setSureBinTil,
  setChangesMade,
}) => {
  const { mob, mobModal } = useContext(StyleContext);

  const { setShowTilTrimModal, setUpdateTilData, updateTilData } =
    useContext(CalendarContext);
  const {
    setTilReqMade,
    tilReqMade,
    modalOpen,
    setModalOpen,
    device,
    countData,
    setCountData,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      mob,
      mobModal,
      setTilReqMade,
      tilReqMade,
      setShowTilTrimModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      mob, //
      mobModal, //
      setTilReqMade, //
      tilReqMade, //
      setShowTilTrimModal,
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);
  let [type, setType] = useState("shift");
  let [id, setId] = useState("");

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 0) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let [shiftMins, setShiftMins] = useState(0);

  let [trimMins, setTrimMins] = useState(60);
  let [start, setStart] = useState(unitStart);
  let [end, setEnd] = useState(unitEnd);
  let [ds, setDs] = useState("");
  let [note, setNote] = useState("");
  let [teamID, setTeamID] = useState(unitTeamID);

  let [whole, setWhole] = useState(false);

  let [invalidModal, setInvalidModal] = useState(false);

  let [yearStartDs, setYearStartDs] = useState("");
  let [remainingTil, setRemainingTil] = useState(0);

  let [notEnoughModal, setNotEnoughModal] = useState(false);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-til-allowance`,
        {
          ds: unitDate,
          userID,
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setYearStartDs(response.data.forceStartDs);

          setDataLoaded(true);
          setRemainingTil(response.data.tilBalance);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let updateTimes = (newWhen) => {
    if (newWhen === "start") {
      let date = new Date();
      date.setHours(unitStart.substr(0, 2), unitStart.substr(3, 2), 0, 0);
      let tenMinsMs = 1000 * 60 * 10;
      let newTsStart = date.getTime() + tenMinsMs;
      let startDateObj = new Date(newTsStart);

      setStart(unitStart);
      setEnd(dateStringer.tsToHHMM(startDateObj.getTime()));

      let tsStart = dateStringer.HHMMToMsToday(unitStart);
      let tsEnd = dateStringer.HHMMToMsToday(
        dateStringer.tsToHHMM(startDateObj.getTime())
      );
      let msDiff = tsEnd - tsStart;
      let minsDiff = msDiff / 1000 / 60;

      if (minsDiff > shiftMins) {
        setTrimMins(shiftMins);
        setWhole(true);
      } else {
        setTrimMins(minsDiff);
        setWhole(false);
      }
    }

    if (newWhen === "end") {
      let date = new Date();
      date.setHours(unitEnd.substr(0, 2), unitEnd.substr(3, 2), 0, 0);
      let tenMinsMs = 1000 * 60 * 10;
      let newTsStart = date.getTime() - tenMinsMs;
      let startDateObj = new Date(newTsStart);

      setStart(dateStringer.tsToHHMM(startDateObj.getTime()));
      setEnd(unitEnd);

      let tsStart = dateStringer.HHMMToMsToday(
        dateStringer.tsToHHMM(startDateObj.getTime())
      );
      let tsEnd = dateStringer.HHMMToMsToday(unitEnd);
      let msDiff = tsEnd - tsStart;
      let minsDiff = msDiff / 1000 / 60;
      // if (minsDiff > shiftMins) {
      //   setTrimMins(shiftMins);
      //   setWhole(true);
      // } else {
      setTrimMins(minsDiff);
      setWhole(false);
      // }
    }
  };

  useEffect(() => {
    // clean not needed
    if (editStart && editEnd) {
      setStart(editStart);
      setEnd(editEnd);

      updateTimes("start");
      updateTimes("end");
    }
  }, []);

  let [endValid, setEndValid] = useState(true);
  let [startValid, setStartValid] = useState(true);
  let checkTimes = (startOrEnd, newTime) => {
    let ts = dateStringer.HHMMToMsToday(newTime);
    let tsMax = dateStringer.HHMMToMsToday(unitEnd);
    let tsMin = dateStringer.HHMMToMsToday(unitStart);

    // Adjust if times span past midnight
    if (tsMax < tsMin) tsMax += 24 * 60 * 60 * 1000; // Add a day's worth of milliseconds to tsMax if it’s past midnight
    if (ts < tsMin) ts += 24 * 60 * 60 * 1000; // If the new time crosses midnight, adjust accordingly

    if (startOrEnd === "end") {
      setEndValid(ts >= tsMin && ts <= tsMax);
    } else if (startOrEnd === "start") {
      setStartValid(ts >= tsMin && ts <= tsMax);
    }
  };

  let updateData = (shiftStart, shiftEnd, tilStart, tilEnd) => {
    // split
    let splitted = false;
    let endOrStart = "end";
    if (shiftStart !== tilStart && shiftEnd !== tilEnd) {
      splitted = true;
    } else {
      if (shiftStart !== tilStart && shiftEnd === tilEnd) {
        endOrStart = "end";
      }
      if (shiftStart === tilStart && shiftEnd !== tilEnd) {
        endOrStart = "start";
      }
    }

    if (splitted) {
    } else {
      if (endOrStart === "start") {
        let ts = dateStringer.HHMMToMsToday(tilEnd) + 60000;
      }

      if (endOrStart === "end") {
        let ts = dateStringer.HHMMToMsToday(tilStart) - 60000;
      }
    }
  };

  useEffect(() => {
    // clean not needed
    setType(unitType);
    setId(unitID);
    setDs(unitDate);
    setShiftMins(unitShiftMins);
    updateTimes("end");
  }, []);

  useEffect(() => {
    // clean not needed
    if (editing) {
      // start:
      setStart(editStart);

      let tsStart = dateStringer.HHMMToMsToday(editStart);
      let tsEnd = dateStringer.HHMMToMsToday(editEnd);
      let msDiff = tsEnd - tsStart;
      let minsDiff = msDiff / 1000 / 60;
      if (minsDiff >= unitShiftMins) {
        setTrimMins(unitShiftMins);
        setWhole(true);
      } else {
        setTrimMins(minsDiff);
        setWhole(false);
      }
      checkTimes("start", editStart);

      // end:
      setEnd(editEnd);

      checkTimes("end", editEnd);
      updateData(unitStart, unitEnd, editStart, editEnd);
    }
  }, []);

  let closeModal = () => {
    if (setShowTilTrimModalParent) {
      // parent from the manager shift
      setShowTilTrimModalParent(false);
    } else {
      // from the my shift
      memoVals.setShowTilTrimModal((x) => false);
    }
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  // master return
  return (
    <div
      className={`clockOnModalUnderlay`}
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`dayCommentsModal ${
            memoVals.mobModal
              ? `mobModalShoulder ${
                  memoVals.device.ios ? "paddingBottom35" : ""
                }`
              : "maxViewZoneModalWidth"
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal ? (
            <div
              className="modalSwiper modalSwiperViewLeaveType"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          ) : (
            ""
          )}
          <div
            className="mySwapsHeader mySwapsHeaderLT"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={memoVals.mobModal ? navyClose : navyClose}
              alt="Close"
              className="closeMySwapsModalImg"
              onClick={() => {
                closeModal();
              }}
            />
            <p>{isMgr ? `${editing ? "Edit" : "Add"} TOIL` : "Request TOIL"}</p>

            {/* <p></p> */}
          </div>
          <div className="dayCommentsModalBody takeToilMaxHeight">
            {/* = = = = = = = ==  */}

            <div className="gpsZoneRow x4389589456986565_">
              <div className="modalExplainer">
                <div className="modalExplainLeftBlob"></div>
                <p className="modalExplainRightTxt">
                  Take time off this {type === "shift" ? "shift" : "overtime"}{" "}
                  using your TOIL balance
                </p>
              </div>

              <p className="lonLatTitles addShShellTitle editShTitle">
                Time off
              </p>

              <div className="addShShellTimeDiv">
                <input
                  className={`shiftTimeInput`}
                  type="time"
                  id="toilStart"
                  value={start}
                  placeholder={start}
                  onChange={(e) => {
                    setStart(e.target.value);

                    let tsStart = dateStringer.HHMMToMsToday(e.target.value);
                    let tsEnd = dateStringer.HHMMToMsToday(end);
                    let msDiff = tsEnd - tsStart;
                    let minsDiff = msDiff / 1000 / 60;
                    if (minsDiff >= shiftMins) {
                      setTrimMins(shiftMins);
                      setWhole(true);
                    } else {
                      setTrimMins(minsDiff);
                      setWhole(false);
                    }
                    checkTimes("start", e.target.value);

                    updateData(unitStart, unitEnd, e.target.value, end);
                  }}
                  onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                />{" "}
                until
                <input
                  className={`shiftTimeInput `}
                  type="time"
                  id="toilStart"
                  value={end}
                  placeholder={end}
                  onChange={(e) => {
                    setEnd(e.target.value);

                    let tsStart = dateStringer.HHMMToMsToday(start);
                    let tsEnd = dateStringer.HHMMToMsToday(e.target.value);
                    let msDiff = tsEnd - tsStart;
                    let minsDiff = msDiff / 1000 / 60;
                    if (minsDiff > shiftMins) {
                      setTrimMins(shiftMins);
                      setWhole(true);
                    } else {
                      setTrimMins(minsDiff);
                      setWhole(false);
                    }
                    checkTimes("end", e.target.value);

                    updateData(unitStart, unitEnd, start, e.target.value);
                  }}
                  onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                />
              </div>
              <div className="x28494422355"></div>
              <p
                className={`addShShellDur ${
                  trimMins <= 0 ? "opac05" : ""
                } editShDurMins`}
              >
                Duration:{" "}
                <span className="colour00aaff">
                  {dateStringer.formatMinsDurationToHours(trimMins)}
                </span>{" "}
                <span className="x29302933443">Available:</span>{" "}
                <span
                  className={`colour00aaff ${
                    trimMins > remainingTil ? "colourBa0000" : ""
                  }`}
                >
                  {dateStringer.formatMinsDurationToHours(remainingTil)}{" "}
                </span>
                <img
                  src={greenTick}
                  alt="Enough balance"
                  className={
                    trimMins > remainingTil
                      ? "toilSufficBalTickImg invis"
                      : "toilSufficBalTickImg"
                  }
                />
                {/* <span className="x29302933443">)</span> */}
              </p>
              {/* <p className={`addShShellDur editShDurMins x3489549854545`}>
                <span className="colour00aaff">
                  {dateStringer.formatMinsDurationToHours(remainingTil)}
                </span>{" "}
                TOIL balance available
              </p> */}
            </div>
            <div className="gpsZoneRow x24356786533">
              <p className="lonLatTitles addShShellTitle editShTitle">
                Add a note
              </p>
              <textarea
                className="EditMyShiftNoteTextBox x3489439858555"
                // type=''
                id="addShiftDateInput"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="dayCommentsModalFooter">
            <div className="viewMapsAndSaveDiv">
              <p
                className={`saveGpsZoneBtn x34895895677 ${
                  start && start.length === 5 && end && end.length === 5
                    ? ""
                    : "disabled defaultCursor"
                }  ${
                  start === unitStart && end === unitEnd
                    ? "disabled defaultCursor none"
                    : ""
                }`}
                onClick={() => {
                  if (!startValid || !endValid || trimMins < 1) {
                    setInvalidModal(true);
                  } else {
                    if (trimMins > remainingTil) {
                      setNotEnoughModal(true);
                    } else {
                      setLoading(true);

                      if (isMgr) {
                        // add toil req here
                        axios
                          .post(
                            `${serverURL}/add-til-to-shift-or-til`,
                            {
                              type: type,
                              start: start,
                              end: end,
                              tilMins: trimMins,
                              shiftID: id,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              setTimeout(() => {
                                setUpdateTilData(!updateTilData);
                              }, 100);
                              if (setChangesMade && isMgr) {
                                setChangesMade(true);
                              }
                              setLoading(false);
                              closeModal();
                              setReload(!reload);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      } else {
                        axios
                          .post(
                            `${serverURL}/take-til-request`,
                            {
                              type: type,
                              start: start,
                              end: end,
                              tilMins: trimMins,
                              note: note,
                              shiftID: id,
                              teamID: teamID,
                              whole: whole,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              if (setChangesMade && isMgr) {
                                setChangesMade(true);
                              }

                              if (!response.data.existed && !isMgr) {
                                setCountData({
                                  outstandingChanges:
                                    countData.outstandingChanges + 1,
                                });
                              }
                              setTimeout(() => {
                                setUpdateTilData(!updateTilData);
                              }, 100);
                              setLoading(false);
                              closeModal();
                              memoVals.setTilReqMade(
                                (x) => !memoVals.tilReqMade
                              );
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }
                  }
                }}
              >
                Submit
              </p>
            </div>

            <p
              className="closeReqInfo"
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </p>
          </div>
        </div>
      )}
      {notEnoughModal ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              {isMgr ? `${fName} does` : "You do"} not have enough TOIL accrued
              since {dateStringer.printedDateWithYrFromDWithoutDay(yearStartDs)}{" "}
              to fulfill this {isMgr ? "time off" : "request"}.
              <br />
              <br />
              {isMgr ? "They" : "You"} only have{" "}
              {dateStringer.formatMinsDurationToHours(remainingTil)} and this
              request requires{" "}
              {dateStringer.formatMinsDurationToHours(trimMins)}.
              <br />
              <br />
              Do you want to {isMgr ? "apply" : "request"} it anyway?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setLoading(true);
                  setNotEnoughModal(false);

                  if (isMgr) {
                    // add til req here
                    axios
                      .post(
                        `${serverURL}/add-til-to-shift-or-til`,
                        {
                          type: type,
                          start: start,
                          end: end,
                          tilMins: trimMins,
                          shiftID: id,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          if (setChangesMade && isMgr) {
                            setChangesMade(true);
                          }
                          setTimeout(() => {
                            setUpdateTilData(!updateTilData);
                          }, 100);
                          setLoading(false);
                          closeModal();
                          setReload(!reload);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  } else {
                    axios
                      .post(
                        `${serverURL}/take-til-request`,
                        {
                          type: type,
                          start: start,
                          end: end,
                          tilMins: trimMins,
                          note: note,
                          shiftID: id,
                          teamID: teamID,
                          whole: whole,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          if (setChangesMade && isMgr) {
                            setChangesMade(true);
                          }
                          if (!response.data.existed && !isMgr) {
                            setCountData({
                              outstandingChanges:
                                countData.outstandingChanges + 1,
                            });
                          }
                          setTimeout(() => {
                            setUpdateTilData(!updateTilData);
                          }, 100);
                          setLoading(false);
                          closeModal();
                          setReload(!reload);
                          memoVals.setTilReqMade((x) => !memoVals.tilReqMade);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setNotEnoughModal(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {invalidModal ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              {start.length !== 5 && end.length !== 5
                ? "Please enter a start and end time"
                : !startValid || !endValid
                ? "Your chosen start and end times fall outside of the scheduled start and end times"
                : "Your chosen times are invalid"}
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setInvalidModal(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TakeToilModal;
