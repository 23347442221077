import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useRef,
  Fragment,
  useMemo,
} from "react";

// cleaned wed 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../contexts/CalendarContext";
import { UserContext } from "../../contexts/UserContext";
import { StyleContext } from "../../contexts/StyleContext";
import { DataContext } from "../../contexts/DataContext";
import { RequestContext } from "../../contexts/RequestContext";

import { NavLink } from "react-router-dom";

import dateStringer from "../../tools/dateStringer";
import serverURL from "../../serverURL";
import websiteURL from "../../websiteURL";
import appURL from "../../appURL";

import TopBar from "../TopBar";

import horizontalBalls from "../../img/loaders/horizontalBalls.svg";
import frlogo from "../../img/general/frlogo.svg";

import navyTri from "../../img/general/navyTri.svg";
import edit from "../../img/general/edit.svg";
import comment from "../../img/general/comment.svg";
import commentRota from "../../img/general/commentRota.svg";
import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";
import rotaMenuOpenShifts from "../../img/general/rotaMenuOpenShifts.svg";

import whitecross from "../../img/general/whitecross.svg";
import greyArr from "../../img/general/arrGrey.svg";
import rotaClock from "../../img/general/rotaClock.svg";
import rotaPerson from "../../img/general/rotaPerson.svg";
import briefcase from "../../img/general/briefcase.svg";
import rotaClockAbs from "../../img/general/rotaClockAbs.svg";
import rotaClockLeave from "../../img/general/rotaClockLeave.svg";

import rotaCoffee from "../../img/general/rotaCoffee.svg";
import doubleTick from "../../img/general/doubleTick.svg";
import adminBurgerMob from "../../img/general/adminBurgerMob.svg";
import commentDay from "../../img/general/commentDay.svg";
import rotaPlane from "../../img/general/rotaPlane.svg";
import streamCross from "../../img/general/streamCross.svg";

import rotaClockWhite from "../../img/general/rotaClockWhite.svg";
import calicon from "../../img/general/calicon.svg";
import currWeek from "../../img/general/currWeek2.svg";
import popup2 from "../../img/general/popup2.svg";
import downRightArrow2 from "../../img/general/downRightArrow2.svg";
import unitTick from "../../img/general/unitTick.svg";
import unitCross from "../../img/general/unitCross.svg";
import partialAbsentTick from "../../img/general/partialAbsentTick.svg";
import hidden from "../../img/general/hidden.svg";
import collapseIcon from "../../img/general/collapse.svg";
import swapInline from "../../img/general/swapInline.svg";
import navyClose from "../../img/general/navyClose.svg";
import view from "../../img/general/view.svg";
import dashSwap from "../../img/general/dashSwap.svg";
import pin from "../../img/general/pin.svg";

import whiteTick from "../../img/general/whiteTick.svg";
import whatsappWhite from "../../img/general/whatsappWhite.svg";
import emailWhite from "../../img/general/emailWhite.svg";
import requestsGrey from "../../img/general/requestsGrey.svg";
// import shareGrey from "../../img/general/shareGrey.svg";
import swapGreyNavy from "../../img/general/swapGreyNavy.svg";
import requestsGreyNavy from "../../img/general/myRotaMenu2.svg";
import requestsGreyNavy2 from "../../img/general/requestsGreyNavy.svg";

import popupNavy from "../../img/general/popupNavy.svg";
import popupBlue from "../../img/general/myRotaMenu1.svg";

import myRotaWeekView from "../../img/general/myRotaMenu4.svg";
import myRotaDayView from "../../img/general/myRotaMenu5.svg";
import openShiftBlockUser from "../../img/general/openShiftBlockUser.svg";
import shiftBlock from "../../img/general/shiftBlock.svg";

import lightBlueQuestionMark from "../../img/general/lightBlueQuestionMark.svg";

import "../../styles/calendar.css";

const UserSchedule = () => {
  const { checkUserState, setCheckUserState } = useContext(UserContext);
  const { setShowReqModal } = useContext(RequestContext);

  const { mob, mobModal, setMob } = useContext(StyleContext);
  const { setCalPage, updatedItem, updateInfo, setUpdateInfo, updateLimits } =
    useContext(CalendarContext);
  const {
    updateShifts,
    setUpdateShifts,
    update2,
    updateOpen,
    addShiftTilPassBackToTeamSched,
    setAddShiftTilPassBackToTeamSched,
    setShowEditUser,
    showEditUser,
    updateSched,
    setUpdateSched,
    dontAddNewItemToRota,
    loadSingleUserSched,
    setLoadSingleUserSched,
    changedMidnightTimestamp,
    device,
    setShowAddAvailModal,
    setSeeUserID,
    setShowMyShiftModal,
    setShowUserReqFromNav,
    setShowSwapsModal,
    allowSwaps,
    setAllowSwaps,
    inboundSwapsQty,
    outboundSwapsQty,
    setShowDayCommentModal,
    amendDayComment,
    dynamicSwap,
    dynamicSwapOrNote,
    setShowUserOpenShiftModal,
    updatedOpenShift,
    setCountMyChanges,
    standalone,
    setStandalone,
    acceptedShiftID,
    setAcceptedShiftID,
    setSelectedDesktop,
    udpateMyRota,
    updatedHighlightedComment,
    countData,
    setShowOpenShiftsModal,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      updateShifts,
      setUpdateShifts,
      update2,
      updateOpen, //
      addShiftTilPassBackToTeamSched,
      setAddShiftTilPassBackToTeamSched,
      setShowEditUser,
      showEditUser,
      updateSched,
      setUpdateSched,
      dontAddNewItemToRota,
      loadSingleUserSched,
      setLoadSingleUserSched, //
      changedMidnightTimestamp,
      device,
      setShowAddAvailModal,
      setSeeUserID,
      setShowMyShiftModal,
      setShowUserReqFromNav,
      setShowSwapsModal,
      allowSwaps,
      setAllowSwaps,
      inboundSwapsQty, //
      outboundSwapsQty,
      setShowDayCommentModal,
      amendDayComment,
      dynamicSwap, //
      dynamicSwapOrNote,
      setShowUserOpenShiftModal,
      updatedOpenShift,
      setCountMyChanges,
      standalone,
      setStandalone,
      acceptedShiftID,
      setAcceptedShiftID,
      setSelectedDesktop,
      udpateMyRota,
      updatedHighlightedComment, //
      mob,
      mobModal,
      setCalPage,
      updatedItem,
      updateInfo,
      setUpdateInfo,
      updateLimits, //
      setShowReqModal,
      setMob,
      setShowOpenShiftsModal,
    }),
    [
      updateShifts,
      setUpdateShifts,
      update2,
      updateOpen, //
      addShiftTilPassBackToTeamSched,
      setAddShiftTilPassBackToTeamSched,
      setShowEditUser,
      showEditUser,
      updateSched,
      setUpdateSched,
      dontAddNewItemToRota,
      loadSingleUserSched,
      setLoadSingleUserSched, //
      changedMidnightTimestamp,
      device,
      setShowAddAvailModal,
      setSeeUserID,
      setShowMyShiftModal,
      setShowUserReqFromNav,
      setShowSwapsModal,
      allowSwaps,
      setAllowSwaps,
      inboundSwapsQty, //
      outboundSwapsQty,
      setShowDayCommentModal,
      amendDayComment,
      dynamicSwap, //
      dynamicSwapOrNote,
      setShowUserOpenShiftModal,
      updatedOpenShift,
      setCountMyChanges,
      standalone,
      setStandalone,
      acceptedShiftID,
      setAcceptedShiftID,
      setSelectedDesktop,
      udpateMyRota,
      updatedHighlightedComment, //
      mob,
      mobModal,
      setCalPage,
      updatedItem,
      updateInfo,
      setUpdateInfo,
      updateLimits, //
      setShowReqModal,
      setMob,
      setShowOpenShiftsModal,
    ]
  );

  let [showPasswordInput, setShowPasswordInput] = useState(false);
  let [passInput, setPassInput] = useState("");
  let [incorrectPassword, setIncorrectPassword] = useState(false);
  let [opensQty, setOpensQty] = useState(0);
  let [capsLockOn, setCapsLockOn] = useState(false);
  let [passedPasswordOK, setPassedPasswordOK] = useState(false);
  let [showEditSuggest, setShowEditSuggest] = useState(false);
  let [managesThisTeam, setManagesThisTeam] = useState(false);
  let [locationID, setLocationID] = useState("");
  let [locationName, setLocationName] = useState("");
  let [showMenu, setShowMenu] = useState(false);

  const handleDragStart = (event) => {
    if (checkUserState.permissions < 3 && managesThisTeam) {
      setTimeout(() => {
        event.preventDefault(); // Prevent default drag behavior
        setShowEditSuggest(true);
      }, 250);
    }
  };

  let checkCaps = (event) => {
    if (event.getModifierState("CapsLock")) {
      setCapsLockOn(true);
    } else {
      setCapsLockOn(false);
    }
  };

  let enterFct = (e) => {
    if ((e.key === "Enter" || e === "xx") && passInput) {
      axios
        .post(
          `${serverURL}/check-rota-pass`,
          {
            monWeek: selectedMonWeek,
            teamID: selectedTeam,
            password: passInput,
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message === "link does not exist") {
            window.location.href = `${appURL}/inactive-rota-link`;
          } else {
            if (response.data.message.includes("logout")) {
              window.location.href = "/signin";
            }
            if (response.data.message === "success") {
              if (response.data.ok) {
                if (incorrectPassword) {
                  setIncorrectPassword(false);
                }
                // OK TO PROCEED
                setShowPasswordInput(false);
                setPassedPasswordOK(true);
                memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
              } else {
                setIncorrectPassword(true);
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  let shortenTeamNameForMobOnly = (val) => {
    if (val.length > 22) {
      return `${val.substr(0, 20)}...`;
    } else {
      return val;
    }
  };

  let shortenTeamNameForTitle = (val) => {
    if (val.length > 14) {
      return `${val.substr(0, 11)}...`;
    } else {
      return val;
    }
  };

  let [showShare, setShowShare] = useState(false);
  let [password, setPassword] = useState("");
  let [originalPassword, setOriginalPassword] = useState("");
  let [shareLink, setShareLink] = useState(false);
  let [shareEmail, setShareEmail] = useState(false);
  let [copied, setCopied] = useState(false);
  let [sureDestroy, setSureDestroy] = useState(false);
  let [passwordEdited, setPasswordEdited] = useState(false);

  useEffect(() => {
    // clean not needed

    let newMonOpens = [];
    let newTueOpens = [];
    let newWedOpens = [];
    let newThuOpens = [];
    let newFriOpens = [];
    let newSatOpens = [];
    let newSunOpens = [];

    state.monOpens.forEach((o) => {
      let newObj = o;
      let del = false;
      if (o.openID === memoVals.updatedOpenShift.openID) {
        newObj["bidUserIDs"] = memoVals.updatedOpenShift.bidUserIDs;
        if (memoVals.updatedOpenShift.delete) {
          del = true;
        }
      }
      if (o.openID === memoVals.updatedOpenShift.openID && del) {
      } else {
        newMonOpens.push(newObj);
      }
    });

    state.tueOpens.forEach((o) => {
      let newObj = o;
      let del = false;
      if (o.openID === memoVals.updatedOpenShift.openID) {
        newObj["bidUserIDs"] = memoVals.updatedOpenShift.bidUserIDs;
        if (memoVals.updatedOpenShift.delete) {
          del = true;
        }
      }
      if (o.openID === memoVals.updatedOpenShift.openID && del) {
      } else {
        newTueOpens.push(newObj);
      }
    });

    state.wedOpens.forEach((o) => {
      let newObj = o;
      let del = false;
      if (o.openID === memoVals.updatedOpenShift.openID) {
        newObj["bidUserIDs"] = memoVals.updatedOpenShift.bidUserIDs;
        if (memoVals.updatedOpenShift.delete) {
          del = true;
        }
      }
      if (o.openID === memoVals.updatedOpenShift.openID && del) {
      } else {
        newWedOpens.push(newObj);
      }
    });

    state.thuOpens.forEach((o) => {
      let newObj = o;
      let del = false;
      if (o.openID === memoVals.updatedOpenShift.openID) {
        newObj["bidUserIDs"] = memoVals.updatedOpenShift.bidUserIDs;
        if (memoVals.updatedOpenShift.delete) {
          del = true;
        }
      }
      if (o.openID === memoVals.updatedOpenShift.openID && del) {
      } else {
        newThuOpens.push(newObj);
      }
    });

    state.friOpens.forEach((o) => {
      let newObj = o;
      let del = false;
      if (o.openID === memoVals.updatedOpenShift.openID) {
        newObj["bidUserIDs"] = memoVals.updatedOpenShift.bidUserIDs;
        if (memoVals.updatedOpenShift.delete) {
          del = true;
        }
      }
      if (o.openID === memoVals.updatedOpenShift.openID && del) {
      } else {
        newFriOpens.push(newObj);
      }
    });

    state.satOpens.forEach((o) => {
      let newObj = o;
      let del = false;
      if (o.openID === memoVals.updatedOpenShift.openID) {
        newObj["bidUserIDs"] = memoVals.updatedOpenShift.bidUserIDs;
        if (memoVals.updatedOpenShift.delete) {
          del = true;
        }
      }
      if (o.openID === memoVals.updatedOpenShift.openID && del) {
      } else {
        newSatOpens.push(newObj);
      }
    });

    state.sunOpens.forEach((o) => {
      let newObj = o;
      let del = false;
      if (o.openID === memoVals.updatedOpenShift.openID) {
        newObj["bidUserIDs"] = memoVals.updatedOpenShift.bidUserIDs;
        if (memoVals.updatedOpenShift.delete) {
          del = true;
        }
      }
      if (o.openID === memoVals.updatedOpenShift.openID && del) {
      } else {
        newSunOpens.push(newObj);
      }
    });

    setState({
      monOpens: newMonOpens,
      tueOpens: newTueOpens,
      wedOpens: newWedOpens,
      thuOpens: newThuOpens,
      friOpens: newFriOpens,
      satOpens: newSatOpens,
      sunOpens: newSunOpens,
    });
  }, [memoVals.updatedOpenShift]);

  let [mgrTeams, setMgrTeams] = useState([]);
  let [monWeeks, setMonWeeks] = useState([]);
  let [selectedMonWeek, setSelectedMonWeek] = useState("");
  let [selectedTeam, setSelectedTeam] = useState("");
  let [teamName, setTeamName] = useState("");
  // let [clientID, setClientID] = useState("");
  let [cliID, setCliID] = useState("");
  let [loggedInStandalone, setLoggedInStandalone] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.swaps) {
      memoVals.setShowSwapsModal((x) => true);

      memoVals.setSelectedDesktop((x) => "rota");
      let currentUrl = new URL(window.location.href);

      // Remove query parameters by setting search to an empty string
      currentUrl.search = "";

      // Update the URL without the query parameters
      window.history.replaceState({}, document.title, currentUrl.href);
    }
    if (params && params.view) {
      memoVals.setStandalone((x) => true);

      var progElement = document.querySelector(".progressier-widget-logo");

      // Check if the element exists before setting the display property
      // Set the display property to "none"
      if (progElement) {
        progElement.style.display = "none";
      }
    }
    if (params && params.cliID) {
      setCliID(dateStringer.cleanParam(params.cliID));
    }

    if (params && params.openID && !params.mgr) {
      memoVals.setShowUserOpenShiftModal((x) =>
        dateStringer.cleanParam(params.openID)
      );
    }
    if (params && params.today) {
      let todayDs = new Date();
      todayDs.setHours(0, 0, 0, 0);
      let dsY = dateStringer.createStringFromTimestamp(todayDs.getTime());
      setSelectedDayViewDs(dsY);
      setSelectedMonWeek(dateStringer.getMonWeekOfDs(dsY));
      setDayView(true);
    }
  }, []);

  let [dayView, setDayView] = useState(false);
  let [highlightedComments, setHighlightedComments] = useState([]);
  useEffect(() => {
    if (memoVals.updatedHighlightedComment) {
      let newArr = memoVals.updatedHighlightedComment.comment
        ? [
            {
              comment: memoVals.updatedHighlightedComment.comment,
              ds: memoVals.updatedHighlightedComment.ds,
            },
          ]
        : [];
      highlightedComments.forEach((c) => {
        if (c.ds !== memoVals.updatedHighlightedComment.ds) {
          newArr.push(c);
        }
      });

      setHighlightedComments(newArr);
    }
  }, [memoVals.updatedHighlightedComment]);
  let [generateLoading, setGenerateLoading] = useState(false);
  let [publishLoading, setPublishLoading] = useState(false);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    // console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 46) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      {" "}
      <img
        src={pin}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaMenuOpenShifts}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={popupBlue}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={dashSwap}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={myRotaDayView}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaClockAbs}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaClockLeave}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={shiftBlock}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={profilePicturePlaceholder}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={streamCross}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaPlane}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={commentDay}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={myRotaWeekView}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={popupNavy}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={requestsGreyNavy}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={requestsGreyNavy2}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={lightBlueQuestionMark}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={swapGreyNavy}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={doubleTick}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={requestsGrey}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={emailWhite}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whatsappWhite}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyClose}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteTick}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyTri}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={edit}
        alt="Edit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={comment}
        alt="Comment"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={commentRota}
        alt="Comment Rota"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyArr}
        alt="Grey Arr"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClock}
        alt="Rota Clock"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaPerson}
        alt="Rota Clock"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={briefcase}
        alt="Rota Clock"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaCoffee}
        alt="Rota Coffee"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClockWhite}
        alt="Rota Clock White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={calicon}
        alt="Calicon"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={currWeek}
        alt="Current Week"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={view}
        alt="Popup 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup2}
        alt="Popup 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={downRightArrow2}
        alt="Down Right Arrow 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitTick}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitCross}
        alt="Unit Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={partialAbsentTick}
        alt="Partial Absent Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={hidden}
        alt="Hidden"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={collapseIcon}
        alt="Collapse Icon"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={swapInline}
        alt="Swap Inline"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      allowSwaps: memoVals.allowSwaps,
      adminSwapsEnabled: false,
      userIDsThatUserManages: [],
      isAdmin: false,
      firstLoaded: false,
      specDays: [],
      hoveredBoxForMove: "",
      hoveredShift: {},
      hideSchedLimits: true,
      availData: [],
      sched: [],
      sunCost: 0,
      sunOpens: [],
      monCost: 0,
      monOpens: [],
      tueCost: 0,
      tueOpens: [],
      wedCost: 0,
      wedOpens: [],
      thuCost: 0,
      thuOpens: [],
      friCost: 0,
      friOpens: [],
      satCost: 0,
      satOpens: [],
      secondaryUserIDs: [],
      daysArr: [],
      closedDayNums: [],
      jobTitles: [],
      loadingMove: "",
      singleDay: "",
      singleDay2: "",
      loading: true,
      limLoad: true,
      availLoading: false,
      pasteLoading: false,
      dayLimitData: [],
      jtLimitData: [],
      fadeShiftID: "",
      initialLimitsGot: false,
      loadingLimits: true,
      myJobTitle: "",
      monCommentsQty: 0,
      tueCommentsQty: 0,
      wedCommentsQty: 0,
      thuCommentsQty: 0,
      friCommentsQty: 0,
      satCommentsQty: 0,
      sunCommentsQty: 0,
      maxTs: 0,
      myTotMins: 0,
    }
  );

  useEffect(() => {
    if (initDone) {
      window.location.reload();
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (memoVals.acceptedShiftID) {
      let newArr = [];
      state.sched.forEach((usr) => {
        let newMon = [];
        let newTue = [];
        let newWed = [];
        let newThu = [];
        let newFri = [];
        let newSat = [];
        let newSun = [];

        let allItems = [
          ...usr.monItems,
          ...usr.tueItems,
          ...usr.wedItems,
          ...usr.thuItems,
          ...usr.friItems,
          ...usr.satItems,
          ...usr.sunItems,
        ];

        allItems.forEach((item) => {
          let newObj = item;
          if (memoVals.acceptedShiftID === item.typeID) {
            newObj["accepted"] = dateStringer.createStringFromTimestamp(
              new Date().getTime()
            );
          }

          if (item.dayN === 1) {
            newMon.push(newObj);
          }

          if (item.dayN === 2) {
            newTue.push(newObj);
          }

          if (item.dayN === 3) {
            newWed.push(newObj);
          }

          if (item.dayN === 4) {
            newThu.push(newObj);
          }

          if (item.dayN === 5) {
            newFri.push(newObj);
          }

          if (item.dayN === 6) {
            newSat.push(newObj);
          }

          if (item.dayN === 0) {
            newSun.push(newObj);
          }
        });

        let userCost = usr.userCost;
        let primaryOrder = usr.primaryOrder;
        let userID = usr.userID;
        let fName = usr.fName;
        let lName = usr.lName;
        let jobTitle = usr.jobTitle;
        let profPicUrl = usr.profPicUrl;

        let monItems = newMon;
        let tueItems = newTue;
        let wedItems = newWed;
        let thuItems = newThu;
        let friItems = newFri;
        let satItems = newSat;
        let sunItems = newSun;
        newArr.push({
          userCost,
          primaryOrder,
          userID,
          fName,
          lName,
          jobTitle,
          monItems,
          tueItems,
          wedItems,
          thuItems,
          friItems,
          satItems,
          sunItems,
          profPicUrl,
          primaryTeamID: usr.primaryTeamID,
          primaryTeamName: usr.primaryTeamName,
        });
      });

      setState({ sched: newArr });
      memoVals.setAcceptedShiftID((x) => "");
    }
  }, [memoVals.acceptedShiftID]);
  let [dayViewArr, setDayViewArr] = useState([]);
  let [selectedDayViewDs, setSelectedDayViewDs] = useState(
    `${dateStringer
      .createStringFromTimestamp(new Date().getTime())
      .substr(0, 11)}H00M00`
    // "Y2024M08D04H00M00"
  );

  useEffect(() => {}, [selectedDayViewDs]);

  useEffect(() => {
    // clean not needed

    if (memoVals.amendDayComment.ds) {
      let dateObj = new Date(
        dateStringer.createTimestampFromString(memoVals.amendDayComment.ds)
      );
      if (dateObj.getDay() === 0) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ sunCommentsQty: state.sunCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ sunCommentsQty: state.sunCommentsQty - 1 });
        }
      }

      if (dateObj.getDay() === 1) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ monCommentsQty: state.monCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ monCommentsQty: state.monCommentsQty - 1 });
        }
      }

      if (dateObj.getDay() === 2) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ tueCommentsQty: state.tueCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ tueCommentsQty: state.tueCommentsQty - 1 });
        }
      }

      if (dateObj.getDay() === 3) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ wedCommentsQty: state.wedCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ wedCommentsQty: state.wedCommentsQty - 1 });
        }
      }

      if (dateObj.getDay() === 4) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ thuCommentsQty: state.thuCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ thuCommentsQty: state.thuCommentsQty - 1 });
        }
      }

      if (dateObj.getDay() === 5) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ friCommentsQty: state.friCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ friCommentsQty: state.friCommentsQty - 1 });
        }
      }

      if (dateObj.getDay() === 6) {
        if (memoVals.amendDayComment.plusOne) {
          setState({ satCommentsQty: state.satCommentsQty + 1 });
        }
        if (memoVals.amendDayComment.minusOne) {
          setState({ satCommentsQty: state.satCommentsQty - 1 });
        }
      }
    }
  }, [memoVals.amendDayComment]);

  let [noPermissions, setNoPermissions] = useState(false);
  let [showLimitTools, setShowLimitTools] = useState(false);
  let [currMonWeek, setCurrMonWeek] = useState("");

  // ^ userIDs that are not primary, but instead have shifts in this team for this week

  // ------

  let [acceptanceKeyRequired, setAcceptanceKeyRequired] = useState(false);

  // let [indicate, setIndicate] = useState("");

  let [specDays, setSpecDays] = useState([]);

  let showSpecDays = () => {
    if (
      (state.specDays.d0 && state.specDays.d0.name) ||
      (state.specDays.d1 && state.specDays.d1.name) ||
      (state.specDays.d2 && state.specDays.d2.name) ||
      (state.specDays.d3 && state.specDays.d3.name) ||
      (state.specDays.d4 && state.specDays.d4.name) ||
      (state.specDays.d5 && state.specDays.d5.name) ||
      (state.specDays.d6 && state.specDays.d6.name)
    ) {
      return true;
    } else {
      return false;
    }
  };
  let shortenSpecDay = (txt) => {
    if (txt && txt.length > 20) {
      return `${txt.substr(0, 19)}...`;
    } else {
      return txt;
    }
  };
  let specDay = (ds) => {
    let dateObj = new Date(dateStringer.createTimestampFromString(ds));
    if (dateObj.getDay() === 0) {
      return shortenSpecDay(state.specDays.d0.name);
    }
    if (dateObj.getDay() === 1) {
      return shortenSpecDay(state.specDays.d1.name);
    }
    if (dateObj.getDay() === 2) {
      return shortenSpecDay(state.specDays.d2.name);
    }
    if (dateObj.getDay() === 3) {
      return shortenSpecDay(state.specDays.d3.name);
    }
    if (dateObj.getDay() === 4) {
      return shortenSpecDay(state.specDays.d4.name);
    }
    if (dateObj.getDay() === 5) {
      return shortenSpecDay(state.specDays.d5.name);
    }
    if (dateObj.getDay() === 6) {
      return shortenSpecDay(state.specDays.d6.name);
    }
  };

  let [showTip, setShowTip] = useState(false);

  useEffect(() => {
    // clean not needed
    // reloads shifts when memoVals.update2 is updated in DataContext by the ManagerShiftModal
    memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
  }, [memoVals.update2]);

  // update sched locally is a shift from myShift has a note/swap info removed
  useEffect(() => {
    // clean not needed

    if (
      memoVals.dynamicSwapOrNote &&
      (memoVals.dynamicSwapOrNote.swapOrNote === "note" ||
        memoVals.dynamicSwapOrNote.swapOrNote === "swap")
    ) {
      let newArr = [];
      state.sched.forEach((usr) => {
        let newMon = [];
        let newTue = [];
        let newWed = [];
        let newThu = [];
        let newFri = [];
        let newSat = [];
        let newSun = [];

        let allItems = [
          ...usr.monItems,
          ...usr.tueItems,
          ...usr.wedItems,
          ...usr.thuItems,
          ...usr.friItems,
          ...usr.satItems,
          ...usr.sunItems,
        ];

        allItems.forEach((item) => {
          let newObj = item;
          if (memoVals.dynamicSwapOrNote.typeID === item.typeID) {
            if (memoVals.dynamicSwapOrNote.swapOrNote === "note") {
              if (memoVals.dynamicSwapOrNote.note) {
                newObj["publicNote"] = ["yes"];
              } else {
                newObj["publicNote"] = [];
              }
            }
            if (memoVals.dynamicSwapOrNote.swapOrNote === "swap") {
              if (memoVals.dynamicSwapOrNote.swap) {
                newObj["userHasSwapped"] = true;
              } else {
                newObj["userHasSwapped"] = false;
              }
            }
          }

          if (item.dayN === 1) {
            newMon.push(newObj);
          }

          if (item.dayN === 2) {
            newTue.push(newObj);
          }

          if (item.dayN === 3) {
            newWed.push(newObj);
          }

          if (item.dayN === 4) {
            newThu.push(newObj);
          }

          if (item.dayN === 5) {
            newFri.push(newObj);
          }

          if (item.dayN === 6) {
            newSat.push(newObj);
          }

          if (item.dayN === 0) {
            newSun.push(newObj);
          }
        });

        let userCost = usr.userCost;
        let primaryOrder = usr.primaryOrder;
        let userID = usr.userID;
        let fName = usr.fName;
        let lName = usr.lName;
        let jobTitle = usr.jobTitle;
        let profPicUrl = usr.profPicUrl;

        let monItems = newMon;
        let tueItems = newTue;
        let wedItems = newWed;
        let thuItems = newThu;
        let friItems = newFri;
        let satItems = newSat;
        let sunItems = newSun;
        newArr.push({
          userCost,
          primaryOrder,
          userID,
          fName,
          lName,
          jobTitle,
          monItems,
          tueItems,
          wedItems,
          thuItems,
          friItems,
          satItems,
          sunItems,
          profPicUrl,
          primaryTeamID: usr.primaryTeamID,
          primaryTeamName: usr.primaryTeamName,
        });
      });

      setState({ sched: newArr });
    }
  }, [memoVals.dynamicSwapOrNote]);

  let calcUserData = (userID) => {
    // returns object {payableMins, cost};

    let userFilt = state.sched.filter((x) => {
      return x.userID === userID;
    });

    if (userFilt[0]) {
      let items = [
        ...userFilt[0].monItems,
        ...userFilt[0].tueItems,
        ...userFilt[0].wedItems,
        ...userFilt[0].thuItems,
        ...userFilt[0].friItems,
        ...userFilt[0].satItems,
        ...userFilt[0].sunItems,
      ];

      let mins = 0;
      let cost = 0;
      items.forEach((item) => {
        if (
          item.type === "leave" &&
          userFilt[0].primaryTeamID === selectedTeam
        ) {
          if (!item.unpaid) {
            mins += item.durMins;
            cost += item.cost;
          }
        }

        if (item.type === "shift" || item.type === "overtime") {
          mins += item.durMins;
          cost += item.cost;
        }
      });

      return { mins, cost };
    }
  };
  // update sched locally with the updated item -- without calling the request again
  useEffect(() => {
    // clean not needed

    if (!memoVals.dontAddNewItemToRota) {
      // if (!memoVals.dontAddNewItemToRota) {
      if (memoVals.updatedItem && memoVals.updatedItem.type === "leave") {
        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
      }
      if (memoVals.updatedItem.data || memoVals.updatedItem.reqID) {
        if (memoVals.updatedItem.doesNotExist) {
          setState({ fadeShiftID: memoVals.updatedItem.failedID });
        }
        // "leave" changes do not have the data property - so assumed is a shift or til
        let typeID = memoVals.updatedItem.reqID
          ? memoVals.updatedItem.reqID
          : memoVals.updatedItem.type === "shift"
          ? memoVals.updatedItem.data.shiftID
          : memoVals.updatedItem.data.tilID;

        let newItem = memoVals.updatedItem.reqID
          ? {}
          : {
              absent: memoVals.updatedItem.data.absent,
              bankCost: memoVals.updatedItem.data.bankCost,
              break: memoVals.updatedItem.data.break,
              dayN: memoVals.updatedItem.dayN,
              ds: memoVals.updatedItem.data.ds,
              durMins: memoVals.updatedItem.data.durMins,
              end: memoVals.updatedItem.data.end,
              ghost: memoVals.updatedItem.data.ghost,
              initial: false,
              midnightTimestamp: memoVals.updatedItem.midnightTimestamp,
              published: memoVals.updatedItem.published,
              start: memoVals.updatedItem.data.start,
              startTs: dateStringer.createTimestampFromString(
                memoVals.updatedItem.data.start
              ),
              tags: memoVals.updatedItem.data.tags,
              type: memoVals.updatedItem.type,
              typeID: typeID,
              cost: memoVals.updatedItem.data.cost,
              salaried: memoVals.updatedItem.salaried,
              publicNote: memoVals.updatedItem.publicNote,
              requireAccept: memoVals.updatedItem.requireAccept,
              accepted: memoVals.updatedItem.accepted,
            };

        console.log("NEW ITEM: ", memoVals.updatedItem);

        let isNew = memoVals.updatedItem.reqID ? false : true;
        if (!memoVals.updatedItem.teamChanged && !memoVals.updatedItem.reqID) {
          state.sched.forEach((user) => {
            user.monItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
            user.tueItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
            user.wedItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
            user.thuItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
            user.friItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
            user.satItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
            user.sunItems.forEach((item) => {
              if (item.typeID === typeID) {
                isNew = false;
              }
            });
          });
        }

        let newArr = [];
        state.sched.forEach((usr) => {
          let newMon = [];
          let newTue = [];
          let newWed = [];
          let newThu = [];
          let newFri = [];
          let newSat = [];
          let newSun = [];
          usr.monItems.forEach((item) => {
            if (item.typeID === typeID) {
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newMon.push(newItem);
              }
            } else {
              // if(item.typeID !== forceDeleteShiftOrTil) {

              // }
              newMon.push(item);
            }
          });

          if (isNew) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 1) {
                newMon.push(newItem);
              }
            }
          }

          usr.tueItems.forEach((item) => {
            if (item.typeID === typeID) {
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newTue.push(newItem);
              }
            } else {
              newTue.push(item);
            }
          });

          if (isNew) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 2) {
                newTue.push(newItem);
              }
            }
          }

          usr.wedItems.forEach((item) => {
            if (item.typeID === typeID) {
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newWed.push(newItem);
              }
            } else {
              newWed.push(item);
            }
          });

          if (isNew) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 3) {
                newWed.push(newItem);
              }
            }
          }

          usr.thuItems.forEach((item) => {
            if (item.typeID === typeID) {
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newThu.push(newItem);
              }
            } else {
              newThu.push(item);
            }
          });

          if (isNew) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 4) {
                newThu.push(newItem);
              }
            }
          }

          usr.friItems.forEach((item) => {
            if (item.typeID === typeID) {
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newFri.push(newItem);
              }
            } else {
              newFri.push(item);
            }
          });

          if (isNew) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 5) {
                newFri.push(newItem);
              }
            }
          }

          usr.satItems.forEach((item) => {
            if (item.typeID === typeID) {
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newSat.push(newItem);
              }
            } else {
              newSat.push(item);
            }
          });

          if (isNew) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 6) {
                newSat.push(newItem);
              }
            }
          }

          usr.sunItems.forEach((item) => {
            if (item.typeID === typeID) {
              if (
                !memoVals.updatedItem.teamChanged &&
                !memoVals.updatedItem.deleted &&
                !memoVals.updatedItem.doesNotExist &&
                item.typeID !== memoVals.updatedItem.failedID
              ) {
                newSun.push(newItem);
              }
            } else {
              newSun.push(item);
            }
          });

          if (isNew) {
            if (usr.userID === memoVals.updatedItem.data.userID) {
              if (memoVals.updatedItem.dayN === 0) {
                newSun.push(newItem);
              }
            }
          }

          let userCost = usr.userCost;
          let primaryOrder = usr.primaryOrder;
          let userID = usr.userID;
          let fName = usr.fName;
          let lName = usr.lName;
          let jobTitle = usr.jobTitle;
          let profPicUrl = usr.profPicUrl;

          let monItems = newMon;
          let tueItems = newTue;
          let wedItems = newWed;
          let thuItems = newThu;
          let friItems = newFri;
          let satItems = newSat;
          let sunItems = newSun;
          newArr.push({
            userCost,
            primaryOrder,
            userID,
            fName,
            lName,
            jobTitle,
            monItems,
            tueItems,
            wedItems,
            thuItems,
            friItems,
            satItems,
            sunItems,
            profPicUrl,
            primaryTeamID: usr.primaryTeamID,
            primaryTeamName: usr.primaryTeamName,
          });
        });

        setState({ sched: newArr });
      }

      // }
    }
  }, [memoVals.updatedItem]);

  useEffect(() => {
    // clean not needed

    if (memoVals.dynamicSwap.teamID === selectedTeam) {
      // update the sched if memoVals.dynamicSwap is updated
      console.log("dynamic swap is: ", memoVals.dynamicSwap);
      let deleteTypeIDs = memoVals.dynamicSwap.getRidOfTypeIDs;
      let newItem1 = memoVals.dynamicSwap.newItem1;
      let newItem2 = memoVals.dynamicSwap.newItem2;

      let newArr = [];
      state.sched.forEach((usr) => {
        let newMon = [];
        let newTue = [];
        let newWed = [];
        let newThu = [];
        let newFri = [];
        let newSat = [];
        let newSun = [];

        usr.monItems.forEach((item) => {
          if (!deleteTypeIDs.includes(item.typeID)) {
            newMon.push(item);
          }
        });

        if (newItem1.userID === usr.userID && newItem1.dayN === 1) {
          newMon.push(newItem1);
        }

        if (newItem2.userID === usr.userID && newItem2.dayN === 1) {
          newMon.push(newItem2);
        }

        // - - - - - - - - - - -

        usr.tueItems.forEach((item) => {
          if (!deleteTypeIDs.includes(item.typeID)) {
            newTue.push(item);
          }
        });

        if (newItem1.userID === usr.userID && newItem1.dayN === 2) {
          newTue.push(newItem1);
        }

        if (newItem2.userID === usr.userID && newItem2.dayN === 2) {
          newTue.push(newItem2);
        }

        // - - - - - - - - - - -

        usr.wedItems.forEach((item) => {
          if (!deleteTypeIDs.includes(item.typeID)) {
            newWed.push(item);
          }
        });

        if (newItem1.userID === usr.userID && newItem1.dayN === 3) {
          newWed.push(newItem1);
        }

        if (newItem2.userID === usr.userID && newItem2.dayN === 3) {
          newWed.push(newItem2);
        }
        // - - - - - - - - - - -

        usr.thuItems.forEach((item) => {
          if (!deleteTypeIDs.includes(item.typeID)) {
            newThu.push(item);
          }
        });

        if (newItem1.userID === usr.userID && newItem1.dayN === 4) {
          newThu.push(newItem1);
        }

        if (newItem2.userID === usr.userID && newItem2.dayN === 4) {
          newThu.push(newItem2);
        }
        // - - - - - - - - - - -

        usr.friItems.forEach((item) => {
          if (!deleteTypeIDs.includes(item.typeID)) {
            newFri.push(item);
          }
        });

        if (newItem1.userID === usr.userID && newItem1.dayN === 5) {
          newFri.push(newItem1);
        }

        if (newItem2.userID === usr.userID && newItem2.dayN === 5) {
          newFri.push(newItem2);
        }
        // - - - - - - - - - - -

        usr.satItems.forEach((item) => {
          if (!deleteTypeIDs.includes(item.typeID)) {
            newSat.push(item);
          }
        });

        if (newItem1.userID === usr.userID && newItem1.dayN === 6) {
          newSat.push(newItem1);
        }

        if (newItem2.userID === usr.userID && newItem2.dayN === 6) {
          newSat.push(newItem2);
        }

        // - - - - - - - - - - -

        usr.sunItems.forEach((item) => {
          if (!deleteTypeIDs.includes(item.typeID)) {
            newSun.push(item);
          }
        });

        if (newItem1.userID === usr.userID && newItem1.dayN === 0) {
          newSun.push(newItem1);
        }

        if (newItem2.userID === usr.userID && newItem2.dayN === 0) {
          newSun.push(newItem2);
        }

        // - - - - - - - - - - -

        let userCost = usr.userCost;
        let primaryOrder = usr.primaryOrder;
        let userID = usr.userID;
        let fName = usr.fName;
        let lName = usr.lName;
        let jobTitle = usr.jobTitle;
        let profPicUrl = usr.profPicUrl;

        let monItems = newMon;
        let tueItems = newTue;
        let wedItems = newWed;
        let thuItems = newThu;
        let friItems = newFri;
        let satItems = newSat;
        let sunItems = newSun;
        newArr.push({
          userCost,
          primaryOrder,
          userID,
          fName,
          lName,
          jobTitle,
          monItems,
          tueItems,
          wedItems,
          thuItems,
          friItems,
          satItems,
          sunItems,
          profPicUrl,
          primaryTeamID: usr.primaryTeamID,
          primaryTeamName: usr.primaryTeamName,
        });
      });

      setState({ sched: newArr });
    }
  }, [memoVals.dynamicSwap]);

  useEffect(() => {
    // clean not needed

    if (
      memoVals.changedMidnightTimestamp &&
      memoVals.changedMidnightTimestamp.typeID
    ) {
      let newArr = [];
      state.sched.forEach((usr) => {
        let newMon = [];
        let newTue = [];
        let newWed = [];
        let newThu = [];
        let newFri = [];
        let newSat = [];
        let newSun = [];

        usr.monItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 1 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newMon.push(item);
          }
        });

        usr.tueItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 2 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newTue.push(item);
          }
        });

        usr.wedItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 3 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newWed.push(item);
          }
        });

        usr.thuItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 4 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newThu.push(item);
          }
        });

        usr.friItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 5 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newFri.push(item);
          }
        });

        usr.satItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 6 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newSat.push(item);
          }
        });

        usr.sunItems.forEach((item) => {
          if (
            memoVals.changedMidnightTimestamp.oldDayN === 0 &&
            item.typeID === memoVals.changedMidnightTimestamp.typeID
          ) {
            // do not add because it no longer lives here
            if (memoVals.changedMidnightTimestamp.newDayN === 1) {
              newMon.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 2) {
              newTue.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 3) {
              newWed.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 4) {
              newThu.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 5) {
              newFri.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 6) {
              newSat.push(item);
            }
            if (memoVals.changedMidnightTimestamp.newDayN === 0) {
              newSun.push(item);
            }
          } else {
            newSun.push(item);
          }
        });

        let userCost = usr.userCost;
        let primaryOrder = usr.primaryOrder;
        let userID = usr.userID;
        let fName = usr.fName;
        let lName = usr.lName;
        let jobTitle = usr.jobTitle;
        let profPicUrl = usr.profPicUrl;

        let monItems = newMon;
        let tueItems = newTue;
        let wedItems = newWed;
        let thuItems = newThu;
        let friItems = newFri;
        let satItems = newSat;
        let sunItems = newSun;
        newArr.push({
          userCost,
          primaryOrder,
          userID,
          fName,
          lName,
          jobTitle,
          monItems,
          tueItems,
          wedItems,
          thuItems,
          friItems,
          satItems,
          sunItems,
          profPicUrl,
          primaryTeamID: usr.primaryTeamID,
          primaryTeamName: usr.primaryTeamName,
        });
      });
      setState({ sched: newArr });
    }
  }, [memoVals.changedMidnightTimestamp]);

  useEffect(() => {
    // clean not needed

    if (memoVals.updateOpen.openID) {
      // reload updated open
      let dateObj = new Date(
        dateStringer.createTimestampFromString(memoVals.updateOpen.start)
      );
      let dayN = dateObj.getDay();

      let monNew = [];
      let tueNew = [];
      let wedNew = [];
      let thuNew = [];
      let friNew = [];
      let satNew = [];
      let sunNew = [];

      if (dayN === 0) {
        sunNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }

      if (dayN === 1) {
        monNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }

      if (dayN === 2) {
        tueNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }

      if (dayN === 3) {
        wedNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }

      if (dayN === 4) {
        thuNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }

      if (dayN === 5) {
        friNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }

      if (dayN === 6) {
        satNew.push({
          openID: memoVals.updateOpen.openID,
          bidUserIDs: memoVals.updateOpen.bidUserIDs,
          startDs: memoVals.updateOpen.start,
          endDs: memoVals.updateOpen.end,
          ds: memoVals.updateOpen.start,
        });
      }
      state.monOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          monNew.push(item);
        }
      });

      state.tueOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          tueNew.push(item);
        }
      });

      state.wedOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          wedNew.push(item);
        }
      });

      state.thuOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          thuNew.push(item);
        }
      });

      state.friOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          friNew.push(item);
        }
      });

      state.satOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          satNew.push(item);
        }
      });

      state.sunOpens.forEach((item) => {
        if (item.openID !== memoVals.updateOpen.openID) {
          sunNew.push(item);
        }
      });
      setState({
        monOpens: monNew,
        tueOpens: tueNew,
        wedOpens: wedNew,
        thuOpens: thuNew,
        friOpens: friNew,
        satOpens: satNew,
        sunOpens: sunNew,
      });
    }
  }, [memoVals.updateOpen]);

  let [hoverUserID, setHoverUserID] = useState("");
  let [hoverDs, setHoverDs] = useState("");

  let [fadeGenBtn, setFadeGenBtn] = useState(false);
  let [currentMw, setCurrentMw] = useState("");

  let [weekHasNoData, setWeekHasNoData] = useState(false);
  let [lastDs, setLastDs] = useState("");

  // split == is today split through curr week?
  useEffect(() => {
    // clean not needed

    setState({ pasteLoading: true });
    //

    let dateObj = new Date(dateStringer.createTimestampFromString(currentMw));
    dateObj.setDate(dateObj.getDate() + 6);
    dateObj.setHours(23, 59, 0, 0);
    if (
      dateStringer.createTimestampFromString(selectedMonWeek) >=
      dateStringer.createTimestampFromString(currentMw)
    ) {
      setFadeGenBtn(false);
    } else {
      setFadeGenBtn(true);
    }

    let day2currWeek = new Date(
      dateStringer.createTimestampFromString(selectedMonWeek)
    );
    day2currWeek.setDate(day2currWeek.getDate() + 1);

    let dateObj2 = new Date(
      dateStringer.createTimestampFromString(selectedMonWeek)
    );
    dateObj2.setDate(dateObj2.getDate() + 6);
    dateObj2.setHours(12, 59, 0, 0);
    setLastDs(dateStringer.createStringFromTimestamp(dateObj2.getTime()));
  }, [selectedTeam, selectedMonWeek, currentMw]);

  let [linkExists, setLinkExists] = useState(false);

  let [todayTs, setTodayTs] = useState(0);

  let [clockedShiftID, setClockedShiftID] = useState("");
  let [initDone, setInitDone] = useState(false);

  let [rotaDayLoading, setRotaDayLoading] = useState(true);

  // dv = dayView
  let [dvState, setDvState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      payDay: false,
      highlightedComment: "",
      highlightedUserName: "",
      highlightedUserProfPic: "",
      commentsQty: 0,
      opens: [],
      leave: [],
      absence: [],
      shifts: [],
    }
  );
  // - - - -
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    const cancelSource2 = axios.CancelToken.source();

    if (memoVals.mob && dayView) {
      if (!rotaDayLoading) {
        setRotaDayLoading(true);
      }
      if (selectedDayViewDs) {
        console.log("Get rota day");
        axios
          .post(
            `${serverURL}/rota-day`,
            {
              ds: selectedDayViewDs,
              teamID: selectedTeam,
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message.includes("logout")) {
              window.location.href = "/signin";
            }
            if (response.data.message === "success") {
              setDvState({
                payDay: response.data.payDay,
                highlightedComment: response.data.highlightedComment,
                highlightedUserName: response.data.highlightedUserName,
                highlightedUserProfPic: response.data.highlightedUserProfPic,
                commentsQty: response.data.commentsQty,
                opens: response.data.opens,
                leave: response.data.leave || [],
                absence: response.data.absence || [],
                shifts: response.data.shifts,
              });
              setState({ loading: false });
              setOpensQty(response.data.opensQty);
              setLoading(false);
              setTimeout(() => {
                setRotaDayLoading(false);
              }, 150);
            }
          });
      }
    } else {
      if (selectedMonWeek) {
        setState({ loading: true, limLoad: true });

        let teamFilt = mgrTeams.filter((item) => item.teamID === selectedTeam);
        if (teamFilt[0]) {
          document.title = `My rota - ${shortenTeamNameForTitle(
            teamFilt[0].teamName
          )}`;
        } else {
          document.title = "My rotas";
        }

        let mainReq = async () => {
          axios
            .post(
              `${serverURL}/get-team-schedule-for-mon-week`,
              {
                cliID,
                monWeek: selectedMonWeek,
                teamID: locationID ? "" : selectedTeam,
                locationID: locationID ? locationID : "",
                singleDay: state.singleDay,
                singleDay2: state.singleDay2,
                singleUserID: memoVals.loadSingleUserSched
                  ? memoVals.loadSingleUserSched
                  : false,
                myRota: true,
                nowDs: dateStringer.createStringFromTimestamp(
                  new Date().getTime()
                ),
                mob: memoVals.mob,
                // myRotaSingleDay: dayView ? selectedDayViewDs : false,
              },

              {
                withCredentials: true,
                credentials: "include",
                cancelToken: cancelSource1.token,
              }
            )
            .then((response) => {
              if (response.data.message.includes("logout")) {
                window.location.href = "/signin";
              }
              if (response.data.message === "success") {
                setInitDone(true);
                if (!initDone) {
                  if (memoVals.mob) {
                    setDayView(response.data.dayView);
                  }
                  setInitDone(true);
                }
                setClockedShiftID(response.data.clockedShiftID);
                setHighlightedComments(response.data.highlightedComments);

                let todayDateObj = new Date();
                todayDateObj.setHours(0, 0, 0, 0);
                setManagesThisTeam(response.data.managesThisTeam);
                setTodayTs(todayDateObj.getTime());
                setAcceptanceKeyRequired(response.data.requireSeen);

                if (
                  memoVals.standalone &&
                  response.data.linkData.active === false
                ) {
                  console.log("LINK NOT ACTIVE! ABORT");
                  window.location.href = `${appURL}/inactive-rota-link`;
                } else {
                  console.log("DATA RECEIVED");

                  setTeamName(
                    response.data.teamName || response.data.locationName
                  );

                  if (memoVals.standalone) {
                    document.title = `${
                      response.data.teamName || response.data.locationName
                    } rota for ${dateStringer.printedDateFromDs(
                      selectedMonWeek
                    )}`;

                    if (response.data.standaloneLoggedInUserID) {
                      let userFiltX = response.data.userIDs.filter((x) => {
                        return x === response.data.standaloneLoggedInUserID;
                      });
                      setLoggedInStandalone(
                        userFiltX[0] ? response.data.standaloneFName : false
                      );
                    }
                  }

                  if (response.data.linkData.active) {
                    setPassword(response.data.linkData.password);
                    setOriginalPassword(response.data.linkData.password);
                    setShareLink(response.data.linkData.shortenedLink);
                    setLinkExists(true);
                  } else {
                    if (linkExists) {
                      setLinkExists(false);
                    }
                    if (password) {
                      setPassword("");
                    }
                    if (shareLink) {
                      setShareLink(false);
                    }
                  }

                  // SCHEDULE DATA
                  let schedLocal = [];

                  if (state.singleDay || state.singleDay2) {
                    let newSchedArr = [];

                    response.data.arr.forEach((usr) => {
                      let items = usr.sunItems;
                      let items2 = usr.sunItems;
                      if (state.singleDay === 1) {
                        items = usr.monItems;
                      }
                      if (state.singleDay2 === 1) {
                        items2 = usr.monItems;
                      }
                      if (state.singleDay === 2) {
                        items = usr.tueItems;
                      }
                      if (state.singleDay2 === 2) {
                        items2 = usr.tueItems;
                      }
                      if (state.singleDay === 3) {
                        items = usr.wedItems;
                      }
                      if (state.singleDay2 === 3) {
                        items2 = usr.wedItems;
                      }

                      if (state.singleDay === 4) {
                        items = usr.thuItems;
                      }
                      if (state.singleDay2 === 4) {
                        items2 = usr.thuItems;
                      }
                      if (state.singleDay === 5) {
                        items = usr.friItems;
                      }
                      if (state.singleDay2 === 5) {
                        items2 = usr.friItems;
                      }
                      if (state.singleDay === 6) {
                        items = usr.satItems;
                      }
                      if (state.singleDay2 === 6) {
                        items2 = usr.satItems;
                      }

                      state.sched.forEach((user) => {
                        if (usr.userID === user.userID) {
                          if (state.singleDay === 0) {
                            user.sunItems = items;
                          }
                          if (state.singleDay2 === 0) {
                            user.sunItems = items2;
                          }
                          if (state.singleDay === 1) {
                            user.monItems = items;
                          }
                          if (state.singleDay2 === 1) {
                            user.monItems = items2;
                          }
                          if (state.singleDay === 2) {
                            user.tueItems = items;
                          }
                          if (state.singleDay2 === 2) {
                            user.tueItems = items2;
                          }
                          if (state.singleDay === 3) {
                            user.wedItems = items;
                          }
                          if (state.singleDay2 === 3) {
                            user.wedItems = items2;
                          }
                          if (state.singleDay === 4) {
                            user.thuItems = items;
                          }
                          if (state.singleDay2 === 4) {
                            user.thuItems = items2;
                          }

                          if (state.singleDay === 5) {
                            user.friItems = items;
                          }
                          if (state.singleDay2 === 5) {
                            user.friItems = items2;
                          }
                          if (state.singleDay === 6) {
                            user.satItems = items;
                          }
                          if (state.singleDay2 === 6) {
                            user.satItems = items2;
                          }
                          newSchedArr.push(user);
                        }
                      });
                    });
                    schedLocal = newSchedArr;
                  } else {
                    schedLocal = response.data.arr;
                  }

                  let c = 0;
                  let locs = [];
                  let locationResp = response.data.locations;
                  response.data.arr.forEach((usr) => {
                    usr.monItems.forEach((item) => {
                      if (item.location) {
                        if (Array.isArray(locationResp)) {
                          let locFilt = locationResp.filter((xx) => {
                            return xx.locationName === item.location;
                          });
                          if (locFilt[0]) {
                            let existing = locs.filter((xxx) => {
                              return xxx.locationID === locFilt[0].locationID;
                            });
                            if (!existing[0]) {
                              locs.push({
                                locationID: locFilt[0].locationID,
                                locationName: locFilt[0].locationName,
                              });
                            }
                          }
                        }
                      }
                      c++;
                    });
                    usr.tueItems.forEach((item) => {
                      if (item.location) {
                        if (Array.isArray(locationResp)) {
                          let locFilt = locationResp.filter((xx) => {
                            return xx.locationName === item.location;
                          });
                          if (locFilt[0]) {
                            let existing = locs.filter((xxx) => {
                              return xxx.locationID === locFilt[0].locationID;
                            });
                            if (!existing[0]) {
                              locs.push({
                                locationID: locFilt[0].locationID,
                                locationName: locFilt[0].locationName,
                              });
                            }
                          }
                        }
                      }
                      c++;
                    });
                    usr.wedItems.forEach((item) => {
                      if (item.location) {
                        if (Array.isArray(locationResp)) {
                          let locFilt = locationResp.filter((xx) => {
                            return xx.locationName === item.location;
                          });
                          if (locFilt[0]) {
                            let existing = locs.filter((xxx) => {
                              return xxx.locationID === locFilt[0].locationID;
                            });
                            if (!existing[0]) {
                              locs.push({
                                locationID: locFilt[0].locationID,
                                locationName: locFilt[0].locationName,
                              });
                            }
                          }
                        }
                      }
                      c++;
                    });
                    usr.thuItems.forEach((item) => {
                      if (item.location) {
                        if (Array.isArray(locationResp)) {
                          let locFilt = locationResp.filter((xx) => {
                            return xx.locationName === item.location;
                          });
                          if (locFilt[0]) {
                            let existing = locs.filter((xxx) => {
                              return xxx.locationID === locFilt[0].locationID;
                            });
                            if (!existing[0]) {
                              locs.push({
                                locationID: locFilt[0].locationID,
                                locationName: locFilt[0].locationName,
                              });
                            }
                          }
                        }
                      }
                      c++;
                    });
                    usr.friItems.forEach((item) => {
                      if (item.location) {
                        if (Array.isArray(locationResp)) {
                          let locFilt = locationResp.filter((xx) => {
                            return xx.locationName === item.location;
                          });
                          if (locFilt[0]) {
                            let existing = locs.filter((xxx) => {
                              return xxx.locationID === locFilt[0].locationID;
                            });
                            if (!existing[0]) {
                              locs.push({
                                locationID: locFilt[0].locationID,
                                locationName: locFilt[0].locationName,
                              });
                            }
                          }
                        }
                      }
                      c++;
                    });
                    usr.satItems.forEach((item) => {
                      if (item.location) {
                        if (Array.isArray(locationResp)) {
                          let locFilt = locationResp.filter((xx) => {
                            return xx.locationName === item.location;
                          });
                          if (locFilt[0]) {
                            let existing = locs.filter((xxx) => {
                              return xxx.locationID === locFilt[0].locationID;
                            });
                            if (!existing[0]) {
                              locs.push({
                                locationID: locFilt[0].locationID,
                                locationName: locFilt[0].locationName,
                              });
                            }
                          }
                        }
                      }
                      c++;
                    });
                    usr.sunItems.forEach((item) => {
                      if (item.location) {
                        if (Array.isArray(locationResp)) {
                          let locFilt = locationResp.filter((xx) => {
                            return xx.locationName === item.location;
                          });
                          if (locFilt[0]) {
                            let existing = locs.filter((xxx) => {
                              return xxx.locationID === locFilt[0].locationID;
                            });
                            if (!existing[0]) {
                              locs.push({
                                locationID: locFilt[0].locationID,
                                locationName: locFilt[0].locationName,
                              });
                            }
                          }
                        }
                      }
                      c++;
                    });
                  });
                  if (c === 0) {
                    setWeekHasNoData(true);
                  }

                  if (loading) {
                    setLoading(false);
                  }

                  setLocations(locs);
                  if (locationID) {
                    let locsFilt = locs.filter((x) => {
                      return x.locationID === locationID;
                    });
                    if (locsFilt[0]) {
                      setLocationName(locsFilt[0].locationName);
                    }
                  }
                  let monCostLocal = response.data.monCost || 0;
                  let tueCostLocal = response.data.tueCost || 0;
                  let wedCostLocal = response.data.wedCost || 0;
                  let thuCostLocal = response.data.thuCost || 0;
                  let friCostLocal = response.data.friCost || 0;
                  let satCostLocal = response.data.satCost || 0;
                  let sunCostLocal = response.data.sunCost || 0;

                  let monOpensLocal = response.data.monOpens;
                  let tueOpensLocal = response.data.tueOpens;
                  let wedOpensLocal = response.data.wedOpens;
                  let thuOpensLocal = response.data.thuOpens;
                  let friOpensLocal = response.data.friOpens;
                  let satOpensLocal = response.data.satOpens;
                  let sunOpensLocal = response.data.sunOpens;

                  if (state.singleDay) {
                    if (state.singleDay === 0) {
                      sunCostLocal = response.data.sunCost || 0;
                      sunOpensLocal = response.data.sunOpens;
                    }

                    if (state.singleDay === 1) {
                      monCostLocal = response.data.monCost || 0;
                      monOpensLocal = response.data.monOpens;
                    }

                    if (state.singleDay === 2) {
                      tueCostLocal = response.data.tueCost || 0;
                      tueOpensLocal = response.data.tueOpens;
                    }

                    if (state.singleDay === 3) {
                      wedCostLocal = response.data.wedCost || 0;
                      wedOpensLocal = response.data.wedOpens;
                    }

                    if (state.singleDay === 4) {
                      thuCostLocal = response.data.thuCost || 0;
                      thuOpensLocal = response.data.thuOpens;
                    }

                    if (state.singleDay === 5) {
                      friCostLocal = response.data.friCost || 0;
                      friOpensLocal = response.data.friOpens;
                    }

                    if (state.singleDay === 6) {
                      satCostLocal = response.data.satCost || 0;
                      satOpensLocal = response.data.satOpens;
                    }

                    if (state.singleDay2) {
                      // - - -
                      if (state.singleDay2 === 0) {
                        sunCostLocal = response.data.sunCost || 0;
                        sunOpensLocal = response.data.sunOpens;
                      }

                      if (state.singleDay2 === 1) {
                        monCostLocal = response.data.monCost || 0;
                        monOpensLocal = response.data.monOpens;
                      }

                      if (state.singleDay2 === 2) {
                        tueCostLocal = response.data.tueCost || 0;
                        tueOpensLocal = response.data.tueOpens;
                      }

                      if (state.singleDay2 === 3) {
                        wedCostLocal = response.data.wedCost || 0;
                        wedOpensLocal = response.data.wedOpens;
                      }

                      if (state.singleDay2 === 4) {
                        thuCostLocal = response.data.thuCost || 0;
                        thuOpensLocal = response.data.thuOpens;
                      }

                      if (state.singleDay2 === 5) {
                        friCostLocal = response.data.friCost || 0;
                        friOpensLocal = response.data.friOpens;
                      }

                      if (state.singleDay2 === 6) {
                        satCostLocal = response.data.satCost || 0;
                        satOpensLocal = response.data.satOpens;
                      }
                    }
                  }

                  let availDataLocal = response.data.availData;

                  if (state.singleDay) {
                    let newAvailData = [];
                    state.availData.forEach((obj) => {
                      if (obj.ds !== state.singleDay) {
                        newAvailData.push(obj);
                      }
                    });

                    response.data.availData.forEach((obj) => {
                      if (state.singleDay === obj.ds) {
                        newAvailData.push(obj);
                      }
                    });

                    availDataLocal = newAvailData;
                  }

                  if (
                    response.data.showTip &&
                    !memoVals.mob &&
                    !state.firstLoaded
                  ) {
                    setTimeout(() => {
                      setShowTip(true);
                      setTimeout(() => {
                        setShowTip(false);
                      }, 30000);
                    }, 3000);
                  }

                  memoVals.setUpdateSched((x) => false);
                  setDataLoaded(true);
                  memoVals.setAllowSwaps((x) => response.data.allowSwaps);
                  setState({
                    allowSwaps: response.data.allowSwaps,
                    adminSwapsEnabled: response.data.adminSwapsEnabled,
                    userIDsThatUserManages:
                      response.data.userIDsThatUserManages,
                    isAdmin: response.data.isAdmin,
                    firstLoaded: true,
                    specDays: response.data.specialPublicHolData,
                    hideSchedLimits: response.data.hideLimits,
                    availData: availDataLocal,
                    sched: schedLocal,
                    secondaryUserIDs: response.data.secondaryUserIDs,
                    daysArr: [
                      response.data.mon,
                      response.data.tue,
                      response.data.wed,
                      response.data.thu,
                      response.data.fri,
                      response.data.sat,
                      response.data.sun,
                    ],
                    monCost: monCostLocal,
                    monOpens: monOpensLocal,
                    tueCost: tueCostLocal,
                    tueOpens: tueOpensLocal,
                    wedCost: wedCostLocal,
                    wedOpens: wedOpensLocal,
                    thuCost: thuCostLocal,
                    thuOpens: thuOpensLocal,
                    friCost: friCostLocal,
                    friOpens: friOpensLocal,
                    satCost: satCostLocal,
                    satOpens: satOpensLocal,
                    sunCost: sunCostLocal,
                    sunOpens: sunOpensLocal,
                    closedDayNums: response.data.closedDayNums,
                    jobTitles: response.data.jobTitles,
                    loadingMove: false,
                    singleDay: "",
                    singleDay2: "",
                    loading: false,
                    // limLoad: "",
                    availLoading: false,
                    pasteLoading: false,
                    // dayLimitData: response2.data.output,
                    // jtLimitData: response2.data.jtSchedLimitData,
                    fadeShiftID: "",
                    myJobTitle: response.data.jobTitle,
                    // initialLimitsGot: true,
                    // loadingLimits: false,

                    monCommentsQty: response.data.monCommentsQty,
                    tueCommentsQty: response.data.tueCommentsQty,
                    wedCommentsQty: response.data.wedCommentsQty,
                    thuCommentsQty: response.data.thuCommentsQty,
                    friCommentsQty: response.data.friCommentsQty,
                    satCommentsQty: response.data.satCommentsQty,
                    sunCommentsQty: response.data.sunCommentsQty,
                    myTotMins: response.data.myTotMins,
                  });

                  memoVals.setCountMyChanges(
                    (x) => response.data.countMyChanges
                  );

                  // if (!hideSchedLimits) {
                  if (!memoVals.standalone) {
                    axios
                      .post(
                        `${serverURL}/get-team-schedule-limit-all-data`,
                        {
                          teamID: selectedTeam,
                          monWeek: selectedMonWeek,
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                          cancelToken: cancelSource2.token,
                        }
                      )
                      .then((response2) => {
                        if (response2.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response2.data.message === "success") {
                          setState({
                            limLoad: false,

                            dayLimitData: response2.data.output,
                            jtLimitData: response2.data.jtSchedLimitData,
                            initialLimitsGot: true,
                            loadingLimits: false,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }

                  memoVals.setLoadSingleUserSched((x) => "");
                }
              }
            })
            .catch((err) => {
              console.error(err);
            });
        };

        if (memoVals.standalone && !passedPasswordOK) {
          axios
            .post(
              `${serverURL}/view-rota`,
              {
                monWeek: selectedMonWeek,
                teamID: selectedTeam,
              },

              {
                withCredentials: true,
                credentials: "include",
                cancelToken: cancelSource1.token,
              }
            )
            .then((response) => {
              if (response.data.message === "link does not exist") {
                window.location.href = `${appURL}/inactive-rota-link`;
              } else {
                if (response.data.password) {
                  console.log("PASSWORD REQUIRED");
                  setShowPasswordInput(true);
                } else {
                  mainReq();
                }
              }
            });
        } else {
          mainReq();
        }

        return () => {
          cancelSource1.cancel("Component unmounted");
          cancelSource2.cancel("Component unmounted");
        };
      }
    }
  }, [
    memoVals.updateInfo,
    selectedTeam,
    selectedMonWeek,
    memoVals.standalone,
    memoVals.udpateMyRota,
    selectedDayViewDs,
    dayView,
    memoVals.mob,
    locationID,
  ]);

  useEffect(() => {
    // clean not needed
    if (!weekHasNoData) {
      let counter = 0;
      state.sched.forEach((user) => {
        user.monItems.forEach((item) => {
          counter++;
        });
        user.tueItems.forEach((item) => {
          counter++;
        });
        user.wedItems.forEach((item) => {
          counter++;
        });
        user.thuItems.forEach((item) => {
          counter++;
        });
        user.friItems.forEach((item) => {
          counter++;
        });
        user.satItems.forEach((item) => {
          counter++;
        });
        user.sunItems.forEach((item) => {
          counter++;
        });
      });

      if (counter === 0) {
        console.log("Data not loaded, re-running: ", new Date());
        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
      }
    }
  }, [state.sched]);

  useEffect(() => {
    // clean not needed

    if (memoVals.updateSched) {
      memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
    }
  }, [memoVals.showEditUser]);

  useEffect(() => {
    // clean not needed

    if (memoVals.addShiftTilPassBackToTeamSched) {
      let min = dateStringer.createTimestampFromString(selectedMonWeek);
      let maxObj = new Date(min);
      maxObj.setDate(maxObj.getDate() + 7);
      maxObj.setMinutes(maxObj.getMinutes() - 1);

      let max = maxObj.getTime();
      let dsToTs = dateStringer.createTimestampFromString(
        memoVals.addShiftTilPassBackToTeamSched
      );
      if (dsToTs >= min && dsToTs <= max) {
        memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
      }
      memoVals.setAddShiftTilPassBackToTeamSched((x) => "");
    }
  }, [memoVals.addShiftTilPassBackToTeamSched]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (state.initialLimitsGot) {
      axios
        .post(
          `${serverURL}/get-team-schedule-limit-all-data`,
          {
            teamID: selectedTeam,
            monWeek: selectedMonWeek,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response2) => {
          if (response2.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response2.data.message === "success") {
            setState({
              dayLimitData: response2.data.output,
              jtLimitData: response2.data.jtSchedLimitData,
              pasteLoading: false,
              fadeShiftID: "",
              limLoad: false,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.updateLimits]);

  let [sureDelete, setSureDelete] = useState("");

  let [locationMode, setLocationMode] = useState("");
  let [locations, setLocations] = useState([]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let isStandalone = false;
    if (params && params.view) {
      isStandalone = true;
    }

    if (params.locationID) {
      setLocationID(dateStringer.cleanParam(params.locationID));
      setSelectedTeam("");
    }

    if (isStandalone) {
      if (params.teamID) {
        setSelectedTeam(dateStringer.cleanParam(params.teamID));
      }

      setSelectedMonWeek(dateStringer.cleanParam(params.monWeek));
      setMonWeeks([dateStringer.cleanParam(params.monWeek)]);
    }

    if (!isStandalone) {
      const cancelSource1 = axios.CancelToken.source();
      const cancelSource2 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-teams-edit-user-is-part-of`,
          { userID: "jwt" },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setMgrTeams(response.data.allTeams);
            if (response.data.allTeams[0]) {
              if (params && params.teamID) {
                setSelectedTeam(dateStringer.cleanParam(params.teamID));
                window.history.pushState(
                  {},
                  document.title,
                  window.location.pathname
                );
              } else {
                setSelectedTeam(response.data.allTeams[0].teamID);
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });

      axios
        .post(
          `${serverURL}/get-week-starts-for-schedule`,
          { someData: 1 },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource2.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            let arr = [];
            let firstDateObj = new Date(
              dateStringer.createTimestampFromString(response.data.firstDs)
            );
            let lastDateObj = new Date(
              dateStringer.createTimestampFromString(response.data.lastDs)
            );
            let weeks = Math.round(
              (lastDateObj.getTime() - firstDateObj.getTime()) /
                1000 /
                60 /
                60 /
                24 /
                7
            );

            for (var i = 0; i < weeks; i++) {
              arr.push(
                dateStringer.createStringFromTimestamp(firstDateObj.getTime())
              );
              firstDateObj.setHours(0, 0, 0, 0);
              firstDateObj.setDate(firstDateObj.getDate() + 7);
            }

            let arr2 = [];
            let firstDateObj2 = new Date(
              dateStringer.createTimestampFromString(response.data.firstDs)
            );

            for (var i = 0; i < weeks * 7; i++) {
              arr2.push(
                dateStringer.createStringFromTimestamp(firstDateObj2.getTime())
              );
              firstDateObj2.setHours(0, 0, 0, 0);
              firstDateObj2.setDate(firstDateObj2.getDate() + 1);
            }

            setDayViewArr(arr2);

            setMonWeeks(arr);

            if (params.monWeek && params.teamID) {
              setSelectedMonWeek(
                dateStringer.getMonWeekOfDs(
                  dateStringer.cleanParam(params.monWeek)
                )
              );
            } else {
              setSelectedMonWeek(response.data.current);
            }

            setState({ availLoading: true, maxTs: response.data.maxTs });
            setCurrentMw(response.data.current);
            setCurrMonWeek(response.data.current);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
        cancelSource2.cancel("Component unmounted");
      };
    }
  }, []);

  let [typeIDsToHide, setTypeIDsToHide] = useState([]);

  let getAvail = (userID, ds, dayNumPassedIn) => {
    if (userID && ds && state.availData[0]) {
      let dateObj = dayNumPassedIn
        ? ""
        : new Date(dateStringer.createTimestampFromString(ds));

      let dayN = dayNumPassedIn ? dayNumPassedIn : dateObj.getDay();

      let userAvail = state.availData.filter((item) => {
        return item.userID === userID;
      });

      let userAvailObj = userAvail.filter((item) => {
        return item.ds === dayN;
      });
      // return "hello";
      // if(userAvailObj[])
      if (userAvailObj[0]) {
        if (userAvailObj[0].data.string2) {
          return `${userAvailObj[0].data.string1}\u00A0\u00A0\u00A0\u00A0+\u00A0\u00A0\u00A0\u00A0${userAvailObj[0].data.string2}`;
        } else {
          if (userAvailObj[0].data.string1) {
            return `${userAvailObj[0].data.string1}`;
          } else {
            return;
          }
        }
      }
      return userAvailObj[0] && userAvailObj[0].data;
    }
  };
  useEffect(() => {
    // clean not needed
    setAddID("");
  }, [selectedMonWeek]);

  let getTeamNameFromTeamID = (teamID) => {
    let filt = mgrTeams.filter((item) => item.teamID === teamID);
    return filt[0].teamName;
  };

  let generateTeamsSelectBar = mgrTeams.map((team, i) => {
    if (memoVals.mob) {
      return (
        <option key={team.teamID} value={team.teamID}>
          {team.teamID === selectedTeam
            ? shortenTeamNameForMobOnly(team.teamName)
            : team.teamName}
        </option>
      );
    } else {
      return (
        <Fragment>
          <div
            key={team.teamID}
            onClick={() => {
              if (selectedTeam !== team.teamID) {
                // if (!loading) {
                setSelectedTeam(team.teamID);
                setState({ loading: true });
                memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
              }
            }}
            className={`teamNameAbsence ${
              selectedTeam === team.teamID ? "myRotaTeamSelected" : ""
            } `}
          >
            {team.teamName}
          </div>
          {i !== mgrTeams.length - 1 ? (
            <div className="subHeadSplit inlineTeamSplit"></div>
          ) : (
            ""
          )}
        </Fragment>
      );
    }
  });

  let generateMonWeeks = dayView
    ? dayViewArr.map((ds) => {
        if (dateStringer.createTimestampFromString(ds) < state.maxTs) {
          let dateObj = new Date(dateStringer.createTimestampFromString(ds));
          let dayS = "";
          if (dateObj.getDay() === 0) {
            dayS = "Sun";
          }
          if (dateObj.getDay() === 1) {
            dayS = "Mon";
          }
          if (dateObj.getDay() === 2) {
            dayS = "Tue";
          }
          if (dateObj.getDay() === 3) {
            dayS = "Wed";
          }
          if (dateObj.getDay() === 4) {
            dayS = "Thu";
          }
          if (dateObj.getDay() === 5) {
            dayS = "Fri";
          }
          if (dateObj.getDay() === 6) {
            dayS = "Sat";
          }
          return (
            <option value={ds} key={ds}>
              {dayS} {dateStringer.dateAndMonth(ds)} '{ds.substr(3, 2)}
            </option>
          );
        }
      })
    : monWeeks.map((monWeek) => {
        let dateObj = new Date(dateStringer.createTimestampFromString(monWeek));
        dateObj.setDate(dateObj.getDate() + 6);
        dateObj.setHours(0, 0, 0, 0);
        // ds, withYear, full, suffix
        let string = `${dateStringer.printedDateFromDs(
          monWeek,
          false,
          false,
          false
        )} - ${dateStringer.printedDateFromDs(
          dateStringer.createStringFromTimestamp(dateObj.getTime()),
          true,
          false,
          false,
          true
        )}`;
        return (
          <option value={monWeek} key={monWeek}>
            {window.innerWidth > 600
              ? string
              : `Mon ${dateStringer.printedDateWithYrFromDWithoutDay(monWeek)}`}
          </option>
        );
      });

  // ------------------------------------------------------------------------

  let [collapse, setCollapse] = useState(false);

  useEffect(() => {
    // clean not needed
    let widthBrk = 820;

    function handleWindowSizeChange() {
      let widthBrk = 820;

      if (window.innerWidth < widthBrk) {
        memoVals.setMob((x) => true);
      } else {
        memoVals.setMob((x) => false);
      }
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [memoVals.calPage]);
  // ------------

  // ------------------------------------------------------------------------
  //  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const targetRef = useRef(null);
  const containerRef = useRef(null);
  let nullRef = useRef();

  const handleScroll = () => {
    let todayDay = new Date();
    if (todayDay.getDay() !== 1) {
      setTimeout(() => {
        if (
          containerRef.current &&
          targetRef.current &&
          currMonWeek === selectedMonWeek
        ) {
          const scrollTop = containerRef.current.scrollLeft;
          const targetOffset = targetRef.current.offsetLeft;
          const position = targetOffset - scrollTop;
          containerRef.current.scrollLeft = position - (memoVals.mob ? 100 : 0);
        } else {
          if (containerRef.current) {
            containerRef.current.scrollLeft = 0;
          }
        }
      }, 250);
    }
  };

  useEffect(() => {
    // clean not needed
    if (!loading) {
      handleScroll();
    }
  }, [loading]);

  // - - - - -

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  let nowDateObj = new Date();
  let isUserActive = (userID) => {
    let active = false;

    let userObjArrItem = state.sched.filter((item) => {
      return item.userID === userID;
    });

    let itemsArr = userObjArrItem[0].sunItems;
    if (nowDateObj.getDay() === 1) {
      itemsArr = userObjArrItem[0].monItems;
    }
    if (nowDateObj.getDay() === 2) {
      itemsArr = userObjArrItem[0].tueItems;
    }
    if (nowDateObj.getDay() === 3) {
      itemsArr = userObjArrItem[0].wedItems;
    }
    if (nowDateObj.getDay() === 4) {
      itemsArr = userObjArrItem[0].thuItems;
    }
    if (nowDateObj.getDay() === 5) {
      itemsArr = userObjArrItem[0].friItems;
    }
    if (nowDateObj.getDay() === 6) {
      itemsArr = userObjArrItem[0].satItems;
    }

    itemsArr.forEach((item) => {
      if (item.type !== "leave" && item.published) {
        let startTs = item.startTs;
        let endObj = new Date(dateStringer.createTimestampFromString(item.end));
        if (endObj.getTime() <= startTs) {
          endObj.setDate(endObj.getDate() + 1);
        }
        let endTs = endObj.getTime();
        if (nowDateObj.getTime() >= startTs && nowDateObj.getTime() < endTs) {
          active = true;
        }
      }
    });

    return active;
  };

  /// - - - - -

  let limitColour = (ds, jt) => {
    let isJt = false;
    if (ds && state.dayLimitData.mon) {
      let dsObj = new Date(dateStringer.createTimestampFromString(ds));
      let dayN = dsObj.getDay();
      if (dayN === 0) {
        if (
          state.dayLimitData.sun.enable &&
          state.dayLimitData.sun.limitByJobTitle
        ) {
          isJt = true;
        }
      }
      if (dayN === 1) {
        if (
          state.dayLimitData.mon.enable &&
          state.dayLimitData.mon.limitByJobTitle
        ) {
          isJt = true;
        }
      }
      if (dayN === 2) {
        if (
          state.dayLimitData.tue.enable &&
          state.dayLimitData.tue.limitByJobTitle
        ) {
          isJt = true;
        }
      }
      if (dayN === 3) {
        if (
          state.dayLimitData.wed.enable &&
          state.dayLimitData.wed.limitByJobTitle
        ) {
          isJt = true;
        }
      }
      if (dayN === 4) {
        if (
          state.dayLimitData.thu.enable &&
          state.dayLimitData.thu.limitByJobTitle
        ) {
          isJt = true;
        }
      }
      if (dayN === 5) {
        if (
          state.dayLimitData.fri.enable &&
          state.dayLimitData.fri.limitByJobTitle
        ) {
          isJt = true;
        }
      }
      if (dayN === 6) {
        if (
          state.dayLimitData.sat.enable &&
          state.dayLimitData.sat.limitByJobTitle
        ) {
          isJt = true;
        }
      }
    }

    if (isJt) {
      let filt = state.jtLimitData.filter(
        (item) => item.ds === ds && item.jt === jt
      );

      if (filt[0] && filt[0].active) {
        if (filt[0].currOff > filt[0].maxOff) {
          return true;
        }
      }
    }
  };

  let shortenJt = (jt) => {
    if (memoVals.mob) {
      if (jt && jt.length > 22) {
        return `${jt.substr(0, 20)}...`;
      } else {
        return jt;
      }
    } else {
      if (jt && jt.length > 28) {
        return `${jt.substr(0, 23)}...`;
      } else {
        return jt;
      }
    }
  };

  //grand
  let jtArr = memoVals.standalone ? [] : [state.myJobTitle || "No job title"];
  state.jobTitles.forEach((jt) => {
    if (!jtArr.includes(jt)) {
      jtArr.push(jt);
    }
  });
  let generateNames = jtArr.map((jt) => {
    let users = [];
    state.sched.forEach((usr) => {
      if (usr.jobTitle === jt) {
        users.push(usr);
      }
    });

    users.sort((a, b) => b.primaryOrder - a.primaryOrder);

    let primaryUsers = [];
    let secondaryUsers = [];

    users.forEach((u) => {
      if (!state.secondaryUserIDs.includes(u.userID)) {
        primaryUsers.push(u);
      } else {
        secondaryUsers.push(u);
      }
    });
    primaryUsers.sort(function (a, b) {
      return a.fName.localeCompare(b.fName);
    });

    secondaryUsers.sort(function (a, b) {
      return a.fName.localeCompare(b.fName);
    });

    let generateJtPrimaryUsers = primaryUsers.map((usr) => {
      return (
        <div
          key={usr.userID}
          className={`myRotaJtUserParent ${
            checkUserState.userID === usr.userID ? "myRotaMyJtUser" : ""
          }`}
        >
          <div
            onClick={() => {
              if (checkUserState.permissions === 1) {
                memoVals.setShowEditUser((x) => usr.userID);
              } else if (!managesThisTeam) {
                memoVals.setSeeUserID((x) => {
                  return { userID: usr.userID, openedFromUserRota: true };
                });
              } else {
                memoVals.setShowEditUser((x) => usr.userID);
              }
            }}
            className={`jtUser ${
              memoVals.standalone ? "jtUserStandalone" : ""
            } myRotaJtUserMarginTop5px ${memoVals.mob ? "font10" : ""}  ${
              state.secondaryUserIDs.includes(usr.userID)
                ? "secondaryUserToTheTeam"
                : ""
            } ${
              usr.userID === checkUserState.userID
                ? `meJwtUser ${memoVals.mob ? "meJwtUserMob" : ""}`
                : ""
            }`}
            key={usr.userID}
          >
            <div className={`${collapse ? "collapseNameDiv" : "none"}`}>
              {usr.fName[0]}
              {usr.lName[0]}
            </div>
            <div className={`${collapse ? "none" : "jtUserLeft"}`}>
              <div
                className={`${
                  collapse || currMonWeek !== selectedMonWeek || !memoVals.mob
                    ? "none"
                    : `${
                        isUserActive(usr.userID)
                          ? "userLightBarOn"
                          : "lightBarOffOff"
                      } ${usr.you ? "userLightBarYou" : ""}`
                }`}
              ></div>
            </div>
            {!memoVals.mob && !collapse ? (
              <img
                src={usr.profPicUrl}
                alt={usr.fName}
                className={`jtUserProfilePicImg myRotaProPic  ${
                  checkUserState.userID === usr.userID ? "x20910841" : ""
                }  ${
                  isUserActive(usr.userID) &&
                  !collapse &&
                  currMonWeek === selectedMonWeek
                    ? "desktopUserIsWorkingNowLight"
                    : usr.userID === checkUserState.userID
                    ? "x01931131"
                    : ""
                }`}
              />
            ) : (
              ""
            )}

            <div
              className={`${
                collapse
                  ? "none"
                  : `jtUserRight ${memoVals.standalone ? "x2211gg" : ""}  ${
                      usr.primaryTeamID !== selectedTeam
                        ? `padTop5 ${
                            usr.primaryTeamName &&
                            usr.primaryTeamName.length > 13 &&
                            checkUserState.userID !== usr.userID
                              ? "padTop3"
                              : ""
                          }`
                        : ""
                    }`
              } ${
                usr.fName.length > 13 || usr.lName.length > 13 ? "name-s" : ""
              }`}
            >
              {memoVals.standalone ? (
                <p className="standaloneJtUserName">{usr.fName}</p>
              ) : (
                usr.fName
              )}
              <br />
              {memoVals.standalone ? (
                <p className="standaloneJtUserName">{usr.lName}</p>
              ) : (
                usr.lName
              )}
              <br />
              <div
                className={`costAndHoursP ${
                  state.secondaryUserIDs.includes(usr.userID)
                    ? "costAndHoursPOnNonPrimary"
                    : ""
                }`}
              >
                {/* <p
                  className={`${
                    calcUserCost(usr.userID) ? "jtUserCostSpan" : "none"
                  }`}
                >
                  {currencySymbol}
                  {Math.round(calcUserCost(usr.userID))}
                </p> */}
                <p
                  className={`userHoursPMyRota ${
                    state.myTotMins > 0 &&
                    memoVals.mob &&
                    usr.userID === checkUserState.userID
                      ? "mobDurWithCost"
                      : ""
                  } ${!memoVals.mob ? "noPaddRight" : ""}  ${
                    usr.userID === checkUserState.userID
                      ? ""
                      : `x001 ${
                          usr.primaryTeamName && usr.primaryTeamName.length > 13
                            ? "x8899"
                            : ""
                        }`
                  }`}
                  // onClick={() => {
                  //   // console.log(usr.userCost);
                  // }}
                >
                  {/* {usr.totMins
                    ? dateStringer.formatMinsDurationToShortHours(usr.totMins)
                    : ""} */}
                  {usr.userID !== checkUserState.userID &&
                  usr.primaryTeamID !== selectedTeam ? (
                    <p className="myRotaPrimTeamTitle">Primary team:</p>
                  ) : (
                    ""
                  )}
                  {usr.userID === checkUserState.userID ? (
                    dayView ? (
                      ""
                    ) : (
                      dateStringer.formatMinsDurationToHours(state.myTotMins)
                    )
                  ) : usr.primaryTeamID !== selectedTeam ? (
                    <span className="pTeamNameUsr">{usr.primaryTeamName}</span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`${
              state.secondaryUserIDs.includes(usr.userID) && !collapse
                ? "secondaryUserDiv"
                : "none"
            }`}
          >
            Non-primary
          </div>
          {/* <div className="unitSplit"></div> */}
        </div>
      );
    });

    let generateJtSecondaryUsers = secondaryUsers.map((usr) => {
      return (
        <div
          key={usr.userID}
          className={`myRotaJtUserParent ${
            checkUserState.userID === usr.userID ? "myRotaMyJtUser" : ""
          }`}
        >
          <div
            className={`jtUser myRotaJtUserMarginTop5px ${
              memoVals.standalone ? "jtUserStandalone" : ""
            } ${
              state.secondaryUserIDs.includes(usr.userID)
                ? "secondaryUserToTheTeam"
                : ""
            } ${
              usr.userID === checkUserState.userID
                ? `meJwtUser ${memoVals.mob ? "meJwtUserMob" : ""}`
                : ""
            }`}
            key={usr.userID}
            onClick={() => {
              memoVals.setSeeUserID((x) => {
                return { userID: usr.userID, openedFromUserRota: true };
              });
            }}
          >
            <div className={`${collapse ? "collapseNameDiv" : "none"}`}>
              {usr.fName[0]}
              {usr.lName[0]}
            </div>
            <div className={`${collapse ? "none" : "jtUserLeft"}`}>
              {memoVals.mob ? (
                <div
                  className={`${
                    collapse || currentMw !== selectedMonWeek || memoVals.mob
                      ? "none"
                      : `${
                          isUserActive(usr.userID)
                            ? "userLightBarOn lightBarOnNonPrimary"
                            : "lightBarOffOff lightBarOnNonPrimary"
                        }`
                  }`}
                ></div>
              ) : (
                ""
              )}
            </div>
            {!memoVals.mob && !collapse ? (
              <img
                src={usr.profPicUrl}
                alt={usr.fName}
                className={`jtUserProfilePicImg myRotaProPic ${
                  checkUserState.userID === usr.userID ? "x20910841" : ""
                } secondaryProfPicImg ${
                  isUserActive(usr.userID) &&
                  !collapse &&
                  currMonWeek === selectedMonWeek
                    ? "desktopUserIsWorkingNowLight"
                    : usr.userID === checkUserState.userID
                    ? "x01931131"
                    : ""
                }`}
              />
            ) : (
              ""
            )}
            <div
              className={`${
                collapse
                  ? "none"
                  : `jtUserRight ${memoVals.standalone ? "x2211gg" : ""} ${
                      usr.primaryTeamID !== selectedTeam
                        ? `padTop5 ${
                            usr.primaryTeamName.length > 13 &&
                            checkUserState.userID !== usr.userID
                              ? "padTop3"
                              : ""
                          }`
                        : ""
                    } `
              }`}
            >
              {memoVals.standalone ? (
                <p className="standaloneJtUserName">{usr.fName}</p>
              ) : (
                usr.fName
              )}
              <br />
              {memoVals.standalone ? (
                <p className="standaloneJtUserName">{usr.lName}</p>
              ) : (
                usr.lName
              )}
              <br />
              <div
                className={`costAndHoursP ${
                  state.secondaryUserIDs.includes(usr.userID)
                    ? "costAndHoursPOnNonPrimary"
                    : ""
                }`}
              >
                {/* <p className={`${usr.userCost ? "jtUserCostSpan" : "none"}`}>
                  {currencySymbol}
                  {Math.round(usr.userCost)}
                </p> */}
                <p
                  className={`userHoursPMyRota ${
                    state.myTotMins > 0 &&
                    memoVals.mob &&
                    usr.userID === checkUserState.userID
                      ? "mobDurWithCost"
                      : ""
                  } ${
                    usr.userID === checkUserState.userID
                      ? ""
                      : `x001 ${usr.primaryTeamName.length > 13 ? "x8899" : ""}`
                  }`}
                >
                  {/* {usr.totMins
                    ? dateStringer.formatMinsDurationToShortHours(usr.totMins)
                    : ""} */}
                  {usr.userID !== checkUserState.userID &&
                  usr.primaryTeamID !== selectedTeam ? (
                    <p className="myRotaPrimTeamTitle">Primary team:</p>
                  ) : (
                    ""
                  )}
                  {usr.userID === checkUserState.userID ? (
                    dayView ? (
                      ""
                    ) : (
                      dateStringer.formatMinsDurationToHours(state.myTotMins)
                    )
                  ) : usr.primaryTeamID !== selectedTeam ? (
                    <span className="pTeamNameUsr">{usr.primaryTeamName}</span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className=""></div>
          <div
            className={`${
              state.secondaryUserIDs.includes(usr.userID) && !collapse
                ? "secondaryUserDiv"
                : "none"
            }`}
          >
            Non-primary
          </div>
          {/* <div className="unitSplit"></div> */}
        </div>
      );
    });
    return (
      <div className="jobTitleUsersContainer" key={jt}>
        <div
          className={`jobTitleTitle x99100111 colour0077ff ${
            collapse ? "collapsedJobTitle" : ""
          }  ${memoVals.mob ? "mobJobTitleTitle" : ""}`}
          onClick={() => {}}
        >
          {collapse ? "" : shortenJt(jt)}
          {jt[jt.length - 1] === "s" || (users.length && !collapse < 2)
            ? ""
            : "s"}
        </div>
        <div className="jtUsers">
          {generateJtPrimaryUsers}
          {generateJtSecondaryUsers}
        </div>
      </div>
    );
  });

  let [addID, setAddID] = useState("");

  let generateContent = state.daysArr.map((day, i) => {
    let ts = dateStringer.createTimestampFromString(day);
    if (ts <= state.maxTs || memoVals.standalone) {
      let jtArr = [state.myJobTitle];
      state.jobTitles.forEach((jt) => {
        if (!jtArr.includes(jt)) {
          jtArr.push(jt);
        }
      });

      let generateJobTitleUnits = jtArr.map((jt, ind) => {
        if (jt === "memoVals.standalone") {
          return "";
        }
        let jtUsers = [];
        let primaryUsersShifts = [];
        let secondaryUsersShifts = [];

        state.sched.forEach((usr) => {
          if (usr.jobTitle === jt) {
            jtUsers.push(usr);
            if (state.secondaryUserIDs.includes(usr.userID)) {
              secondaryUsersShifts.push(usr);
            } else {
              primaryUsersShifts.push(usr);
            }
          }
        });

        jtUsers.sort(function (a, b) {
          return a.fName.localeCompare(b.fName);
        });

        primaryUsersShifts.sort(function (a, b) {
          return a.fName.localeCompare(b.fName);
        });

        secondaryUsersShifts.sort(function (a, b) {
          return a.fName.localeCompare(b.fName);
        });
        // jtUsers.sort((a, b) => b.primaryOrder - a.primaryOrder);

        let todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        let yesterdayDate = new Date(todayDate.getTime());
        yesterdayDate.setDate(yesterdayDate.getDate() - 1);
        yesterdayDate.setHours(0, 0, 0, 0);
        let tomorrowDate = new Date(todayDate.getTime());
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        tomorrowDate.setHours(0, 0, 0, 0);
        let generateDayString = (ds) => {
          if (
            ds === dateStringer.createStringFromTimestamp(todayDate.getTime())
          ) {
            return "Today";
          }
          if (
            ds ===
            dateStringer.createStringFromTimestamp(yesterdayDate.getTime())
          ) {
            return "Yesterday";
          }
          if (
            ds ===
            dateStringer.createStringFromTimestamp(tomorrowDate.getTime())
          ) {
            return "Tomorrow";
          }
          return `${dateStringer.printedDateFromDs(ds)}`;
        };

        let generateShifts = (shiftsArr) => {
          return shiftsArr.map((user) => {
            let shifts = [];

            state.sched.forEach((schedUser) => {
              if (i === 0) {
                shifts = user.monItems;
              }
              if (i === 1) {
                shifts = user.tueItems;
              }
              if (i === 2) {
                shifts = user.wedItems;
              }
              if (i === 3) {
                shifts = user.thuItems;
              }
              if (i === 4) {
                shifts = user.friItems;
              }
              if (i === 5) {
                shifts = user.satItems;
              }
              if (i === 6) {
                shifts = user.sunItems;
              }
            });

            shifts.sort((a, b) => a.startTs - b.startTs);

            let shortenTag = (txt) => {
              if (txt.length > 17) {
                return `${txt.substr(0, 14)}...`;
              } else {
                return txt;
              }
            };

            let generateShifts =
              !state.pasteLoading &&
              shifts.map((sh, shIndex) => {
                let generateTags =
                  !sh.ghost && sh.tags
                    ? sh.tags.map((tag, i) => {
                        if (i >= 1) {
                          if (i === sh.tags.length - 1) {
                            return (
                              <p
                                className={`shTagInline appendingShTagInline myRotaTag tagGreyColours ${
                                  user.userID === checkUserState.userID
                                    ? "shTagInlineMe"
                                    : ""
                                }`}
                                key={tag}
                              >
                                +{sh.tags.length - 1}
                              </p>
                            );
                          }
                        } else {
                          return (
                            <p
                              className={`shTagInline myRotaTag tagGreyColours ${
                                hoverUserID === user.userID
                                  ? "hoveredRowTags"
                                  : ""
                              }  ${
                                user.userID === checkUserState.userID
                                  ? "shTagInlineMe"
                                  : ""
                              }`}
                              key={tag}
                            >
                              {shortenTag(tag)}
                            </p>
                          );
                        }
                      })
                    : "";

                if (
                  !sh.initial &&
                  !typeIDsToHide.includes(sh.typeID)
                  //  &&
                  // !sh.otherTeam
                ) {
                  let tilStrip = sh.tilApproved
                    ? dateStringer.generateTilShiftString(
                        sh.tilStart,
                        sh.tilEnd,
                        `${sh.start.substr(12, 2)}:${sh.start.substr(15, 2)}`,
                        `${sh.end.substr(12, 2)}:${sh.end.substr(15, 2)}`,
                        false,
                        "",
                        sh.shiftID ? "shift" : "overtime"
                      )
                    : "";

                  return (
                    <div key={sh.typeID}>
                      <div
                        // onMouseDown={handleMouseDown}
                        onDragStart={handleDragStart}
                        draggable="true" // Enable draggable attribute
                        className={`jtUserShiftsBox ${
                          tilStrip.includes("Whole") ? "none" : ""
                        } ${
                          sh.location && !memoVals.mob
                            ? "myRotaShiftBoxDesktopLoc"
                            : ""
                        } ${
                          sh.type === "leave"
                            ? `leaveShiftBox leaveShiftBoxMyRota ${
                                user.userID === checkUserState.userID
                                  ? "leaveShiftBoxMyRotaHov"
                                  : ""
                              } ${
                                sh.isAbsence
                                  ? `leaveShiftBoxMyRotaAbs ${
                                      user.userID === checkUserState.userID
                                        ? "leaveShiftBoxMyRotaAbsHov"
                                        : ""
                                    } ${
                                      user.userID === checkUserState.userID
                                        ? "leaveShiftBoxMyRotaAbsMyAbs"
                                        : ""
                                    }`
                                  : ""
                              } ${
                                memoVals.standalone
                                  ? "leaveShiftBoxMyRotaStandalone"
                                  : ""
                              } ${
                                sh.type === "leave" && sh.firstHalfOff
                                  ? "firstHalfOffBg_"
                                  : ""
                              } ${
                                sh.type === "leave" && sh.lastHalfOff
                                  ? "lastHalfOffBg_"
                                  : ""
                              } ${locationID ? "none" : ""}`
                            : `myRotaShiftBox ${
                                !memoVals.mob ? "myRotaShiftBoxDesktop" : ""
                              } ${
                                memoVals.standalone
                                  ? "myRotaShiftBoxStandalone"
                                  : ""
                              }`
                        }  ${
                          user.userID === checkUserState.userID && !sh.isAbsence
                            ? `myJtUserShiftBox ${
                                nowDateObj.getTime() >= sh.startTs &&
                                nowDateObj.getTime() <
                                  dateStringer.createTimestampFromString(
                                    sh.end
                                  ) &&
                                (sh.type === "shift" ||
                                  sh.type === "til" ||
                                  sh.type === "overtime")
                                  ? `myJtUserShiftBoxActive ${
                                      sh.location && memoVals.mob
                                        ? "myRotaLocStripActiveMob"
                                        : ""
                                    } ${
                                      sh.typeID === clockedShiftID
                                        ? "myJtUserShiftBoxActiveClocked"
                                        : ""
                                    }`
                                  : ""
                              } ${
                                sh.type !== "leave"
                                  ? "myJtUserShiftBoxLeave"
                                  : ""
                              }`
                            : ""
                        } ${
                          sh.tags && sh.tags[0] ? "myShiftBoxWithTags" : ""
                        } ${sh.ghost ? "ghostShiftUnitTeamSched" : ""}
                      
                      ${
                        sh.isAbsence &&
                        sh.type === "leave" &&
                        !sh.lastHalfOff &&
                        !sh.firstHalfOff
                          ? `${
                              state.hoveredShift.typeID &&
                              state.hoveredShift.typeID.includes(sh.typeID) &&
                              checkUserState.userID === user.userID
                                ? "absenceLeaveShiftBoxHovered "
                                : `absenceLeaveShiftBox ${
                                    checkUserState.userID === user.userID
                                      ? "myAbsRotaBorder"
                                      : ""
                                  }`
                            }`
                          : ""
                      }

                     


       

                      ${
                        sh.type === "leave" && sh.lastHalfOff && sh.isAbsence
                          ? `${
                              state.hoveredShift.typeID &&
                              state.hoveredShift.typeID.includes(sh.typeID)
                                ? "absenceLastHalfOffBgHovered_"
                                : "absenceLastHalfOffBg_"
                            }`
                          : ""
                      }  

                      ${
                        sh.type === "leave" && sh.firstHalfOff && sh.isAbsence
                          ? `${
                              state.hoveredShift.typeID &&
                              state.hoveredShift.typeID.includes(sh.typeID)
                                ? "absenceFirstHalfOffBgHovered_"
                                : "absenceFirstHalfOffBg_"
                            }`
                          : ""
                      } 
                      ${shIndex !== 0 ? "myShiftItemMarginRight" : ""} ${
                          checkUserState.userID !== user.userID &&
                          sh.type === "leave"
                            ? "cursorDefault"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            sh.type === "shift" &&
                            checkUserState.userID === user.userID
                          ) {
                            memoVals.setShowMyShiftModal((x) => {
                              return {
                                unitType: "shift",
                                unitID: sh.typeID,
                              };
                            });
                          }

                          if (
                            sh.type === "til" ||
                            (sh.type === "overtime" &&
                              checkUserState.userID === user.userID)
                          ) {
                            memoVals.setShowMyShiftModal((x) => {
                              return {
                                unitType: sh.type,
                                unitID: sh.typeID,
                              };
                            });
                          }

                          if (
                            sh.type === "leave" &&
                            checkUserState.userID === user.userID
                          ) {
                            console.log("open my request modal");

                            memoVals.setShowUserReqFromNav((x) => sh.reqID);
                          }

                          if (
                            (sh.type === "shift" ||
                              sh.type === "til" ||
                              sh.type === "overtime") &&
                            checkUserState.userID !== user.userID
                          ) {
                            memoVals.setShowMyShiftModal((x) => {
                              return {
                                unitType: sh.type,
                                unitID: sh.typeID,
                                otherUser: true,
                              };
                            });
                          }
                        }}
                      >
                        <div className={`shiftBoxLeft`}>
                          {sh.userHasSwapped ? (
                            <img
                              src={swapInline}
                              alt="Swap request"
                              className="swapInline"
                            />
                          ) : (
                            ""
                          )}
                          {/* obrien */}
                          {sh.requireAccept && !sh.accepted && !sh.ghost ? (
                            <img
                              src={lightBlueQuestionMark}
                              alt="Double tick"
                              className="myRotaDoubleTickImg rotaQmark"
                            />
                          ) : sh.requireAccept && sh.accepted && !sh.ghost ? (
                            <img
                              src={doubleTick}
                              alt="Double tick"
                              className="myRotaDoubleTickImg"
                            />
                          ) : new Date().getTime() <
                              dateStringer.createTimestampFromString(
                                sh.start
                              ) && sh.type === "leave" ? (
                            <img
                              src={sh.isAbsence ? streamCross : rotaPlane}
                              alt="Double tick"
                              className="myRotaWhiteTick"
                            />
                          ) : (
                            // <div className="pendingShiftStartBlob"></div>
                            <img
                              src={
                                sh.type === "leave" && sh.isAbsence
                                  ? streamCross
                                  : sh.type === "leave" && !sh.isAbsence
                                  ? rotaPlane
                                  : !sh.published && sh.type !== "leave"
                                  ? hidden
                                  : !sh.absent || sh.type === "leave"
                                  ? unitTick
                                  : `${
                                      sh.partialAbsent
                                        ? partialAbsentTick
                                        : unitCross
                                    }`
                              }
                              alt="Status"
                              className={`shiftUnitPublicStatusIconImg ${
                                sh.type === "leave" && !sh.isAbsence
                                  ? "shiftUnitPublicStatusIconImgAnnLeave"
                                  : ""
                              } ${
                                !sh.published && sh.type !== "leave"
                                  ? "unpublishedSizing"
                                  : ""
                              } ${
                                sh.type === "leave" && sh.isAbsence
                                  ? "x23943943"
                                  : ""
                              }`}
                            />
                          )}

                          {sh.publicNote &&
                          sh.publicNote[0] &&
                          !memoVals.standalone ? (
                            <img
                              src={comment}
                              alt="Note"
                              className="shiftInlineCommentImg"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="shiftBoxMiddle">
                          <div
                            className={`${
                              sh.type === "leave"
                                ? `${
                                    (sh.type !== "leave" &&
                                      state.hoveredShift.typeID ===
                                        sh.typeID) ||
                                    state.hoveredShift.typeID ===
                                      `${sh.typeID}${day}`
                                      ? `leaveTypeNameRowHovered ${
                                          sh.leaveTypeName &&
                                          sh.leaveTypeName.length > 16
                                            ? "hoveredLTnameLong"
                                            : ""
                                        }`
                                      : "leaveTypeNameRow leaveTypeNameRowX993"
                                  }`
                                : "none"
                            }
                         ${
                           sh.leaveTypeName && sh.leaveTypeName.length > 16
                             ? "longLeaveTypeName"
                             : ""
                         } ${sh.isAbsence ? "absNameUserRota" : ""}`}
                          >
                            {sh.leaveTypeName}
                          </div>
                          <div
                            className={`shiftBoxMiddleRow1 ${
                              sh.tags &&
                              sh.tags[0] &&
                              user.userID === checkUserState.userID
                                ? "x19849171"
                                : ""
                            }`}
                          >
                            <div
                              className={`shiftBoxTimeStripMyRota ${
                                dateStringer.dsToTimeStrip(sh.start, true)
                                  .length > 4 ||
                                dateStringer.dsToTimeStrip(sh.end, true)
                                  .length > 4
                                  ? "makeTimesSmaller"
                                  : ""
                              } ${
                                (sh.type !== "leave" &&
                                  state.hoveredShift.typeID === sh.typeID) ||
                                state.hoveredShift.typeID ===
                                  `${sh.typeID}${day}`
                                  ? `${
                                      sh.type === "leave"
                                        ? "pullUpTimeStripHoveredLeave"
                                        : "pullUpTimeStrip"
                                    }`
                                  : ""
                              } ${
                                sh.type === "leave"
                                  ? `${
                                      (sh.type !== "leave" &&
                                        state.hoveredShift.typeID ===
                                          sh.typeID) ||
                                      state.hoveredShift.typeID ===
                                        `${sh.typeID}${day}`
                                        ? "leaveTimeStripHovered"
                                        : "leaveTimeStrip"
                                    }`
                                  : ""
                              } ${sh.initial ? "doNotShowTimeStrip" : ""} ${
                                sh.absent ? "colourRed" : ""
                              }`}
                            >
                              <span
                                className={`${
                                  sh.ghost ? "paidTimeOffStrip" : "none"
                                }`}
                              >
                                Paid day off
                              </span>
                              <p
                                className={`${
                                  sh.ghost
                                    ? "none"
                                    : `${
                                        sh.type === "leave"
                                          ? `noLeaveReqP ${
                                              sh.isAbsence
                                                ? "absNameUserRota"
                                                : ""
                                            }`
                                          : ""
                                      }`
                                } ${
                                  tilStrip && tilStrip.length > 18
                                    ? `tilStripWidther ${
                                        tilStrip.includes("Whole")
                                          ? "tilStripWidtherWhole"
                                          : ""
                                      }`
                                    : ""
                                }`}
                              >
                                {tilStrip
                                  ? tilStrip
                                  : `${
                                      (sh.type === "leave" &&
                                        sh.startEndTimesKnown) ||
                                      sh.type !== "leave"
                                        ? `${dateStringer.dsToTimeStrip(
                                            sh.start,
                                            true
                                          )} - ${dateStringer.dsToTimeStrip(
                                            sh.end,
                                            true
                                          )}`
                                        : sh.durMins > 0
                                        ? dateStringer.formatMinsDurationToHours(
                                            sh.durMins
                                          )
                                        : "No leave required"
                                    }`}{" "}
                              </p>
                              {checkUserState.userID === user.userID ? (
                                <span
                                  className={`${
                                    sh.cost &&
                                    sh.type === "leave" &&
                                    !sh.startEndTimesKnown
                                      ? "shCostSpan marginLeft0"
                                      : "none"
                                  } ${
                                    sh.type === "leave" ? "colourWhite" : ""
                                  } ${
                                    sh.type === "leave" && sh.unpaid
                                      ? "none"
                                      : ""
                                  } fontSize10 ${
                                    sh.type === "leave" ? "colourWhite" : ""
                                  }`}
                                >
                                  {/* {currencySymbol} */}
                                  {sh.break}
                                  {/* {sh.cost < 1
                                  ? sh.cost.toFixed(2)
                                  : Math.ceil(sh.cost)} */}
                                  {/* {Math.round((sh.cost + Number.EPSILON) * 100) /
                                  100} */}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div
                            className={`shiftBoxMiddleRow2 ${
                              (sh.type !== "leave" &&
                                state.hoveredShift.typeID === sh.typeID) ||
                              state.hoveredShift.typeID === `${sh.typeID}${day}`
                                ? "none"
                                : ""
                            } ${
                              state.fadeShiftID === sh.typeID
                                ? "noExistJtActions"
                                : ""
                            } ${sh.type === "leave" ? "opacFull" : ""}`}
                          >
                            <p
                              className={`${
                                !sh.startEndTimesKnown &&
                                sh.type === "leave" &&
                                sh.firstHalfOff &&
                                sh.durMins > 0
                                  ? !sh.isAbsence
                                    ? "leaveFirstLastHalfTxt colorB1ABE7"
                                    : "leaveFirstLastHalfTxt color607186"
                                  : "none"
                              }`}
                            >
                              Last half off
                            </p>
                            <p
                              className={`${
                                !sh.startEndTimesKnown &&
                                sh.type === "leave" &&
                                sh.lastHalfOff &&
                                sh.durMins > 0
                                  ? !sh.isAbsence
                                    ? "leaveFirstLastHalfTxt colorB1ABE7"
                                    : "leaveFirstLastHalfTxt color607186"
                                  : "none"
                              }`}
                            >
                              First half off
                            </p>
                            <p
                              className={`${
                                !sh.startEndTimesKnown &&
                                sh.type === "leave" &&
                                !sh.lastHalfOff &&
                                !sh.firstHalfOff &&
                                sh.durMins > 0
                                  ? !sh.isAbsence
                                    ? "leaveFirstLastHalfTxt colorB1ABE7"
                                    : "leaveFirstLastHalfTxt color607186"
                                  : "none"
                              }`}
                            >
                              Full day off
                            </p>

                            <p
                              className={`shiftBoxDurMins marginLeft0 ${
                                sh.type !== "shift" ? "makeTxt9374d6" : ""
                              } ${
                                sh.tags &&
                                sh.tags[0] &&
                                user.userID === checkUserState.userID
                                  ? "x184928741"
                                  : ""
                              } ${
                                sh.type === "leave"
                                  ? `${
                                      sh.startEndTimesKnown
                                        ? "leaveDurMins"
                                        : "none"
                                    }`
                                  : ""
                              } ${
                                dateStringer.formatMinsDurationToHours(
                                  sh.durMins
                                ).length > 7
                                  ? "exSmallDurStrip"
                                  : ""
                              } ${sh.ghost ? "colourWhite" : ""} fontSize10 ${
                                sh.type === "leave" && !sh.isAbsence
                                  ? "fontSize10jj"
                                  : ""
                              }  ${
                                sh.isAbsence || sh.type === "leave"
                                  ? "colorWhite_"
                                  : ""
                              }
                              `}
                            >
                              {" "}
                              <img
                                src={
                                  sh.type === "leave" && !sh.isAbsence
                                    ? rotaClockLeave
                                    : sh.type === "leave" && sh.isAbsence
                                    ? rotaClockAbs
                                    : sh.type === "leave" || sh.ghost
                                    ? rotaClockWhite
                                    : rotaClock
                                }
                                alt="Break"
                                className={`rotaClock ${
                                  sh.type === "leave"
                                    ? "rotaClockLeaveOnly"
                                    : ""
                                }`}
                              />
                              {dateStringer.formatMinsDurationToHours(
                                sh.durMins
                              )}{" "}
                              {/* {checkUserState.userID === user.userID ? ( */}
                              <span
                                className={`${
                                  // sh.cost &&
                                  sh.type !== "til"
                                    ? "shCostSpan marginLeft0"
                                    : "none"
                                } ${
                                  sh.type === "leave" ? "colourWhite" : ""
                                }  ${
                                  sh.salaried
                                    ? `salariedCostSpan ${
                                        sh.type === "leave"
                                          ? "salariedCostSpanLeave"
                                          : ""
                                      }`
                                    : ""
                                } ${sh.ghost ? "colourWhite" : ""} ${
                                  sh.type === "leave" && sh.unpaid ? "none" : ""
                                } fontSize10 ${
                                  sh.ghost || sh.type === "leave" ? "none" : ""
                                }`}
                              >
                                <img
                                  src={rotaCoffee}
                                  alt="Break"
                                  className={`rotaCoffee ${
                                    user.userID === checkUserState.userID
                                      ? "myRotaCoffee"
                                      : ""
                                  }`}
                                />
                                {sh.break}m
                                {/* {sh.salaried ? "Salaried" : currencySymbol}
                                {
                                  sh.salaried
                                    ? ""
                                    : sh.cost < 1
                                    ? sh.cost.toFixed(2)
                                    : Math.ceil(sh.cost)

                                  // Math.round(
                                  //     (sh.cost + Number.EPSILON) * 100
                                  //   ) / 100
                                } */}
                              </span>
                              {/* // ) : ( // "" // )} */}
                            </p>
                            {/* <p
                          className={`shiftBoxDurMins ${
                            (sh.type !== "leave" && sureDelete === sh.typeID) ||
                            (sh.type === "leave" &&
                              sureDelete === `${sh.typeID}${day}`)
                              ? "leaveDurMins deleteSureTxt"
                              : "none"
                          }`}
                        >
                          DELETE?
                        </p> */}
                          </div>
                        </div>
                        <div
                          className={`${
                            (sh.type !== "leave" &&
                              state.hoveredShift.typeID === sh.typeID) ||
                            state.hoveredShift.typeID === `${sh.typeID}${day}`
                              ? "shiftBoxRight"
                              : "shiftBoxRight"
                          }`}
                        >
                          {/* <p
                          className={`${
                            sh.ghost ? "shiftBoxTypeTxtTimeOff" : "none"
                          } ${sh.published ? "publishedShiftLight" : ""}`}
                        >
                          DAY OFF
                        </p> */}
                          <p
                            className={`${
                              sh.type === "leave" && !sh.ghost
                                ? "shiftBoxTypeTxtLeave"
                                : "none"
                            } ${sh.published ? "publishedShiftLight" : ""} ${
                              sh.isAbsence ? "absenceLight" : ""
                            }`}
                          >
                            {sh.isAbsence ? "ABSENCE" : "LEAVE"}
                          </p>
                          <p
                            className={`${
                              sh.type === "shift" && !sh.ghost
                                ? "shiftBoxTypeTxt"
                                : "none"
                            } ${
                              sh.published ? "publishedShiftLight none_" : ""
                            }`}
                          >
                            SHIFT
                          </p>

                          <p
                            className={`${
                              sh.type === "overtime" && !sh.ghost
                                ? "shiftBoxTypeTxtOvertime"
                                : "none"
                            } ${
                              sh.published ? "publishedShiftLight none_" : ""
                            }`}
                          >
                            OVERTIME
                          </p>
                          <p
                            className={`${
                              sh.type === "til" && !sh.ghost
                                ? "shiftBoxTypeTxtTil"
                                : "none"
                            } ${
                              sh.published ? "publishedShiftLight none_" : ""
                            }`}
                          >
                            LIEU
                          </p>
                        </div>
                      </div>

                      {/* {!sh.ghost && ( */}
                      <div
                        className={`shiftBoxLower ${
                          tilStrip.includes("Whole") ? "none" : ""
                        } ${
                          user.userID === checkUserState.userID &&
                          sh.tags &&
                          sh.tags[0]
                            ? "x197467268841"
                            : ""
                        }`}
                      >
                        {sh.location ? (
                          <div
                            className={`myRotaLocStrip ${
                              memoVals.mob ? "myRotaLocStripMob" : ""
                            } ${
                              user.userID === checkUserState.userID &&
                              !sh.isAbsence &&
                              nowDateObj.getTime() >= sh.startTs &&
                              nowDateObj.getTime() <
                                dateStringer.createTimestampFromString(
                                  sh.end
                                ) &&
                              (sh.type === "shift" ||
                                sh.type === "til" ||
                                sh.type === "overtime")
                                ? `myRotaLocStripActive ${
                                    memoVals.mob ? "x28838348422" : ""
                                  }`
                                : ""
                            }`}
                          >
                            <img
                              src={pin}
                              alt="Location"
                              className="myRotaPinImg"
                            />
                            {dateStringer.shorten(sh.location, 18)}
                          </div>
                        ) : (
                          generateTags
                        )}
                      </div>
                      {/* )} */}
                    </div>
                  );
                } else {
                  return "";
                }
              });

            return (
              <div
                key={user.userID}
                className={`hh88 ${
                  checkUserState.userID === user.userID ? "hh99" : ""
                }`}
              >
                <div
                  className={`userShiftsCollectionMyRota ${
                    checkUserState.userID === user.userID ? "myRotaMyRow" : ""
                  } myRotaJtUserParent_`}
                >
                  {generateShifts[0] ? generateShifts : ""}
                  {checkUserState.userID === user.userID &&
                  ts >= todayTs &&
                  !standalone ? (
                    generateShifts[0] ? (
                      <p
                        className="addLeaveMyRota"
                        onClick={() => {
                          memoVals.setShowReqModal(day);
                        }}
                      >
                        +
                      </p>
                    ) : (
                      <p
                        className="addLeaveMyRotaLarge"
                        onClick={() => {
                          memoVals.setShowReqModal(day);
                        }}
                      >
                        +
                      </p>
                    )
                  ) : (
                    ""
                  )}
                </div>{" "}
                <div className="unitSplit"></div>
              </div>
            );
          });
        };
        let generatePrimaryUserShifts = generateShifts(primaryUsersShifts);
        let generateSecondaryUserShifts = generateShifts(secondaryUsersShifts);

        let isDayClosed = (ds) => {
          let dateObj = new Date(dateStringer.createTimestampFromString(ds));
          if (state.closedDayNums.includes(dateObj.getDay())) {
            return true;
          } else {
          }
        };

        let generateSchedLimit = (jt, day) => {
          let active = false;
          let currOff = 0;
          let maxOff = 0;
          state.jtLimitData &&
            state.jtLimitData.forEach((lim) => {
              if (lim.ds === day && lim.jt === jt && lim.active) {
                active = true;
                currOff = lim.currOff;
                maxOff = lim.maxOff;
              }
            });

          let showDayLimit = (ds) => {
            let dateObj = new Date(dateStringer.createTimestampFromString(ds));
            if (dateObj.getDay() === 1) {
              // return true;
              if (
                state.dayLimitData.mon &&
                state.dayLimitData.mon.enable &&
                state.dayLimitData.mon.limitByJobTitle
              ) {
                return true;
              }
            } else if (dateObj.getDay() === 2) {
              if (
                state.dayLimitData.tue &&
                state.dayLimitData.tue.enable &&
                state.dayLimitData.tue.limitByJobTitle
              ) {
                return true;
              }
            } else if (dateObj.getDay() === 3) {
              if (
                state.dayLimitData.wed &&
                state.dayLimitData.wed.enable &&
                state.dayLimitData.wed.limitByJobTitle
              ) {
                return true;
              }
            } else if (dateObj.getDay() === 4) {
              if (
                state.dayLimitData.thu &&
                state.dayLimitData.thu.enable &&
                state.dayLimitData.thu.limitByJobTitle
              ) {
                return true;
              }
            } else if (dateObj.getDay() === 5) {
              if (
                state.dayLimitData.fri &&
                state.dayLimitData.fri.enable &&
                state.dayLimitData.fri.limitByJobTitle
              ) {
                return true;
              }
            } else if (dateObj.getDay() === 6) {
              if (
                state.dayLimitData.sat &&
                state.dayLimitData.sat.enable &&
                state.dayLimitData.sat.limitByJobTitle
              ) {
                return true;
              }
            } else if (dateObj.getDay() === 0) {
              if (
                state.dayLimitData.sun &&
                state.dayLimitData.sun.enable &&
                state.dayLimitData.sun.limitByJobTitle
              ) {
                return true;
              }
            } else {
              return false;
            }
          };
          return (
            <div
              className={`jtUserSchedLimitDiv ${
                showDayLimit(day) && !state.loadingLimits ? "" : "none"
              } ${
                state.hideSchedLimits || state.loading ? "none" : ""
              } noBorder`}
            >
              <p className={`${active ? "none" : "limitTitle"}`}>No limit</p>
              <div className={`${active ? "schedLimitDataTxtCont" : "none"}`}>
                {/* <p className="totAllowedOff">Limit</p> */}
                <p
                  className={`userTotAllOffActual ${
                    currOff > maxOff ? "limitOKMaxOff" : ""
                  }`}
                >
                  <span
                    className={`${
                      currOff > maxOff ? "limitExceededCurrOff" : ""
                    }`}
                  >
                    {currOff}
                  </span>{" "}
                  <span className="limitOffSpan">off</span> / {maxOff}
                </p>
              </div>{" "}
            </div>
          );
        };
        return (
          <div
            className={`jobTitleContentUnit ${
              jt === "STANDALONE" ? "none" : ""
            }`}
            key={ind}
            onClick={() => {
              console.log({ jt });
            }}
          >
            <div
              className={`jobTitleBlanker ${
                limitColour(day, jt) ? "l imitColourBg" : ""
              } paddingRight10px`}
            >
              <p
                className={`blankDayTxt fullOpac ${
                  ind === 0 ? "hideFirst DayString" : ""
                } ${state.loading ? "none" : ""} ${
                  limitColour(day, jt) ? "limitColourDayTxtWhite" : ""
                }`}
                ref={generateDayString(day) === "Today" ? targetRef : nullRef}
              >
                {generateDayString(day)}
              </p>

              {(!state.pasteLoading && !state.hideSchedLimits) ||
              !state.loadingLimits
                ? generateSchedLimit(jt, day)
                : ""}
            </div>
            {generatePrimaryUserShifts}
            {generateSecondaryUserShifts}
            {/* {generateJobTitleUsersPrimary}
          {generateJobTitleUsersSecondary} */}

            <div
              className={`${isDayClosed(day) ? "fadeAddShBox" : ""} ${
                addID === `${jt}${day}`
                  ? "addShiftBox addShiftBoxNoBottomBorder"
                  : "addShiftBox"
              } none`}
              onClick={() => {
                setState({ hoveredShift: {} });
                if (addID === `${jt}${day}`) {
                  setAddID("");
                } else setAddID(`${jt}${day}`);
              }}
            >
              +
            </div>
          </div>
        );
      });

      let generateDayCost = (d) => {
        let dateObj = new Date(dateStringer.createTimestampFromString(d));
        let dayN = dateObj.getDay();
        let cost = 0;
        if (dayN === 1) {
          state.sched.forEach((usr) => {
            usr.monItems.forEach((item) => {
              cost += item.cost;
            });
          });
        }
        if (dayN === 2) {
          state.sched.forEach((usr) => {
            usr.tueItems.forEach((item) => {
              cost += item.cost;
            });
          });
        }
        if (dayN === 3) {
          state.sched.forEach((usr) => {
            usr.wedItems.forEach((item) => {
              cost += item.cost;
            });
          });
        }
        if (dayN === 4) {
          state.sched.forEach((usr) => {
            usr.thuItems.forEach((item) => {
              cost += item.cost;
            });
          });
        }
        if (dayN === 5) {
          state.sched.forEach((usr) => {
            usr.friItems.forEach((item) => {
              cost += item.cost;
            });
          });
        }
        if (dayN === 6) {
          state.sched.forEach((usr) => {
            usr.satItems.forEach((item) => {
              cost += item.cost;
            });
          });
        }
        if (dayN === 0) {
          state.sched.forEach((usr) => {
            usr.sunItems.forEach((item) => {
              cost += item.cost;
            });
          });
        }

        return Math.ceil(cost);
      };

      let generateDayMins = (d) => {
        let dateObj = new Date(dateStringer.createTimestampFromString(d));
        let dayN = dateObj.getDay();
        let totMins = 0;
        if (dayN === 1) {
          state.sched.forEach((usr) => {
            usr.monItems.forEach((item) => {
              if (item.durMins) {
                totMins += item.durMins;
              }

              if (item.duration) {
                totMins += item.duration / 1000 / 60;
              }
            });
          });
        }
        if (dayN === 2) {
          state.sched.forEach((usr) => {
            usr.tueItems.forEach((item) => {
              if (item.durMins) {
                totMins += item.durMins;
              }

              if (item.duration) {
                totMins += item.duration / 1000 / 60;
              }
            });
          });
        }
        if (dayN === 3) {
          state.sched.forEach((usr) => {
            usr.wedItems.forEach((item) => {
              if (item.durMins) {
                totMins += item.durMins;
              }

              if (item.duration) {
                totMins += item.duration / 1000 / 60;
              }
            });
          });
        }
        if (dayN === 4) {
          state.sched.forEach((usr) => {
            usr.thuItems.forEach((item) => {
              if (item.durMins) {
                totMins += item.durMins;
              }

              if (item.duration) {
                totMins += item.duration / 1000 / 60;
              }
            });
          });
        }
        if (dayN === 5) {
          state.sched.forEach((usr) => {
            usr.friItems.forEach((item) => {
              if (item.durMins) {
                totMins += item.durMins;
              }

              if (item.duration) {
                totMins += item.duration / 1000 / 60;
              }
            });
          });
        }
        if (dayN === 6) {
          state.sched.forEach((usr) => {
            usr.satItems.forEach((item) => {
              if (item.durMins) {
                totMins += item.durMins;
              }

              if (item.duration) {
                totMins += item.duration / 1000 / 60;
              }
            });
          });
        }
        if (dayN === 0) {
          state.sched.forEach((usr) => {
            usr.sunItems.forEach((item) => {
              if (item.durMins) {
                totMins += item.durMins;
              }

              if (item.duration) {
                totMins += item.duration / 1000 / 60;
              }
            });
          });
        }

        return dateStringer.formatMinsDurationToHours(totMins);
      };

      let todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);
      let yesterdayDate = new Date(todayDate.getTime());
      yesterdayDate.setDate(yesterdayDate.getDate() - 1);
      yesterdayDate.setHours(0, 0, 0, 0);
      let tomorrowDate = new Date(todayDate.getTime());
      tomorrowDate.setDate(tomorrowDate.getDate() + 1);
      tomorrowDate.setHours(0, 0, 0, 0);
      let todayDs = dateStringer.createStringFromTimestamp(todayDate.getTime());
      let generateDayString = (ds) => {
        if (
          ds === dateStringer.createStringFromTimestamp(todayDate.getTime())
        ) {
          return "Today";
        }
        if (
          ds === dateStringer.createStringFromTimestamp(yesterdayDate.getTime())
        ) {
          return "Yesterday";
        }
        if (
          ds === dateStringer.createStringFromTimestamp(tomorrowDate.getTime())
        ) {
          return "Tomorrow";
        }
        return `${dateStringer.printedDateFromDs(ds)}`;
      };

      let isDayClosed = (ds) => {
        let dateObj = new Date(dateStringer.createTimestampFromString(ds));
        if (state.closedDayNums.includes(dateObj.getDay())) {
          return true;
        } else {
        }
      };

      let dayIsOdd = (ds) => {
        let dateObj = new Date(dateStringer.createTimestampFromString(ds));

        if (
          dateObj.getDay() === 0 ||
          dateObj.getDay() === 2 ||
          dateObj.getDay() === 4 ||
          dateObj.getDay() === 6
        ) {
          return true;
        } else {
        }
      };

      let dsObj = new Date(dateStringer.createTimestampFromString(day));

      let dayOpens = state.sunOpens;
      if (dsObj.getDay() === 1) {
        dayOpens = state.monOpens;
      }
      if (dsObj.getDay() === 2) {
        dayOpens = state.tueOpens;
      }
      if (dsObj.getDay() === 3) {
        dayOpens = state.wedOpens;
      }
      if (dsObj.getDay() === 4) {
        dayOpens = state.thuOpens;
      }
      if (dsObj.getDay() === 5) {
        dayOpens = state.friOpens;
      }
      if (dsObj.getDay() === 6) {
        dayOpens = state.satOpens;
      }

      dayOpens.sort(function (a, b) {
        return a.openID.localeCompare(b.openID);
      });

      let generateOpens =
        !state.pasteLoading &&
        dayOpens.map((open) => {
          let claimed = false;
          let filtClaim = open.bidUserIDs.filter((item) => {
            return item.userID === checkUserState.userID;
          });

          if (filtClaim[0]) {
            claimed = true;
          }
          if (!open.initial) {
            return (
              <div
                key={open.openID}
                className={`jtUserShiftsBox myRotaShiftBox_ myOpenShiftUnit 
              ${claimed ? "claimedOpen" : ""}`}
                onClick={() => {
                  memoVals.setShowUserOpenShiftModal((x) => open.openID);
                }}
              >
                {/* <div className="shiftBoxLeft invis">
                <div
                  className={`shiftLight shiftLightBlue ${
                    claimed ? "claimedLight" : ""
                  }`}
                ></div>
              </div> */}
                <div className="shiftBoxMiddle">
                  <div className={`shiftBoxMiddleRow1`}>
                    <p
                      className={`openShiftTimeStripMyRota ${
                        dateStringer.dsToTimeStrip(open.startDs, true).length >
                          4 ||
                        dateStringer.dsToTimeStrip(open.endDs, true).length > 4
                          ? "makeTimesSmaller"
                          : ""
                      }  `}
                    >
                      {`${dateStringer.dsToTimeStrip(
                        open.startDs,
                        true
                      )} - ${dateStringer.dsToTimeStrip(open.endDs, true)}`}
                    </p>
                  </div>

                  <div
                    className={`shiftBoxMiddleRow2 openMiddleRow ${
                      state.hoveredShift.typeID === open.openID ? "" : ""
                    }`}
                  >
                    {open.bidUserIDs.length === 0 ? (
                      <p className={`bidderQtyMyRota`}>No bids</p>
                    ) : (
                      <p className={`bidderQtyMyRota`}>
                        {" "}
                        {open.bidUserIDs.length} bid
                        {open.bidUserIDs.length === 1 ? "" : "s"}{" "}
                      </p>
                    )}
                  </div>
                  {/*  */}

                  {/*  */}
                </div>

                <div className="shiftBoxRight onShiftBoxRightMy">
                  <p className="shiftBoxTypeTxtOpen">OPEN</p>
                </div>
              </div>
            );
          } else {
            return "initial";
          }
        });

      let generateDayLimitData = (index) => {
        let limitObj = {};

        if (index === 0) {
          limitObj = state.dayLimitData.mon || {};
        } else if (index === 1) {
          limitObj = state.dayLimitData.tue || {};
        } else if (index === 2) {
          limitObj = state.dayLimitData.wed || {};
        } else if (index === 3) {
          limitObj = state.dayLimitData.thu || {};
        } else if (index === 4) {
          limitObj = state.dayLimitData.fri || {};
        } else if (index === 5) {
          limitObj = state.dayLimitData.sat || {};
        } else if (index === 6) {
          limitObj = state.dayLimitData.sun || {};
        }

        let limitString = "No limit";
        if (limitObj.enable) {
          limitString = limitObj.limitByAll
            ? `${limitObj.currOff} off out of a max of ${limitObj.maxAllOff}`
            : "Limited by job title";
        }

        return (
          <div className={`limitBtUserRotaDiv ${state.limLoad ? "invis" : ""}`}>
            <div
              className={`limityUserRotaLimByTxt ${
                limitObj.currOff > limitObj.maxAllOff
                  ? "limitExceededUserScheduleTeam X19831"
                  : ""
              }`}
            >
              {limitObj.currOff > limitObj.maxAllOff &&
              limitString.includes("job")
                ? "Job title limit exceeded"
                : limitString}
              {limitString.includes("job") ? (
                <img
                  src={downRightArrow2}
                  alt="Limit by job title"
                  className="limitBtJtDownRightImg"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        );
      };

      let getRotaQty = (dayN) => {
        if (dayN === 0) {
          return state.monCommentsQty;
        }

        if (dayN === 1) {
          return state.tueCommentsQty;
        }

        if (dayN === 2) {
          return state.wedCommentsQty;
        }

        if (dayN === 3) {
          return state.thuCommentsQty;
        }

        if (dayN === 4) {
          return state.friCommentsQty;
        }

        if (dayN === 5) {
          return state.satCommentsQty;
        }

        if (dayN === 6) {
          return state.sunCommentsQty;
        }
      };

      let dontShow = false;
      if (dayView) {
        let dateObj = new Date(
          dateStringer.createTimestampFromString(selectedDayViewDs)
        );
        let dateOfCol = new Date(dateStringer.createTimestampFromString(day));
        let dayn = dateOfCol.getDay();
        if (dateObj.getDay() !== dayn) {
          dontShow = true;
        }
      }
      let highlight = () => {
        let highlightFilt = highlightedComments.filter((x) => {
          return x.ds === day;
        });

        if (highlightFilt[0]) {
          return highlightFilt[0].comment;
        }
      };
      return (
        // today
        <div
          key={i}
          className={`dayCol ${dayIsOdd(day) ? "dayOddColBg" : ""} ${
            isDayClosed(day) ? "dayClosedBg" : ""
          } ${
            dateStringer.createStringFromTimestamp(yesterdayDate.getTime()) ===
            day
              ? "yesterdayDayCol"
              : ""
          } ${hoverDs === day ? "hoveredDayColBorderLandR" : ""}  ${
            todayDs === day
              ? `todayDayColBg ${i === 0 ? "mondayTodayBorder" : ""}`
              : ""
          } noBottomPadding ${dontShow ? "none" : ""}`}
        >
          {/* <div className="jobTitleUsersContainer"> */}
          <div
            className={`dayTopUnit ${
              i === 1 || i === 3 || i === 5 ? "dayTopUnitOdd" : ""
            }  ${
              dateStringer.createStringFromTimestamp(
                yesterdayDate.getTime()
              ) === day
                ? "yesterdayDayTopUnit"
                : ""
            }  ${i === 6 ? "lastDayTopUnit" : ""} ${
              dateStringer.createStringFromTimestamp(tomorrowDate.getTime()) ===
              day
                ? "tomorrowDayTopUnit"
                : ""
            } ${todayDs === day ? "todayDayTopUnit" : ""}`}
            onClick={() => {}}
          >
            <p
              className={`dayTitleHeadline ${state.loading ? "none" : ""}  ${
                memoVals.mob && dayView ? "invis" : ""
              }
            `}
              onClick={() => {}}
            >
              {generateDayString(day)}
            </p>{" "}
            {state.loading || memoVals.standalone ? (
              ""
            ) : (
              <div
                className="rotaCommentDiv"
                onClick={() => {
                  memoVals.setShowDayCommentModal((x) => {
                    return {
                      show: true,
                      isMgr: managesThisTeam, // pierre
                      teamID: selectedTeam,
                      ds: day,
                    };
                  });
                }}
              >
                <img
                  src={commentRota}
                  alt="Day comments"
                  className={`dayCommentIcon ${
                    getRotaQty(i) ? "fullOpac hoverCommentActive" : "x9247925"
                  }`}
                />
                {getRotaQty(i) ? (
                  <p className="rotaCommentsQty">{getRotaQty(i)}</p>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          {showSpecDays() && !state.loading ? (
            <div
              className={`${
                showSpecDays() && !state.loading ? "specDayRow" : "none"
              } ${specDay(day) && "activeSpecDayRow marginLeft0"}`}
            >
              <p>
                {specDay(day)}{" "}
                {specDay(day) ? (
                  <span className="specDayClosedSpan">Closed</span>
                ) : (
                  ""
                )}
              </p>{" "}
            </div>
          ) : (
            <div>
              <p> </p>
            </div>
          )}
          <div
            className={`${
              state.jobTitles.length > 0
                ? "dayColContent"
                : `${state.loading ? "dayColContent" : "dayColContent"}`
            }`}
          >
            {highlightedComments[0] ? (
              <div
                className={`highlightedCommBox ${
                  highlight()
                    ? `highlightedBG ${
                        highlight().length > 70 ? "smallerHighlight" : ""
                      }`
                    : ""
                } ${memoVals.standalone ? "defaultCursor" : ""}`}
                onClick={() => {
                  if (highlight()) {
                    memoVals.setShowDayCommentModal((x) => {
                      return {
                        show: true,
                        isMgr: managesThisTeam,
                        teamID: selectedTeam,
                        ds: day,
                      };
                    });
                  }
                }}
              >
                {highlight() ? <p className="highlightExclaim">!</p> : ""}
                <p
                  className={`highlightTxt ${
                    memoVals.standalone ? "highlightTxtStandalone" : ""
                  }`}
                >
                  {dateStringer.shorten(highlight(), 82)}
                </p>
                {highlight() ? <p className="highlightExclaim">!</p> : ""}
              </div>
            ) : (
              ""
            )}
            {!memoVals.standalone && (
              <div
                className={`openShiftsArrayDiv ${
                  (state.sunOpens.length === 0 &&
                    state.monOpens.length === 0 &&
                    state.tueOpens.length === 0 &&
                    state.wedOpens.length === 0 &&
                    state.thuOpens.length === 0 &&
                    state.friOpens.length === 0 &&
                    state.satOpens.length === 0) ||
                  state.pasteLoading
                    ? "zeroOpensHeight"
                    : ""
                }  
          
`}
              >
                {generateOpens}
              </div>
            )}

            {!memoVals.standalone && (
              <div
                className={`limitRowDayDataDiv  userRotaAvailColours ${
                  i === 6 ? "userRotaAvailColoursLast" : ""
                } ${state.hideSchedLimits ? "hideUserSchedLimits" : ""}`}
                onClick={() => {
                  console.log(state.hideSchedLimits);
                }}
              >
                {generateDayLimitData(i)}
              </div>
            )}
            {generateJobTitleUnits}
          </div>
          {/* <div className="myRotaBottomBar "></div> */}
          {/* </div> */}
        </div>
      );
    }
  });

  // console.log({ selectedMonWeek });
  // master
  //  - - - -

  return (
    <div
      className={`userShiftsPage ${
        !memoVals.mob
          ? `desktopTeamSchedHeight ${
              memoVals.standalone ? "desktopTeamSchedHeightStandalone" : ""
            }`
          : "mobMyRotaBgColour"
      } `}
    >
      {" "}
      {!memoVals.standalone && (
        <div
          className={`userShiftsPageUpperContainer purpleBg ${
            memoVals.mob ? "myRotaColours noBorder" : ""
          }`}
        >
          {!memoVals.mob && !memoVals.standalone ? (
            <TopBar teamSchedule={true} />
          ) : (
            ""
          )}
          <div
            className={`userShiftsUpperInnerContainer ${
              memoVals.mob ? "mobUpper" : ""
            }`}
          >
            <div
              className={`teamShiftsTitleContainer ${
                memoVals.mob
                  ? "teamShiftsTitleContainerMob"
                  : "desktopTeamRotaTitleBlock"
              }`}
              onClick={() => {}}
            >
              {/* <NavLink
              to="/rota"
              onClick={() => {
                memoVals.setCalPage(x =>"schedule");
              }}
            >
              <img
                src={memoVals.mob ? whiteBack2 : purpleBack}
                alt="Back"
                className="returnBackFromTeamAbsenceImg"
              />
            </NavLink> */}
              <p
                className={`myRotaTitle ${
                  memoVals.mob
                    ? "myRotaTitleMob myRotaTitle"
                    : "headerTitleSize"
                }`}
                onClick={() => {
                  // console.log("mon opens: ", state.monOpens);
                  // console.log({ dayViewArr });
                  console.log({ state });
                }}
              >
                My rota
                {/* {width} */}
              </p>
            </div>
            {checkUserState.permissions < 3 ? (
              <NavLink
                to="/team-rota"
                onClick={() => {
                  memoVals.setCalPage((x) => "team-schedule");
                }}
              >
                <div
                  className={`staffRotaBtn ${
                    memoVals.mob ? "staffRotaBtnMob pinkColours" : "pinkColours"
                  }`}
                >
                  Edit rota{" "}
                  <img
                    src={edit}
                    alt="Staff rota"
                    className="staffRotaWhiteGoImg"
                  />{" "}
                </div>
              </NavLink>
            ) : (
              ""
            )}
            {/* SANDRA */}
            {/* <div
              className={`userSchedMgrAvailBtn ${
                checkUserState.permissions > 2 ? "nonMgrAvailBtn" : ""
              } ${
                memoVals.mob
                  ? `userSchedMgrAvailBtnMob ${
                      checkUserState.permissions > 2 ? "nonMgrAvailBtnMob" : ""
                    }`
                  : ""
              } ${memoVals.mob ? "none" : ""}`}
              onClick={() => {
                memoVals.setShowAddAvailModal((x) => {
                  return {
                    loadTeamID: selectedTeam,
                    monWeek: selectedMonWeek,
                  };
                });
              }}
            >
          
              {window.innerWidth < 390 ? "Availability" : "My availability"}
              {window.innerWidth >= 390 ? (
                <img src={popup2} alt="Staff rota" className="availPopupImg" />
              ) : (
                ""
              )}
            </div> */}
          </div>{" "}
          {!memoVals.mob ? (
            locationID ? (
              <p className={`userRotaLocMode ${!locationName ? "hidden" : ""}`}>
                Viewing rota for all employees at {locationName}
              </p>
            ) : (
              <div className={`shiftsTeamBar myRotaTeamBar`}>
                {generateTeamsSelectBar}
              </div>
            )
          ) : (
            ""
          )}
          {memoVals.mob ? (
            <select
              className="teamRotaTeamSelectDropdown teamRotaTeamSelectDropdownMyRota"
              value={selectedTeam}
              onChange={(e) => {
                setSelectedTeam(e.target.value);
                setState({ loading: true });
                memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
              }}
            >
              {generateTeamsSelectBar}
            </select>
          ) : (
            ""
          )}
        </div>
      )}
      {/* <div className="adminCurverCropper bgColUpcoming absenceShoulder hardPurpleBg">
        <div className="adminCurve"></div>
      </div>{" "} */}
      <div
        className={`shiftsPageFilterBarRota noPadLeft ${
          memoVals.mob ? "x6282" : "borderBottomGrey"
        } teamShiftsSectionTeamOnly ${
          !memoVals.mob
            ? "borderLeftGrey"
            : !memoVals.standalone
            ? "mobRotaTopLine"
            : ""
        } ${memoVals.mob ? "teamShiftsSectionTeamOnlyDayView" : ""} ${
          memoVals.mob ? "whiteBg" : ""
        } noBorderRight`}
      >
        {!memoVals.standalone ? (
          memoVals.mob && dayView ? (
            <div className={`teamSchedFilterBarLeft marginLeft3pc`}>
              <img
                src={currMonWeek === selectedMonWeek ? greyArr : greyArr}
                alt="Left"
                className={`leftMonWeek ${
                  selectedMonWeek === monWeeks[0] || state.loading
                    ? "fadeSelMonWeekBtn_"
                    : ""
                } ${currMonWeek !== selectedMonWeek && "nonCurrColours"} ${
                  rotaDayLoading ? "disable" : ""
                }`}
                onClick={() => {
                  if (dayView) {
                    let dateObj = new Date(
                      dateStringer.createTimestampFromString(selectedDayViewDs)
                    );
                    dateObj.setDate(dateObj.getDate() - 1);
                    if (dateObj.getDay() === 0) {
                      setSelectedMonWeek(
                        dateStringer.createStringFromTimestamp(
                          dateObj.getTime()
                        )
                      );
                    }
                    setSelectedDayViewDs(
                      dateStringer.createStringFromTimestamp(dateObj.getTime())
                    );
                  } else {
                    setHoverUserID("");
                    setHoverDs("");

                    setState({ loadingLimits: true });
                    let currTs =
                      dateStringer.createTimestampFromString(selectedMonWeek);
                    let dateObj = new Date(currTs);
                    dateObj.setDate(dateObj.getDate() - 7);
                    dateObj.setHours(0, 0, 0, 0);

                    let newMonWeek = dateStringer.createStringFromTimestamp(
                      dateObj.getTime()
                    );

                    if (monWeeks.includes(newMonWeek)) {
                      setSelectedMonWeek(newMonWeek);
                      setState({ availLoading: true });
                    }
                  }
                }}
              />
              <div
                className={`myRotaDayViewDs ${
                  `${dateStringer
                    .createStringFromTimestamp(new Date().getTime())
                    .substr(0, 11)}H00M00` === selectedDayViewDs
                    ? "myRotaDayViewDsToday"
                    : ""
                }`}
              >
                <p className="myRotaDayViewDsUpper">
                  {dateStringer.getThreeDayLettersFromDs(
                    selectedDayViewDs,
                    true
                  )}
                </p>
                <p className="myRotaDayViewDsLower">
                  {dateStringer.printedDateWithYrFromDWithoutDay(
                    selectedDayViewDs
                  )}{" "}
                </p>
              </div>
              {/* <select
                className={`monWeeksDropdown ${
                  currMonWeek === selectedMonWeek && !dayView
                    ? "currMonWeekBgDrop"
                    : ""
                } ${
                  window.innerWidth > 600
                    ? "fullScreenWeekSelector"
                    : "weekSelectorMedium"
                } ${dayView ? "dayViewWeekSelector" : ""}`}
                value={dayView ? selectedDayViewDs : selectedMonWeek}
                onChange={(e) => {
                  if (dayView) {
                    setSelectedDayViewDs(e.target.value);
                    if (
                      selectedMonWeek !==
                      dateStringer.getMonWeekOfDs(e.target.value)
                    ) {
                      setSelectedMonWeek(
                        dateStringer.getMonWeekOfDs(e.target.value)
                      );
                      setState({ availLoading: true, loading: true });
                    }
                  } else {
                    setSelectedMonWeek(e.target.value);
                    setState({ availLoading: true, loading: true });
                  }
                }}
              >
                {generateMonWeeks}
              </select>{" "} */}
              <img
                src={currMonWeek === selectedMonWeek ? greyArr : greyArr}
                alt="Right"
                className={`rightMonWeek ${
                  selectedMonWeek === monWeeks[monWeeks.length - 1] ||
                  state.loading
                    ? "fadeSelMonWeekBtn"
                    : ""
                } ${currMonWeek !== selectedMonWeek && "nonCurrColours"} ${
                  window.innerWidth < 300 ? "none" : ""
                } ${rotaDayLoading ? "disable" : ""}`}
                onClick={() => {
                  if (dayView) {
                    let dateObj = new Date(
                      dateStringer.createTimestampFromString(selectedDayViewDs)
                    );
                    dateObj.setDate(dateObj.getDate() + 1);
                    if (dateObj.getDay() === 1) {
                      setSelectedMonWeek(
                        dateStringer.createStringFromTimestamp(
                          dateObj.getTime()
                        )
                      );
                    }
                    setSelectedDayViewDs(
                      dateStringer.createStringFromTimestamp(dateObj.getTime())
                    );
                  } else {
                    setState({ loadingLimits: true });
                    setHoverUserID("");
                    setHoverDs("");

                    let currTs =
                      dateStringer.createTimestampFromString(selectedMonWeek);
                    let dateObj = new Date(currTs);
                    dateObj.setDate(dateObj.getDate() + 7);
                    dateObj.setHours(0, 0, 0, 0);

                    let newMonWeek = dateStringer.createStringFromTimestamp(
                      dateObj.getTime()
                    );

                    if (monWeeks.includes(newMonWeek)) {
                      setSelectedMonWeek(newMonWeek);
                      setState({ availLoading: true });
                    }
                  }
                }}
              />
              {/* <img
                src={currWeek}
                onClick={() => {
                  if (dayView) {
                    let todayObj = new Date();
                    todayObj.setHours(0, 0, 0, 0);

                    let todayDs = dateStringer.createStringFromTimestamp(
                      todayObj.getTime()
                    );
                    setSelectedMonWeek(dateStringer.getMonWeekOfDs(todayDs));
                    setSelectedDayViewDs(todayDs);
                  } else {
                    setSelectedMonWeek(currMonWeek);
                    setState({ availLoading: true });
                  }
                }}
                alt="Current week"
                className={`${
                  selectedDayViewDs !==
                  `${dateStringer
                    .createStringFromTimestamp(new Date().getTime())
                    .substr(0, 11)}H00M00`
                    ? "currWeekIcon"
                    : "none"
                } ${state.loading ? "fadeSelMonWeekBtn" : ""}`}
              /> */}
            </div>
          ) : (
            <div
              className={`teamSchedFilterBarLeft ${
                memoVals.mob ? "teamSchedFilterBarLeftMob" : ""
              }`}
            >
              <img
                src={currMonWeek === selectedMonWeek ? greyArr : greyArr}
                alt="Left"
                className={`leftMonWeek ${
                  window.innerWidth < 940 && !memoVals.mob ? "none" : ""
                } ${
                  selectedMonWeek === monWeeks[0] || state.loading
                    ? "fadeSelMonWeekBtn"
                    : ""
                } ${currMonWeek !== selectedMonWeek && "nonCurrColours"} ${
                  memoVals.mob ? "none" : ""
                }`}
                onClick={() => {
                  if (dayView) {
                    let dateObj = new Date(
                      dateStringer.createTimestampFromString(selectedDayViewDs)
                    );
                    dateObj.setDate(dateObj.getDate() - 1);
                    if (dateObj.getDay() === 0) {
                      setSelectedMonWeek(
                        dateStringer.createStringFromTimestamp(
                          dateObj.getTime()
                        )
                      );
                    }
                    setSelectedDayViewDs(
                      dateStringer.createStringFromTimestamp(dateObj.getTime())
                    );
                  } else {
                    setHoverUserID("");
                    setHoverDs("");

                    setState({ loadingLimits: true });
                    let currTs =
                      dateStringer.createTimestampFromString(selectedMonWeek);
                    let dateObj = new Date(currTs);
                    dateObj.setDate(dateObj.getDate() - 7);
                    dateObj.setHours(0, 0, 0, 0);

                    let newMonWeek = dateStringer.createStringFromTimestamp(
                      dateObj.getTime()
                    );

                    if (monWeeks.includes(newMonWeek)) {
                      setSelectedMonWeek(newMonWeek);
                      setState({ availLoading: true });
                    }
                  }
                }}
              />
              <select
                className={`monWeeksDropdown ${
                  memoVals.mob ? "monWeeksDropdownMob" : ""
                } ${
                  currMonWeek === selectedMonWeek && !dayView
                    ? `currMonWeekBgDrop ${
                        memoVals.mob ? "currMonWeekBgDropMob" : ""
                      }`
                    : ""
                } ${
                  window.innerWidth > 600
                    ? "fullScreenWeekSelector"
                    : "weekSelectorMedium"
                } ${dayView ? "dayViewWeekSelector" : ""}`}
                value={dayView ? selectedDayViewDs : selectedMonWeek}
                onChange={(e) => {
                  if (dayView) {
                    setSelectedDayViewDs(e.target.value);
                    if (
                      selectedMonWeek !==
                      dateStringer.getMonWeekOfDs(e.target.value)
                    ) {
                      setSelectedMonWeek(
                        dateStringer.getMonWeekOfDs(e.target.value)
                      );
                      setState({ availLoading: true, loading: true });
                    }
                  } else {
                    setSelectedMonWeek(e.target.value);
                    setState({ availLoading: true, loading: true });
                  }
                }}
              >
                {generateMonWeeks}
              </select>{" "}
              <img
                src={currMonWeek === selectedMonWeek ? greyArr : greyArr}
                alt="Right"
                className={`rightMonWeek ${
                  window.innerWidth < 940 && !memoVals.mob ? "none" : ""
                } ${
                  selectedMonWeek === monWeeks[monWeeks.length - 1] ||
                  state.loading
                    ? "fadeSelMonWeekBtn"
                    : ""
                } ${currMonWeek !== selectedMonWeek && "nonCurrColours"} ${
                  window.innerWidth < 378 ? "none" : ""
                }`}
                onClick={() => {
                  if (dayView) {
                    let dateObj = new Date(
                      dateStringer.createTimestampFromString(selectedDayViewDs)
                    );
                    dateObj.setDate(dateObj.getDate() + 1);
                    if (dateObj.getDay() === 1) {
                      setSelectedMonWeek(
                        dateStringer.createStringFromTimestamp(
                          dateObj.getTime()
                        )
                      );
                    }
                    setSelectedDayViewDs(
                      dateStringer.createStringFromTimestamp(dateObj.getTime())
                    );
                  } else {
                    setState({ loadingLimits: true });
                    setHoverUserID("");
                    setHoverDs("");

                    let currTs =
                      dateStringer.createTimestampFromString(selectedMonWeek);
                    let dateObj = new Date(currTs);
                    dateObj.setDate(dateObj.getDate() + 7);
                    dateObj.setHours(0, 0, 0, 0);

                    let newMonWeek = dateStringer.createStringFromTimestamp(
                      dateObj.getTime()
                    );

                    if (monWeeks.includes(newMonWeek)) {
                      setSelectedMonWeek(newMonWeek);
                      setState({ availLoading: true });
                    }
                  }
                }}
              />
              <img
                src={currWeek}
                onClick={() => {
                  if (dayView) {
                    let todayObj = new Date();
                    todayObj.setHours(0, 0, 0, 0);

                    let todayDs = dateStringer.createStringFromTimestamp(
                      todayObj.getTime()
                    );
                    setSelectedMonWeek(dateStringer.getMonWeekOfDs(todayDs));
                    setSelectedDayViewDs(todayDs);
                  } else {
                    setSelectedMonWeek(currMonWeek);
                    setState({ availLoading: true });
                  }
                }}
                alt="Current week"
                className={`${
                  currMonWeek !== selectedMonWeek || dayView
                    ? "currWeekIcon lightBlueColours"
                    : "none"
                } ${state.loading ? "fadeSelMonWeekBtn" : ""} ${
                  locationID ? "none" : ""
                }`}
              />
              {!memoVals.mob ? (
                <select
                  className={`monWeeksDropdown userRotaLocationsDropdown ${
                    !locationID ? "bbcada" : ""
                  } ${locations.length === 0 ? "none" : ""}`}
                  value={locationID}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setLocationName("");
                    }
                    setLocationID(e.target.value);
                  }}
                >
                  <option value="">All locations</option>
                  {locations
                    .sort((a, b) => {
                      return a.locationName.localeCompare(b.locationName);
                    })
                    .map((loc) => {
                      return (
                        <option key={loc.locationID} value={loc.locationID}>
                          {dateStringer.shorten(
                            loc.locationName,
                            locationID === loc.locationID ? 18 : 100
                          )}
                        </option>
                      );
                    })}
                </select>
              ) : (
                ""
              )}
            </div>
          )
        ) : (
          <p
            className={`rotaTitleStandalone ${
              memoVals.mob
                ? `rotaTitleStandaloneMob ${
                    teamName && teamName.length > 28
                      ? "rotaTitleStandaloneMobSmall"
                      : ""
                  }`
                : ""
            }`}
          >
            <span className={`locTeamTitle ${!teamName ? "hidden" : ""}`}>
              {locationID ? "Location: " : "Team: "}
            </span>{" "}
            {teamName}
          </p>
        )}
        {memoVals.standalone &&
        acceptanceKeyRequired &&
        window.innerWidth > 560 ? (
          <div className="acceptKeyBox">
            {" "}
            <img
              src={doubleTick}
              alt="Double tick"
              className="myRotaDoubleTickImg keyDoubleTick"
            />
            <p className="acceptKeyTxt">= Accepted</p>
          </div>
        ) : (
          ""
        )}
        <div
          className={`teamSchedFilterBarRight ${
            memoVals.standalone ? "teamSchedFilterBarRightStandalone" : ""
          } ${state.loading ? "invis" : ""}`}
        >
          {!memoVals.standalone && !memoVals.mob && (
            <div
              className={`myRotaAllowSwapsBtn allowSwapsMyRotaBtnActive ${
                state.adminSwapsEnabled ? "changesMoveLeft" : ""
              } 
                ${window.innerWidth <= 400 ? "reqsBtnSub520" : ""}
                `}
              onClick={() => {
                memoVals.setShowAddAvailModal((x) => {
                  return {
                    loadTeamID: selectedTeam,
                    monWeek: selectedMonWeek,
                  };
                });
              }}
            >
              {/* todo: show qty blue if unseen changes is more than 1 */}
              Availability
              {/* {countMyChanges ? (
                <p className={`rotaSwapsQty`}>
                  {countMyChanges > 9 ? "9+" : countMyChanges}
                </p>
              ) : (
                ""
              )} */}
              <img
                src={popupNavy}
                alt="Share"
                className="shareGreyImg x3289292x x4398759785424"
              />
            </div>
          )}
          {!memoVals.standalone && !memoVals.mob && (
            <NavLink to="/hours?changes=true">
              <div
                className={`myRotaAllowSwapsBtn allowSwapsMyRotaBtnActive ${
                  state.adminSwapsEnabled ? "changesMoveLeft" : ""
                } 
                ${window.innerWidth <= 400 ? "reqsBtnSub520" : ""}
                `}
              >
                {/* todo: show qty blue if unseen changes is more than 1 */}
                Requests{" "}
                {/* {countMyChanges ? (
                <p className={`rotaSwapsQty`}>
                  {countMyChanges > 9 ? "9+" : countMyChanges}
                </p>
              ) : (
                ""
              )} */}
                {countData.outstandingChanges > 0 ? (
                  <p className={`rotaSwapsQty `}>
                    {countData.outstandingChanges}
                  </p>
                ) : (
                  <img
                    src={requestsGreyNavy2}
                    alt="Share"
                    className="shareGreyImg x3289292x"
                  />
                )}
              </div>
            </NavLink>
          )}

          {!memoVals.standalone && memoVals.mob && (
            <div
              className={`myRotaMenuBtn ${
                showMenu ? "myRotaMenuBtnActive" : ""
              }`}
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            >
              {countData.inboundSwaps +
                countData.outboundSwaps +
                countData.outstandingChanges +
                countData.openShifts >
              0 ? (
                <div className="rotaMenuNotif">
                  {countData.inboundSwaps +
                    countData.outboundSwaps +
                    countData.outstandingChanges +
                    countData.openShifts >
                  9
                    ? "9+"
                    : countData.inboundSwaps +
                      countData.outboundSwaps +
                      countData.outstandingChanges +
                      countData.openShifts}
                </div>
              ) : (
                ""
              )}
              Rota menu{" "}
              <img
                src={adminBurgerMob}
                className="myRotaMenuBurger"
                alt="My rota menu"
              />
            </div>
          )}
          {memoVals.mob && showMenu ? (
            <div
              className="myRotaMenuUnderlay"
              onClick={() => {
                setShowMenu(false);
              }}
            >
              <div className="myRotaMenuOptions">
                <p
                  className={`mobAdminOption myRotaMenuOption generalNoBorder`}
                  onClick={() => {
                    setShowMenu(false);
                    if (dayView) {
                      setLoading(true);
                    }
                    setRotaDayLoading(true);
                    // setDayView(!dayView);
                    axios
                      .post(
                        `${serverURL}/toggle-rota-view`,
                        { data: !dayView },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setDayView(response.data.dayView);
                          setSelectedMonWeek(
                            dateStringer.getMonWeekOfDs(selectedMonWeek)
                          );
                          setSelectedDayViewDs(selectedMonWeek);
                          // haribo
                        }
                      });
                  }}
                >
                  {dayView ? "Week view" : "Day view"}
                  <img
                    src={dayView ? myRotaWeekView : myRotaDayView}
                    alt="My swaps"
                    className={`myRotaMenuImgSwaps ${
                      !dayView ? "x233894984" : ""
                    }`}
                  />
                </p>
                <p
                  className={`mobAdminOption myRotaMenuOption`}
                  onClick={() => {
                    setShowMenu(false);
                    memoVals.setShowOpenShiftsModal((x) => true);
                  }}
                >
                  Open shifts{" "}
                  {countData.openShifts ? (
                    <span className="mySwapsQtySpan">
                      {countData.openShifts}
                    </span>
                  ) : (
                    ""
                  )}
                  <img
                    src={rotaMenuOpenShifts}
                    alt="My swaps"
                    className="myRotaMenuImgSwaps"
                  />
                </p>
                <p
                  className={`mobAdminOption myRotaMenuOption`}
                  onClick={() => {
                    setShowMenu(false);
                    memoVals.setShowSwapsModal((x) => true);
                  }}
                >
                  My swaps{" "}
                  {countData.inboundSwaps + countData.outboundSwaps > 0 ? (
                    <span className="mySwapsQtySpan">
                      {countData.inboundSwaps + countData.outboundSwaps}
                    </span>
                  ) : (
                    ""
                  )}
                  <img
                    src={dashSwap}
                    alt="My swaps"
                    className="myRotaMenuImgSwaps"
                  />
                </p>
                <p
                  className={`mobAdminOption myRotaMenuOption`}
                  onClick={() => {
                    setShowMenu(false);
                    memoVals.setShowAddAvailModal((x) => {
                      return {
                        loadTeamID: selectedTeam,
                        monWeek: selectedMonWeek,
                      };
                    });
                  }}
                >
                  My availability
                  <img
                    src={popupBlue}
                    alt="My swaps"
                    className="myRotaMenuImgSwaps"
                  />
                </p>{" "}
                <NavLink to="/hours?changes=true">
                  <p
                    className={`mobAdminOption myRotaMenuOption`}
                    onClick={() => {
                      setShowMenu(false);
                    }}
                  >
                    My shift requests{" "}
                    {countData.outstandingChanges > 0 ? (
                      <span className="mySwapsQtySpan">
                        {countData.outstandingChanges}
                      </span>
                    ) : (
                      ""
                    )}
                    <img
                      src={requestsGreyNavy}
                      alt="My swaps"
                      className="myRotaMenuImgSwaps myRotaMenuImgRequests"
                    />
                  </p>{" "}
                </NavLink>
              </div>
            </div>
          ) : (
            ""
          )}
          {state.adminSwapsEnabled && !memoVals.standalone && !memoVals.mob ? (
            <div
              className={`myRotaAllowSwapsBtn ${
                memoVals.allowSwaps ? "allowSwapsMyRotaBtnActive" : ""
              } ${memoVals.mob ? "x198398121" : ""}`}
              onClick={() => {
                memoVals.setShowSwapsModal((x) => true);
              }}
            >
              {" "}
              Swaps{" "}
              {countData.inboundSwaps > 0 ? (
                <p className={`rotaSwapsQty`}>{countData.inboundSwaps}</p>
              ) : (
                <img
                  src={swapGreyNavy}
                  alt="Share"
                  className="shareGreyImg x3289292d"
                />
              )}
              {/* {memoVals.allowSwaps ? (
                <img
                  src={unitTick}
                  className="allowSwapsTickCrossImg"
                  alt="Allow swaps"
                />
              ) : (
                ""
              )} */}
              {/*  KNOB BEGIN */}
              {/* <div className="editDayFxSchedKnob"> */}
              {/* <div className="knobContainer allowSwapsKnobMyRota">
                <div
                  className={`switchKnob ${
                    state.memoVals.allowSwaps ? "knobOn allowSwapsKnobOn" : "knobOff"
                  }`}
                ></div>
                <div className="knobBg allowSwapsKnobBg"></div>
              </div> */}
              {/* </div> */}
              {/*  KNOB END */}
            </div>
          ) : (
            ""
          )}
        </div>
        {memoVals.standalone && (
          <p className="standaloneRotaStrip">
            {dateStringer.printedDateFromDs(selectedMonWeek)} -{" "}
            {dateStringer.printedDateWithYrFromDs(lastDs)}
          </p>
        )}

        {memoVals.standalone && (
          <p className="standloneLoggedInDiv">
            {loggedInStandalone ? (
              <div
                className="standaloneLoggedIn"
                onClick={() => {
                  window.location.href = `${appURL}/team-rota?load=true&teamID=${selectedTeam}&monweek=${selectedMonWeek}`;
                }}
              >
                View in app
              </div>
            ) : (
              <a href="https://flowrota.com/">
                <img src={frlogo} alt="FlowRota app" className="frLogoRota" />
              </a>
            )}
          </p>
        )}
      </div>{" "}
      <div className="shiftTilFilterHolder"></div>
      <div
        className={`filterSplitterContainer ${
          !memoVals.mob ? "borderLeftGrey" : ""
        }`}
      >
        <div className={`${!memoVals.mob ? "borderLeftGrey" : ""}`}></div>
      </div>
      <div
        className={`teamSchedulePage teamSchedulePageUser ${
          !memoVals.mob
            ? "desktopTeamSchedHeight"
            : "paddingTop0 mobUserRotaSchedHeight"
        } ${
          memoVals.mob && memoVals.standalone
            ? "mobStandaloneTeamSchedHeight"
            : ""
        }`}
      >
        {/* new content */}
        <div
          className={`${showTip && !memoVals.standalone ? "tipBox" : "none"}`}
        >
          <img
            src={whitecross}
            alt="close"
            className="tipBoxClose"
            onClick={() => {
              setShowTip(false);
            }}
          />
          <div className="tipBoxBody">
            <p className="tipBoxTxt">
              <span className="tipBoxTipSpan">Tip: </span>
              Click anywhere on the rota and use your arrow keys to move around.
            </p>
            <p
              className="dontShowTipBtn"
              onClick={() => {
                setShowTip(false);
                axios
                  .post(
                    `${serverURL}/disable-tip`,
                    {
                      tip: "arrows",
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Don't show again
            </p>
          </div>
        </div>
        {loading && !showPasswordInput ? (
          <img
            src={horizontalBalls}
            alt="Loading"
            className="teamSchedLoadingBallsImg"
          />
        ) : (
          ""
        )}
        {showPasswordInput ? (
          <div className="rotaPassInputDiv">
            <p className="rotaPassTitle">A password is required</p>

            <input
              value={passInput}
              onChange={(e) => {
                setPassInput(e.target.value);
                if (incorrectPassword) {
                  setIncorrectPassword(false);
                }
              }}
              type="password"
              onKeyUp={(e) => {
                checkCaps(e);
              }}
              onKeyPress={(e) => {
                enterFct(e);
              }}
              autoComplete="new-password"
              className="rotaPassInputter"
            ></input>
            <p className={`capsLockTxt ${capsLockOn ? "" : "invis"}`}>
              Caps lock is on
            </p>

            <p
              className="rotaPassEnter"
              onClick={() => {
                enterFct("xx");
              }}
            >
              Submit
            </p>

            {incorrectPassword ? (
              <p className="wrongPassRota">Incorrect password</p>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        <div
          className={`teamSchedContainer ${
            !memoVals.mob
              ? "teamSchedDesktop"
              : `teamSchedContainerMob ${
                  memoVals.device.ios ? "teamSchedContainerMobIos" : ""
                }`
          } ${
            !memoVals.mob ? "borderLeftGrey myRotaSchedContainerDesktop" : ""
          } ${loading ? "none" : ""} ${
            memoVals.device.vhIssue ? "vhIssue" : ""
          } ${
            memoVals.standalone && !memoVals.mob ? "standaloneDesktopRota" : ""
          } ${memoVals.standalone && memoVals.mob ? "standaloneMobRota" : ""} ${
            memoVals.mob && dayView ? "noScroll" : ""
          }`}
        >
          {memoVals.mob && dayView ? (
            <div
              className={`mobRotaInnerDayView ${
                memoVals.device.vhIssue ? "mobRotaInnerDayViewIss_" : ""
              }`}
            >
              {rotaDayLoading ? (
                <img
                  src={horizontalBalls}
                  alt="Loading"
                  className="teamSchedLoadingBallsImg zoomIn"
                />
              ) : (
                // "DAY VIEWHERE"
                <div>
                  <div className="dayViewRow">
                    <div className="dayViewRowLeft">
                      <p className="dayViewRowLeftTitle">Day comments</p>
                    </div>{" "}
                    <div className="dayViewRowRight">
                      {dvState.highlightedComment && (
                        <div
                          className="dvStateHighlight"
                          onClick={() => {
                            memoVals.setShowDayCommentModal((x) => {
                              return {
                                show: true,
                                isMgr: managesThisTeam, // pierre
                                teamID: selectedTeam,
                                ds: selectedDayViewDs,
                              };
                            });
                          }}
                        >
                          <p className="dvStateExclaim">!</p>
                          <div className="dvStateHighlightedNote">
                            {dvState.highlightedComment}
                            <br />
                            <p className="dvStateHighlightedNoteName color516b8b">
                              {dvState.highlightedUserName}
                            </p>
                          </div>
                          {/* <p className="dvStateExclaim">!</p> */}
                        </div>
                      )}
                      <div
                        className={`dvStateHighlight dvStateNotesBox ${
                          dvState.highlightedComment
                            ? "dvStateNotesBoxWithHighlight"
                            : ""
                        }`}
                        onClick={() => {
                          memoVals.setShowDayCommentModal((x) => {
                            return {
                              show: true,
                              isMgr: managesThisTeam, // pierre
                              teamID: selectedTeam,
                              ds: selectedDayViewDs,
                            };
                          });
                        }}
                      >
                        <img
                          src={commentDay}
                          alt="Day comments"
                          className={`dayCommentInline ${
                            dvState.commentsQty === 0 ? "greyscale" : ""
                          }`}
                        />
                        {dvState.commentsQty === 0 ? (
                          <div className="dvStateHighlightedNote">
                            No comments
                          </div>
                        ) : (
                          <div className="dvStateHighlightedNote">
                            {dvState.commentsQty} comment
                            {dvState.commentsQty === 1 ? "" : "s"}
                          </div>
                        )}
                        {/* <p className="dvStateExclaim">!</p> */}
                      </div>
                    </div>
                  </div>

                  {dvState.payDay && (
                    <div className="dayViewRow">
                      <div className="dayViewRowLeft">
                        <p className="dayViewRowLeftTitle"></p>
                      </div>{" "}
                      <div className="dayViewRowRight">
                        <p className="dvPayDay">Pay day</p>
                      </div>
                    </div>
                  )}

                  <div className="dayViewRow">
                    <div className="dayViewRowLeft">
                      <p className="dayViewRowLeftTitle">Shifts</p>
                    </div>{" "}
                    <div className="dayViewRowRight">
                      {dvState.shifts.map((shift) => {
                        return (
                          <div
                            className="dvStateHighlight dvStateHighlightShift paddingLeft0"
                            onClick={() => {
                              memoVals.setShowMyShiftModal((x) => {
                                return {
                                  unitType: shift.type,
                                  unitID: shift.itemID,
                                  otherUser: shift.mine ? false : true,
                                };
                              });
                            }}
                          >
                            {shift.mine && (
                              <div className="rotaDayMyShBueBar"></div>
                            )}
                            <div className="shBlockAndProPicDiv">
                              <img
                                src={shiftBlock}
                                alt="User shift"
                                className="openShiftBlockDayViewImg openShiftBlockDayViewImgShift"
                              />{" "}
                              <img
                                src={
                                  shift.profPicUrl || profilePicturePlaceholder
                                }
                                alt={shift.fName}
                                className="shImgUserPP"
                              />
                            </div>
                            <div className="dvStateHighlightedNote paddingRight0">
                              <span className="weight600">
                                {shift.type === "til"
                                  ? "Overtime (time in lieu)"
                                  : shift.type === "shift"
                                  ? "Shift"
                                  : "Overtime"}
                              </span>
                              <br />
                              <p className="dvStateHighlightedNoteName">
                                <img
                                  src={rotaClock}
                                  alt="Rota clock inline"
                                  className="rotaClockOpenDvInlineImg"
                                />
                                {dateStringer.dsToTimeStrip(shift.startDs)} -{" "}
                                {dateStringer.dsToTimeStrip(shift.endDs)}
                                {shift.tmw ? " +1d" : ""}
                              </p>
                              {/* {open.tags[0] && (
                                  <div className="dayViewOpenTagsRow">
                                    <div className="dvOpenTag lilacColours_">
                                      {open.tags[0]}
                                    </div>
                                    {open.tags.length > 1 && (
                                      <div className="dvOpenTag lilacColours_">
                                        +{open.tags.length - 1}
                                      </div>
                                    )}
                                  </div>
                                )} */}
                              <div className={`dvShiftNameRow `}>
                                <img
                                  src={rotaPerson}
                                  className="dvShPP"
                                  alt={shift.fName}
                                />{" "}
                                <p className="dvShName">
                                  {shift.fName} {shift.lName}
                                </p>
                              </div>
                              <div className={`dvShiftNameRow  `}>
                                <img
                                  src={briefcase}
                                  className="dvShPP"
                                  alt={shift.jobTitle}
                                />{" "}
                                <p className="dvShName bbcada">
                                  {shift.jobTitle || "No job title"}
                                </p>
                              </div>
                              {shift.locationName ? (
                                <div className={`dvShiftNameRow  `}>
                                  <img
                                    src={pin}
                                    className="dvShPP x48583333"
                                    alt={shift.locationName}
                                  />{" "}
                                  <p className="dvShName bbcada">
                                    {shift.locationName}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {[...dvState.leave, ...dvState.absence] &&
                    Array.isArray([...dvState.leave, ...dvState.absence]) &&
                    [...dvState.leave, ...dvState.absence].length > 0 && (
                      <div className="dayViewRow">
                        <div className="dayViewRowLeft">
                          <p className="dayViewRowLeftTitle">Leave & absence</p>
                        </div>{" "}
                        <div className="dayViewRowRight">
                          {[...dvState.leave, ...dvState.absence].map(
                            (item) => {
                              return (
                                <div
                                  className={`dvStateHighlight ${
                                    item.type === "leave"
                                      ? "dvItemLeave"
                                      : "dvItemAbsence"
                                  }`}
                                  onClick={() => {
                                    if (item.mine) {
                                      memoVals.setShowUserReqFromNav(
                                        (x) => item.reqID
                                      );
                                    }
                                  }}
                                >
                                  <div className="dvLeaveItemLeft">
                                    <img
                                      src={
                                        item.profPicUrl ||
                                        profilePicturePlaceholder
                                      }
                                      alt={item.fName}
                                      className="dvLeaveItemPP"
                                    />
                                    <img
                                      src={
                                        item.type === "absence"
                                          ? streamCross
                                          : rotaPlane
                                      }
                                      alt="Leave / absence"
                                      className={`openShiftBlockDayViewImg ${
                                        item.type === "leave"
                                          ? "openShiftBlockDayViewImgLeave"
                                          : "openShiftBlockDayViewImgAbs"
                                      }`}
                                    />{" "}
                                  </div>
                                  <div
                                    className={`dvStateHighlightedNote x23904943342 ${
                                      item.type === "absence"
                                        ? "x23904943342Abs"
                                        : ""
                                    }`}
                                  >
                                    <p className={`dvStateLeaveName `}>
                                      {item.mine
                                        ? `My ${
                                            item.type === "leave"
                                              ? "leave"
                                              : "absence"
                                          }`
                                        : item.fName}{" "}
                                      {item.mine ? "" : item.lName}
                                    </p>
                                    <br />
                                    <span className="weight600">
                                      {item.typeName}
                                    </span>
                                    <br />
                                    {item.mine ? (
                                      item.durMins === 0 || !item.dayNum ? (
                                        <p className="dvStateHighlightedNoteName x2849844">
                                          Non-working day
                                        </p>
                                      ) : (
                                        <p className="dvStateHighlightedNoteName x2849844">
                                          Day {item.dayNum} / {item.totDays}{" "}
                                          <span className="x328432984894">
                                            {dateStringer.formatMinsDurationToHours(
                                              item.durMins
                                            )}
                                          </span>
                                        </p>
                                      )
                                    ) : item.dayNum > 0 ? (
                                      <p className="dvStateHighlightedNoteName x2849844">
                                        Day {item.dayNum} / {item.totDays}
                                      </p>
                                    ) : (
                                      <p className="dvStateHighlightedNoteName x2849844">
                                        {item.type === "leave"
                                          ? "Leave period"
                                          : "Absence period"}
                                      </p>
                                    )}
                                    {/* {open.tags[0] && (
                                    <div className="dayViewOpenTagsRow">
                                      <div className="dvOpenTag lilacColours">
                                        {open.tags[0]}
                                      </div>
                                      {open.tags.length > 1 && (
                                        <div className="dvOpenTag lilacColours">
                                          +{open.tags.length - 1}
                                        </div>
                                      )}
                                    </div>
                                  )} */}
                                    {/* <div
                                    className={`dvOpenBidsRow ${
                                      open.bids ? "dvOpenBidsRowBlue" : ""
                                    }`}
                                  >
                                    {open.bids === 0
                                      ? "No claims yet"
                                      : `${open.bids} claimer${
                                          open.bid === 1 ? "" : "s"
                                        }`}{" "}
                                    {open.meBid && (
                                      <span className="dvBidMe">incl. you</span>
                                    )}
                                  </div> */}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}

                  {dvState.opens.length > 0 && (
                    <div className="dayViewRow">
                      <div className="dayViewRowLeft">
                        <p className="dayViewRowLeftTitle">Open shifts</p>
                      </div>{" "}
                      <div className="dayViewRowRight">
                        {dvState.opens.map((open) => {
                          return (
                            <div
                              className="dvStateHighlight"
                              onClick={() => {
                                memoVals.setShowUserOpenShiftModal(
                                  (x) => open.openID
                                );
                              }}
                            >
                              <img
                                src={openShiftBlockUser}
                                alt="Open shift"
                                className="openShiftBlockDayViewImg"
                              />{" "}
                              <div className="dvStateHighlightedNote x43895489459822">
                                <span className="weight600">
                                  Open{" "}
                                  {open.openType === "til"
                                    ? "overtime (time in lieu)"
                                    : open.openType}
                                </span>
                                <br />
                                <p className="dvStateHighlightedNoteName marginTop3">
                                  <img
                                    src={rotaClock}
                                    alt="Rota clock inline"
                                    className="rotaClockOpenDvInlineImg"
                                  />
                                  {dateStringer.dsToTimeStrip(open.startDs)} -{" "}
                                  {dateStringer.dsToTimeStrip(open.endDs)}
                                </p>
                                {/* {open.tags[0] && (
                                  <div className="dayViewOpenTagsRow">
                                    <div className="dvOpenTag lilacColours_">
                                      {open.tags[0]}
                                    </div>
                                    {open.tags.length > 1 && (
                                      <div className="dvOpenTag lilacColours_">
                                        +{open.tags.length - 1}
                                      </div>
                                    )}
                                  </div>
                                )} */}
                                <div
                                  className={`dvOpenBidsRow ${
                                    open.bids ? "dvOpenBidsRowBlue" : ""
                                  }`}
                                >
                                  {open.bids === 0
                                    ? "No bids yet"
                                    : `${open.bids} bid${
                                        open.bid === 1 ? "" : "s"
                                      }`}{" "}
                                  {open.meBid && (
                                    <span className="dvBidMe">incl. you</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div
              className={`teamSchedInnerCont ${
                state.loading ? "fadeSched" : ""
              }`}
            >
              <div
                className={`${
                  state.sched.length > -1
                    ? `nameColContainer ${
                        memoVals.mob ? "nameColContainerMob" : ""
                      } ${collapse ? "collapsedNameCol" : ""}`
                    : "none"
                } ${state.jobTitles.length === 0 ? "none" : ""} ${
                  !memoVals.mob ? "w130px" : ""
                }`}
              >
                <div className="dayTopUnit a1DayTopUnit">
                  {!collapse ? (
                    <p
                      className={`myRotaDayViewBtn ${
                        memoVals.mob ? "myRotaDayViewBtnMob" : ""
                      } ${memoVals.standalone || memoVals.mob ? "invis" : ""}`}
                      onClick={() => {
                        setDayView(!dayView);
                        if (dayView) {
                          setDayView(false);

                          if (
                            dateStringer.getMonWeekOfDs(selectedDayViewDs) !==
                            selectedMonWeek
                          ) {
                            setSelectedMonWeek(
                              dateStringer.getMonWeekOfDs(selectedDayViewDs)
                            );
                          }
                        } else {
                          let todayDs = new Date();
                          todayDs.setHours(0, 0, 0, 0);
                          let dsY = dateStringer.createStringFromTimestamp(
                            todayDs.getTime()
                          );
                          setSelectedDayViewDs(dsY);
                          setSelectedMonWeek(dateStringer.getMonWeekOfDs(dsY));
                          setDayView(true);
                        }
                      }}
                    >
                      {!memoVals.mob ? (
                        <img
                          src={calicon}
                          alt="Calendar view"
                          className="dayViewCalImg"
                        />
                      ) : (
                        ""
                      )}
                      {memoVals.mob
                        ? dayView
                          ? "Week"
                          : "Day"
                        : dayView
                        ? "Week view"
                        : "Day view"}
                    </p>
                  ) : (
                    ""
                  )}
                  <img
                    src={collapse ? collapseIcon : collapseIcon}
                    onClick={() => {
                      setCollapse(!collapse);
                    }}
                    className={`${
                      collapse
                        ? "collapseIcon"
                        : `reverseCollapseIcon ${
                            memoVals.mob
                              ? "reverseCollapseIconUserMob"
                              : "reverseCollapseIconUser"
                          }`
                    } ${
                      !memoVals.mob && !collapse ? "revIconToTheRight" : ""
                    } ${memoVals.mob && dayView ? "invis" : ""}`}
                    alt="Collapse"
                  />
                </div>
                <div
                  className={`${showSpecDays() ? "specDayRow" : "none"}`}
                ></div>
                {highlightedComments[0] && !loading ? (
                  <div className="highlightedCommentsA1 noBorderRight"></div>
                ) : (
                  ""
                )}
                {!memoVals.standalone && (
                  <div
                    className={`openShiftsNameDiv2 ${
                      collapse ? "collapseOpenTxt" : ""
                    } ${
                      (state.sunOpens.length === 0 &&
                        state.monOpens.length === 0 &&
                        state.tueOpens.length === 0 &&
                        state.wedOpens.length === 0 &&
                        state.thuOpens.length === 0 &&
                        state.friOpens.length === 0 &&
                        state.satOpens.length === 0) ||
                      state.pasteLoading
                        ? "zeroOpensHeight"
                        : ""
                    }`}
                  >
                    <span
                      className={`${
                        state.sunOpens.length === 0 &&
                        state.monOpens.length === 0 &&
                        state.tueOpens.length === 0 &&
                        state.wedOpens.length === 0 &&
                        state.thuOpens.length === 0 &&
                        state.friOpens.length === 0 &&
                        state.satOpens.length === 0
                          ? `noShiftsOpenSpanTxt ${
                              !memoVals.mob && !collapse
                                ? "openShiftsToTheRight"
                                : ""
                            }`
                          : ""
                      } ${
                        memoVals.mob
                          ? `opens33 ${collapse ? "opens44" : ""}`
                          : ""
                      }`}
                    >
                      Open shifts
                    </span>
                    {collapse ? (
                      ""
                    ) : (
                      <img
                        src={navyTri}
                        alt="Open shifts"
                        className="openShiftsTriSpan"
                      />
                    )}
                  </div>
                )}
                {!memoVals.standalone && (
                  <div
                    className={`limitRowInitialCol limitRowInitialColUser ${
                      collapse ? "collapseLimitTitle" : ""
                    } userRotaAvailColours ${
                      state.hideSchedLimits ? "hideUserSchedLimits" : ""
                    } ${memoVals.mob ? "limitRowInitialColMob" : ""}`}
                  >
                    <p
                      className={`limitTabTitle ${
                        memoVals.mob ? "limitTabTitleMob" : ""
                      }`}
                    >
                      {collapse ? " Lim." : "Leave limits"}
                    </p>{" "}
                  </div>
                )}
                {generateNames}
              </div>
              <div className="teamSchedSplit"></div>
              <div
                className={`teamSchedContent x248489458 ${
                  memoVals.mob
                    ? "mobMyRotaWidthSched"
                    : `desktopMyRotaWidthSched ${
                        memoVals.standalone
                          ? `desktopMyRotaWidthSchedStandalone ${
                              collapse
                                ? "desktopMyRotaWidthSchedStandaloneCollapsed"
                                : ""
                            }`
                          : "desktopMyRotaWidthSched"
                      }`
                }`}
                ref={containerRef}
                id="teamSchedContent"
              >
                {generateContent}
              </div>
            </div>
          )}
        </div>
      </div>
      {showShare && (
        <div
          className="clockOnModalUnderlay"
          onClick={() => {
            setPassword(originalPassword);
            setShowShare(false);
            setPasswordEdited(false);
          }}
        >
          <div
            className={`dayCommentsModal ${
              memoVals.mobModal ? "mobModalShoulder" : "maxViewZoneModalWidth"
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="mySwapsHeader spaceBetween" onClick={() => {}}>
              <img
                src={navyClose}
                alt="Close"
                className="closeMySwapsModalImg"
                onClick={() => {
                  setPassword(originalPassword);
                  setPasswordEdited(false);

                  setShowShare(false);
                }}
              />
              <p>Share rota week</p>

              {/* <p></p> */}
            </div>
            <div className="dayCommentsModalBody">
              <div className="gpsZoneRow noBorder">
                <p className="lonLatTitles">Team</p>

                <p className={`lonLatVal`}>{teamName}</p>
              </div>{" "}
              <div className="gpsZoneRow">
                <p className="lonLatTitles">Rota week</p>

                <p className={`lonLatVal`}>
                  {dateStringer.printedDateFromDs(selectedMonWeek)} -{" "}
                  {dateStringer.printedDateFromDs(lastDs, true)}
                </p>

                {!shareLink && (
                  <p className="linkNotActive">
                    Click the 'Get link' button below to generate a link to a
                    stripped-down version of this rota to be shared with people
                    outside of {teamName}. Viewers will not need a FlowRota
                    account to view it.
                  </p>
                )}
              </div>{" "}
              {shareLink && (
                <div className="gpsZoneRow">
                  {/* <p className="shareRotaInfoWarn">
                    The link below will send the visitor to a stripped-down
                    version of this week's rota. Visitors will see shifts,
                    absences and leave, and the week rota will be available to
                    the anyone with the link - not just logged-in users of this
                    team.
                    <br />
                    <br />
                    It is therefore recommended that you set a password.{" "}
                  </p> */}
                  <p
                    className="lonLatTitles"
                    onClick={() => {
                      console.log({ password });
                    }}
                  >
                    Password (optional)
                  </p>

                  <div className="sharePassDiv">
                    <input
                      type="text"
                      className="shareRotaPassInput"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        // if (showPassword) {
                        //   console.log(e.target.value);
                        // } else {
                        //   console.log(hidePass(password));
                        // }
                        setPasswordEdited(true);
                      }}
                    />
                    {/* <img
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      src={view}
                      alt="Show password"
                      className={`showSharePassImg ${
                        showPassword ? "showPassViewShare" : ""
                      }`}
                    /> */}
                    {passwordEdited ? (
                      <img
                        src={whiteTick}
                        alt="Show password"
                        className={`showSharePassImg saveRotaPass`}
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/update-rota-link-password`,
                              {
                                monWeek: selectedMonWeek,
                                teamID: selectedTeam,
                                data: password,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                setPassword(response.data.password);
                                setOriginalPassword(response.data.password);
                                setPasswordEdited(false);
                              }
                            });
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              {shareLink && (
                <div className="gpsZoneRow shareLinkBg">
                  <p className="lonLatTitles">Sharable link</p>

                  <div className="sharePassDiv">
                    <p className="sharablelink">
                      {appURL}/fr_{shareLink}
                    </p>
                    {copied ? (
                      <p
                        className="copiedLink"
                        onClick={() => {
                          setCopied(false);
                        }}
                      >
                        Copied
                      </p>
                    ) : (
                      ""
                    )}
                    {/* <img
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      src={view}
                      alt="Show password"
                      className={`showSharePassImg p183141 $`}
                    /> */}
                  </div>
                  <div className="shareRotaIconsDiv">
                    <a
                      className="shareRotaShareBtn"
                      href={`mailto:?subject=${
                        checkUserState.fName
                      } has shared ${dateStringer.possession(
                        teamName
                      )} rota for ${dateStringer.printedDateWithYrFromDWithoutDay(
                        selectedMonWeek
                      )} with you.&body=Here is the team rota for ${teamName} commencing ${dateStringer.printedDateFromDs(
                        selectedMonWeek
                      )}. ${appURL}/fr_${shareLink}`}
                    >
                      Email{" "}
                      <img
                        src={emailWhite}
                        alt="Email"
                        className="shareRotaIconImg emailWhite"
                      />
                    </a>
                    <a
                      className="shareRotaShareBtn"
                      href={`https://wa.me/?text=Here is the team rota for ${teamName} commencing ${dateStringer.printedDateFromDs(
                        selectedMonWeek
                      )}. ${appURL}/fr_${shareLink}`}
                    >
                      WhatsApp{" "}
                      <img
                        src={whatsappWhite}
                        alt="Email"
                        className="shareRotaIconImg"
                      />
                    </a>
                    <p
                      className="showSharePassImg p183141"
                      onClick={() => {
                        const copyToClipboard = async (textToCopy) => {
                          try {
                            if (navigator.clipboard) {
                              // Use Clipboard API if available
                              await navigator.clipboard.writeText(textToCopy);
                              setCopied(true);

                              console.log(
                                "Text successfully copied to clipboard!"
                              );
                            } else {
                              // Fallback for browsers that do not support Clipboard API
                              const textArea =
                                document.createElement("textarea");
                              textArea.value = textToCopy;
                              document.body.appendChild(textArea);
                              textArea.select();
                              document.execCommand("copy");
                              document.body.removeChild(textArea);
                              setCopied(true);

                              console.log(
                                "Text successfully copied to clipboard (fallback)"
                              );
                            }
                          } catch (err) {
                            console.error(
                              "Unable to copy text to clipboard:",
                              err
                            );
                          }
                        };

                        copyToClipboard(`${appURL}/fr_${shareLink}`);

                        setTimeout(() => {
                          setCopied(false);
                        }, 4000);
                      }}
                    >
                      Copy
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="dayCommentsModalFooter">
              <div className="viewMapsAndSaveDiv">
                {linkExists && (
                  <p
                    className="shareRotaCta shareRotaBack x384829x"
                    onClick={() => {
                      setSureDestroy(true);
                    }}
                  >
                    Destroy link
                  </p>
                )}

                {sureDestroy ? (
                  <div
                    className="areYouSureModalUnderlay"
                    onClick={() => {
                      setSureDestroy(false);
                    }}
                  >
                    <div
                      className="formCreatedModal"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <p className="overlapsRenTxt">
                        Are you sure you want to destory this link?
                        <br />
                        <br />
                        You can generate a new link at any time.
                      </p>
                      <div className="areYouModalBtnsDiv">
                        <p
                          className="areYouSureModalYesBtn"
                          onClick={() => {
                            axios
                              .post(
                                `${serverURL}/destroy-rota-link`,
                                {
                                  monWeek: selectedMonWeek,
                                  teamID: selectedTeam,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message.includes("logout")) {
                                  window.location.href = "/signin";
                                }
                                if (response.data.message === "success") {
                                  setSureDestroy(false);
                                  if (shareLink) {
                                    setShareLink(false);
                                    setLinkExists(false);
                                    setPassword("");
                                    setOriginalPassword("");
                                  }

                                  if (shareEmail) {
                                    setShareEmail(false);
                                  }
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }}
                        >
                          Yes
                        </p>
                        <p
                          className="areYouModalNoBtn"
                          onClick={() => {
                            setSureDestroy(false);
                          }}
                        >
                          No
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {!linkExists && (shareLink || shareEmail) ? (
                  <p
                    className="shareRotaCta shareRotaBack"
                    onClick={() => {
                      if (shareLink) {
                        setShareLink(false);
                      }

                      if (shareEmail) {
                        setShareEmail(false);
                      }
                    }}
                  >
                    Back
                  </p>
                ) : (
                  ""
                )}
                {!shareLink && !shareEmail && (
                  <p
                    className="shareRotaCta"
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/create-rota-link`,
                          {
                            monWeek: selectedMonWeek,
                            teamID: selectedTeam,
                            password,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setShareLink(response.data.shortenedLink);
                            setPassword(response.data.password);
                            setLinkExists(true);
                          }
                        });
                    }}
                  >
                    Get link
                  </p>
                )}
                {!shareLink ? (
                  <p className="linkNotYetActiveIndicate">No link yet</p>
                ) : (
                  <p className="linkNotYetActiveIndicate linkActive">
                    Link active
                  </p>
                )}
                {/* <p
                  className="shareRotaCta shareRotaBack x248928742"
                  onClick={() => {}}
                >
                  Notify team
                </p> */}
                {/* {!shareLink && !shareEmail && (
                  <p
                    className="shareRotaCta marginLeft8"
                    onClick={() => {
                      setShareEmail(true);
                    }}
                  >
                    Share
                  </p>
                )} */}
              </div>

              <p
                className="closeSeeUserIDBtn"
                onClick={() => {
                  setPassword("");
                  setPassword(originalPassword);

                  setPasswordEdited(false);

                  setShowShare(false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      )}
      {showEditSuggest ? (
        <div
          className="areYouSureModalUnderlay"
          onClick={() => {
            setShowEditSuggest(false);
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              You are unable to amend the rota
              <br />
              from 'My rota'. <br />
              <br />
              Go to 'Staff rota' instead to amend, add and remove shifts.
            </p>
            <div className="areYouModalBtnsDiv">
              <NavLink
                className="areYouModalNoBtn staffRotaBtnColours_ pinkColours"
                to="/team-rota"
                onClick={() => {
                  memoVals.setCalPage((x) => "team-schedule");
                }}
              >
                Staff rota
              </NavLink>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setShowEditSuggest(false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default UserSchedule;
