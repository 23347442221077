import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";
import serverURL from "../../../serverURL";
import websiteURL from "../../../websiteURL";

import dateStringer from "../../../tools/dateStringer";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import plus from "../../../img/general/plus.svg";
import navyClose from "../../../img/general/navyClose.svg";
import unlinked from "../../../img/general/unlink.svg";
import checkbox1 from "../../../img/general/checkboxInstall1.svg";
import checkbox4 from "../../../img/general/checkboxInstall4.svg";

import checkbox2 from "../../../img/general/checkboxInstall2purp.svg";
import noShiftScheduled from "../../../img/general/noShiftScheduled.svg";
import whiteComment from "../../../img/general/whiteComment.svg";
// import rotaClockLate from "../../../img/general/rotaClockLate.svg";

import rotaClock from "../../../img/general/rotaClock.svg";
import rotaCoffee from "../../../img/general/rotaCoffee.svg";
import rotaDate from "../../../img/general/rotaDate.svg";
import whiteTick from "../../../img/general/whiteTick.svg";
import rotaDur from "../../../img/general/rotaDur.svg";

import navyFilter from "../../../img/general/navyFilter.svg";
import popup from "../../../img/general/popupNavy.svg";
import shiftBlockNavy from "../../../img/general/shiftBlockNavy.svg";
import profilePicturePlaceholder from "../../../img/general/profilePicturePlaceholder.svg";
import teamIcon from "../../../img/general/rotaTeam.svg";

import "../../../styles/calendar.css";

import ShiftsHeader from "./ShiftsHeader";
import HoursDatabox from "./HoursDataBox";
import ClockOns from "./ClockOns";
import TeamHoursDataBox from "./TeamHoursDataBox";
import { data } from "jquery";

const TeamClockOns = ({ sideBoxData, my, setMy }) => {
  const { setShiftsPage } = useContext(CalendarContext);
  const { checkUserState } = useContext(UserContext);

  const { setCalPage } = useContext(CalendarContext);
  const {
    setPage,
    availableMonths,
    device,
    setClockOnID,
    selectedShiftTeam,
    setSelectedShiftTeam,
    reloadTeamClockOns,
    changedClockCard,
    setIndicate,
    availableTeamsForShifts,
    setShowEditUser,
    countData,
    setCountData,
  } = useContext(DataContext);
  const { mob, full, fullButNarrow } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setShiftsPage, //
      setCalPage, //
      setPage, //
      availableMonths, //
      device, //
      setClockOnID, //
      selectedShiftTeam, //
      setSelectedShiftTeam, //
      reloadTeamClockOns, //
      changedClockCard, //
      setIndicate, //
      availableTeamsForShifts, //
      mob, //
      full, //
      fullButNarrow, //
      checkUserState,
      setShowEditUser,
    }),
    [
      setShiftsPage, //
      setCalPage, //
      setPage, //
      availableMonths, //
      device, //
      setClockOnID, //
      selectedShiftTeam, //
      setSelectedShiftTeam, //
      reloadTeamClockOns, //
      changedClockCard, //
      setIndicate, //
      availableTeamsForShifts, //
      mob, //
      full, //
      fullButNarrow, //
      checkUserState,
      setShowEditUser,
    ]
  );

  let [recordLateClockOnIDs, setRecordLateClockOnIDs] = useState([]);
  let [recordLateUnpaidClockOnIDs, setRecordLateUnpaidClockOnIDs] = useState(
    []
  );

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let imgPreload = (
    <div className="imagesHidden">
      {/* Previously added images */}
      {/* ... */}
      {/* Additional images */}
      <img
        src={navyClose}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      {/* <img
        src={rotaClockLate}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "} */}
      <img
        src={whiteTick}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={checkbox4}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaClock}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaCoffee}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={whiteComment}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaDate}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={plus}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unlinked}
        alt="Unlinked"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDur}
        alt="Unlinked"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox1}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox2}
        alt="Checkbox Install 2 Purple"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={noShiftScheduled}
        alt="No Shift Scheduled"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyFilter}
        alt="Filter White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup}
        alt="Popup"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftBlockNavy}
        alt="Clock Card"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 18) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let [showUnlinkedInfoModal, setShowUnlinkedInfoModal] = useState(false);

  useEffect(() => {
    // clean not needed
    setSelectedNameUserID("allNames"); // egypt
  }, [memoVals.selectedShiftTeam]);

  let [selectedNameUserID, setSelectedNameUserID] = useState("allNames");
  let [nameObjects, setNameObjects] = useState([]);
  let [selectedDay, setSelectedDay] = useState("allDays");
  let [reverse, setReverse] = useState(false);
  let [clockOns, setClockOns] = useState([]);

  let [showShifts, setShowShifts] = useState(true);
  let [showTils, setShowTils] = useState(true);
  let [showOvertimes, setShowOvertimes] = useState(true);

  let [days, setDays] = useState([]);

  let [showFilter, setShowFilter] = useState(false);

  let [awaitingClockOnIDs, setAwaitingClockOnIDs] = useState([]);
  let [awaitingClocks, setAwaitingClocks] = useState([]);

  let [showUnlinked, setShowUnlinked] = useState(false);
  let [unlinkedExist, setUnlinkedExist] = useState(0);

  let [notYetClockedOff, setNotYetClockedOff] = useState([]);
  let [approveLoading, setApproveLoading] = useState(false);

  useEffect(() => {
    // clean not needed

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && dateStringer.cleanParam(params.clockCard)) {
      // open clock card here
      memoVals.setClockOnID((x) => {
        return {
          clockOnID: dateStringer.cleanParam(params.clockCard),
          isMgr: true,
        };
      });
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  let clocksScrollBox = useRef();
  useEffect(() => {
    // clean not needed

    clocksScrollBox.current.scrollTop = 0;
  }, [memoVals.selectedShiftTeam]);

  useEffect(() => {
    // clean not needed

    let count = 0;
    clockOns.forEach((c) => {
      if (c.unlinked) {
        count++;
      }
    });
    setUnlinkedExist(count);
  }, [clockOns]);

  // let [currMy, setCurrMy] = useState("");

  // useEffect(() => {
  //   // clean not needed

  //   let today = new Date();
  //   let monthNum = today.getMonth();
  //   if (monthNum < 10) {
  //     monthNum = `0${monthNum}`;
  //   } else {
  //   }
  //   let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

  //   setMy(`${monthNum}${yearNum}`);
  //   setCurrMy(`${monthNum}${yearNum}`);
  // }, []);

  let [filteredUserHasOutstanding, setFilteredUserHasOutstanding] =
    useState(false);
  useEffect(() => {
    // clean not needed

    setSelectedDay("allDays");
    setSelectedNameUserID("allNames");
  }, [my]);

  let [reload, setReload] = useState(false);

  useEffect(() => {
    // clean not needed

    if (memoVals.changedClockCard) {
      let liveArr = [];
      clockOns.forEach((c) => {
        if (c.clockOnID === memoVals.changedClockCard.clockOnID) {
          if (
            !memoVals.changedClockCard.deleting &&
            !memoVals.changedClockCard.justApproved
          ) {
            liveArr.push(memoVals.changedClockCard);
          }
        } else {
          liveArr.push(c);
        }
      });

      let awaitingArr = [];

      awaitingClocks.forEach((c) => {
        if (c.clockOnID === memoVals.changedClockCard.clockOnID) {
          if (memoVals.changedClockCard.justApproved) {
            let newArr = [];
            awaitingClockOnIDs.forEach((id) => {
              if (memoVals.changedClockCard.clockOnID !== id) {
                newArr.push(id);
              }
            });

            setAwaitingClockOnIDs(newArr);
            liveArr.push(memoVals.changedClockCard);
          } else {
            if (!memoVals.changedClockCard.deleting) {
              awaitingArr.push(memoVals.changedClockCard);
            }
          }
        } else {
          awaitingArr.push(c);
        }
      });

      if (memoVals.changedClockCard.justCreated) {
        if (memoVals.changedClockCard.approved) {
          liveArr.push(memoVals.changedClockCard);
        } else {
          awaitingArr.push(memoVals.changedClockCard);
        }
      }

      setClockOns(liveArr);
      setAwaitingClocks(awaitingArr);
    }
  }, [memoVals.changedClockCard]);

  useEffect(() => {
    clockOns.forEach((c) => {
      if (c.userID === selectedNameUserID && c.approved === null) {
        setFilteredUserHasOutstanding(true);
      }
    });
  }, [selectedNameUserID]);

  let [currMy, setCurrMy] = useState(
    dateStringer.getMyFromTs(new Date().getTime())
  );

  let [users, setUsers] = useState([]);
  let [selectedUserID, setSelectedUserID] = useState("");

  let generateTeamsSelectBar = users.map((user) => {
    return (
      <option key={user.userID} value={user.userID}>
        {user.fName} {user.lName[0]}
      </option>
    );
  });

  useEffect(() => {
    if (!users[0]) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-users-user-can-manage`,
          { someData: 1 },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setUsers(
              response.data.usersArr.sort((a, b) => {
                return a.fName.localeCompare(b.fName);
              })
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, []);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (my) {
      setLoading(true);
      axios
        .post(
          `${serverURL}/get-mgr-clock-ons`,
          {
            my: my,
            teamID: memoVals.selectedShiftTeam || "allStaff",
            awaitingDecision: false,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setReverse(response.data.reverse);
            let names = [];
            let userIDs = [];
            let dayNums = [];
            if (response.data.clockOns && response.data.clockOns[0]) {
              let unlinkedCounter = 0;
              response.data.clockOns.forEach((c) => {
                if (c.unlinked) {
                  unlinkedCounter++;
                }
                if (c.startDs && c.userID) {
                  if (!userIDs.includes(c.userID)) {
                    names.push({
                      fName: c.fName,
                      lName: c.lName,
                      userID: c.userID,
                    });
                    userIDs.push(c.userID);
                  }
                }

                let ds = c.startDs;

                if (
                  ds &&
                  ds[0] === "Y" &&
                  !dayNums.includes(parseInt(`${ds.substr(9, 2)}`))
                ) {
                  dayNums.push(parseInt(`${ds.substr(9, 2)}`));
                }
              });

              setUnlinkedExist(unlinkedCounter);

              dayNums.sort(function (a, b) {
                return a - b;
              });
              setDays(dayNums);
              setNameObjects(names);
              setClockOns(response.data.clockOns);

              if (my !== currMy) {
                setDataLoaded(true);
                setLoading(false);
                setAwaitingClockOnIDs([]);
                setAwaitingClocks([]);
                setNotYetClockedOff([]);
              }
            } else {
              setClockOns([]);
            }
            if (my === currMy) {
              axios
                .post(
                  `${serverURL}/get-mgr-clock-ons`,
                  {
                    my: my,
                    teamID: memoVals.selectedShiftTeam || "allStaff",
                    awaitingDecision: true,
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response1) => {
                  if (response1.data.message.includes("logout")) {
                    window.location.href = "/signin";
                  }
                  if (response1.data.message === "success") {
                    setAwaitingClockOnIDs(
                      response1.data.clocksAwaitingDecision
                    );
                    setAwaitingClocks(response1.data.clockOns);
                    setNotYetClockedOff(response1.data.notYetClockedOff);
                  }
                  if (dataLoaded) {
                    setDataLoaded(false);
                  }
                  setDataLoaded(true);
                });
            } else {
              if (dataLoaded) {
                setDataLoaded(false);
              }
              setDataLoaded(true);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.selectedShiftTeam, my, reload, memoVals.reloadTeamClockOns]);

  clockOns.sort(function (a, b) {
    if (!reverse) {
      return b.clockOnTs - a.clockOnTs;
    } else {
      return a.clockOnTs - b.clockOnTs;
    }
  });

  awaitingClocks.sort(function (a, b) {
    if (!reverse) {
      return b.clockOnTs - a.clockOnTs;
    } else {
      return a.clockOnTs - b.clockOnTs;
    }
  });

  let generateMonthOptions = useMemo(() => {
    return memoVals.availableMonths.map((month) => {
      if (dateStringer.getTsFromMy(month) <= new Date().getTime()) {
        let formatMonth = "January";
        let formatYear = month.substr(2, 2);
        if (month.substr(0, 2) === "00") {
          formatMonth = 1 + 1 === 3 ? "January" : "Jan";
        }
        if (month.substr(0, 2) === "01") {
          formatMonth = 1 + 1 === 3 ? "February" : "Feb";
        }
        if (month.substr(0, 2) === "02") {
          formatMonth = 1 + 1 === 3 ? "March" : "Mar";
        }
        if (month.substr(0, 2) === "03") {
          formatMonth = 1 + 1 === 3 ? "April" : "Apr";
        }
        if (month.substr(0, 2) === "04") {
          formatMonth = 1 + 1 === 3 ? "May" : "May";
        }
        if (month.substr(0, 2) === "05") {
          formatMonth = 1 + 1 === 3 ? "June" : "Jun";
        }
        if (month.substr(0, 2) === "06") {
          formatMonth = 1 + 1 === 3 ? "July" : "Jul";
        }
        if (month.substr(0, 2) === "07") {
          formatMonth = 1 + 1 === 3 ? "August" : "Aug";
        }
        if (month.substr(0, 2) === "08") {
          formatMonth = 1 + 1 === 3 ? "September" : "Sep";
        }
        if (month.substr(0, 2) === "09") {
          formatMonth = 1 + 1 === 3 ? "October" : "Oct";
        }
        if (month.substr(0, 2) === "10") {
          formatMonth = 1 + 1 === 3 ? "November" : "Nov";
        }
        if (month.substr(0, 2) === "11") {
          formatMonth = 1 + 1 === 3 ? "December" : "Dec";
        }

        return (
          <option key={month} value={month}>
            {formatMonth} {formatYear}
          </option>
        );
      }
    });
  }, [
    memoVals.availableMonths,
    showUnlinked,
    showFilter,
    showOvertimes,
    showTils,
    showShifts,
    my,
    memoVals.selectedShiftTeam,
    window.innerWidth,
    memoVals.mob,
  ]);

  let generateDayOptions = useMemo(() => {
    return days.map((day) => {
      let nth = "th";
      if (day === 2 || day === 22) {
        nth = "nd";
      }
      if (day === 3 || day === 23) {
        nth = "rd";
      }
      if (day === 1 || day === 21 || day === 31) {
        nth = "st";
      }
      return (
        <option key={Math.random()} value={day}>
          {day}
          {nth}
        </option>
      );
    });
  }, [
    days,
    ,
    showUnlinked,
    showFilter,
    showOvertimes,
    showTils,
    showShifts,
    my,
    memoVals.selectedShiftTeam,
  ]);

  // set calendarSubBar:
  useEffect(() => {
    // clean not needed
    document.title = "Team clock cards - FlowRota";

    memoVals.setCalPage((x) => "team-shifts");
    memoVals.setShiftsPage((x) => "team-clock-ons");
    memoVals.setPage((x) => "team-clock-ons");
  }, []);
  // shiftsPage, setShiftsPage:
  // shifts, stats, availability, team-shifts

  let [selectedClock, setSelectedClock] = useState("");

  let shortenTeamName = (val, limit) => {
    if (val.length > limit) {
      return `${val.substr(0, limit - 2)}...`;
    } else {
      return val;
    }
  };

  let generateClocksAwaiting = useMemo(() => {
    // if (!showUnlinked) {
    return awaitingClocks.map((clock, i) => {
      let lateMins = 0;
      if (clock.scheduled && clock.scheduledStart) {
        let schedStartTs = dateStringer.createTimestampFromString(
          clock.scheduledStart
        );
        let clockedStartTs = dateStringer.createTimestampFromString(
          clock.startDs
        );
        if (schedStartTs < clockedStartTs) {
          lateMins = (clockedStartTs - schedStartTs) / 1000 / 60;
        }
      }

      let dateObj = new Date(
        dateStringer.createTimestampFromString(clock.startDs)
      );
      let dateN = dateObj.getDate();
      if (!showUnlinked && selectedUserID && selectedUserID !== clock.userID) {
      } else {
        if (
          (!showUnlinked || (showUnlinked && clock.unlinked)) &&
          (clock.userID === selectedUserID ||
            selectedNameUserID === "allNames") &&
          (selectedDay === "allDays" || parseInt(selectedDay) === dateN) &&
          ((showShifts && clock.type === "shift") ||
            (showTils && clock.type === "til") ||
            (showOvertimes && clock.type === "overtime")) &&
          (memoVals.selectedShiftTeam === "allStaff" ||
            memoVals.selectedShiftTeam === clock.teamID)
        ) {
          if (clock.startDs && clock.endDs) {
            let calcClockedMins = (ds1, ds2, brkMins) => {
              if (ds1 && ds2) {
                let ds1Ts = dateStringer.createTimestampFromString(ds1);
                let ds2Ts = dateStringer.createTimestampFromString(ds2);

                let msDiff = ds2Ts - ds1Ts;

                let brkMs = brkMins * 60 * 1000;
                let totMs = msDiff - brkMs;

                return totMs / 1000;
              }
            };

            return (
              <div
                key={i}
                className={`${
                  !memoVals.mob ||
                  selectedClock === clock.clockOnID ||
                  2 + 2 === 4
                    ? `mgrClockUnitWrapperOpen ${
                        lateMins > 0 ? "mgrClockUnitWrapperOpenWithLate" : ""
                      } ${lateMins ? "mgrClockUnitWrapperOpenWithLate" : ""}`
                    : "mgrClockUnitWrapperClosed"
                } ${clock.oneOfTwo === "two" ? "x3848848482_" : ""} ${
                  selectedClock === clock.clockOnID || (memoVals.mob && i === 0)
                    ? "pullFirstMobClockUnitUp"
                    : ""
                } animateZoomIn_ ${
                  approveLoading === clock.clockOnID
                    ? "zoomClockCardOutUnit"
                    : ""
                } ${clock.declined ? "opac07" : ""}`}
              >
                <div className="clockNameAndTeamDiv">
                  <div
                    className="mgrClockNameDiv  pendClockName_"
                    onClick={(e) => {
                      e.stopPropagation();

                      memoVals.setShowEditUser((x) => clock.userID);
                    }}
                  >
                    <img
                      src={clock.picUrl || profilePicturePlaceholder}
                      alt={clock.fName}
                      className="itemNameTabImg"
                    />{" "}
                    {clock.fName} {clock.lName}{" "}
                    {clock.you ? <span className="youSpan">(You)</span> : ""}
                    {clock.notes ? (
                      <p
                        className="clockNoteInline fontSize12"
                        onClick={(e) => {
                          e.stopPropagation();
                          memoVals.setClockOnID((x) => {
                            return {
                              clockOnID: clock.clockOnID,
                              isMgr: true,
                              notes: true,
                            };
                          });
                        }}
                      >
                        {" "}
                        <img
                          src={whiteComment}
                          alt="Clock card notes"
                          className="clockNoteImgInline"
                        />
                        x{clock.notes}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div className="mgrClockNameDiv clockTeamNameTopRight">
                    {memoVals.mob
                      ? shortenTeamName(clock.teamName, 10)
                      : shortenTeamName(clock.teamName, 16)}
                  </div> */}
                </div>

                {/* {clock.oneOfTwo && clock.oneOfTwo === "one" ? (
                <div className="clockOnOfTwoBar"></div>
              ) : (
                ""
              )} */}
                <div
                  className={`mgrClockUnit ${
                    lateMins > 0 ? "mgrClockUnitWithLate" : ""
                  } mgrClockUnitPending_ pend_ ${
                    !memoVals.mob ||
                    selectedClock === clock.clockOnID ||
                    2 + 2 === 4
                      ? "noBorderRadiusBottomRight"
                      : ""
                  } leftReqItemPendingBorder_ mgrReqItemPendingBorderMgr_ ${
                    memoVals.mob ? "borderRadius10" : ""
                  }`}
                  key={clock.clockOnID}
                  onClick={() => {
                    memoVals.setClockOnID((x) => {
                      return {
                        clockOnID: clock.clockOnID,
                        isMgr: true,
                      };
                    });
                  }}
                >
                  {!clock.approved && !clock.declined ? (
                    <div
                      className={`unsubmittedTsBlob clockAwaitBlob ${
                        lateMins > 0 ? "clockAwaitBlobWithLate" : ""
                      }`}
                    ></div>
                  ) : (
                    ""
                  )}
                  <div className="teamClockLeftParent">
                    <div className="tilShiftSection0">
                      {/* {!clock.unlinked && (
                        <img
                          src={shiftBlockNavy}
                          alt="Clock card"
                          className="teamClockCardIcon"
                        />
                      )} */}
                      {/* {clock.unlinked && <p className="unlinkedExlaim">!</p>} */}
                    </div>
                    <div
                      className={`tilShiftSection1Clock pendingClockSection1 ${
                        window.innerWidth < 1080 && memoVals.full
                          ? "pendingClockSection1CorrectionMidFull"
                          : ""
                      } ${
                        window.innerWidth < 458
                          ? "pendingClockSection1Under458With"
                          : ""
                      }`}
                    >
                      <p className={`tilShiftDs tilShiftDsFirstRow flexStart`}>
                        {/* x222 */}
                        <img
                          src={rotaDate}
                          alt="Clocked times"
                          className="teamClockRotaClockImg"
                        />
                        {dateStringer.printedDateFromDs(clock.startDs)}
                      </p>
                      <p className={`tilShiftDs tilShiftDsSub flexStart`}>
                        <img
                          src={rotaDur}
                          alt="Clocked times"
                          className="teamClockRotaClockImg teamClockRotaClockImgCoffee"
                        />
                        {dateStringer.formatMinsDurationToHours(
                          calcClockedMins(
                            clock.startDs,
                            clock.endDs,
                            clock.totBrkMins
                          ) / 60,
                          false,
                          true
                        )}
                        {/* {clock.totBrkMins === 60 ||
                        clock.totBrkMins === 120 ||
                        clock.totBrkMins === 180 ||
                        clock.totBrkMins === 240
                          ? dateStringer.formatMinsDurationToHours(
                              clock.totBrkMins,
                              false,
                              true
                            )
                          : `${Math.round(clock.totBrkMins)}m`} */}
                      </p>
                      {lateMins > 0 && (
                        <p
                          className={`tilShiftDs tilShiftDsSub flexStart teamClocksLowerRow ccDec lateClockRow`}
                        >
                          {/* x222 */}
                          <img
                            src={rotaClock}
                            alt="Clocked times"
                            className="teamClockRotaClockImg opac05"
                          />
                          {dateStringer.formatMinsDurationToHours(lateMins)}{" "}
                          late
                        </p>
                      )}

                      <p
                        className={`tilShiftDs tilShiftDsSub flexStart teamClocksLowerRow teamClocksLowerRow2`}
                      >
                        {/* x222 */}
                        <img
                          src={teamIcon}
                          alt="Clocked times"
                          className="teamClockRotaClockImg"
                        />

                        {dateStringer.shorten(
                          clock.teamName,
                          memoVals.mob ? 19 : 25
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`teamClockRightParent ${
                      clock.tmw ? "teamClockRightParentWithTmw" : ""
                    }`}
                  >
                    <div className="tilShiftSection2 pendingClockSection2">
                      {/* <p className="tilShiftDurationTxtTeam">
                        {dateStringer.formatMinsDurationToHours(
                          calcClockedMins(
                            clock.startDs,
                            clock.endDs,
                            clock.totBrkMins
                          ) / 60,
                          false,
                          true
                        )}
                      </p> */}
                      <div className="absTimelineVal ccDivTimeline">
                        <div className="ccDiv">
                          <div className="ccDivMid">
                            <div className="ccDivTs">
                              <p className="ccDivTitle">On</p>

                              <p
                                className={`x1983948894894 ${
                                  lateMins > 0 ? "ba0000" : ""
                                }`}
                              >
                                {" "}
                                {dateStringer.dsToTimeStrip(
                                  clock.startDs,
                                  true
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`absCanDivSplitter ccMgrSplitter`}
                        ></div>

                        <div className="ccDiv ccDiv2">
                          <div className="ccDivMid">
                            <div className="ccDivTs">
                              <p className="ccDivTitle ccDivTitle2">Off</p>

                              <p className="x1983948894894">
                                {" "}
                                {dateStringer.dsToTimeStrip(clock.endDs, true)}
                              </p>
                            </div>
                          </div>
                        </div>
                        {clock.tmw && <p className="ccTmw">+{clock.tmw}d</p>}

                        {/*  */}
                      </div>
                      <p className={`teamClockBreakDurStrip`}>
                        <img
                          src={shiftBlockNavy}
                          alt="Team"
                          className="teamIconHours teamIconHoursLight"
                        />{" "}
                        {clock.type === "til"
                          ? "Overtime"
                          : clock.type === "overtime"
                          ? "Overtime"
                          : "Shift"}
                        {/* {dateStringer.printedDateFromDs(clock.startDs)} */}
                      </p>
                    </div>

                    <div className="itemTongue clockTongue">
                      <p className="tongueTxt clockTongueTxt">Clock card</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    !memoVals.mob ||
                    selectedClock === clock.clockOnID ||
                    2 + 2 === 4
                      ? `mgrClockSubBar ${
                          memoVals.mob ? "mgrClockSubBarMob" : ""
                        }`
                      : "none"
                  } pendClockSub ${
                    lateMins > 0 ? "mgrClockSubBarWithLate" : ""
                  }`}
                >
                  <div
                    className={`clocksSchedSubLeft ${
                      memoVals.mob ? "font12px" : ""
                    } ${clock.unlinked ? "cursorP" : ""}`}
                    onClick={() => {
                      if (clock.unlinked) {
                        setShowUnlinkedInfoModal(true);
                      }
                    }}
                  >
                    <div
                      className={`clockSchedTitle ${
                        memoVals.mob && clock.createdByClock
                          ? "originallyClockedMobFont"
                          : ""
                      } ${clock.createdByClock ? "createdByDynamic" : ""}  ${
                        clock.unlinked || clock.scheduledRemoved ? "ba0000" : ""
                      } ${clock.oneOfTwo === "two" ? "clockedOtTxt_" : ""}`}
                    >
                      <img
                        src={unlinked}
                        className={`${
                          clock.unlinked ? "unlinkedClockImg" : "none"
                        }`}
                        alt="Unlinked"
                      />
                      <img
                        src={
                          !clock.scheduled &&
                          !clock.unlinked &&
                          !clock.scheduledRemoved
                            ? shiftBlockNavy
                            : noShiftScheduled
                        }
                        className={`${
                          clock.unlinked || clock.scheduled
                            ? "none"
                            : "noShiftSchedImg"
                        } ${clock.oneOfTwo === "two" ? "x49578274" : ""} ${
                          !clock.scheduled &&
                          !clock.unlinked &&
                          !clock.scheduledRemoved
                            ? "x8484848482828483"
                            : ""
                        }`}
                        alt="No shift scheduled"
                      />

                      {lateMins > 0 && !clock.unlinked ? (
                        <div
                          className="clockLateBar"
                          onClick={() => {
                            if (
                              memoVals.mob &&
                              !recordLateClockOnIDs.includes(clock.clockOnID)
                            ) {
                              if (
                                recordLateClockOnIDs.includes(clock.clockOnID)
                              ) {
                                console.log(
                                  recordLateClockOnIDs.filter((id) => {
                                    return id !== clock.clockOnID;
                                  })
                                );
                                setRecordLateClockOnIDs(
                                  recordLateClockOnIDs.filter((id) => {
                                    return id !== clock.clockOnID;
                                  })
                                );
                              } else {
                                setRecordLateClockOnIDs([
                                  ...recordLateClockOnIDs,
                                  clock.clockOnID,
                                ]);
                                console.log([
                                  ...recordLateClockOnIDs,
                                  clock.clockOnID,
                                ]);
                              }
                            }
                          }}
                        >
                          <div
                            className="recLateCheck"
                            onClick={() => {
                              if (
                                recordLateClockOnIDs.includes(clock.clockOnID)
                              ) {
                                console.log(
                                  recordLateClockOnIDs.filter((id) => {
                                    return id !== clock.clockOnID;
                                  })
                                );
                                setRecordLateClockOnIDs(
                                  recordLateClockOnIDs.filter((id) => {
                                    return id !== clock.clockOnID;
                                  })
                                );
                              } else {
                                setRecordLateClockOnIDs([
                                  ...recordLateClockOnIDs,
                                  clock.clockOnID,
                                ]);
                                console.log([
                                  ...recordLateClockOnIDs,
                                  clock.clockOnID,
                                ]);
                              }
                            }}
                          >
                            {" "}
                            <img
                              src={checkbox4}
                              alt="Record late"
                              className={`recLateCheckBox ${
                                recordLateClockOnIDs.includes(clock.clockOnID)
                                  ? ""
                                  : "invis"
                              }`}
                            />{" "}
                          </div>
                          <div className="recordLateMain">
                            <p>
                              Record{" "}
                              <span className="colourBa0000">
                                {dateStringer.formatMinsDurationToHours(
                                  lateMins
                                )}
                              </span>{" "}
                              late
                            </p>
                            {recordLateClockOnIDs.includes(clock.clockOnID) ? (
                              <div
                                className="recLateUnpaidSel"
                                onClick={() => {
                                  if (
                                    recordLateUnpaidClockOnIDs.includes(
                                      clock.clockOnID
                                    )
                                  ) {
                                    setRecordLateUnpaidClockOnIDs(
                                      recordLateUnpaidClockOnIDs.filter(
                                        (id) => {
                                          return id !== clock.clockOnID;
                                        }
                                      )
                                    );
                                  } else {
                                    setRecordLateUnpaidClockOnIDs([
                                      ...recordLateUnpaidClockOnIDs,
                                      clock.clockOnID,
                                    ]);
                                  }
                                }}
                              >
                                Unpaid
                                <div className="recLateCheckUnpaid">
                                  <img
                                    src={checkbox4}
                                    alt="Record late"
                                    className={`recLateCheckBoxUnpaid ${
                                      recordLateUnpaidClockOnIDs.includes(
                                        clock.clockOnID
                                      )
                                        ? ""
                                        : "invis"
                                    }`}
                                  />{" "}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {clock.scheduled
                        ? `${
                            clock.createdByClock
                              ? "Originally clocked"
                              : "Scheduled"
                          }`
                        : `${
                            clock.unlinked || clock.scheduledRemoved
                              ? clock.unlinked
                                ? "Unlinked shift"
                                : "Scheduled shift removed"
                              : clock.oneOfTwo === "two"
                              ? "Clocked overtime"
                              : "No shift scheduled"
                          }`}
                      {clock.unlinked ? (
                        <p className="unlinkedExplainBtn">?</p>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className=""> */}

                    <p
                      className={`${
                        clock.scheduled ? "clockSchedValue" : "none"
                      }`}
                    >
                      {dateStringer.dsToTimeStrip(clock.scheduledStart, true)} -{" "}
                      {dateStringer.dsToTimeStrip(clock.scheduledEnd, true)}{" "}
                      {/* <span className="clockBrkSpan">/ 60m </span> */}
                    </p>
                    {/* </div> */}
                  </div>
                  <div
                    className={`clocksSchedSubRight ${
                      approveLoading === clock.clockOnID
                        ? "clocksLoadingApprove_"
                        : ""
                    } ${memoVals.mob ? "flexEnd" : ""}`}
                  >
                    {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                    <p
                      className={`approveClock ${
                        clock.blockApprove ? "invis" : ""
                      }`}
                      onClick={() => {
                        if (!clock.approved && !clock.declined) {
                          setApproveLoading(clock.clockOnID);
                          // setApproveLoading(false);
                          axios
                            .post(
                              `${serverURL}/approve-clock-card`,
                              {
                                clockOnID: clock.clockOnID,
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                recordLate: recordLateClockOnIDs.includes(
                                  clock.clockOnID
                                )
                                  ? lateMins
                                  : false,
                                recordLateUnpaid:
                                  recordLateUnpaidClockOnIDs.includes(
                                    clock.clockOnID
                                  )
                                    ? true
                                    : false,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                if (clock.unlinked) {
                                  // remove from unlinked count
                                  setUnlinkedExist(unlinkedExist - 1);
                                }

                                setCountData({
                                  clocks: countData.clocks - 1,
                                });

                                let awaitArr = [];
                                let liveArr = clockOns.filter((item) => {
                                  return item.clockOnID !== clock.clockOnID;
                                });
                                awaitingClocks.forEach((c) => {
                                  if (c.clockOnID !== clock.clockOnID) {
                                    awaitArr.push(c);
                                  }

                                  if (c.clockOnID === clock.clockOnID) {
                                    clock.approved = true;
                                    clock.declined = false;

                                    clock.order = 99999999;

                                    // add to clocks only if it falls within current my
                                    let startOfMy =
                                      dateStringer.getTsFromMy(my);
                                    let endObj = new Date(startOfMy);
                                    endObj.setMonth(endObj.getMonth() + 1);
                                    endObj.setDate(endObj.getDate() - 1);
                                    endObj.setHours(23, 59, 0, 0);
                                    let clockTs =
                                      dateStringer.createTimestampFromString(
                                        clock.startDs
                                      );
                                    if (
                                      clockTs >= startOfMy &&
                                      clockTs <= endObj.getTime()
                                    ) {
                                      liveArr.push(clock);
                                    }
                                  }
                                });

                                // remove clockOnID from awaitingClockOnIDs
                                let newArr = [];
                                awaitingClockOnIDs.forEach((id) => {
                                  if (id !== clock.clockOnID) {
                                    newArr.push(id);
                                  }
                                });
                                setAwaitingClockOnIDs(newArr);

                                setAwaitingClocks(awaitArr);

                                setClockOns(liveArr);
                                setTimeout(() => {
                                  setApproveLoading("");
                                }, 200);
                                memoVals.setIndicate((x) => {
                                  return {
                                    show: true,
                                    message: `${dateStringer.possession(
                                      clock.fName
                                    )} clock card approved`,
                                    colour: "green",
                                    duration: 4000,
                                  };
                                });
                                // setReload(!reload);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      {/* Approve{" "} */}
                      <img
                        src={whiteTick}
                        alt="Approve clock card"
                        className="approveCCtickimg"
                      />
                    </p>
                    {/* )} */}
                    <p
                      className={`${
                        clock.approved ? "clockIsApproved" : "none"
                      } ${
                        memoVals.mob ? "font12px mobClockStatusBringLeft" : ""
                      }`}
                    >
                      Approved
                    </p>

                    <p
                      className={`openClock ${memoVals.mob ? "none" : ""}`}
                      onClick={() => {
                        memoVals.setClockOnID((x) => {
                          return {
                            clockOnID: clock.clockOnID,
                            isMgr: true,
                          };
                        });
                      }}
                    >
                      Open
                    </p>
                  </div>
                </div>
                {/* {clock.oneOfTwo === "two" ? (
                <div className="x485487597252"></div>
              ) : (
                ""
              )}{" "} */}
              </div>
            );
          }
        }
      }
    });
    // }
  }, [
    awaitingClocks,
    showUnlinked,
    showFilter,
    showOvertimes,
    showTils,
    showShifts,
    my,
    selectedNameUserID,
    selectedUserID,
    selectedClock,
    reverse,
    approveLoading,
    window.innerWidth,
    memoVals.full,
    ,
    recordLateClockOnIDs,
    recordLateUnpaidClockOnIDs,
  ]);

  let generateClockOns = useMemo(() => {
    return clockOns.map((clock, i) => {
      let lateMins = 0;
      if (clock.scheduled && clock.scheduledStart) {
        let schedStartTs = dateStringer.createTimestampFromString(
          clock.scheduledStart
        );
        let clockedStartTs = dateStringer.createTimestampFromString(
          clock.startDs
        );
        if (schedStartTs < clockedStartTs) {
          lateMins = (clockedStartTs - schedStartTs) / 1000 / 60;
        }
      }

      let dateObj = new Date(
        dateStringer.createTimestampFromString(clock.startDs)
      );
      let dateN = dateObj.getDate();
      if (!showUnlinked && selectedUserID && selectedUserID !== clock.userID) {
      } else {
        if (
          (!showUnlinked || (showUnlinked && clock.unlinked)) &&
          (clock.userID === selectedUserID ||
            selectedNameUserID === "allNames") &&
          (selectedDay === "allDays" || parseInt(selectedDay) === dateN) &&
          ((showShifts && clock.type === "shift") ||
            (showTils && clock.type === "til") ||
            (showOvertimes && clock.type === "overtime")) &&
          (memoVals.selectedShiftTeam === "allStaff" ||
            memoVals.selectedShiftTeam === clock.teamID)
        ) {
          if (clock.startDs && clock.endDs) {
            let calcClockedMins = (ds1, ds2, brkMins) => {
              if (ds1 && ds2) {
                let ds1Ts = dateStringer.createTimestampFromString(ds1);
                let ds2Ts = dateStringer.createTimestampFromString(ds2);

                let msDiff = ds2Ts - ds1Ts;

                let brkMs = brkMins * 60 * 1000;
                let totMs = msDiff - brkMs;

                return totMs / 1000;
              }
            };

            return (
              <div
                className={`${
                  !memoVals.mob ||
                  selectedClock === clock.clockOnID ||
                  2 + 2 === 4
                    ? `mgrClockUnitWrapperOpen ${
                        lateMins > 0 ? "mgrClockUnitWrapperOpenWithLate" : ""
                      } ${lateMins ? "mgrClockUnitWrapperOpenWithLate" : ""}`
                    : "mgrClockUnitWrapperClosed"
                } ${clock.oneOfTwo === "two" ? "x3848848482_" : ""} ${
                  selectedClock === clock.clockOnID || (memoVals.mob && i === 0)
                    ? "pullFirstMobClockUnitUp"
                    : ""
                } animateZoomIn_ ${
                  approveLoading === clock.clockOnID
                    ? "zoomClockCardOutUnit_"
                    : ""
                } ${clock.declined ? "opac07" : ""}`}
                // onClick={() => {
                //   console.log(clock);
                // }}
              >
                <div className="clockNameAndTeamDiv">
                  <div
                    className="mgrClockNameDiv  pendClockName_"
                    onClick={(e) => {
                      e.stopPropagation();

                      memoVals.setShowEditUser((x) => clock.userID);
                    }}
                  >
                    <img
                      src={clock.picUrl || profilePicturePlaceholder}
                      alt={clock.fName}
                      className="itemNameTabImg"
                    />{" "}
                    {clock.fName} {clock.lName}{" "}
                    {clock.you ? <span className="youSpan">(You)</span> : ""}
                    {clock.notes ? (
                      <p
                        className="clockNoteInline fontSize12"
                        onClick={(e) => {
                          e.stopPropagation();
                          memoVals.setClockOnID((x) => {
                            return {
                              clockOnID: clock.clockOnID,
                              isMgr: true,
                              notes: true,
                            };
                          });
                        }}
                      >
                        {" "}
                        <img
                          src={whiteComment}
                          alt="Clock card notes"
                          className="clockNoteImgInline"
                        />
                        x{clock.notes}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div className="mgrClockNameDiv clockTeamNameTopRight">
                    {memoVals.mob
                      ? shortenTeamName(clock.teamName, 10)
                      : shortenTeamName(clock.teamName, 16)}
                  </div> */}
                </div>

                {/* {clock.oneOfTwo && clock.oneOfTwo === "one" ? (
                <div className="clockOnOfTwoBar"></div>
              ) : (
                ""
              )} */}
                <div
                  className={`mgrClockUnit ${
                    lateMins > 0 ? "mgrClockUnitWithLate" : ""
                  } mgrClockUnitPending_ pend_ ${
                    !memoVals.mob ||
                    selectedClock === clock.clockOnID ||
                    2 + 2 === 4
                      ? "noBorderRadiusBottomRight"
                      : ""
                  } leftReqItemPendingBorder_ mgrReqItemPendingBorderMgr_ ${
                    memoVals.mob ? "borderRadius10" : ""
                  }`}
                  key={clock.clockOnID}
                  onClick={() => {
                    memoVals.setClockOnID((x) => {
                      return {
                        clockOnID: clock.clockOnID,
                        isMgr: true,
                      };
                    });
                  }}
                >
                  {!clock.approved && !clock.declined ? (
                    <div
                      className={`unsubmittedTsBlob clockAwaitBlob ${
                        lateMins > 0 ? "clockAwaitBlobWithLate" : ""
                      }`}
                    ></div>
                  ) : (
                    ""
                  )}
                  <div className="teamClockLeftParent">
                    <div className="tilShiftSection0">
                      {/* {!clock.unlinked && (
                        <img
                          src={shiftBlockNavy}
                          alt="Clock card"
                          className="teamClockCardIcon"
                        />
                      )} */}
                      {/* {clock.unlinked && <p className="unlinkedExlaim">!</p>} */}
                    </div>
                    <div
                      className={`tilShiftSection1Clock pendingClockSection1 ${
                        memoVals.mob ? "pendingClockSection1Decided" : ""
                      }  ${
                        window.innerWidth < 1080 && memoVals.full
                          ? "pendingClockSection1CorrectionMidFull_"
                          : ""
                      } ${
                        window.innerWidth < 458
                          ? "pendingClockSection1Under458With"
                          : ""
                      }`}
                    >
                      <p className={`tilShiftDs tilShiftDsFirstRow flexStart`}>
                        {/* x222 */}
                        <img
                          src={rotaDate}
                          alt="Clocked times"
                          className="teamClockRotaClockImg teamIconHoursLight"
                        />{" "}
                        {dateStringer.printedDateFromDs(clock.startDs)}
                        {/* Clocked{" "}
                        {clock.type === "til" ? (
                          <>
                            overtime{" "}
                            <span className="smallTilF">(in lieu)</span>
                          </>
                        ) : clock.type === "overtime" ? (
                          "overtime (paid)"
                        ) : (
                          "shift"
                        )} */}
                      </p>
                      <p className={`tilShiftDs tilShiftDsSub flexStart`}>
                        <img
                          src={rotaDur}
                          alt="Clocked times"
                          className="teamClockRotaClockImg teamClockRotaClockImgCoffee"
                        />
                        {dateStringer.formatMinsDurationToHours(
                          calcClockedMins(
                            clock.startDs,
                            clock.endDs,
                            clock.totBrkMins
                          ) / 60,
                          false,
                          true
                        )}
                      </p>
                      {lateMins > 0 && (
                        <p
                          className={`tilShiftDs tilShiftDsSub flexStart teamClocksLowerRow ccDec lateClockRow`}
                        >
                          {/* x222 */}
                          <img
                            src={rotaClock}
                            alt="Clocked times"
                            className="teamClockRotaClockImg opac05"
                          />
                          {dateStringer.formatMinsDurationToHours(lateMins)}{" "}
                          late
                        </p>
                      )}
                      <p
                        className={`tilShiftDs tilShiftDsSub flexStart  teamClocksLowerRow teamClocksLowerRow2`}
                      >
                        {/* x222 */}
                        <img
                          src={teamIcon}
                          alt="Clocked times"
                          className="teamClockRotaClockImg"
                        />

                        {dateStringer.shorten(
                          clock.teamName,
                          memoVals.mob ? 19 : 25
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`teamClockRightParent ${
                      clock.tmw ? "teamClockRightParentWithTmw" : ""
                    }`}
                  >
                    <div className="tilShiftSection2 pendingClockSection2">
                      {/* <p className="tilShiftDurationTxtTeam">
                        {dateStringer.formatMinsDurationToHours(
                          calcClockedMins(
                            clock.startDs,
                            clock.endDs,
                            clock.totBrkMins
                          ) / 60,
                          false,
                          true
                        )}
                      </p> */}
                      <div className="absTimelineVal ccDivTimeline">
                        <div className="ccDiv">
                          <div className="ccDivMid">
                            <div className="ccDivTs">
                              <p className="ccDivTitle">On</p>

                              <p
                                className={`x1983948894894 ${
                                  clock.lateMins > 0 ? "ba0000" : ""
                                }`}
                              >
                                {" "}
                                {dateStringer.dsToTimeStrip(
                                  clock.startDs,
                                  true
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`absCanDivSplitter ccMgrSplitter`}
                        ></div>

                        <div className="ccDiv ccDiv2">
                          <div className="ccDivMid">
                            <div className="ccDivTs">
                              <p className="ccDivTitle ccDivTitle2">Off</p>

                              <p className="x1983948894894">
                                {" "}
                                {dateStringer.dsToTimeStrip(clock.endDs, true)}
                              </p>
                            </div>
                          </div>
                        </div>
                        {clock.tmw && <p className="ccTmw">+{clock.tmw}d</p>}

                        {/*  */}
                      </div>
                      <p className={`teamClockBreakDurStrip`}>
                        <img
                          src={shiftBlockNavy}
                          alt="Team"
                          className="teamIconHours teamIconHoursLight"
                        />{" "}
                        {clock.type === "til"
                          ? "Overtime"
                          : clock.type === "overtime"
                          ? "Overtime"
                          : "Shift"}
                      </p>
                    </div>

                    <div className="itemTongue clockTongue">
                      <p className="tongueTxt clockTongueTxt">Clock card</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    !memoVals.mob ||
                    selectedClock === clock.clockOnID ||
                    2 + 2 === 4
                      ? `mgrClockSubBar ${
                          memoVals.mob ? "mgrClockSubBarMob" : ""
                        }`
                      : "none"
                  } pendClockSub`}
                >
                  <div
                    className={`clocksSchedSubLeft ${
                      memoVals.mob ? "font12px" : ""
                    } ${clock.unlinked ? "cursorP" : ""}`}
                    onClick={() => {
                      if (clock.unlinked) {
                        setShowUnlinkedInfoModal(true);
                      }
                    }}
                  >
                    <div
                      className={`clockSchedTitle ${
                        memoVals.mob && clock.createdByClock
                          ? "originallyClockedMobFont"
                          : ""
                      } ${clock.createdByClock ? "createdByDynamic" : ""}  ${
                        clock.unlinked || clock.scheduledRemoved
                          ? "ba0000 x4385785487458754"
                          : ""
                      } ${clock.oneOfTwo === "two" ? "clockedOtTxt" : ""}`}
                    >
                      <img
                        src={unlinked}
                        className={`${
                          clock.unlinked ? "unlinkedClockImg" : "none"
                        }`}
                        alt="Unlinked"
                      />
                      <img
                        src={noShiftScheduled}
                        className={`${
                          clock.unlinked || clock.scheduled
                            ? "none"
                            : "noShiftSchedImg"
                        } ${clock.oneOfTwo === "two" ? "x49578274" : ""}`}
                        alt="No shift scheduled"
                      />
                      {clock.scheduled
                        ? `${
                            clock.createdByClock
                              ? "Originally clocked"
                              : "Scheduled"
                          }`
                        : `${
                            clock.unlinked || clock.scheduledRemoved
                              ? clock.unlinked
                                ? "Unlinked shift"
                                : "Scheduled shift removed"
                              : clock.oneOfTwo === "two"
                              ? "Clocked overtime"
                              : "No shift scheduled"
                          }`}
                      {clock.unlinked ? (
                        <p className="unlinkedExplainBtn">?</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <p
                      className={`${
                        clock.scheduled ? "clockSchedValue" : "none"
                      }`}
                    >
                      {dateStringer.dsToTimeStrip(clock.scheduledStart, true)} -{" "}
                      {dateStringer.dsToTimeStrip(clock.scheduledEnd, true)}{" "}
                      {/* <span className="clockBrkSpan">/ 60m </span> */}
                    </p>
                  </div>
                  <div
                    className={`clocksSchedSubRight ${
                      approveLoading === clock.clockOnID
                        ? "clocksLoadingApprove"
                        : ""
                    } ${memoVals.mob ? "flexEnd" : ""}`}
                  >
                    {/* {!approveLoading && !clock.approved && !clock.declined && ( */}

                    {/* )} */}
                    <p
                      className={`${
                        memoVals.mob ? "font12px mobClockStatusBringLeft" : ""
                      } ${clock.declined ? "bbcada" : ""} ccAppDecStatus`}
                    >
                      {clock.approved ? "Approved" : "Declined"}
                    </p>

                    <p
                      className={`openClock ${memoVals.mob ? "none" : ""}`}
                      onClick={() => {
                        memoVals.setClockOnID((x) => {
                          return {
                            clockOnID: clock.clockOnID,
                            isMgr: true,
                          };
                        });
                      }}
                    >
                      Open
                    </p>
                  </div>
                </div>
                {/* {clock.oneOfTwo === "two" ? (
                <div className="x485487597252"></div>
              ) : (
                ""
              )}{" "} */}
              </div>
            );
          }
        }
      }
    });
  }, [
    clockOns,
    showUnlinked,
    showFilter,
    showOvertimes,
    showTils,
    showShifts,
    my,
    selectedUserID,
    selectedNameUserID,
    selectedClock,
    reverse,
    memoVals.mob,
    memoVals.fullButNarrow,
  ]);

  let generateNameOptions = useMemo(() => {
    if (nameObjects) {
      return nameObjects.map((name) => {
        return (
          <option key={name.userID} value={name.userID}>
            {name.fName} {name.lName && name.lName[0]}
          </option>
        );
      });
    }
  }, [
    nameObjects,
    showUnlinked,
    showFilter,
    showOvertimes,
    showTils,
    showShifts,
    my,
    memoVals.selectedShiftTeam,
    selectedClock,
  ]);

  let getTeamName = (teamID, teams) => {
    if (teamID) {
      if (teamID === "allStaff") {
        return "all staff you manage";
      } else {
        let teamFilt = teams.filter((item) => {
          return item.teamID === memoVals.selectedShiftTeam;
        });

        if (teamFilt[0]) {
          console.log("!!!", teamFilt[0]);
          return teamFilt[0].teamName;
        }
      }
    }
  };

  // master

  return (
    <div
      className={`userShiftsPage ${memoVals.mob ? "" : "pageLeft240pxDesktop"}`}
    >
      <ShiftsHeader my={my} setMy={setMy} />
      {/* <div className="adminCurverCropper bgColUpcoming absenceShoulder">
        <div className="adminCurve"></div>
      </div>{" "} */}
      <div className={`desktopMaster ${memoVals.mob ? "desktopPurpBg" : ""}`}>
        <div
          className={`desktopMasterMain  ${
            memoVals.mob ? "mob100Witdth mob100WitdthPlus2" : ""
          } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}  ${
            memoVals.mob ? "mgrHoursShoulderBar" : ""
          }`}
        >
          <div
            className={`shiftsPageFilterBar ${
              memoVals.mob ? "userHoursShoulderBar" : ""
            } ${!memoVals.full ? "noBorder_" : ""} ${
              !memoVals.mob && "borderLeftGrey"
            } noBorderBottom_ ${
              !unlinkedExist && memoVals.mob
                ? "noUnlinkedMobClocksShoulderHeight"
                : ""
            }`}
          >
            {memoVals.mob ? (
              // "memoVals.mob clock cards shoulder"
              <div
                className={`staffClocksMobShoulder ${
                  !unlinkedExist ? "x109842082" : ""
                } `}
              >
                <div
                  className={`staffClocksMobShoulderRow ${
                    memoVals.mob && unlinkedExist > 0
                      ? "staffClocksMobShoulderRowUnlinked"
                      : "staffClocksMobShoulderRowUnlinkedZero"
                  }`}
                >
                  <select
                    className={`shiftsTilTeamMonthDropdown shiftsTilMonthDropdownSmall  ${
                      my === dateStringer.getMyFromTs(new Date().getTime())
                        ? "colour00aaff"
                        : ""
                    }`}
                    value={my}
                    onChange={(e) => {
                      if (showUnlinked) {
                        setShowUnlinked(false);
                      }
                      setLoading(true);
                      setMy(e.target.value);
                    }}
                  >
                    {generateMonthOptions}
                  </select>
                  <select
                    className={`shiftsHeaderTeamDropdown  ${
                      selectedUserID
                        ? `teamSelectedDrop ${
                            memoVals.mob
                              ? "mobteamSelectedDrop x23949849882442"
                              : ""
                          }`
                        : ""
                    }`}
                    value={selectedUserID}
                    onChange={(e) => {
                      // setLoadUser({});
                      let val = e.target.value;
                      setSelectedUserID((x) => val);
                      // memoVals.setSelectedShiftTeam(x =>{return{(e.target.value)});
                    }}
                  >
                    <option value="">-- All staff you manage</option>
                    {generateTeamsSelectBar}
                  </select>
                </div>{" "}
                <div className="staffClocksMobShoulderRow2">
                  {/* <select
                    className={`${
                      selectedNameUserID === "allNames"
                        ? "shiftNamesDropdown unselectedShiftOrTil2"
                        : "unselectedShiftOrTil2"
                    }`}
                    value={selectedNameUserID}
                    onChange={(e) => {
                      // setLoading(true);
                      // setUpdateShifts(!updateShifts);
                      setSelectedNameUserID(e.target.value);
                      // setSelectedChangeID("");
                    }}
                  >
                    <option key={Math.random()} value="allNames">
                      -- All of your staff
                    </option>
                    {generateNameOptions}
                  </select> */}
                  <p>
                    {/* {showUnlinked ? "show unlinked" : "do not show unlinked"}{" "}
                    --- {unlinkedExist} */}
                  </p>

                  <p></p>
                </div>
              </div>
            ) : (
              <div className="desktopClockCardsShoulder">
                <div
                  className={`shiftsPageFilterBarLeft ${
                    memoVals.mob && showFilter ? "narrowerFilterLeft" : ""
                  }`}
                >
                  <select
                    className={`shiftsTilMonthDropdown shiftsTilMonthDropdownSmall ${
                      my === dateStringer.getMyFromTs(new Date().getTime())
                        ? "colour00aaff"
                        : ""
                    }`}
                    value={my}
                    onChange={(e) => {
                      setLoading(true);
                      if (showUnlinked) {
                        setShowUnlinked(false);
                      }
                      // setUpdateShifts(!updateShifts);
                      setMy(e.target.value);
                    }}
                  >
                    {generateMonthOptions}
                  </select>
                </div>{" "}
                <div className={`teamClockOnsFilterBarRight`}>
                  {showUnlinked || unlinkedExist > 0 ? (
                    <div
                      className={`shiftsOrTilFilterTeam ${
                        showUnlinked ? "" : "unselectedShiftOrTil"
                      } unlinkedFiltBtnCorrection`}
                      onClick={() => {
                        if (!showUnlinked) {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Showing unlinked clock cards only",
                              colour: "orange",
                              duration: 4000,
                            };
                          });
                        } else {
                          memoVals.setIndicate((x) => {
                            return {
                              show: false,
                              message: "",
                              colour: "",
                              duration: 0,
                            };
                          });
                        }
                        setShowUnlinked(!showUnlinked);
                      }}
                    >
                      Unlinked{" "}
                      <span className="unlinkedQtySpan">{unlinkedExist}</span>
                      <img
                        src={showUnlinked ? checkbox2 : checkbox1}
                        alt="Select"
                        className="shiftTilSelectImg"
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {!memoVals.mob ? (
                    <div
                      className={`${
                        showFilter
                          ? "shiftTilFilterBtnActive marginLeft0"
                          : `${
                              selectedNameUserID !== "allNames"
                                ? "shiftTilFilterBtn marginRight0"
                                : "shiftTilFilterBtn marginRight0"
                            }`
                      } `}
                      onClick={() => {
                        if (showFilter) {
                          setSelectedNameUserID("allNames");
                          memoVals.setSelectedShiftTeam((x) => "allStaff");
                        }
                        setShowFilter(!showFilter);
                      }}
                    >
                      <img
                        src={navyFilter}
                        alt="Close filter"
                        className={`filtImg ${!showFilter ? "x19831" : ""}`}
                      />{" "}
                      <img
                        className={`${
                          showFilter ? "closeFilterImgTilShifts" : "none"
                        }`}
                        alt="close"
                        src={navyClose}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>

          {showFilter && !memoVals.mob ? (
            <div
              className={`nameAndDateDropdownsContainer paddingLeft5pc ${
                !memoVals.full ? "noBorder" : ""
              }`}
            >
              {" "}
              <select
                className={`shiftsHeaderTeamDropdown x10924014 ${
                  memoVals.mob ? "shiftsHeaderTeamDropdownMob" : ""
                } ${
                  memoVals.selectedShiftTeam !== "allStaff"
                    ? `teamSelectedDrop ${
                        memoVals.mob ? "mobteamSelectedDrop" : ""
                      }`
                    : ""
                }`}
                value={memoVals.selectedShiftTeam}
                onChange={(e) => {
                  // setLoadUser({});

                  memoVals.setSelectedShiftTeam((x) => e.target.value);
                }}
              >
                <option value="allStaff">-- All of your teams</option>
                {generateTeamsSelectBar}
              </select>
              <select
                className={`${
                  selectedNameUserID === "allNames"
                    ? "shiftNamesDropdown x10893011"
                    : "shiftNamesDropdown x10893011"
                } ${memoVals.fullButNarrow ? "x2038282" : ""}`}
                value={selectedNameUserID}
                onChange={(e) => {
                  // setLoading(true);
                  // setUpdateShifts(!updateShifts);
                  setSelectedNameUserID(e.target.value);
                  // setSelectedChangeID("");
                }}
              >
                <option key={Math.random()} value="allNames">
                  -- All staff
                </option>
                {generateNameOptions}
              </select>
            </div>
          ) : (
            ""
          )}

          <div
            className={`availabilityBodyContainer ${
              !memoVals.full ? "noBorder" : ""
            } ${!memoVals.mob ? "x19374872" : ""}`}
            onClick={() => {}}
          >
            {/* render here */}
            {!memoVals.mob ? (
              <div
                className={`filterSplitterContainer x8765433 ${
                  !memoVals.full || loading ? "noBorder" : "noBorder"
                }`}
              >
                <div className="filterSplitter"></div>
              </div>
            ) : (
              ""
            )}
            <img
              src={horizontalBalls}
              className={`${loading ? "loadingHoriztonalImg" : "none"}`}
              alt="Loading"
            />

            <div
              ref={clocksScrollBox}
              className={`${
                !loading
                  ? `clockOnsContainer  ${
                      memoVals.mob && !unlinkedExist
                        ? `clockOnsContainerMobNoUnlinked ${
                            memoVals.device.ios
                              ? "clockOnsContainerMobNoUnlinkedIos"
                              : ""
                          }`
                        : ""
                    } ${
                      memoVals.mob && unlinkedExist
                        ? `mobUnlinkedExistContainer ${
                            memoVals.device.ios
                              ? "mobUnlinkedExistContainerIos_"
                              : ""
                          }`
                        : ""
                    } ${showFilter ? "clockOnsContainerWithFilt" : ""}`
                  : "none"
              } ${!memoVals.mob && !memoVals.full ? "noBorder_" : ""} ${
                memoVals.device.vhIssue ? "vhIssue" : ""
              } ${!memoVals.mob ? "x183989842" : ""}`}
            >
              {my === currMy ? (
                <p
                  className={`${
                    awaitingClocks.length > 0 &&
                    !showUnlinked &&
                    selectedUserID === ""
                      ? // &&
                        // filteredUserHasOutstanding
                        "clocksAwaitingTitles"
                      : "none"
                  } ${
                    selectedNameUserID !== "allNames" &&
                    !filteredUserHasOutstanding
                      ? "none"
                      : ""
                  }`}
                  onClick={() => {
                    console.log({ recordLateClockOnIDs });
                  }}
                >
                  Outstanding{" "}
                  <span className="pendingChNum pendingChNumMgr">
                    {awaitingClocks.length}
                  </span>
                </p>
              ) : (
                ""
              )}

              {showUnlinked && !loading ? (
                <p
                  className={`unlinkedClockCardsExplain ${
                    memoVals.mob ? "unlinkedClockCardsExplainMob" : ""
                  } ${!memoVals.mob && !memoVals.full ? "x983184" : ""}`}
                >
                  Unlinked clock cards are approved clock cards that are no
                  longer associated to a shift for which the employee is paid.
                  This could be because the shift that was created by the clock
                  card has since been moved or deleted.
                  <br />
                  <br />
                  You can re-link each clock card, which creates a new shift for
                  which the employee is paid.
                  <br />
                  <br />
                  Read more about this
                  <a
                    className="unlinkedSupportBtn"
                    href={`${websiteURL}/support/unlinked-clock-cards`}
                    target="_blank"
                  >
                    here <img src={popup} className="unlinkedPopup" />
                  </a>
                </p>
              ) : (
                ""
              )}
              {currMy === my ? generateClocksAwaiting : ""}

              <div
                className={`${
                  awaitingClocks.length > 0 &&
                  clockOns.length > 0 &&
                  !showUnlinked &&
                  currMy === my &&
                  selectedUserID === ""
                    ? //  &&
                      // filteredUserHasOutstanding
                      "awaitingClockDivider"
                    : "none"
                }`}
              ></div>

              {clockOns[0] || loading ? (
                generateClockOns
              ) : (
                <div
                  className={`noShiftsTxtContainer noUpcomingReqs ${
                    memoVals.mob ? "x1984971" : ""
                  } ${showUnlinked ? "none" : ""}`}
                >
                  <div className="noShiftsSideBar"></div>

                  <p
                    className={`noShiftsTxt nilTxtTeamClocks`}
                    onClick={() => {
                      console.log({ loading, clockOns });
                    }}
                  >
                    No clock cards in{" "}
                    {dateStringer.printMonthAndYearFromMy(my, true, true)}{" "}
                    amongst{" "}
                    {getTeamName(
                      memoVals.selectedShiftTeam,
                      memoVals.availableTeamsForShifts
                    )}{" "}
                  </p>

                  <div className="noShiftsSideBar"></div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`${
            memoVals.mob || !memoVals.full ? "none" : "desktopMasterSecondary"
          }`}
        >
          <TeamHoursDataBox
            // sideBoxData={sideBoxData} my={my} setMy={setMy}

            setLoadUser={false}
            setShowRequests={false}
          />{" "}
        </div>
      </div>

      {showUnlinkedInfoModal ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setShowUnlinkedInfoModal(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              {" "}
              Unlinked clock cards are approved clock cards that are no longer
              associated to a shift for which the employee is paid. This could
              be because the shift that was created by the clock card has since
              been moved or deleted.
              <br />
              <br />
              You can re-link each clock card, which creates a new shift for
              which the employee is paid.
              <br />
              <br />
              Read more about this
              <a
                className="unlinkedPopUpLink"
                href={`${websiteURL}/support/unlinked-clock-cards`}
                target="_blank"
              >
                here <img src={popup} className="unlinkedPopup" />
              </a>
            </p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setShowUnlinkedInfoModal(false);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {memoVals.mob && (
        <div
          className={`shiftsOrTilFilterTeam ${
            showUnlinked ? "" : "unselectedShiftOrTil"
          } unlinkedFiltBtnCorrection unlinkedClockBtnMobOnly ${
            unlinkedExist <= 0 ? "none" : ""
          }`}
          onClick={() => {
            if (!showUnlinked) {
              memoVals.setIndicate((x) => {
                return {
                  show: true,
                  message: "Showing unlinked clock cards only",
                  colour: "orange",
                  duration: 4000,
                };
              });
            } else {
              memoVals.setIndicate((x) => {
                return {
                  show: false,
                  message: "",
                  colour: "",
                  duration: 0,
                };
              });
            }
            setShowUnlinked(!showUnlinked);
          }}
        >
          Unlinked <span className="unlinkedQtySpan">{unlinkedExist}</span>
          <img
            src={showUnlinked ? checkbox2 : checkbox1}
            alt="Select"
            className="shiftTilSelectImg"
          />
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default TeamClockOns;
