import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wed 24 july 23
// params cleared 10 sep 23
// images preloaded 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";
import { UserContext } from "../../contexts/UserContext";

import dateStringer from "../../tools/dateStringer";
import serverURL from "../../serverURL";
import whitecross from "../../img/general/whitecross.svg";
import close from "../../img/general/lightBlueCross.svg";
import popup from "../../img/general/popup.svg";

import "../../styles/calendar.css";

const MyAbsencePerformanceDataBox = ({
  modal,
  setShowSideModal,
  profileSideBox,
  selectedYear,
  setSelectedYearParent,
  availableYears,
}) => {
  const {
    hoursDataLoading,
    setHoursDataLoading,
    updatedReq,
    device,
    setViewAbsenceUserTimeline,
  } = useContext(DataContext);
  const { checkUserState } = useContext(UserContext);
  let [showTopWhiteBorder, setShowTopWhiteBorder] = useState(false);

  const { mob, mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      hoursDataLoading,
      setHoursDataLoading,
      updatedReq,
      checkUserState,
      mobModal,
      mob,
      device,
      setViewAbsenceUserTimeline,
    }),
    [
      hoursDataLoading, //
      setHoursDataLoading, //
      updatedReq, //
      checkUserState, //
      mobModal, //
      mob,
      device,
      setViewAbsenceUserTimeline,
    ]
  );

  useEffect(() => {
    // clean not needed
    memoVals.setHoursDataLoading((x) => true);
  }, []);

  let [customQty, setCustomQty] = useState(0);
  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      if (setShowSideModal) {
        setShowSideModal(false);
      }
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let [prevYear, setPrevYear] = useState(false);
  useEffect(() => {
    // clean not needed
    if (parseInt(selectedYear) < memoVals.checkUserState.currClientYear) {
      setPrevYear(true);
    } else {
      setPrevYear(false);
    }
  }, [selectedYear]);

  let [data, setData] = useState({
    userDailyMins: 0,
    customData: [],
    totalAllowMins: 0,
    usedMins: 0,
    remaining: 0,
  });

  let [absenceTypes, setAbsenceTypes] = useState([]);
  let [totAbsentMins, setTotAbsentMins] = useState(0);
  let [totPeriodAbsentMins, setTotPeriodAbsentMins] = useState(0);
  let [unpaidAbsentMins, setUnpaidAbsentMins] = useState(0);
  let [paidAbsentMins, setPaidAbsentMins] = useState(0);
  let [lateMins, setLateMins] = useState(0);
  let [lateCount, setLateCount] = useState(0);
  let [percentage, setPercentage] = useState(0);
  let [userDailyMins, setUserDailyMins] = useState(0);
  let [paidAbsenceInstances, setPaidAbsenceInstances] = useState(0);
  let [unpaidAbsenceInstances, setUnpaidAbsenceInstances] = useState(0);
  let [rank, setRank] = useState("-");
  let [outOf, setOutOf] = useState(0);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (selectedYear) {
      memoVals.setHoursDataLoading((x) => true);
      axios
        .post(
          `${serverURL}/get-my-absence-data-box`,
          {
            year: parseInt(selectedYear),
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }

          if (response.data.message === "success") {
            setTotAbsentMins(response.data.totAbsentMins);
            setTotPeriodAbsentMins(response.data.absentMinsFromAllPeriods);
            setUnpaidAbsentMins(response.data.unpaidAbsentMins);
            setPaidAbsentMins(response.data.paidAbsentMins);
            setLateMins(response.data.lateMins);
            setLateCount(response.data.lateCount);
            setUserDailyMins(response.data.userDailyMins);
            setPercentage(response.data.percentage);
            setPaidAbsenceInstances(response.data.paidAbsenceInstances);
            setUnpaidAbsenceInstances(response.data.unpaidAbsenceInstances);

            setRank(response.data.rank);
            setOutOf(response.data.outOf);
            // setUserDailyMins(0);
            memoVals.setHoursDataLoading((x) => false);
            let arr = [];
            response.data.absenceTypeMins.forEach((obj) => {
              if (obj.mins > 0) {
                arr.push(obj);
              }
            });

            console.log("arr;", arr);
            setCustomQty(arr.length);
            setAbsenceTypes(arr);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [selectedYear, memoVals.updatedReq]);

  let breakName = (val) => {
    let output = "";
    if (val) {
      let spaceFound = false;
      for (let i = 0; i < val.length; i++) {
        if (!spaceFound) {
          if (val[i] !== " ") {
            output += val[i];
          } else {
            spaceFound = true;
          }
        }
      }

      if (output.length > 11) {
        return true;
      } else {
        return false;
      }
    }
  };

  let [showCustom, setShowCustom] = useState(
    modal || profileSideBox ? true : true
  );
  let generateCustoms = useMemo(() => {
    return absenceTypes
      ? absenceTypes.map((item, i) => {
          return (
            <div className="customMappedItem">
              <div
                className={`desktopDataRow desktopRowThickBorder customDataBoxTitle `}
              >
                <p
                  className={`desktopDataTitle boldDataVal customLeaveDataTitle 
                ${breakName(item.name) && !modal ? "font12px" : ""}
                ${memoVals.mob ? "txtFontSize16px" : ""} colorWhite`}
                  onClick={() => {}}
                >
                  {item.name}
                </p>
                <p
                  className={`${
                    memoVals.hoursDataLoading ? "none" : ""
                  } desktopDataValue boldDataVal ${
                    memoVals.mob ? "txtFontSize16px_" : ""
                  }`}
                >
                  {dateStringer.formatMinsDurationToHours(item.mins)}
                </p>{" "}
                <p
                  className={`${
                    memoVals.hoursDataLoading
                      ? "memoVals.hoursDataLoading"
                      : "none"
                  }`}
                >
                  -
                </p>
              </div>
              <div
                className={`desktopDataRow customDataRowBottomBorder ${
                  i === customQty - 1 ? "noBorder" : ""
                } noBorder`}
                onClick={() => {
                  console.log({ customQty });
                }}
              >
                <p
                  className={`desktopDataTitle font12px ${
                    memoVals.mob ? "txtFontSize16px" : ""
                  } colorWhite`}
                >
                  Rate of pay
                </p>
                <div
                  className={`desktopDataValue boldDataVal ${
                    memoVals.mob ? "txtFontSize16px_" : ""
                  } ${memoVals.hoursDataLoading ? "none" : ""}`}
                >
                  {item.rate === 0 ? "Unpaid" : `${item.rate}x`}
                </div>

                <p
                  className={`${
                    memoVals.hoursDataLoading
                      ? "memoVals.hoursDataLoading"
                      : "none"
                  }`}
                >
                  -
                </p>
              </div>
            </div>
          );
        })
      : "";
  }, [selectedYear, memoVals.hoursDataLoading, customQty, absenceTypes]);
  let customScrollBox = useRef();
  useEffect(() => {
    // clean not needed
    if (customScrollBox.current) {
      customScrollBox.current.scrollTop = 0;
    }
  }, [showCustom, selectedYear]);

  let generateAvailableYears = useMemo(() => {
    return availableYears.map((item) => (
      <option value={item} key={item}>
        {item}
      </option>
    ));
  }, [availableYears]);
  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      setShowSideModal(false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className={`desktopDataBox ${
        modal
          ? `modalReqDataBox ${customQty === 0 ? "modalMyAbsNoCustoms" : ""}`
          : ""
      } reqsDesktopBoxScroll ${
        customQty === 0 ? "noCustomsDataBoxSizeAbsence" : ""
      } ${!showCustom ? "dataBoxMin" : ""} ${
        memoVals.mobModal ? "x2489ff" : ""
      } ${memoVals.device.ios ? "paddingBottom20" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={modalPosition}
      ref={modalRef}
    >
      {/* <div className={`${modal ? "showMyToggler" : "none"}`}>
        <img
          src={whitecross}
          alt="Close"
          className="closeStatsModalCrossImg"
          onClick={() => {
            setShowReqStats(false);
          }}
        />
      </div> */}
      {window.innerWidth <= 550 && (
        <div className="modalSwiper modalSwiperMyAllows"></div>
      )}
      <div
        className="desktopDataBoxHeader"
        onTouchStart={handleDown}
        onTouchMove={window.innerWidth <= 550 ? handleMove : null}
        onTouchEnd={window.innerWidth <= 550 ? handleUp : null}
        onMouseDown={window.innerWidth <= 550 ? handleDown : null}
        onMouseMove={window.innerWidth <= 550 ? handleMove : null}
        onMouseUp={window.innerWidth <= 550 ? handleUp : null}
      >
        <div className="myAbsDataBoxHeaderLeft">
          {memoVals.mob || modal ? (
            <img
              src={whitecross}
              alt="Close"
              className="closeReqDataBoxImg"
              onClick={() => {
                // setShowReqStats(false);
                setShowSideModal(false);
              }}
            />
          ) : (
            ""
          )}
          <span
            className={`dataBoxHeading colourNavy_ ${
              modal ? "font18px modalMyAbsTitle" : ""
            }`}
            onClick={() => {
              console.log("data: ", data);
              if (memoVals.mobModal) {
                setShowSideModal(false);
              }
            }}
          >
            {" "}
            {profileSideBox ? "Absence performance" : "Absence performance"}
          </span>
        </div>

        <select
          className={`reqsDataBoxYearSelect ${
            memoVals.mob ? "txtFontSize16px" : ""
          }`}
          value={selectedYear}
          onChange={(e) => {
            setSelectedYearParent(e.target.value);
          }}
        >
          {generateAvailableYears}
        </select>
      </div>
      <div className="desktopDataRow desktopRowThickBorder">
        <p
          className={`desktopDataTitle boldDataVal ${
            modal ? "font16px" : "font16px_"
          } colourNavy_ colorWhite`}
          onClick={() => {
            console.log({ prevYear });
          }}
        >
          {selectedYear} total
        </p>
        <p
          className={`${
            memoVals.hoursDataLoading ? "none" : ""
          } desktopDataValue boldDataVal ${modal ? "font16px_" : ""}`}
        >
          {paidAbsenceInstances + unpaidAbsenceInstances} time
          {paidAbsenceInstances + unpaidAbsenceInstances === 1 ? "" : "s"}
          {/* daisy */}
          <span className={`myLeaveDataMinsSpan`}>
            {dateStringer.formatMinsDurationToHours(totAbsentMins)}
          </span>
        </p>{" "}
        <p
          className={`${
            memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
          }`}
        >
          -
        </p>
      </div>

      <div className="desktopDataRow desktopRowThickBorder absPercSideBox">
        <p
          className={`desktopDataTitle boldDataVal ${
            modal ? "font16px" : "font16px_"
          } colourNavy_ colorWhite`}
          onClick={() => {
            console.log({ prevYear });
          }}
        >
          Percentage
        </p>
        <p
          className={`${
            memoVals.hoursDataLoading ? "none" : ""
          } desktopDataValue boldDataVal ${modal ? "font16px_" : ""}`}
        >
          {percentage.toFixed(0)}% absent
        </p>{" "}
        <p
          className={`${
            memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
          }`}
        >
          -
        </p>
      </div>

      <div className="desktopDataRow firstDataVal">
        <p
          className={`desktopDataTitle ${
            modal ? "font16px" : ""
          } usedRemainingIndent colorWhite`}
        >
          Unpaid
        </p>
        <p
          className={`${
            memoVals.hoursDataLoading ? "none" : ""
          } desktopDataValue boldDataVal ${modal ? "font16px_" : ""}`}
        >
          {unpaidAbsenceInstances} instance
          {unpaidAbsenceInstances === 1 ? "" : "s"}
          <span className="myLeaveDataMinsSpan">
            {dateStringer.formatMinsDurationToHours(unpaidAbsentMins)}
          </span>
        </p>{" "}
        <p
          className={`${
            memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
          }`}
        >
          -
        </p>
      </div>
      <div className="desktopDataRow">
        <p
          className={`desktopDataTitle ${
            modal ? "font16px" : ""
          } usedRemainingIndent colorWhite`}
        >
          Paid
        </p>
        <p
          className={`${
            memoVals.hoursDataLoading ? "none" : ""
          } desktopDataValue boldDataVal ${modal ? "font16px_" : ""}`}
        >
          {paidAbsenceInstances} instance{paidAbsenceInstances === 1 ? "" : "s"}
          <span className="myLeaveDataMinsSpan">
            {dateStringer.formatMinsDurationToHours(paidAbsentMins)}
          </span>
        </p>{" "}
        <p
          className={`${
            memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
          }`}
        >
          -
        </p>
      </div>

      <div className="desktopDataRow noBorder">
        <p
          className={`desktopDataTitle ${
            modal ? "font16px" : ""
          } usedRemainingIndent colorWhite`}
        >
          Late
        </p>
        <p
          className={`${
            memoVals.hoursDataLoading ? "none" : ""
          } desktopDataValue boldDataVal ${modal ? "font16px_" : ""}`}
        >
          {lateCount === 0
            ? "None"
            : `${lateCount} time${lateCount === 1 ? "" : "s"}`}{" "}
          / {dateStringer.formatMinsDurationToHours(lateMins)}
        </p>{" "}
        <p
          className={`${
            memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
          }`}
        >
          -
        </p>
      </div>

      {/* <div className="desktopDataRow noBorder">
        <div className="absLowerBetterDiv2">
          <p
            className={`desktopDataTitle ${
              modal ? "font16px" : ""
            } usedRemainingIndent colorWhite`}
          >
            Absence rank
          </p>
          <p className="lowerBetter2">in the company</p>
        </div>
        <div className="absLowerBetterDiv">
          {rank === "disabled" ? (
            ""
          ) : (
            <p
              className={`${
                memoVals.hoursDataLoading ? "none" : ""
              } desktopDataValue boldDataVal ${modal ? "font16px_" : ""}`}
            >
              #{rank} / {outOf}
            </p>
          )}
          <p className="lowerBetter">
            {rank === "disabled" ? "Unavailable" : "The lower the better"}
          </p>
        </div>
        <p
          className={`${
            memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
          }`}
        >
          -
        </p>
      </div> */}
      {/* - - - - */}
      <div className="emptyDesktopDataRow"></div>

      {/* {showCustom ? (
        <div
          className={`myReqDataSplitter ${
            modal ? "modalShowCustomDataSplitter" : ""
          }`}
        ></div>
      ) : (
        ""
      )} */}
      {showCustom ? (
        <div
          ref={customScrollBox}
          className={`reqDataCustomScrollBoxAbsence ${
            modal ? "modalCustomScrollBox2" : ""
          } ${
            memoVals.mob && customQty === 0 ? "noCustomQtyScrollBoxMob" : ""
          } ${showTopWhiteBorder ? "topWhiteBorder" : ""}`}
          onScroll={(e) => {
            if (e.target.scrollTop > 0) {
              setShowTopWhiteBorder(true);
            } else {
              setShowTopWhiteBorder(false);
            }
          }}
        >
          {/* <div className="myAbsDataBoxTimelineRow">
            <p
              className="absDataViewAbsTimelineBtn absDataViewAbsTimelineBtnMy blueColours"
              onClick={() => {
                memoVals.setViewAbsenceUserTimeline((x) => {
                  return {
                    show: true,
                    userID: "me",
                    year: parseInt(selectedYear),
                    mgr: false,
                  };
                });
              }}
            >
              Absence timeline{" "}
              <img
                src={popup}
                alt="Timeline popup"
                className="absTimelinepopupImg"
              />
            </p>{" "}
          </div> */}
          {generateCustoms}
        </div>
      ) : (
        ""
      )}
      {modal || memoVals.mob ? (
        <div className={`mgrAllowsFooter`}>
          <p></p>{" "}
          <p
            className={`closeEditShiftBtn closeMgrPurpleBtn_`}
            onClick={() => {
              setShowSideModal(false);
            }}
          >
            Close
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MyAbsencePerformanceDataBox;
