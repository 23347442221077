// design done 28 feb 25
// keys done 5/3/25
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 sep 24
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";
import { CalendarContext } from "../contexts/CalendarContext";

import axios from "axios";
import serverURL from "../serverURL";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import navyClose from "../img/general/navycross.svg";
import bin1 from "../img/general/bin1.svg";
import arrGrey from "../img/general/arrGrey.svg";
import greyClose from "../img/general/greyClose.svg";
import like from "../img/general/like.svg";
import liked from "../img/general/liked.svg";
import liked2 from "../img/general/liked2.svg";
import acknowledgeArr from "../img/general/acknowledgeArr.svg";
import noteImg from "../img/general/navyNote.svg";
import navyTick from "../img/general/navyTick.svg";

const DayCommentModal = () => {
  const { mobModal } = useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);
  const { updateInfo, setUpdateInfo } = useContext(CalendarContext);

  const {
    showDayCommentModal,
    setShowDayCommentModal,
    setAmendDayComment,
    setUpdateCalComments,
    setUpdatedHighlightedComment,
    updateCalAvail,
    setUpdateCalAvail,
    modalOpen,
    device,
    setModalOpen,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      showDayCommentModal,
      setShowDayCommentModal,
      setAmendDayComment,
      setUpdateCalComments,
      setUpdatedHighlightedComment,
      updateCalAvail,
      setUpdateCalAvail,
      checkUserState,
      mobModal,
      modalOpen,
      setModalOpen,
      device,
      updateInfo,
      setUpdateInfo,
    }),
    [
      showDayCommentModal, //
      setShowDayCommentModal, //
      setAmendDayComment, //
      setUpdateCalComments, //
      setUpdatedHighlightedComment, //
      updateCalAvail, //
      setUpdateCalAvail, //
      checkUserState, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
      updateInfo,
      setUpdateInfo,
    ]
  );

  let notesBox = useRef();

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [toggleAddNote, setToggleAddNote] = useState(false);
  let [shiftAddNoteValue, setShiftAddNoteValue] = useState("");
  let [showLikedCommentIDs, setShowCommentLikedIDs] = useState([]);
  let [hoveredLikeCommentID, setHoveredLikeCommentID] = useState("");
  let [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowDayCommentModal((x) => {
        return {
          show: false,
          isMgr: false,
          ds: "",
          teamID: "",
        };
      });
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let addNoteTextArea = useRef();

  let [needToUpdateRota, setNeedToUpdateRota] = useState(false);

  useEffect(() => {
    // clean not needed
    if (addNoteTextArea.current) {
      addNoteTextArea.current.focus();
    }
  }, [toggleAddNote]);
  let closeModal = () => {
    memoVals.setShowDayCommentModal((x) => {
      return {
        show: false,
        isMgr: false,
        ds: "",
        teamID: "",
      };
    });
    setUpdateCalAvail((x) => !memoVals.updateCalAvail);
    if (needToUpdateRota) {
      setUpdateInfo((x) => !memoVals.updateInfo);
    }
  };

  let [update, setUpdate] = useState(false);

  let [notes, setNotes] = useState([]);
  let [teamName, setTeamName] = useState("");

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 10) {
      setTimeout(() => {
        setLoading(false);
      }, 1);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyClose}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyTick}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={bin1}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={arrGrey}
        alt="Grey Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyClose}
        alt="Grey Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={like}
        alt="Like"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={liked}
        alt="Liked"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={liked2}
        alt="Liked 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={acknowledgeArr}
        alt="Acknowledge Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={noteImg}
        alt="Note"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-day-comments`,
        {
          ds: memoVals.showDayCommentModal.ds,
          teamID: memoVals.showDayCommentModal.teamID,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (
          response.data.message === "success" &&
          response.data.output &&
          response.data.output
        ) {
          setNotes(response.data.output);
        }
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setTeamName(response.data.teamName);

          setDataLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [update]);

  let generateNotes =
    notes && notes[0]
      ? notes
          .sort((a, b) => a.tsMade - b.tsMade)
          .map((note, i) => {
            let usrLiked = false;
            if (note.likedArr && note.likedArr[0]) {
              note.likedArr.forEach((x) => {
                if (x.userID === memoVals.checkUserState.userID) {
                  usrLiked = true;
                }
              });
            }
            let nameVal = `${note.fName} ${note.lName}`;
            return (
              <div
                key={i}
                className={`shiftNotesObj ${
                  note.highlighted ? "highlightedNoteObj" : ""
                }`}
              >
                <div className="commentObjNameAndDs">
                  <p className="noteObjName">
                    {note.profPicUrl ? (
                      <img
                        src={note.profPicUrl}
                        alt={note.fName}
                        className="dayCommentComProfPic"
                      />
                    ) : (
                      <p className="dayCommInits">
                        {note.fName[0]}
                        {note.lName[0]}
                      </p>
                    )}{" "}
                    {nameVal && nameVal.length > 14
                      ? `${note.fName} ${
                          note.lName && note.lName[0] ? note.lName[0] : ""
                        }`
                      : nameVal}
                  </p>
                  <div className="dayCommentTsAndHighlight">
                    <p className="noteObjFsDs">
                      {dateStringer.printedDateWithTimeFromDs(note.dsMade)}
                    </p>
                    {memoVals.showDayCommentModal.isMgr ? (
                      <div
                        className="highlightCommentBtn x23849822358"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/highlight-comment`,
                              {
                                commentID: note.commentID,
                                teamID: memoVals.showDayCommentModal.teamID,
                                ds: memoVals.showDayCommentModal.ds,
                                data: !note.highlighted,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                setNeedToUpdateRota(true);
                                let newArr = [];
                                notes.forEach((x) => {
                                  if (x.commentID === note.commentID) {
                                    newArr.push({
                                      commentID: x.commentID,
                                      userID: x.userID,
                                      fName: x.fName,
                                      lName: x.lName,
                                      dsMade: x.dsMade,
                                      tsMade: x.tsMade,
                                      comment: x.comment,
                                      commentID: x.commentID,
                                      likedUserIDs: x.likedUserIDs,
                                      highlighted: !note.highlighted,
                                      profPicUrl: x.profPicUrl,
                                    });
                                  } else {
                                    newArr.push({
                                      commentID: x.commentID,
                                      userID: x.userID,
                                      fName: x.fName,
                                      lName: x.lName,
                                      dsMade: x.dsMade,
                                      tsMade: x.tsMade,
                                      comment: x.comment,
                                      commentID: x.commentID,
                                      likedUserIDs: x.likedUserIDs,
                                      highlighted: false,
                                      profPicUrl: x.profPicUrl,
                                    });
                                  }
                                });
                                setNotes(newArr);
                                memoVals.setUpdatedHighlightedComment((x) => {
                                  return {
                                    ds: memoVals.showDayCommentModal.ds,
                                    comment: !note.highlighted
                                      ? note.comment
                                      : "",
                                    teamID: memoVals.showDayCommentModal.teamID,
                                    // teamName: response.data.teamName,
                                    // fName: response.data.fName,
                                    // lName: response.data.lName,
                                  };
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        Highlight on rota{" "}
                        {note.highlighted ? (
                          <img
                            src={navyTick}
                            alt="Highlight"
                            className={`highglightTick`}
                          />
                        ) : (
                          <div className="unhighlightedCommTick"></div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <p className="dayCommentObj">{note.comment}</p>
                <div className="deleteNoteRow x23943985895">
                  {note.likedArr &&
                  note.likedArr[0] &&
                  note.likedArr.length > 0 ? (
                    <div className="likedUsersSection">
                      {note.likedArr[0] ? (
                        <p className="ackTitle">Acknowledged:</p>
                      ) : (
                        ""
                      )}
                      {note.likedArr[0] ? (
                        <div
                          className="likedArrMapDiv"
                          onClick={() => {
                            if (showLikedCommentIDs.includes(note.commentID)) {
                              setShowCommentLikedIDs(
                                showLikedCommentIDs.filter((x) => {
                                  return x !== note.commentID;
                                })
                              );
                            } else {
                              setShowCommentLikedIDs([
                                ...showLikedCommentIDs,
                                note.commentID,
                              ]);
                            }
                          }}
                        >
                          {note.likedArr.map((liked, i) => {
                            if (i < 8) {
                              return liked.profPicUrl ? (
                                <img
                                  src={liked.profPicUrl}
                                  alt={liked.fName}
                                  className="ackUsrIcon"
                                  key={i}
                                />
                              ) : (
                                <p className="ackUsrInitials" key={i}>
                                  {liked.fName[0]}
                                  {liked.lName[0]}
                                </p>
                              );
                            }
                          })}{" "}
                          {note.likedArr.length > 7 ? (
                            <p className="ackUsrExcess">
                              +{note.likedArr.length - 8}
                            </p>
                          ) : (
                            ""
                          )}
                          {showLikedCommentIDs.includes(note.commentID) ? (
                            ""
                          ) : (
                            <img
                              src={acknowledgeArr}
                              alt="Show acknowledged"
                              className="showAckExpandIcon"
                              onClick={() => {
                                setShowCommentLikedIDs([
                                  ...showLikedCommentIDs,
                                  note.commentID,
                                ]);
                              }}
                            />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {showLikedCommentIDs.includes(note.commentID) ? (
                        <div className="showLikedNamesCom">
                          <img
                            onClick={() => {
                              setShowCommentLikedIDs(
                                showLikedCommentIDs.filter((x) => {
                                  return x !== note.commentID;
                                })
                              );
                            }}
                            src={arrGrey}
                            alt="Acknowledged"
                            className="ackArrClose"
                          />
                          <div className="ackNamesList">
                            {note.likedArr.map((x, i) => {
                              return (
                                <p className="ackNameItem" key={i}>
                                  {x.fName} {x.lName}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <p></p>
                  )}
                  {note.userID === memoVals.checkUserState.userID ? (
                    <img
                      src={bin1}
                      alt="Remove note"
                      className="binNoteImg"
                      onClick={() => {
                        setNotes(
                          notes.filter((x) => {
                            return x.commentID !== note.commentID;
                          })
                        );
                        axios
                          .post(
                            `${serverURL}/remove-day-comment`,
                            {
                              commentID: note.commentID,
                              teamID: memoVals.showDayCommentModal.teamID,
                              ds: memoVals.showDayCommentModal.ds,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              setNeedToUpdateRota(true);

                              setToggleAddNote(false);
                              setUpdateCalComments((x) => {
                                return {
                                  adding: false,
                                  removing: true,
                                  ds: memoVals.showDayCommentModal.ds,
                                  noMoreComments:
                                    notes.filter((x) => {
                                      return x.commentID !== note.commentID;
                                    }).length === 0,
                                };
                              });

                              setShiftAddNoteValue("");
                              memoVals.setAmendDayComment((x) => {
                                return {
                                  minusOne: true,
                                  plusOne: false,
                                  ds: memoVals.showDayCommentModal.ds,
                                };
                              });

                              // setUpdate(!update);
                              // setNotes(response.data.doc.comments);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    />
                  ) : (
                    <img
                      src={
                        hoveredLikeCommentID === note.commentID &&
                        usrLiked &&
                        !memoVals.mobModal
                          ? liked2
                          : usrLiked
                          ? liked
                          : like
                      }
                      alt="Acknowledge note"
                      className={`binNoteImg ${usrLiked ? "x18429814581" : ""}`}
                      onMouseEnter={() => {
                        setHoveredLikeCommentID(note.commentID);
                      }}
                      onMouseLeave={() => {
                        setHoveredLikeCommentID("");
                      }}
                      onClick={() => {
                        setShowCommentLikedIDs(
                          showLikedCommentIDs.filter((x) => {
                            return x !== note.commentID;
                          })
                        );

                        axios
                          .post(
                            `${serverURL}/acknowledge-day-comment`,
                            {
                              commentID: note.commentID,
                              teamID: memoVals.showDayCommentModal.teamID,
                              ds: memoVals.showDayCommentModal.ds,
                              undo: usrLiked ? true : false,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              // update here
                              setUpdate(!update);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })
      : "";

  let enterFct = (e) => {
    if (e.key === "Enter" || e === "add")
      if (shiftAddNoteValue) {
        setToggleAddNote(false);

        axios
          .post(
            `${serverURL}/add-day-comment`,
            {
              comment: shiftAddNoteValue,
              dsMade: dateStringer.createStringFromTimestamp(
                new Date().getTime()
              ),
              ds: memoVals.showDayCommentModal.ds,
              teamID: memoVals.showDayCommentModal.teamID,
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message.includes("logout")) {
              window.location.href = "/signin";
            }
            if (response.data.message === "success") {
              setNeedToUpdateRota(true);

              setToggleAddNote(false);
              setShiftAddNoteValue("");
              setUpdateCalComments((x) => {
                return {
                  adding: true,
                  removing: false,
                  ds: memoVals.showDayCommentModal.ds,
                };
              });
              // setUpdate(!update);
              setNotes([
                {
                  fName: response.data.fName,
                  lName: response.data.lName,
                  profPicUrl: response.data.profPicUrl,
                  comment: shiftAddNoteValue,
                  commentID: response.data.commentID,
                  userID: memoVals.checkUserState.userID,
                  dsMade: response.data.dsMade,
                  tsMade: response.data.tsMade,
                },
                ...notes,
              ]);
              // setNotes(response.data.doc.comments);
              memoVals.setAmendDayComment((x) => {
                return {
                  minusOne: false,
                  plusOne: true,
                  ds: memoVals.showDayCommentModal.ds,
                };
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        if (notesBox && notesBox.current) {
          notesBox.current.scrollTo({
            top: notesBox.current.scrollHeight, // Scroll to the bottom of the div
            behavior: "smooth", // Smooth scroll behavior
          });
        }
      }, 200);
    }
  }, [toggleAddNote, notes, notesBox, loading]);

  // master return
  return (
    <div
      className="clockOnModalUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`dayCommentsModal addUserModalHeight ${
            memoVals.mobModal ? "seeUserModalMob mobModalShoulder" : ""
          } ${memoVals.device.ios ? "paddingBottom35" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperDayComments"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="mySwapsHeader dasyCommsHeader"
            onClick={() => {}}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={memoVals.mobModal ? navyClose : navyClose}
              alt="Close"
              className="closeMySwapsModalImg"
              onClick={() => {
                closeModal();
              }}
            />
            {!loading ? (
              <p>
                {dateStringer.printedDateFromDs(
                  memoVals.showDayCommentModal.ds
                )}{" "}
                - {dateStringer.shorten(teamName, 19)}
              </p>
            ) : (
              ""
            )}

            {/* <p></p> */}
          </div>
          {loading ? (
            <p>
              <br />
              <br />
              <br />
              <br />
            </p>
          ) : (
            <div
              className={`dayCommentsModalBody ${
                !memoVals.mobModal ? "dayCommentsModalBodyDesktop" : ""
              }`}
            >
              <div className="shiftNotesTitleAndAdd myShiftTitleAndAdd">
                <p className="dayCommentsTitleTxt">Day comments</p>
              </div>

              <div
                className={`dayCommentsHolder ${
                  !memoVals.mobModal
                    ? `dayCommentsHolderDesktop ${
                        notes.length === 0
                          ? "dayCommentsHolderDesktopNoNotes"
                          : ""
                      }`
                    : ""
                } ${!teamName ? "invis" : ""} ${
                  !isAtTop ? "dayCommsTopBorder" : ""
                } `}
                ref={notesBox}
                onScroll={(e) => {
                  if (e.target.scrollTop === 0) {
                    setIsAtTop(true);
                  } else {
                    setIsAtTop(false);
                  }
                }}
              >
                <div className={`dayCommentsFrameWithinScroll `}>
                  {notes && notes[0] ? (
                    generateNotes
                  ) : (
                    <p className="noComments">No comments yet</p>
                  )}
                  {!toggleAddNote && (
                    <div
                      className={`addClockNoteBtn addMyShNote ${
                        toggleAddNote ? "addClockNoteBtnActive" : ""
                      }`}
                      onClick={() => {
                        setToggleAddNote(!toggleAddNote);
                      }}
                    >
                      Add note{" "}
                      <img
                        src={noteImg}
                        alt="Add note"
                        className={`addNoteInlineImg ${
                          toggleAddNote ? "x19387459" : ""
                        }`}
                      />
                    </div>
                  )}
                  {toggleAddNote ? (
                    <div className="shiftAddNoteDiv x238939884">
                      <textarea
                        ref={addNoteTextArea}
                        className="shiftAddNoteTextArea"
                        value={shiftAddNoteValue}
                        onKeyPress={(e) => {
                          enterFct(e);
                        }}
                        onChange={(e) => {
                          setShiftAddNoteValue(e.target.value);
                        }}
                      ></textarea>
                      <div className="shiftAddNoteRightBtns">
                        <p
                          className={`shiftAddNoteSaveBtn ${
                            !shiftAddNoteValue ? "disableAddNoteBtn" : ""
                          }`}
                          onClick={() => {
                            enterFct("add");
                          }}
                        >
                          Add
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="dayCommentsModalFooter">
            <p></p>

            <p
              className="closeReqInfo"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default DayCommentModal;
