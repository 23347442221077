// keys and design done 18 mar 25
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 8 sep 24
import axios from "axios";
import serverURL from "../serverURL";
import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";
import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import close from "../img/modals/close.svg";
import whitecross from "../img/general/whitecross.svg";
import unclockedCheck from "../img/general/checkboxInstall4.svg";
import unclocked from "../img/general/unclocked.svg";

import time from "../img/general/shiftBlockAbs.svg";
import profilePicturePlaceholder from "../img/general/profilePicturePlaceholder.svg";
import desktopBurger from "../img/general/desktopBurger.svg";
import rotaDur from "../img/general/rotaDur.svg";
import rotaTeam from "../img/general/rotaTeam.svg";
import popup00aaff from "../img/general/popupNavy.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

const UnclocksModal = () => {
  const {
    setShowUnclocked,
    setOpenManagerShiftModal,
    loadUnclocks,
    setUnclockedQty,
    showEditUser,
    updateUnclocked,
    setIndicate,
    modalOpen,
    setModalOpen,
    device,
    countData,
    setCountData,
    setShowEditUser,
  } = useContext(DataContext);
  const { mobModal } = useContext(StyleContext);
  let [actionLoading, setActionLoading] = useState(false);

  let formatTag = (tag) => {
    if (tag && tag.length > 11) {
      return `${tag.substr(0, 10)}...`;
    } else return tag;
  };

  const memoVals = useMemo(
    () => ({
      setShowUnclocked,
      setOpenManagerShiftModal,
      loadUnclocks,
      setUnclockedQty,
      showEditUser,
      updateUnclocked,
      mobModal,
      setIndicate,
      modalOpen,
      setModalOpen,
      device,
      setShowEditUser,
    }),
    [
      setShowUnclocked, //
      setOpenManagerShiftModal, //
      loadUnclocks, //
      setUnclockedQty, //
      showEditUser, //
      updateUnclocked, //
      mobModal, //
      setIndicate,
      modalOpen,
      setModalOpen,
      device,
      setShowEditUser,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [unclocks, setUnclocks] = useState([]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 9) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={profilePicturePlaceholder}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unclockedCheck}
        alt="Unclocked Check"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unclocked}
        alt="Unclocked"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={desktopBurger}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDur}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaTeam}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={popup00aaff}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
    </div>
  );
  let [multiSelect, setMultiSelect] = useState(false);
  let [selectedShiftIDs, setSelectedShiftIDs] = useState([]);
  let [selectedTilIDs, setSelectedTilIDs] = useState([]);
  let [selectedShiftID, setSelectedShiftID] = useState("");
  let [selectedTilID, setSelectedTilID] = useState("");
  let [showActions, setShowActions] = useState(false);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-header-clocks`,
        {
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          previewOnly: false,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          response.data.unclocks.sort((a, b) => {
            return (
              dateStringer.createTimestampFromString(a.start) -
              dateStringer.createTimestampFromString(b.start)
            );
          });
          setUnclocks(response.data.unclocks);
          if (response.data.unclocks.length === 0) {
            memoVals.setIndicate((x) => {
              return {
                show: true,
                message: `There are no unclocked shifts`,
                colour: "green",
                duration: 4000,
                ts: new Date().getTime(),
              };
            });
            memoVals.setShowUnclocked((x) => false);
          }
          memoVals.setUnclockedQty((x) => response.data.qty);
          if (dataLoaded) {
            setDataLoaded(false);
          }
          setDataLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.loadUnclocks, memoVals.updateUnclocked]);

  let actionFct = (action, singleShift, singleTil) => {
    // action: makeWorked, delete, absentPaid, absentUnpaid
    setShowActions(false);
    // setLoading(true);

    setActionLoading(true);
    axios
      .post(
        `${serverURL}/unclock-item-action`,

        {
          makeWorked: action === "makeWorked" ? true : false,
          deleteItems: action === "delete" ? true : false,
          makeAbsentUnpaid: action === "absentUnpaid" ? true : false,
          makeAbsentPaid: action === "absentPaid" ? true : false,
          shiftIDs: singleShift ? [selectedShiftID] : selectedShiftIDs,
          tilIDs: singleTil ? [selectedTilID] : selectedTilIDs,
        },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          if (singleShift || singleTil) {
            setCountData({
              unclocked: countData.unclocked - 1,
            });
          } else {
            setCountData({
              unclocked: 0,
            });
          }

          let newArr = [];
          if (singleShift) {
            unclocks.map((c) => {
              if (c.shiftID !== selectedShiftID) {
                newArr.push(c);
              }
            });
          } else if (singleTil) {
            unclocks.map((c) => {
              if (c.tilID !== selectedTilID) {
                newArr.push(c);
              }
            });
          } else {
            unclocks.forEach((c) => {
              if (
                selectedShiftIDs.includes(c.shiftID) ||
                selectedTilIDs.includes(c.tilID)
              ) {
              } else {
                newArr.push(c);
              }
            });
          }

          setUnclocks(newArr);

          setSelectedShiftIDs([]);
          setSelectedTilID([]);
          setActionLoading(false);
        } else {
          setActionLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setActionLoading(false);
      });
  };

  let generateUnclocks = unclocks.map((unc, i) => {
    let startDs = unc.start;
    let endDs = unc.end;
    let type = "shift";
    if (unc.tilID) {
      type = unc.tilType;
      startDs = `${unc.ds.substr(0, 11)}H${unc.start.substr(
        0,
        2
      )}M${unc.start.substr(3, 2)}`;
      endDs = `${unc.ds.substr(0, 11)}H${unc.end.substr(0, 2)}M${unc.end.substr(
        3,
        2
      )}`;
    }

    return (
      <div className="reqUnitParent" key={i}>
        <div
          key={i}
          onClick={() => {
            if (multiSelect) {
              if (unc.shiftID) {
                if (selectedShiftIDs.includes(unc.shiftID)) {
                  let newArr = [];
                  selectedShiftIDs.forEach((sh) => {
                    if (sh !== unc.shiftID) {
                      newArr.push(sh);
                    }
                  });
                  setSelectedShiftIDs(newArr);
                } else {
                  let newArr = [...selectedShiftIDs, unc.shiftID];
                  setSelectedShiftIDs(newArr);
                }
              }
              if (unc.tilID) {
                if (selectedTilIDs.includes(unc.tilID)) {
                  let newArr = [];
                  selectedTilIDs.forEach((til) => {
                    if (til !== unc.tilID) {
                      newArr.push(til);
                    }
                  });
                  setSelectedTilIDs(newArr);
                } else {
                  let newArr = [...selectedTilIDs, unc.tilID];
                  setSelectedTilIDs(newArr);
                }
              }
            } else {
              if (
                (selectedShiftID && selectedShiftID === unc.shiftID) ||
                (selectedTilID && selectedTilID === unc.tilID)
              ) {
                setSelectedShiftID("");
                setSelectedTilID("");
              } else {
                if (unc.shiftID) {
                  setSelectedShiftID(unc.shiftID);
                  setSelectedTilID("");
                } else {
                  setSelectedShiftID("");
                  setSelectedTilID(unc.tilID);
                }
              }
            }
          }}
          className={`mgrReqItem pend ${
            memoVals.mob ? "borderRadius10x" : ""
          } leaveReqItem shiftItemRows3 mgrReqItemWidth94`}
        >
          {" "}
          <div
            className={`unsubmittedTsBlob annLeaveBlob annLeaveBlobRows4 darkRedBg minHeight96`}
          ></div>
          <div className="reqColBarDiv divPend"></div>
          <div
            className={`mgrReqItemCol2 ${
              memoVals.mob ? "mgrReqItemCol2Mob" : ""
            }`}
          >
            <div className="mgrReqTopRow">
              <div className="mgrReqName mgrReqName2">
                {/* ------ */}
                <div
                  className={`mgrReqDateStrip pendDateDiv x29898988444 shContentLeftRows3 x848888484233`}
                >
                  <div
                    className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons displayFlexShiftType`}
                  >
                    <img
                      src={desktopBurger}
                      alt="Request duration"
                      className="rotaSunReqs leaveNameBurger leaveNameBurgerUnc"
                    />

                    <p className="shTypeNameStrip">
                      {unc.type === "shift" ? (
                        "Shift"
                      ) : unc.tilType === "til" ? (
                        <>
                          Overtime{" "}
                          <span className="shTypeTilSmallSpan">(in lieu)</span>
                        </>
                      ) : (
                        "Overtime"
                      )}
                    </p>
                  </div>
                  <div
                    className={`mgrReqDayLengthSpan upcomingMgrReqsSpan marginTop6`}
                  >
                    <img
                      src={rotaDur}
                      alt="Request duration"
                      className="rotaSunReqs"
                    />
                    {`${dateStringer.formatMinsDurationToHours(unc.durMins)}`}{" "}
                  </div>
                  <div
                    className={`mgrReqDayLengthSpan upcomingMgrReqsSpan marginTop6`}
                  >
                    <img
                      src={rotaTeam}
                      alt="Request duration"
                      className="rotaSunReqs"
                    />
                    {unc.teamName}
                  </div>
                </div>

                {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
              </div>
              <div className="teamShRightBlock">
                <div className="absTimelineVal absTimelineValReqs flexReverse marginRight0">
                  <div className={`shItemTimes`}>
                    <p className="shItemTimeRow">
                      {unc.start.length > 10
                        ? dateStringer.dsToTimeStrip(unc.start, true)
                        : unc.start}
                    </p>
                    <div className="shItemTimeRowSplitter"></div>
                    <p className="shItemTimeRow">
                      {unc.end.length > 10
                        ? dateStringer.dsToTimeStrip(unc.end, true)
                        : unc.end}
                    </p>
                  </div>
                  <div className={`absCalDiv`}>
                    <div className="absCalDivUpper">
                      <div className="absDivCalNip"></div>
                      <div className="absDivCalNip"></div>
                    </div>
                    <div className={`absCalDivLower absCalDivLowerAllTime`}>
                      {/* <p className="calDivUpperThreeLetterDay">{dateStringer.getThreeDayLettersFromDs(unit.startDs)}</p> */}
                      <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                        {parseInt(unc.start.substr(9, 2))}
                      </p>
                      <p className="absCalDivLowerMonth absCalDivLowerMonthReqs unclockDayTxt">
                        {dateStringer.getThreeDayLettersFromDs(unc.start)}
                      </p>{" "}
                      <p className="absCalDivLowerYear">
                        {dateStringer.getMonthThreeLettersFromDs(unc.start)}
                        {/* {unit.startDs.substr(1, 4)} */}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <p className={`teamClockBreakDurStrip bbcada marginTop20`}>
                  {dateStringer.shorten(unc.teamName, 20)}{" "}
                  <img
                    src={rotaTeam}
                    alt="Team"
                    className="teamIconHours opac05"
                  />
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`mgrReqNameTab pendClockName shNameTabRows3 marginLeft3pcx`}
          onClick={() => {
            if (multiSelect) {
              if (unc.shiftID) {
                if (selectedShiftIDs.includes(unc.shiftID)) {
                  let newArr = [];
                  selectedShiftIDs.forEach((sh) => {
                    if (sh !== unc.shiftID) {
                      newArr.push(sh);
                    }
                  });
                  setSelectedShiftIDs(newArr);
                } else {
                  let newArr = [...selectedShiftIDs, unc.shiftID];
                  setSelectedShiftIDs(newArr);
                }
              }
              if (unc.tilID) {
                if (selectedTilIDs.includes(unc.tilID)) {
                  let newArr = [];
                  selectedTilIDs.forEach((til) => {
                    if (til !== unc.tilID) {
                      newArr.push(til);
                    }
                  });
                  setSelectedTilIDs(newArr);
                } else {
                  let newArr = [...selectedTilIDs, unc.tilID];
                  setSelectedTilIDs(newArr);
                }
              }
            } else {
              memoVals.setShowEditUser((x) => unc.userID);
            }
          }}
        >
          {multiSelect && (
            <div className="multiSelectUnc">
              {selectedShiftIDs.includes(unc.shiftID) ||
              selectedTilIDs.includes(unc.tilID) ? (
                <img
                  src={unclockedCheck}
                  alt="Selected"
                  className="uncSelCheckImg"
                />
              ) : (
                ""
              )}
            </div>
          )}
          <img
            src={unc.profPicUrl || profilePicturePlaceholder}
            alt={unc.fName}
            className="itemNameTabImg"
          />{" "}
          {unc.fName} {unc.lName}
          {unc.you ? <span className="youSpan">(You)</span> : ""}{" "}
        </div>
        <div
          className={`${
            (selectedShiftID && selectedShiftID === unc.shiftID) ||
            (selectedTilID && selectedTilID === unc.tilID)
              ? "unclockItemMenuDiv"
              : "none"
          }`}
        >
          <div
            className={`unclockMenuL ${
              loading || actionLoading ? "disableUnclockActions" : ""
            }`}
          >
            <p
              className={`unclockActionBtn unclockAbsBtn unclockMarkWorkedBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "makeWorked",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Mark as worked
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockDeleteBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "delete",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Delete{" "}
              {type === "shift"
                ? "shift"
                : `${type === "til" ? "TOIL" : "overtime"}`}
            </p>
            <div className="unclockBtnDivider"></div>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "absentUnpaid",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Mark absent - unpaid
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "absentPaid",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Mark absent - paid
            </p>{" "}
          </div>
          <div className="unclockMenuR">
            <p
              className="viewUnclockItem colour00aaff_"
              onClick={() => {
                memoVals.setOpenManagerShiftModal((x) => {
                  return {
                    shiftID: unc.shiftID ? unc.shiftID : "",
                    tilID: unc.tilID ? unc.tilID : "",
                    fName: unc.fName,
                    lName: unc.lName,
                    openedFromShiftsPage: false,
                    openedFromUnclockedPage: true,
                    type: unc.shiftID ? "shift" : "til",
                  };
                });
              }}
            >
              View{" "}
              <img
                src={popup00aaff}
                className="viewShUnclPopUp"
                alt="View shift"
              />
            </p>
          </div>
        </div>
      </div>
    );
  });

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowUnclocked((x) => false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      memoVals.setShowUnclocked((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return

  return (
    <div
      className={`clockOnModalUnderlay ${memoVals.showEditUser ? "none" : ""}`}
      onClick={() => {
        memoVals.setShowUnclocked((x) => false);
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`clockOnModalBox whiteBg ${
            memoVals.mobModal
              ? "mobUnclockModalBox slideUp mobModalShoulder"
              : "zoomIn"
          } ${memoVals.device.ios ? "mobUnclockModalBoxIos" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperUnclocked"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="clockOnModalHeader"
            onClick={() => {
              if (memoVals.mobModal) {
                memoVals.setShowUnclocked((x) => false);
              }
            }}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <div className="clockOnModalHeaderSideUnit">
              <img
                src={close}
                alt="Close"
                className="closeClockOnModalImg"
                onClick={() => {
                  memoVals.setShowUnclocked((x) => false);
                }}
              />
            </div>
            <p className="clockOnModalHeaderTitle" onClick={() => {}}>
              Shift no-shows
            </p>
            <div className="clockOnModalHeaderSideUnit"></div>
          </div>
          <div className="modalExplainer">
            <div className="modalExplainLeftBlob"></div>
            <p className="modalExplainRightTxt">
              Shifts requiring staff to clock on, but no clock activity took
              place.
            </p>
          </div>

          <div
            className={`${
              memoVals.mobModal ? "mobUnclocksModalBody" : ""
            } unclocksModalContentBox ${
              showActions ? "unclocksModalContentBoxShowActions" : ""
            }
          `}
          >
            <div
              className={`unclockedContainer ${
                memoVals.mobModal ? "unclockedContainerMob" : ""
              } ${
                showActions
                  ? `${
                      memoVals.mobModal
                        ? "minus140pxForActionsMob"
                        : "minus140pxForActions"
                    }`
                  : ""
              }`}
            >
              {generateUnclocks}
            </div>
          </div>
          <div
            className={`${showActions ? "unclockMultiActionsBox" : "none"} ${
              !memoVals.mobModal ? "desktopMultiActionBox" : ""
            } ${loading ? "disableUnclockActions" : ""}`}
          >
            <p
              className={`unclockActionBtn unclockAbsBtn unclockMarkWorkedBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              } paddingLandR15px`}
              onClick={() => {
                actionFct("makeWorked", false, false);
              }}
            >
              Mark as worked
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockDeleteBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              } paddingLandR15px`}
              onClick={() => {
                actionFct("delete", false, false);
              }}
            >
              Delete selected
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              } paddingLandR15px`}
              onClick={() => {
                actionFct("absentUnpaid", false, false);
              }}
            >
              Mark absent - unpaid
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              } paddingLandR15px`}
              onClick={() => {
                actionFct("absentPaid", false, false);
              }}
            >
              Mark absent - paid
            </p>{" "}
          </div>
          <div className="blankModalFooter">
            <div className="backAndDeleteShiftContainer">
              <div
                className={`pubShiftBtn ${
                  multiSelect ? "multiSelectUnclocks" : ""
                }`}
                onClick={() => {
                  setMultiSelect(!multiSelect);
                  setSelectedShiftIDs([]);
                  setSelectedTilIDs([]);
                  setSelectedShiftID("");
                  setSelectedTilID("");
                  setShowActions(false);
                }}
              >
                {multiSelect ? "Cancel" : "Select"}
              </div>
              {!selectedShiftIDs[0] && !selectedTilIDs[0] ? (
                <div
                  className={`pubShiftBtn selectAllBtn ${
                    multiSelect ? "multiSelectUnclocks" : "none"
                  }`}
                  onClick={() => {
                    let sIDs = [];
                    let tIDs = [];
                    unclocks.forEach((cl) => {
                      if (cl.shiftID) {
                        sIDs.push(cl.shiftID);
                      }
                      if (cl.tilID) {
                        tIDs.push(cl.tilID);
                      }
                    });

                    setSelectedShiftIDs(sIDs);
                    setSelectedTilIDs(tIDs);
                  }}
                >
                  Select all
                </div>
              ) : (
                ""
              )}
              <div
                className={`pubShiftBtn ${
                  selectedShiftIDs.length > 0 || selectedTilIDs.length > 0
                    ? "actionsUnclockActive"
                    : "none"
                } x2384384587545445`}
                onClick={() => {
                  setShowActions(!showActions);
                }}
              >
                {" "}
                <img
                  src={whitecross}
                  className={`${
                    showActions ? "showActionsWhiteCrossImg" : "none"
                  }`}
                  alt="Close"
                />
                Actions
              </div>
            </div>
            <p
              className="closeReqInfo"
              onClick={() => {
                memoVals.setShowUnclocked((x) => false);
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default UnclocksModal;
