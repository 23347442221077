// design and keys done 12 mar 25
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useReducer,
} from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 8 sep 24
import axios from "axios";
import { UserContext } from "../../contexts/UserContext";
import { DataContext } from "../../contexts/DataContext";

import serverURL from "../../serverURL";
import websiteURL from "../../websiteURL";

import dateStringer from "../../tools/dateStringer";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";
import rotaTag from "../../img/general/rotaTag.svg";
import pin from "../../img/general/pin.svg";
import rotaCoffee from "../../img/general/rotaCoffee.svg";
import navyAdd from "../../img/general/navyAdd.svg";
import bin1 from "../../img/general/bin1.svg";

import AddShellShiftModal from "../AddShellShiftModal";
import desktopBurger from "../../img/general/desktopBurger.svg";

import navyClose from "../../img/general/navyClose.svg";
import remove from "../../img/general/greyBin.svg";
import templateArrow1 from "../../img/general/greyGo.svg";
import templateArrow2 from "../../img/general/whiteGo.svg";
import publishTick1 from "../../img/general/checkboxInstall1.svg";
import publishTick2 from "../../img/general/navyTick.svg";
import ClosedDays from "../account/admin/ClosedDays";

const SaveTemplateModal = ({
  saveTemplateObj,
  setSaveTemplateObj,
  updateInfo,
  setUpdateInfo,
  mobModal,
}) => {
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(
    saveTemplateObj.loading ? false : true
  );

  const { modalOpen, setModalOpen, device, setIndicate } =
    useContext(DataContext);
  const { checkUserState } = useContext(UserContext);

  let [showTags, setShowTags] = useState([]);
  let [toggleAddJobTitle, setToggleAddJobTitle] = useState(false);
  let [daysNotCreatedDueToLeave, setDaysNotCreatedDueToLeave] = useState([]);

  useEffect(() => {
    setModalOpen((x) => true);

    return () => {
      setModalOpen((x) => false);
    };
  }, [setModalOpen]);
  let [sureDeleteTemplate, setSureDeleteTemplate] = useState("");

  let [hide, setHide] = useState(window.innerWidth < 820);
  useEffect(() => {
    if (dataLoaded && imagesLoaded === 12) {
      setLoading(false);
      setTimeout(() => {
        setHide(false);
      }, 150);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyClose}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={bin1}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaCoffee}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyAdd}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={desktopBurger}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={pin}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaTag}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={remove}
        alt="Remove"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={templateArrow1}
        alt="Template Arrow 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={templateArrow2}
        alt="Template Arrow 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={publishTick1}
        alt="Publish Tick 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={publishTick2}
        alt="Publish Tick 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  let [savingTemplate, setSavingTemplate] = useState(saveTemplateObj.saving);

  let [templateName, setTemplateName] = useState(
    dateStringer.createShortStripFromDateString(saveTemplateObj.monWeek)
  );

  let [description, setDescription] = useState("");

  let [deleteExisting, setDeleteExisting] = useState(false);
  let [allJobTitles, setAllJobTitles] = useState([]);
  // ... this means to delete existing shifts when loading a template

  let [qty, setQty] = useState({});
  let [monWeeksToPublish, setMonWeeksToPublish] = useState([]);
  const inputRef = useRef();

  useEffect(() => {
    // clean not needed
    setSavingTemplate(saveTemplateObj.saving);
  }, [saveTemplateObj]);

  let [shellMode, setShellMode] = useState(false);

  let [includeShifts, setIncludeShifts] = useState(true);
  let [includeOvertimes, setIncludeOvertimes] = useState(true);
  let [includeTils, setIncludeTils] = useState(true);
  let [includeOpens, setIncludeOpens] = useState(true);

  let [closedDays, setClosedDays] = useState([]);
  let [templates, setTemplates] = useState([]);
  let [shells, setShells] = useState([]);
  let [selectedShellID, setSelectedShellID] = useState("");
  let [selectedTemplate, setSelectedTemplate] = useState("");
  let [deleteTemplate, setDeleteTemplate] = useState("");
  let [deleteShell, setDeleteShell] = useState("");

  let [tempDataUsers, setTempDataUsers] = useState([]);
  let [selectedTemplateData, setSelectedTemplateData] = useState({});

  let [usersNoLongerInTeam, setUsersNoLongerInTeam] = useState([]);

  let [previewTemplateData, setPreviewTemplateData] = useState([]); // grouped by name: shifts, tils, overtimes etc
  let [previewOpens, setPreviewOpens] = useState([]);
  let mobileWidthBreak = 820;
  const [width, setWidth] = useState(window.innerWidth);
  let [loadWeeksQty, setLoadWeeksQty] = useState(1);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  let shortenDesciption = (words) => {
    if (words.length > 60) {
      return `${words.substr(0, 55)}...`;
    } else {
      return words;
    }
  };

  useEffect(() => {
    // clean not needed
    if (saveTemplateObj.loading && selectedTemplate) {
      axios
        .post(
          `${serverURL}/load-template-preview`,
          {
            templateID: selectedTemplate,
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            let temp = response.data.template;

            let previewOpensLocal = [{ initial: true }];
            temp[0] &&
              temp[0].dayData.forEach((day) => {
                previewOpensLocal.push({
                  dayNum: day.dayNum,
                  opens: day.opens,
                  order: day.dayNum === 0 ? 99 : day.dayNum,
                });
              });

            previewOpensLocal.sort(function (a, b) {
              return b.order - a.order;
            });

            setPreviewOpens(previewOpensLocal);
            let tempObj = temp[0];

            setSelectedTemplateData(tempObj);
            setDeleteTemplate("");
            let userIDs = [];
            let userObjs = [];

            let dayNumsArr = [0, 1, 2, 3, 4, 5, 6];

            dayNumsArr.forEach((dayN) => {
              temp[0] &&
                temp[0].dayData[dayN].shifts.forEach((item) => {
                  if (!userIDs.includes(item.userID)) {
                    userIDs.push(item.userID);
                    userObjs.push({
                      userID: item.userID,
                      fName: item.fName,
                      lName: item.lName,
                    });
                  }
                });

              temp[0] &&
                temp[0].dayData[dayN].tils.forEach((item) => {
                  if (!userIDs.includes(item.userID)) {
                    userIDs.push(item.userID);
                    userObjs.push({
                      userID: item.userID,
                      fName: item.fName,
                      lName: item.lName,
                    });
                  }
                });
              temp[0] &&
                temp[0].dayData[dayN].overtimes.forEach((item) => {
                  if (!userIDs.includes(item.userID)) {
                    userIDs.push(item.userID);
                    userObjs.push({
                      userID: item.userID,
                      fName: item.fName,
                      lName: item.lName,
                    });
                  }
                });

              setTempDataUsers(userObjs);
            });

            temp[0] &&
              userObjs.forEach((user) => {
                let counter = 0;
                let allDays = [
                  ...temp[0].dayData[0].shifts,
                  ...temp[0].dayData[0].tils,
                  ...temp[0].dayData[0].overtimes,
                  ...temp[0].dayData[1].shifts,
                  ...temp[0].dayData[1].tils,
                  ...temp[0].dayData[1].overtimes,
                  ...temp[0].dayData[2].shifts,
                  ...temp[0].dayData[2].tils,
                  ...temp[0].dayData[2].overtimes,
                  ...temp[0].dayData[3].shifts,
                  ...temp[0].dayData[3].tils,
                  ...temp[0].dayData[3].overtimes,
                  ...temp[0].dayData[4].shifts,
                  ...temp[0].dayData[4].tils,
                  ...temp[0].dayData[4].overtimes,
                  ...temp[0].dayData[5].shifts,
                  ...temp[0].dayData[5].tils,
                  ...temp[0].dayData[5].overtimes,
                  ...temp[0].dayData[6].shifts,
                  ...temp[0].dayData[6].tils,
                  ...temp[0].dayData[6].overtimes,
                ];

                allDays.forEach((d) => {
                  //   if()
                  // alfie
                });
              });

            let userRows = [];
            userObjs.forEach((usr) => {
              let fName = usr.fName;
              let lName = usr.lName;
              let userID = usr.userID;

              let monData = [];
              let tueData = [];
              let wedData = [];
              let thuData = [];
              let friData = [];
              let satData = [];
              let sunData = [];

              dayNumsArr.forEach((dayN2) => {
                let shifts =
                  temp[0] &&
                  temp[0].dayData[dayN2].shifts.filter((obj) => {
                    return obj.userID === usr.userID;
                  });

                let tils =
                  temp[0] &&
                  temp[0].dayData[dayN2].tils.filter((obj) => {
                    return obj.userID === usr.userID;
                  });

                let overtimes =
                  temp[0] &&
                  temp[0].dayData[dayN2].overtimes.filter((obj) => {
                    return obj.userID === usr.userID;
                  });

                if (dayN2 === 0) {
                  shifts.forEach((item) => {
                    sunData.push({ item, type: "shift" });
                  });
                  tils.forEach((item) => {
                    sunData.push({ item, type: "til" });
                  });
                  overtimes.forEach((item) => {
                    sunData.push({ item, type: "overtime" });
                  });
                }
                if (dayN2 === 1) {
                  shifts.forEach((item) => {
                    monData.push({ item, type: "shift" });
                  });
                  tils.forEach((item) => {
                    monData.push({ item, type: "til" });
                  });
                  overtimes.forEach((item) => {
                    monData.push({ item, type: "overtime" });
                  });
                }
                if (dayN2 === 2) {
                  shifts.forEach((item) => {
                    tueData.push({ item, type: "shift" });
                  });
                  tils.forEach((item) => {
                    tueData.push({ item, type: "til" });
                  });
                  overtimes.forEach((item) => {
                    tueData.push({ item, type: "overtime" });
                  });
                }
                if (dayN2 === 3) {
                  shifts.forEach((item) => {
                    wedData.push({ item, type: "shift" });
                  });
                  tils.forEach((item) => {
                    wedData.push({ item, type: "til" });
                  });
                  overtimes.forEach((item) => {
                    wedData.push({ item, type: "overtime" });
                  });
                }
                if (dayN2 === 4) {
                  shifts.forEach((item) => {
                    thuData.push({ item, type: "shift" });
                  });
                  tils.forEach((item) => {
                    thuData.push({ item, type: "til" });
                  });
                  overtimes.forEach((item) => {
                    thuData.push({ item, type: "overtime" });
                  });
                }
                if (dayN2 === 5) {
                  shifts.forEach((item) => {
                    friData.push({ item, type: "shift" });
                  });
                  tils.forEach((item) => {
                    friData.push({ item, type: "til" });
                  });
                  overtimes.forEach((item) => {
                    friData.push({ item, type: "overtime" });
                  });
                }
                if (dayN2 === 6) {
                  shifts.forEach((item) => {
                    satData.push({ item, type: "shift" });
                  });
                  tils.forEach((item) => {
                    satData.push({ item, type: "til" });
                  });
                  overtimes.forEach((item) => {
                    satData.push({ item, type: "overtime" });
                  });
                }
              });

              userRows.push({
                fName,
                lName,
                userID,
                monData,
                tueData,
                wedData,
                thuData,
                friData,
                satData,
                sunData,
              });
            });

            setPreviewTemplateData(userRows);
          }
        });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    // cleaned
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let [teamName, setTeamName] = useState("");
  let [locationName, setLocationName] = useState("");
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    // inputRef.current.focus();
    // inputRef.current.select();
    if (saveTemplateObj.saving) {
      let monShifts = 0;
      let monOvertimes = 0;
      let monTils = 0;
      let monOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.mon &&
          saveTemplateObj.opens.mon.length) ||
        0;

      let tueShifts = 0;
      let tueOvertimes = 0;
      let tueTils = 0;
      let tueOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.tue &&
          saveTemplateObj.opens.tue.length) ||
        0;

      let wedShifts = 0;
      let wedOvertimes = 0;
      let wedTils = 0;
      let wedOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.wed &&
          saveTemplateObj.opens.wed.length) ||
        0;

      let thuShifts = 0;
      let thuOvertimes = 0;
      let thuTils = 0;
      let thuOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.thu &&
          saveTemplateObj.opens.thu.length) ||
        0;

      let friShifts = 0;
      let friOvertimes = 0;
      let friTils = 0;
      let friOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.fri &&
          saveTemplateObj.opens.fri.length) ||
        0;

      let satShifts = 0;
      let satOvertimes = 0;
      let satTils = 0;
      let satOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.sat &&
          saveTemplateObj.opens.sat.length) ||
        0;

      let sunShifts = 0;
      let sunOvertimes = 0;
      let sunTils = 0;
      let sunOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.sun &&
          saveTemplateObj.opens.sun.length) ||
        0;

      saveTemplateObj.shifts.forEach((user) => {
        user.monItems.forEach((item) => {
          if (item.type === "shift") {
            monShifts++;
          }
          if (item.type === "overtime") {
            monOvertimes++;
          }
          if (item.type === "til") {
            monTils++;
          }
        });

        user.tueItems.forEach((item) => {
          if (item.type === "shift") {
            tueShifts++;
          }
          if (item.type === "overtime") {
            tueOvertimes++;
          }
          if (item.type === "til") {
            tueTils++;
          }
        });

        user.wedItems.forEach((item) => {
          if (item.type === "shift") {
            wedShifts++;
          }
          if (item.type === "overtime") {
            wedOvertimes++;
          }
          if (item.type === "til") {
            wedTils++;
          }
        });

        user.thuItems.forEach((item) => {
          if (item.type === "shift") {
            thuShifts++;
          }
          if (item.type === "overtime") {
            thuOvertimes++;
          }
          if (item.type === "til") {
            thuTils++;
          }
        });

        user.friItems.forEach((item) => {
          if (item.type === "shift") {
            friShifts++;
          }
          if (item.type === "overtime") {
            friOvertimes++;
          }
          if (item.type === "til") {
            friTils++;
          }
        });

        user.satItems.forEach((item) => {
          if (item.type === "shift") {
            satShifts++;
          }
          if (item.type === "overtime") {
            satOvertimes++;
          }
          if (item.type === "til") {
            satTils++;
          }
        });

        user.sunItems.forEach((item) => {
          if (item.type === "shift") {
            sunShifts++;
          }
          if (item.type === "overtime") {
            sunOvertimes++;
          }
          if (item.type === "til") {
            sunTils++;
          }
        });
      });

      setQty({
        monShifts,
        monOvertimes,
        monTils,
        monOpens,
        tueShifts,
        tueOvertimes,
        tueTils,
        tueOpens,
        wedShifts,
        wedOvertimes,
        wedTils,
        wedOpens,
        thuShifts,
        thuOvertimes,
        thuTils,
        thuOpens,
        friShifts,
        friOvertimes,
        friTils,
        friOpens,
        satShifts,
        satOvertimes,
        satTils,
        satOpens,
        sunShifts,
        sunOvertimes,
        sunTils,
        sunOpens,
      });
    }

    if (saveTemplateObj.loading) {
      axios
        .post(
          `${serverURL}/load-templates`,
          {
            teamID: saveTemplateObj.teamID,
            locationID: saveTemplateObj.locationID,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setTemplates(response.data.templates);

            setDataLoaded(true);
            setLocationName(response.data.locationName);
            setTeamName(response.data.teamName);
            let userIDs = [];
            // response.data.templates.forEach((day) => {});
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [saveTemplateObj]);

  let [saved, setSaved] = useState(false);

  let shortenName = (name) => {
    if (shellMode) {
      return `${dateStringer.pluraliseNoun(name)}`;
    } else {
      if (name.length > 8) {
        return `${name.substr(0, 6)}...`;
      } else {
        return name;
      }
    }
  };

  let openItemsQtr = () => {
    let count = 0;
    previewOpens.forEach((op) => {
      if (!op.initial) {
        if (op.opens.length > count) {
          count = op.opens.length;
        }
      }
    });

    return count;
  };

  let generateOpensRow =
    previewOpens[0] &&
    previewOpens.map((op, i) => {
      if (op.initial) {
        return (
          <div className="a1previewOpensRowCell" key={i}>
            <p className="templateOpenShiftsTitle">Open shifts</p>
          </div>
        );
      } else {
        let generateOpenItems = op.opens[0] ? (
          op.opens.map((open, ind) => {
            // if (ind !== 0) {
            return (
              <div key={ind} className="templateItem">
                <p
                  className={`templateStart ${
                    dateStringer.dsToTimeStrip(open.start).length > 4
                      ? "tempTimeSmaller"
                      : ""
                  }`}
                >
                  {dateStringer.dsToTimeStrip(open.start, true)}
                </p>{" "}
                <span className="templateBetweenLine">-</span>
                <p
                  className={`templateEnd ${
                    dateStringer.dsToTimeStrip(open.end, true).length > 4
                      ? "tempTimeSmaller"
                      : ""
                  }`}
                >
                  {dateStringer.dsToTimeStrip(open.end, true)}
                </p>
                <p className={`templateType templateOpenTxt`}>OPEN</p>
              </div>
            );
            // }
          })
        ) : (
          <div className="dudTemplateItem">-</div>
        );

        return (
          //   <div>
          <div className="previewOpenCell" key={i}>
            {generateOpenItems}
          </div>
          //   </div>
        );
      }
    });

  let generateUserRows = previewTemplateData.map((user, i) => {
    let generateUserItems = (user, day) => {
      let obj = [];
      if (day === "mon") {
        obj = user.monData;
      }
      if (day === "tue") {
        obj = user.tueData;
      }
      if (day === "wed") {
        obj = user.wedData;
      }
      if (day === "thu") {
        obj = user.thuData;
      }
      if (day === "fri") {
        obj = user.friData;
      }
      if (day === "sat") {
        obj = user.satData;
      }
      if (day === "sun") {
        obj = user.sunData;
      }

      if (!obj[0]) {
        return (
          <div className={`templateUserDateCell`}>
            <div className="dudTemplateItem">-</div>
          </div>
        );
      }
      return obj.map((item, i) => {
        return (
          <div key={i} className="templateItem">
            <p
              className={`templateStart ${
                dateStringer.dsToTimeStrip(item.item.start, true).length > 4
                  ? "tempTimeSmaller"
                  : ""
              }`}
            >
              {dateStringer.dsToTimeStrip(item.item.start, true)}
            </p>{" "}
            <span className="templateBetweenLine">-</span>
            <p
              className={`templateEnd ${
                dateStringer.dsToTimeStrip(item.item.end, true).length > 4
                  ? "tempTimeSmaller"
                  : ""
              }`}
            >
              {dateStringer.dsToTimeStrip(item.item.end, true)}
            </p>
            <p
              className={`templateType ${
                item.type !== "shift" ? "tilTypeStripInLoadTemplate" : ""
              }`}
            >{`${
              item.type === "shift"
                ? "SHIFT"
                : item.type === "til"
                ? "LIEU"
                : "OVER"
            }`}</p>
          </div>
        );
      });
    };

    let dayItemsQty = (uid) => {
      // curry
      let count = 0;
      let userArr = previewTemplateData.filter((item) => {
        return item.userID === uid;
      });
      userArr.forEach((usr) => {
        let arrOfArr = [
          usr.monData,
          usr.tueData,
          usr.wedData,
          usr.thuData,
          usr.friData,
          usr.satData,
          usr.sunData,
        ];
        arrOfArr.forEach((arr) => {
          if (arr.length > count) {
            count = arr.length;
          }
        });
      });

      return count;
    };

    return (
      <div
        key={user.userID}
        className={`templateUserRow ${
          dayItemsQty(user.userID) === 2 && "templateUserRow2items"
        }  ${dayItemsQty(user.userID) === 3 && "templateUserRow3items"}  ${
          dayItemsQty(user.userID) === 4 && "templateUserRow4items"
        }  ${dayItemsQty(user.userID) === 5 && "templateUserRow5items"}  ${
          dayItemsQty(user.userID) === 6 && "templateUserRow6items"
        }`}
      >
        <div className="templateNameCell">
          <p className="templateUserRowfName">{shortenName(user.fName)}</p>
          <p className="templateUserRowlName">{shortenName(user.lName)}</p>
          {/* <p className="templateUserRowlName">{dayItemsQty(user.userID)}</p> */}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "mon")}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "tue")}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "wed")}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "thu")}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "fri")}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "sat")}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "sun")}
        </div>
      </div>
    );
  });

  let [showShifts, setShowShifts] = useState([]);
  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      setSaveTemplateObj((x) => {
        return {};
      });
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
      setDragPosition(0);
    }
  };

  let [nameTaken, setNameTaken] = useState(false);
  let [sureDeleteShell, setSureDeleteShell] = useState(false);
  let [showExistingShells, setShowExistingShells] = useState(false);
  let [existingShells, setExistingShells] = useState([]);

  let arr = shellMode ? shells : templates;

  let generateTemplates = arr.map((temp) => {
    return (
      <div
        key={temp.templateID || temp.shellID}
        className={`templateOption ${
          !shellMode && selectedTemplate === temp.templateID
            ? "selectedTemplateUnit"
            : ""
        }  ${
          shellMode && selectedShellID === temp.shellID
            ? "selectedTemplateUnit"
            : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          if (!shellMode) {
            if (temp.templateID === selectedTemplate) {
              setSelectedTemplate("");
            } else {
              let originalData = previewTemplateData;
              let originalOpens = previewOpens;
              setPreviewTemplateData([]);
              setPreviewOpens([]);
              setSelectedTemplate(temp.templateID);
            }

            setDeleteTemplate("");
          }

          if (shellMode) {
            if (temp.shellID === selectedShellID) {
              setSelectedShellID("");
            } else {
              setSelectedShellID(temp.shellID);
              axios
                .post(
                  `${serverURL}/open-shell`,
                  {
                    shellID: temp.shellID,
                  },
                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message.includes("logout")) {
                    window.location.href = "/signin";
                  }
                  if (response.data.message === "success") {
                    setTemplateName(response.data.shell.name);
                    setDescription(response.data.shell.description);
                    let arr = [
                      ...response.data.shell.mon,
                      ...response.data.shell.tue,
                      ...response.data.shell.wed,
                      ...response.data.shell.thu,
                      ...response.data.shell.fri,
                      ...response.data.shell.sat,
                      ...response.data.shell.sun,
                    ];
                    let jts = [];
                    arr.forEach((x) => {
                      if (!jts.includes(x.jt)) {
                        jts.push(x.jt);
                      }
                    });
                    setJobTitles(jts);
                    setShell({
                      loaded: true,
                    });

                    // - - - - - - - - -
                    let userObjs = [];
                    let userIDs = [];

                    if (Array.isArray(arr)) {
                      arr.forEach((item) => {
                        if (!userIDs.includes(item.id)) {
                          userIDs.push(item.jt);
                          userObjs.push({
                            userID: item.jt,
                            fName: item.jt,
                            lName: "",
                          });
                        }
                      });
                    }
                    setTempDataUsers(userObjs);
                    let userRows = [];
                    jts.forEach((jt) => {
                      let fName = jt;
                      let lName = "";
                      let userID = jt;

                      let monData = [];
                      let tueData = [];
                      let wedData = [];
                      let thuData = [];
                      let friData = [];
                      let satData = [];
                      let sunData = [];

                      response.data.shell.mon.forEach((x) => {
                        if (x.jt === jt) {
                          monData.push({
                            item: {
                              type: x.type,
                              start: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.start.substr(
                                0,
                                2
                              )}M${x.start.substr(3, 2)}`,
                              end: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.end.substr(
                                0,
                                2
                              )}M${x.end.substr(3, 2)}`,
                              brk: parseInt(x.brk),
                              userID,
                              fName,
                              lName,
                              tags: x.tags,
                              durMins: x.durMins || 0,
                              nightShift: x.nightShift,
                              rate: x.rate,
                              teamID: response.data.shell.teamID,
                            },
                            type: x.type,
                          });
                        }
                      });

                      response.data.shell.tue.forEach((x) => {
                        if (x.jt === jt) {
                          tueData.push({
                            item: {
                              type: x.type,
                              start: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.start.substr(
                                0,
                                2
                              )}M${x.start.substr(3, 2)}`,
                              end: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.end.substr(
                                0,
                                2
                              )}M${x.end.substr(3, 2)}`,
                              brk: parseInt(x.brk),
                              userID,
                              fName,
                              lName,
                              tags: x.tags,
                              durMins: x.durMins || 0,
                              nightShift: x.nightShift,
                              rate: x.rate,
                              teamID: response.data.shell.teamID,
                            },
                            type: x.type,
                          });
                        }
                      });
                      response.data.shell.wed.forEach((x) => {
                        if (x.jt === jt) {
                          wedData.push({
                            item: {
                              type: x.type,
                              start: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.start.substr(
                                0,
                                2
                              )}M${x.start.substr(3, 2)}`,
                              end: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.end.substr(
                                0,
                                2
                              )}M${x.end.substr(3, 2)}`,
                              brk: parseInt(x.brk),
                              userID,
                              fName,
                              lName,
                              tags: x.tags,
                              durMins: x.durMins || 0,
                              nightShift: x.nightShift,
                              rate: x.rate,
                              teamID: response.data.shell.teamID,
                            },
                            type: x.type,
                          });
                        }
                      });
                      response.data.shell.thu.forEach((x) => {
                        if (x.jt === jt) {
                          thuData.push({
                            item: {
                              type: x.type,
                              start: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.start.substr(
                                0,
                                2
                              )}M${x.start.substr(3, 2)}`,
                              end: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.end.substr(
                                0,
                                2
                              )}M${x.end.substr(3, 2)}`,
                              brk: parseInt(x.brk),
                              userID,
                              fName,
                              lName,
                              tags: x.tags,
                              durMins: x.durMins || 0,
                              nightShift: x.nightShift,
                              rate: x.rate,
                              teamID: response.data.shell.teamID,
                            },
                            type: x.type,
                          });
                        }
                      });
                      response.data.shell.fri.forEach((x) => {
                        if (x.jt === jt) {
                          friData.push({
                            item: {
                              type: x.type,
                              start: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.start.substr(
                                0,
                                2
                              )}M${x.start.substr(3, 2)}`,
                              end: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.end.substr(
                                0,
                                2
                              )}M${x.end.substr(3, 2)}`,
                              brk: parseInt(x.brk),
                              userID,
                              fName,
                              lName,
                              tags: x.tags,
                              durMins: x.durMins || 0,
                              nightShift: x.nightShift,
                              rate: x.rate,
                              teamID: response.data.shell.teamID,
                            },
                            type: x.type,
                          });
                        }
                      });
                      response.data.shell.sat.forEach((x) => {
                        if (x.jt === jt) {
                          satData.push({
                            item: {
                              type: x.type,
                              start: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.start.substr(
                                0,
                                2
                              )}M${x.start.substr(3, 2)}`,
                              end: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.end.substr(
                                0,
                                2
                              )}M${x.end.substr(3, 2)}`,
                              brk: parseInt(x.brk),
                              userID,
                              fName,
                              lName,
                              tags: x.tags,
                              durMins: x.durMins || 0,
                              nightShift: x.nightShift,
                              rate: x.rate,
                              teamID: response.data.shell.teamID,
                            },
                            type: x.type,
                          });
                        }
                      });
                      response.data.shell.sun.forEach((x) => {
                        if (x.jt === jt) {
                          sunData.push({
                            item: {
                              type: x.type,
                              start: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.start.substr(
                                0,
                                2
                              )}M${x.start.substr(3, 2)}`,
                              end: `${dateStringer
                                .createStringFromTimestamp(new Date().getTime())
                                .substring(0, 11)}H${x.end.substr(
                                0,
                                2
                              )}M${x.end.substr(3, 2)}`,
                              brk: parseInt(x.brk),
                              userID,
                              fName,
                              lName,
                              tags: x.tags,
                              durMins: x.durMins || 0,
                              nightShift: x.nightShift,
                              rate: x.rate,
                              teamID: response.data.shell.teamID,
                            },
                            type: x.type,
                          });
                        }
                      });

                      let hasItem = [
                        ...monData,
                        ...tueData,
                        ...wedData,
                        ...thuData,
                        ...friData,
                        ...satData,
                        ...sunData,
                      ][0]
                        ? true
                        : false;
                      if (hasItem) {
                        userRows.push({
                          fName,
                          lName,
                          userID,
                          monData,
                          tueData,
                          wedData,
                          thuData,
                          friData,
                          satData,
                          sunData,
                        });
                      }
                    });

                    // - - - - - - - -

                    setPreviewTemplateData(userRows);
                  }
                });
            }
          }
        }}
      >
        <div className="templateOptionLeft">
          <p className="templateUnitName">{temp.name}</p>
          <p className="templateUnitDescription">
            {shortenDesciption(temp.description)}
          </p>
        </div>
        <div className="templateOptionRight">
          <img
            src={
              (shellMode && selectedShellID === temp.shellID) ||
              (!shellMode && selectedTemplate === temp.templateID)
                ? templateArrow2
                : templateArrow1
            }
            className={`${
              window.innerWidth < 820
                ? "tempArrowImgDownWide"
                : "tempArrowImgDownMob"
            } ${
              (!shellMode && deleteTemplate === temp.templateID) ||
              (shellMode && deleteTemplate === temp.shellID)
                ? "none"
                : ""
            }`}
            alt="select"
          />

          <img
            src={remove}
            className={`removeTemplateImg ${
              (!shellMode && deleteTemplate === temp.templateID) ||
              (shellMode && deleteTemplate === temp.shellID)
                ? "deleteSelectedBorderBin"
                : ""
            }`}
            alt="remove template"
            onClick={(e) => {
              e.stopPropagation();

              if (shellMode) {
                setSureDeleteShell(temp.shellID);
              } else {
                setSureDeleteTemplate(temp.templateID);
              }
            }}
          />
        </div>
      </div>
    );
  });

  useEffect(() => {
    if (saveTemplateObj.saving && !saveTemplateObj.loading && !shellMode) {
      let userIDs = [];
      let userObjs = [];

      let dayNumsArr = [0, 1, 2, 3, 4, 5, 6];

      if (Array.isArray(saveTemplateObj.shifts)) {
        saveTemplateObj.shifts.forEach((item) => {
          if (!userIDs.includes(item.userID)) {
            userIDs.push(item.userID);
            userObjs.push({
              userID: item.userID,
              fName: item.fName,
              lName: item.lName,
            });
          }
        });
      }
      setTempDataUsers(userObjs);

      let userRows = [];
      userObjs.forEach((usr) => {
        let fName = usr.fName;
        let lName = usr.lName;
        let userID = usr.userID;

        let monData = [];
        let tueData = [];
        let wedData = [];
        let thuData = [];
        let friData = [];
        let satData = [];
        let sunData = [];

        let userItemsFilt = saveTemplateObj.shifts.filter((x) => {
          return x.userID === usr.userID;
        });

        userItemsFilt.forEach((item) => {
          item.monItems.forEach((x) => {
            monData.push({
              item: {
                type: x.type,
                start: x.start,
                end: x.end,
                brk: x.break || x.brk,
                userID,
                fName,
                lName,
                tags: x.tags,
                durMins: x.durMins,
                nightShift: x.nightShift,
                rate: x.rate,
                teamID: x.teamID,
              },
              type: x.type,
            });
          });
          item.tueItems.forEach((x) => {
            tueData.push({
              item: {
                type: x.type,
                start: x.start,
                end: x.end,
                brk: x.break || x.brk,
                userID,
                fName,
                lName,
                tags: x.tags,
                durMins: x.durMins,
                nightShift: x.nightShift,
                rate: x.rate,
                teamID: x.teamID,
              },
              type: x.type,
            });
          });
          item.wedItems.forEach((x) => {
            wedData.push({
              item: {
                type: x.type,
                start: x.start,
                end: x.end,
                brk: x.break || x.brk,
                userID,
                fName,
                lName,
                tags: x.tags,
                durMins: x.durMins,
                nightShift: x.nightShift,
                rate: x.rate,
                teamID: x.teamID,
              },
              type: x.type,
            });
          });
          item.thuItems.forEach((x) => {
            thuData.push({
              item: {
                type: x.type,
                start: x.start,
                end: x.end,
                brk: x.break || x.brk,
                userID,
                fName,
                lName,
                tags: x.tags,
                durMins: x.durMins,
                nightShift: x.nightShift,
                rate: x.rate,
                teamID: x.teamID,
              },
              type: x.type,
            });
          });
          item.friItems.forEach((x) => {
            friData.push({
              item: {
                type: x.type,
                start: x.start,
                end: x.end,
                brk: x.break || x.brk,
                userID,
                fName,
                lName,
                tags: x.tags,
                durMins: x.durMins,
                nightShift: x.nightShift,
                rate: x.rate,
                teamID: x.teamID,
              },
              type: x.type,
            });
          });
          item.satItems.forEach((x) => {
            satData.push({
              item: {
                type: x.type,
                start: x.start,
                end: x.end,
                brk: x.break || x.brk,
                userID,
                fName,
                lName,
                tags: x.tags,
                durMins: x.durMins,
                nightShift: x.nightShift,
                rate: x.rate,
                teamID: x.teamID,
              },
              type: x.type,
            });
          });
          item.sunItems.forEach((x) => {
            sunData.push({
              item: {
                type: x.type,
                start: x.start,
                end: x.end,
                brk: x.type === "shift" ? x.break : x.brk,
                userID,
                fName,
                lName,
                tags: x.tags,
                durMins: x.durMins,
                nightShift: x.nightShift,
                rate: x.rate,
                teamID: x.teamID,
              },
              type: x.type,
            });
          });
        });

        let hasItem = [
          ...monData,
          ...tueData,
          ...wedData,
          ...thuData,
          ...friData,
          ...satData,
          ...sunData,
        ][0]
          ? true
          : false;
        if (hasItem) {
          userRows.push({
            fName,
            lName,
            userID,
            monData,
            tueData,
            wedData,
            thuData,
            friData,
            satData,
            sunData,
          });
        }
      });

      // do opens now
      let dayNum0 = saveTemplateObj.opens.sun; // sun
      let dayNum1 = saveTemplateObj.opens.mon; // mon
      let dayNum2 = saveTemplateObj.opens.tue; // tue
      let dayNum3 = saveTemplateObj.opens.wed; // wed
      let dayNum4 = saveTemplateObj.opens.thu; // thu
      let dayNum5 = saveTemplateObj.opens.fri; // fri
      let dayNum6 = saveTemplateObj.opens.sat; // sat

      let dayNum0formatted = [];
      let dayNum1formatted = [];
      let dayNum2formatted = [];
      let dayNum3formatted = [];
      let dayNum4formatted = [];
      let dayNum5formatted = [];
      let dayNum6formatted = [];

      dayNum0.forEach((x) => {
        dayNum0formatted.push({
          brk: parseInt(x.brk),
          type: x.type,
          start: x.startDs,
          end: x.endDs,
          durMins: x.durMins,
          tags: x.tags,
          teamID: x.teamID,
        });
      });

      dayNum1.forEach((x) => {
        dayNum1formatted.push({
          brk: parseInt(x.brk),
          type: x.type,
          start: x.startDs,
          end: x.endDs,
          durMins: x.durMins,
          tags: x.tags,
          teamID: x.teamID,
        });
      });

      dayNum2.forEach((x) => {
        dayNum2formatted.push({
          brk: parseInt(x.brk),
          type: x.type,
          start: x.startDs,
          end: x.endDs,
          durMins: x.durMins,
          tags: x.tags,
          teamID: x.teamID,
        });
      });

      dayNum3.forEach((x) => {
        dayNum3formatted.push({
          brk: parseInt(x.brk),
          type: x.type,
          start: x.startDs,
          end: x.endDs,
          durMins: x.durMins,
          tags: x.tags,
          teamID: x.teamID,
        });
      });
      dayNum4.forEach((x) => {
        dayNum4formatted.push({
          brk: parseInt(x.brk),
          type: x.type,
          start: x.startDs,
          end: x.endDs,
          durMins: x.durMins,
          tags: x.tags,
          teamID: x.teamID,
        });
      });
      dayNum5.forEach((x) => {
        dayNum5formatted.push({
          brk: parseInt(x.brk),
          type: x.type,
          start: x.startDs,
          end: x.endDs,
          durMins: x.durMins,
          tags: x.tags,
          teamID: x.teamID,
        });
      });
      dayNum6.forEach((x) => {
        dayNum6formatted.push({
          brk: parseInt(x.brk),
          type: x.type,
          start: x.startDs,
          end: x.endDs,
          durMins: x.durMins,
          tags: x.tags,
          teamID: x.teamID,
        });
      });

      let opensPreview = [
        { initial: true },
        { dayNum: 0, opens: dayNum0formatted, order: 99 },
        { dayNum: 1, opens: dayNum1formatted, order: 1 },
        { dayNum: 2, opens: dayNum2formatted, order: 2 },
        { dayNum: 3, opens: dayNum3formatted, order: 3 },
        { dayNum: 4, opens: dayNum4formatted, order: 4 },
        { dayNum: 5, opens: dayNum5formatted, order: 5 },
        { dayNum: 6, opens: dayNum6formatted, order: 6 },
      ];

      // saveTemplateObj.opens.forEach((x) => {});
      // lily
      setPreviewOpens(opensPreview);
      setPreviewTemplateData(userRows);
    }
  }, [shellMode]);

  useEffect(() => {
    if (shellMode && !shells[0] && saveTemplateObj.loading) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/load-shells`,
          {
            teamID: saveTemplateObj.teamID,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setShells(response.data.shells);

            setDataLoaded(true);
            // setLocationName(response.data.locationName);
            setTeamName(response.data.teamName);
            let userIDs = [];
            // response.data.templates.forEach((day) => {});
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [shellMode]);

  useEffect(() => {
    if (saveTemplateObj.shell) {
      setShellMode(true);
      setShowExistingShells(false);

      setTemplateName("");
    }
    loadShellFromObj();
  }, []);

  let loadShellFromObj = () => {
    let schedJobTitles = [];

    let mon = [];
    let tue = [];
    let wed = [];
    let thu = [];
    let fri = [];
    let sat = [];
    let sun = [];

    let sched = saveTemplateObj.shifts;

    sched.forEach((x) => {
      if (!schedJobTitles.includes(x.jobTitle)) {
        schedJobTitles.push(x.jobTitle);
      }
    });
    schedJobTitles.forEach((jt) => {
      let schedJtFilt = sched.filter((x) => x.jobTitle === jt);
      schedJtFilt.forEach((x) => {
        x.monItems.forEach((item) => {
          mon.push({
            type: item.type,
            start: `${item.start.substr(12, 2)}:${item.start.substr(15, 2)}`,
            end: `${item.end.substr(12, 2)}:${item.end.substr(15, 2)}`,
            locationID: item.locationID,
            tags: item.tags,
            brk: item.break,
            jt: jt,
            id: dateStringer.generateUniqueID(),
          });
        });
        x.tueItems.forEach((item) => {
          tue.push({
            type: item.type,
            start: `${item.start.substr(12, 2)}:${item.start.substr(15, 2)}`,
            end: `${item.end.substr(12, 2)}:${item.end.substr(15, 2)}`,
            locationID: item.locationID,
            tags: item.tags,
            brk: item.break,
            jt: jt,
            id: dateStringer.generateUniqueID(),
          });
        });
        x.wedItems.forEach((item) => {
          wed.push({
            type: item.type,
            start: `${item.start.substr(12, 2)}:${item.start.substr(15, 2)}`,
            end: `${item.end.substr(12, 2)}:${item.end.substr(15, 2)}`,
            locationID: item.locationID,
            tags: item.tags,
            brk: item.break,
            jt: jt,
            id: dateStringer.generateUniqueID(),
          });
        });
        x.thuItems.forEach((item) => {
          thu.push({
            type: item.type,
            start: `${item.start.substr(12, 2)}:${item.start.substr(15, 2)}`,
            end: `${item.end.substr(12, 2)}:${item.end.substr(15, 2)}`,
            locationID: item.locationID,
            tags: item.tags,
            brk: item.break,
            jt: jt,
            id: dateStringer.generateUniqueID(),
          });
        });
        x.friItems.forEach((item) => {
          fri.push({
            type: item.type,
            start: `${item.start.substr(12, 2)}:${item.start.substr(15, 2)}`,
            end: `${item.end.substr(12, 2)}:${item.end.substr(15, 2)}`,
            locationID: item.locationID,
            tags: item.tags,
            brk: item.break,
            jt: jt,
            id: dateStringer.generateUniqueID(),
          });
        });
        x.satItems.forEach((item) => {
          sat.push({
            type: item.type,
            start: `${item.start.substr(12, 2)}:${item.start.substr(15, 2)}`,
            end: `${item.end.substr(12, 2)}:${item.end.substr(15, 2)}`,
            locationID: item.locationID,
            tags: item.tags,
            brk: item.break,
            jt: jt,
            id: dateStringer.generateUniqueID(),
          });
        });
        x.sunItems.forEach((item) => {
          sun.push({
            type: item.type,
            start: `${item.start.substr(12, 2)}:${item.start.substr(15, 2)}`,
            end: `${item.end.substr(12, 2)}:${item.end.substr(15, 2)}`,
            locationID: item.locationID,
            tags: item.tags,
            brk: item.break,
            jt: jt,
            id: dateStringer.generateUniqueID(),
          });
        });
      });
    });

    setShell({ mon, tue, wed, thu, fri, sat, sun });
  };

  let [saveLoading, setSaveLoading] = useState(false);

  let [availableLocations, setAvailableLocations] = useState([]);

  let getLocationName = (locID) => {
    if (!locID) {
      return "No location";
    } else {
      let locFilt = availableLocations.filter((x) => {
        return x.locationID === locID;
      });
      if (locFilt[0]) {
        return locFilt[0].locationName;
      } else {
        return "No location";
      }
    }
  };
  let [jobTitles, setJobTitles] = useState([]);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (shellMode && !availableLocations[0]) {
      axios
        .post(
          `${serverURL}/get-locations`,
          {
            teamIDJobTitles: saveTemplateObj.teamID,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setAvailableLocations(response.data.locations || []);
            setJobTitles(
              response.data.jobTitles.sort((a, b) => {
                return a.localeCompare(b);
              }) || []
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [shellMode]);

  let [addShift, setAddShift] = useState({
    show: false,
    day: "", // mon, tue etc.
    jobTitle: "",
  });

  let [shell, setShell] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      mon: [
        // {
        //   start: "09:00",
        //   end: "17:00",
        //   brk: 60,
        //   tags: ["GMC duty", "Calling out sales"],
        //   locationID: "0342bb4e-a4ad-4552-a852-2881fcd159ee",
        //   jt: "Clinical Risk Manager",
        //   type: "shift",
        //   id: dateStringer.generateUniqueID(),
        // },
        // {
        //   start: "09:00",
        //   end: "17:00",
        //   brk: 60,
        //   tags: ["GMC duty", "Calling out sales"],
        //   locationID: "0342bb4e-a4ad-4552-a852-2881fcd159ee",
        //   jt: "Clinical Risk Manager",
        //   type: "overtime",
        //   id: dateStringer.generateUniqueID(),
        // },
        // {
        //   start: "09:00",
        //   end: "17:00",
        //   brk: 60,
        //   tags: ["GMC duty", "Calling out sales"],
        //   locationID: "0342bb4e-a4ad-4552-a852-2881fcd159ee",
        //   jt: "Beybalder",
        //   type: "til",
        //   id: dateStringer.generateUniqueID(),
        // },
        // {
        //   start: "09:00",
        //   end: "17:00",
        //   brk: 60,
        //   tags: [],
        //   locationID: "",
        //   jt: "Clinical Risk Manager",
        //   type: "shift",
        //   id: dateStringer.generateUniqueID(),
        // },
        // {
        //   start: "09:00",
        //   end: "17:00",
        //   brk: 60,
        //   tags: ["GMC duty", "Calling out sales"],
        //   locationID: "0342bb4e-a4ad-4552-a852-2881fcd159ee",
        //   jt: "Clinical Risk Manager",
        //   type: "shift",
        //   id: dateStringer.generateUniqueID(),
        // },
        // {
        //   start: "09:00",
        //   end: "17:00",
        //   brk: 60,
        //   tags: ["GMC duty", "Calling out sales"],
        //   locationID: "0342bb4e-a4ad-4552-a852-2881fcd159ee",
        //   jt: "Clinical Risk Manager",
        //   type: "shift",
        //   id: dateStringer.generateUniqueID(),
        // },
      ],
      tue: [
        // {
        //   start: "09:00",
        //   end: "17:00",
        //   brk: 60,
        //   tags: ["GMC duty", "Calling out sales"],
        //   locationID: "0342bb4e-a4ad-4552-a852-2881fcd159ee",
        //   jt: "Airline pilot",
        //   type: "shift",
        //   id: dateStringer.generateUniqueID(),
        // },
      ],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    }
  );

  let updateShell = (shellData) => {
    if (shellData.day) {
      if (shellData.day === "mon") {
        setShell({
          mon: [
            ...shell.mon,
            {
              start: shellData.start,
              end: shellData.end,
              brk: shellData.brk,
              tags: shellData.tags,
              locationID: shellData.locationID,
              jt: shellData.jt,
              id: dateStringer.generateUniqueID(),
              type: shellData.type,
            },
          ],
        });
      }
      if (shellData.day === "tue") {
        setShell({
          tue: [
            ...shell.tue,
            {
              start: shellData.start,
              end: shellData.end,
              brk: shellData.brk,
              tags: shellData.tags,
              locationID: shellData.locationID,
              jt: shellData.jt,
              id: dateStringer.generateUniqueID(),
              type: shellData.type,
            },
          ],
        });
      }
      if (shellData.day === "wed") {
        setShell({
          wed: [
            ...shell.wed,
            {
              start: shellData.start,
              end: shellData.end,
              brk: shellData.brk,
              tags: shellData.tags,
              locationID: shellData.locationID,
              jt: shellData.jt,
              id: dateStringer.generateUniqueID(),
              type: shellData.type,
            },
          ],
        });
      }
      if (shellData.day === "thu") {
        setShell({
          thu: [
            ...shell.thu,
            {
              start: shellData.start,
              end: shellData.end,
              brk: shellData.brk,
              tags: shellData.tags,
              locationID: shellData.locationID,
              jt: shellData.jt,
              id: dateStringer.generateUniqueID(),
              type: shellData.type,
            },
          ],
        });
      }
      if (shellData.day === "fri") {
        setShell({
          fri: [
            ...shell.fri,
            {
              start: shellData.start,
              end: shellData.end,
              brk: shellData.brk,
              tags: shellData.tags,
              locationID: shellData.locationID,
              jt: shellData.jt,
              id: dateStringer.generateUniqueID(),
              type: shellData.type,
            },
          ],
        });
      }
      if (shellData.day === "sat") {
        setShell({
          sat: [
            ...shell.sat,
            {
              start: shellData.start,
              end: shellData.end,
              brk: shellData.brk,
              tags: shellData.tags,
              locationID: shellData.locationID,
              jt: shellData.jt,
              id: dateStringer.generateUniqueID(),
              type: shellData.type,
            },
          ],
        });
      }
      if (shellData.day === "sun") {
        setShell({
          sun: [
            ...shell.sun,
            {
              start: shellData.start,
              end: shellData.end,
              brk: shellData.brk,
              tags: shellData.tags,
              locationID: shellData.locationID,
              jt: shellData.jt,
              id: dateStringer.generateUniqueID(),
              type: shellData.type,
            },
          ],
        });
      }
    }
  };

  let getShellDay = (day, jt) => {
    let dayShell =
      day === "mon"
        ? shell.mon
        : day === "tue"
        ? shell.tue
        : day === "wed"
        ? shell.wed
        : day === "thu"
        ? shell.thu
        : day === "fri"
        ? shell.fri
        : day === "sat"
        ? shell.sat
        : shell.sun;

    return dayShell
      .filter((x) => {
        return x.jt === jt;
      })
      .map((x, i) => {
        return (
          <div
            className="shellShift"
            key={i}
            onClick={() => {
              if (showShifts.includes(x.id)) {
                setShowShifts(
                  showShifts.filter((y) => {
                    return y !== x.id;
                  })
                );
              } else {
                setShowShifts([...showShifts, x.id]);
              }
            }}
          >
            <p className="shellShiftTimes">
              {x.start} - {x.end}
            </p>
            {/*  */}
            {showShifts.includes(x.id) ? (
              <p
                className={`shellLocation ${
                  x.type === "overtime" || x.type === "til"
                    ? "shellLocationOvertime"
                    : ""
                }`}
              >
                <img
                  src={rotaCoffee}
                  alt="Tag"
                  className={`tempTagInlineChild saveTempPinImg x23894389439843`}
                />
                {dateStringer.formatMinsDurationToHours(x.brk)}
              </p>
            ) : (
              ""
            )}
            {showShifts.includes(x.id) ? (
              <p
                className={`shellLocation ${
                  x.type === "overtime" || x.type === "til"
                    ? "shellLocationOvertime"
                    : ""
                }`}
              >
                <img
                  src={desktopBurger}
                  alt="Tag"
                  className={`tempTagInlineChild saveTempPinImg ${
                    x.type !== "shift" ? "x23983489439834" : ""
                  }`}
                />
                {x.type === "shift"
                  ? "Shift"
                  : x.type === "overtime"
                  ? "Overtime (paid)"
                  : "Overtime (time in lieu)"}
              </p>
            ) : (
              ""
            )}
            {showShifts.includes(x.id) ? (
              <div className="shellShTags">
                {x.tags.length === 0 ? (
                  <p className="shellNoTags">No tags</p>
                ) : showTags.includes(`${jt}${day}${i}`) ? (
                  <div className="shellTags">
                    {x.tags.map((tag, i) => {
                      return (
                        <div className="shellTag" key={tag}>
                          <img
                            src={rotaTag}
                            alt="Tag"
                            className={`tempTagInlineChild ${
                              i > 0 ? "invis" : ""
                            }`}
                          />
                          {tag}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p
                    className="shellTagShow"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowTags([...showTags, `${jt}${day}${i}`]);
                    }}
                  >
                    {" "}
                    <img src={rotaTag} alt="Tag" className="tempTagInline" />
                    Tags <span className="tempTagQty">
                      {x.tags.length}
                    </span>{" "}
                  </p>
                )}
              </div>
            ) : (
              ""
            )}
            {showShifts.includes(x.id) ? (
              <p className="shellLocation">
                <img
                  src={pin}
                  alt="Tag"
                  className="tempTagInlineChild saveTempPinImg"
                />
                {getLocationName(x.locationID)}
              </p>
            ) : (
              ""
            )}{" "}
            {showShifts.includes(x.id) ? (
              <p
                className="removeFromShell"
                onClick={(e) => {
                  e.stopPropagation();
                  setShell({
                    [day]: shell[day].filter((y) => {
                      return y.id !== x.id;
                    }),
                  });
                }}
              >
                <img
                  src={bin1}
                  alt="Tag"
                  className="tempTagInlineChild x24903293232"
                />
                Remove{" "}
              </p>
            ) : (
              ""
            )}
          </div>
        );
      });
  };

  let openShell = (val) => {
    if (val) {
      // load shell here
      setShowExistingShells(false);
      setShellMode(val);

      axios
        .post(
          `${serverURL}/open-shell`,
          {
            shellID: val,
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setTemplateName(response.data.shell.name);
            setDescription(response.data.shell.description);
            let arr = [
              ...response.data.shell.mon,
              ...response.data.shell.tue,
              ...response.data.shell.wed,
              ...response.data.shell.thu,
              ...response.data.shell.fri,
              ...response.data.shell.sat,
              ...response.data.shell.sun,
            ];
            let jobTitles = [];
            arr.forEach((x) => {
              if (!jobTitles.includes(x.jt)) {
                jobTitles.push(x.jt);
              }
            });
            setJobTitles(jobTitles);
            setShell({
              mon: response.data.shell.mon,
              tue: response.data.shell.tue,
              wed: response.data.shell.wed,
              thu: response.data.shell.thu,
              fri: response.data.shell.fri,
              sat: response.data.shell.sat,
              sun: response.data.shell.sun,
            });
          }
        });
    }
  };

  let templatePreview = (
    <div
      className={`loadTemplatemodalUnit ${
        window.innerWidth < 820
          ? `loadTemplatemodalUnitMob ${
              saveTemplateObj.saving ? "loadTemplatemodalUnitMobSaving" : ""
            }`
          : ""
      }`}
    >
      <p
        className={`${
          selectedTemplate ||
          selectedShellID ||
          (!saveTemplateObj.loading && !shellMode) ||
          saveTemplateObj.saving
            ? "none"
            : "selectTemplateFirstTxt"
        }`}
      >
        Select a {shellMode ? "shell" : "template"}{" "}
        {width < mobileWidthBreak ? "above" : "to the left"}
      </p>
      {selectedShellID &&
      // selectedShellID !== "empty" &&
      shell.loaded &&
      !jobTitles[0] &&
      shellMode ? (
        <p
          className={`noShellData ${
            selectedShellID === "empty" ? "invis" : ""
          }`}
        >
          There is no data in this shell
        </p>
      ) : (
        ""
      )}
      <div
        className={`${
          selectedTemplate ||
          selectedShellID ||
          (!saveTemplateObj.loading && !shellMode) ||
          saveTemplateObj.saving
            ? `tempLookLikeDayRow ${
                window.innerWidth < 820
                  ? `tempLookLikeDayRowMob ${
                      saveTemplateObj.saving
                        ? "tempLookLikeDayRowMobSaving"
                        : ""
                    }`
                  : ""
              }`
            : "none"
        } ${selectedShellID && shell.loaded && !jobTitles[0] ? "none" : ""}`}
      >
        <div className="tempLookLikeA1"></div>
        <div className="tempLookLikeDayCell">M</div>
        <div className="tempLookLikeDayCell">T</div>
        <div className="tempLookLikeDayCell">W</div>
        <div className="tempLookLikeDayCell">T</div>
        <div className="tempLookLikeDayCell">F</div>
        <div className="tempLookLikeDayCell">S</div>
        <div className="tempLookLikeDayCell">S</div>
      </div>
      <div
        // ref={scrollBox}
        className={`${
          selectedTemplate ||
          (!saveTemplateObj.loading && !shellMode) ||
          (shellMode && selectedShellID && shell.loaded)
            ? `templateSelectBox templateSelectBox2 ${
                window.innerWidth < 820
                  ? `templateSelectBoxMob ${
                      saveTemplateObj.saving ? "templateSelectBoxMobSaving" : ""
                    }`
                  : ""
              }`
            : "none"
        }`}
      >
        {openItemsQtr() > 0 && !shellMode ? (
          <div
            className={`templateOpensRow ${
              openItemsQtr() === 1 && "opensRow1"
            }  ${openItemsQtr() === 2 && "opensRow2"} ${
              openItemsQtr() === 3 && "opensRow3"
            }  ${openItemsQtr() === 4 && "opensRow4"}  ${
              openItemsQtr() === 5 && "opensRow5"
            }  ${openItemsQtr() === 6 && "opensRow6"}  ${
              openItemsQtr() === 7 && "opensRow7"
            }  ${openItemsQtr() === 8 && "opensRow8"}  ${
              openItemsQtr() === 9 && "opensRow9"
            }`}
          >
            {(selectedTemplate || (!saveTemplateObj.loading && !shellMode)) &&
              generateOpensRow}
          </div>
        ) : (
          ""
        )}
        {((!shellMode && selectedTemplate) ||
          (shellMode && jobTitles[0] && selectedShellID) ||
          !saveTemplateObj.loading) &&
          generateUserRows}
      </div>
    </div>
  );

  // master return
  return (
    <div
      className="bulkEditLimitUnderlay"
      onClick={() => {
        setSaveTemplateObj((x) => {
          return {};
        });
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            window.innerWidth < 820
              ? "shiftLoadingBoxMob"
              : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              window.innerWidth < 820
                ? "shiftLoadingBallsImgMob slideUp"
                : "zoomIn"
            }`}
          />
        </div>
      ) : closedDays[0] ? (
        <div
          className={`${saved ? "templateSavedModal" : "none"}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p className="tempSavedTxt">
            Shifts have been loaded onto the following closed days.
          </p>

          <p className="followingStaffTempTitle">
            Be sure you to review the following days in order to ensure staff
            are not scheduled to work on days the company is closed.
          </p>

          {closedDays[0] ? (
            <div className="noLongerInTeamDiv">
              {closedDays.map((item, i) => {
                return (
                  <p key={i} className="userNotInTeamItem">
                    {dateStringer.printedDateFromDs(item)}
                  </p>
                );
              })}
            </div>
          ) : (
            ""
          )}

          <p
            className="tempSavedCloseBtn"
            onClick={() => {
              setClosedDays([]);
            }}
          >
            Understood
          </p>
        </div>
      ) : (
        <div
          className={`${saved ? "templateSavedModal" : "none"}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p className="tempSavedTxt">
            Template {saveTemplateObj.saving ? "saved" : "loaded"}{" "}
            {/* {saveTemplateObj.saving ? "" : <br />} */}
            {saveTemplateObj.saving
              ? ""
              : `${loadWeeksQty === 1 ? "on" : "on"} to ${
                  loadWeeksQty === 1 ? "one" : loadWeeksQty
                } week${loadWeeksQty === 1 ? "" : "s"}`}
          </p>

          {usersNoLongerInTeam[0] || daysNotCreatedDueToLeave[0] ? (
            <div className="tempSavedScroller">
              {usersNoLongerInTeam[0] ? (
                <p className="followingStaffTempTitle">
                  Shifts were not created for the following staff because they
                  are no longer in this team:
                </p>
              ) : (
                ""
              )}
              {usersNoLongerInTeam[0] ? (
                <div className="noLongerInTeamDiv">
                  {usersNoLongerInTeam.map((item, i) => {
                    return (
                      <p className="userNotInTeamItem" key={i}>
                        {item}
                      </p>
                    );
                  })}
                </div>
              ) : (
                ""
              )}

              {daysNotCreatedDueToLeave[0] && usersNoLongerInTeam[0] ? (
                <br />
              ) : (
                ""
              )}
              {daysNotCreatedDueToLeave[0] ? (
                <p className="followingStaffTempTitle">
                  The following items were not created because of scheduled
                  leave periods:
                </p>
              ) : (
                ""
              )}
              {daysNotCreatedDueToLeave[0] ? (
                <div className="noLongerInTeamDiv">
                  {daysNotCreatedDueToLeave.map((item, i) => {
                    return (
                      <div
                        className="userNotInTeamItem userNotInTeamItemDay"
                        key={i}
                      >
                        <p className="notloadTitle">
                          {item.type === "overtime" || item.type === "til"
                            ? "An overtime"
                            : "A shift"}{" "}
                          for {item.fName} {item.lName} on{" "}
                          {dateStringer.printedDateFromDs(item.ds)}
                        </p>
                        <p className="notLoadTitleSub">
                          scheduled {item.leaveTypeName.toLowerCase()}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <p
            className={`${
              saveTemplateObj.loading ? "tempLoadedNotLimitsTxt" : "none"
            }`}
          >
            <span className="limitsNoteSpan">Note: </span> leave limits are not
            loaded from templates.
          </p>
          {saveTemplateObj.loading ? (
            <div className="publishTemplatedNow">
              <p
                className="tempSavedCloseBtn"
                onClick={() => {
                  setSaveTemplateObj((x) => {
                    return {};
                  });
                }}
              >
                Don't publish
              </p>
              <p
                className="tempSavedCloseBtnPublish"
                onClick={() => {
                  // teamID = saveTemplateObj.teamID
                  // monWeeksToPublish
                  // lottie
                  setLoading(true);
                  setSaveTemplateObj((x) => {
                    return {};
                  });

                  axios
                    .post(
                      `${serverURL}/publish-team-week`,
                      {
                        teamID: saveTemplateObj.teamID,
                        locationID: saveTemplateObj.locationID,
                        monWeek: saveTemplateObj.monWeek,
                        data: true,

                        loadWeeksQty,
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setUpdateInfo((x) => !updateInfo);
                        setLoading(false);
                        setSaved(true);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Publish now
              </p>
            </div>
          ) : (
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setSaveTemplateObj((x) => {
                  return {};
                });
              }}
            >
              Close
            </p>
          )}
        </div>
      )}
      {!loading ? (
        <div
          className={`${
            width < mobileWidthBreak
              ? "bulkEditLimitModalBox slideUp"
              : "bulkEditLimitModalBox saveTemplateWideModalBox"
          } ${saved ? "none" : ""} ${loading ? "loadingSaveTemp" : ""} ${
            mobModal
              ? `mobModalShoulder bulkEditLimitModalBoxMob mobTemplateModal ${
                  device.ios ? "paddingBottom20" : ""
                }`
              : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {window.innerWidth < 820 && (
            <div
              className="modalSwiper modalSwiperSaveTemplate"
              onTouchStart={handleDown}
              onTouchMove={window.innerWidth < 820 ? handleMove : null}
              onTouchEnd={window.innerWidth < 820 ? handleUp : null}
              onMouseDown={window.innerWidth < 820 ? handleDown : null}
              onMouseMove={window.innerWidth < 820 ? handleMove : null}
              onMouseUp={window.innerWidth < 820 ? handleUp : null}
            ></div>
          )}
          <div
            className={`bulkEditLimitModalHeader ${
              window.innerWidth < 820 ? "bulkEditLimitModalHeaderMob" : ""
            }`}
            onTouchStart={handleDown}
            onTouchMove={window.innerWidth < 820 ? handleMove : null}
            onTouchEnd={window.innerWidth < 820 ? handleUp : null}
            onMouseDown={window.innerWidth < 820 ? handleDown : null}
            onMouseMove={window.innerWidth < 820 ? handleMove : null}
            onMouseUp={window.innerWidth < 820 ? handleUp : null}
          >
            <img
              src={navyClose}
              alt="Close"
              className="closeBulkEditLimitModalIcon"
              onClick={() => {
                setSaveTemplateObj((x) => {
                  return {};
                });
              }}
            />
            <p className="bulkEditLimitModalHeaderTitle">
              {savingTemplate
                ? `Save ${shellMode ? "shell" : "template"}`
                : `Load a ${shellMode ? "shell" : "template"}`}
            </p>
            <div
              className={`shellModeSelector ${
                window.innerWidth < 820 ? "none" : ""
              }`}
            >
              <p
                className={`shellMode ${
                  shellMode ? "x294885555" : "shellModeActive"
                }`}
                onClick={() => {
                  setShellMode(false);
                  setShowExistingShells(false);
                  setSelectedTemplate();
                  setPreviewTemplateData([]);
                  setSelectedShellID("");

                  setPreviewOpens([]);
                  setJobTitles([]);
                  setShell({
                    mon: [],
                    tue: [],
                    wed: [],
                    thu: [],
                    fri: [],
                    sat: [],
                    sun: [],
                  });
                  setDescription("");
                  setTemplateName(
                    dateStringer.createShortStripFromDateString(
                      saveTemplateObj.monWeek
                    )
                  );
                }}
              >
                Template
              </p>
              <p
                className={`shellMode ${
                  shellMode
                    ? "shellModeActive shellModeActiveShell"
                    : "x23497955"
                }`}
                onClick={() => {
                  setShellMode(true);
                  setShowExistingShells(false);
                  setSelectedShellID("");
                  setTemplateName("");
                  setSelectedTemplate();
                  setPreviewTemplateData([]);
                  setPreviewOpens([]);
                  setJobTitles([]);
                  setDescription("");

                  // setShell({
                  //   mon: [],
                  //   tue: [],
                  //   wed: [],
                  //   thu: [],
                  //   fri: [],
                  //   sat: [],
                  //   sun: [],
                  // });
                }}
              >
                Shell
              </p>
            </div>
          </div>

          {window.innerWidth >= 820 || !shellMode || saveTemplateObj.loading ? (
            ""
          ) : showExistingShells ? (
            <select
              value=""
              className="shiftRateDropdown existingShellDrop mobEditExistingShellBtn"
              onChange={(e) => {
                openShell(e.target.value);
              }}
            >
              <option value="">Select a shell</option>
              {existingShells
                .sort((a, b) => {
                  return a.name.localeCompare(b.name);
                })
                .map((shell, i) => {
                  if (
                    typeof shellMode === "string" &&
                    shellMode === shell.shellID
                  ) {
                  } else {
                    return (
                      <option key={i} value={shell.shellID}>
                        {shell.name}
                      </option>
                    );
                  }
                })}
            </select>
          ) : (
            <p
              className="clearShellDesktop mobEditExistingShellBtn x438954954545445"
              onClick={() => {
                setShowExistingShells(true);

                if (!existingShells[0]) {
                  // get existing shells
                  axios
                    .post(
                      `${serverURL}/load-shells`,
                      {
                        teamID: saveTemplateObj.teamID,
                        someData: 1,
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setExistingShells(response.data.shells);
                      }
                    });
                }
              }}
            >
              Edit {typeof shellMode === "string" ? "other" : "existing"} shell
            </p>
          )}
          {window.innerWidth < 820 ? (
            <div className={`shellModeSelector shellModeSelectorMob `}>
              <p
                className={`shellMode ${
                  shellMode ? "x294885555" : "shellModeActive"
                }`}
                onClick={() => {
                  setShellMode(false);
                  setTemplateName(
                    dateStringer.createShortStripFromDateString(
                      saveTemplateObj.monWeek
                    )
                  );
                }}
              >
                Template
              </p>
              <p
                className={`shellMode ${
                  shellMode
                    ? "shellModeActive shellModeActiveShell"
                    : "x23497955"
                }`}
                onClick={() => {
                  setShellMode(true);
                  setTemplateName("");
                }}
              >
                Shell
              </p>
            </div>
          ) : (
            ""
          )}
          <div
            className={`bulkEditTextDiv ${
              window.innerWidth < 820
                ? `bulkEditTextDivMob ${
                    !savingTemplate ? "bulkEditTextDivMobSaving" : ""
                  }`
                : ""
            } x238943893598345 ${
              !savingTemplate
                ? `loadTempDivWithMutliWeek ${
                    window.innerWidth < 820
                      ? `x9248943893583 ${shellMode ? "x4985895445" : ""}`
                      : ""
                  }`
                : ""
            }`}
            onClick={() => {}}
          >
            {shellMode && savingTemplate ? (
              <div
                className={`modalExplainer modalExplainerWhite x389598533 ${
                  window.innerWidth < 820 ? "x389598533mob" : ""
                }`}
              >
                <div
                  className={`modalExplainLeftBlob ${
                    window.innerWidth < 820 ? "x3498349838" : ""
                  }`}
                ></div>
                <p className="modalExplainRightTxt">
                  A shell is a type of template that holds a series of
                  unassisnged shifts that can be assigned to colleagues later.
                </p>
              </div>
            ) : savingTemplate && !shellMode ? (
              <div
                className={`modalExplainer modalExplainerWhite x389598533 ${
                  window.innerWidth < 820
                    ? "x389598533mob x38993 x8954698569865"
                    : ""
                }`}
              >
                <div
                  className={`modalExplainLeftBlob ${
                    window.innerWidth < 820 ? "x349834983" : ""
                  }`}
                ></div>
                <p className="modalExplainRightTxt">
                  {" "}
                  Save week commencing {/* <br /> */}
                  <span className="templateDataSpan">
                    Mon{" "}
                    {dateStringer.createShortStripFromDateString(
                      saveTemplateObj.monWeek
                    )}
                  </span>{" "}
                  as a {shellMode ? "shell" : "template"}{" "}
                  {saveTemplateObj.locationName ? (
                    <>
                      for{" "}
                      <span className="templateDataSpan">
                        {saveTemplateObj.locationName}
                      </span>
                    </>
                  ) : (
                    <>
                      for{" "}
                      <span className="templateDataSpan">
                        {saveTemplateObj.teamName}
                      </span>
                    </>
                  )}
                  .{" "}
                  {shellMode
                    ? "A shell is a series of unassisnged shifts that can be assigned to colleagues later."
                    : "A template is a series of assigned shifts that will be assigned to colleagues when loaded."}{" "}
                  {saveTemplateObj.locationName &&
                  checkUserState.permissions !== 1 &&
                  !shellMode
                    ? "When this template is loaded, only the shifts within teams you are authorised to manage will be created."
                    : ""}
                </p>
              </div>
            ) : (
              <div
                className={`modalExplainer modalExplainerWhite x389598533 ${
                  window.innerWidth >= 820 ? "x284349898833244" : ""
                } ${
                  window.innerWidth < 820
                    ? "x389598533mob x38993 x43985498455454435"
                    : ""
                }`}
              >
                <div
                  className={`modalExplainLeftBlob ${
                    window.innerWidth < 820
                      ? "x349834983 x4389549845895656"
                      : ""
                  }`}
                ></div>
                <p
                  className={`modalExplainRightTxt ${
                    window.innerWidth < 820 &&
                    checkUserState.permissions !== 1 &&
                    locationName
                      ? "x2394983498349822"
                      : ""
                  }`}
                >
                  Load a {shellMode ? "shell" : "template of shifts"} to
                  commence on{" "}
                  <span className="templateDataSpan">
                    Mon{" "}
                    {dateStringer.createShortStripFromDateString(
                      saveTemplateObj.monWeek
                    )}
                  </span>{" "}
                  for{" "}
                  <span className="templateDataSpan">
                    {teamName || locationName}
                  </span>
                  .
                  {checkUserState.permissions !== 1 && locationName
                    ? ` When loading a ${
                        shellMode ? "shell" : "template"
                      } for a location, only the shifts within teams you are authorised to manage will be created.`
                    : ""}
                </p>
              </div>
            )}

            {!savingTemplate ? (
              <div
                className={`loadMultiWeeks ${
                  window.innerWidth < 820 ? "loadMultiWeeksMob" : ""
                }`}
              >
                <p
                  className={`applyTempTxt ${
                    window.innerWidth < 820 ? "applyTempTxtMob" : ""
                  }`}
                >
                  Weeks
                </p>
                <div className="applyWeeksContainer">
                  <p
                    className={`applyTempPlusMinus ${
                      loadWeeksQty === 1 ? "invis" : ""
                    }`}
                    onClick={() => {
                      if (loadWeeksQty > 1) {
                        setLoadWeeksQty(loadWeeksQty - 1);
                      }
                    }}
                  >
                    -
                  </p>
                  <input
                    type="number"
                    className="loadWeeksNumberInput"
                    value={loadWeeksQty}
                    min="1"
                    max="12"
                  />
                  <p
                    className={`applyTempPlusMinus ${
                      loadWeeksQty === 4 ? "invis" : ""
                    }`}
                    onClick={() => {
                      if (loadWeeksQty < 4) {
                        setLoadWeeksQty(loadWeeksQty + 1);
                      }
                    }}
                  >
                    +
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div
            className={`${dragPosition || hide ? "invis" : ""} ${
              width < mobileWidthBreak
                ? `saveTempPageMob ${
                    !shellMode
                      ? `saveTempPageMobTemp ${
                          saveTemplateObj.loading
                            ? "saveTempPageMobTempLoading"
                            : ""
                        }`
                      : ""
                  } ${
                    shellMode && window.innerWidth < 820
                      ? "saveTempPageMobLoadingShell"
                      : ""
                  }`
                : `saveTempPageWide ${
                    saveTemplateObj.loading ? "" : "flexReverse"
                  }`
            }`}
          >
            <div
              className={`saveTemplateModalUnit ${
                window.innerWidth >= 820 && shellMode && savingTemplate
                  ? "saveTemplateModalUnitRightWide"
                  : ""
              }`}
            >
              <div
                className={`${
                  saveTemplateObj.loading ? "templateSelectDiv" : "none"
                }`}
              >
                <p
                  className={`selectTemplateTxt ${
                    window.innerWidth < 820 ? "selectTemplateTxtMob" : ""
                  }`}
                >
                  Select {shellMode ? "a shell" : "a template"}{" "}
                  {shellMode ? (
                    <a
                      href={`${websiteURL}/support/shells`}
                      target="_blank"
                      className="readMoreHere x34985489456954"
                    >
                      What are shells?
                    </a>
                  ) : (
                    ""
                  )}
                </p>{" "}
                <div
                  // ref={scrollBox}
                  className={`templateSelectBox templateSelectBox1 ${
                    window.innerWidth < 820 ? "mobileMaxHeightTemplateBox" : ""
                  }`}
                >
                  {" "}
                  {shellMode ? (
                    <div
                      className={`templateOption ${
                        shellMode && selectedShellID === "empty"
                          ? "selectedTemplateUnit"
                          : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();

                        if (shellMode) {
                          if ("empty" === selectedShellID) {
                            setSelectedShellID("");
                          } else {
                            setSelectedShellID("empty");

                            setTemplateName("Empty shell");
                            setDescription(
                              "Add shell shifts on the rota directly on the rota"
                            );

                            setJobTitles([]);
                            setShell({
                              loaded: true,
                            });

                            // - - - - - - - - -
                            // let userObjs = [];

                            setTempDataUsers([]);
                            // let userRows = [];

                            // - - - - - - - -

                            // setJobTitles(jobTitles);
                            setPreviewTemplateData([]);
                          }
                        }
                      }}
                    >
                      <div className="templateOptionLeft">
                        <p className="templateUnitName">Empty shell</p>
                        <p className="templateUnitDescription">
                          Add shell shifts on the rota directly on the rota
                        </p>
                      </div>
                      <div className="templateOptionRight">
                        <img
                          src={
                            selectedShellID === "empty"
                              ? templateArrow2
                              : templateArrow1
                          }
                          className={`${
                            window.innerWidth < 820
                              ? "tempArrowImgDownWide"
                              : "tempArrowImgDownMob"
                          } ${
                            shellMode && deleteTemplate === "empty"
                              ? "none"
                              : ""
                          }`}
                          alt="select"
                        />
                        <img
                          src={remove}
                          className={`removeTemplateImg invis`}
                          alt="remove template"
                          onClick={(e) => {
                            e.stopPropagation();

                            if (shellMode) {
                              setSureDeleteShell(temp.shellID);
                            } else {
                              setSureDeleteTemplate(temp.templateID);
                            }
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {generateTemplates}
                  <p
                    className={`${
                      templates.length > 0 ? "none" : "noSavedTempsTxt"
                    }`}
                  >
                    There are no saved templates.
                    <br />
                    <br />
                    Click{" "}
                    <span
                      className="clickHereToSaveTempInsteadSpan"
                      onClick={() => {
                        setSaveTemplateObj((x) => {
                          return {
                            teamID: saveTemplateObj.teamID,
                            monWeek: saveTemplateObj.monWeek,
                            saving: true,
                            loading: false,
                            shifts: saveTemplateObj.shifts,
                            opens: saveTemplateObj.opens,
                          };
                        });
                      }}
                    >
                      here
                    </span>{" "}
                    to save the current week as a template instead.
                  </p>
                </div>
              </div>
              {saveTemplateObj.saving ? (
                shellMode ? (
                  <div
                    className={`saveShellTable ${
                      window.innerWidth < 820 ? "saveShellTableMob" : ""
                    }`}
                  >
                    {jobTitles
                      // .sort((a, b) => {
                      //   return a.localeCompare(b);
                      // })
                      .map((jt, jtIndex) => {
                        return (
                          <div className="shellJtCol" key={jt}>
                            <p
                              className={`shellJtColTitle ${
                                jtIndex === jobTitles.length - 1
                                  ? "noBorderRight"
                                  : ""
                              }`}
                            >
                              {jt}{" "}
                              <img
                                src={bin1}
                                alt="Remove job title"
                                className="jtBinShellImg"
                                onClick={() => {
                                  let newMon = shell.mon.filter((x) => {
                                    {
                                      return x.jt !== jt;
                                    }
                                  });
                                  let newTue = shell.tue.filter((x) => {
                                    {
                                      return x.jt !== jt;
                                    }
                                  });
                                  let newWed = shell.wed.filter((x) => {
                                    {
                                      return x.jt !== jt;
                                    }
                                  });
                                  let newThu = shell.thu.filter((x) => {
                                    {
                                      return x.jt !== jt;
                                    }
                                  });
                                  let newFri = shell.fri.filter((x) => {
                                    {
                                      return x.jt !== jt;
                                    }
                                  });
                                  let newSat = shell.sat.filter((x) => {
                                    {
                                      return x.jt !== jt;
                                    }
                                  });
                                  let newSun = shell.sun.filter((x) => {
                                    {
                                      return x.jt !== jt;
                                    }
                                  });
                                  setShell({
                                    mon: newMon,
                                    tue: newTue,
                                    wed: newWed,
                                    thu: newThu,
                                    fri: newFri,
                                    sat: newSat,
                                    sun: newSun,
                                  });
                                  setJobTitles(
                                    jobTitles.filter((x) => {
                                      return x !== jt;
                                    })
                                  );
                                }}
                              />
                            </p>
                            <div
                              className={`shellJtColVertical ${
                                window.innerWidth < 820
                                  ? "shellJtColVerticalMob"
                                  : ""
                              }`}
                            >
                              {/*  */}
                              <div
                                className={`shellJtDay noBorder marginTop0 ${
                                  !shell.mon.filter((x) => {
                                    return x.jt === jt;
                                  })[0]
                                    ? "noShiftsShellDay"
                                    : ""
                                }`}
                              >
                                {shell.mon.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <div className="shellDayBlob"></div>
                                ) : (
                                  ""
                                )}{" "}
                                Mon
                              </div>
                              <div className="shellData">
                                {!shell.mon.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <p className="noShellSh">No shifts</p>
                                ) : (
                                  getShellDay("mon", jt)
                                )}
                                <p
                                  className="addShellShift"
                                  onClick={() => {
                                    setAddShift({
                                      show: true,
                                      day: "mon",
                                      jobTitle: jt,
                                    });
                                  }}
                                >
                                  Add shift
                                </p>
                              </div>
                              {/*  */}
                              {/*  */}
                              <div
                                className={`shellJtDay ${
                                  !shell.tue.filter((x) => {
                                    return x.jt === jt;
                                  })[0]
                                    ? "noShiftsShellDay"
                                    : ""
                                }`}
                              >
                                {shell.tue.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <div className="shellDayBlob"></div>
                                ) : (
                                  ""
                                )}{" "}
                                Tue
                              </div>
                              <div className="shellData">
                                {!shell.tue.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <p className="noShellSh">No shifts</p>
                                ) : (
                                  getShellDay("tue", jt)
                                )}
                                <p
                                  className="addShellShift"
                                  onClick={() => {
                                    setAddShift({
                                      show: true,
                                      day: "tue",
                                      jobTitle: jt,
                                    });
                                  }}
                                >
                                  Add shift
                                </p>
                              </div>
                              {/*  */}
                              {/*  */}
                              <div
                                className={`shellJtDay ${
                                  !shell.wed.filter((x) => {
                                    return x.jt === jt;
                                  })[0]
                                    ? "noShiftsShellDay"
                                    : ""
                                }`}
                              >
                                {shell.wed.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <div className="shellDayBlob"></div>
                                ) : (
                                  ""
                                )}{" "}
                                Wed
                              </div>
                              <div className="shellData">
                                {!shell.wed.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <p className="noShellSh">No shifts</p>
                                ) : (
                                  getShellDay("wed", jt)
                                )}
                                <p
                                  className="addShellShift"
                                  onClick={() => {
                                    setAddShift({
                                      show: true,
                                      day: "wed",
                                      jobTitle: jt,
                                    });
                                  }}
                                >
                                  Add shift
                                </p>
                              </div>
                              {/*  */}
                              {/*  */}
                              <div
                                className={`shellJtDay ${
                                  !shell.thu.filter((x) => {
                                    return x.jt === jt;
                                  })[0]
                                    ? "noShiftsShellDay"
                                    : ""
                                }`}
                              >
                                {shell.thu.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <div className="shellDayBlob"></div>
                                ) : (
                                  ""
                                )}{" "}
                                Thu
                              </div>{" "}
                              <div className="shellData">
                                {!shell.thu.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <p className="noShellSh">No shifts</p>
                                ) : (
                                  getShellDay("thu", jt)
                                )}
                                <p
                                  className="addShellShift"
                                  onClick={() => {
                                    setAddShift({
                                      show: true,
                                      day: "thu",
                                      jobTitle: jt,
                                    });
                                  }}
                                >
                                  Add shift
                                </p>
                              </div>
                              {/*  */}
                              {/*  */}
                              <div
                                className={`shellJtDay ${
                                  !shell.fri.filter((x) => {
                                    return x.jt === jt;
                                  })[0]
                                    ? "noShiftsShellDay"
                                    : ""
                                }`}
                              >
                                {shell.fri.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <div className="shellDayBlob"></div>
                                ) : (
                                  ""
                                )}{" "}
                                Fri
                              </div>
                              <div className="shellData">
                                {!shell.fri.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <p className="noShellSh">No shifts</p>
                                ) : (
                                  getShellDay("fri", jt)
                                )}
                                <p
                                  className="addShellShift"
                                  onClick={() => {
                                    setAddShift({
                                      show: true,
                                      day: "fri",
                                      jobTitle: jt,
                                    });
                                  }}
                                >
                                  Add shift
                                </p>
                              </div>
                              {/*  */}
                              {/*  */}
                              <div
                                className={`shellJtDay ${
                                  !shell.sat.filter((x) => {
                                    return x.jt === jt;
                                  })[0]
                                    ? "noShiftsShellDay"
                                    : ""
                                }`}
                              >
                                {" "}
                                {shell.sat.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <div className="shellDayBlob"></div>
                                ) : (
                                  ""
                                )}{" "}
                                Sat
                              </div>{" "}
                              <div className="shellData">
                                {!shell.sat.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <p className="noShellSh">No shifts</p>
                                ) : (
                                  getShellDay("sat", jt)
                                )}
                                <p
                                  className="addShellShift"
                                  onClick={() => {
                                    setAddShift({
                                      show: true,
                                      day: "sat",
                                      jobTitle: jt,
                                    });
                                  }}
                                >
                                  Add shift
                                </p>
                              </div>
                              {/*  */}
                              {/*  */}
                              <div
                                className={`shellJtDay ${
                                  !shell.sun.filter((x) => {
                                    return x.jt === jt;
                                  })[0]
                                    ? "noShiftsShellDay"
                                    : ""
                                }`}
                              >
                                {" "}
                                {shell.sun.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <div className="shellDayBlob"></div>
                                ) : (
                                  ""
                                )}{" "}
                                Sun
                              </div>{" "}
                              <div className="shellData">
                                {!shell.sun.filter((x) => {
                                  return x.jt === jt;
                                })[0] ? (
                                  <p className="noShellSh">No shifts</p>
                                ) : (
                                  getShellDay("sun", jt)
                                )}
                                <p
                                  className="addShellShift x2489438943"
                                  onClick={() => {
                                    setAddShift({
                                      show: true,
                                      day: "sun",
                                      jobTitle: jt,
                                    });
                                  }}
                                >
                                  Add shift
                                </p>
                              </div>
                              {/*  */}
                            </div>
                          </div>
                        );
                      })}
                    {toggleAddJobTitle ? (
                      allJobTitles.filter((x) => {
                        return !jobTitles.includes(x);
                      }).length === 0 ? (
                        <p className="noMoreJt">No more available job titles</p>
                      ) : (
                        <select
                          className="shiftRateDropdown shellJtAddDrop"
                          value=""
                          onChange={(e) => {
                            if (e.target.value) {
                              setJobTitles([...jobTitles, e.target.value]);
                            }
                          }}
                        >
                          <option value="">-- Select job title</option>
                          {allJobTitles.map((jt) => {
                            if (!jobTitles.includes(jt)) {
                              return (
                                <option value={jt} key={jt}>
                                  {jt}
                                </option>
                              );
                            }
                          })}
                        </select>
                      )
                    ) : (
                      <p
                        className="addShellJobTitle"
                        onClick={() => {
                          if (!allJobTitles[0]) {
                            axios
                              .post(
                                `${serverURL}/get-all-job-titles`,
                                {
                                  someData: 1,
                                },
                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message.includes("logout")) {
                                  window.location.href = "/signin";
                                }
                                if (response.data.message === "success") {
                                  setAllJobTitles(response.data.jobTitles);
                                  setToggleAddJobTitle(true);
                                }
                              });
                          }
                        }}
                      >
                        Add job title
                        <img
                          src={navyAdd}
                          className="addJtShellImg"
                          alt="Add job title"
                        />
                      </p>
                    )}

                    {/* Save shell here */}
                  </div>
                ) : (
                  templatePreview
                )
              ) : (
                ""
              )}
              {1 + 1 === 2 ? (
                ""
              ) : saveTemplateObj.saving && !shellMode ? (
                <div className="templateIncludeRow">
                  <p className="includeCell">Save</p>
                  <div className="includeKnobContainer">
                    {/* KNOB BEGIN */}
                    <div
                      className="includeTypesSaveTempHover"
                      onClick={() => {
                        setIncludeShifts(!includeShifts);
                      }}
                    >
                      <div className={`knobContainer templateKnob `}>
                        <div
                          className={`switchKnob ${
                            includeShifts ? "knobOn " : "knobOff"
                          }`}
                        ></div>
                        <div className="knobBg"></div>
                      </div>
                    </div>
                    {/*  KNOB END */}
                  </div>
                  <div className="includeKnobContainer">
                    {/* KNOB BEGIN */}
                    <div
                      className="includeTypesSaveTempHover"
                      onClick={() => {
                        setIncludeOvertimes(!includeOvertimes);
                      }}
                    >
                      <div className={`knobContainer templateKnob `}>
                        <div
                          className={`switchKnob ${
                            includeOvertimes ? "knobOn " : "knobOff"
                          }`}
                        ></div>
                        <div className="knobBg"></div>
                      </div>
                    </div>
                    {/*  KNOB END */}
                  </div>
                  <div className="includeKnobContainer">
                    {/* KNOB BEGIN */}
                    <div
                      className="includeTypesSaveTempHover"
                      onClick={() => {
                        setIncludeTils(!includeTils);
                      }}
                    >
                      <div className={`knobContainer templateKnob `}>
                        <div
                          className={`switchKnob ${
                            includeTils ? "knobOn " : "knobOff"
                          }`}
                        ></div>
                        <div className="knobBg"></div>
                      </div>
                    </div>
                    {/*  KNOB END */}
                  </div>
                  <div className="includeKnobContainer">
                    {/* KNOB BEGIN */}
                    <div
                      className="includeTypesSaveTempHover"
                      onClick={() => {
                        setIncludeOpens(!includeOpens);
                      }}
                    >
                      <div className={`knobContainer templateKnob `}>
                        <div
                          className={`switchKnob ${
                            includeOpens ? "knobOn " : "knobOff"
                          }`}
                        ></div>
                        <div className="knobBg"></div>
                      </div>
                    </div>
                    {/*  KNOB END */}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className={`${
                width >= mobileWidthBreak
                  ? `saveTempMobWideDivider`
                  : "saveTempMobDivider"
              } ${templates.length === 0 ? "invis" : ""}`}
            ></div>
            {saveTemplateObj.saving ? "" : templatePreview}
            <div
              className={`${
                savingTemplate
                  ? `saveTemplateModalUnit ${
                      shellMode && window.innerWidth >= 820
                        ? "saveTemplateModalUnitShellWide"
                        : ""
                    }`
                  : "none"
              } ${
                window.innerWidth < 820
                  ? !shellMode
                    ? "saveTempMobUpper"
                    : "saveShellMobUpper"
                  : ""
              }`}
            >
              {shellMode ? (
                <a
                  href={`${websiteURL}/support/shells`}
                  target="_blank"
                  className={`readMoreHere x34985489456954 x249843984894 ${
                    window.innerWidth < 820 ? "whatAreShellMob" : ""
                  }`}
                >
                  What are shells?
                </a>
              ) : (
                ""
              )}
              <p
                className={`nameTemplateTxt ${
                  window.innerWidth < 820 && shellMode
                    ? "nameTemplateTxtShellMob"
                    : ""
                }`}
              >
                Name your {shellMode ? "shell" : "template"}
              </p>
              <textarea
                ref={inputRef}
                className={`templateNameInput ${
                  nameTaken ? "templateNameInputNameTaken" : ""
                }`}
                value={templateName}
                placeholder={shellMode ? "Shell name" : ""}
                onChange={(e) => {
                  setTemplateName(e.target.value);
                  if (nameTaken) {
                    setNameTaken(false);
                  }
                }}
              ></textarea>
              <p
                className={`nameTemplateTxt ${
                  window.innerWidth < 820 ? "nameTemplateTxtMob" : ""
                }`}
              >
                Describe it (optional)
              </p>
              <textarea
                className={`templateNameInput ${
                  width >= mobileWidthBreak
                    ? `descriptionBoxInWideSaveTempModal ${
                        shellMode
                          ? "descriptionBoxInWideSaveTempModalShell"
                          : ""
                      }`
                    : ""
                } resizable ${
                  savingTemplate && window.innerWidth < 820
                    ? `templateNameInputMob ${
                        shellMode ? "templateNameInputMobShell" : ""
                      }`
                    : ""
                }`}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></textarea>
              {shellMode && window.innerWidth >= 820 ? (
                <div className="shellLoadClear">
                  {showExistingShells ? (
                    <select
                      value=""
                      className="shiftRateDropdown existingShellDrop"
                      onChange={(e) => {
                        openShell(e.target.value);
                      }}
                    >
                      <option value="">Select a shell</option>
                      {existingShells
                        .sort((a, b) => {
                          return a.name.localeCompare(b.name);
                        })
                        .map((shell, i) => {
                          if (
                            typeof shellMode === "string" &&
                            shellMode === shell.shellID
                          ) {
                          } else {
                            return (
                              <option key={i} value={shell.shellID}>
                                {shell.name}
                              </option>
                            );
                          }
                        })}
                    </select>
                  ) : (
                    <p
                      className="clearShellDesktop"
                      onClick={() => {
                        setShowExistingShells(true);

                        if (!existingShells[0]) {
                          // get existing shells
                          axios
                            .post(
                              `${serverURL}/load-shells`,
                              {
                                teamID: saveTemplateObj.teamID,
                                someData: 1,
                              },
                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                setExistingShells(response.data.shells);
                              }
                            });
                        }
                      }}
                    >
                      Edit{" "}
                      {typeof shellMode === "string" ? "other" : "existing"}{" "}
                      shell
                    </p>
                  )}
                  <p
                    className="clearShellDesktop"
                    onClick={() => {
                      setShell({
                        mon: [],
                        tue: [],
                        wed: [],
                        thu: [],
                        fri: [],
                        sat: [],
                        sun: [],
                      });
                    }}
                  >
                    Clear
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div
            className={`bulkModalFooter ${
              !window.innerWidth < 820 ? "desktopBulkModalFooter" : ""
            }`}
          >
            <div className="saveTempFooterRightDiv">
              <div className="saveShellFooterLeft">
                <p
                  className={`saveBulkModalBtn ${
                    !templateName ||
                    (!includeOpens &&
                      !includeShifts &&
                      !includeTils &&
                      !includeOvertimes) ||
                    loading ||
                    (saveTemplateObj.loading &&
                      !shellMode &&
                      !selectedTemplate) ||
                    (saveTemplateObj.loading && shellMode && !selectedShellID)
                      ? "fadeBulkSaveBtn"
                      : ""
                  } ${shellMode && !templateName ? "fadeBulkSaveBtn" : ""} ${
                    nameTaken ? "fadeBulkSaveBtn" : ""
                  } ${saveLoading ? "fadeBulkSaveBtn" : ""} ${
                    selectedShellID &&
                    selectedShellID !== "empty" &&
                    shell.loaded &&
                    !jobTitles[0] &&
                    shellMode
                      ? "fadeBulkSaveBtn"
                      : ""
                  }`}
                  onClick={() => {
                    if (shellMode) {
                      if (saveTemplateObj.saving) {
                        setSaveLoading(true);
                        axios
                          .post(
                            `${serverURL}/save-shell`,
                            {
                              teamID: saveTemplateObj.teamID,
                              mon: shell.mon,
                              tue: shell.tue,
                              wed: shell.wed,
                              thu: shell.thu,
                              fri: shell.fri,
                              sat: shell.sat,
                              sun: shell.sun,
                              name: templateName,
                              description,
                              shellID:
                                typeof shellMode === "string" ? shellMode : "",
                            },
                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "name taken") {
                              setNameTaken(true);
                              setIndicate({
                                show: true,
                                message: `Name already taken`,
                                colour: "red",
                                duration: 4000,
                              });
                            }
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              setIndicate({
                                show: true,
                                message: `${
                                  shellMode ? "Shell" : "Template"
                                } saved`,
                                colour: "green",
                                duration: 4000,
                              });

                              setSaveLoading(false);
                              setSaveTemplateObj((x) => {
                                return {};
                              });
                            } else {
                              setSaveLoading(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                            setSaveLoading(false);
                          });
                      } else {
                        setLoading(true);
                        axios
                          .post(
                            `${serverURL}/load-shell-to-rota`,
                            {
                              teamID: saveTemplateObj.teamID,
                              locationID: saveTemplateObj.locationID,
                              monWeek: saveTemplateObj.monWeek,
                              shellID: selectedShellID,
                              weeks: parseInt(
                                loadWeeksQty < 0 ? 1 : loadWeeksQty
                              ),
                            },
                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              setUpdateInfo((x) => !updateInfo);

                              setLoading(false);
                              setSaved(true);
                              setSaveTemplateObj({});
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    } else {
                      if (
                        !loading &&
                        templateName &&
                        saveTemplateObj.monWeek &&
                        saveTemplateObj.saving
                      ) {
                        // setLoading(true);
                        setSaveLoading(true);
                        axios
                          .post(
                            `${serverURL}/save-template-week`,
                            {
                              teamID: saveTemplateObj.teamID,
                              locationID: saveTemplateObj.locationID,

                              monWeek: saveTemplateObj.monWeek,
                              includeShifts,
                              includeOpens,
                              includeOvertimes,
                              includeTils,
                              name: templateName,
                              description,
                            },
                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              setIndicate({
                                show: true,
                                message: `${
                                  shellMode ? "Shell" : "Template"
                                } saved`,
                                colour: "green",
                                duration: 4000,
                              });

                              setSaveLoading(false);
                              setSaveTemplateObj((x) => {
                                return {};
                              });
                            } else {
                              setSaveLoading(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                            setSaveLoading(false);
                          });
                      }

                      if (
                        saveTemplateObj.loading &&
                        selectedTemplate &&
                        !loading
                      ) {
                        setLoading(true);
                        axios
                          .post(
                            `${serverURL}/load-template-onto-mon-week`,
                            {
                              teamID: saveTemplateObj.teamID,
                              locationID: saveTemplateObj.locationID,
                              monWeek: saveTemplateObj.monWeek,
                              deleteExisting,
                              templateID: selectedTemplate,
                              loadWeeksQty,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },
                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message.includes("logout")) {
                              window.location.href = "/signin";
                            }
                            if (response.data.message === "success") {
                              setDaysNotCreatedDueToLeave(
                                response.data.daysNotCreatedDueToLeave
                              );
                              setUpdateInfo((x) => !updateInfo);
                              setUsersNoLongerInTeam(
                                response.data.noLongerOnTeam
                              );
                              setLoading(false);
                              setSaved(true);
                              setClosedDays(
                                response.data.closedDaysThatWereGeneratedWithin
                              );
                              setMonWeeksToPublish(response.data.monWeeks);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }
                  }}
                >
                  {savingTemplate ? "Save" : "Load"}{" "}
                </p>
                {window.innerWidth < 820 &&
                shellMode &&
                saveTemplateObj.saving ? (
                  <p
                    className="clearshellMob"
                    onClick={() => {
                      setShell({
                        mon: [],
                        tue: [],
                        wed: [],
                        thu: [],
                        fri: [],
                        sat: [],
                        sun: [],
                      });
                    }}
                  >
                    Clear
                  </p>
                ) : (
                  ""
                )}
                {typeof shellMode === "string" ? (
                  <p
                    className="deleteShellBtn"
                    onClick={() => {
                      setSureDeleteShell(shellMode);
                    }}
                  >
                    Delete shell
                    <img
                      src={bin1}
                      alt="Remove shell bin"
                      className="deleteShellBtnBinImg"
                    />
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div
                className={`pubShiftBtn replaceExistingBtn ${
                  !saveTemplateObj.saving
                    ? `${deleteExisting ? "pubImmedBg_" : ""}`
                    : "invis"
                } ${shellMode ? "none" : ""}`}
                onClick={() => {
                  setDeleteExisting(!deleteExisting);
                }}
              >
                {deleteExisting ? (
                  <img
                    src={publishTick2}
                    className="replaceTickImg"
                    alt="Publish"
                  />
                ) : (
                  <p className="x249892842456"></p>
                )}
                Replace existing
              </div>
            </div>
            <p
              className="closeReqInfo"
              onClick={() => {
                setSaveTemplateObj((x) => {
                  return {};
                });
              }}
            >
              Close
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
      {sureDeleteTemplate ? (
        <div className="clockOnModalUnderlay">
          <div
            className={`formCreatedModal `}
            onClick={(e) => {
              e.stopPropagation();
              setSureDeleteTemplate("");
            }}
          >
            <p className="overlapsRenTxt">
              Are you sure you want to delete this template?
              <br /> <br />
              You cannot undo this action.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setSureDeleteTemplate("");
                  axios
                    .post(
                      `${serverURL}/delete-template`,
                      {
                        templateID: sureDeleteTemplate,
                        teamID: saveTemplateObj.teamID,
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setTemplates(response.data.templates);

                        setSelectedTemplate("");
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeleteTemplate("");
                }}
              >
                No
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {addShift.show ? (
        <AddShellShiftModal
          setAddShift={setAddShift}
          addShift={addShift}
          teamID={saveTemplateObj.teamID}
          jobTitles={jobTitles}
          updateShell={updateShell}
          availableLocations={availableLocations}
          locationID={saveTemplateObj.locationID}
        />
      ) : (
        ""
      )}
      {sureDeleteShell ? (
        <div
          className="areYouSureModalUnderlay"
          onClick={() => {
            setSureDeleteShell(false);
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              Are you sure you want to delete this shell?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/delete-shell`,
                      {
                        shellID: sureDeleteShell,
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setNameTaken(false);
                        setJobTitles([]);
                        setSureDeleteShell(false);
                        setSelectedShellID("");

                        setShellMode(true); // was a string
                        setShowExistingShells(false);
                        setShell({
                          mon: [],
                          tue: [],
                          wed: [],
                          thu: [],
                          fri: [],
                          sat: [],
                          sun: [],
                        });
                        setShells(
                          shells.filter((x) => {
                            return x.shellID !== sureDeleteShell;
                          })
                        );

                        setExistingShells(
                          existingShells.filter((x) => {
                            return x.shellID !== shellMode;
                          })
                        );
                        setIndicate((x) => {
                          return {
                            show: true,
                            message: `Shell deleted`,
                            colour: "green",
                            duration: 4000,
                            ts: new Date().getTime(),
                          };
                        });
                      } else {
                        setIndicate((x) => {
                          return {
                            show: true,
                            message: `Error - Shell not deleted`,
                            colour: "red",
                            duration: 4000,
                            ts: new Date().getTime(),
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                      setIndicate((x) => {
                        return {
                          show: true,
                          message: `Error - Shell not deleted`,
                          colour: "red",
                          duration: 4000,
                          ts: new Date().getTime(),
                        };
                      });
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeleteShell(false);
                }}
              >
                No
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SaveTemplateModal;
