// keys done 5 mar 25
// design done 5 mar 25

import React, { useEffect, useState, useContext, useMemo, useRef } from "react";

import axios from "axios";

import { DataContext } from "../../../../contexts/DataContext";
import { StyleContext } from "../../../../contexts/StyleContext";

import serverURL from "../../../../serverURL";

import magni from "../../../../img/general/magni.svg";
import close from "../../../../img/modals/close.svg";
import send from "../../../../img/general/send.svg";
import profilePicturePlaceholder from "../../../../img/general/profilePicturePlaceholder.svg";
import navyTick from "../../../../img/general/navyTick.svg";
import horizontalBalls from "../../../../img/loaders/horizontalBalls.svg";
import dateStringer from "../../../../tools/dateStringer.js";

const SendMemo = ({}) => {
  const {
    showSendMemoModal,
    setShowSendMemoModal,
    modalOpen,
    setModalOpen,
    device,
    setIndicate,
  } = useContext(DataContext);
  const { mob, mobModal } = useContext(StyleContext);
  const memoVals = useMemo(
    () => ({
      showSendMemoModal,
      setShowSendMemoModal,
      mob,
      mobModal,
      modalOpen,
      setModalOpen,
      device,
      setIndicate,
    }),
    [
      showSendMemoModal,
      setShowSendMemoModal,
      mob, //
      modalOpen,
      setModalOpen,
      device,
      setIndicate,
    ]
  );
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [availableUsers, setAvailableUsers] = useState([]);
  let [availableTeams, setAvailableTeams] = useState([]);
  let [teamName, setTeamName] = useState("");
  let [selectedUsers, setSelectedUsers] = useState([]);
  let [sending, setSending] = useState(false);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-memo-users`,
        {
          teamID: memoVals.showSendMemoModal.teamID || "allStaff",
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setAvailableTeams(response.data.teams);
          setAvailableUsers(response.data.users);
          setTeamName(response.data.currTeamName || "");
          if (memoVals.showSendMemoModal.teamID !== "allStaff") {
            // setSelectedUsers(response.data.users.map((x) => x.userID));
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.showSendMemoModal.teamID]);

  let [searchName, setSearchName] = useState("");
  let [memoValue, setMemoValue] = useState("");
  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let closeModal = () => {
    memoVals.setShowSendMemoModal((x) => {
      return { show: false };
    });
  };

  // master return

  return (
    <div
      className="editShiftModalUnderlay"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div
        className={`addShiftModalBox ${
          memoVals.mobModal ? "mobModalShoulder slideUp" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {memoVals.mobModal && (
          <div className="modalSwiper modalSwiperAddShiftModal2"></div>
        )}
        <div
          className={`addShiftModalHeader ${
            memoVals.mobModal ? "memoHeaderMob" : "memoHeader"
          } `}
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={close}
            alt="close"
            className="closeAddShiftModalImg"
            onClick={() => {
              closeModal();
            }}
          />
          <p className="addShiftHeaderTitle addShiftTitle">Send memo</p>
        </div>

        {/* page 0 */}
        <div
          className={`addShiftMidContainer  x438935895349 ${
            memoVals.mobModal ? "memoMidMob" : ""
          } noScroll_`}
        >
          {!loading ? (
            <p className="addShiftInputLabel x238943988493545">Message</p>
          ) : (
            ""
          )}
          {!loading ? (
            <textarea
              className={`memoTxtArea ${
                memoVals.mobModal ? "memoTxtAreaMob" : ""
              }`}
              value={memoValue}
              placeholder="What would you like to say?"
              onChange={(e) => {
                if (e.target.value === "{" || e.target.value === "}") {
                  return;
                }
                if (memoValue.length >= 1000) {
                  setMemoValue(e.target.value.substr(0, 999));
                  return;
                }
                setMemoValue(e.target.value);
              }}
            ></textarea>
          ) : (
            ""
          )}
          {!loading ? (
            <p className="memoRemaining">
              {999 - memoValue.length} / 999 characters remaining
            </p>
          ) : (
            ""
          )}

          {/* 
          
          ======== */}
          <div className="memoSel">
            <p className="addShiftInputLabel">Send to</p>
            <select
              className="memoTeamSel"
              value={memoVals.showSendMemoModal.teamID || "allStaff"}
              onChange={(e) => {
                if (selectedUsers[0]) {
                  setSelectedUsers([]);
                }
                memoVals.setShowSendMemoModal((x) => {
                  return { show: true, teamID: e.target.value };
                });
              }}
            >
              <option value="allStaff">All of your staff</option>
              {availableTeams
                .sort((a, b) => {
                  return a.teamName.localeCompare(b.teamName);
                })
                .map((team, i) => {
                  return (
                    <option value={team.teamID} key={i}>
                      {team.teamID === memoVals.showSendMemoModal.teamID
                        ? dateStringer.shorten(team.teamName, 20)
                        : team.teamName}
                    </option>
                  );
                })}
            </select>
          </div>
          {!loading ? (
            <div className="memoSearch">
              <input
                type="text"
                onChange={(e) => {
                  setSearchName(e.target.value);
                }}
                placeholder="Search for an employee"
                value={searchName}
                className="memoSearchInput"
              ></input>
              <img src={magni} alt="Search" className="memoMagni" />
            </div>
          ) : (
            ""
          )}
          <div className={`sendMemoUserSelectBox ${loading ? "noScroll" : ""}`}>
            {loading ? (
              <img
                src={horizontalBalls}
                alt="Loading"
                className="loadingMemo"
              />
            ) : (
              availableUsers
                .sort((a, b) => {
                  return a.fName.localeCompare(b.fName);
                })
                .map((user, i) => {
                  let fullNameString = `${user.fName.toLowerCase()} ${user.lName.toLowerCase()}`;
                  if (
                    !searchName ||
                    user.fName
                      .toLowerCase()
                      .includes(searchName.toLowerCase()) ||
                    user.lName
                      .toLowerCase()
                      .includes(searchName.toLowerCase()) ||
                    fullNameString
                      .toLowerCase()
                      .includes(searchName.toLowerCase())
                  ) {
                    return (
                      <div
                        className="memoUser"
                        key={i}
                        onClick={() => {
                          if (selectedUsers.includes(user.userID)) {
                            setSelectedUsers(
                              selectedUsers.filter((x) => {
                                return x !== user.userID;
                              })
                            );
                          } else {
                            setSelectedUsers([...selectedUsers, user.userID]);
                          }
                        }}
                      >
                        <div className="memoUserLeft">
                          <img
                            src={user.profPicUrl || profilePicturePlaceholder}
                            alt={user.fName}
                            className="memoUserPPimg"
                          />

                          <p className="memoUserName">
                            {user.fName} {user.lName}
                          </p>
                        </div>
                        <div className="memoUserSelector">
                          {selectedUsers.includes(user.userID) ? (
                            <img
                              src={navyTick}
                              alt="Selected"
                              className="memoUserTick"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  }
                })
            )}
          </div>
          <div className="memoBtns">
            <p
              className={`memoBtn ${
                availableUsers.length === selectedUsers.length ||
                searchName.length > 0
                  ? "disableSendMemo"
                  : ""
              }`}
              onClick={() => {
                setSelectedUsers(availableUsers.map((x) => x.userID));
              }}
            >
              Select all
            </p>
            <p
              className={`memoBtn memoBtn2 ${
                selectedUsers.length === 0 ? "disableSendMemo" : ""
              }`}
              onClick={() => {
                setSelectedUsers([]);
              }}
            >
              Unselect all
            </p>
          </div>
          <p className="memoQty">
            Send to:{" "}
            <span className={selectedUsers.length > 0 ? "memoQtyBlue" : ""}>
              {selectedUsers.length} staff
            </span>
          </p>
        </div>
        {/* END OF PAGE 0 */}

        <div className={`addShiftFooter `}>
          <p
            className={`blueSubmitButton x3849843566 ${
              selectedUsers.length <= 0 ||
              memoValue.length > 999 ||
              !memoValue ||
              sending
                ? "disableSendMemo"
                : ""
            }`}
            onClick={() => {
              setSending(true);
              axios
                .post(
                  `${serverURL}/send-memo`,
                  {
                    userIDs: selectedUsers,
                    message: memoValue,
                    nowDs: dateStringer.createDateInputFromTimestamp(
                      new Date().getTime()
                    ),
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    memoVals.setIndicate((x) => {
                      return {
                        show: true,
                        message: "Memo sent successfully",
                        colour: "green",
                        duration: 4000,
                      };
                    });
                    closeModal();
                  } else {
                    setSending(false);
                  }
                })
                .catch((e) => {
                  console.error(e);
                  setSending(false);
                });
            }}
          >
            Send memo
            <img src={send} alt="Send" className="sendMemoImg" />
          </p>
          <p
            className={`closeReqInfo`}
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </p>
        </div>
      </div>
    </div>
  );
};

export default SendMemo;
