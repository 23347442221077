import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useMemo,
  useRef,
} from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";

import "../../../styles/settings.css";
import "../../../styles/admin.css";

import whiteAdd from "../../../img/general/whiteAdd.svg";

import popup from "../../../img/general/popup.svg";
import editUserEdit1 from "../../../img/general/editUserEdit1.svg";
import greyClose from "../../../img/general/greyClose.svg";
import greyBin from "../../../img/general/greyBin.svg";
import navyBin from "../../../img/general/navyBin.svg";
import whiteAddThin from "../../../img/general/whiteAddThin.svg";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";

import serverURL from "../../../serverURL";
import dateStringer from "../../../tools/dateStringer";

const Locations = () => {
  const { setAccNav, mob, mobModal, full } = useContext(StyleContext);
  const {
    device,
    locationsCount,
    setLocationsCount,
    setViewZoneModal,
    updateAdminLocations,
    setUpdateAdminLocations,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setAccNav,
      mob,
      mobModal,
      full,

      device,
      locationsCount,
      setLocationsCount,
      setViewZoneModal,
      updateAdminLocations,
      setUpdateAdminLocations,
    }),
    [
      setAccNav,
      mob,
      mobModal,
      full,
      device,
      locationsCount,
      setLocationsCount,
      setViewZoneModal,
      updateAdminLocations,
      setUpdateAdminLocations,
    ]
  );

  let [enableGPS, setEnableGPS] = useState(false);

  useEffect(() => {
    memoVals.setAccNav((x) => "admin");
  }, []);

  let [locations, setLocations] = useState([]);
  let [loading, setLoading] = useState(true);
  let [showTopBorder, setShowTopBorder] = useState(false);

  let [renameLocationID, setRenameLocationID] = useState("");
  let [nameInput, setNameInput] = useState("");

  useEffect(() => {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Update the slug
    currentURL.pathname = "/admin/locations";

    // Change the URL without reloading the page
    window.history.pushState({}, "", currentURL.href);
  }, []);

  let [mgrsCanEditTeams, setMgrsCanEditTeams] = useState(false);

  useEffect(() => {
    // if (!locations[0]) {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-locations`,
        { someData: 1 },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setLocations(response.data.locations || []);
          setEnableGPS(response.data.enableGPS);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
    // }
  }, [memoVals.updateAdminLocations]);

  let [sureArchive, setSureArchive] = useState({ show: false });
  let [toggleAdd, setToggleAdd] = useState(false);

  let generateLocations = locations
    .sort((a, b) => {
      return a.locationName.localeCompare(b.locationName);
    })

    .map((loc, i) => {
      return (
        <div
          className={`dashTeamItem adminTeamItem ${
            memoVals.mob ? "adminTeamItemMob" : ""
          } locItem `}
          key={i}
        >
          <div className="dasthTeamItemUpper">
            <div className="userDashDetailsLeft">
              <div className="userDashDetailsLeftRow">
                {/* <p className="userDashDetailsLeftRowTitle">Team</p> */}
                <div
                  className={`userDashDetailsLeftRowValue fontSize16 fontWeight600 x934539753 ${
                    loc.locationID === renameLocationID ? "" : "x8487239742"
                  }`}
                >
                  {loc.locationID === renameLocationID ? (
                    <input
                      type="text"
                      value={nameInput}
                      className={`editUserValInput editUserNameInputSize x4839859383 ${
                        window.innerWidth < 400 ? "x3893489384" : ""
                      }`}
                      onChange={(e) => {
                        setNameInput(e.target.value);
                      }}
                    ></input>
                  ) : (
                    loc.locationName
                  )}{" "}
                  {loc.locationID === renameLocationID ? (
                    <div className="flexDiv">
                      <img
                        src={greyBin}
                        alt="Cancel"
                        className="cancelEditNameJt canxLocRename archiveLocBtn"
                        onClick={() => {
                          setRenameLocationID("");
                          setNameInput("");
                          setSureArchive({
                            show: true,
                            locationID: loc.locationID,
                            locationName: loc.locationName,
                          });
                        }}
                      />
                      <img
                        src={greyClose}
                        alt="Cancel"
                        className="cancelEditNameJt canxLocRename"
                        onClick={() => {
                          setRenameLocationID("");
                          setNameInput("");
                        }}
                      />
                      <p
                        className="saveNameAndJtBtn"
                        onClick={() => {
                          // axios here
                          setRenameLocationID("");
                          setNameInput("");
                          axios
                            .post(
                              `${serverURL}/add-delete-update-location`,
                              { locationID: loc.locationID, name: nameInput },
                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                setLocations(response.data.locations);
                              }
                            });
                        }}
                      >
                        Save
                      </p>{" "}
                    </div>
                  ) : (
                    <img
                      onClick={() => {
                        setRenameLocationID(loc.locationID);
                        setNameInput(loc.locationName);
                      }}
                      src={editUserEdit1}
                      alt="Edit location name"
                      className="editUserEditImg editLocNameImg"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="userDashDetailsRight">
              {loc.locationID === renameLocationID ? (
                ""
              ) : (
                <p
                  className={`viewLocBtn teamDashBtn x398857 blueColours`}
                  onClick={() => {}}
                >
                  View{" "}
                  <img
                    src={popup}
                    alt="Edit profile"
                    className={`editProfilePenIcon ${
                      !memoVals.full && !memoVals.mob
                        ? "editProfilePenIconMid"
                        : ""
                    } x48585935`}
                  />
                </p>
              )}
            </div>
          </div>{" "}
        </div>
      );
    });

  let scrollRef = useRef();
  useEffect(() => {
    if (toggleAdd && scrollRef) {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: 0, // Scroll to the bottom of the div
          behavior: "smooth", // Smooth scroll behavior
        });
      }
    }
  }, [toggleAdd]);

  let populateGPSArray = locations.map((item) => {
    return (
      <div key={item.locationID}>
        <div
          className="specialDayUnit"
          onClick={() => {
            memoVals.setViewZoneModal((x) => item.locationID);
          }}
        >
          <span onClick={() => {}}>
            <p className="specialDayDate">
              {dateStringer.shorten(item.locationName, 24)}
            </p>
          </span>
          <img
            className="gpsZoneDeleteCrossImg"
            src={navyBin}
            alt="Delete"
            onClick={(e) => {
              e.stopPropagation();
              setSureArchive({
                show: true,
                locationID: item.locationID,
                locationName: item.locationName,
              });
            }}
          />
        </div>
      </div>
    );
  });
  // master
  return (
    <Fragment>
      <div className="adminTeamsBody">
        <div className="adminUsersParent">
          {/* <div
            className={`adminUserKeyBar ${
              memoVals.mob ? "adminUserKeyBarMob" : ""
            }`}
          >
            <div className="adminUserKeyLeft">
              <div
                className={`adminAddUsrBtn ${
                  memoVals.mob ? "adminAddUsrBtnMob" : ""
                } lilacColours colourWhite ${toggleAdd ? "hidden" : ""}`}
                onClick={() => {
                  setToggleAdd(true);
                }}
              >
                Add location
                <img
                  src={memoVals.mob ? whiteAdd : whiteAdd}
                  alt="Add new employee"
                  className="addNewEmpIcon"
                />
              </div>
            </div>
            <div className="adminUserKeyRight"></div>
          </div> */}
          <div
            ref={scrollRef}
            className={`AdminSettingsContainer ${
              memoVals.mob
                ? `AdminSettingsContainerMob ${
                    memoVals.device.ios
                      ? "AdminSettingsContainerMobUsersIos"
                      : ""
                  } x3213456765 minWidth100vw x1383747_ ${
                    showTopBorder ? "showUsersTopBorder" : ""
                  }`
                : "maxWidth600px borderRightGrey teamsAdminHeight_"
            }`}
            onScroll={(e) => {
              if (memoVals.mob) {
                if (e.target.scrollTop > 0) {
                  setShowTopBorder(true);
                } else {
                  setShowTopBorder(false);
                }
              }
            }}
          >
            <div className="AdminInner">
              {" "}
              {/* <p className="teamLocationExplain">
                Locations are sites where employees work. Employees can clock on
                at any location, and locations can be assigned to scheduled
                shifts.
              </p> */}
              {/* {!locations[0] && !loading && (
                <p className="noAdminTeamsAvail">No locations</p>
              )}
              {!locations[0] && !loading && (
                <p
                  className="addTeamNowNoTeamsbTN"
                  onClick={() => {
                    memoVals.setShowAddTeamModal((x) => true);
                  }}
                >
                  Add one now
                </p>
              )} */}
              {!loading && toggleAdd ? (
                <div
                  className={`dashTeamItem adminTeamItem ${
                    memoVals.mob ? "adminTeamItemMob" : ""
                  } locItem locItemNew`}
                >
                  <div className="dasthTeamItemUpper">
                    <div className="userDashDetailsLeft">
                      <div className="userDashDetailsLeftRow">
                        {/* <p className="userDashDetailsLeftRowTitle">Team</p> */}
                        <div
                          className={`userDashDetailsLeftRowValue fontSize16 fontWeight600 x934539753 x8487239742 x24738757855`}
                        >
                          <input
                            type="text"
                            placeholder="Location name"
                            value={nameInput}
                            className={`editUserValInput editUserNameInputSize x4839859383 ${
                              window.innerWidth < 400 ? "x3893489384" : ""
                            }`}
                            onChange={(e) => {
                              setNameInput(e.target.value);
                            }}
                          ></input>

                          <div className="flexDiv">
                            <img
                              src={greyClose}
                              alt="Cancel"
                              className="cancelEditNameJt canxLocRename x35989385334"
                              onClick={() => {
                                setToggleAdd(false);
                                setNameInput("");
                              }}
                            />
                            <p
                              className="saveNameAndJtBtn"
                              onClick={() => {
                                // axios here
                                setRenameLocationID("");
                                setNameInput("");
                                setToggleAdd(false);
                                axios
                                  .post(
                                    `${serverURL}/add-delete-update-location`,
                                    {
                                      name: nameInput,
                                    },
                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (
                                      response.data.message.includes("logout")
                                    ) {
                                      window.location.href = "/signin";
                                    }
                                    if (response.data.message === "success") {
                                      setLocations(response.data.locations);
                                      memoVals.setLocationsCount(
                                        (x) => memoVals.locationsCount + 1
                                      );
                                    }
                                  });
                              }}
                            >
                              Save
                            </p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="userDashDetailsRight"></div>
                  </div>{" "}
                </div>
              ) : (
                ""
              )}
              {loading ? (
                <img
                  src={horizontalBalls}
                  alt="Loading"
                  className="loadingUsersAdmin"
                />
              ) : (
                // generateLocations
                <div
                  className={`gpsAdminRow ${
                    memoVals.mob ? "gpsAdminRowMob" : ""
                  }`}
                >
                  <div className={`settingsRow`}>
                    <div className="rowWithSubTextGPS">
                      <p className="absenceRowMainText">Locations</p>
                      <p className="absenceRowSubText overtimeRowSubText">
                        Set specific sites for where shifts are scheduled and
                        where you expect employees to clock in. FlowRota will
                        show the distance between an employee's clock-in
                        location and their nearest location if the location has
                        GPS co-ordinates. <br />
                        <br />
                        We recommend using{" "}
                        <a
                          href="https://www.gps-coordinates.net/"
                          target="_blank"
                          className="coordLinkTxt"
                        >
                          this website
                        </a>{" "}
                        to obtain GPS co-ordinates.
                      </p>
                    </div>{" "}
                  </div>
                  <div
                    className={`${
                      locations.length > 0 && enableGPS ? "gpsList" : "none"
                    }`}
                  >
                    <div className="specialDayUnitsContainer">
                      {populateGPSArray}
                    </div>
                  </div>{" "}
                  <div className={`addZoneParent ${enableGPS ? "" : "none"}`}>
                    <p
                      className="addGPSBtn blueColours"
                      onClick={() => {
                        memoVals.setViewZoneModal((x) => "add");
                      }}
                    >
                      Add location{" "}
                      <img
                        src={whiteAddThin}
                        alt="Add zone"
                        className="addZonePlus"
                      />
                    </p>
                  </div>
                </div>
              )}
              <br />
            </div>
          </div>
        </div>
        {sureArchive.show ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();
              setSureArchive({ show: false });
            }}
          >
            <div className="formCreatedModal">
              <p className="overlapsRenTxt">
                Are you sure you want to remove this location? <br />
                <br />
                {sureArchive.locationName} will still appear on scheduled shifts
                and any clock cards associated with this location.
              </p>

              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    // archive here
                    setSureArchive({ show: false });

                    axios
                      .post(
                        `${serverURL}/add-delete-update-location`,
                        { locationID: sureArchive.locationID, deleting: true },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setLocations(response.data.locations);
                          memoVals.setLocationsCount(
                            (x) => memoVals.locationsCount - 1
                          );
                        }
                      });
                  }}
                >
                  Yes
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setSureArchive({ show: false });
                  }}
                >
                  Cancel
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};

export default Locations;
