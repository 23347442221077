// Design 19 feb 25
// keys done 5 mar 25

import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useMemo,
} from "react";
// cleaned 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done

import axios from "axios";
import { CalendarContext } from "../contexts/CalendarContext";
import { UserContext } from "../contexts/UserContext";
import { StyleContext } from "../contexts/StyleContext";
import { DataContext } from "../contexts/DataContext";

import dateStringer from "../tools/dateStringer";
import serverURL from "../serverURL";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import navyClose from "../img/general/navyClose.svg";
import schedLimitPlus from "../img/general/schedLimitPlus.svg";
import schedLimitMinus from "../img/general/schedLimitMinus.svg";
import tick from "../img/general/onTimeTick.svg";
import cross from "../img/general/unitCross.svg";
import popup from "../img/general/popup2.svg";
import arrGrey from "../img/general/arrGrey.svg";
import lightBlueQuestionMark from "../img/general/lightBlueQuestionMark.svg";
import schedLimitPowerOff from "../img/general/navyPower.svg";

import "../styles/calendar.css";

const AddAvailModal = ({ monWeek, loadTeamID }) => {
  const {
    setShowAddAvailModal,
    showAddAvailModal,
    setIndicate,
    updateCalAvail,
    setUpdateCalAvail,
    device,
    modalOpen,
    setModalOpen,
  } = useContext(DataContext);
  const { mob, mobModal } = useContext(StyleContext);
  const memoVals = useMemo(
    () => ({
      setShowAddAvailModal,
      showAddAvailModal,
      setIndicate,
      updateCalAvail,
      setUpdateCalAvail,
      device,
      mob,
      mobModal,
      modalOpen,
      setModalOpen,
    }),
    [
      setShowAddAvailModal, //
      showAddAvailModal, //
      setIndicate, //
      updateCalAvail, //
      setUpdateCalAvail, //
      device, //
      mob, //
      mobModal, //
      modalOpen,
      setModalOpen,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [weekStarts, setWeekStarts] = useState([]);
  let [selectedMonWeek, setSelectedMonWeek] = useState(monWeek);
  let [currMonWeek, setCurrMonWeek] = useState("");
  let [loadingAvail, setLoadingAvail] = useState(false);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let todayDs00 = `${dateStringer
    .createStringFromTimestamp(new Date().getTime())
    .substr(0, 11)}H00M00`;

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 9) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyClose}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={schedLimitPlus}
        alt="Schedule Limit Plus"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={schedLimitMinus}
        alt="Schedule Limit Minus"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={tick}
        alt="Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={cross}
        alt="Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup}
        alt="Popup"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={arrGrey}
        alt="Grey Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={lightBlueQuestionMark}
        alt="Light Blue Question Mark"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={schedLimitPowerOff}
        alt="Schedule Limit Power Off"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [teams, setTeams] = useState([]);
  let [selectedTeam, setSelectedTeam] = useState(loadTeamID);
  let [showMonthView, setShowMonthView] = useState(
    memoVals.showAddAvailModal.openedFromCal || loadTeamID
      ? false
      : dateStringer.getMyFromTs(new Date().getTime())
  );
  let [monthData, setMonthData] = useState([]);

  let availScrollBox = useRef();
  useEffect(() => {
    if (availScrollBox.current) {
      availScrollBox.current.scrollTop = 0;
    }
  }, [selectedMonWeek, selectedTeam]);

  useEffect(() => {
    if (showMonthView) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/my-avail-month-snake`,
          { my: showMonthView, teamID: selectedTeam },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setMonthData(response.data.calendar || []);
            setLoadingAvail(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [showMonthView, selectedTeam]);

  let [changed, setChanged] = useState(false);

  let [sureClose, setSureClose] = useState(false);
  let [sureWeek, setSureWeek] = useState(false);
  let [sureTeam, setSureTeam] = useState(false);
  let [sureDelete, setSureDelete] = useState(false);

  let [saved, setSaved] = useState(false);

  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      availData: [],
      monStart: "00:00",
      monEnd: "23:59",
      monStart2: "00:00",
      monEnd2: "23:59",
      monCount: 1,
      monActive: false,

      tueStart: "00:00",
      tueEnd: "23:59",
      tueStart2: "00:00",
      tueEnd2: "23:59",
      tueCount: 1,
      tueActive: false,

      wedStart: "00:00",
      wedEnd: "23:59",
      wedStart2: "00:00",
      wedEnd2: "23:59",
      wedCount: 1,
      wedActive: false,

      thuStart: "00:00",
      thuEnd: "23:59",
      thuStart2: "00:00",
      thuEnd2: "23:59",
      thuCount: 1,
      thuActive: false,

      friStart: "00:00",
      friEnd: "23:59",
      friStart2: "00:00",
      friEnd2: "23:59",
      friCount: 1,
      friActive: false,

      satStart: "00:00",
      satEnd: "23:59",
      satStart2: "00:00",
      satEnd2: "23:59",
      satCount: 1,
      satActive: false,

      sunStart: "00:00",
      sunEnd: "23:59",
      sunStart2: "00:00",
      sunEnd2: "23:59",
      sunCount: 1,
      sunActive: false,

      loading: true,
    }
  );

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  let [dsState, setDsState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      mon: "",
      tue: "",
      wed: "",
      thu: "",
      fri: "",
      sat: "",
      sun: "",
    }
  );

  useEffect(() => {
    // clean not needed
    let mon = selectedMonWeek;
    let dateObj = new Date(
      dateStringer.createTimestampFromString(selectedMonWeek)
    );
    dateObj.setDate(dateObj.getDate() + 1);
    dateObj.setHours(0, 0, 0, 0);
    let tue = dateStringer.createStringFromTimestamp(dateObj.getTime());
    dateObj.setDate(dateObj.getDate() + 1);
    dateObj.setHours(0, 0, 0, 0);
    let wed = dateStringer.createStringFromTimestamp(dateObj.getTime());

    dateObj.setDate(dateObj.getDate() + 1);
    dateObj.setHours(0, 0, 0, 0);
    let thu = dateStringer.createStringFromTimestamp(dateObj.getTime());

    dateObj.setDate(dateObj.getDate() + 1);
    dateObj.setHours(0, 0, 0, 0);
    let fri = dateStringer.createStringFromTimestamp(dateObj.getTime());

    dateObj.setDate(dateObj.getDate() + 1);
    dateObj.setHours(0, 0, 0, 0);
    let sat = dateStringer.createStringFromTimestamp(dateObj.getTime());

    dateObj.setDate(dateObj.getDate() + 1);
    dateObj.setHours(0, 0, 0, 0);
    let sun = dateStringer.createStringFromTimestamp(dateObj.getTime());

    setDsState({
      mon,
      tue,
      wed,
      thu,
      fri,
      sat,
      sun,
    });
  }, [selectedMonWeek]);

  let availDs = (day) => {
    if (day === "mon") {
      return dateStringer.printedDateFromDs(dsState.mon);
    }

    if (day === "tue") {
      return dateStringer.printedDateFromDs(dsState.tue);
    }

    if (day === "wed") {
      return dateStringer.printedDateFromDs(dsState.wed);
    }

    if (day === "thu") {
      return dateStringer.printedDateFromDs(dsState.thu);
    }

    if (day === "fri") {
      return dateStringer.printedDateFromDs(dsState.fri);
    }

    if (day === "sat") {
      return dateStringer.printedDateFromDs(dsState.sat);
    }

    if (day === "sun") {
      return dateStringer.printedDateFromDs(dsState.sun);
    }
  };

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-week-starts-for-schedule`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          // setWeekStarts(response.data.dsArr);

          let arr = [];
          let firstDateObj = new Date(
            dateStringer.createTimestampFromString(response.data.firstDs)
          );
          let lastDateObj = new Date(
            dateStringer.createTimestampFromString(response.data.lastDs)
          );
          let weeks = Math.round(
            (lastDateObj.getTime() - firstDateObj.getTime()) /
              1000 /
              60 /
              60 /
              24 /
              7
          );

          for (var i = 0; i < weeks; i++) {
            arr.push(
              dateStringer.createStringFromTimestamp(firstDateObj.getTime())
            );
            firstDateObj.setHours(0, 0, 0, 0);
            firstDateObj.setDate(firstDateObj.getDate() + 7);
          }

          setWeekStarts(arr);
          if (params && params.monWeek) {
            setSelectedMonWeek(dateStringer.getMonWeekOfDs(params.monWeek));
          } else {
            if (!monWeek) {
              setSelectedMonWeek(response.data.current);
            }
          }
          setCurrMonWeek(response.data.current);
          setTeams(response.data.teamObjs);

          if (loadTeamID === "loadPRIMARY") {
            if (response.data.teamObjs[0]) {
              let primFilt = response.data.teamObjs.filter((item) => {
                return item.primaryOrder === 0;
              });
              if (primFilt[0]) {
                setSelectedTeam(primFilt[0].teamID);
              }
            }
          } else {
            setSelectedTeam(loadTeamID);
          }
          //   // setSelectedMonWeek(monWeek);
          // } else {
          if (params && params.teamID) {
            setSelectedTeam(params.teamID);
          } else {
            if (!loadTeamID) {
              setSelectedTeam(response.data.teamObjs[0].teamID);
            }
          }
          // }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [itemActive, setItemActive] = useState(false);
  let [paramsDone, setParamsDone] = useState(false);
  let [reload, setReload] = useState(false);
  let [invalidTeam, setInvalidTeam] = useState(false);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    setChanged(false);
    if (selectedTeam) {
      setSaved(false);
      axios
        .post(
          `${serverURL}/get-availability-item`,
          {
            monWeek: selectedMonWeek,
            teamID: selectedTeam,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "team does not exist") {
            setInvalidTeam(true);
          }
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setState({ availData: response.data.avail || {} });
            if (response.data.avail) {
              setItemActive(true);
              if (params && params.monWeek) {
                setParamsDone(true);
              }
              setState({
                monStart: response.data.avail.availData.monStart || "",
                monStart2: response.data.avail.availData.monStart2 || "",
                monEnd: response.data.avail.availData.monEnd || "",
                monEnd2: response.data.avail.availData.monEnd2 || "",
                monActive: response.data.avail.availData.monActive || "",
                monCount: response.data.avail.availData.monCount || 0,

                tueStart: response.data.avail.availData.tueStart || "",
                tueStart2: response.data.avail.availData.tueStart2 || "",
                tueEnd: response.data.avail.availData.tueEnd || "",
                tueEnd2: response.data.avail.availData.tueEnd2 || "",
                tueActive: response.data.avail.availData.tueActive || "",
                tueCount: response.data.avail.availData.tueCount || 0,

                wedStart: response.data.avail.availData.wedStart || "",
                wedStart2: response.data.avail.availData.wedStart2 || "",
                wedEnd: response.data.avail.availData.wedEnd || "",
                wedEnd2: response.data.avail.availData.wedEnd2 || "",
                wedActive: response.data.avail.availData.wedActive || "",
                wedCount: response.data.avail.availData.wedCount || 0,

                thuStart: response.data.avail.availData.thuStart || "",
                thuStart2: response.data.avail.availData.thuStart2 || "",
                thuEnd: response.data.avail.availData.thuEnd || "",
                thuEnd2: response.data.avail.availData.thuEnd2 || "",
                thuActive: response.data.avail.availData.thuActive || "",
                thuCount: response.data.avail.availData.thuCount || 0,

                friStart: response.data.avail.availData.friStart || "",
                friStart2: response.data.avail.availData.friStart2 || "",
                friEnd: response.data.avail.availData.friEnd || "",
                friEnd2: response.data.avail.availData.friEnd2 || "",
                friActive: response.data.avail.availData.friActive || "",
                friCount: response.data.avail.availData.friCount || 0,

                satStart: response.data.avail.availData.satStart || "",
                satStart2: response.data.avail.availData.satStart2 || "",
                satEnd: response.data.avail.availData.satEnd || "",
                satEnd2: response.data.avail.availData.satEnd2 || "",
                satActive: response.data.avail.availData.satActive || "",
                satCount: response.data.avail.availData.satCount || 0,

                sunStart: response.data.avail.availData.sunStart || "",
                sunStart2: response.data.avail.availData.sunStart2 || "",
                sunEnd: response.data.avail.availData.sunEnd || "",
                sunEnd2: response.data.avail.availData.sunEnd2 || "",
                sunActive: response.data.avail.availData.sunActive || "",
                sunCount: response.data.avail.availData.sunCount || 0,

                loading: false,
              });
            } else {
              setItemActive(false);

              setState({
                monStart: "00:00",
                monEnd: "23:59",
                monStart2: "00:00",
                monEnd2: "23:59",
                monCount: 1,
                monActive: false,

                tueStart: "00:00",
                tueEnd: "23:59",
                tueStart2: "00:00",
                tueEnd2: "23:59",
                tueCount: 1,
                tueActive: false,

                wedStart: "00:00",
                wedEnd: "23:59",
                wedStart2: "00:00",
                wedEnd2: "23:59",
                wedCount: 1,
                wedActive: false,

                thuStart: "00:00",
                thuEnd: "23:59",
                thuStart2: "00:00",
                thuEnd2: "23:59",
                thuCount: 1,
                thuActive: false,

                friStart: "00:00",
                friEnd: "23:59",
                friStart2: "00:00",
                friEnd2: "23:59",
                friCount: 1,
                friActive: false,

                satStart: "00:00",
                satEnd: "23:59",
                satStart2: "00:00",
                satEnd2: "23:59",
                satCount: 1,
                satActive: false,

                sunStart: "00:00",
                sunEnd: "23:59",
                sunStart2: "00:00",
                sunEnd2: "23:59",
                sunCount: 1,
                sunActive: false,

                loading: false,
              });
            }
            if (loading) {
              setDataLoaded(true);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [selectedMonWeek, selectedTeam, reload]);

  let mobileWidthBreak = 820;
  mobileWidthBreak = 99999999999999;
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    // cleaned
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let generateMonWeeks = weekStarts.map((monWeek) => {
    return (
      <option value={monWeek} key={monWeek}>
        {dateStringer.printedDateWithYrFromDs(monWeek)}
      </option>
    );
  });

  let rows = [0, 1, 2, 3, 4, 5, 6];

  let generateTeams = teams.map((team) => {
    return (
      <option key={team.teamID} value={team.teamID}>
        {selectedTeam === team.teamID
          ? dateStringer.shorten(team.teamName, 28)
          : team.teamName}
      </option>
    );
  });

  let getTeamName = (teamID) => {
    let teamsFilt = teams.filter((x) => {
      return x.teamID === teamID;
    });
    if (teamsFilt[0]) {
      return teamsFilt[0].teamName;
    } else {
      return "";
    }
  };

  // let updateParent = (deleting) => {
  //   let dsArr = [];
  //   let dateObj = new Date(
  //     dateStringer.createTimestampFromString(selectedMonWeek)
  //   );
  //   for (var i = 0; i < 7; i++) {
  //     dsArr.push(dateStringer.createStringFromTimestamp(dateObj.getTime()));
  //     dateObj.setDate(dateObj.getDate() + 1);
  //     dateObj.setHours(0, 0, 0, 0);
  //   }
  //   let arr = [];
  //   dsArr.forEach((ds) => {
  //     let dateObj = new Date(dateStringer.createTimestampFromString(ds));
  //     if (dateObj.getDay() === 1) {
  //       arr.push({
  //         unavailable: !state.monActive,
  //         monWeek: selectedMonWeek,
  //         teamID: selectedTeam,
  //         startDs1: state.monStart,
  //         endDs1: state.monEnd,
  //         startDs1: state.monStart2,
  //         endDs2: state.monEnd2,
  //       });
  //     }

  //     if (dateObj.getDay() === 2) {
  //       arr.push({
  //         deleting,
  //         unavailable: !state.tueActive,
  //         monWeek: selectedMonWeek,
  //         teamID: selectedTeam,
  //         startDs1: state.tueStart,
  //         endDs1: state.tueEnd,
  //         startDs1: state.tueStart2,
  //         endDs2: state.tueEnd2,
  //         ds,
  //       });
  //     }

  //     if (dateObj.getDay() === 3) {
  //       arr.push({
  //         deleting,

  //         unavailable: !state.wedActive,
  //         monWeek: selectedMonWeek,
  //         teamID: selectedTeam,
  //         startDs1: state.wedStart,
  //         endDs1: state.wedEnd,
  //         startDs1: state.wedStart2,
  //         endDs2: state.wedEnd2,
  //         ds,
  //       });
  //     }

  //     if (dateObj.getDay() === 4) {
  //       arr.push({
  //         deleting,

  //         unavailable: !state.thuActive,
  //         monWeek: selectedMonWeek,
  //         teamID: selectedTeam,
  //         startDs1: state.thuStart,
  //         endDs1: state.thuEnd,
  //         startDs1: state.thuStart2,
  //         endDs2: state.thuEnd2,
  //         ds,
  //       });
  //     }

  //     if (dateObj.getDay() === 5) {
  //       arr.push({
  //         deleting,

  //         unavailable: !state.friActive,
  //         monWeek: selectedMonWeek,
  //         teamID: selectedTeam,
  //         startDs1: state.friStart,
  //         endDs1: state.friEnd,
  //         startDs1: state.friStart2,
  //         endDs2: state.friEnd2,
  //         ds,
  //       });
  //     }

  //     if (dateObj.getDay() === 6) {
  //       arr.push({
  //         deleting,

  //         unavailable: !state.satActive,
  //         monWeek: selectedMonWeek,
  //         teamID: selectedTeam,
  //         startDs1: state.satStart,
  //         endDs1: state.satEnd,
  //         startDs1: state.satStart2,
  //         endDs2: state.satEnd2,
  //         ds,
  //       });
  //     }

  //     if (dateObj.getDay() === 0) {
  //       arr.push({
  //         deleting,

  //         unavailable: !state.sunActive,
  //         monWeek: selectedMonWeek,
  //         teamID: selectedTeam,
  //         startDs1: state.sunStart,
  //         endDs1: state.sunEnd,
  //         startDs1: state.sunStart2,
  //         endDs2: state.sunEnd2,
  //         ds,
  //       });
  //     }
  //   });
  //   memoVals.setUpdateCalAvail( { teamID: selectedTeam, arr });
  // };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      memoVals.setShowAddAvailModal((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [copy, setCopy] = useState({ active: false, monWeek: "", teamID: "" });
  let [copyLoading, setCopyLoading] = useState(false);
  useEffect(() => {
    if (copyLoading) {
      setCopyLoading(false);
    }
  }, [selectedMonWeek, selectedTeam]);
  // master return

  return (
    <div
      className={`bulkEditLimitUnderlay`}
      onClick={() => {
        if (!showMonthView) {
          if (changed) {
            setSureClose(true);
          } else {
            if (params && params.teamID) {
              window.history.replaceState({}, document.title, "/rota");
            }
            memoVals.setShowAddAvailModal((x) => false);
          }
        }
      }}
    >
      {invalidTeam ? (
        ""
      ) : loading && !showMonthView ? (
        <div
          className={`shiftLoadingBox zoomIn ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`${
            width < mobileWidthBreak
              ? "availModalBox"
              : "availModalBox saveTemplateWideModalBox"
          } ${memoVals.mobModal ? "availModalBoxMob mobModalShoulder" : ""} ${
            memoVals.device.ios ? "availModalBoxMobIos" : ""
          } ${memoVals.device.vhIssue ? "x19839842" : ""} ${
            showMonthView ? "none" : ""
          } `}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div className="modalSwiper modalSwiperAddAvail"></div>
          )}
          <div
            className="bulkEditLimitModalHeader"
            onClick={() => {
              if (memoVals.mob) {
                if (changed) {
                  setSureClose(true);
                } else {
                  if (params && params.teamID) {
                    window.history.replaceState({}, document.title, "/rota");
                  }
                  memoVals.setShowAddAvailModal((x) => false);
                }
              }
            }}
            onTouchStart={handleDown}
            onTouchMove={handleMove}
            onTouchEnd={handleUp}
            onMouseDown={handleDown}
            onMouseMove={handleMove}
            onMouseUp={handleUp}
          >
            <img
              src={navyClose}
              alt="Close"
              className="closeBulkEditLimitModalIcon"
              onClick={() => {
                if (changed) {
                  setSureClose(true);
                } else {
                  if (params && params.teamID) {
                    window.history.replaceState({}, document.title, "/rota");
                  }
                  memoVals.setShowAddAvailModal((x) => false);
                }
              }}
            />
            <p className="bulkEditLimitModalHeaderTitle">Your availability</p>
            {/* <img
              src={navyClose}
              alt="Close"
              className="closeBulkEditLimitModalIcon bulkEditCloseBlank"
            />{" "} */}
          </div>
          {sureClose || sureWeek || sureTeam ? (
            <div
              className="sureCloseAvailModalUnderlay"
              onClick={() => {
                setSureClose(false);
              }}
            >
              <div className="formCreatedModal">
                <p className="overlapsRenTxt">
                  You have made changes without saving.
                  <br />
                  <br />
                  Are you sure you want to{" "}
                  {sureClose
                    ? "exit"
                    : sureWeek
                    ? "change week"
                    : "change team"}
                  ?
                </p>
                <div className="areYouModalBtnsDiv">
                  <p
                    className="areYouSureModalYesBtn"
                    onClick={() => {
                      if (sureClose) {
                        if (params && params.teamID) {
                          window.history.replaceState(
                            {},
                            document.title,
                            "/rota"
                          );
                        }
                        memoVals.setShowAddAvailModal((x) => false);
                      } else if (sureWeek) {
                        if (sureWeek === "forward") {
                          let currTs =
                            dateStringer.createTimestampFromString(
                              selectedMonWeek
                            );
                          let dateObj = new Date(currTs);
                          dateObj.setDate(dateObj.getDate() + 7);
                          dateObj.setHours(0, 0, 0, 0);

                          let newMonWeek =
                            dateStringer.createStringFromTimestamp(
                              dateObj.getTime()
                            );

                          if (weekStarts.includes(newMonWeek)) {
                            setSelectedMonWeek(newMonWeek);
                            // setLoading(true);
                          }
                          setSureWeek(false);
                        } else if (sureWeek === "backward") {
                          let currTs =
                            dateStringer.createTimestampFromString(
                              selectedMonWeek
                            );
                          let dateObj = new Date(currTs);
                          dateObj.setDate(dateObj.getDate() - 7);
                          dateObj.setHours(0, 0, 0, 0);

                          let newMonWeek =
                            dateStringer.createStringFromTimestamp(
                              dateObj.getTime()
                            );

                          if (weekStarts.includes(newMonWeek)) {
                            setSelectedMonWeek(newMonWeek);
                            // setLoading(true);
                          }
                          setSureWeek(false);
                        } else {
                          setSelectedMonWeek(sureWeek);
                          // setLoading(true);
                          setSureWeek(false);
                        }
                      } else {
                        setSelectedTeam(sureTeam);
                        setSureTeam(false);
                      }
                    }}
                  >
                    {sureClose
                      ? "Exit"
                      : sureWeek
                      ? "Change week"
                      : "Change team"}
                  </p>
                  <p
                    className="areYouModalNoBtn"
                    onClick={() => {
                      if (sureClose) {
                        setSureClose(false);
                      } else if (sureWeek) {
                        setSureWeek(false);
                      } else {
                        setSureTeam(false);
                      }
                    }}
                  >
                    Keep editing
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {sureDelete ? (
            <div
              className="sureCloseAvailModalUnderlay"
              onClick={() => {
                setSureClose(false);
              }}
            >
              <div className="formCreatedModal">
                <p className="overlapsRenTxt">
                  Are you sure you want to remove all availability entries for
                  the week starting{" "}
                  {dateStringer.printedDateFromDs(selectedMonWeek)} for{" "}
                  {getTeamName(selectedTeam)}?
                </p>
                <div className="areYouModalBtnsDiv">
                  <p
                    className="areYouSureModalYesBtn"
                    onClick={() => {
                      if (copy.active) {
                        setCopy({ active: false });
                      }
                      axios
                        .post(
                          `${serverURL}/delete-avail-item`,
                          {
                            teamID: selectedTeam,
                            monWeek: selectedMonWeek,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setSaved(false);
                            setItemActive(false);
                            memoVals.setUpdateCalAvail(
                              (x) => !memoVals.updateCalAvail
                            );
                            // updateParent(true);
                            setState({
                              monStart: "00:00",
                              monEnd: "23:59",
                              monStart2: "00:00",
                              monEnd2: "23:59",
                              monCount: 1,
                              monActive: false,

                              tueStart: "00:00",
                              tueEnd: "23:59",
                              tueStart2: "00:00",
                              tueEnd2: "23:59",
                              tueCount: 1,
                              tueActive: false,

                              wedStart: "00:00",
                              wedEnd: "23:59",
                              wedStart2: "00:00",
                              wedEnd2: "23:59",
                              wedCount: 1,
                              wedActive: false,

                              thuStart: "00:00",
                              thuEnd: "23:59",
                              thuStart2: "00:00",
                              thuEnd2: "23:59",
                              thuCount: 1,
                              thuActive: false,

                              friStart: "00:00",
                              friEnd: "23:59",
                              friStart2: "00:00",
                              friEnd2: "23:59",
                              friCount: 1,
                              friActive: false,

                              satStart: "00:00",
                              satEnd: "23:59",
                              satStart2: "00:00",
                              satEnd2: "23:59",
                              satCount: 1,
                              satActive: false,

                              sunStart: "00:00",
                              sunEnd: "23:59",
                              sunStart2: "00:00",
                              sunEnd2: "23:59",
                              sunCount: 1,
                              sunActive: false,
                            });

                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: `Week availability removed`,
                                colour: "blue",
                                duration: 4000,
                              };
                            });
                            setSureDelete(false);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    Yes - remove
                  </p>
                  <p
                    className="areYouModalNoBtn"
                    onClick={() => {
                      setSureDelete(false);
                    }}
                  >
                    No
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <div className="bulkEditTextDiv" onClick={() => {}}>
            <p className="availTxtInfo">
              Provide your availability for each team, for each week
              <br />
            </p>
          </div> */}
          <div className="modalExplainer modalExplainerAvail">
            {/* <div className="modalExplainLeftBlob"></div> */}
            <p className="modalExplainRightTxt">
              Provide your availability for each team, for each week
            </p>
          </div>
          <div
            className={`${
              width > mobileWidthBreak
                ? "availBoxContainerWide"
                : `availBoxContainerMob ${
                    memoVals.device.ios ? "availBoxContainerMobIos" : ""
                  }`
            }`}
          >
            <div
              className={`availUnit1 ${
                width <= mobileWidthBreak ? "availUnit1MobOnly" : ""
              }`}
            >
              <div className="addAvailSelectRow2">
                <p className="selectDateRange">Week</p>
                <div className="teamSchedFilterBarLeftAvail">
                  <img
                    src={currMonWeek === selectedMonWeek ? arrGrey : arrGrey}
                    alt="Left"
                    className={`leftMonWeek ${
                      selectedMonWeek === weekStarts[0]
                        ? "fadeSelMonWeekBtn"
                        : ""
                    }  ${
                      currMonWeek !== selectedMonWeek ? "nonCurrColours" : ""
                    }`}
                    onClick={() => {
                      if (changed) {
                        setSureWeek("backward");
                      } else {
                        let currTs =
                          dateStringer.createTimestampFromString(
                            selectedMonWeek
                          );
                        let dateObj = new Date(currTs);
                        dateObj.setDate(dateObj.getDate() - 7);
                        dateObj.setHours(0, 0, 0, 0);

                        let newMonWeek = dateStringer.createStringFromTimestamp(
                          dateObj.getTime()
                        );

                        if (weekStarts.includes(newMonWeek)) {
                          setSelectedMonWeek(newMonWeek);
                          // setLoading(true);
                        }
                      }
                    }}
                  />
                  <select
                    className={`monWeeksDropdown x289489398232 ${
                      currMonWeek === selectedMonWeek ? "currMonWeekBgDrop" : ""
                    } fontSize14`}
                    value={selectedMonWeek}
                    onChange={(e) => {
                      if (changed) {
                        setSureWeek(e.target.value);
                      } else {
                        setSelectedMonWeek(e.target.value);
                        // setLoading(true);
                      }
                    }}
                  >
                    {generateMonWeeks}
                  </select>{" "}
                  <img
                    src={currMonWeek === selectedMonWeek ? arrGrey : arrGrey}
                    alt="Right"
                    className={`rightMonWeek ${
                      selectedMonWeek === weekStarts[weekStarts.length - 1]
                        ? "fadeSelMonWeekBtn"
                        : ""
                    } ${
                      currMonWeek !== selectedMonWeek ? "nonCurrColours" : ""
                    }`}
                    onClick={() => {
                      if (changed) {
                        setSureWeek("forward");
                      } else {
                        let currTs =
                          dateStringer.createTimestampFromString(
                            selectedMonWeek
                          );
                        let dateObj = new Date(currTs);
                        dateObj.setDate(dateObj.getDate() + 7);
                        dateObj.setHours(0, 0, 0, 0);

                        let newMonWeek = dateStringer.createStringFromTimestamp(
                          dateObj.getTime()
                        );

                        if (weekStarts.includes(newMonWeek)) {
                          setSelectedMonWeek(newMonWeek);
                          // setLoading(true);
                        }
                      }
                    }}
                  />
                  {/* <img
                  src={currWeek}
                  onClick={() => {
                    setSelectedMonWeek(currMonWeek);
                  }}
                  alt="Current week"
                  className={`${
                    currMonWeek !== selectedMonWeek ? "currWeekIcon" : "none"
                  }`}
                /> */}
                </div>{" "}
              </div>
              <div className="addAvailSelectRow2">
                <p className="selectDateRange">Team</p>
                <select
                  className="availTeamsDropdown fontSize14 marginTop0"
                  value={selectedTeam}
                  onChange={(e) => {
                    if (changed) {
                      setSureTeam(e.target.value);
                    } else {
                      setSelectedTeam(e.target.value);
                    }
                  }}
                >
                  {generateTeams}
                </select>
              </div>
            </div>

            <div
              className={`${itemActive ? "availActiveBanner" : "none"} ${
                state.loading ? "invis" : ""
              }`}
            >
              Submitted
            </div>
            <div
              className={`${
                !itemActive ? "notYetSubmittedAvailBanner" : "none"
              } ${state.loading ? "invis" : ""}`}
            >
              Not yet submitted
            </div>

            <div
              className={`availUnit2 ${itemActive ? "availActiveBorder" : ""} ${
                state.loading ? "invis" : ""
              }`}
              ref={availScrollBox}
            >
              <div className={`availTable ${loading ? "none" : ""}`}>
                {" "}
                <div
                  onClick={() => {
                    if (!state.monActive) {
                      setState({ monActive: true });
                      setChanged(true);
                    }
                  }}
                  className={`availModalRow ${
                    state.monCount === 2 ? "extendAvailRow" : ""
                  } ${state.monActive ? "" : "availRowOff"}`}
                >
                  {" "}
                  <img
                    src={schedLimitPowerOff}
                    className={`availPower ${
                      state.monActive ? "" : "availPowerOff"
                    }`}
                    alt="Enable"
                    onClick={() => {
                      setState({ monActive: !state.monActive });
                      setChanged(true);
                    }}
                  />
                  <div className="availDayDiv">
                    <p
                      className={`availDay ${
                        state.monActive ? "" : "availDayMinWidth"
                      }`}
                    >
                      Mon
                    </p>
                    <p className="availDayStrip">
                      {availDs("mon").substr(4, 6)}
                    </p>
                  </div>{" "}
                  <div className="availDataParent">
                    <div
                      className={`availData ${state.monActive ? "" : "none"}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setState({ monActive: true });
                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                          setState({ monActive: true });
                          setChanged(true);
                        }}
                        className="availStart"
                        value={state.monStart}
                        onChange={(e) => {
                          setState({
                            monActive: true,
                            monStart: e.target.value,
                          });

                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={() => {
                          setState({ monActive: true });
                        }}
                        className="availStart"
                        value={state.monEnd}
                        onChange={(e) => {
                          setState({ monActive: true, monEnd: e.target.value });
                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>
                    <p className={`${state.monActive ? "none" : "unavilTxt"}`}>
                      Unavailable
                    </p>
                    <div
                      className={`${
                        state.monCount > 1 && state.monActive
                          ? "availData"
                          : "none"
                      }`}
                      onClick={() => {
                        setState({ monActive: true });
                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.monStart2}
                        onChange={(e) => {
                          setState({ monStart2: e.target.value });
                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.monEnd2}
                        onChange={(e) => {
                          setState({ monEnd2: e.target.value });
                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>
                  </div>
                  <img
                    src={
                      state.monCount === 1 ? schedLimitPlus : schedLimitMinus
                    }
                    className={`${
                      state.monActive
                        ? "availPlusAddBtn"
                        : "availPlusAddBtn fadeSelMonWeekBtn invis"
                    }`}
                    alt="Add"
                    onClick={() => {
                      setChanged(true);
                      if (state.monCount === 1) {
                        setState({ monCount: 2 });
                      }
                      if (state.monCount === 2) {
                        setState({ monCount: 1 });
                      }
                    }}
                  />
                </div>
                <div
                  onClick={() => {
                    if (!state.tueActive) {
                      setState({ tueActive: true });
                      setChanged(true);
                    }
                  }}
                  className={`availModalRow ${
                    state.tueCount === 2 ? "extendAvailRow" : ""
                  } ${state.tueActive ? "" : "availRowOff"}`}
                >
                  {" "}
                  <img
                    src={schedLimitPowerOff}
                    className={`availPower ${
                      state.tueActive ? "" : "availPowerOff"
                    }`}
                    alt="Enable"
                    onClick={() => {
                      setState({ tueActive: !state.tueActive });

                      setChanged(true);
                    }}
                  />
                  <div className="availDayDiv">
                    <p
                      className={`availDay ${
                        state.tueActive ? "" : "availDayMinWidth"
                      }`}
                    >
                      Tue
                    </p>
                    <p className="availDayStrip">
                      {availDs("tue").substr(4, 6)}
                    </p>
                  </div>{" "}
                  <div className="availDataParent">
                    <div
                      className={`availData ${state.tueActive ? "" : "none"}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setState({ tueActive: true });

                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                          setState({ tueActive: true });

                          setChanged(true);
                        }}
                        className="availStart"
                        value={state.tueStart}
                        onChange={(e) => {
                          setState({
                            tueActive: true,
                            tueStart: e.target.value,
                          });

                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                          setState({ tueActive: true });
                          setChanged(true);
                        }}
                        className="availStart"
                        value={state.tueEnd}
                        onChange={(e) => {
                          setState({ tueActive: true, tueEnd: e.target.value });

                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>{" "}
                    <p className={`${state.tueActive ? "none" : "unavilTxt"}`}>
                      Unavailable
                    </p>
                    <div
                      className={`${
                        state.tueCount > 1 && state.tueActive
                          ? "availData"
                          : "none"
                      }`}
                      onClick={() => {
                        setState({ tueActive: true });

                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                          setChanged(true);
                        }}
                        className="availStart"
                        value={state.tueStart2}
                        onChange={(e) => {
                          setState({ tueStart2: e.target.value });

                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.tueEnd2}
                        onChange={(e) => {
                          setState({ tueEnd2: e.target.value });

                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>
                  </div>
                  <img
                    src={
                      state.tueCount === 1 ? schedLimitPlus : schedLimitMinus
                    }
                    className={`${
                      state.tueActive
                        ? "availPlusAddBtn"
                        : "availPlusAddBtn fadeSelMonWeekBtn invis"
                    }`}
                    alt="Add"
                    onClick={() => {
                      setChanged(true);

                      if (state.tueCount === 1) {
                        setState({ tueCount: 2 });
                      }
                      if (state.tueCount === 2) {
                        setState({ tueCount: 1 });
                      }
                    }}
                  />
                </div>
                <div
                  onClick={() => {
                    if (!state.wedActive) {
                      setState({ wedActive: true });
                      setChanged(true);
                    }
                  }}
                  className={`availModalRow ${
                    state.wedCount === 2 ? "extendAvailRow" : ""
                  } ${state.wedActive ? "" : "availRowOff"}`}
                >
                  {" "}
                  <img
                    src={schedLimitPowerOff}
                    className={`availPower ${
                      state.wedActive ? "" : "availPowerOff"
                    }`}
                    alt="Enable"
                    onClick={() => {
                      setState({ wedActive: !state.wedActive });
                      setChanged(true);
                    }}
                  />
                  <div className="availDayDiv">
                    <p
                      className={`availDay ${
                        state.wedActive ? "" : "availDayMinWidth"
                      }`}
                    >
                      Wed
                    </p>
                    <p className="availDayStrip">
                      {availDs("wed").substr(4, 6)}
                    </p>
                  </div>{" "}
                  <div className="availDataParent">
                    <div
                      className={`availData ${state.wedActive ? "" : "none"}`}
                      onClick={() => {
                        setState({ wedActive: true });
                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                          setState({ wedActive: true });
                          setChanged(true);
                        }}
                        className="availStart"
                        value={state.wedStart}
                        onChange={(e) => {
                          setState({
                            wedStart: e.target.value,
                            wedActive: true,
                          });

                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          setState({ wedActive: true });
                          setChanged(true);

                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.wedEnd}
                        onChange={(e) => {
                          setState({ wedEnd: e.target.value, wedActive: true });

                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>
                    <p className={`${state.wedActive ? "none" : "unavilTxt"}`}>
                      Unavailable
                    </p>
                    <div
                      className={`${
                        state.wedCount > 1 && state.wedActive
                          ? "availData"
                          : "none"
                      }`}
                      onClick={() => {
                        setState({ wedActive: true });
                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.wedStart2}
                        onChange={(e) => {
                          setState({ wedStart2: e.target.value });
                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.wedEnd2}
                        onChange={(e) => {
                          setState({ wedEnd2: e.target.value });

                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>
                  </div>
                  <img
                    src={
                      state.wedCount === 1 ? schedLimitPlus : schedLimitMinus
                    }
                    className={`${
                      state.wedActive
                        ? "availPlusAddBtn"
                        : "availPlusAddBtn fadeSelMonWeekBtn invis"
                    }`}
                    alt="Add"
                    onClick={() => {
                      setChanged(true);

                      if (state.wedCount === 1) {
                        setState({ wedCount: 2 });
                      }
                      if (state.wedCount === 2) {
                        setState({ wedCount: 1 });
                      }
                    }}
                  />
                </div>
                <div
                  onClick={() => {
                    if (!state.thuActive) {
                      setState({ thuActive: true });
                      setChanged(true);
                    }
                  }}
                  className={`availModalRow ${
                    state.thuCount === 2 ? "extendAvailRow" : ""
                  } ${state.thuActive ? "" : "availRowOff"}`}
                >
                  {" "}
                  <img
                    src={schedLimitPowerOff}
                    className={`availPower ${
                      state.thuActive ? "" : "availPowerOff"
                    }`}
                    alt="Enable"
                    onClick={() => {
                      setState({ thuActive: !state.thuActive });
                      setChanged(true);
                    }}
                  />
                  <div className="availDayDiv">
                    <p
                      className={`availDay ${
                        state.thuActive ? "" : "availDayMinWidth"
                      }`}
                    >
                      Thu
                    </p>
                    <p className="availDayStrip">
                      {availDs("thu").substr(4, 6)}
                    </p>
                  </div>{" "}
                  <div className="availDataParent">
                    <div
                      className={`availData ${state.thuActive ? "" : "none"}`}
                      onClick={() => {
                        setState({ thuActive: true });
                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                          setState({ thuActive: true });
                          setChanged(true);
                        }}
                        className="availStart"
                        value={state.thuStart}
                        onChange={(e) => {
                          setState({
                            thuActive: true,
                            thuStart: e.target.value,
                          });

                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                          setState({ thuActive: true });
                          setChanged(true);
                        }}
                        className="availStart"
                        value={state.thuEnd}
                        onChange={(e) => {
                          setState({ thuActive: true, thuEnd: e.target.value });

                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>
                    <div
                      className={`${
                        state.thuCount > 1 && state.thuActive
                          ? "availData"
                          : "none"
                      }`}
                      onClick={() => {
                        setState({ thuActive: true });
                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.thuStart2}
                        onChange={(e) => {
                          setState({ thuStart2: e.target.value });

                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.thuEnd2}
                        onChange={(e) => {
                          setState({ thuEnd2: e.target.value });

                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>
                    <p className={`${state.thuActive ? "none" : "unavilTxt"}`}>
                      Unavailable
                    </p>
                  </div>
                  <img
                    src={
                      state.thuCount === 1 && state.thuActive
                        ? schedLimitPlus
                        : schedLimitMinus
                    }
                    className={`${
                      state.thuActive
                        ? "availPlusAddBtn"
                        : "availPlusAddBtn fadeSelMonWeekBtn invis"
                    }`}
                    alt="Add"
                    onClick={() => {
                      setChanged(true);

                      if (state.thuCount === 1) {
                        setState({ thuCount: 2 });
                      }
                      if (state.thuCount === 2) {
                        setState({ thuCount: 1 });
                      }
                    }}
                  />
                </div>
                <div
                  onClick={() => {
                    if (!state.friActive) {
                      setState({ friActive: true });
                      setChanged(true);
                    }
                  }}
                  className={`availModalRow ${
                    state.friCount === 2 ? "extendAvailRow" : ""
                  } ${state.friActive ? "" : "availRowOff"}`}
                >
                  {" "}
                  <img
                    src={schedLimitPowerOff}
                    className={`availPower ${
                      state.friActive ? "" : "availPowerOff"
                    }`}
                    alt="Enable"
                    onClick={() => {
                      setState({ friActive: !state.friActive });
                      setChanged(true);
                    }}
                  />
                  <div className="availDayDiv">
                    <p
                      className={`availDay ${
                        state.friActive ? "" : "availDayMinWidth"
                      }`}
                    >
                      Fri
                    </p>
                    <p className="availDayStrip">
                      {availDs("fri").substr(4, 6)}
                    </p>
                  </div>{" "}
                  <div className="availDataParent">
                    <div
                      className={`availData ${state.friActive ? "" : "none"}`}
                      onClick={() => {
                        setState({ friActive: true });
                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                          setState({ friActive: true });

                          setChanged(true);
                        }}
                        className="availStart"
                        value={state.friStart}
                        onChange={(e) => {
                          setState({
                            friActive: true,
                            friStart: e.target.value,
                          });
                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                          setState({ friActive: true });
                          setChanged(true);
                        }}
                        className="availStart"
                        value={state.friEnd}
                        onChange={(e) => {
                          setState({ friActive: true, friEnd: e.target.value });

                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>
                    <div
                      className={`${
                        state.friCount > 1 && state.friActive
                          ? "availData"
                          : "none"
                      }`}
                      onClick={() => {
                        setState({ friActive: true });
                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.friStart2}
                        onChange={(e) => {
                          setState({ friStart2: e.target.value });
                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.friEnd2}
                        onChange={(e) => {
                          setState({ friEnd2: e.target.value });
                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>
                    <p className={`${state.friActive ? "none" : "unavilTxt"}`}>
                      Unavailable
                    </p>
                  </div>
                  <img
                    src={
                      state.friCount === 1 ? schedLimitPlus : schedLimitMinus
                    }
                    className={`${
                      state.friActive
                        ? "availPlusAddBtn"
                        : "availPlusAddBtn fadeSelMonWeekBtn invis"
                    }`}
                    alt="Add"
                    onClick={() => {
                      setChanged(true);

                      if (state.friCount === 1) {
                        setState({ friCount: 2 });
                      }
                      if (state.friCount === 2) {
                        setState({ friCount: 1 });
                      }
                    }}
                  />
                </div>
                <div
                  onClick={() => {
                    if (!state.satActive) {
                      setState({ satActive: true });
                      setChanged(true);
                    }
                  }}
                  className={`availModalRow ${
                    state.satCount === 2 ? "extendAvailRow" : ""
                  } ${state.satActive ? "" : "availRowOff"}`}
                >
                  {" "}
                  <img
                    src={schedLimitPowerOff}
                    className={`availPower ${
                      state.satActive ? "" : "availPowerOff"
                    }`}
                    alt="Enable"
                    onClick={() => {
                      setState({ satActive: !state.satActive });
                      setChanged(true);
                    }}
                  />
                  <div className="availDayDiv">
                    <p
                      className={`availDay ${
                        state.satActive ? "" : "availDayMinWidth"
                      }`}
                    >
                      Sat
                    </p>
                    <p className="availDayStrip">
                      {availDs("sat").substr(4, 6)}
                    </p>
                  </div>{" "}
                  <div className="availDataParent">
                    <div
                      className={`availData ${state.satActive ? "" : "none"}`}
                      onClick={() => {
                        setState({ satActive: true });
                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                          setState({ satActive: true });
                          setChanged(true);
                        }}
                        className="availStart"
                        value={state.satStart}
                        onChange={(e) => {
                          setState({
                            satActive: true,
                            satStart: e.target.value,
                          });

                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                          setState({ satActive: true });
                          setChanged(true);
                        }}
                        className="availStart"
                        value={state.satEnd}
                        onChange={(e) => {
                          setState({ satActive: true, satEnd: e.target.value });

                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>
                    <div
                      className={`${
                        state.satCount > 1 && state.satActive
                          ? "availData"
                          : "none"
                      }`}
                      onClick={() => {
                        setState({ satActive: true });
                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.satStart2}
                        onChange={(e) => {
                          setState({ atStart2: e.target.value });
                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.satEnd2}
                        onChange={(e) => {
                          setState({ satEnd2: e.target.value });

                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>
                    <p className={`${state.satActive ? "none" : "unavilTxt"}`}>
                      Unavailable
                    </p>
                  </div>
                  <img
                    src={
                      state.satCount === 1 ? schedLimitPlus : schedLimitMinus
                    }
                    className={`${
                      state.satActive
                        ? "availPlusAddBtn"
                        : "availPlusAddBtn fadeSelMonWeekBtn invis"
                    }`}
                    alt="Add"
                    onClick={() => {
                      setChanged(true);

                      if (state.satCount === 1) {
                        setState({ satCount: 2 });
                      }
                      if (state.satCount === 2) {
                        setState({ satCount: 1 });
                      }
                    }}
                  />
                </div>
                <div
                  onClick={() => {
                    if (!state.sunActive) {
                      setState({ sunActive: true });
                      setChanged(true);
                    }
                  }}
                  className={`availModalRow ${
                    state.sunCount === 2 ? "extendAvailRow" : ""
                  } ${state.sunActive ? "" : "availRowOff"}`}
                >
                  {" "}
                  <img
                    src={schedLimitPowerOff}
                    className={`availPower ${
                      state.sunActive ? "" : "availPowerOff"
                    }`}
                    alt="Enable"
                    onClick={() => {
                      setState({ sunActive: !state.sunActive });
                      setChanged(true);
                    }}
                  />
                  <div className="availDayDiv">
                    <p
                      className={`availDay ${
                        state.sunActive ? "" : "availDayMinWidth"
                      }`}
                    >
                      Sun
                    </p>
                    <p className="availDayStrip">
                      {availDs("sun").substr(4, 6)}
                    </p>
                  </div>
                  <div className="availDataParent">
                    <div
                      className={`availData ${state.sunActive ? "" : "none"}`}
                      onClick={() => {
                        setState({ sunActive: true });
                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                          setState({ sunActive: true });

                          setChanged(true);
                        }}
                        className="availStart"
                        value={state.sunStart}
                        onChange={(e) => {
                          setState({
                            sunActive: true,
                            sunStart: e.target.value,
                          });

                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                          setState({
                            sunActive: true,
                            sunStart: e.target.value,
                          });
                          setChanged(true);
                        }}
                        className="availStart"
                        value={state.sunEnd}
                        onChange={(e) => {
                          setState({ sunActive: true, sunEnd: e.target.value });

                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>
                    <div
                      className={`${
                        state.sunCount > 1 && state.sunActive
                          ? "availData"
                          : "none"
                      }`}
                      onClick={() => {
                        setState({ sunActive: true });
                        setChanged(true);
                      }}
                    >
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.sunStart2}
                        onChange={(e) => {
                          setState({ sunStart2: e.target.value });
                          setChanged(true);
                        }}
                      ></input>
                      <p className="availUntil">to</p>
                      <input
                        type="time"
                        onFocus={(e) => e.target.showPicker()} // Show the time picker when focused
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="availStart"
                        value={state.sunEnd2}
                        onChange={(e) => {
                          setState({ sunEnd2: e.target.value });
                          setChanged(true);
                        }}
                      ></input>{" "}
                    </div>
                    <p className={`${state.sunActive ? "none" : "unavilTxt"}`}>
                      Unavailable
                    </p>
                  </div>
                  <img
                    src={
                      state.sunCount === 1 ? schedLimitPlus : schedLimitMinus
                    }
                    className={`${
                      state.sunActive
                        ? "availPlusAddBtn"
                        : "availPlusAddBtn fadeSelMonWeekBtn invis"
                    }`}
                    alt="Add"
                    onClick={() => {
                      setChanged(true);

                      if (state.sunCount === 1) {
                        setState({ sunCount: 2 });
                      }
                      if (state.sunCount === 2) {
                        setState({ sunCount: 1 });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="availModalFooter">
            <div className="availSaveAndDeleteContainer">
              {/* <p className={`${saved && !changed ? "availSavedBtn" : "none"}`}>
                Saved
              </p> */}

              <p
                className={`saveBulkModalBtn saveAvailBtn ${
                  itemActive ? "x233245211" : ""
                } ${!itemActive ? "marginRight0" : ""} ${changed ? "" : ""} ${
                  itemActive && !changed ? "none" : ""
                }`}
                onClick={() => {
                  if (copy.active) {
                    setCopy({ active: false });
                  }
                  let availData = {
                    monStart: state.monStart,
                    monEnd: state.monEnd,
                    monActive: state.monActive,
                    monStart2: state.monStart2,
                    monEnd2: state.monEnd2,
                    monCount: state.monCount,
                    tueStart: state.tueStart,
                    tueEnd: state.tueEnd,
                    tueActive: state.tueActive,
                    tueStart2: state.tueStart2,
                    tueEnd2: state.tueEnd2,
                    tueCount: state.tueCount,
                    wedStart: state.wedStart,
                    wedEnd: state.wedEnd,
                    wedActive: state.wedActive,
                    wedStart2: state.wedStart2,
                    wedEnd2: state.wedEnd2,
                    wedCount: state.wedCount,
                    thuStart: state.thuStart,
                    thuEnd: state.thuEnd,
                    thuActive: state.thuActive,
                    thuStart2: state.thuStart2,
                    thuEnd2: state.thuEnd2,
                    thuCount: state.thuCount,
                    friStart: state.friStart,
                    friEnd: state.friEnd,
                    friActive: state.friActive,
                    friStart2: state.friStart2,
                    friEnd2: state.friEnd2,
                    friCount: state.friCount,
                    satStart: state.satStart,
                    satEnd: state.satEnd,
                    satActive: state.satActive,
                    satStart2: state.satStart2,
                    satEnd2: state.satEnd2,
                    satCount: state.satCount,
                    sunStart: state.sunStart,
                    sunEnd: state.sunEnd,
                    sunActive: state.sunActive,
                    sunStart2: state.sunStart2,
                    sunEnd2: state.sunEnd2,
                    sunCount: state.sunCount,
                  };
                  axios
                    .post(
                      `${serverURL}/edit-avail`,
                      {
                        teamID: selectedTeam,
                        monWeek: selectedMonWeek,
                        availData,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setSaved(true);
                        setChanged(false);
                        setItemActive(true);
                        memoVals.setUpdateCalAvail(!memoVals.updateCalAvail);

                        // updateParent();

                        setState({
                          monStart: response.data.doc.availData.monStart,
                          monStart2: response.data.doc.availData.monStart2,
                          monEnd: response.data.doc.availData.monEnd,
                          monEnd2: response.data.doc.availData.monEnd2,
                          monActive: response.data.doc.availData.monActive,
                          monCount: response.data.doc.availData.monCount,

                          tueStart: response.data.doc.availData.tueStart,
                          tueStart2: response.data.doc.availData.tueStart2,
                          tueEnd: response.data.doc.availData.tueEnd,
                          tueEnd2: response.data.doc.availData.tueEnd2,
                          tueActive: response.data.doc.availData.tueActive,
                          tueCount: response.data.doc.availData.tueCount,

                          wedStart: response.data.doc.availData.wedStart,
                          wedStart2: response.data.doc.availData.wedStart2,
                          wedEnd: response.data.doc.availData.wedEnd,
                          wedEnd2: response.data.doc.availData.wedEnd2,
                          wedActive: response.data.doc.availData.wedActive,
                          wedCount: response.data.doc.availData.wedCount,

                          thuStart: response.data.doc.availData.thuStart,
                          thuStart2: response.data.doc.availData.thuStart2,
                          thuEnd: response.data.doc.availData.thuEnd,
                          thuEnd2: response.data.doc.availData.thuEnd2,
                          thuActive: response.data.doc.availData.thuActive,
                          thuCount: response.data.doc.availData.thuCount,

                          friStart: response.data.doc.availData.friStart,
                          friStart2: response.data.doc.availData.friStart2,
                          friEnd: response.data.doc.availData.friEnd,
                          friEnd2: response.data.doc.availData.friEnd2,
                          friActive: response.data.doc.availData.friActive,
                          friCount: response.data.doc.availData.friCount,

                          satStart: response.data.doc.availData.satStart,
                          satStart2: response.data.doc.availData.satStart2,
                          satEnd: response.data.doc.availData.satEnd,
                          satEnd2: response.data.doc.availData.satEnd2,
                          satActive: response.data.doc.availData.satActive,
                          satCount: response.data.doc.availData.satCount,

                          sunStart: response.data.doc.availData.sunStart,
                          sunStart2: response.data.doc.availData.sunStart2,
                          sunEnd: response.data.doc.availData.sunEnd,
                          sunEnd2: response.data.doc.availData.sunEnd2,
                          sunActive: response.data.doc.availData.sunActive,
                          sunCount: response.data.doc.availData.sunCount,
                        });

                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: `Availability submitted`,
                            colour: "green",
                            duration: 4000,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                {itemActive ? "Save" : "Submit"}
              </p>
              <p
                className={`${itemActive ? "deleteAvailBtn" : "none"}`}
                onClick={() => {
                  setSureDelete(true);
                }}
              >
                Delete
              </p>
              {!itemActive ? (
                ""
              ) : state.monActive ||
                state.tueActive ||
                state.wedActive ||
                state.thuActive ||
                state.friActive ||
                state.satActive ||
                state.sunActive ? (
                <p
                  className={`mgrAvailDayModeBtn ${
                    copy.active &&
                    copy.monWeek === selectedMonWeek &&
                    copy.teamID === selectedTeam
                      ? "copiedFont"
                      : ""
                  }`}
                  onClick={() => {
                    setCopy({
                      active: true,
                      monWeek: selectedMonWeek,
                      teamID: selectedTeam,
                    });
                  }}
                >
                  {copy.active &&
                  copy.monWeek === selectedMonWeek &&
                  copy.teamID === selectedTeam
                    ? "Copied"
                    : "Copy"}
                </p>
              ) : (
                ""
              )}
              {copy.active &&
              (copy.monWeek !== selectedMonWeek ||
                copy.teamID !== selectedTeam) ? (
                <p
                  className={`mgrAvailDayModeBtn ${
                    copyLoading ? "disable" : ""
                  }`}
                  onClick={() => {
                    setCopyLoading(true);
                    axios
                      .post(
                        `${serverURL}/paste-availability`,
                        {
                          oldMonWeek: copy.monWeek,
                          newMonWeek: selectedMonWeek,
                          newTeamID: selectedTeam,
                          oldTeamID: copy.teamID,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message.includes("logout")) {
                          window.location.href = "/signin";
                        }
                        if (response.data.message === "success") {
                          setTimeout(() => {
                            setCopyLoading(false);
                          }, 2000);
                          setReload(!reload);
                        } else {
                          setCopyLoading(false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                        setCopyLoading(false);
                      });
                  }}
                >
                  Paste
                </p>
              ) : (
                ""
              )}
              {/* <p
                className={`mgrAvailDayModeBtn `}
                onClick={() => {
                  setShowMonthView(
                    dateStringer.getMyFromTs(
                      dateStringer.createTimestampFromString(selectedMonWeek)
                    )
                  );
                }}
              >
                {" "}
                Month view{" "}
                <img
                  src={popup}
                  alt="Month view"
                  className="availMonthViewPopupImg"
                />
              </p> */}
            </div>
            <p
              className="closeReqInfo"
              onClick={() => {
                if (changed) {
                  setSureClose(true);
                } else {
                  if (params && params.teamID) {
                    window.history.replaceState({}, document.title, "/rota");
                  }
                  memoVals.setShowAddAvailModal((x) => false);
                }
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}

      {loading && showMonthView ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : showMonthView ? (
        <div
          className="bulkEditLimitUnderlay_ myAvailMonthViewUnderlay_"
          onClick={(e) => {
            e.stopPropagation();

            memoVals.setShowAddAvailModal((x) => false);
          }}
        >
          <div
            className={`availMonthViewModal ${
              memoVals.mobModal
                ? `mobModalShoulder slideUp ${
                    memoVals.device.ios ? "availMonthViewModalIos" : ""
                  }`
                : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={modalPosition}
            ref={modalRef}
            onTouchStart={handleDown}
            onTouchMove={handleMove}
            onTouchEnd={handleUp}
            onMouseDown={handleDown}
            onMouseMove={handleMove}
            onMouseUp={handleUp}
          >
            {memoVals.mobModal && (
              <div className="modalSwiper modalSwiperAddAvail"></div>
            )}
            <div
              className="bulkEditLimitModalHeader"
              onClick={() => {
                if (memoVals.mob) {
                  memoVals.setShowAddAvailModal((x) => false);
                }
              }}
            >
              <img
                src={navyClose}
                alt="Close"
                className="closeBulkEditLimitModalIcon"
                onClick={() => {
                  memoVals.setShowAddAvailModal((x) => false);
                }}
              />
              <p className="bulkEditLimitModalHeaderTitle">Your availability</p>
              {/* <img
                src={navyClose}
                alt="Close"
                className="closeBulkEditLimitModalIcon invis"
                // onClick={() => {
                //   setShowMonthView(false);
                // }}
              /> */}
              {/* <select
                className="availTeamsDropdown"
                value={selectedTeam}
                onChange={(e) => {
                  setSelectedTeam(e.target.value);
                }}
              >
                {generateTeams}
              </select> */}
            </div>{" "}
            <div className="availTeamsParent">
              <p></p>
              <select
                className="availTeamsDropdown"
                value={selectedTeam}
                onChange={(e) => {
                  setSelectedTeam(e.target.value);
                }}
              >
                {generateTeams}
              </select>
            </div>
            <div className="availMonthSelBar">
              <img
                src={arrGrey}
                alt="Back a month"
                className={`availMonthSelArrLeft ${
                  !memoVals.mob ? "availMonthSelArrLeftDesktop" : ""
                }`}
                onClick={() => {
                  setLoadingAvail(true);

                  let dateObj = new Date(
                    dateStringer.getTsFromMy(showMonthView)
                  );
                  dateObj.setDate(1);
                  dateObj.setMonth(dateObj.getMonth() - 1);
                  setShowMonthView(dateStringer.getMyFromTs(dateObj.getTime()));
                }}
              />
              <p className="availMonthMonthTitle">
                {dateStringer.printMonthAndYearFromMy(
                  showMonthView,
                  true,
                  true
                )}
              </p>
              <img
                src={arrGrey}
                alt="Forward a month"
                className={`availMonthSelArrRight ${
                  !memoVals.mob ? "availMonthSelArrRightDesktop" : ""
                }`}
                onClick={() => {
                  setLoadingAvail(true);
                  let dateObj = new Date(
                    dateStringer.getTsFromMy(showMonthView)
                  );
                  dateObj.setDate(1);
                  dateObj.setMonth(dateObj.getMonth() + 1);
                  setShowMonthView(dateStringer.getMyFromTs(dateObj.getTime()));
                }}
              />
            </div>
            <div className="monthAvailDayBar">
              <p className="monthAvailDayTitle">Mon</p>
              <p className="monthAvailDayTitle">Tue</p>
              <p className="monthAvailDayTitle">Wed</p>
              <p className="monthAvailDayTitle">Thu</p>
              <p className="monthAvailDayTitle">Fri</p>
              <p className="monthAvailDayTitle">Sat</p>
              <p className="monthAvailDayTitle">Sun</p>
            </div>
            <div className="monthAvailSnake">
              {/* hello */}
              {monthData.map((row, i) => {
                return (
                  <div
                    key={i}
                    className="myAvailWkRow"
                    onClick={() => {
                      setSelectedMonWeek(row.monWeek);

                      setShowMonthView(false);
                    }}
                  >
                    {row.arr.map((x, i) => {
                      return (
                        <div
                          key={i}
                          className={`myAvailCalDayItem ${
                            loadingAvail ? "loadingAvail" : ""
                          } ${
                            todayDs00 === x.date ? "todayAvailItemMark" : ""
                          }`}
                        >
                          <p className={x.focused ? "x314562" : "availDayN"}>
                            {x.dayNum}
                          </p>
                          <img
                            src={
                              !x.submitted
                                ? lightBlueQuestionMark
                                : x.items[0]
                                ? tick
                                : cross
                            }
                            alt="Availability submission"
                            className="availSubmissionIcon"
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div className="monthAvailFooter">
              <p></p>
              <p
                className="closeReqInfo"
                onClick={() => {
                  memoVals.setShowAddAvailModal((x) => false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {invalidTeam ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            if (params && params.teamID) {
              window.history.replaceState({}, document.title, "/rota");
            }
            memoVals.setShowAddAvailModal((x) => false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">The team doesn't exist.</p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                if (params && params.teamID) {
                  window.history.replaceState({}, document.title, "/rota");
                }
                memoVals.setShowAddAvailModal((x) => false);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default AddAvailModal;
