import React, { useContext, useState, useEffect, useMemo } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";

import "../../../styles/settings.css";
import "../../../styles/admin.css";

import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";
import { AdminContext } from "../../../contexts/AdminContext";

import serverURL from "../../../serverURL";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import navyBin from "../../../img/general/navyBin.svg";
import whiteAddThin from "../../../img/general/whiteAddThin.svg";
import appURL from "../../../appURL";

const Absence2 = () => {
  const { setAccNav, mob } = useContext(StyleContext);

  const {
    setSelectedNav,
    device,
    setShowAbsenceTypeModal,
    setSureRemoveAbsenceType,
  } = useContext(DataContext);

  const {
    allowSelfLogAbsence,
    setAllowSelfLogAbsence,
    enableAbsenceRanking,
    setEnableAbsenceRanking,
    rtwFormEnabled, //
    setRtwFormEnabled,
    rtwQuestions,
    setRtwQuestions,
    absenceTypes,
    setAbsenceTypes,
    showAddRtwModal,
    setShowAddRtwModal,
    question,
    setQuestion, //
  } = useContext(AdminContext);

  const memoVals = useMemo(
    () => ({
      setAccNav,
      mob,
      setSelectedNav,
      setShowAbsenceTypeModal,
      setSureRemoveAbsenceType,
      allowSelfLogAbsence,
      setAllowSelfLogAbsence,
      enableAbsenceRanking,
      setEnableAbsenceRanking,
      rtwFormEnabled,
      setRtwFormEnabled,
      rtwQuestions,
      setRtwQuestions,
      absenceTypes,
      setAbsenceTypes,
      showAddRtwModal,
      setShowAddRtwModal,
      question,
      setQuestion,
      device,
    }),
    [
      setAccNav,
      mob,
      setSelectedNav,
      setShowAbsenceTypeModal,
      setSureRemoveAbsenceType,
      allowSelfLogAbsence,
      setAllowSelfLogAbsence,
      enableAbsenceRanking,
      setEnableAbsenceRanking,
      rtwFormEnabled,
      setRtwFormEnabled,
      rtwQuestions,
      setRtwQuestions,
      absenceTypes,
      setAbsenceTypes,
      showAddRtwModal,
      setShowAddRtwModal,
      question,
      setQuestion,
      device,
    ]
  );

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 2) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyBin}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />

      <img
        src={whiteAddThin}
        alt="Edit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  // if the page is refreshed, set the AccNav to settings
  useEffect(() => {
    memoVals.setAccNav((x) => "admin");
    memoVals.setSelectedNav((x) => 5.1);
  }, []);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-absence-admin-settings`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "permissions not correct") {
          window.location.href = `${appURL}/signin`;
        }
        if (response.data) {
          // console.log(response.data);
          memoVals.setAllowSelfLogAbsence(
            (x) => response.data.absenceAllowSelfLog
          );
          memoVals.setEnableAbsenceRanking(
            (x) => response.data.absenceEnableRank
          );
          memoVals.setRtwFormEnabled((x) => response.data.rtwForm);
          memoVals.setRtwQuestions((x) => response.data.rtwQuestions);
          memoVals.setAbsenceTypes((x) => response.data.absenceTypes);
          setDataLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [showRemoved, setShowRemoved] = useState(false);
  let [selfLogPaid, setSelfLogPaid] = useState(false);

  let shortenLocation = (val) => {
    if (!val) {
      return "";
    }
    if (val?.length > 25) {
      return `${val.substr(0, 22)}...`;
    } else return val;
  };

  useEffect(() => {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Update the slug
    currentURL.pathname = "/admin/absence";

    // Change the URL without reloading the page
    window.history.pushState({}, "", currentURL.href);
  }, []);

  let generateTypes = absenceTypes
    ?.filter((x) => {
      return !x.removed;
    })
    ?.map((item) => {
      return (
        <div key={item._id}>
          <div
            className="specialDayUnit customLeaveTypeUnit"
            onClick={() => {
              memoVals.setShowAbsenceTypeModal((x) => {
                return {
                  show: true,
                  absenceTypeID: item.absenceTypeID,
                  rate: item.rate,
                  name: item.name,
                  removed: item.removed,
                  removable: item.removable,
                };
              });
            }}
          >
            <div className="customTypeLeft">
              <span onClick={() => {}}>
                <p
                  className={`specialDayDate ${
                    shortenLocation(item.name)?.length > 18 ? "x283819123" : ""
                  }`}
                >
                  {shortenLocation(item.name)}
                </p>
              </span>
              <p className="customLeaveTypeSubTxt">
                {item.rate === 0 || item.rate === "0"
                  ? "Unpaid"
                  : `Paid | ${item.rate}x`}
              </p>
            </div>
            <img
              className={`gpsZoneDeleteCrossImg ${
                item.removable ? "" : "invis"
              }`}
              src={navyBin}
              alt="Delete"
              onClick={(e) => {
                e.stopPropagation();
                if (!item.removed) {
                  memoVals.setSureRemoveAbsenceType((x) => item.absenceTypeID);
                }
              }}
            />
          </div>
        </div>
      );
    });

  let generateRemovedTypes = absenceTypes
    ?.filter((x) => {
      return x.removed;
    })
    ?.map((item) => {
      return (
        <div key={item._id}>
          <div
            className="specialDayUnit customLeaveTypeUnit zoomInSlow"
            onClick={() => {
              memoVals.setShowAbsenceTypeModal((x) => {
                return {
                  show: true,
                  absenceTypeID: item.absenceTypeID,
                  rate: item.rate,
                  name: item.name,
                  removed: item.removed,
                  removable: item.removable,
                };
              });
            }}
          >
            <div className="customTypeLeft">
              <span onClick={() => {}}>
                <p
                  className={`specialDayDate ${
                    shortenLocation(item.name)?.length > 18 ? "x283819123" : ""
                  }`}
                >
                  {shortenLocation(item.name)}
                </p>
              </span>
              <p className="customLeaveTypeSubTxt">
                {item.paid ? `Paid | ${item.rate}x` : "Unpaid"}
              </p>
            </div>
            <p className="removedTypeTxt">Archived</p>
          </div>
        </div>
      );
    });

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-absence-admin-settings`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          // console.log(response.data);
          memoVals.setAllowSelfLogAbsence(
            (x) => response.data.absenceAllowSelfLog
          );
          memoVals.setEnableAbsenceRanking(
            (x) => response.data.absenceEnableRank
          );
          memoVals.setRtwFormEnabled((x) => response.data.rtwForm);
          memoVals.setRtwQuestions((x) => response.data.rtwQuestions);
          memoVals.setAbsenceTypes((x) => response.data.absenceTypes);
          setSelfLogPaid(response.data.selfLogPaid);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let generateQuestions = memoVals.rtwQuestions?.map((item, index) => {
    return (
      <div className="rtwQUnit" key={index}>
        <p className="rtwQtxt">{item.question}</p>
        <img
          src={navyBin}
          alt="Remove question"
          className="removeQuestionImg"
          onClick={() => {
            axios
              .post(
                `${serverURL}/delete-rtw-q`,
                { questionID: item.questionID },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message.includes("logout")) {
                  window.location.href = "/signin";
                }
                if (response.data.message === "success") {
                  let newArr = [];
                  memoVals.rtwQuestions?.forEach((q) => {
                    if (q.questionID !== response.data.questionID) {
                      newArr.push(q);
                    }
                  });
                  memoVals.setRtwQuestions((x) => newArr);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        />
      </div>
    );
  });

  return (
    <div
      className={`AdminSettingsContainer ${
        memoVals.mob
          ? `AdminSettingsContainerMob ${
              memoVals.device.ios ? "AdminSettingsContainerMobIos" : ""
            }`
          : "borderRightGrey"
      }`}
    >
      {/* <div className='whiteGradientAdmin'></div> */}

      {loading ? (
        <img
          src={horizontalBalls}
          alt="Loading"
          className="teamSchedLoadingBallsImg"
        />
      ) : (
        <div className="AdminInner">
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow clickShiftRowAdmin ${
              memoVals.mob ? "clickShiftRowAdminMob mobileAdminRow" : ""
            }`}
            onClick={() => {
              axios
                .post(
                  `${serverURL}/toggle-allow-self-log-absence`,
                  { toggleAllowSelfLogAbsence: !memoVals.allowSelfLogAbsence },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message.includes("logout")) {
                    window.location.href = "/signin";
                  }
                  if (response.data.message === "success") {
                    memoVals.setAllowSelfLogAbsence(
                      (x) => response.data.toggleAllowSelfLogAbsence
                    );
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">
                  Allow staff to log their own absence
                </p>
                <p className="absenceRowSubText overtimeRowSubText">
                  If an employee is going to be absent for their upcoming
                  scheduled shift, can they mark themselves as absent? If
                  disabled, only their managers can mark them as absent.
                </p>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                <div className="knobContainer knobContainerSettings   ">
                  <div
                    className={`switchKnob ${
                      memoVals.allowSelfLogAbsence ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}

          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`gpsAdminRow ${memoVals.mob ? "gpsAdminRowMob" : ""}`}
          >
            <div className={`settingsRow`}>
              <div className="rowWithSubTextGPS">
                <p className="absenceRowMainText">Absence types</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Add different types of absence with different rates of pay.
                </p>
              </div>{" "}
            </div>
            <div
              className={`${
                absenceTypes?.length > 0 ? "gpsList paddingBottom0" : "none"
              }`}
            >
              <div className="specialDayUnitsContainer">
                {generateTypes}
                {showRemoved ? (
                  <div className="showRemovedTypesDivider"></div>
                ) : (
                  ""
                )}
                {showRemoved ? generateRemovedTypes : ""}
              </div>
            </div>{" "}
            <div className={`addZoneParent`}>
              <p
                className="addGPSBtn addCustomBtn blueColours"
                onClick={() => {
                  memoVals.setShowAbsenceTypeModal((x) => {
                    return {
                      new: true,
                      show: true,
                      name: "New absence type",
                    };
                  });
                }}
              >
                Add absence type{" "}
                <img
                  src={whiteAddThin}
                  alt="Add zone"
                  className="addZonePlus addTypePlusImg"
                />
              </p>
            </div>
            {absenceTypes?.filter((x) => {
              return x.removed;
            })?.length > 0 ? (
              <p
                className="showRemovedTypes"
                onClick={() => {
                  setShowRemoved(!showRemoved);
                }}
              >
                {showRemoved ? "Hide" : "Show"} archived types
              </p>
            ) : (
              ""
            )}
          </div>

          {/* ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow ${
              !memoVals.rtwFormEnabled ? "clickShiftRowAdmin" : ""
            } ${memoVals.mob ? "clickShiftRowAdminMob mobileAdminRow" : ""}`}
            onClick={(e) => {
              if (!memoVals.rtwFormEnabled) {
                axios
                  .post(
                    `${serverURL}/toggle-rtw-form`,
                    { rtwFormEnabled: !memoVals.rtwFormEnabled },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.status === 200) {
                      memoVals.setRtwFormEnabled(
                        (x) => response.data.rtwFormEnabled
                      );
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">Return-to-work form</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Allow managers to create return-to-work forms for employees to
                  fill in upon returning to work after a period of absence.
                </p>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings   "
                  onClick={() => {
                    if (memoVals.rtwFormEnabled) {
                      axios
                        .post(
                          `${serverURL}/toggle-rtw-form`,
                          { rtwFormEnabled: !memoVals.rtwFormEnabled },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.status === 200) {
                            memoVals.setRtwFormEnabled(
                              (x) => response.data.rtwFormEnabled
                            );
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  <div
                    className={`switchKnob ${
                      memoVals.rtwFormEnabled ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>

            {memoVals.rtwFormEnabled ? (
              <div className="questionsWrapper">
                {/* <p className="questionsTitle">Questions</p> */}
                {generateQuestions}
                {memoVals.showAddRtwModal ? (
                  <div className="addQdiv">
                    <textarea
                      className="addRtwInput"
                      placeholder="Type question here"
                      value={memoVals.question}
                      onChange={(e) => {
                        let val = e.target.value;
                        if (val && val?.length < 401) {
                          memoVals.setQuestion((x) => val);
                        }
                      }}
                    ></textarea>
                    {memoVals.question ? (
                      <p
                        className="saveGpsZoneBtn addQBtn"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/add-rtw-q`,
                              { question: memoVals.question },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.status === 200) {
                                axios
                                  .post(
                                    `${serverURL}/get-absence-admin-settings`,
                                    { someData: 1 },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data) {
                                      memoVals.setRtwQuestions(
                                        (x) => response.data.rtwQuestions
                                      );
                                    }
                                  })
                                  .then(() => {
                                    memoVals.setShowAddRtwModal((x) => false);
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        Save
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                {memoVals.rtwQuestions?.length < 30 &&
                !memoVals.showAddRtwModal ? (
                  <p
                    className="addGPSBtn addQuestionBtn blueColours"
                    onClick={() => {
                      memoVals.setQuestion((x) => "");
                      memoVals.setShowAddRtwModal((x) => true);
                    }}
                  >
                    Add question{" "}
                    <img
                      src={whiteAddThin}
                      alt="Add zone"
                      className="addZonePlus addTypePlusImg"
                    />
                  </p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}

          <br />
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default Absence2;
