// use node v22.9.0 --  as of 24/2/2025
// use node 17.5.0 -- works as of 9th aug 2022
// use node 16.14.0 -- works as of 25 Dec 2023

import React, { useContext, useEffect, useState } from "react";
import {
  Route,
  BrowserRouter,
  Switch,
  useHistory,
  HashRouter,
} from "react-router-dom";
import { Redirect } from "react-router";

import * as serviceWorker from "./serviceWorker"; // Import service worker module

// local dev must run on SSL:
// https://www.youtube.com/watch?v=neT7fmZ6sDE
// in the .env file, there is the perm and key vars for the SSL certificate

import axios from "axios";

import serverURL from "./serverURL";
import appURL from "./appURL";

// import $ from 'jquery';
import "./App.css";
import "./styles/MleaveReqUpper.css";
// import "./styles/darkmode.css";

// Context providers
import DataContextProvider from "./contexts/DataContext";
import AdminContextProvider from "./contexts/AdminContext";
import CalendarContextProvider from "./contexts/CalendarContext";
import UserContextProvider from "./contexts/UserContext";
import StyleContextProvider from "./contexts/StyleContext";
import RequestContextProvider from "./contexts/RequestContext";
import TowerContextProvider from "./contexts/TowerContext";

import adminBurgerMob from "./img/general/adminBurgerMob.svg";

import Signin from "./components/signin/Signin";
import Forgot from "./components/signin/Forgot";
import Signup from "./components/signin/Signup";

import LeaveReqsPage from "./components/requests/LeaveReqsPage";

import "./styles/style.css";
import Welcome from "./components/account/Welcome";
import AccountSettings from "./components/account/AccountSettings";
import ClockTerminal from "./components/account/ClockTerminal";

import Reports from "./components/calendar/Reports";
import Admin from "./components/account/Admin";
import Manage from "./components/account/Manage";
import Menu from "./components/Menu";
import Offline from "./components/Offline";

import Calendar from "./components/calendar/Calendar";
import StaffCalendar from "./components/calendar/StaffCalendar";

import Absence from "./components/calendar/Absence";
import StaffAbsence from "./components/calendar/StaffAbsence";
import Schedule from "./components/calendar/Schedule";
import InactiveRotaLink from "./components/calendar/InactiveRotaLink";

import Shifts from "./components/calendar/shifts/Shifts";
import RTWForm from "./components/calendar/RTWForm";
import ResetPassword from "./components/ResetPassword";
import ContactForm from "./components/ContactForm";
import Dashboard from "./components/dashboard/Dashboard";

import FourOhFour from "./components/FourOhFour";
import SignUpForm from "./components/signin/SignUpForm";

function App() {
  useEffect(() => {
    if (window.location.href && window.location.href.includes("fr_")) {
      let urlString = JSON.stringify(window.location.href);
      function extractAfterFr(inputString) {
        if (typeof inputString === "string") {
          const frIndex = inputString.indexOf("fr_");

          if (frIndex !== -1) {
            // Extract characters after "fr_"
            const result = inputString.substring(frIndex + 3);
            return result;
          } else {
            // "fr_" not found, return original string or handle as needed
            return inputString;
          }
        }

        // Return an empty string or handle the case when input is not a string
        return "";
      }

      // Example usage
      const inputString = window.location.href;
      const output = extractAfterFr(inputString);
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-rota-link`,
          { shortenedLink: output },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            window.location.href = `${appURL}/${response.data.fullLink}`;
          } else {
            window.location.href = `${appURL}/inactive-rota-link`;
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, []);

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  useEffect(() => {
    // if (!window.location.href.includes("app.flow") ) {
    //   serviceWorker.unregister();
    //   console.log("Unregistered service worker as not in production");
    // }
    if (process.env.NODE_ENV === "production") {
      // console.log("REGISTERING SERVICE WORKER");
      // Register service worker
      serviceWorker.register();

      // Clean-up function (unregister service worker)
      return () => {
        serviceWorker.unregister();
      };
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  // if ("serviceWorker" in navigator) {
  //   window.addEventListener("load", function () {
  //     navigator.serviceWorker.register("/progressier.js");
  //   });
  // }
  useEffect(() => {
    // cleaned
    function handleResize() {
      setViewportHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const preventGesture = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchstart", preventGesture, { passive: false });
    document.addEventListener("touchmove", preventGesture, { passive: false });

    return () => {
      document.removeEventListener("touchstart", preventGesture);
      document.removeEventListener("touchmove", preventGesture);
    };
  }, []);

  useEffect(() => {
    // clean not needed
    if (screen.orientation && screen.orientation.lock) {
      // Lock the screen orientation to "portrait-primary"
      screen.orientation
        .lock("portrait-primary")
        .then(function () {
          console.log("Screen orientation locked to portrait-primary.");
        })
        .catch(function (error) {
          console.error("Failed to lock screen orientation: ", error);
        });
    }
  }, []);
  useEffect(() => {
    // Your code here

    const scrollContainer = document.getElementById("appContainer");
    let lastY = 0;

    const handleTouchStart = function (event) {
      lastY = event.touches[0].clientY;
    };

    const handleTouchMove = function (event) {
      const top = event.touches[0].clientY;

      const scrollTop = event.currentTarget.scrollTop;
      const direction = lastY - top < 0 ? "up" : "down";

      if (window.fixScroll && scrollTop === 0 && direction === "up") {
        event.preventDefault();
      } else if (
        window.fixScroll &&
        scrollTop >=
          event.currentTarget.scrollHeight - event.target.offsetHeight &&
        direction === "down"
      ) {
        event.preventDefault();
      }

      lastY = top;
    };

    scrollContainer.addEventListener("touchstart", handleTouchStart);
    scrollContainer.addEventListener("touchmove", handleTouchMove);

    // Clean up the event listeners when the component unmounts
    return () => {
      scrollContainer.removeEventListener("touchstart", handleTouchStart);
      scrollContainer.removeEventListener("touchmove", handleTouchMove);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const [isPageVisible, setPageVisible] = useState(true);

  // useEffect(() => {
  //   if (window.innerWidth < 820) {
  //     const handleVisibilityChange = () => {
  //       setPageVisible(!document.hidden);
  //     };

  //     document.addEventListener("visibilitychange", handleVisibilityChange);

  //     return () => {
  //       document.removeEventListener(
  //         "visibilitychange",
  //         handleVisibilityChange
  //       );
  //     };
  //   }
  // }, []);

  // if (isPageVisible) {
  return (
    <BrowserRouter>
      <div
        className="App"
        id="appContainer"
        style={{ minHeight: viewportHeight, maxHeight: viewportHeight }}
      >
        <div className="wrapper">
          <img src={adminBurgerMob} alt=" " className="x92929948211" />
          <UserContextProvider>
            <AdminContextProvider>
              <DataContextProvider>
                <StyleContextProvider>
                  <RequestContextProvider>
                    <CalendarContextProvider>
                      <TowerContextProvider>
                        {/* {!serverURL.includes("flowrota.app")
                          ? preload
                          : preload} */}

                        {/* Navbar routes */}
                        <Switch>
                          {/* Todo: If user not logged in, direct to signin page */}
                          <Route path="/offline" component={Offline} />
                          <Route exact path="/" component={Calendar} />
                          <Route path="/signin" component={Signin} />
                          <Route path="/signin/contact" component={Signin} />
                          <Route path="/forgot" component={Forgot} />
                          <Route path="/signup" component={SignUpForm} />
                          <Route
                            path="/reset-password"
                            component={ResetPassword}
                          />
                          <Route path="/welcome" component={Welcome} />
                          <Route path="/menu" component={Menu} />
                          <Route path="/leave" component={LeaveReqsPage} />
                          <Route path="/reports" component={Reports} />
                          <Route path="/terminal" component={ClockTerminal} />
                          <Route
                            path="/clock-terminal"
                            component={ClockTerminal}
                          />
                          <Route path="/clock" component={ClockTerminal} />
                          <Route
                            path="/team-leave"
                            component={() => <LeaveReqsPage isTeam={true} />}
                          />
                          <Route
                            path="/contact"
                            component={() => <ContactForm />}
                          />
                          {/* <Route path='/requests-past' component={PastLeaveReqsPage} />
              <Route path='/manage-requests' component={MLeaveReqsPage} /> */}
                          {/* <Route path='/requests-declined' component={DecLeaveReqsPage} /> */}
                          {/* <Route path='/team-allowances' component={TeamAllowanceViews} /> */}
                          {/* <Route path="/account" component={Inputter} /> */}
                          <Route
                            path="/account/my-allowances"
                            component={() => (
                              <AccountSettings allowances={true} />
                            )}
                          />
                          <Route path="/account" component={AccountSettings} />
                          <Route
                            path="/confirm-email"
                            component={AccountSettings}
                          />
                          <Route
                            path="/whatsapp-messenger"
                            component={() => <AccountSettings contact={true} />}
                          />
                          <Route
                            path="/notifications"
                            component={() => (
                              <AccountSettings notifications={true} />
                            )}
                          />
                          <Route
                            path="/admin/employees"
                            component={() => <Admin showUsers={true} />}
                          />{" "}
                          <Route
                            path="/inactive-rota-link"
                            component={() => <InactiveRotaLink />}
                          />{" "}
                          <Route
                            path="/admin/leave-settings"
                            component={() => <Admin showLeaveTypes={true} />}
                          />{" "}
                          <Route
                            path="/admin/overtime"
                            component={() => <Admin showOvertime={true} />}
                          />{" "}
                          <Route
                            path="/admin/teams"
                            component={() => <Admin showTeams={true} />}
                          />
                          <Route
                            path="/admin/locations"
                            component={() => <Admin showLocations={true} />}
                          />
                          <Route
                            path="/admin/administrators"
                            component={() => <Admin showAdmins={true} />}
                          />{" "}
                          <Route
                            path="/admin/permissions"
                            component={() => <Admin showPermissions={true} />}
                          />
                          <Route
                            path="/admin/billing"
                            component={() => <Admin showBilling={true} />}
                          />
                          <Route
                            path="/admin/day-rules"
                            component={() => <Admin showClosedDays={true} />}
                          />
                          <Route
                            path="/admin/absence"
                            component={() => <Admin showAbsence={true} />}
                          />{" "}
                          <Route path="/admin" component={Admin} />
                          <Route
                            path="/staff-dashboard/allowances"
                            component={() => <Dashboard allowances={true} />}
                          />
                          <Route
                            path="/staff-dashboard/documents"
                            component={() => <Dashboard docs={true} />}
                          />
                          <Route
                            path="/staff-dashboard/toil"
                            component={() => <Dashboard toil={true} />}
                          />
                          <Route
                            path="/staff-dashboard/employees"
                            component={() => <Dashboard employees={true} />}
                          />
                          <Route
                            path="/staff-dashboard"
                            component={() => <Dashboard dashboard={true} />}
                          />
                          <Route path="/manage" component={Manage} />
                          <Route
                            path="/calendar/limit-checker"
                            component={() => (
                              <Calendar showLimitChecker={true} />
                            )}
                          />
                          <Route
                            path="/calendar/leave-chart"
                            component={() => (
                              <Calendar showViewColleague={true} />
                            )}
                          />
                          <Route path="/calendar" component={Calendar} />
                          <Route path="/messages" component={Calendar} />
                          {/* <Route
                            path="/staff-calendar"
                            component={StaffCalendar}
                          /> */}
                          <Route
                            path="/staff-calendar"
                            component={() => <Schedule isStaffCal={true} />}
                          />
                          <Route
                            path="/messages"
                            component={() => <Calendar messages={true} />}
                          />
                          <Route
                            path="/team-calendar"
                            component={() => <Calendar teamCal={true} />}
                          />
                          <Route path="/rota" component={Schedule} />
                          <Route path="/view-rota" component={Schedule} />
                          <Route
                            path="/team-rota"
                            component={() => <Schedule isTeam={true} />}
                          />
                          <Route path="/absence" component={Absence} />
                          <Route path="/absence/forms" component={Absence} />
                          <Route
                            path="/staff-absence"
                            component={() => <StaffAbsence />}
                          />
                          <Route
                            path="/staff-absence/forms"
                            component={() => <StaffAbsence />}
                          />
                          <Route
                            path="/clock-ons"
                            component={() => <Shifts isClockOns={true} />}
                          />
                          <Route
                            path="/team-clock-ons"
                            component={() => (
                              <Shifts isClockOns={true} isTeam={true} />
                            )}
                          />
                          <Route
                            path="/timesheets"
                            component={() => <Shifts isTimesheets={true} />}
                          />
                          <Route
                            path="/team-timesheets"
                            component={() => <Shifts isTeamTimesheets={true} />}
                          />
                          <Route path="/hours" component={Shifts} />
                          <Route path="/shifts" component={Shifts} />
                          <Route
                            path="/swaps"
                            component={() => <Shifts isSwaps={true} />}
                          />
                          <Route
                            path="/team-hours"
                            component={() => <Shifts isTeam={true} />}
                          />
                          <Route path="/absence-form" component={RTWForm} />
                          {/* Tower routes */}
                          {window.location.href.includes("fr_") ? (
                            ""
                          ) : (
                            <Route component={FourOhFour} />
                          )}
                          {/* <Route path='/new-leave-request' component={MakeLeaveReq} /> */}
                        </Switch>
                        <div className="userSelect"></div>
                      </TowerContextProvider>
                    </CalendarContextProvider>
                  </RequestContextProvider>
                </StyleContextProvider>
              </DataContextProvider>
            </AdminContextProvider>
          </UserContextProvider>
        </div>
      </div>
    </BrowserRouter>
  );
  // } else {
  //   return "";
  // }
}

export default App;
