// design and maps fonr 20 mar 25

import React, {
  useEffect,
  useState,
  useContext,
  useReducer,
  useMemo,
  useRef,
} from "react";
// clean not needed
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 8 sep 24
import axios from "axios";

import { DataContext } from "../../../contexts/DataContext";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";

import serverURL from "../../../serverURL";
import { StyleContext } from "../../../contexts/StyleContext";
import dateStringer from "../../../tools/dateStringer";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import navyNote from "../../../img/general/navyNote.svg";

import close from "../../../img/modals/close.svg";
import whitecross from "../../../img/general/whitecross.svg";
import bid from "../../../img/general/bid.svg";
import bin1 from "../../../img/general/bin1.svg";
import comment from "../../../img/general/whiteComment.svg";

const UserOpenShiftModal = ({ unitType, unitID, otherUser }) => {
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [changed, setChanged] = useState(false);
  let [location, setLocation] = useState("");
  let [jt, setJt] = useState("");
  useEffect(() => {
    if (dataLoaded && imagesLoaded === 6) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyNote}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={bid}
        alt="Bid"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={bin1}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={comment}
        alt="Comment"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      type: "",
      openID: "",
      startDs: "",
      endDs: "",
      break: 0,
      rate: 1,
      durationMins: "",
      teamID: "",
      teamName: "",
      tags: [],
      publicNote: [],
      bidders: [],
      userHasActiveBid: false,
      acceptFirstUser: false,
    }
  );

  const { setUpdatedItem } = useContext(CalendarContext);
  const { checkUserState } = useContext(UserContext);
  const { mobModal } = useContext(StyleContext);
  const {
    showUserOpenShiftModal,
    setShowUserOpenShiftModal,
    setUpdatedOpenShift,
    setIndicate,
    setUpdateCalAvail,
    updateCalAvail,
    updateOpensModal,
    setUpdateOpensModal,
    modalOpen,
    setModalOpen,
    device,
    countData,
    setCountData,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      showUserOpenShiftModal,
      setShowUserOpenShiftModal,
      setUpdatedOpenShift,
      setIndicate,
      setUpdateCalAvail,
      updateCalAvail,
      checkUserState,
      setUpdatedItem,
      mobModal,
      updateOpensModal,
      setUpdateOpensModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      showUserOpenShiftModal, //
      setShowUserOpenShiftModal, //
      setUpdatedOpenShift, //
      setIndicate, //
      setUpdateCalAvail, //
      updateCalAvail, //
      checkUserState, //
      setUpdatedItem, //
      mobModal, //
      updateOpensModal,
      setUpdateOpensModal,
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [showBigSuccessModal, setShowBidSuccessModal] = useState(false);

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      closeModal();
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let today00 = new Date();
  today00.setHours(0, 0, 0, 0);
  let [shiftDoesNotExist, setShiftDoesNotExist] = useState(false);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-single-open-data`,
        { openID: memoVals.showUserOpenShiftModal, nonMgr: true },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (
          response.data.message &&
          response.data.message.includes("available")
        ) {
          setDataLoaded(true);
          setShiftDoesNotExist(true);
        }
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          setLocation(response.data.location);
          setJt(response.data.jt);
          setDataLoaded(true);
          setState({
            type: response.data.type,
            openID: memoVals.showUserOpenShiftModal,
            startDs: response.data.start,
            endDs: response.data.end,
            break: response.data.break,
            rate: response.data.rate,
            durationMins: response.data.durationMins,
            teamID: response.data.teamID,
            teamName: response.data.teamName,
            tags: response.data.tags,
            publicNote: response.data.publicNote,
            bidders: response.data.bidUserIDs,
            userHasActiveBid: response.data.bidded,
            acceptFirstUser: response.data.acceptFirstUser,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [showNotes, setShowNotes] = useState(false);
  let [toggleAddNote, setToggleAddNote] = useState(false);
  let [shiftAddNoteValue, setShiftAddNoteValue] = useState("");

  let notesBox = useRef();
  useEffect(() => {
    if (showNotes || toggleAddNote) {
      setTimeout(() => {
        if (notesBox && notesBox.current) {
          notesBox.current.scrollTo({
            top: notesBox.current.scrollHeight, // Scroll to the bottom of the div
            behavior: "smooth", // Smooth scroll behavior
          });
        }
      }, 200);
    }
  }, [showNotes, toggleAddNote, state.publicNote]);

  let addNoteTextArea = useRef();
  useEffect(() => {
    // clean not needed
    if (addNoteTextArea.current) {
      addNoteTextArea.current.focus();
    }
  }, [toggleAddNote]);
  let generateTags = React.useMemo(() => {
    return state.tags.map((tag, i) => {
      return (
        <p className="itemTag" key={i}>
          {tag}
        </p>
      );
    });
  }, [state]);

  let generateClaimants = state.bidders.map((claim, i) => {
    return (
      <div className="userClaimantUser" key={claim.ts} key={i}>
        <div className="claimantTxtLeft minW100">
          <div className="claimUserTopDiv">
            {claim.userID === memoVals.checkUserState.userID ? (
              <p className="claimantName">You</p>
            ) : (
              <p className="claimantName">
                {claim.fName} {claim.lName[0]}{" "}
              </p>
            )}
            {memoVals.checkUserState.userID === claim.userID ? (
              <p
                className="cancelClaim"
                onClick={() => {
                  setSureCancelBid(true);
                }}
              >
                Cancel
              </p>
            ) : (
              <p></p>
            )}
          </div>

          <p className="claimantTsSpan">
            Bidded{" "}
            {dateStringer.printedDateWithTimeFromDs(
              dateStringer.createStringFromTimestamp(claim.ts)
            )}
          </p>
        </div>
      </div>
    );
  });

  let generateNotes =
    state.publicNote && state.publicNote[0]
      ? state.publicNote
          .sort((a, b) => {
            return a.ts - b.ts;
          })
          .map((note, i) => {
            let nameVal = `${note.fName} ${note.lName}`;
            return (
              <div className="shiftNotesObj" key={i}>
                <div className="noteObjNameAndDs">
                  <p className="noteObjName">
                    {nameVal && nameVal.length > 14
                      ? `${note.fName} ${
                          note.lName && note.lName[0] ? note.lName[0] : ""
                        }`
                      : nameVal}
                  </p>
                  <p className="noteObjFsDs">
                    {dateStringer.printedDateWithTimeFromDs(note.ds)}
                  </p>
                </div>
                <p className="noteObjNote">{note.note}</p>
                {note.userID === memoVals.checkUserState.userID ? (
                  <div className="deleteNoteRow deleteNoteRowOpen">
                    {otherUser ? (
                      ""
                    ) : (
                      <img
                        src={bin1}
                        alt="Remove note"
                        className="binNoteImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/add-note-to-shift`,
                              {
                                type: state.type,
                                shiftID: memoVals.showUserOpenShiftModal,
                                open: true,
                                noteIDifRemoving: note.noteID,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message.includes("logout")) {
                                window.location.href = "/signin";
                              }
                              if (response.data.message === "success") {
                                setToggleAddNote(false);
                                setShiftAddNoteValue("");

                                response.data.publicNote.sort(
                                  (a, b) =>
                                    dateStringer.createTimestampFromString(
                                      b.ds
                                    ) -
                                    dateStringer.createTimestampFromString(a.ds)
                                );

                                setState({
                                  publicNote: response.data.publicNote,
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })
      : "";

  let shortenTeamName = (name) => {
    if (name.length > 20) {
      return `${name.substr(0, 18)}...`;
    } else {
      return name;
    }
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      memoVals.setShowUserOpenShiftModal((x) => "");
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let closeModal = () => {
    memoVals.setShowUserOpenShiftModal((x) => "");
    if (changed) {
      memoVals.setUpdateOpensModal(!memoVals.updateOpensModal);
    }
  };

  let enterFct = (e) => {
    if (e.key === "Enter" || e === "add") {
      if (shiftAddNoteValue) {
        axios
          .post(
            `${serverURL}/add-note-to-shift`,
            {
              type: state.type,
              shiftID: memoVals.showUserOpenShiftModal,
              ds: dateStringer.createStringFromTimestamp(new Date().getTime()),
              note: shiftAddNoteValue,
              open: true,
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message.includes("logout")) {
              window.location.href = "/signin";
            }
            if (response.data.message === "success") {
              setToggleAddNote(false);
              setShiftAddNoteValue("");

              response.data.publicNote.sort(
                (a, b) =>
                  dateStringer.createTimestampFromString(b.ds) -
                  dateStringer.createTimestampFromString(a.ds)
              );

              setState({
                publicNote: response.data.publicNote,
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  let [sureCancelBid, setSureCancelBid] = useState(false);

  // master return
  return (
    <div
      className="myShiftModalUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`myShiftModalBox ${
            memoVals.mobModal
              ? "mobMyShiftModalBox mobModalShoulder slideUp"
              : "zoomIn desktopUserOpenModalBox"
          } ${memoVals.device.ios ? "mobMyShiftModalBoxIos" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperUserOpenShift"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="myShiftHeaderParent"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <div className="openShiftHeaderLeft">
              <div
                className="myShiftModalHeaderContainer myShiftModalHeaderContainerOpen"
                onClick={() => {
                  if (memoVals.mobModal) {
                    // memoVals.setShowUserOpenShiftModal((x) => "");
                    closeModal();
                  }
                }}
              >
                <div
                  className="myShiftCloseHolder"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <img
                    src={close}
                    alt="Close"
                    className="closeMyShiftModalImg"
                  />
                </div>
                <p className="openShiftModalTitle">
                  Open{" "}
                  {state.type === "shift"
                    ? "shift"
                    : state.type === "til"
                    ? "overtime (time in lieu)"
                    : "overtime"}
                  {state.bidders.length > 0 ? (
                    <img src={bid} alt="Bidders" className="bidQtyUserIcon" />
                  ) : (
                    ""
                  )}
                  {state.bidders.length > 0 ? (
                    <span className="bidUsrQtySpan">
                      x{state.bidders.length}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
                {/* <div className="myShiftModalHeaderRight">&nbsp;</div> */}
              </div>
              <div className="shiftBubblesBar">
                <div className="shiftTeamSubBubble x4895469865">
                  {shortenTeamName(state.teamName)}
                </div>
                <p className="shiftCostBubble salariedCost">
                  {dateStringer.formatMinsDurationToHours(state.durationMins)}
                </p>
              </div>
            </div>
            {/* <div className="myShiftHeaderRight"></div> */}
          </div>
          {/* modal body */}
          <div
            className={`myShiftModalBody ${
              memoVals.mobModal
                ? "mobMyShiftModalBody mobMyShiftModalBodyOpen"
                : "myOpenShDesktop"
            }`}
            ref={notesBox}
          >
            {showNotes ? (
              <div>
                <div className="shiftNotesTitleAndAdd myShiftTitleAndAdd">
                  <p className="shiftNotesTitleTxt">Notes</p>
                  <p></p>
                </div>

                <div className="notesHolder">
                  {state.publicNote && state.publicNote[0] ? (
                    generateNotes
                  ) : (
                    <p className="noShiftNotes">No notes yet</p>
                  )}
                  {!toggleAddNote ? (
                    <p
                      className="addMyShNote"
                      onClick={() => {
                        setToggleAddNote(true);
                      }}
                    >
                      Add note{" "}
                      <img
                        src={navyNote}
                        alt="Note"
                        className="userOpenAddNoteImg"
                      />
                    </p>
                  ) : (
                    ""
                  )}
                  {toggleAddNote ? (
                    <div className="shiftAddNoteDiv shiftAddNoteDivOpen">
                      <textarea
                        ref={addNoteTextArea}
                        className="shiftAddNoteTextArea"
                        value={shiftAddNoteValue}
                        onChange={(e) => {
                          setShiftAddNoteValue(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          enterFct(e);
                        }}
                      ></textarea>
                      <div className="shiftAddNoteRightBtns">
                        <p
                          className={`shiftAddNoteSaveBtn ${
                            !shiftAddNoteValue ? "disableAddNoteBtn" : ""
                          }`}
                          onClick={() => {
                            enterFct("add");
                          }}
                        >
                          {/* shiftID, type, ds, noteIDifRemoving, note */}
                          Add
                        </p>
                        <p
                          className="shiftAddNoteSaveBtnCanx none"
                          onClick={() => {
                            setToggleAddNote(false);
                          }}
                        >
                          Cancel
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <div className="">
                {state.userHasActiveBid && (
                  <p className="claimedStatus">
                    You have bid for this open shift
                  </p>
                )}
                {state.userHasActiveBid && state.bidders && (
                  <p className="claimedStatus">
                    {state.bidders.length - 1 === 0
                      ? "No other bids yet"
                      : `${state.bidders.length} other bid${
                          state.bidders.length === 1 ? "" : "s"
                        }`}
                  </p>
                )}
                <div className="myShiftRow">
                  <div className="myShiftRowLeft">
                    <p className="myShiftRowLeftTitle">When</p>{" "}
                    <p className="myShiftWhenDsStrip">
                      {dateStringer.printedDateFromDs(state.startDs)}&nbsp;
                      {state.startDs.substr(1, 4)}
                    </p>
                  </div>
                  <div className="myShiftRowRight"></div>
                </div>

                {/* new row */}
                <div className="myShiftRow">
                  <div className="myShiftRowLeft">
                    <p className="myShiftRowLeftTitle">Times</p>
                    {dateStringer.dsToTimeStrip(state.startDs)} -{" "}
                    {dateStringer.dsToTimeStrip(state.endDs)}{" "}
                  </div>
                  <div className="myShiftRowRight"></div>
                </div>

                {/* end of row */}
                {/* new row */}
                <div className="myShiftRow">
                  <div className="myShiftRowLeft">
                    <p className="myShiftRowLeftTitle">Job role</p>
                    {jt || "Any job title"}
                  </div>
                  <div className="myShiftRowRight"></div>
                </div>

                {/* end of row */}
                {/* new row */}
                <div className="myShiftRow">
                  <div className="myShiftRowLeft">
                    <p className="myShiftRowLeftTitle">Location</p>
                    {location || "No location set"}
                  </div>
                  <div className="myShiftRowRight"></div>
                </div>

                {/* end of row */}
                {/* new row */}
                <div className="myShiftRow">
                  <div className="myShiftRowLeft">
                    <p className="myShiftRowLeftTitle">Duration</p>
                    {dateStringer.formatMinsDurationToHours(
                      state.durationMins
                    )}{" "}
                    <span className="myShiftBreakSpan">
                      / {dateStringer.formatMinsDurationToHours(state.break)}{" "}
                      {state.type === "til"
                        ? "excluded for break"
                        : "unpaid break"}
                    </span>
                  </div>
                  <div className="myShiftRowRight"></div>
                </div>

                {/* end of row */}
                {/* new row */}
                <div className="myShiftRow">
                  <div className="myShiftRowLeft">
                    <p className="myShiftRowLeftTitle">Rate of pay</p>
                    {state.rate || 0}x{" "}
                  </div>
                  <div className="myShiftRowRight"></div>
                </div>

                {/* end of row */}
                {/* new row */}
                <div
                  className={`${state.tags.length > 0 ? "myShiftRow" : "none"}`}
                >
                  <div className="myShiftRowLeft">
                    <p className="myShiftRowLeftTitle">Tags</p>
                    <div className="myShiftTagsContainer">{generateTags}</div>
                  </div>
                  <div className="myShiftRowRight"></div>
                </div>
                {/* end of row */}

                {/* new row */}
                <div className="myShiftRow">
                  <div className="myShiftRowLeft">
                    {(state.acceptFirstUser && state.bidders.length === 0) ||
                    state.bidders.length === 0 ? (
                      ""
                    ) : (
                      <p className="myShiftRowLeftTitle">Bidders</p>
                    )}
                    {!state.acceptFirstUser ? (
                      <p className="userOpenAcceptFirstTxt">
                        This open{" "}
                        {state.type === "til"
                          ? "overtime (time in lieu)"
                          : state.type}{" "}
                        will be assigned to a bidder by a team manager
                      </p>
                    ) : (
                      ""
                    )}
                    {state.acceptFirstUser && state.bidders.length === 0 ? (
                      <p className="userOpenAcceptFirstTxt">
                        This open{" "}
                        {state.type === "til"
                          ? "overtime (time in lieu)"
                          : state.type}{" "}
                        will be given to the first person who bids for it
                      </p>
                    ) : state.bidders.length > 0 ? (
                      <div className="userClaimantsDiv">
                        {generateClaimants}
                      </div>
                    ) : (
                      <p className="noClaimsTxt">No bids yet</p>
                    )}
                  </div>
                  <div className="myShiftRowRight"></div>
                </div>
                {/* end of row */}
                {/* new row */}
              </div>
            )}

            {/* end of body history */}
          </div>
          {/* end of modal body middle bit */}
          {/* modal footer */}
          <div
            className={`myShiftModalFooter ${
              memoVals.mobModal ? "mobMyShiftModalFooter" : ""
            }`}
          >
            <div className="openShiftFooterNotesAndClaimDiv">
              <div
                className={`${
                  showNotes
                    ? `requestShiftHistoryBtnContainerActive`
                    : `requestShiftHistoryBtnContainer`
                } `}
                onClick={() => {
                  if (!showNotes) {
                  }

                  setShowNotes(!showNotes);
                }}
              >
                <img
                  src={whitecross}
                  className={`${
                    showNotes ? "closeHistoryShiftImgCross" : "none"
                  }`}
                  alt="Close"
                />
                <div
                  className={`${
                    showNotes
                      ? "requestShiftHistoryBtnTxtActive"
                      : `requestShiftHistoryBtnTxtInactive ${
                          state.publicNote && state.publicNote.length > 0
                            ? "blueColours"
                            : ""
                        }`
                  }`}
                  onClick={() => {}}
                >
                  {" "}
                  {showNotes ? (
                    ""
                  ) : (
                    <img
                      src={comment}
                      alt="Notes"
                      className="myShiftNoteCommentIcon"
                    />
                  )}
                  Notes (
                  {state.publicNote && state.publicNote[0]
                    ? state.publicNote.length > 9
                      ? "9+"
                      : state.publicNote.length
                    : 0}
                  )
                </div>
              </div>

              {state.userHasActiveBid ? (
                <p className="hasBiddedTxt">Bid submitted</p>
              ) : (
                <p
                  className={`claimItemBtn `}
                  onClick={() => {
                    if (!state.userHasActiveBid) {
                      setChanged(true);

                      axios
                        .post(
                          `${serverURL}/bid-shift`,
                          {
                            openID: memoVals.showUserOpenShiftModal,
                            type: state.type,
                            nowDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "accept") {
                            // execute assign request here
                            axios
                              .post(
                                `${serverURL}/convert-open-to-shift-or-til`,
                                {
                                  userID: memoVals.checkUserState.userID,
                                  openID: memoVals.showUserOpenShiftModal,
                                  autoAccepted: true,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response2) => {
                                memoVals.setUpdatedOpenShift((x) => {
                                  return {
                                    openID: memoVals.showUserOpenShiftModal,
                                    delete: true,
                                  };
                                });
                                setCountData({
                                  openShifts: countData.openShifts - 1,
                                  openShiftsMgr: countData.openShiftsMgr - 1,
                                });
                                let n = response2.data.newItem;

                                // absent: updatedItem.data.absent,
                                // bankCost: updatedItem.data.bankCost,
                                // break: updatedItem.data.break,
                                // dayN: updatedItem.dayN,
                                // ds: updatedItem.data.ds,
                                // durMins: updatedItem.data.durMins,
                                // end: updatedItem.data.end,
                                // ghost: updatedItem.data.ghost,
                                // initial: false,
                                // midnightTimestamp: updatedItem.midnightTimestamp,
                                // published: updatedItem.published,
                                // start: updatedItem.data.start,
                                // startTs: dateStringer.createTimestampFromString(
                                //   updatedItem.data.start
                                // ),
                                // tags: updatedItem.data.tags,
                                // type: updatedItem.type,
                                // typeID: typeID,
                                // cost: updatedItem.data.cost,
                                // salaried: updatedItem.salaried,
                                // userHasSwapped: updatedItem.userHasSwapped,
                                // publicNote: updatedItem.comments ? ["00"] : false,
                                // payRoll: updatedItem.payRoll,

                                setUpdatedItem((x) => {
                                  return {
                                    type: n.type,
                                    typeID: n.typeID,
                                    dayN: n.dayN,
                                    midnightTimestamp: n.midnightTimestamp,
                                    published: n.published,
                                    salaried: n.salaried,
                                    publicNote: n.publicNote,
                                    data: {
                                      absent: false,
                                      bankCost: 0,
                                      ghost: false,
                                      initial: false,
                                      ds: n.ds,
                                      durMins: n.durMins,
                                      end: n.end,
                                      break: n.break,
                                      start: n.start,
                                      startTs: n.startTs,
                                      tags: n.tags,
                                      cost: n.cost,
                                      userID: memoVals.checkUserState.userID,
                                      shiftID: n.typeID,
                                      tilID: n.typeID,
                                    },
                                  };
                                });

                                memoVals.setIndicate((x) => {
                                  return {
                                    show: true,
                                    message:
                                      n.type === "til"
                                        ? "Shift in lieu is now yours"
                                        : n.type === "shift"
                                        ? "Shift is now yours"
                                        : "Overtime is now yours",
                                    colour: "green",
                                    duration: 4000,
                                  };
                                });
                                memoVals.setUpdateCalAvail(
                                  (x) => !memoVals.updateCalAvail
                                );

                                closeModal();
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }

                          if (response.data.message.includes("already")) {
                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: "Already bidded",
                                colour: "orange",
                                duration: 4000,
                              };
                            });
                          }

                          if (response.data.message.includes("logout")) {
                            window.location.href = "/signin";
                          }
                          if (response.data.message === "success") {
                            setState({
                              bidders: response.data.bidUserIDs,
                              userHasActiveBid: true,
                            });

                            memoVals.setUpdatedOpenShift((x) => {
                              return {
                                openID: memoVals.showUserOpenShiftModal,
                                bidUserIDs: response.data.bidUserIDs,
                              };
                            });
                            memoVals.setUpdateCalAvail(
                              (x) => !memoVals.updateCalAvail
                            );

                            setShowBidSuccessModal(true);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  Bid for this{" "}
                  {state.type === "shift"
                    ? "shift"
                    : state.type === "til"
                    ? "lieu shift"
                    : "overtime"}
                </p>
              )}
            </div>
            <div
              className="closeReqInfo"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </div>
          </div>
          {/* end of footer */}
        </div>
      )}
      {!loading && showBigSuccessModal ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              Your bid has been added.
              <br />
              <br /> We will notify you when your manager has decided who to
              give this open{" "}
              {state.type === "shift"
                ? "shift"
                : state.type === "til"
                ? "overtime (time in lieu)"
                : "overtime"}{" "}
              to.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setShowBidSuccessModal(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}{" "}
      {shiftDoesNotExist ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            closeModal();
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            This open shift no longer exists.
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </p>
              {/* <p
                    className="areYouModalNoBtn"
                    onClick={() => {
                      setNotEnoughModal(false);
                    }}
                  >
                    Cancel
                  </p> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {sureCancelBid ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setSureCancelBid(false);
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              Are you sure you want to cancel and withdraw your bid?
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setChanged(true);
                  setSureCancelBid(false);
                  axios
                    .post(
                      `${serverURL}/cancel-open-claim`,
                      {
                        openID: memoVals.showUserOpenShiftModal,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Bid cancelled",
                            colour: "green",
                            duration: 4000,
                          };
                        });
                        setState({
                          bidders: response.data.bidUserIDs,
                          userHasActiveBid: false,
                        });
                        memoVals.setUpdatedOpenShift((x) => {
                          return {
                            openID: memoVals.showUserOpenShiftModal,
                            bidUserIDs: response.data.bidUserIDs,
                          };
                        });
                        memoVals.setUpdateCalAvail(
                          (x) => !memoVals.updateCalAvail
                        );
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureCancelBid(false);
                }}
              >
                Go back
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default UserOpenShiftModal;
