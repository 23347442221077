// Menu for the mobile view only - accessed via the burger button in the mobile navbar
import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// iphone done jul 24

import { NavLink } from "react-router-dom";

import axios from "axios";
import serverURL from "../serverURL";

import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";
import "time-input-polyfill/auto";

import dateStringer from "../tools/dateStringer";
import CheckUser from "../tools/CheckUser";
import "../styles/menu.css"; // also contains css for request fullscreen modal

import Upcoming from "./Upcoming";
import Navbar from "./Navbar";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";
import menuToday from "../img/general/menuToday.svg";
import menuToday2 from "../img/general/menuToday2.svg";
import menuToday3 from "../img/general/menuToday3.svg";
import menuToday0 from "../img/general/menuToday0.svg";
import menuChange from "../img/general/menuChange.svg";
import menuChangeMgr from "../img/general/menuChangeMgr.svg";
import menuAddUser from "../img/general/menuAddUser.svg";

import menuSwap from "../img/general/menuSwap.svg";
import menuSwapMgr from "../img/general/menuSwapMgr.svg";
import menuAllowancesMgr from "../img/general/menuAllowancesMgr.svg";
import menuAllowancesUser from "../img/general/menuAllowancesUser.svg";
import menuRota from "../img/general/menuRota.svg";

import plane2 from "../img/general/plane2.svg";
import menuOpen from "../img/general/menuOpen.svg";
import menuOpenMgr from "../img/general/menuOpenMgr.svg";
import schedToday from "../img/general/schedToday.svg";

import menuUnclocked from "../img/general/menuUnclocked.svg";
import menuAcceptance from "../img/general/menuAcceptance.svg";
import popup from "../img/general/popup.svg";
import whiteGo from "../img/general/whiteGo.svg";

import clockOnPower from "../img/general/clockOnPower.svg";
import whitecross from "../img/general/whitecross.svg";
import profilePicturePlaceholder from "../img/general/profilePicturePlaceholder.svg";
import pullUp from "../img/general/pullUp.svg";
import blueLoader from "../img/general/blueLoader.gif";
import admin from "../img/general/admin.svg";

const Menu = () => {
  const {
    setSelectedNav,
    setShowUnclocked,
    setShowClockOnModal,
    // unclockedQty,
    // activeClocksQty,
    setShowActiveClocks,
    userIsClockedOn,
    userIsOnClockedBreak,
    device,
    // acceptDecline,
    setShowMgrAcceptanceModal,
    // teamSwapsQty,
    setShowTeamSwapsModal,
    // inboundSwapsQty,
    setShowReqStats,
    setShowOpenShiftsModal,
    setShowOpenShiftsMgrModal,
    setShowSwapsModal,
    modalOpen,
    setModalOpen,

    setShowAddUserModal,
    countData,
    showNotifications,
    setShowScheduledTodayModal,
    setPage,
  } = useContext(DataContext);
  const { checkUserState } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      setSelectedNav,
      setShowUnclocked,
      setShowClockOnModal,
      // unclockedQty,
      // activeClocksQty,
      setShowActiveClocks,
      userIsClockedOn,
      userIsOnClockedBreak,
      device,
      // acceptDecline,
      setShowMgrAcceptanceModal,
      checkUserState,
      // teamSwapsQty,
      setShowTeamSwapsModal,
      // inboundSwapsQty,
      setShowReqStats,
      setShowOpenShiftsModal,
      setShowOpenShiftsMgrModal,
      setShowSwapsModal,
      setShowAddUserModal,
      setModalOpen,
      modalOpen,
      showNotifications,
      setShowScheduledTodayModal,
    }),
    [
      setSelectedNav,
      setShowUnclocked,
      setShowClockOnModal,
      // unclockedQty,
      // activeClocksQty,
      setShowActiveClocks,
      userIsClockedOn,
      userIsOnClockedBreak,
      device,
      // acceptDecline,
      setShowMgrAcceptanceModal,
      checkUserState,
      // teamSwapsQty,
      setShowTeamSwapsModal,
      // inboundSwapsQty,
      setShowReqStats,
      setShowOpenShiftsModal,
      setShowOpenShiftsMgrModal,
      setShowSwapsModal,
      setShowAddUserModal,
      setModalOpen,
      modalOpen,
      showNotifications,
      setShowScheduledTodayModal,
    ]
  );

  let [calcBeforeDs, setCalcBeforeDs] = useState("");

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [upcQty, setUpcQty] = useState(0);
  useEffect(() => {
    if (dataLoaded && imagesLoaded && imagesLoaded === 25) {
      setLoading(false);
      setPage("menu");
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={menuAllowancesMgr}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={menuRota}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={schedToday}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={admin}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={menuAllowancesUser}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={menuChangeMgr}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={menuUnclocked}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={menuOpen}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={menuOpenMgr}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={whiteGo}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={plane2}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={popup}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={menuAcceptance}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={menuChange}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={menuToday}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={menuToday2}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={menuToday3}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={menuSwap}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={menuSwapMgr}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={menuToday0}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={clockOnPower}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={pullUp}
        alt="4"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={blueLoader}
        alt="5"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  // const {

  //   userID,

  // } = useContext(UserContext);
  let [excessLoading, setExcessLoading] = useState(true);

  CheckUser();

  // let [gmtOffset, setGmtOffset] = useState(0);
  let [menuPage, setMenuPage] = useState("upcoming"); // upcoming, manager, open
  let [propic, setPropic] = useState("");
  let [menuFName, setMenuFName] = useState("");
  let [menuLName, setMenuLName] = useState("");
  let [isMgr, setIsMgr] = useState(false);
  // let [upcomingShift, setUpcomingShift] = useState({});
  // let [subsequentShift, setSubsequentShift] = useState({});
  let [clockOnRequired, setClockOnRequired] = useState(false);
  let [remainingMins, setRemainingMins] = useState(0);
  let [dailyMins, setDailyMins] = useState(0);
  // let [wPatt, setWPatt] = useState("fixedScheduled");
  let [topLoading, setTopLoading] = useState(true);
  let [upcomingObject, setUpcomingObject] = useState([]);
  let [subsequentObject, setSubsequentObject] = useState([]);
  let [clockOnOK, setClockOnOK] = useState(false);
  let [leaveInfo, setLeaveInfo] = useState({});
  let [showAllowances, setShowAllowances] = useState(false);
  // let [showExcessMins, setShowExcessMins] = useState(false); // if user is paid ot on reqs only
  // let [excessMpd, setExcessMpd] = useState(0);
  // let [refPeriod, setRefPeriod] = useState(52);

  let [lastUpdatedExcessTs, setLastUpdatedExcessTs] =
    useState("Y1970M00D00H00M00");

  let [excessData, setExcessData] = useState({});

  let [weeksUsed, setWeeksUsed] = useState(0);

  let [excessDataArr, setExcessDataArr] = useState([]);

  useEffect(() => {
    // clean not needed
    if (window.innerWidth > 820) {
      window.location.href = "/";
    }
  }, [window.innerWidth]);

  let generateExcessHistoryRows = excessDataArr
    ? excessDataArr.map((item) => {
        if (item.startDs) {
          return (
            <div className="excessHistoryRow" key={item.startDs}>
              <div className="excessHistoryRowStart">
                {dateStringer.printedDateFromDs(item.startDs)} '
                {`${item.startDs.substr(3, 2)}`} to{" "}
                {dateStringer.printedDateFromDs(item.endDs)} '
                {`${item.endDs.substr(3, 2)}`}
              </div>
              <div className="excessHistoryRowTable">
                <div className="excessHistoryRowTableRow excessHistoryRowTableRowFirst">
                  <p className="excessHistoryRowTableRowTitle">
                    Hours from shifts
                  </p>
                  <p className="excessHistoryRowTableRowValue">
                    {dateStringer.formatMinsDurationToHours(
                      item.minsFromShifts
                    )}
                  </p>
                </div>
                <div className="excessHistoryRowTableRow">
                  <p className="excessHistoryRowTableRowTitle">
                    Hours from overtime
                  </p>
                  <p className="excessHistoryRowTableRowValue">
                    {dateStringer.formatMinsDurationToHours(
                      item.minsFromOvertimes
                    )}
                  </p>
                </div>

                <div className="excessHistoryRowTableRow">
                  <p className="excessHistoryRowTableRowTitle">
                    Hours from leave
                  </p>
                  <p className="excessHistoryRowTableRowValue">
                    {dateStringer.formatMinsDurationToHours(item.minsFromReqs)}
                  </p>
                </div>
                <div
                  // className="excessHistoryRowTableRow"
                  className={`${
                    item.minsFromHistory > 0
                      ? "excessHistoryRowTableRow"
                      : "none"
                  }`}
                >
                  <p className="excessHistoryRowTableRowTitle">
                    Hours from before you were added to FlowRota
                  </p>
                  <p className="excessHistoryRowTableRowValue">
                    {dateStringer.formatMinsDurationToHours(
                      item.minsFromHistory
                    )}
                  </p>
                </div>
              </div>
            </div>
          );
        }
      })
    : [];

  let generateNextItemString = (upcoming, subsequent) => {
    if (upcoming && upcoming[0]) {
      let type = "Shift";
      if (upcoming[0].type === "til") {
        type = "Shift in lieu";
      }
      if (upcoming[0].type === "overtime") {
        type = "Overtime";
      }
      return `${type} today @ ${dateStringer.dsToTimeStrip(upcoming[0].start)}`;
    }

    if (subsequent && subsequent[0]) {
      let type = "Shift";
      if (subsequent[0].type === "til") {
        type = "Shift in lieu";
      }
      if (subsequent[0].type === "overtime") {
        type = "Overtime";
      }

      let when = "tomorrow";
      let todayMidnight = new Date();
      todayMidnight.setHours(0, 0, 0, 0);
      let shiftMidnight = new Date(
        dateStringer.createTimestampFromString(subsequent[0].start)
      );
      shiftMidnight.setHours(0, 0, 0, 0);

      let daysDiff =
        (shiftMidnight.getTime() - todayMidnight.getTime()) /
        (1000 * 60 * 60 * 24);
      if (daysDiff === 0) {
        when = "today";
      }
      if (daysDiff === 1) {
        when = "tomorrow";
      }
      if (daysDiff > 1) {
        if (shiftMidnight.getDay() === 0) {
          when = "on Sun";
        }
        if (shiftMidnight.getDay() === 1) {
          when = "on Mon";
        }
        if (shiftMidnight.getDay() === 2) {
          when = "on Tue";
        }
        if (shiftMidnight.getDay() === 3) {
          when = "on Wed";
        }
        if (shiftMidnight.getDay() === 4) {
          when = "on Thu";
        }
        if (shiftMidnight.getDay() === 5) {
          when = "on Fri";
        }
        if (shiftMidnight.getDay() === 6) {
          when = "on Sat";
        }
      }
      return (
        <>
          {type} {when}{" "}
          {when !== "today" && when !== "tomorrow"
            ? shiftMidnight.getDate()
            : ""}
          {when !== "today" && when !== "tomorrow" ? (
            <p className="menuSuff">
              {dateStringer.nd(shiftMidnight.getDate())}
            </p>
          ) : (
            ""
          )}{" "}
          {shiftMidnight.getMonth() === new Date().getMonth() ||
          when === "tomorrow"
            ? ""
            : dateStringer.getMonthThreeLettersFromDs(
                dateStringer.createStringFromTimestamp(shiftMidnight.getTime())
              )}{" "}
          @ {dateStringer.dsToTimeStrip(subsequent[0].start)}
        </>
      );
    }

    return "No upcoming shift";
  };

  // let [openShiftsWithoutClaimers, setOpenShiftsWithoutClaimers] = useState(0);
  const cancelSource1 = axios.CancelToken.source();
  // const cancelSource2 = axios.CancelToken.source();
  const cancelSource3 = axios.CancelToken.source();
  useEffect(() => {
    memoVals.setSelectedNav((x) => 1.1);

    let nowDate = new Date();
    let todayMidnight = new Date();
    todayMidnight.setHours(0, 0, 0, 0);

    axios
      .post(
        `${serverURL}/get-menu-profile-data`,
        {
          // userID: userID || "NO USER ID",
          todayDs: dateStringer.createStringFromTimestamp(
            todayMidnight.getTime()
          ),
          now: dateStringer.createStringFromTimestamp(nowDate.getTime()),
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("logout")) {
          window.location.href = "/signin";
        }
        if (response.data.message === "success") {
          // setChangeCount(response.data.changeCount);
          setIsMgr(response.data.permissions < 3);
          // setUserOutstandingReqs(response.data.userOutstandingReqs);
          // setMyAcceptsRequired(response.data.requireAccepts);
          if (response.data.permissions < 3) {
            setMenuPage("manager");
            axios
              .post(
                `${serverURL}/get-upcoming`,
                {
                  ds: dateStringer.createStringFromTimestamp(
                    new Date().getTime()
                  ),
                  countOnly: true,
                },

                {
                  withCredentials: true,
                  credentials: "include",
                  cancelToken: cancelSource3.token,
                }
              )
              .then((response) => {
                if (response.data.message.includes("logout")) {
                  window.location.href = "/signin";
                }
                if (response.data.message === "success") {
                  setUpcQty(response.data.output);
                }
              })
              .catch((e) => {
                console.log({ e });
              });
          }
          setDataLoaded(true);
          setLeaveInfo(response.data.leaveInfo);
          if (response.data.requireClockOn) {
            setClockOnRequired(true);
            if (
              response.data.upcomingItem[0] &&
              response.data.upcomingItem[0].clockOnNotNeeded
            ) {
              setClockOnRequired(false);
            }
            if (
              response.data.subsequentItem[0] &&
              response.data.subsequentItem[0].clockOnNotNeeded
            ) {
              setClockOnRequired(false);
            }
          }
          setMenuFName(response.data.fName);
          setMenuLName(response.data.lName);
          // setShowExcessMins(response.data.showExcessMins);
          // setExcessMpd(response.data.excessMpd);
          setDailyMins(response.data.dailyMins);
          setRemainingMins(response.data.remainingMins);
          // setWPatt(response.data.workPatt);
          setPropic(response.data.propic);
          setClockOnOK(response.data.clockOnWithinClockOnPeriod);

          if (response.data.upcomingItem[0]) {
            setUpcomingObject([
              {
                type: response.data.upcomingItem[0].type,
                start: response.data.upcomingItem[0].start,
                itemID: response.data.upcomingItem[0].itemID,
              },
            ]);

            if (response.data.upcomingItem[0].clockOnNotNeeded) {
              setClockOnRequired(false);
            }
          } else if (response.data.subsequentItem[0]) {
            setSubsequentObject([
              {
                type: response.data.subsequentItem[0].type,
                start: response.data.subsequentItem[0].start,
                itemID: response.data.subsequentItem[0].itemID,
              },
            ]);
            if (response.data.subsequentItem[0].clockOnNotNeeded) {
              setClockOnRequired(false);
            }
          }

          setTopLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
      // cancelSource2.cancel("Component unmounted");
      cancelSource3.cancel("Component unmounted");
    };
  }, []);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  useEffect(() => {
    if (showAllowances) {
      setModalPosition({ bottom: "0px" });
      if (!modalOpen) {
        setModalOpen(true);
      }
    }
    if (!showAllowances) {
      if (modalOpen) setModalOpen(false);
    }
  }, [showAllowances]);

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      setShowAllowances(false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let scrollBox = useRef();
  useEffect(() => {
    if (scrollBox.current) {
      scrollBox.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [menuPage]);
  // master return
  return (
    <div className="menuMainContainer">
      {/* <div className="menuWhiteOverlayContainer"> */}
      {/* <div className="menuWhiteOverlaySquare"></div> */}
      {/* <img src={homeBulge} alt="Bulge" className="homeBulge" /> */}
      {/* </div> */}
      <div className={`${topLoading ? "topMenuContentLoad" : "none"}`}>
        <img src={blueLoader} className="menuLoadingImg" alt="Loading" />
      </div>

      <div className={`${topLoading ? "none" : "topMenuContent"}`}>
        {/* {isMgr && (
          <div className="mgrMobTopRight">
            {memoVals.unclockedQty ? (
              <p
                className="mgrMobTopRightBtn"
                onClick={() => {
                  memoVals.setShowUnclocked((x) => true);
                }}
              >
                Unclocked{" "}
                <span className="mgrMobTopRightBtnQty">
                  {memoVals.unclockedQty > 99 ? "99+" : memoVals.unclockedQty}
                </span>
              </p>
            ) : (
              ""
            )}
            {memoVals.activeClocksQty > 0 ? (
              <p
                className="mgrMobTopRightBtn"
                onClick={() => {
                  memoVals.setShowActiveClocks((x) => true);
                }}
              >
                Today{" "}
                <span className="mgrMobTopRightBtnQty">
                  {memoVals.activeClocksQty > 99
                    ? "99+"
                    : memoVals.activeClocksQty}
                </span>
              </p>
            ) : (
              ""
            )}
            {countData.accepted + countData.declined >
            0 ? (
              <p
                className="mgrMobTopRightBtn"
                onClick={() => {
                  memoVals.setShowMgrAcceptanceModal((x) => true);
                }}
              >
                Acceptance{" "}
                <span className="mgrMobTopRightBtnQty">
                  {countData.accepted +
                    countData.declined >
                  99
                    ? "99+"
                    : countData.accepted +
                      countData.declined}
                </span>{" "}
              </p>
            ) : (
              ""
            )}
          </div>
        )} */}
        <div className="menuTopSectionContainer">
          <div className="menuTopRow1">
            {/* <div className='menuTopRow1SideUnit'>&nbsp;</div> */}
            {propic ? (
              <NavLink to="/account">
                <img
                  src={propic}
                  alt="Profile picture"
                  className="menuProfPicImg"
                />
              </NavLink>
            ) : (
              <NavLink to="/account">
                <div className="menuNoProPic">
                  <img
                    src={profilePicturePlaceholder}
                    alt={menuFName}
                    className="menuProPicPlaceholder"
                  />
                  <p className="menuProPicInits">
                    {menuFName ? menuFName[0] : ""}
                    {menuLName ? menuLName[0] : ""}
                  </p>
                </div>{" "}
              </NavLink>
            )}
            <div className="menuTopRow2">
              <p className="menuName">
                {menuFName} {menuLName}
              </p>
              <p className="menuRemaining">
                <span className="menuRemainingBoldSpan">
                  {dailyMins > 0
                    ? dateStringer.convertMinsToDays(
                        remainingMins,
                        dailyMins,
                        true
                      )
                    : dateStringer.formatMinsDurationToHours(remainingMins)}
                </span>{" "}
                left
              </p>
              <p
                className={`${
                  dailyMins > 0
                    ? `menuRemainingDays ${remainingMins < 0 ? "fullOpac" : ""}`
                    : "none"
                }`}
              >
                <span
                  className={`menuRemainingBoldSpan ${
                    remainingMins < 0 ? "overaLLOWtxtRed" : ""
                  }`}
                >
                  {remainingMins >= 0
                    ? dateStringer.formatMinsDurationToHours(remainingMins)
                    : `${dateStringer.formatMinsDurationToHours(
                        remainingMins
                      )} over allowance`}
                </span>{" "}
              </p>
            </div>
          </div>

          <div className="menuTopRow1SideUnit">
            {/* <img
              src={showAllowances ? allowancesClose : allowancesEye}
              alt="Edit"
              className="editProfileImg"
              // onClick={() => {
              //   setShowAllowances(!showAllowances);
              // }}
            /> */}
            <div
              onClick={() => {
                if (!showAllowances && !excessDataArr[0]) {
                  axios
                    .post(
                      `${serverURL}/get-excess-data-array`,
                      {
                        someData: 1,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message.includes("logout")) {
                        window.location.href = "/signin";
                      }
                      if (response.data.message === "success") {
                        setExcessDataArr(
                          response.data.excessData.excessDataArr.sort(
                            (a, b) => {
                              return b.startTs - a.startTs;
                            }
                          )
                        );
                        setExcessData(response.data.excessData);
                        let dateObj = new Date(
                          dateStringer.createTimestampFromString(
                            response.data.lastUpdatedExcessTs || 0
                          )
                        );
                        if (typeof response.data.gmtOffset === "number") {
                          dateObj.setHours(
                            dateObj.getHours() + response.data.gmtOffset
                          );
                        }
                        console.log({ dateObj });
                        setLastUpdatedExcessTs(
                          dateStringer.createStringFromTimestamp(
                            dateObj.getTime()
                          )
                        );
                        let lastDayCalc = new Date(
                          dateStringer.createTimestampFromString(
                            response.data.excessData.lastDayOfCalculation
                          )
                        );
                        lastDayCalc.setDate(lastDayCalc.getDate() + 1);
                        setCalcBeforeDs(
                          dateStringer.createStringFromTimestamp(
                            lastDayCalc.getTime()
                          )
                        );
                        setExcessLoading(false);
                        // setGmtOffset(response.data.gmtOffset);
                        setWeeksUsed(
                          response.data.excessData.refPeriod.refPeriodWeeks
                          // response.data.excessData.weeksFromRefPeriodUsed +
                          //   response.data.excessData
                          //     .weeksFromMaxFarBackPeriodUsed
                        );
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
                setShowAllowances(!showAllowances);
              }}
              //
              className={
                showAllowances ? "hideProfileStatsBtn" : "showProfileStatsBtn"
              }
            >
              <img
                src={whitecross}
                className={`${
                  showAllowances ? "profileStatsWhiteCross" : "none"
                }`}
                alt="close"
              />
              History
            </div>
          </div>
        </div>
        {showAllowances && !memoVals.showNotifications ? (
          <div
            className={`menuAllowancesContainer slideUp ${
              excessLoading ? "excessLoadingMenu" : ""
            }`}
            style={modalPosition}
            ref={modalRef}
          >
            <div
              className="menuMobHeaderSec"
              onTouchStart={handleDown}
              onTouchMove={handleMove}
              onTouchEnd={handleUp}
              onMouseDown={handleDown}
              onMouseMove={handleMove}
              onMouseUp={handleUp}
            >
              <div className="modalSwiper mobMenuModalSwiper"></div>
            </div>
            {excessLoading ? (
              <div className="allowPanelTopAndMiddle noBorder">
                <img
                  src={horizontalBalls}
                  alt="Loading"
                  className="loadingExcessImg"
                />
              </div>
            ) : (
              <div className="allowPanelTopAndMiddle">
                {weeksUsed === 0 ? (
                  <div className={`${excessData ? "allowPanelTop" : "none"}`}>
                    Your history will become available once FlowRota has at
                    least one full seven-day period's worth of data.
                  </div>
                ) : (
                  <div className={`${excessData ? "allowPanelTop" : "none"}`}>
                    Over the last{" "}
                    <span className="excessMpdBold">
                      {weeksUsed}
                      {/* {excessData.refPeriod &&
                      excessData.refPeriod.refPeriodWeeks} */}
                    </span>{" "}
                    weeks up to{" "}
                    {dateStringer.printedDateFromDs(
                      calcBeforeDs,
                      true,
                      true,
                      false,
                      false,
                      false
                    )}
                    , you were paid for an average of approximately{" "}
                    <span className="excessMpdBold">
                      {dateStringer.formatMinsDurationToHours(
                        excessData.periodAvgPerDay && excessData.periodAvgPerDay
                      )}
                    </span>{" "}
                    per payable day. <br />
                    <br />
                    <p
                      className={`${
                        excessData.weeksFromMaxFarBackPeriodUsed ? "" : "none"
                      }`}
                    >
                      There{" "}
                      {excessData.weeksFromMaxFarBackPeriodUsed &&
                      excessData.weeksFromMaxFarBackPeriodUsed === 1
                        ? "was"
                        : "were"}{" "}
                      <span className="excessMpdBold">
                        {excessData.weeksFromMaxFarBackPeriodUsed &&
                          excessData.weeksFromMaxFarBackPeriodUsed}
                      </span>{" "}
                      seven-day period
                      {excessData.weeksFromMaxFarBackPeriodUsed &&
                      excessData.weeksFromMaxFarBackPeriodUsed === 1
                        ? ""
                        : "s"}{" "}
                      within which you were not paid, so FlowRota went back
                      further than the{" "}
                      {excessData.refPeriod &&
                        excessData.refPeriod.refPeriodWeeks}{" "}
                      week reference period.
                    </p>
                  </div>
                )}
                <div className="allowPanelMiddle">
                  <div className="excessDataRow">
                    <p className="menuExcessTitle">Last calculated</p>{" "}
                    <p className="menuExcessValue">
                      {dateStringer.dsToTimeStrip(lastUpdatedExcessTs)},{" "}
                      {dateStringer.printedDateFromDs(lastUpdatedExcessTs)}
                    </p>
                  </div>
                  {/* <div className="excessDataRow">
                    <p className="menuExcessTitle">Total open days</p>{" "}
                    <p className="menuExcessValue">
                      {excessData && excessData.openDsArr}
                    </p>
                  </div>
                  <div className="excessDataRow">
                    <p className="menuExcessTitle">Total closed days</p>{" "}
                    <p className="menuExcessValue">
                      {excessData && excessData.closedDsArr}
                    </p>
                  </div> */}
                  <div className="excessDataRow">
                    <p className="menuExcessTitle">Total hours from shifts</p>{" "}
                    <p className="menuExcessValue">
                      {excessData.shifts &&
                        dateStringer.formatMinsDurationToHours(
                          excessData.shifts.minsFromShifts
                        )}
                    </p>
                  </div>
                  <div className="excessDataRow">
                    <p className="menuExcessTitle">Total hours from overtime</p>{" "}
                    <p className="menuExcessValue">
                      {excessData.tils &&
                        dateStringer.formatMinsDurationToHours(
                          excessData.tils.minsFromOvertimes
                        )}
                    </p>
                  </div>
                  {/* <div className="excessDataRow">
                <p className="menuExcessTitle">Total hours from time-in-lieu</p>{" "}
                <p className="menuExcessValue">
                  {excessData.tils &&
                    dateStringer.formatMinsDurationToHours(
                      excessData.tils.minsFromTils
                    )}
                </p>
              </div> */}
                  <div
                    className={`excessDataRow ${
                      excessData.history &&
                      excessData.history.totMinsFromHistory === 0
                        ? "noBorderExcessData"
                        : "none"
                    }`}
                  >
                    <p className="menuExcessTitle">Total hours from leave</p>{" "}
                    <p className="menuExcessValue">
                      {excessData.reqs &&
                        dateStringer.formatMinsDurationToHours(
                          excessData.reqs.minsFromReqs
                        )}
                    </p>
                  </div>

                  <div
                    className={`${
                      excessData.history &&
                      excessData.history.totMinsFromHistory > 0
                        ? "excessDataRow"
                        : "none"
                    }`}
                  >
                    <p className="menuExcessTitleHistory">
                      Total hours from before you were added to FlowRota
                    </p>{" "}
                    <p className="menuExcessValue">
                      {excessData.reqs &&
                        dateStringer.formatMinsDurationToHours(
                          excessData.history
                            ? excessData.history.totMinsFromHistory
                            : 0
                        )}
                    </p>
                  </div>
                </div>{" "}
                {/* - - - - - -  */}
                {weeksUsed !== 0 && (
                  <p className="excessHistoryDataTitle">
                    Reference period data
                  </p>
                )}
                {weeksUsed !== 0 && (
                  <p className="excessRefPeriodInfo">
                    The weeks listed below are 7-day periods during which
                    FlowRota has enough data about you to calculate your average
                    payable hours per week.
                  </p>
                )}
                {weeksUsed !== 0 && (
                  <div className="excessHistoryRowDataDiv">
                    {generateExcessHistoryRows}
                  </div>
                )}
                {/* - - - - - -  */}
              </div>
            )}
            {/* {!excessLoading ? (
              <div
                className="allowPanelBottom"
                onClick={() => {
                  setShowAllowances(false);
                }}
              >
                <img
                  src={pullUp}
                  className="menuAllowancesPullUpIcon"
                  alt="Close"
                />
              </div>
            ) : (
              ""
            )} */}
          </div>
        ) : (
          ""
        )}
        <div className="menuTopDivider"></div>
        <div className="shiftTodayRow">
          <div className="nextShiftAndNextLeaveDiv">
            <div className={`menuShiftTodayTxt `}>
              {generateNextItemString(upcomingObject, subsequentObject)}
            </div>
            <p
              className={`nextLeaveTxt ${
                leaveInfo.nextLeaveInDays ? "" : "none"
              } ${
                leaveInfo.leaveToday || leaveInfo.nextLeaveInDays < 0
                  ? "fullOpac"
                  : ""
              }`}
            >
              {leaveInfo.leaveToday
                ? "You're on leave today"
                : `${
                    leaveInfo.leaveInFuture
                      ? leaveInfo.nextLeaveInDays < 0
                        ? `You're on leave today`
                        : `Next leave in ${leaveInfo.nextLeaveInDays} days`
                      : "No upcoming leave"
                  }`}
            </p>
          </div>
          <div
            className={`${
              clockOnOK &&
              clockOnRequired &&
              (upcomingObject[0] || subsequentObject[0])
                ? `menuClockOnDiv ${
                    memoVals.userIsClockedOn ? "clockOffBtnActive" : ""
                  } ${
                    memoVals.userIsOnClockedBreak ? "clockedBrkBtnTxtSize" : ""
                  }`
                : "none"
            }`}
            onClick={() => {
              memoVals.setShowClockOnModal((x) => true);
            }}
          >
            <p className="menuClockOnBtnTxt">
              {memoVals.userIsClockedOn
                ? memoVals.userIsOnClockedBreak
                  ? "Clock back on"
                  : "Clock off"
                : "Clock on"}
            </p>{" "}
            <img
              src={clockOnPower}
              alt="Clock on/off"
              className="menuClockOnPowerImg"
            />
          </div>
        </div>
        {/* <div className="shiftTodayRow nextLeaveRow">
          <p
            className="nextLeaveTxt"
            onClick={() => {
              console.log(
                "upcomingObject: ",
                upcomingObject,
                "subsequentObject: ",
                subsequentObject
              );
            }}
          >
            {leaveInfo.leaveToday
              ? "On leave today"
              : `${
                  leaveInfo.leaveInFuture
                    ? `Next leave in ${leaveInfo.nextLeaveInDays} days`
                    : "No upcoming leave"
                }`}
          </p>
        </div> */}
      </div>

      <div
        className={`upcomingNotificationsContainer ${
          isMgr ? "reverse_" : "reverse_"
        } ${loading ? "invis" : ""}`}
      >
        <div
          className="upcomingNotifContainer"
          onClick={() => {
            setMenuPage("upcoming");
          }}
        >
          <p className={`upcomingTitle`}>
            <span
              className={`${
                menuPage !== "upcoming" ? "fadedNotifUpcomingTxt" : ""
              }`}
            >
              Upcoming
            </span>
            {/* <p className={`notifCount ${upcQty === 0 ? "none" : ""}`}>
              {upcQty > 99 ? "99+" : upcQty}
            </p> */}
          </p>
          {menuPage === "upcoming" && <div className="upcomingUnderling"></div>}
        </div>{" "}
        {/*  */}
        {/*  */}
        {isMgr && (
          <div
            className="upcomingNotifContainer"
            onClick={() => {
              setMenuPage("manager");
            }}
          >
            <div
              className={`upcomingTitle`}
              onClick={() => {
                console.log(
                  "....",

                  "leave reqs: ",
                  countData.leaveReqs,
                  "unclocked: ",
                  countData.unclocked,
                  "ttrs: ",
                  countData.ttrs,
                  "drops: ",
                  countData.drops,
                  "currClocked: ",
                  countData.currClocked,
                  "staff swaps: ",
                  countData.staffSwaps,
                  "declined: ",
                  countData.declined,
                  "open shifts mgr: ",
                  countData.openShiftsMgr
                );
              }}
            >
              <span
                className={`${
                  menuPage !== "manager" ? "fadedNotifUpcomingTxt" : ""
                }`}
              >
                Manager
              </span>
              <p
                className={`notifCount notifCountPurple ${
                  countData.leaveReqs +
                    countData.unclocked +
                    countData.ttrs +
                    countData.drops +
                    countData.shiftEdits +
                    countData.currClocked +
                    countData.staffSwaps +
                    countData.declined +
                    countData.openShiftsMgr ===
                  0
                    ? "none"
                    : ""
                }`}
              >
                {countData.leaveReqs +
                  countData.unclocked +
                  countData.ttrs +
                  countData.drops +
                  countData.shiftEdits +
                  countData.currClocked +
                  countData.staffSwaps +
                  countData.declined +
                  countData.openShiftsMgr >
                99
                  ? "99+"
                  : countData.leaveReqs +
                    countData.unclocked +
                    countData.ttrs +
                    countData.drops +
                    countData.shiftEdits +
                    countData.currClocked +
                    countData.staffSwaps +
                    countData.declined +
                    countData.openShiftsMgr}
              </p>
            </div>
            {menuPage === "manager" && (
              <div className="upcomingUnderling"></div>
            )}{" "}
          </div>
        )}
        <div
          className="upcomingNotifContainer"
          onClick={() => {
            setMenuPage("user");
          }}
        >
          <div className={`upcomingTitle`}>
            <span
              className={`${
                menuPage !== "user" ? "fadedNotifUpcomingTxt" : ""
              }`}
            >
              My shortcuts
            </span>
            <p className="notifCount">
              {countData.userLeave +
                countData.openShifts +
                countData.inboundSwaps +
                countData.outstandingChanges +
                countData.shiftAccepts >
              99
                ? "99+"
                : countData.userLeave +
                  countData.openShifts +
                  countData.inboundSwaps +
                  countData.outstandingChanges +
                  countData.shiftAccepts}
            </p>
          </div>
          {menuPage === "user" && <div className="upcomingUnderling"></div>}{" "}
        </div>{" "}
      </div>
      <div className="menuUpcNotifDivider"></div>
      <div
        ref={scrollBox}
        className={`menuUpcomingHolder ${
          memoVals.device.vhIssue ? "vhIssue" : ""
        } ${memoVals.device.ios ? "menuUpcomingHolderIos" : ""}`}
      >
        {!loading && <div className="menuShadow"></div>}

        {loading ? (
          ""
        ) : menuPage === "upcoming" ? (
          <Upcoming setUpcQty={setUpcQty} />
        ) : menuPage === "user" ? (
          <div className="menuOpenShiftsBox">
            <div
              className="menuMgrRow"
              onClick={() => {
                memoVals.setShowScheduledTodayModal({
                  show: true,
                  teamID: "",
                  ds: dateStringer.createStringFromTimestamp(
                    new Date().getTime()
                  ),
                });
              }}
            >
              <div className="menuMgrRowLeft">
                <img
                  src={schedToday}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuUserColour">
                    Scheduled today
                  </p>
                  <p className="menuMgrRowSub">
                    See who is clocked on, and who is scheduled to work today
                  </p>
                </div>
              </div>
              <img src={whiteGo} alt="Pop up" className="menuMgrPopUpImg" />
            </div>
            <NavLink to="leave" className="menuMgrRow">
              <div className="menuMgrRowLeft">
                <img
                  src={plane2}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuUserColour">
                    My leave requests
                  </p>
                  <p className="menuMgrRowSub">
                    {" "}
                    <span
                      className={`${
                        countData.userLeave ? "menyQtyHighlightUser" : ""
                      } ${
                        countData.userLeave === 0 ? "NoQtySpaceRightCorr" : ""
                      }`}
                    >
                      {countData.userLeave === 0 ? "No " : countData.userLeave}
                    </span>{" "}
                    leave request{countData.userLeave === 1 ? "" : "s"} awaiting
                    approval
                  </p>
                </div>
              </div>
              <img src={whiteGo} alt="Pop up" className="menuMgrPopUpImg" />
            </NavLink>
            <NavLink to="leave?myallowances=true" className="menuMgrRow">
              <div className="menuMgrRowLeft">
                <img
                  src={menuAllowancesUser}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuUserColour">
                    My leave allowances
                  </p>
                  <p className="menuMgrRowSub">
                    {" "}
                    View your leave allowances for{" "}
                    {checkUserState.currClientYear}
                  </p>
                </div>
              </div>
              <img src={whiteGo} alt="Pop up" className="menuMgrPopUpImg" />
            </NavLink>
            <div
              className="menuMgrRow"
              onClick={() => {
                memoVals.setShowOpenShiftsModal((x) => true);
              }}
            >
              <div className="menuMgrRowLeft">
                <img
                  src={menuOpen}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuUserColour">
                    Open shifts available
                  </p>
                  <p className="menuMgrRowSub">
                    <span
                      className={`${
                        countData.openShifts ? "menyQtyHighlightUser" : ""
                      } ${
                        countData.openShifts === 0 ? "NoQtySpaceRightCorr" : ""
                      }`}
                    >
                      {countData.openShifts === 0 ? "No" : countData.openShifts}
                    </span>{" "}
                    open shift{countData.openShifts === 1 ? "" : "s"} looking
                    for staff
                  </p>
                </div>
              </div>
              <img src={popup} alt="Pop up" className="menuMgrPopUpImg" />
            </div>
            <div
              className="menuMgrRow"
              onClick={() => {
                memoVals.setShowSwapsModal((x) => true);
              }}
            >
              <div className="menuMgrRowLeft">
                <img
                  src={menuSwap}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuUserColour">
                    My shift swaps
                  </p>
                  <p className="menuMgrRowSub">
                    {" "}
                    <span
                      className={`${
                        countData.inboundSwaps ? "menyQtyHighlightUser" : ""
                      } ${
                        countData.inboundSwaps === 0
                          ? "NoQtySpaceRightCorr"
                          : ""
                      }`}
                    >
                      {countData.inboundSwaps === 0
                        ? "No"
                        : countData.inboundSwaps}
                    </span>{" "}
                    swap request{countData.inboundSwaps === 1 ? "" : "s"}{" "}
                    received
                  </p>
                </div>
              </div>
              <img src={popup} alt="Pop up" className="menuMgrPopUpImg" />
            </div>

            <NavLink
              to="hours?changes=true"
              className="menuMgrRow"
              // onClick={() => {
              //   // memoVals.setShowMgrAcceptanceModal((x) => true);
              // }}
            >
              <div className="menuMgrRowLeft">
                <img
                  src={menuChange}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuUserColour">
                    My shift change requests
                  </p>
                  <p className="menuMgrRowSub">
                    {" "}
                    <span
                      className={`${
                        countData.outstandingChanges
                          ? "menyQtyHighlightUser"
                          : ""
                      } ${
                        countData.outstandingChanges === 0
                          ? "NoQtySpaceRightCorr"
                          : ""
                      }`}
                    >
                      {countData.outstandingChanges === 0
                        ? "No"
                        : countData.outstandingChanges}
                    </span>{" "}
                    outstanding change request
                    {countData.outstandingChanges === 1 ? "" : "s"}
                  </p>
                </div>
              </div>
              <img src={whiteGo} alt="Pop up" className="menuMgrPopUpImg" />
            </NavLink>

            {/* Show only if outstanding shift acepts are needed */}
            <NavLink
              to="hours?toaccept=true"
              className="menuMgrRow  noBorder finalMgrMenuItem"
              onClick={() => {}}
            >
              <div className="menuMgrRowLeft">
                <img
                  src={menuAcceptance}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuUserColour">
                    My shifts to accept
                  </p>
                  <p className="menuMgrRowSub">
                    {" "}
                    <span
                      className={`${
                        countData.shiftAccepts ? "menyQtyHighlightUser" : ""
                      } ${
                        countData.shiftAccepts === 0
                          ? "NoQtySpaceRightCorr"
                          : ""
                      }`}
                    >
                      {countData.shiftAccepts === 0
                        ? "No"
                        : countData.shiftAccepts}
                    </span>{" "}
                    shift{countData.shiftAccepts === 1 ? "" : "s"} for you to
                    accept
                  </p>
                </div>
              </div>
              <img src={whiteGo} alt="Pop up" className="menuMgrPopUpImg" />
            </NavLink>
          </div>
        ) : (
          <div className="menuMgr">
            <NavLink to="team-rota" className="menuMgrRow">
              <div className="menuMgrRowLeft">
                <img
                  src={menuRota}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuMgrColour">Staff rotas</p>
                  <p className="menuMgrRowSub">
                    Manage the rotas for staff you manage
                  </p>
                </div>
              </div>
              <img src={whiteGo} alt="Pop up" className="menuMgrPopUpImg" />
            </NavLink>
            {checkUserState.permissions === 1 ? (
              <NavLink to="admin" className="menuMgrRow">
                <div className="menuMgrRowLeft">
                  <img
                    src={admin}
                    alt="Today employees"
                    className="menuTodayImg"
                  />
                  <div className="menuMgrRowRight">
                    <p className="menuMgrRowTitle menuMgrColour">
                      Admin settings
                    </p>
                    <p className="menuMgrRowSub">
                      Amend the settings of the company as a FlowRota admin
                    </p>
                  </div>
                </div>
                <img src={whiteGo} alt="Pop up" className="menuMgrPopUpImg" />
              </NavLink>
            ) : (
              ""
            )}
            <div
              className="menuMgrRow"
              onClick={() => {
                memoVals.setShowActiveClocks((x) => true);
              }}
            >
              <div className="menuMgrRowLeft">
                <img
                  src={
                    countData.currClocked >= 3
                      ? menuToday3
                      : countData.currClocked === 2
                      ? menuToday2
                      : countData.currClocked === 1
                      ? menuToday
                      : menuToday0
                  }
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuMgrColour">
                    Clocked on employees
                  </p>
                  <p className="menuMgrRowSub">
                    <span
                      className={`${
                        countData.currClocked ? "menyQtyHighlight" : ""
                      } ${
                        countData.currClocked === 0
                          ? "NoQtySpaceRightCorr none"
                          : ""
                      }`}
                    >
                      {countData.currClocked === 0
                        ? "No "
                        : countData.currClocked}
                    </span>{" "}
                    {countData.currClocked === 0 ? "No " : ""}employee
                    {countData.currClocked === 1 ? "" : "s"} currently clocked
                    on
                  </p>
                </div>
              </div>
              <img src={popup} alt="Pop up" className="menuMgrPopUpImg" />
            </div>
            <NavLink
              to="team-leave?staffallowances=true"
              className="menuMgrRow"
              // onClick={() => {
              //   console.log(":)");
              //   memoVals.setShowReqStats((x) => true);
              // }}
            >
              <div className="menuMgrRowLeft">
                <img
                  src={menuAllowancesMgr}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuMgrColour">
                    Staff leave allowances
                  </p>
                  <p className="menuMgrRowSub">
                    Show the leave allowances for staff you manage
                  </p>
                </div>
              </div>
              <img src={whiteGo} alt="Pop up" className="menuMgrPopUpImg" />
            </NavLink>
            <div
              className="menuMgrRow"
              onClick={() => {
                memoVals.setShowUnclocked((x) => true);
              }}
            >
              <div className="menuMgrRowLeft">
                <img
                  src={menuUnclocked}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuMgrColour">
                    Employee no-shows
                  </p>
                  <p className="menuMgrRowSub">
                    <span
                      className={`${
                        countData.unclocked ? "menyQtyHighlight" : ""
                      } ${
                        countData.unclocked === 0 ? "NoQtySpaceRightCorr" : ""
                      }`}
                    >
                      {countData.unclocked === 0 ? "No " : countData.unclocked}
                    </span>{" "}
                    shifts that were not clocked on
                  </p>
                </div>
              </div>
              <img src={popup} alt="Pop up" className="menuMgrPopUpImg" />
            </div>

            <NavLink to="team-leave" className="menuMgrRow">
              <div className="menuMgrRowLeft">
                <img
                  src={plane2}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuMgrColour">
                    Staff leave requests
                  </p>
                  <p className="menuMgrRowSub">
                    <span
                      className={`${
                        countData.leaveReqs ? "menyQtyHighlight" : ""
                      } ${
                        countData.leaveReqs === 0 ? "NoQtySpaceRightCorr" : ""
                      }`}
                    >
                      {countData.leaveReqs === 0 ? "No " : countData.leaveReqs}
                    </span>{" "}
                    request{countData.leaveReqs === 1 ? "" : "s"} awaiting
                    approval
                  </p>
                </div>
              </div>
              <img src={whiteGo} alt="Pop up" className="menuMgrPopUpImg" />
            </NavLink>
            <div
              className="menuMgrRow"
              onClick={() => {
                memoVals.setShowOpenShiftsMgrModal((x) => true);
              }}
            >
              <div className="menuMgrRowLeft">
                <img
                  src={menuOpenMgr}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuMgrColour">Open shifts</p>
                  <p className="menuMgrRowSub">
                    <span
                      className={`${
                        countData.openShiftsMgr ? "menyQtyHighlight" : ""
                      } ${
                        countData.openShiftsMgr === 0
                          ? "NoQtySpaceRightCorr"
                          : ""
                      }`}
                    >
                      {countData.openShiftsMgr === 0
                        ? "No "
                        : countData.openShiftsMgr}
                    </span>{" "}
                    open shifts looking for staff
                  </p>
                </div>
              </div>
              <img src={popup} alt="Pop up" className="menuMgrPopUpImg" />
            </div>

            <NavLink
              to="team-hours?requests=true"
              className="menuMgrRow"
              // onClick={() => {
              //   memoVals.setShowMgrAcceptanceModal((x) => true);
              // }}
            >
              <div className="menuMgrRowLeft">
                <img
                  src={menuChangeMgr}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuMgrColour">
                    Shift change requests
                  </p>
                  <p className="menuMgrRowSub">
                    <span
                      className={`${
                        countData.ttrs +
                        countData.shiftAccepts +
                        countData.drops
                          ? "menyQtyHighlight"
                          : ""
                      } ${
                        countData.ttrs +
                          countData.shiftAccepts +
                          countData.drops ===
                        0
                          ? "NoQtySpaceRightCorr"
                          : ""
                      }`}
                    >
                      {countData.ttrs +
                        countData.shiftAccepts +
                        countData.drops ===
                      0
                        ? "No "
                        : countData.ttrs +
                          countData.shiftAccepts +
                          countData.drops}
                    </span>{" "}
                    shift change request
                    {countData.ttrs +
                      countData.shiftAccepts +
                      countData.drops ===
                    1
                      ? ""
                      : "s"}
                  </p>
                </div>
              </div>
              <img src={whiteGo} alt="Pop up" className="menuMgrPopUpImg" />
            </NavLink>

            <div
              className="menuMgrRow"
              onClick={() => {
                memoVals.setShowTeamSwapsModal((x) => true);
              }}
            >
              <div className="menuMgrRowLeft">
                <img
                  src={menuSwapMgr}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuMgrColour">
                    Staff swap requests
                  </p>
                  <p
                    className={`menuMgrRowSub ${
                      countData.staffSwaps === 0 ? "menuMgrRowSubNoSwaps" : ""
                    }`}
                  >
                    <span
                      className={`${
                        countData.staffSwaps ? "menyQtyHighlight" : ""
                      } ${
                        countData.staffSwaps === 0 ? "NoQtySpaceRightCorr" : ""
                      }`}
                    >
                      {countData.staffSwaps === 0
                        ? "No "
                        : countData.staffSwaps}
                    </span>{" "}
                    shift swap request
                    {countData.staffSwaps === 1 ? "" : "s"} amongst your staff
                  </p>
                </div>
              </div>
              <img src={popup} alt="Pop up" className="menuMgrPopUpImg" />
            </div>

            <div
              className={`menuMgrRow ${
                memoVals.checkUserState.permissions > 1
                  ? "noBorder finalMgrMenuItem"
                  : ""
              } ${countData.accepted + countData.declined === 0 ? "none" : ""}`}
              onClick={() => {
                memoVals.setShowMgrAcceptanceModal((x) => true);
              }}
            >
              <div className="menuMgrRowLeft">
                <img
                  src={menuAcceptance}
                  alt="Today employees"
                  className="menuTodayImg"
                />
                <div className="menuMgrRowRight">
                  <p className="menuMgrRowTitle menuMgrColour">
                    Shift acceptance
                  </p>
                  <p className="menuMgrRowSub">
                    <span
                      className={`${
                        countData.accepted
                          ? "menyQtyHighlight menyQtyHighlightGreen"
                          : "NoQtySpaceRightCorr"
                      }`}
                    >
                      {countData.accepted === 0 ? "No " : countData.accepted}
                    </span>{" "}
                    accepted shift
                    {countData.accepted === 1 ? "" : "s"}
                  </p>
                  <p className="menuMgrRowSub">
                    <span
                      className={`${
                        countData.declined
                          ? "menyQtyHighlight"
                          : "NoQtySpaceRightCorr"
                      }`}
                    >
                      {countData.declined === 0 ? "No " : countData.declined}
                    </span>{" "}
                    declined shift
                    {countData.declined === 1 ? "" : "s"}
                  </p>
                </div>
              </div>
              <img src={popup} alt="Pop up" className="menuMgrPopUpImg" />
            </div>
            {memoVals.checkUserState.permissions === 1 && (
              <div
                className="menuMgrRow  noBorder finalMgrMenuItem"
                onClick={() => {
                  memoVals.setShowAddUserModal((x) => true);
                }}
              >
                <div className="menuMgrRowLeft">
                  <img
                    src={menuAddUser}
                    alt="Today employees"
                    className="menuTodayImg"
                  />
                  <div className="menuMgrRowRight">
                    <p className="menuMgrRowTitle menuMgrColour">
                      Add employee
                    </p>
                    <p className="menuMgrRowSub">Add a new user to FlowRota</p>
                  </div>
                </div>
                <img src={popup} alt="Pop up" className="menuMgrPopUpImg" />
              </div>
            )}
          </div>
        )}
      </div>

      <Navbar />
      {imgPreload}
      {/* <p className='heresYourLeaveTxt'>Here's your dashboard, {fName}</p>

      <input
        type='time'
        className='timeInputTrial'
        value={val}
        onChange={(e) => {
          setVal(e.target.value);
        }}
      ></input> */}
    </div>
  );
};

export default Menu;
