import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useRef,
  Fragment,
} from "react";

// this is a subcomponent of the Dashboard -- the dashboard file is already big enough

import axios from "axios";
import { UserContext } from "../../contexts/UserContext";
import { StyleContext } from "../../contexts/StyleContext";
import { DataContext } from "../../contexts/DataContext";

import { NavLink } from "react-router-dom";
import imageCompression from "browser-image-compression";

import serverURL from "../../serverURL";

import Navbar from "../Navbar";
import CheckUser from "../../tools/CheckUser";
// import MgrAccountNavbar from "./MgrAccountNavbar";

import "../../styles/settings.css";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";
import allowArr from "../../img/general/allowArr.svg";
import cog from "../../img/general/cog.svg";
import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";
import editGrey from "../../img/general/editGrey.svg";
import customPlus from "../../img/general/customPlus.svg";
import customMinus from "../../img/general/customMinus.svg";

import close from "../../img/modals/close.svg";
import dateStringer from "../../tools/dateStringer";

const DashboardAllowances = ({
  availableYears,
  setAvailableYears,
  dashPage,
  setDashPage,
}) => {
  // check the user is valid - this fct will update the UserContext
  //   CheckUser(contact ? "whatsapp" : false);
  const { checkUserState, currencySymbol } = useContext(UserContext);

  const { mob, full, fullButNarrow, mobModal } = useContext(StyleContext);
  const {
    setShowEditUser,
    updateSingleUserAllowances,
    setUpdateSingleUserAllowances,
    device,
  } = useContext(DataContext);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let [selectedUserIDs, setSelectedUserIDs] = useState([]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  let [currClientYear, setCurrClientYear] = useState();

  const cancelSource1 = axios.CancelToken.source();
  const cancelSource2 = axios.CancelToken.source();

  useEffect(() => {
    if (!availableYears[1]) {
      axios
        .post(
          `${serverURL}/get-available-years`,
          {
            client: 1,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setAvailableYears(response.data.data);
            setSelectedYear(response.data.currentYear);
            setCurrClientYear(response.data.currentYear);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [users, setUsers] = useState([]);

  useEffect(() => {
    // get allows here
    if (selectedYear) {
      axios
        .post(
          `${serverURL}/dash-allowances`,
          {
            year: parseInt(selectedYear),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource2.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            setUsers(response.data.users);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return () => {
        cancelSource2.cancel("Component unmounted");
      };
    }
  }, [selectedYear]);

  useEffect(() => {
    // get allows here -- for single when updateSingleUserAllowances is updated with a userID

    if (selectedYear && updateSingleUserAllowances) {
      axios
        .post(
          `${serverURL}/dash-allowances`,
          {
            year: parseInt(selectedYear),
            userID: updateSingleUserAllowances,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource2.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("logout")) {
            window.location.href = "/signin";
          }
          if (response.data.message === "success") {
            let newUsers = [];
            users.forEach((u) => {
              if (u.userID === updateSingleUserAllowances) {
                response.data.users.forEach((xx) => {
                  newUsers.push(xx);
                });
              } else {
                newUsers.push(u);
              }
            });

            setUsers(newUsers);
            setUpdateSingleUserAllowances("");
            // setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return () => {
        cancelSource2.cancel("Component unmounted");
      };
    }
  }, [updateSingleUserAllowances]);

  // master return
  return (
    <div className="dashAllowancesContainer">
      <div className="dashAllowsTopBar">
        {" "}
        <div className="dashAllowTilSelector">
          <div
            className={`dashAllowTilBtn ${
              dashPage === "allowances" ? "dashAllowTilBtnSel" : ""
            }`}
            onClick={() => {
              setDashPage("allowances");
            }}
          >
            <p>Allowances</p>
            <div
              className={`dashAllowTilBtnBar ${
                dashPage !== "allowances" ? "invis" : ""
              }`}
            ></div>
          </div>
          <div className="subHeadSplit2"></div>
          <div
            className={`dashAllowTilBtn ${
              dashPage === "toil" ? "dashAllowTilBtnSel" : ""
            }`}
            onClick={() => {
              setDashPage("toil");
            }}
          >
            {" "}
            <p>TOIL</p>
            <div
              className={`dashAllowTilBtnBar ${
                dashPage !== "toil" ? "invis" : ""
              }`}
            ></div>
          </div>
        </div>
        <div className="dashAllowTopRight">
          <select
            className="dashYrSelectorDropdown"
            value={selectedYear}
            onChange={(e) => {
              setLoading(true);
              setSelectedYear(e.target.value);
              if (selectedUserIDs[0]) {
                setSelectedUserIDs([]);
              }
            }}
          >
            {availableYears.map((yr) => {
              return (
                <option value={yr} key={yr}>
                  {yr}
                </option>
              );
            })}
          </select>
          {checkUserState.permissions === 1 ? (
            <NavLink
              to="/admin/leave-settings"
              className="allowAdminBtn x398298328932"
            >
              {/* Allowances admin{" "} */}
              <img
                src={cog}
                alt="Allowances admin"
                className="allowancesAdminCogImg"
              />
            </NavLink>
          ) : (
            <p></p>
          )}
        </div>
      </div>
      <div
        className={`dashAllowsUsersBox ${
          device.ios ? "dashAllowsUsersBoxIos" : ""
        }`}
      >
        {loading ? (
          <img
            src={horizontalBalls}
            alt="Loading user"
            className="dashUserLoadingimg"
          />
        ) : (
          users
            .sort((a, b) => a.fName.localeCompare(b.fName))
            .map((user) => {
              return (
                <div
                  className={`dashAllowRow ${
                    selectedUserIDs.includes(user.userID)
                      ? "dashAllowRowSelected"
                      : ""
                  }`}
                  key={user.userID}
                  onClick={() => {
                    if (selectedUserIDs.includes(user.userID)) {
                      setSelectedUserIDs([]);
                    } else {
                      setSelectedUserIDs([user.userID]);
                    }
                  }}
                >
                  <div className="dashAllowMain">
                    <div className="dashAllowUserInfo">
                      <img
                        src={user.profPicUrl || profilePicturePlaceholder}
                        className="dashAllowProPic"
                        alt={user.fName}
                        //   onClick={() => {
                        //     setShowEditUser({
                        //       userID: user.userID,
                        //       allows: true,
                        //     });
                        //   }}
                      />

                      <p
                        className={`dashAllowUserName ${
                          mob
                            ? `dashAllowUserNameMob ${
                                window.innerWidth < 420 ? "padRight20" : ""
                              }`
                            : ""
                        }`}
                      >
                        {user.fName} {user.lName}
                      </p>
                    </div>

                    <div className="dashAllowDataSec">
                      <div className="dashAllowItem dashAllowItemTot">
                        <p className="dashAllowItemTitle">Total</p>
                        {user.totalAllowDays === null ||
                        user.totalAllowDays === undefined ? (
                          <p className="dashAllowItemValue">
                            {dateStringer.formatMinsDurationToHours(
                              user.totalAllowMins
                            )}
                          </p>
                        ) : (
                          <p className="dashAllowItemValue">
                            {dateStringer.convertMinsToDays(
                              user.allowInfo.totalAllowMins,
                              Math.floor(user.allowInfo.userDailyMins),
                              true
                            )}
                            {/* {user.allowInfo.totalAllowDays} days */}
                          </p>
                        )}
                      </div>

                      <div className="dashAllowItem">
                        <p className="dashAllowItemTitle">Remaining</p>
                        <p
                          className={`dashAllowItemValue ${
                            user.remaining <= 0 ? "colourRed" : "colourGreen"
                          }`}
                        >
                          {dateStringer.convertMinsToDays(
                            user.remaining,
                            Math.floor(user.allowInfo.userDailyMins),
                            true
                          )}
                        </p>
                      </div>
                    </div>

                    <img
                      src={allowArr}
                      alt="View other allowances"
                      className={`dashallowArrImg ${
                        selectedUserIDs.includes(user.userID)
                          ? "dashAllowArrSel"
                          : ""
                      }`}
                    />
                  </div>

                  {selectedUserIDs.includes(user.userID) && (
                    <div
                      className="dashAllowsUserTable"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="myAccLeaveChartRow">
                        <div
                          className={`myAccLeaveChartTaken myAccLeaveChartTakenMgr ${
                            user.leaveChartData.taken === 0
                              ? "char0"
                              : user.leaveChartData.taken === 10
                              ? "char10"
                              : user.leaveChartData.taken === 20
                              ? "char20"
                              : user.leaveChartData.taken === 30
                              ? "char30"
                              : user.leaveChartData.taken === 40
                              ? "char40"
                              : user.leaveChartData.taken === 50
                              ? "char50"
                              : user.leaveChartData.taken === 60
                              ? "char60"
                              : user.leaveChartData.taken === 70
                              ? "char70"
                              : user.leaveChartData.taken === 80
                              ? "char80"
                              : user.leaveChartData.taken === 90
                              ? "char90"
                              : "char100"
                          }`}
                        ></div>
                        <div
                          className={`myAccLeaveChartBooked myAccLeaveChartBookedMgr ${
                            user.leaveChartData.booked === 0 &&
                            user.leaveChartData.taken === 0
                              ? "myAccLeaveChartBookedMinus5Left"
                              : ""
                          }  ${
                            user.leaveChartData.booked === 0
                              ? "char0"
                              : user.leaveChartData.booked === 10
                              ? "char10"
                              : user.leaveChartData.booked === 20
                              ? "char20"
                              : user.leaveChartData.booked === 30
                              ? "char30"
                              : user.leaveChartData.booked === 40
                              ? "char40"
                              : user.leaveChartData.booked === 50
                              ? "char50"
                              : user.leaveChartData.booked === 60
                              ? "char60"
                              : user.leaveChartData.booked === 70
                              ? "char70"
                              : user.leaveChartData.booked === 80
                              ? "char80"
                              : user.leaveChartData.booked === 90
                              ? "char90"
                              : "char100"
                          }
`}
                        ></div>

                        <div
                          className={`myAccLeaveChartRemaining myAccLeaveChartRemainingMgr ${
                            user.leaveChartData.remaining === 0
                              ? "char0"
                              : user.leaveChartData.remaining === 10
                              ? "char10"
                              : user.leaveChartData.remaining === 20
                              ? "char20"
                              : user.leaveChartData.remaining === 30
                              ? "char30"
                              : user.leaveChartData.remaining === 40
                              ? "char40"
                              : user.leaveChartData.remaining === 50
                              ? "char50"
                              : user.leaveChartData.remaining === 60
                              ? "char60"
                              : user.leaveChartData.remaining === 70
                              ? "char70"
                              : user.leaveChartData.remaining === 80
                              ? "char80"
                              : user.leaveChartData.remaining === 90
                              ? "char90"
                              : "char100"
                          }
`}
                        ></div>
                      </div>
                      <div className="myAccLeaveChartKey userAllowChartBottom">
                        <div className="myAccLeaveChartKeyItem keyItem1">
                          <div className="myAccLeaveChartKeyBlob myAccLeaveChartTakenMgr"></div>
                          <div className="myAccLCKeyTxtDiv">
                            <p className="myAccLCkeyVal">
                              {user.leaveChartData.daysOrHours === "hours"
                                ? dateStringer.formatMinsDurationToShortHours(
                                    user.leaveChartData.takenVal || 0
                                  )
                                : user.leaveChartData.takenVal || 0}{" "}
                              {user.leaveChartData.daysOrHours === "days"
                                ? "day"
                                : ""}
                              {user.leaveChartData.takenVal === 1
                                ? ""
                                : user.leaveChartData.daysOrHours === "days"
                                ? "s"
                                : ""}
                            </p>
                            <p className="myAccLCkeyValTxt">taken</p>
                          </div>
                        </div>{" "}
                        {parseInt(selectedYear) >= new Date().getFullYear() && (
                          <div className="myAccLeaveChartKeyItem keyItem2">
                            <div className="myAccLeaveChartKeyBlob myAccLeaveChartKeyBlobBooked myAccLeaveChartBookedMgr"></div>
                            <div className="myAccLCKeyTxtDiv">
                              <p className="myAccLCkeyVal">
                                {user.leaveChartData.daysOrHours === "hours"
                                  ? dateStringer.formatMinsDurationToShortHours(
                                      user.leaveChartData.bookedVal || 0
                                    )
                                  : user.leaveChartData.bookedVal || 0}{" "}
                                {user.leaveChartData.daysOrHours === "days"
                                  ? "day"
                                  : ""}
                                {user.leaveChartData.bookedVal === 1
                                  ? ""
                                  : user.leaveChartData.daysOrHours === "days"
                                  ? "s"
                                  : ""}
                              </p>
                              <p className="myAccLCkeyValTxt">booked</p>
                            </div>
                          </div>
                        )}
                        <div className="myAccLeaveChartKeyItem keyItem3">
                          <div className="myAccLeaveChartKeyBlob myAccLeaveChartKeyBlobRemaining myAccLeaveChartRemainingMgr"></div>
                          <div className="myAccLCKeyTxtDiv">
                            <p className="myAccLCkeyVal">
                              {user.leaveChartData.daysOrHours === "hours"
                                ? dateStringer.formatMinsDurationToShortHours(
                                    user.leaveChartData.remainingVal || 0
                                  )
                                : user.leaveChartData.remainingVal || 0}{" "}
                              {user.leaveChartData.daysOrHours === "days"
                                ? "day"
                                : ""}
                              {user.leaveChartData.remainingVal === 1
                                ? ""
                                : user.leaveChartData.daysOrHours === "days"
                                ? "s"
                                : ""}
                            </p>
                            <p className="myAccLCkeyValTxt">remaining</p>
                          </div>
                        </div>
                      </div>
                      <div className="dashAllowTableRow annLeaveDashMainRow">
                        <p className="dashAllowTableTitle">Annual leave</p>
                        <div className="dashAllowRightSec">
                          <div className="dashAllowTableItem">
                            <p
                              className="dashAllowTableItemTitle"
                              onClick={() => {
                                console.log(user.totalAllowDays);
                              }}
                            >
                              Total
                            </p>
                            <p className="dashAllowTableItemValue">
                              {/* {user.allowInfo.totalAllowDays === null
                                ? dateStringer.formatMinsDurationToHours(
                                    user.allowInfo.totalAllowMins
                                  )
                                : `${user.allowInfo.totalAllowDays} days`} */}
                              {/* BUTLER */}

                              {user.allowInfo.userDailyMins
                                ? dateStringer.convertMinsToDays(
                                    user.totalAllowMins,
                                    Math.floor(user.allowInfo.userDailyMins),
                                    true
                                  )
                                : user.totalAllowDays === null ||
                                  user.totalAllowDays === undefined
                                ? dateStringer.formatMinsDurationToHours(
                                    user.totalAllowMins
                                  )
                                : `${user.totalAllowDays} days`}
                            </p>
                          </div>
                          <div className="dashAllowTableItem">
                            <p className="dashAllowTableItemTitle">Used</p>
                            <p className="dashAllowTableItemValue">
                              {" "}
                              {user.totalAllowDays === null ||
                              user.totalAllowDays === undefined
                                ? dateStringer.formatMinsDurationToHours(
                                    user.allowInfo.usedMins
                                  )
                                : `${user.allowInfo.usedDays} days`}
                            </p>
                          </div>
                          <div className="dashAllowTableItem">
                            <p className="dashAllowTableItemTitle">Remaining</p>
                            <p
                              className={`dashAllowTableItemValue ${
                                user.remaining <= 0
                                  ? "colourRed"
                                  : "colourGreen"
                              }`}
                            >
                              {" "}
                              {dateStringer.convertMinsToDays(
                                user.remaining,
                                Math.floor(user.allowInfo.userDailyMins),
                                true
                              )}
                            </p>
                          </div>{" "}
                        </div>
                      </div>
                      <div className="dashAllowEditBtnHolder">
                        <div
                          className="allLeaveDashController"
                          onClick={(e) => {
                            e.stopPropagation();

                            setShowEditUser((x) => {
                              return {
                                userID: user.userID,
                                allows: true,
                              };
                            });
                          }}
                        >
                          <div className="dashAddDeductTitle">
                            Amend allowances{" "}
                            <img
                              src={editGrey}
                              alt="Edit"
                              className="editDashPencil"
                            />
                          </div>
                        </div>
                      </div>
                      {user.allowInfo &&
                        user.allowInfo.customData &&
                        user.allowInfo.customData.map((custom, i) => {
                          return (
                            <div
                              className={`dashAllowTableRow ${
                                i === user.allowInfo.customData.length - 1
                                  ? "noBorder"
                                  : ""
                              }`}
                              key={i}
                            >
                              <p className="dashAllowTableTitle">
                                {dateStringer.shorten(custom.name, 30)}
                              </p>
                              <div className="dashAllowRightSec">
                                <div className="dashAllowTableItem">
                                  <p className="dashAllowTableItemTitle">
                                    Total
                                  </p>
                                  <p className="dashAllowTableItemValue">
                                    {custom.totAllow === 366
                                      ? "Unlimited"
                                      : custom.daysOrHours === "days"
                                      ? `${
                                          custom.totAllow === 1
                                            ? `${custom.totAllow} day`
                                            : `${custom.totAllow} days`
                                        }`
                                      : dateStringer.formatMinsDurationToHours(
                                          custom.totAllow
                                        )}
                                  </p>
                                </div>
                                <div className="dashAllowTableItem">
                                  <p className="dashAllowTableItemTitle">
                                    Used
                                  </p>
                                  <p className="dashAllowTableItemValue">
                                    {" "}
                                    {custom.daysOrHours === "days"
                                      ? `${
                                          custom.used === 1
                                            ? `${custom.used} day`
                                            : `${custom.used} days`
                                        }`
                                      : dateStringer.formatMinsDurationToHours(
                                          custom.used
                                        )}
                                  </p>
                                </div>
                                <div
                                  className={`dashAllowTableItem ${
                                    custom.totAllow === 366 ? "invis" : ""
                                  }`}
                                >
                                  <p className="dashAllowTableItemTitle">
                                    Remaining
                                  </p>
                                  <p
                                    className={`dashAllowTableItemValue ${
                                      custom.remaining <= 0
                                        ? "colourRed"
                                        : "colourGreen"
                                    }`}
                                  >
                                    {" "}
                                    {custom.totAllow === 366
                                      ? "Unlimited"
                                      : custom.daysOrHours === "days"
                                      ? `${
                                          custom.remaining === 1
                                            ? `${custom.remaining} day`
                                            : `${custom.remaining} days`
                                        }`
                                      : dateStringer.formatMinsDurationToHours(
                                          custom.remaining
                                        )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}

                  {/* {selectedUserIDs.includes(user.userID) && (
                    <p
                      className="dashManageTeamsBtn x13vf"
                      onClick={(e) => {
                        e.stopPropagation();

                        setShowEditUser((x) => {
                          return {
                            userID: user.userID,
                            allows: true,
                          };
                        });
                      }}
                    >
                      Manage {dateStringer.possession(user.fName)} allowances{" "}
                      <img
                        src={editGrey}
                        alt="Edit"
                        className="editDashPencil"
                      />
                    </p>
                  )} */}
                </div>
              );
            })
        )}
      </div>
    </div>
  );
};

export default DashboardAllowances;
